import { uuidv4 } from '../../../../../common';
import { isCashTrade, roundUnitsByMarketCode } from '../utils';
import { Rebalance, Security, Trade, TradeAction, TradeMethod, PriceType, ExpiryType } from './types';
import { moment } from 'src/common/types';

const setCashTrade = (trades: Trade[], totalPortfolioValue: number) => {
  const totalBuysCash =
    trades.reduce((prev, next) => (next.tradeAction === TradeAction.Buy && !isCashTrade(next) ? prev + next.newCalculatedValue : prev ?? 0), 0) ?? 0;
  const totalSellsCash =
    trades.reduce(
      (prev, next) =>
        (next.tradeAction === TradeAction.Sell || next.tradeAction === TradeAction.All) && !isCashTrade(next) ? prev + next.newCalculatedValue : prev ?? 0,
      0
    ) ?? 0;

  return trades.map((trade) => {
    return isCashTrade(trade)
      ? {
          ...trade,
          newCalculatedValue: totalSellsCash - totalBuysCash,
          proposedValue: trade.currentValue - totalBuysCash + totalSellsCash,
          proposedPercent: ((trade.currentValue - totalBuysCash + totalSellsCash) / totalPortfolioValue) * 100,
        }
      : trade;
  });
};

const setAmountToTrade = (
  tradeAction: TradeAction,
  method: TradeMethod | undefined,
  currentUnits: number,
  currentAmountToTrade: number,
  currentUnitPrice: number,
  currentPercent: number,
  marketCode: string
) => {
  return tradeAction === TradeAction.All
    ? currentUnits
    : tradeAction === TradeAction.Sell && method === TradeMethod.Hash && currentAmountToTrade >= currentUnits
    ? currentUnits
    : tradeAction === TradeAction.Sell && method === TradeMethod.Dollar && currentAmountToTrade >= currentUnits * currentUnitPrice
    ? currentUnits * currentUnitPrice
    : tradeAction === TradeAction.Sell && method === TradeMethod.Percentage && currentAmountToTrade >= currentPercent
    ? currentPercent
    : method === TradeMethod.Hash
    ? roundUnitsByMarketCode(currentAmountToTrade, marketCode)
    : currentAmountToTrade;
};

const setUnits = (
  tradeAction: TradeAction,
  method: TradeMethod | undefined,
  currentUnits: number,
  currentAmountToTrade: number,
  currentUnitPrice: number,
  currentPercent: number,
  marketCode: string,
  totalPortfolioValue: number
) => {
  return tradeAction === TradeAction.All ||
    (tradeAction === TradeAction.Sell &&
      ((method === TradeMethod.Hash && currentAmountToTrade >= currentUnits) ||
        (method === TradeMethod.Dollar && currentAmountToTrade >= roundUnitsByMarketCode(currentUnits * currentUnitPrice, marketCode)) ||
        (method === TradeMethod.Percentage && currentAmountToTrade >= currentPercent)))
    ? currentUnits
    : method === TradeMethod.Dollar
    ? roundUnitsByMarketCode(currentAmountToTrade / currentUnitPrice, marketCode)
    : method === TradeMethod.Percentage
    ? roundUnitsByMarketCode(((currentAmountToTrade / 100) * totalPortfolioValue) / currentUnitPrice, marketCode)
    : roundUnitsByMarketCode(currentAmountToTrade, marketCode);
};

export const initialiseTrades = (rebalance: Rebalance): Trade[] => {
  const trades: Trade[] = rebalance.trades.map((trade) => {
    const units = roundUnitsByMarketCode(trade.calculatedUnits, trade.marketCode);

    const price = trade.priceType === PriceType.Limit && trade.priceLimit !== null ? trade.priceLimit : trade.unitPrice;

    return !isCashTrade(trade)
      ? {
          ...trade,
          method: TradeMethod.Hash,
          amountToTrade: units,
          newCalculatedUnits: units,
          newCalculatedValue: units * price,
          proposedValue: trade.tradeAction === TradeAction.Buy ? trade.currentValue + units * price : trade.currentValue - units * price,
          proposedPercent:
            ((trade.tradeAction === TradeAction.Buy ? trade.currentValue + units * price : trade.currentValue - units * price) /
              rebalance.totalPortfolioValue) *
            100,
        }
      : {
          ...trade,
          amountToTrade: trade.amountToTrade ?? 0,
          newCalculatedUnits: trade.newCalculatedUnits ?? 0,
          method: undefined,
        };
  });

  return setCashTrade(trades, rebalance.totalPortfolioValue);
};

export const clearAllTrades = (rebalance: Rebalance): Trade[] => {
  return rebalance.trades.map((trade) => {
    const isCash = isCashTrade(trade);

    return {
      ...trade,
      amountToTrade: 0,
      newCalculatedUnits: 0,
      newCalculatedValue: 0,
      proposedValue: trade.currentValue,
      proposedPercent: (trade.currentValue / rebalance.totalPortfolioValue) * 100,
      tradeAction: isCash ? trade.tradeAction : TradeAction.Buy,
      method: isCash ? undefined : TradeMethod.Dollar,
    };
  });
};

export const sellAllTrades = (rebalance: Rebalance): Trade[] => {
  const trades: Trade[] = rebalance.trades.map((trade) => {
    const units = roundUnitsByMarketCode(trade.currentUnits, trade.marketCode);

    const price = trade.priceType === PriceType.Limit && trade.priceLimit !== null ? trade.priceLimit : trade.unitPrice;

    return !isCashTrade(trade)
      ? {
          ...trade,
          tradeAction: TradeAction.All,
          method: TradeMethod.Hash,
          amountToTrade: units,
          newCalculatedUnits: units,
          newCalculatedValue: units * price,
          proposedValue: trade.currentValue - units * price,
          proposedPercent: ((trade.currentValue - units * price) / rebalance.totalPortfolioValue) * 100,
        }
      : trade;
  });

  return setCashTrade(trades, rebalance.totalPortfolioValue);
};

export const updateTradeMethod = (rebalance: Rebalance, tradeId: string, method: TradeMethod): Trade[] => {
  const trades: Trade[] = rebalance.trades.map((trade) => {
    if (trade.id === tradeId) {
      // Must calculate amount to trade first to determine correct units
      const amountToTrade = setAmountToTrade(
        trade.tradeAction,
        method,
        trade.currentUnits,
        trade.amountToTrade,
        trade.currentUnitPrice,
        trade.currentPercent,
        trade.marketCode
      );

      const units = setUnits(
        trade.tradeAction,
        method,
        trade.currentUnits,
        trade.amountToTrade,
        PriceType.Limit && trade.priceLimit !== null ? trade.priceLimit : trade.currentUnitPrice,
        trade.currentPercent,
        trade.marketCode,
        rebalance.totalPortfolioValue
      );

      const price = trade.priceType === PriceType.Limit && trade.priceLimit !== null ? trade.priceLimit : trade.unitPrice;

      return {
        ...trade,
        method: method,
        amountToTrade: amountToTrade,
        newCalculatedUnits: units,
        newCalculatedValue: units * price,
        proposedValue: trade.tradeAction === TradeAction.Buy ? trade.currentValue + units * price : trade.currentValue - units * price,
        proposedPercent:
          ((trade.tradeAction === TradeAction.Buy ? trade.currentValue + units * price : trade.currentValue - units * price) / rebalance.totalPortfolioValue) *
          100,
      };
    }
    return trade;
  });

  return setCashTrade(trades, rebalance.totalPortfolioValue);
};

export const updatePriceType = (rebalance: Rebalance, tradeId: string, priceType: PriceType): Trade[] => {
  const trades: Trade[] = rebalance.trades.map((trade) => {
    if (trade.id === tradeId) {
      return {
        ...trade,
        priceType,
      };
    }
    return trade;
  });

  return setCashTrade(trades, rebalance.totalPortfolioValue);
};

export const updateExpiryType = (rebalance: Rebalance, tradeId: string, expiryType: ExpiryType): Trade[] => {
  const trades: Trade[] = rebalance.trades.map((trade) => {
    if (trade.id === tradeId) {
      return {
        ...trade,
        expiryType,
      };
    }
    return trade;
  });

  return setCashTrade(trades, rebalance.totalPortfolioValue);
};

export const updateTradeAction = (rebalance: Rebalance, tradeId: string, tradeAction: TradeAction): Trade[] => {
  const trades: Trade[] = rebalance.trades.map((trade) => {
    // Must calculate amount to trade first to determine correct units
    const amountToTrade = setAmountToTrade(
      tradeAction,
      trade.method,
      trade.currentUnits,
      trade.amountToTrade,
      trade.currentUnitPrice,
      trade.currentPercent,
      trade.marketCode
    );

    const units = setUnits(
      tradeAction,
      trade.method,
      trade.currentUnits,
      trade.amountToTrade,
      PriceType.Limit && trade.priceLimit !== null ? trade.priceLimit : trade.currentUnitPrice,
      trade.currentPercent,
      trade.marketCode,
      rebalance.totalPortfolioValue
    );

    const price = trade.priceType === PriceType.Limit && trade.priceLimit !== null ? trade.priceLimit : trade.unitPrice;

    return trade.id === tradeId
      ? {
          ...trade,
          newCalculatedUnits: units,
          newCalculatedValue: units * price,
          proposedValue: tradeAction === TradeAction.Buy ? trade.currentValue + units * price : trade.currentValue - units * price,
          proposedPercent:
            ((tradeAction === TradeAction.Buy ? trade.currentValue + units * price : trade.currentValue - units * price) / rebalance.totalPortfolioValue) * 100,
          tradeAction: tradeAction,
          amountToTrade: amountToTrade,
          method: tradeAction === TradeAction.All ? TradeMethod.Hash : trade.method,
        }
      : trade;
  });

  return setCashTrade(trades, rebalance.totalPortfolioValue);
};

export const addTrade = (rebalance: Rebalance, security: Security, units: number): Trade[] => {
  const newTrades = [...rebalance.trades];

  const trade: Trade = {
    assetClass: security.assetClass,
    assetClassId: security.assetClassId,
    calculatedUnits: 0,
    calculatedValue: 0,
    id: uuidv4(),
    securityToleranceBand: '',
    tradeAction: TradeAction.Buy,
    currentUnits: 0,
    currentValue: 0,
    currentPercent: 0,
    unitPrice: security.currentUnitPrice,
    currentUnitPrice: security.currentUnitPrice,
    currentUnitPriceTime: security.currentUnitPriceTime,
    comment: '',
    securityCode: `${security.securityCode}.${security.marketCode}`,
    securityName: security.securityName,
    securityId: security.securityId,
    securityType: security.securityType,
    securityCategory: '',
    marketCode: security.marketCode,
    productCode: '',
    productName: '',
    modelName: '',
    modelCode: '',
    amountToTrade: units,
    targetPercent: 0,
    targetValue: 0,
    method: TradeMethod.Hash,
    proposedValue: units * security.currentUnitPrice,
    proposedPercent: ((units * security.currentUnitPrice) / rebalance.totalPortfolioValue) * 100,
    newCalculatedUnits: units,
    newCalculatedValue: units * security.currentUnitPrice,
    expiryDate: '',
    expiryType: 'GoodTillDate',
    priceLimit: null,
    priceType: 'Market',
  };

  newTrades.push(trade);

  return setCashTrade(newTrades, rebalance.totalPortfolioValue);
};

export const clearTrade = (rebalance: Rebalance, tradeId: string): Trade[] => {
  const trades: Trade[] = rebalance.trades.map((trade) => {
    const isCash = isCashTrade(trade);

    return trade.id === tradeId
      ? {
          ...trade,
          amountToTrade: 0,
          newCalculatedUnits: 0,
          newCalculatedValue: 0,
          proposedValue: trade.currentValue,
          proposedPercent: (trade.currentValue / rebalance.totalPortfolioValue) * 100,
          tradeAction: isCash ? trade.tradeAction : TradeAction.Buy,
          method: isCash ? undefined : TradeMethod.Dollar,
        }
      : trade;
  });

  return setCashTrade(trades, rebalance.totalPortfolioValue);
};

export const updateAmountToTrade = (rebalance: Rebalance, tradeId: string, amount: number): Trade[] => {
  const trades: Trade[] = rebalance.trades.map((trade) => {
    // Must calculate amount to trade first to determine correct units
    const amountToTrade = setAmountToTrade(
      trade.tradeAction,
      trade.method,
      trade.currentUnits,
      amount,
      trade.currentUnitPrice,
      trade.currentPercent,
      trade.marketCode
    );

    const units = setUnits(
      trade.tradeAction,
      trade.method,
      trade.currentUnits,
      amount,
      PriceType.Limit && trade.priceLimit !== null ? trade.priceLimit : trade.currentUnitPrice,
      trade.currentPercent,
      trade.marketCode,
      rebalance.totalPortfolioValue
    );

    const price = trade.priceType === PriceType.Limit && trade.priceLimit !== null ? trade.priceLimit : trade.unitPrice;

    return trade.id === tradeId
      ? {
          ...trade,
          amountToTrade: amountToTrade,
          newCalculatedUnits: units,
          newCalculatedValue: units * price,
          proposedValue: trade.tradeAction === TradeAction.Buy ? trade.currentValue + units * price : trade.currentValue - units * price,
          proposedPercent:
            ((trade.tradeAction === TradeAction.Buy ? trade.currentValue + units * price : trade.currentValue - units * price) /
              rebalance.totalPortfolioValue) *
            100,
        }
      : trade;
  });

  return setCashTrade(trades, rebalance.totalPortfolioValue);
};

export const updatePriceLimit = (rebalance: Rebalance, tradeId: string, priceLimit: number | null): Trade[] => {
  const trades: Trade[] = rebalance.trades.map((trade) => {
    const price = trade.priceType === PriceType.Limit && priceLimit !== null ? priceLimit : trade.unitPrice;
    const units = setUnits(
      trade.tradeAction,
      trade.method,
      trade.currentUnits,
      trade.amountToTrade,
      PriceType.Limit && priceLimit !== null ? priceLimit : trade.currentUnitPrice,
      trade.currentPercent,
      trade.marketCode,
      rebalance.totalPortfolioValue
    );
    return trade.id === tradeId
      ? {
          ...trade,
          priceLimit,
          newCalculatedUnits: units,
          newCalculatedValue: units * price,
          proposedValue: trade.tradeAction === TradeAction.Buy ? trade.currentValue + units * price : trade.currentValue - units * price,
          proposedPercent:
            ((trade.tradeAction === TradeAction.Buy ? trade.currentValue + units * price : trade.currentValue - units * price) /
              rebalance.totalPortfolioValue) *
            100,
        }
      : trade;
  });

  return setCashTrade(trades, rebalance.totalPortfolioValue);
};

export const updateDateLimit = (rebalance: Rebalance, tradeId: string, expiryDate: moment | null): Trade[] => {
  const trades: Trade[] = rebalance.trades.map((trade) => {
    return trade.id === tradeId
      ? {
          ...trade,
          expiryDate,
        }
      : trade;
  });

  return setCashTrade(trades, rebalance.totalPortfolioValue);
};

export const clearGroupTrades = (rebalance: Rebalance, groupedTrades: Trade[]): Trade[] => {
  const trades: Trade[] = rebalance.trades.map((trade) => {
    return groupedTrades.some((groupTrade) => groupTrade.id === trade.id) && !isCashTrade(trade)
      ? {
          ...trade,
          amountToTrade: 0,
          newCalculatedUnits: 0,
          newCalculatedValue: 0,
          proposedValue: trade.currentValue,
          proposedPercent: (trade.currentValue / rebalance.totalPortfolioValue) * 100,
          tradeAction: TradeAction.Buy,
          method: TradeMethod.Dollar,
        }
      : trade;
  });

  return setCashTrade(trades, rebalance.totalPortfolioValue);
};

export const updateTrades = (rebalance: Rebalance, importedTrades: Trade[]): Trade[] => {
  const trades: Trade[] = rebalance.trades.map((trade) => {
    const importedTrade = importedTrades.find((newTrade) => newTrade.securityCode === trade.securityCode);
    if (importedTrade && !isCashTrade(trade)) {
      // Must calculate amount to trade first to determine correct units
      const amountToTrade = setAmountToTrade(
        importedTrade.tradeAction,
        importedTrade.method,
        trade.currentUnits,
        importedTrade.amountToTrade,
        trade.currentUnitPrice,
        trade.currentPercent,
        trade.marketCode
      );

      const units = setUnits(
        importedTrade.tradeAction,
        importedTrade.method,
        trade.currentUnits,
        importedTrade.amountToTrade,
        PriceType.Limit && trade.priceLimit !== null ? trade.priceLimit : trade.currentUnitPrice,
        trade.currentPercent,
        trade.marketCode,
        rebalance.totalPortfolioValue
      );

      importedTrades = importedTrades.filter((newTrade) => newTrade.securityCode !== trade.securityCode);

      const price = trade.priceType === PriceType.Limit && trade.priceLimit !== null ? trade.priceLimit : trade.unitPrice;

      return {
        ...trade,
        method: importedTrade.method,
        tradeAction: importedTrade.tradeAction,
        amountToTrade: amountToTrade,
        newCalculatedUnits: units,
        newCalculatedValue: units * price,
        proposedValue: importedTrade.tradeAction === TradeAction.Buy ? trade.currentValue + units * price : trade.currentValue - units * price,
        proposedPercent:
          ((importedTrade.tradeAction === TradeAction.Buy ? trade.currentValue + units * price : trade.currentValue - units * price) /
            rebalance.totalPortfolioValue) *
          100,
      };
    }
    if (!importedTrade && !isCashTrade(trade)) {
      return {
        ...trade,
        amountToTrade: 0,
        newCalculatedUnits: 0,
        newCalculatedValue: 0,
        proposedValue: trade.currentValue,
        proposedPercent: (trade.currentValue / rebalance.totalPortfolioValue) * 100,
        tradeAction: TradeAction.Buy,
        method: TradeMethod.Dollar,
      };
    }
    return trade;
  });

  // Add new trades
  importedTrades.forEach((importTrade) => {
    const units = setUnits(
      TradeAction.Buy,
      importTrade.method,
      0,
      importTrade.amountToTrade,
      importTrade.currentUnitPrice,
      0,
      importTrade.marketCode,
      rebalance.totalPortfolioValue
    );

    const trade: Trade = {
      ...importTrade,
      calculatedUnits: 0,
      calculatedValue: 0,
      id: uuidv4(),
      securityToleranceBand: '',
      tradeAction: TradeAction.Buy,
      currentUnits: 0,
      currentValue: 0,
      currentPercent: 0,
      comment: '',
      securityCategory: '',
      productCode: '',
      productName: '',
      modelName: '',
      modelCode: '',
      targetPercent: 0,
      targetValue: 0,
      proposedValue: units * importTrade.currentUnitPrice,
      proposedPercent: ((units * importTrade.currentUnitPrice) / rebalance.totalPortfolioValue) * 100,
      newCalculatedUnits: units,
      newCalculatedValue: units * importTrade.currentUnitPrice,
    };

    trades.push(trade);
  });

  return setCashTrade(trades, rebalance.totalPortfolioValue);
};
