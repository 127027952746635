import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { progressSelectorFactory } from '../../../common';
import { RootState } from '../../../reducers';
import { DetailsComponent } from './components/DetailsComponent';
import { selectStrategyDetails } from './store/selectors';
import { strategyDetailsSlice } from './store/slice';
import { fetchStrategyDetails, StrategyDetailsActionTypes } from './store/thunks';

const actionTypes = [StrategyDetailsActionTypes.FetchStrategyDetails];

const mapStateToProps = (state: RootState) => ({
  strategyDetails: selectStrategyDetails(state),
  loadingProgress: progressSelectorFactory(actionTypes)(state),
});

const mapDispatchToProps = {
  ...strategyDetailsSlice.actions,
  fetchStrategyDetails,
};

interface MatchParams {
  strategyId?: string;
}

const connector = connect(mapStateToProps, mapDispatchToProps);
export type Props = ConnectedProps<typeof connector> & RouteComponentProps<MatchParams>;
export const StrategyDetailsStateContainer = connect(mapStateToProps, mapDispatchToProps)(DetailsComponent);
