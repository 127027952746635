import { moment } from 'src/common/types';

export interface ModelCompositionParameters {
  selectedGroup: string;
}

export interface FetchModelCompositionCSVPayload {
  modelVersionId: number;
  isModelVersionActive: boolean;
}

export interface ComponentAllocations {
  assetClass: string;
  componentId: number;
  componentCode: string;
  componentName: string;
  componentType: string;
  status: string;
  statusDate: moment;
  type: string;
  units: number;
  unitPrice: number;
  value: number;
  weight: number;
}

export enum ModelCompositionMethod {
  Weighted = 'Weighted',
  Fixed = 'Fixed',
}

export enum MenuOptions {
  None = 'None',
  Import = 'Import',
  Export = 'Export',
}

export interface AssetAllocation {
  securityCode: string;
  assetClass: string;
  targetAllocation: number;
}

export interface ImportRequestPayload {
  file: File;
}

export interface SaveModelCompositionAssetAllocationType {
  code: string;
  assetClass: string;
  targetAllocation: number;
}

export interface SaveModelCompositionPayload {
  modelId: number;
  assetAllocations: SaveModelCompositionAssetAllocationType[];
}

export interface SaveModelCompositionResult {
  warnings: string[];
  errors: string[];
  information: string[];
}

export interface AllocationsImporterResponse {
  errors: string[];
  information: string[];
  warnings: string[];
}

export enum AssetClass {
  AustralianShares = 'Australian Shares',
  FixedIncome = 'Fixed Income',
  Cash = 'Cash',
}
export interface CompositionGroup {
  label: string;
  value: number;
  weight: number;
  componentAllocations: ComponentAllocations[];
}

export interface CompositionDetail {
  modelVersionId: number;
  name: string;
  method: string;
  groups: CompositionGroup[];
}
export interface ModelCompositionState {
  parameters: ModelCompositionParameters;
  compositionDetail: CompositionDetail | null;
  importErrors: string[];
  importWarnings: string[];
  importInformation: string[];
  modelUploadSuccess: boolean;
}

export interface FetchModelCompositionPayload {
  modelVersionId: number;
}

export interface SecuritySearchResults {
  pageNumber: number;
  pageSize: number;
  results: SecuritySearchResult[];
  totalNumberOfPages: number;
  totalNumberOfRecords: number;
}

export interface SecuritySearchResult {
  entityCoreId: number;
  securityCode: string;
  securityId: number;
  securityName: string;
}

export interface SecurityValidationResult {
  securities: Security[];
  errors: string[];
}
export interface Security {
  securityId: number;
  securityName: string;
  securityCode: string;
  currentUnitPriceTime: string;
  currentUnitPrice: number;
  securityType: string;
  marketCode: string;
  assetClass: string;
  assetClassId: number;
}
