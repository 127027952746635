import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SuperSimplifierBeneficiary, SuperSimplifierBeneficiariesState } from './types';
import { fetchSuperSimplifierBeneficiaries } from './thunks';

export const initialState: SuperSimplifierBeneficiariesState = {
  beneficiaries: [],
};

export const superSimplifierSlice = createSlice({
  name: 'superSimplifierSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchSuperSimplifierBeneficiaries.fulfilled, (state, action: PayloadAction<SuperSimplifierBeneficiary[]>) => {
      state.beneficiaries = action.payload;
    });
  },
});
