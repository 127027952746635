import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { progressSelectorFactory } from '../../../../common';
import { RootState } from '../../../../reducers';
import { selectPrevNextRoutePaths } from '../store/routeSelectors';
import {
  selectAdviceFeeEdit,
  selectAdviceFeesValues,
  selectAdvisor,
  selectClientAccount,
  selectEstimatedFeesItems,
  selectId,
  selectStandardFees,
  selectSuperSimplifierDetailsValues,
  selectTieredFeeDetailsEdit,
  selectTieredFeeDetailsItems,
} from '../store/selectors';
import { onboardSlice } from '../store/slice';
import { fetchFeesForAdviser, OnboardActionTypes, saveAdviceFeesValues } from '../store/thunks';
import { AdviceFees } from './components/adviceFees';

const mapStateToProps = (state: RootState, routeComponentProps: RouteComponentProps) => ({
  id: selectId(state),
  prevNextRoutePaths: selectPrevNextRoutePaths(state, routeComponentProps.location.pathname),
  clientAccount: selectClientAccount(state),
  superSimplifierDetailsValues: selectSuperSimplifierDetailsValues(state),
  adviceFeesValues: selectAdviceFeesValues(state),
  selectedAdvisor: selectAdvisor(state),
  estimatedFeesItems: selectEstimatedFeesItems(state),
  selectedFeeItem: selectAdviceFeeEdit(state),
  tieredFeeDetailsItems: selectTieredFeeDetailsItems(state),
  selectedTieredFeeDetailsEdit: selectTieredFeeDetailsEdit(state),
  standardFees: selectStandardFees(state),
  fetchFeesForAdviserProgress: progressSelectorFactory([OnboardActionTypes.FetchFeesForAdviser])(state),
  saveProgress: progressSelectorFactory([OnboardActionTypes.SaveOnboarding])(state),
});

const mapDispatchToProps = {
  saveEstimatedFee: onboardSlice.actions.saveEstimatedFee,
  removeEstimatedFee: onboardSlice.actions.removeEstimatedFee,
  importStandardFees: onboardSlice.actions.importStandardFees,
  setEstimatedFeeEdit: onboardSlice.actions.setEstimatedFeeEdit,
  setTieredFeeDetailsEditId: onboardSlice.actions.setTieredFeeDetailsEditId,
  setTieredFeeDetailsAdd: onboardSlice.actions.setTieredFeeDetailsAdd,
  saveEditingTieredFeeDetails: onboardSlice.actions.saveEditingTieredFeeDetails,
  removeEditingTieredFeeDetails: onboardSlice.actions.removeEditingTieredFeeDetails,
  saveAdviceFeesValues,
  fetchFeesForAdviser,
};

interface MatchParams {
  id?: string;
}

const connector = connect(mapStateToProps, mapDispatchToProps);
export type Props = ConnectedProps<typeof connector> & RouteComponentProps<MatchParams>;
export const AdviceFeesContainer = connect(mapStateToProps, mapDispatchToProps)(AdviceFees);
