import { TableCell, TableFooter, TableRow } from '@mui/material';
import MUIDataTable, { MUIDataTableColumnOptions, MUIDataTableOptions } from 'mui-datatables';
import { default as React, ReactNode, useCallback, useEffect, useState } from 'react';
import PulseLoader from 'react-spinners/PulseLoader';
import { PagedRequest, PagedResult, QueryField } from '../../../../store';
import { theme } from '../../../../themes';
import { LoadingProgress } from '../../../store/types';
import { Pagination } from '../../pagination';
import { useStyles } from '../../themes/dataTableStyles';
import { Filter, FilterOperator, getOperator } from '../common';
import { DatatableColumn, FilterDataType } from '../types';
import Button from './../../button/Button';
import { getColumnWidth, getTotalColumnWidth } from './../functions';

export interface ServerSideDataTableProps<T> {
  fixedWidth?: boolean;
  pagedRequest: PagedRequest;
  pagedResult?: PagedResult<T>;
  columns: DatatableColumn[];
  defaultNumericFilterOperator?: string;
  defaultStringFilterOperator?: string;
  options: MUIDataTableOptions;
  loadingProgress: LoadingProgress;
  csvDownload?: () => void;
  handlePagedRequest: (pagedRequest: PagedRequest) => void;
  handleRowClick?: (rowData: string[], rowMeta: { dataIndex: number; rowIndex: number }) => void;
}

export function ServerSideDataTable<T>({
  defaultStringFilterOperator,
  defaultNumericFilterOperator,
  pagedRequest,
  pagedResult,
  loadingProgress,
  csvDownload,
  columns,
  options,
  handlePagedRequest,
  handleRowClick,
  fixedWidth,
}: ServerSideDataTableProps<T>): JSX.Element {
  const classes = useStyles();
  const _maxSortFields = 4;
  const [sortQueryFields, setSortQueryFields] = useState<QueryField[]>([]);
  const [mUIDataTableColumnDefs, setMUIDataTableColumnDefs] = useState<DatatableColumn[]>([]);
  const noDataLabel = 'No data to display';

  const [icon, setIcon] = useState<ReactNode | null>(null);

  const fetchFilterDisplay = useCallback(
    (filterList, onChange, index, displayColumn, column) => {
      return (
        <Filter
          dataType={column.filterDataType ?? FilterDataType.string}
          operator={getOperator((filterList[index] as string[])[0])}
          value={(filterList[index] as string[])[1]}
          fieldLabel={displayColumn.label ?? ''}
          defaultStringFilterOperator={defaultStringFilterOperator}
          defaultNumericFilterOperator={defaultNumericFilterOperator}
          onFilterChange={(operator: FilterOperator, value: string) => {
            if (!!value) {
              filterList[index] = [operator.name, value, displayColumn?.label ?? ''];
            } else {
              filterList[index] = []; // clear the filter
            }
            onChange(filterList[index], index, displayColumn);
          }}
        ></Filter>
      );
    },
    [defaultNumericFilterOperator, defaultStringFilterOperator]
  );

  useEffect(() => {
    if (loadingProgress.isLoading) {
      setIcon(
        <div className="LoadingIndicator" style={{ paddingTop: '14px' }}>
          <PulseLoader size="12px" margin="5px" color={theme.palette.grey[400]} />
        </div>
      );
    } else {
      setIcon(<></>);
    }
  }, [loadingProgress]);

  useEffect(() => {
    setSortQueryFields(pagedRequest.queryFields.filter((f) => f.isSortTerm));
  }, [pagedRequest]);

  useEffect(() => {
    setMUIDataTableColumnDefs(
      columns.map((column) => {
        // merge the incoming columns with settings below
        const baseOptions: MUIDataTableColumnOptions = {
          filterType: 'custom',
          setCellProps: () => {
            return {
              style: {
                textAlign: column.textAlign || 'left',
                borderBottom: 'none',
              },
            };
          },
          setCellHeaderProps: () => {
            if (fixedWidth === true) {
              const totalColumnWidth = getTotalColumnWidth(columns);
              const columnWidth = getColumnWidth((column.label || '').length, totalColumnWidth);

              return {
                className: column.textAlign === 'right' ? classes.headerHorizontalAlignRight : null,
                width: columnWidth + '%',
              };
            }

            if (column.options?.sort === true) {
              if (column.textAlign === 'right') {
                return {
                  className: classes.headerHorizontalAlignRight,
                };
              }
            }

            if (column.options?.sort === false && (column.textAlign === 'right' || column.textAlign === 'center')) {
              return {
                style: {
                  className: classes.headerHorizontalAlignRight,
                  textAlign: column.textAlign,
                },
              };
            }
            return {
              className: column.textAlign === 'right' ? classes.headerHorizontalAlignRight : null,
            };
          },
          filterOptions: {
            fullWidth: true,

            display: (filterList, onChange, index, displayColumn) => fetchFilterDisplay(filterList, onChange, index, displayColumn, column),
          },
          customFilterListOptions: {
            // chips
            render: (filter) => {
              // this sets the label inside the filter chips
              return `${filter[2]} ${getOperator(filter[0])?.chipLabel} ${filter[1]}`;
            },
            update: (filterList, _filterPos, index) => {
              if (index >= 0) {
                filterList[index] = [];
              }

              return filterList;
            },
          },
        };

        return {
          ...column,
          options: {
            ...baseOptions,
            ...column.options,
          },
        };
      })
    );
  }, [columns, fetchFilterDisplay, fixedWidth]);

  const updatedOptions: MUIDataTableOptions = {
    print: false,
    selectableRows: 'none',
    selectableRowsHideCheckboxes: true,
    selectToolbarPlacement: 'none',
    filter: true,
    viewColumns: !loadingProgress.isLoading,
    filterType: 'multiselect',
    responsive: 'vertical',
    serverSide: true,
    count: pagedResult?.totalNumberOfRecords,
    rowsPerPage: 10,
    rowsPerPageOptions: [],
    confirmFilters: true,
    search: false,
    onRowClick: handleRowClick || undefined,
    customFooter: function renderFooter(
      rowCount: number,
      page: number,
      rowsPerPage: number,
      changeRowsPerPage: (page: number) => void,
      changePage: (newPage: number) => void
    ) {
      return !!pagedResult?.results && pagedResult?.totalNumberOfPages > 1 ? (
        <TableFooter>
          <TableRow>
            <TableCell className={classes.footer}>
              <Pagination
                numberOfPages={pagedResult.totalNumberOfPages}
                handleClickPagination={(page) => changePage(page - 1)}
                pageNumber={pagedRequest.pageNumber}
              />
            </TableCell>
          </TableRow>
        </TableFooter>
      ) : (
        <TableFooter></TableFooter>
      );
    },
    onTableChange: (action, tableState) => {
      switch (action) {
        case 'changePage':
          handlePagedRequest({ ...pagedRequest, pageNumber: tableState.page + 1 });
          break;
        case 'sort':
          handlePagedRequest({
            ...pagedRequest,
            pageNumber: pagedRequest.pageNumber,
            queryFields: [
              {
                fieldName: tableState.sortOrder.name,
                hasSearchTerm: false,
                searchTerm: '',
                isSortTerm: true,
                descendingSortDirection: tableState.sortOrder.direction === 'desc',
                operator: '',
              },
              ...sortQueryFields.filter((q) => q.fieldName !== tableState.sortOrder.name),
            ].slice(0, _maxSortFields),
          });
          break;
      }
    },
    onFilterChange: (column, filterList, type) => {
      if (typeof column === 'string') {
        handleFilterSubmit(filterList);
      }
      if (type === 'chip') {
        handleFilterSubmit(filterList);
      }
    },
    customFilterDialogFooter: function renderFilterDialogFooter(currentFilterList, applyNewFilters) {
      return (
        <div style={{ marginTop: '40px' }}>
          <Button
            className={classes.apply}
            variant="contained"
            color={'primary'}
            onClick={() => handleFilterSubmit(!!applyNewFilters ? applyNewFilters() : [])}
          >
            Apply Filters
          </Button>
        </div>
      );
    },
    download: !loadingProgress.isLoading && (!!csvDownload || options.download || false),
    onDownload: (buildHead, buildBody, columns, data) => {
      if (!!csvDownload) {
        csvDownload();
        return false;
      }
      return !!options.onDownload ? options.onDownload(buildHead, buildBody, columns, data) : false;
    },
    textLabels: {
      ...options.textLabels,
      body: { ...options.textLabels?.body, noMatch: noDataLabel },
    },
    setRowProps: () => {
      return {
        className: handleRowClick && classes.trClickable,
      };
    },
    ...options,
    customToolbar: function customToolbar() {
      return (
        <>
          {icon}
          {!loadingProgress.isLoading && !!options.customToolbar ? options.customToolbar({ displayData: [] }) : null}
        </>
      );
    },
  };

  const handleFilterSubmit = (filterList: string[][]) => {
    const filterQueryFields: QueryField[] = filterList
      .map((filterValue, index) => {
        return {
          fieldName: columns[index].name,
          hasSearchTerm: true,
          searchTerm: !!filterValue[1] ? filterValue[1] : '',
          operator: !!filterValue[0] ? filterValue[0] : '',
          isSortTerm: false,
          descendingSortDirection: false,
        };
      })
      .filter((queryField) => !!queryField.operator && !!queryField.searchTerm);

    handlePagedRequest({
      ...pagedRequest,
      pageNumber: 1,
      queryFields: [...sortQueryFields, ...filterQueryFields],
    });
  };

  return <MUIDataTable title={''} data={(pagedResult?.results || []) as Record<string, unknown>[]} columns={mUIDataTableColumnDefs} options={updatedOptions} />;
}
