import { GroupedByKey, TradeAction, ValidationResult } from '../../store/types';
export { GroupedByKey, MarketCode, SecurityCategory, TradeAction } from '../../store/types';
export type { PreApprovalTrade, RebalanceValidationRequest, ValidateRebalanceSuccessPayload, ValidationResult } from '../../store/types';
import { moment } from 'src/common/types';

export enum SecurityType {
  TermDeposit = 'TermDeposit',
}

export enum TradeMethod {
  Dollar = 'Dollar',
  Percentage = 'Percentage',
  Hash = 'Hash',
}

export enum PriceType {
  Market = 'Market',
  Limit = 'Limit',
}

export enum ExpiryType {
  GTC = 'GoodTillCancelled',
  GFD = 'GoodForDay',
  GTD = 'GoodTillDate',
}

export enum MenuOptions {
  None = 'None',
  QuickOrder = 'QuickOrder',
  Import = 'Import',
  Export = 'Export',
}

export interface RebalanceKey {
  investmentServiceId: number;
  rebalanceId: string;
}

export interface RebalanceDeletionRequest {
  investmentServiceRebalanceIds: string[];
  rebalanceId: string;
}

export interface SecuritiesValidationRequest {
  afslId: number;
  SecurityCodes: string[];
}

export interface SecurityValidationResult {
  securities: Security[];
  errors: string[];
}

export interface Trade {
  assetClass: string;
  assetClassId: number;
  calculatedUnits: number;
  calculatedValue: number;
  comment: string;
  currentPercent: number;
  currentUnitPrice: number;
  currentUnitPriceTime: string;
  currentUnits: number;
  currentValue: number;
  expiryDate: string | null;
  expiryType: string | null;
  id: string;
  marketCode: string;
  modelCode: string | null;
  modelName: string | null;
  priceLimit: number | null;
  priceType: string | null;
  productCode: string | null;
  productName: string | null;
  securityCategory: string;
  securityCode: string;
  securityId: number;
  securityName: string;
  securityToleranceBand: string | null;
  securityType: string;
  targetPercent: number;
  targetValue: number;
  tradeAction: TradeAction;
  unitPrice: number;
  newCalculatedValue: number;
  newCalculatedUnits: number;
  proposedValue: number;
  proposedPercent: number;
  amountToTrade: number;
  method?: TradeMethod;
}

export interface ImportTrade {
  securityCode: string;
  tradeAction: string;
  amountToTrade: number;
  method: string | null;
}

export interface GroupedTrades {
  trades: Trade[];
  totalCurrentPercent: number;
  totalCurrentValue: number;
  totalTargetPercent: number;
  totalTargetValue: number;
  totalProposedPercent: number;
  totalProposedValue: number;
  totalNewCalculatedValue: number;
  totalBuys: number;
  totalSells: number;
  title: string;
  groupedByKey: GroupedByKey;
  key: string;
}

export interface Rebalance {
  afslId: number;
  availableCMA: number;
  canRebalanceByModel: boolean;
  clientName: string;
  investmentServiceId: number;
  investmentServiceRebalanceId: string;
  isClientApprovalRequired: boolean;
  rebalanceId: string;
  serviceType: string;
  totalPortfolioValue: number;
  trades: Trade[];
  validationResult: ValidationResult;
}

export interface Summary {
  openingCash: number;
  totalBuysCash: number;
  totalSellsCash: number;
  proposedCash: number;
}

export interface RebalanceState {
  rebalanceKey: RebalanceKey;
  rebalance: Rebalance;
  filter: string;
  groupedByKey: GroupedByKey;
  isPreApproved: boolean;
  validationMessages: ValidationResult;
  securitySearch: SecuritySearch;
  importErrors: string[];
}

export interface SecuritySearch {
  securitySearch: string;
  securitySearchResults: SecuritySearchResults;
  security: Security;
}

export interface SecuritySearchResults {
  pageNumber: number;
  pageSize: number;
  results: SecuritySearchResult[];
  totalNumberOfPages: number;
  totalNumberOfRecords: number;
}

export interface SecuritySearchResult {
  entityCoreId: number;
  securityCode: string;
  securityId: number;
  securityName: string;
}

export interface TradeTotals {
  calculatedUnits: number;
  calculatedValue: number;
  calculatedPercent: number;
}

export interface Security {
  securityId: number;
  securityName: string;
  securityCode: string;
  currentUnitPriceTime: string;
  currentUnitPrice: number;
  securityType: string;
  marketCode: string;
  assetClass: string;
  assetClassId: number;
}

export interface SetAmountToTradePayload {
  id: string;
  tradeAmount: number;
}

export interface SetPriceLimitPayload {
  id: string;
  priceLimit: number | null;
}

export interface SetExpiryDatePayload {
  id: string;
  expiryDate: moment | null;
}

export interface SetTradePayload {
  id: string;
  tradeAction: TradeAction;
}

export interface SetMethodPayload {
  id: string;
  tradeMethod: TradeMethod;
}

export interface SetPriceTypePayload {
  id: string;
  priceType: PriceType;
}
export interface SetExpiryTypePayload {
  id: string;
  expiryType: ExpiryType;
}

export interface AddTradePayload {
  security: Security;
  units: number;
}

export interface SetRebalanceKeyPayload {
  investmentServiceId: number;
  rebalanceId: string;
}

export interface FetchSecurityRequestPayload {
  afslId: number;
  securityId: number;
}

export interface FetchSecuritySearchRequestPayload {
  afslId: number;
  search: string;
}

export interface ImportRequestPayload {
  file: File;
}

export interface RebalanceDeletionRequestPayload {
  investmentServiceRebalanceId: string;
  rebalanceId: string;
}

export interface ExportTrade {
  tradeAction: string;
  unitPrice: number;
  currentUnitPrice: number;
  currentValue: number;
  currentUnits: number;
  targetValue: number;
  comment: string;
  currentUnitPriceTime: string;
  securityCode: string;
  securityName: string;
  assetClass: string;
  productCode: string;
  productName: string;
  modelCode: string;
  modelName: string;
  currentPercent: number;
  targetPercent: number;
  calculatedValue: number;
  calculatedUnits: number;
  method: string;
  amountToTrade: number;
  newCalculatedUnits: number;
  newCalculatedValue: number;
  proposeValue: number;
  proposedPercent: number;
}
