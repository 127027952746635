import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  addTrade,
  clearAllTrades,
  clearGroupTrades,
  clearTrade,
  initialiseTrades,
  sellAllTrades,
  updateAmountToTrade,
  updatePriceLimit,
  updateDateLimit,
  updateTradeAction,
  updateTradeMethod,
  updatePriceType,
  updateExpiryType,
  updateTrades,
} from './mutators';
import { importTradesAction } from './sagas/importSaga';
import { deleteRebalance, fetchRebalance, fetchSecurities, fetchSecurity, validateRebalance } from './thunks';
import {
  AddTradePayload,
  GroupedByKey,
  Rebalance,
  RebalanceKey,
  RebalanceState,
  Security,
  SecuritySearch,
  SecuritySearchResults,
  SetAmountToTradePayload,
  SetPriceLimitPayload,
  SetExpiryDatePayload,
  SetMethodPayload,
  SetPriceTypePayload,
  SetExpiryTypePayload,
  SetTradePayload,
  Trade,
  ValidateRebalanceSuccessPayload,
  ValidationResult,
} from './types';

const initialState: RebalanceState = {
  rebalanceKey: {} as RebalanceKey,
  rebalance: {} as Rebalance,
  filter: '',
  groupedByKey: GroupedByKey.AssetClass,
  isPreApproved: false,
  validationMessages: {
    errors: [],
    information: [],
    warnings: [],
  },
  importErrors: [],
  securitySearch: {} as SecuritySearch,
};

export const rebalanceEditSlice = createSlice({
  name: '@@rebalance/edit',
  initialState,
  reducers: {
    fetchRebalanceKeySuccess: (state: RebalanceState, action: PayloadAction<RebalanceKey>) => {
      state.rebalanceKey = action.payload;
    },
    setRebalanceKey: (state: RebalanceState, action: PayloadAction<RebalanceKey>) => {
      state.rebalanceKey = action.payload;
    },
    fetchRebalanceKeyFailed: (state: RebalanceState, action: PayloadAction<ValidationResult>) => {
      state.validationMessages = action.payload;
      state.rebalance.validationResult = action.payload;
      state.isPreApproved = false;
    },
    setGroupedBy: (state: RebalanceState, action: PayloadAction<GroupedByKey>) => {
      state.groupedByKey = action.payload;
    },
    setFilter: (state: RebalanceState, action: PayloadAction<string>) => {
      state.filter = action.payload;
    },
    setClearAll: (state: RebalanceState) => {
      state.rebalance.trades = clearAllTrades(state.rebalance);
      state.isPreApproved = false;
    },
    setSellAll: (state: RebalanceState) => {
      state.rebalance.trades = sellAllTrades(state.rebalance);
      state.isPreApproved = false;
    },
    setMethod: (state: RebalanceState, action: PayloadAction<SetMethodPayload>) => {
      state.rebalance.trades = updateTradeMethod(state.rebalance, action.payload.id, action.payload.tradeMethod);
      state.isPreApproved = false;
    },
    setPriceType: (state: RebalanceState, action: PayloadAction<SetPriceTypePayload>) => {
      state.rebalance.trades = updatePriceType(state.rebalance, action.payload.id, action.payload.priceType);
      state.isPreApproved = false;
    },
    setExpiryType: (state: RebalanceState, action: PayloadAction<SetExpiryTypePayload>) => {
      state.rebalance.trades = updateExpiryType(state.rebalance, action.payload.id, action.payload.expiryType);
      state.isPreApproved = false;
    },
    setTrade: (state: RebalanceState, action: PayloadAction<SetTradePayload>) => {
      state.rebalance.trades = updateTradeAction(state.rebalance, action.payload.id, action.payload.tradeAction);
      state.isPreApproved = false;
    },
    addTrade: (state: RebalanceState, action: PayloadAction<AddTradePayload>) => {
      state.rebalance.trades = addTrade(state.rebalance, action.payload.security, action.payload.units);
      state.isPreApproved = false;
    },
    setClearTrade: (state: RebalanceState, action: PayloadAction<string>) => {
      state.rebalance.trades = clearTrade(state.rebalance, action.payload);
      state.isPreApproved = false;
    },
    resetIsPreApproved: (state: RebalanceState) => {
      state.isPreApproved = false;
    },
    setAmountToTrade: (state, action: PayloadAction<SetAmountToTradePayload>) => {
      state.rebalance.trades = updateAmountToTrade(state.rebalance, action.payload.id, action.payload.tradeAmount);
      state.isPreApproved = false;
    },
    setPriceLimit: (state, action: PayloadAction<SetPriceLimitPayload>) => {
      state.rebalance.trades = updatePriceLimit(state.rebalance, action.payload.id, action.payload.priceLimit);
      state.isPreApproved = false;
    },
    setExpiryDate: (state, action: PayloadAction<SetExpiryDatePayload>) => {
      state.rebalance.trades = updateDateLimit(state.rebalance, action.payload.id, action.payload.expiryDate);
      state.isPreApproved = false;
    },
    setClearGroupTrades: (state: RebalanceState, action: PayloadAction<Trade[]>) => {
      state.rebalance.trades = clearGroupTrades(state.rebalance, action.payload);
      state.isPreApproved = false;
    },
    setClearSecuritySearch: (state: RebalanceState) => {
      state.securitySearch = {} as SecuritySearch;
    },
    setRebalanceValidated: (state, action: PayloadAction<ValidateRebalanceSuccessPayload>) => {
      state.validationMessages = action.payload.validationResult;
      state.rebalance.validationResult = action.payload.validationResult;
      state.isPreApproved = true;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(importTradesAction.fulfilled, (state, action: PayloadAction<Trade[]>) => {
      state.rebalance.trades = updateTrades(state.rebalance, action.payload);
      state.importErrors = [];
    });
    builder.addCase(importTradesAction.rejected, (state, action: PayloadAction<string[]>) => {
      state.importErrors = action.payload;
    });
    builder.addCase(fetchRebalance.fulfilled, (state, action: PayloadAction<Rebalance>) => {
      state.rebalance = action.payload;
      state.rebalance.trades = initialiseTrades(action.payload);
    });
    builder.addCase(fetchRebalance.rejected, (state, action) => {
      if (action.payload) {
        state.validationMessages = action.payload;
        state.rebalance.validationResult = action.payload;
      }
      state.isPreApproved = false;
    });
    builder.addCase(fetchSecurities.fulfilled, (state, action: PayloadAction<SecuritySearchResults>) => {
      state.securitySearch.securitySearchResults = action.payload;
    });
    builder.addCase(fetchSecurities.rejected, (state, action) => {
      state.securitySearch.securitySearchResults = {} as SecuritySearchResults;
      if (action.payload) {
        state.validationMessages = action.payload;
      }
    });
    builder.addCase(fetchSecurity.fulfilled, (state, action: PayloadAction<Security>) => {
      state.securitySearch.security = action.payload;
    });
    builder.addCase(fetchSecurity.rejected, (state, action) => {
      state.securitySearch.security = {} as Security;
      if (action.payload) {
        state.validationMessages = action.payload;
      }
    });
    builder.addCase(deleteRebalance.fulfilled, (state) => {
      state.rebalanceKey = {} as RebalanceKey;
      state.rebalance = {} as Rebalance;
      state.filter = '';
      state.groupedByKey = GroupedByKey.AssetClass;
      state.isPreApproved = false;
      state.validationMessages = {} as ValidationResult;
      state.securitySearch = {} as SecuritySearch;
    });
    builder.addCase(deleteRebalance.rejected, (state, action) => {
      if (action.payload) {
        state.validationMessages = action.payload;
      }
    });
    builder.addCase(validateRebalance.fulfilled, (state, action: PayloadAction<ValidateRebalanceSuccessPayload>) => {
      state.validationMessages = action.payload.validationResult;
      state.rebalance.validationResult = action.payload.validationResult;
      state.isPreApproved = true;
    });
    builder.addCase(validateRebalance.rejected, (state, action) => {
      if (action.payload) {
        state.validationMessages = action.payload;
        state.rebalance.validationResult = action.payload;
      }
      state.isPreApproved = false;
    });
  },
});
