import { Container, Grid } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { Field, Form, Formik } from 'formik';
import React, { useCallback, useEffect, useState } from 'react';
import { LoadingIndicator } from 'src/common/components/LoadingIndicator';
import { formatDollars, formatNumberCommaSeparated } from '../../../../common';
import CardComponent from '../../../../common/components/CardComponent';
import { FormikTextField } from '../../../../common/components/formik';
import { theme } from '../../../../themes';
import { Props } from '../container';
import { ConsolidatedSecurityHoldingsSummaryThunkParameters, SecurityDetails } from '../store/types';

interface FormValues extends Partial<SecurityDetails> {
  assetClass: string;
  expenseIcr: string;
}

export const DetailsComponent = (props: Props): JSX.Element => {
  const {
    securityDetails,
    consolidatedSecurityHoldingsSummary,
    securityDetailsProgress,
    consolidatedSecurityHoldingsSummaryProgress,
    fetchConsolidatedSecurityHoldingsSummary,
    fetchSecurityDetails,
    match: { params },
  } = props;

  const initialFormValues: FormValues = {
    assetClass: '',
    expenseIcr: '',
  };

  const fetch = useCallback(() => {
    if (params.securityId) {
      const requestPayload: ConsolidatedSecurityHoldingsSummaryThunkParameters = {
        securityId: parseInt(params.securityId),
      };
      fetchConsolidatedSecurityHoldingsSummary(requestPayload);
      fetchSecurityDetails(requestPayload);
    }
  }, [fetchConsolidatedSecurityHoldingsSummary, fetchSecurityDetails, params.securityId]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  const arrayToString = (arr: string[]) => {
    const newArr: string[] = [];
    arr.forEach((item: string) => {
      newArr.push(item);
    });
    return newArr.toString();
  };

  useEffect(() => {
    setFormValues({
      ...securityDetails,
      assetClass: arrayToString(securityDetails?.assetClasses || []),
      expenseIcr: securityDetails?.securityIcr ? securityDetails?.securityIcr.toString() : '0',
    });
  }, [securityDetails]);

  const readonly = true;

  const [formValues, setFormValues] = useState<FormValues>(initialFormValues);

  return (
    <Container style={{ paddingTop: '20px', paddingBottom: '20px' }}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <CardComponent
            {...props}
            progress={consolidatedSecurityHoldingsSummaryProgress}
            background={theme.palette.gradient1?.main}
            value={formatDollars(consolidatedSecurityHoldingsSummary?.fua)}
            subText={'FUA'}
          />
        </Grid>
        <Grid item xs={6}>
          <CardComponent
            {...props}
            progress={consolidatedSecurityHoldingsSummaryProgress}
            background={theme.palette.gradient2?.main}
            value={formatNumberCommaSeparated(consolidatedSecurityHoldingsSummary?.portfolioCount ?? 0)}
            subText={'Portfolios'}
          />
        </Grid>
      </Grid>

      <Formik<FormValues>
        initialValues={formValues}
        enableReinitialize={true}
        onSubmit={() => {
          return;
        }}
      >
        <Form>
          <LoadingIndicator progress={securityDetailsProgress}>
            <Card style={{ marginTop: '25px' }} elevation={0}>
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={6} container>
                    <Grid item xs={12}>
                      <Field disabled={readonly} component={FormikTextField} name={'code'} label="SECURITY CODE" fullWidth />
                    </Grid>
                    <Grid item xs={12} style={{ marginTop: '20px' }}>
                      <Field disabled={readonly} component={FormikTextField} name={'name'} label="SECURITY NAME" fullWidth />
                    </Grid>
                    <Grid item xs={12} style={{ marginTop: '20px' }}>
                      <Field disabled={readonly} component={FormikTextField} name={'isin'} label="ISIN" fullWidth />
                    </Grid>
                    <Grid item xs={12} style={{ marginTop: '20px' }}>
                      <Field disabled={readonly} component={FormikTextField} name={'gicsSector'} label="GICS SECTOR" fullWidth />
                    </Grid>
                    <Grid item xs={12} style={{ marginTop: '20px' }}>
                      <Field disabled={readonly} component={FormikTextField} name={'gicsSubGroup'} label="GICS SUB GROUP" fullWidth />
                    </Grid>
                  </Grid>
                  <Grid item xs={6} container>
                    <Grid item xs={12}>
                      <Field disabled={readonly} component={FormikTextField} name={'market'} label="MARKET" fullWidth />
                    </Grid>
                    <Grid item xs={12} style={{ marginTop: '20px' }}>
                      <Field disabled={readonly} component={FormikTextField} name={'currency'} label="CURRENCY" fullWidth />
                    </Grid>
                    <Grid item xs={12} style={{ marginTop: '20px' }}>
                      <Field disabled={readonly} component={FormikTextField} name={'expenseIcr'} label="EXPENSE (ICR)" fullWidth />
                    </Grid>
                    <Grid item xs={12} style={{ marginTop: '20px' }}>
                      <Field disabled={readonly} component={FormikTextField} name={'assetClass'} label="ASSET CLASS" fullWidth />
                    </Grid>
                    <Grid item xs={12} style={{ marginTop: '20px' }}>
                      <Field disabled={readonly} component={FormikTextField} name={'trustType'} label="TRUST TYPE" fullWidth />
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>

            <Card style={{ marginTop: '25px' }} elevation={0}>
              <CardContent>
                <Grid container>
                  <Field
                    disabled={readonly}
                    component={FormikTextField}
                    name={'description'}
                    label="DESCRIPTION"
                    style={{ marginTop: '20px' }}
                    fullWidth
                    rows="3"
                    multiline
                  />
                </Grid>
              </CardContent>
            </Card>
          </LoadingIndicator>
        </Form>
      </Formik>
    </Container>
  );
};
