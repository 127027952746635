import { Cancel as CancelIcon, Search as SearchIcon } from '@mui/icons-material';
import { FormControl, InputLabel, MenuItem } from '@mui/material';
import React from 'react';
import { GroupedByKey, RebalanceKey } from '../store/types';
import { WO2Select, WO2TextInput } from './../../../../../common';
import WO2Button from './../../../../../common/components/button/Button';

interface Props {
  groupedByKey: GroupedByKey;
  fetchRebalance: (payload: RebalanceKey) => void;
  setGroupedBy: (payload: GroupedByKey) => void;
  setFilter: (payload: string) => void;
  setClearAll: () => void;
  filter: string;
  rebalanceKey: RebalanceKey;
}

export default function Filters(props: Props): JSX.Element {
  const { groupedByKey, setGroupedBy, setFilter, setClearAll, fetchRebalance, filter, rebalanceKey } = props;

  return (
    <>
      <FormControl variant="outlined" style={{ marginRight: '10px', width: '134px' }}>
        <InputLabel>GROUP BY</InputLabel>
        <WO2Select
          value={groupedByKey}
          onChange={(event) => {
            event.target.value && event.target.value !== groupedByKey && setGroupedBy(event.target.value as GroupedByKey);
          }}
          label={'GROUP BY'}
        >
          <MenuItem key={GroupedByKey.AssetClass} value={GroupedByKey.AssetClass}>
            {'Asset Class'}
          </MenuItem>
          <MenuItem key={GroupedByKey.Model} value={GroupedByKey.Model}>
            {'Model'}
          </MenuItem>
        </WO2Select>
      </FormControl>

      <WO2TextInput
        style={{ marginRight: '10px' }}
        placeholder={'Filter Security'}
        inputProps={{ 'aria-label': 'search' }}
        value={filter}
        onChange={(event) => {
          setFilter(event.target.value);
        }}
        InputProps={{ startAdornment: <SearchIcon style={{ margin: '0 10px' }}>search</SearchIcon> }}
      />

      <WO2Button
        style={{ marginRight: '10px' }}
        variant={'outlined'}
        color={'primary'}
        onClick={setClearAll}
        value="Clear all"
        endIcon={<CancelIcon>clear</CancelIcon>}
      >
        Clear all
      </WO2Button>

      <WO2Button
        style={{ marginRight: '10px' }}
        variant={'contained'}
        disableElevation
        onClick={() => {
          fetchRebalance(rebalanceKey);
        }}
        color={'primary'}
        value="Reset rebalance"
      >
        Reset rebalance
      </WO2Button>
    </>
  );
}
