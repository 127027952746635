import { createAsyncThunk } from '@reduxjs/toolkit';
import { deleteRebalanceService, fetchRebalanceService, fetchSecuritySearchService, fetchSecurityService, validateRebalanceService } from './services';
import {
  FetchSecurityRequestPayload,
  FetchSecuritySearchRequestPayload,
  Rebalance,
  RebalanceDeletionRequest,
  RebalanceDeletionRequestPayload,
  RebalanceKey,
  RebalanceValidationRequest,
  Security,
  SecuritySearchResults,
  ValidateRebalanceSuccessPayload,
  ValidationResult,
} from './types';

export enum RebalanceEditActionTypes {
  DeleteRebalance = '@@rebalance/edit/deleteRebalance',
  ValidateRebalance = '@@rebalance/edit/validateRebalance',
  FetchSecurities = '@@rebalance/edit/fetchSecurities',
  FetchRebalance = '@@rebalance/edit/fetchRebalance',
  FetchSecurity = '@@rebalance/edit/fetchSecurity',
}

export const fetchRebalance = createAsyncThunk<Rebalance, RebalanceKey, { rejectValue: ValidationResult }>(
  RebalanceEditActionTypes.FetchRebalance,
  async (rebalanceKey: RebalanceKey) => {
    return await fetchRebalanceService(rebalanceKey.investmentServiceId, rebalanceKey.rebalanceId);
  }
);

export const validateRebalance = createAsyncThunk<ValidateRebalanceSuccessPayload, RebalanceValidationRequest, { rejectValue: ValidationResult }>(
  RebalanceEditActionTypes.ValidateRebalance,
  async (payload: RebalanceValidationRequest, thunkApi) => {
    try {
      const result = await validateRebalanceService(payload);
      return result.validationResult &&
        ((result.validationResult.errors && result.validationResult.errors.length > 0) ||
          (result.validationResult.warnings && result.validationResult.warnings.length > 0))
        ? thunkApi.rejectWithValue(result.validationResult)
        : result;
    } catch (e) {
      const result: ValidationResult = { errors: [`${e.status} - ${e.statusText}`], warnings: [], information: [] };
      return thunkApi.rejectWithValue(result);
    }
  }
);

export const fetchSecurity = createAsyncThunk<Security, FetchSecurityRequestPayload, { rejectValue: ValidationResult }>(
  RebalanceEditActionTypes.FetchSecurity,
  async (payload: FetchSecurityRequestPayload, thunkApi) => {
    try {
      return await fetchSecurityService(payload.afslId, payload.securityId);
    } catch (e) {
      const result: ValidationResult = { errors: [`${e.status} - ${e.statusText}`], warnings: [], information: [] };
      return thunkApi.rejectWithValue(result);
    }
  }
);

export const fetchSecurities = createAsyncThunk<SecuritySearchResults, FetchSecuritySearchRequestPayload, { rejectValue: ValidationResult }>(
  RebalanceEditActionTypes.FetchSecurities,
  async (payload: FetchSecuritySearchRequestPayload, thunkApi) => {
    try {
      return await fetchSecuritySearchService(payload.afslId, payload.search);
    } catch (e) {
      const result: ValidationResult = { errors: [`${e.status} - ${e.statusText}`], warnings: [], information: [] };
      return thunkApi.rejectWithValue(result);
    }
  }
);

export const deleteRebalance = createAsyncThunk<void, RebalanceDeletionRequestPayload, { rejectValue: ValidationResult }>(
  RebalanceEditActionTypes.DeleteRebalance,
  async (payload: RebalanceDeletionRequestPayload, thunkApi) => {
    const rebalanceDeletionRequest: RebalanceDeletionRequest = {
      investmentServiceRebalanceIds: [payload.investmentServiceRebalanceId],
      rebalanceId: payload.rebalanceId,
    };
    try {
      return await deleteRebalanceService(rebalanceDeletionRequest);
    } catch (e) {
      const result: ValidationResult = { errors: [`${e.status} - ${e.statusText}`], warnings: [], information: [] };
      return thunkApi.rejectWithValue(result);
    }
  }
);
