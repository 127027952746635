import { createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../../../../../app/api';
import { buildEncodedQueryString } from '../../../../../../common';
import { SuperSimplifierBeneficiary } from './types';

export enum SuperSimplifierActionTypes {
  FetchSuperSimplifierBeneficiaries = '@@client/details/supersimplifier/FetchSuperSimplifierBeneficiaries',
}

export enum SuperSimplifierApiEndpoints {
  FetchSuperSimplifierBeneficiaries = '/customers/GetSuperSimplifierMemberBeneficiaries',
}

export const fetchSuperSimplifierBeneficiaries = createAsyncThunk(SuperSimplifierActionTypes.FetchSuperSimplifierBeneficiaries, async (clientId: number) => {
  const queryString = buildEncodedQueryString({
    clientId,
  });
  const response = await api.get<SuperSimplifierBeneficiary[]>(`${SuperSimplifierApiEndpoints.FetchSuperSimplifierBeneficiaries}${queryString}`);
  return response.data;
});
