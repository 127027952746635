import { connect, ConnectedProps } from 'react-redux';
import { hasErrorsSelectorFactory, isLoadingSelectorFactory } from '../../../../../common/store/selectors';
import { RootState } from '../../../../../reducers';
import { selectClientId } from '../../common/store/selectors';
import { selectAssetGroupResults, selectHoldingItems, selectParameters, selectSelectedHoldingItems } from '../store/selectors';
import { dashboardSlice } from '../store/slice';
import { DashboardActionTypes, fetchClientPortfolioDetails } from '../store/thunks';
import { ClientPortfolioDetailsComponent } from './ClientPortfolioDetailsComponent';

const actionTypes = [DashboardActionTypes.FetchClientPortfolioDetails];

const mapStateToProps = (state: RootState) => ({
  isLoading: isLoadingSelectorFactory(actionTypes)(state),
  hasErrors: hasErrorsSelectorFactory(actionTypes)(state),
  clientId: selectClientId(state),
  parameters: selectParameters(state),
  assetGroups: selectAssetGroupResults(state),
  holdings: selectHoldingItems(state),
  selectSelectedHoldingItems: selectSelectedHoldingItems(state),
});

const mapDispatchToProps = {
  fetchClientPortfolioDetails,
  setSelectedHolding: dashboardSlice.actions.setSelectedHolding,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export type Props = ConnectedProps<typeof connector>;
export const ClientPortfolioDetailsContainer = connect(mapStateToProps, mapDispatchToProps)(ClientPortfolioDetailsComponent);
