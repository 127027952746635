import * as Sentry from '@sentry/react';
import React from 'react';
import { Provider } from 'react-redux';
import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { loadUser, OidcProvider } from 'redux-oidc';
import reduxPromise from 'redux-promise';
import createSagaMiddleware from 'redux-saga';
import ReduxThunk from 'redux-thunk';
import { featureFlagsSaga } from './common';
import FeatureFlagProvider from './common/components/featureFlags/provider';
import gainsLosesSagas from './features/clients/client/gainslosses/store/sagas';
import { importAllocationsSaga } from './features/models/composition/store/sagas/allocationsImporterSaga';
import { importTradesSaga } from './features/orders/rebalance/edit/store/sagas/importSaga';
import reducers, { RootState } from './store/reducer';
import { userManager } from './userManager';

const configureStore = ({ children, initialState }: { children: JSX.Element; initialState?: RootState }): JSX.Element => {
  const sagaMiddleware = createSagaMiddleware();

  const sentryReduxEnhancer = Sentry.createReduxEnhancer({
    // Optionally pass options
  });

  const store = createStore(reducers, initialState, composeWithDevTools(applyMiddleware(sagaMiddleware, ReduxThunk, reduxPromise), sentryReduxEnhancer));
  sagaMiddleware.run(importTradesSaga);
  sagaMiddleware.run(featureFlagsSaga);
  sagaMiddleware.run(gainsLosesSagas);
  sagaMiddleware.run(importAllocationsSaga);

  loadUser(store, userManager);

  return (
    <Provider store={store}>
      <OidcProvider userManager={userManager} store={store}>
        <FeatureFlagProvider>{children}</FeatureFlagProvider>
      </OidcProvider>
    </Provider>
  );
};

export default configureStore;
