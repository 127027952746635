import { User } from 'oidc-client';
import React, { Component, Dispatch, MouseEventHandler } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { AnyAction } from 'redux';
import { RootState } from 'src/reducers';
import { userManager } from 'src/userManager';

interface Props {
  user: User | undefined | null;
}

class Header extends Component<Props> {
  logOut: MouseEventHandler<HTMLButtonElement> | undefined;
  constructor(props: Props) {
    super(props);

    this.logOut = (e) => {
      e.preventDefault();
      if (props.user) {
        userManager.signoutRedirect({ id_token_hint: props.user.id_token });
      } else {
        userManager.signoutRedirect();
      }
      userManager.removeUser();
    };
  }

  render() {
    const someStyle = { padding: '10px' };

    return (
      <div>
        <div>
          <Link to="/client/644/dashboard" className="item" style={someStyle}>
            [Client]
          </Link>
          <Link to="/client/8418/dashboard" className="item" style={someStyle}>
            [Super Simplifier Client]
          </Link>
          <Link to="/rebalance?investmentServiceId=79&rebalanceId=5ea901f78e0f820001caa58e" className="item" style={someStyle}>
            [Rebalance]
          </Link>
          <button onClick={this.logOut} style={someStyle}>
            Logout
          </button>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state: RootState) {
  return {
    user: state.oidc.user,
  };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>) {
  return { dispatch };
}

export default connect(mapStateToProps, mapDispatchToProps)(Header);
