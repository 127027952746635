import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AdviserItem, AfslItem, ModelItem, PracticeItem, SecurityItem, ServiceType } from '../../../common/store/types';
import { fetchResults, fetchSecurities, fetchModels } from './thunks';
import { OrderSearchResult, OrderSearchState, SecurityHeld } from './types';

export const initialState: OrderSearchState = {
  parameters: {
    afsls: [],
    practices: [],
    advisers: [],
    serviceType: ServiceType.Mda.name,
    clientTypes: [],
    portfolioValueFrom: null,
    portfolioValueTo: null,
    availableCashBalanceFrom: null,
    availableCashBalanceTo: null,
    securityHeld: SecurityHeld.Held.name,
    security: null,
    securitySearch: null,
    model: null,
  },
  securities: [],
  models: [],
  results: [],
  selectedResults: [],
};

export const searchSlice = createSlice({
  name: '@@bulk/order/search',
  initialState,
  reducers: {
    setAfslParameter: (state, action: PayloadAction<AfslItem[]>) => {
      state.parameters.afsls = action.payload;
    },
    setPracticeParameter: (state, action: PayloadAction<PracticeItem[]>) => {
      state.parameters.practices = action.payload;
    },
    setAdviserParameter: (state, action: PayloadAction<AdviserItem[]>) => {
      state.parameters.advisers = action.payload;
    },
    setClientTypesParameter: (state, action: PayloadAction<string[]>) => {
      state.parameters.clientTypes = action.payload;
    },
    setServiceTypeParameter: (state, action: PayloadAction<string>) => {
      state.parameters.serviceType = action.payload;
    },
    setPortfolioValueFrom: (state, action: PayloadAction<number | null>) => {
      state.parameters.portfolioValueFrom = action.payload;
    },
    setPortfolioValueTo: (state, action: PayloadAction<number | null>) => {
      state.parameters.portfolioValueTo = action.payload;
    },
    setAvailableCashBalanceFrom: (state, action: PayloadAction<number | null>) => {
      state.parameters.availableCashBalanceFrom = action.payload;
    },
    setAvailableCashBalanceTo: (state, action: PayloadAction<number | null>) => {
      state.parameters.availableCashBalanceTo = action.payload;
    },
    setSecurityHeld: (state, action: PayloadAction<string>) => {
      state.parameters.securityHeld = action.payload;
    },
    setSecurity: (state, action: PayloadAction<SecurityItem | null>) => {
      state.parameters.security = action.payload;
    },
    setSecuritySearch: (state, action: PayloadAction<string | null>) => {
      state.parameters.securitySearch = action.payload;
    },
    setModel: (state, action: PayloadAction<ModelItem | null>) => {
      state.parameters.model = action.payload;
    },
    setSelectedResults: (state, action: PayloadAction<number[]>) => {
      state.selectedResults = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchResults.fulfilled, (state, action: PayloadAction<OrderSearchResult[]>) => {
      state.results = action.payload;
    });

    builder.addCase(fetchSecurities.fulfilled, (state, action: PayloadAction<SecurityItem[]>) => {
      state.securities = action.payload;
    });
    builder.addCase(fetchModels.pending, (state) => {
      state.models = [];
    });

    builder.addCase(fetchModels.fulfilled, (state, action: PayloadAction<ModelItem[]>) => {
      state.models = action.payload;
    });
  },
});
