import { Enumeration } from '../../../../../../common/types/enumeration';

export enum ServiceType {
  IMA = 'Individually Managed Account (IMA)',
  MDA = 'Managed Discretionary Account (MDA)',
  Personal = 'Personal',
  StatementOfAdvice = 'Statement of Advice (SOA)',
  Unlisted = 'Unlisted',
  Wholesale = 'Wholesale',
}

export class RebalanceTypeEnum extends Enumeration {
  static Monthly = new RebalanceTypeEnum(1, 'Monthly', 'Monthly');
  static Quarterly = new RebalanceTypeEnum(2, 'Quarterly', 'Quarterly');
  static BiYearly = new RebalanceTypeEnum(3, 'BiYearly', 'BiYearly');
  static Annual = new RebalanceTypeEnum(4, 'Annual', 'Annual');

  static getById = (status: number): RebalanceTypeEnum | undefined => {
    return Object.values(RebalanceTypeEnum)
      .filter((t) => t instanceof RebalanceTypeEnum)
      .find((t: RebalanceTypeEnum) => t.id === status);
  };

  static getByName = (name: string): RebalanceTypeEnum | undefined => {
    return Object.values(RebalanceTypeEnum)
      .filter((t) => t instanceof RebalanceTypeEnum)
      .find((t: RebalanceTypeEnum) => t.name.toLowerCase() === name.toLowerCase());
  };
}

export class SecurityTypeEnum extends Enumeration {
  static Security = new SecurityTypeEnum(1, 'Security', 'Security');
  static Model = new SecurityTypeEnum(2, 'Model', 'Model');

  static getById = (status: number): SecurityTypeEnum | undefined => {
    return Object.values(SecurityTypeEnum)
      .filter((t) => t instanceof SecurityTypeEnum)
      .find((t: SecurityTypeEnum) => t.id === status);
  };

  static getByName = (name: string): SecurityTypeEnum | undefined => {
    return Object.values(SecurityTypeEnum)
      .filter((t) => t instanceof SecurityTypeEnum)
      .find((t: SecurityTypeEnum) => t.name.toLowerCase() === name.toLowerCase());
  };
}
