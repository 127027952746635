import { connect, ConnectedProps } from 'react-redux';
import { progressSelectorFactory } from '../../../common';
import { RootState } from '../../../reducers';
import { selectCommonModelVersionId } from '../common/store/selectors';
import { DetailsComponent } from './components/DetailsComponent';
import { selectModelDetails } from './store/selectors';
import { modelDetailsSlice } from './store/slice';
import { fetchModelDetails, ModelDetailsActionTypes } from './store/thunks';

const actionTypes = [ModelDetailsActionTypes.FetchModelDetails];

const mapStateToProps = (state: RootState) => ({
  modelVersionId: selectCommonModelVersionId(state),
  modelDetails: selectModelDetails(state),
  loadingProgress: progressSelectorFactory(actionTypes)(state),
});

const mapDispatchToProps = {
  ...modelDetailsSlice.actions,
  fetchModelDetails,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export type Props = ConnectedProps<typeof connector>;
export const ModelDetailsStateContainer = connect(mapStateToProps, mapDispatchToProps)(DetailsComponent);
