import { createAsyncThunk } from '@reduxjs/toolkit';
import { FetchConstraintsPayload, SecurityConstraint, UpdateConstraintsPayload } from '.';
import api from '../../../../../../../app/api';
import { buildEncodedQueryString } from '../../../../../../../common';
import { commonSlice, fetchInvestmentServiceVersionsByClient } from '../../../store/common';

export enum ConstraintsActionTypes {
  FetchInvestmentServiceConstraints = '@@client/investmentServices/constraints/Fetch',
  UpdateInvestmentServiceConstraints = '@@client/investmentServices/constraints/Update',
}

export enum ConstraintsApiEndpoints {
  FetchInvestmentServiceConstraints = '/investmentservices/GetInvestmentServiceConstraints',
  UpdateInvestmentServiceConstraints = '/investmentservices/CreateOrUpdateSecurityConstraints',
}

export const fetchConstraints = createAsyncThunk(
  ConstraintsActionTypes.FetchInvestmentServiceConstraints,
  async ({ investmentServiceVersionId, clientId }: FetchConstraintsPayload) => {
    const queryString = buildEncodedQueryString({
      clientId,
      investmentServiceVersionId,
    });
    const response = await api.get<SecurityConstraint[]>(`${ConstraintsApiEndpoints.FetchInvestmentServiceConstraints}${queryString}`);
    return response.data;
  }
);

export const saveConstraints = createAsyncThunk(
  ConstraintsActionTypes.UpdateInvestmentServiceConstraints,
  async (payload: UpdateConstraintsPayload, thunkApi) => {
    const response = await api.post<number>(`${ConstraintsApiEndpoints.UpdateInvestmentServiceConstraints}`, payload);

    thunkApi.dispatch(commonSlice.actions.setInvestmentServiceVersionId(response.data));

    await thunkApi.dispatch(fetchConstraints({ investmentServiceVersionId: payload.investmentServiceVersionId, clientId: payload.clientId }));

    await thunkApi.dispatch(
      fetchInvestmentServiceVersionsByClient({
        clientId: payload.clientId,
        showLatestVersionOnly: false,
      })
    );

    return { message: 'Constraints saved' };
  }
);
