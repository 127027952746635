const dollarFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 2,
});

export const formatDollars = (value?: number | null, absoluteValue = false): string => {
  const formatter = dollarFormatter;
  if (value === null || value === undefined) {
    return formatter.format(0);
  }

  // fix -0.00 to become 0.00 by adding 0
  value = +value.toFixed(2);
  value += 0;

  const displayValue = value < 0 && absoluteValue ? value * -1 : value;
  return formatter.format(displayValue);
};

export const formatDollarsScaled = (value?: number | null): string => {
  let result = '$0.00';
  let scaledValue = 0;
  let suffix = '';
  if (value) {
    if (Math.abs(value) >= 1000000) {
      scaledValue = value / 1000000; //.toFixed(1);
      suffix = 'm';
    } else if (Math.abs(value) >= 1000) {
      scaledValue = value / 1000; //.toFixed(1);
      suffix = 'k';
    } else {
      scaledValue = value; //.toFixed(1);
    }

    result = formatDollars(scaledValue) + suffix;
  }

  return result;
};
