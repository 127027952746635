import InfoIcon from '@mui/icons-material/Info';
import { Box, Card, CardContent, Container, Fade, Grid, Paper, Tooltip, Typography } from '@mui/material';
import { Field, FieldProps, Form, Formik, FormikProps } from 'formik';
import { DateTime } from 'luxon';
import React, { useEffect, useState } from 'react';
import { FormikKeyboardDatePicker } from 'src/common/components/formik';
import { LoadingProgress } from 'src/common/store/types';
import { DateTimeFormat, getLocalDateTime } from 'src/common/utils';
import * as yup from 'yup';
import { ToggleButtonItem } from '../../../../../../common';
import { EditCancelSaveButtons, FormikNumberFormat, FormikSwitch, FormikTextField, FormikToggleButton, Mode } from '../../../../../../common/components/formik';
import { ClientAccountSubType, PensionType } from '../../../../../../common/types';
import { theme } from '../../../../../../themes';
import { inSpecieLabelText, rolloverAmountFieldText, rolloverAmountTooltipText } from '../../../../common/config/superSimplifier';
import { RolloverType } from '../../../../common/enums';
import { PaymentFrequency, PaymentLimitType, PaymentType } from '../../../common/store/enums';
import { SuperSimplifierDetails } from '../../../common/store/types';
import { Status } from '../../mainDetails/store';
import { SuperSimplifierBeneficiary } from './../store/types';
import { Beneficiaries } from './beneficiaries';

interface FormValues extends Partial<SuperSimplifierDetails> {
  rollover: boolean;
  additionalRollover: boolean;
  additionalRolloverSecond: boolean;
}

export interface Props {
  details: SuperSimplifierDetails;
  hasClientEditPermission: boolean;
  hasClientEditAdminOnlyPermission: boolean;
  readonly: boolean;
  savingProgress: LoadingProgress;
  loadingProgressBeneficiaries: LoadingProgress;
  beneficiaries: SuperSimplifierBeneficiary[];
  status: number | undefined;
  onSave: (details: SuperSimplifierDetails) => void;
  onToggleEditMode: () => void;
}

export const DetailsForm = ({
  details,
  hasClientEditPermission,
  hasClientEditAdminOnlyPermission,
  readonly,
  savingProgress,
  loadingProgressBeneficiaries,
  beneficiaries,
  onSave,
  onToggleEditMode,
  status,
}: Props): JSX.Element => {
  const rolloverToggleButtons: ToggleButtonItem<boolean>[] = RolloverType.getArray().map((rollover: RolloverType) => {
    return { name: rollover.displayName, value: rollover.name === RolloverType.FullRollover.name };
  });

  const paymentFrequencyToggleButtons: ToggleButtonItem<number>[] = PaymentFrequency.getArray().map((paymentFrequency: PaymentFrequency) => {
    return { name: paymentFrequency.displayName, value: paymentFrequency.id };
  });

  const paymentLimitTypeToggleButtons: ToggleButtonItem<number>[] = PaymentLimitType.getArray().map((paymentLimit: PaymentLimitType) => {
    return { name: paymentLimit.displayName, value: paymentLimit.id };
  });

  const paymentTypeToggleButtons: ToggleButtonItem<number>[] = PaymentType.getArray().map((paymentType: PaymentType) => {
    return { name: paymentType.displayName, value: paymentType.id };
  });

  const pensionTypeToggleButtons: ToggleButtonItem<number>[] = PensionType.getArray().map((pensionType: PensionType) => {
    return { name: pensionType.displayName, value: pensionType.id };
  });

  const initialFormValues: FormValues = {
    rollover: true,
    additionalRollover: false,
    additionalRolloverSecond: false,
  };

  const [formValues, setFormValues] = useState<FormValues>(initialFormValues);

  useEffect(() => {
    setFormValues({
      ...details,
      ageOver65: details.ageOver65 ?? false,
      ageOver60NotPaid: details.ageOver60NotPaid ?? false,
      ageNotOver60NotPaid: details.ageNotOver60NotPaid ?? false,
      atp: details.atp ?? false,
      reachedAge: details.reachedAge ?? false,
      resident: details.resident ?? false,
      paymentTypeId: details.paymentTypeId ?? PaymentType.Neither.id,
      paymentLimitTypeId: details.paymentLimitTypeId || PaymentLimitType.Other.id,
      inSpecie: details.inSpecie ?? false,
      inSpecieSecond: details.inSpecieSecond ?? false,
      inSpecieThird: details.inSpecieThird ?? false,
      rollover: !!details.fundName,
      additionalRollover: !!details.fundNameSecond,
      additionalRolloverSecond: !!details.fundNameSecond && !!details.fundNameThird,
      contributionsAmount: details.subAccountTypeId === ClientAccountSubType.Pension.id ? null : details.contributionsAmount,
    });
  }, [details]);

  return (
    <Formik<FormValues>
      initialValues={formValues}
      validationSchema={yup.object({
        subAccountTypeId: yup.number(),
        rollover: yup.boolean(),
        additionalRollover: yup.boolean(),
        additionalRolloverSecond: yup.boolean(),
        feeAnniversaryDate: hasClientEditAdminOnlyPermission
          ? yup.date().nullable().typeError('Fee anniversary date must be a valid date')
          : yup.date().nullable(),
        contributionsAmount: yup.number().nullable().typeError('ESTIMATED CONTRIBUTIONS PER ANNUM must be a number'),
        fullRollover: yup.boolean(),
        fullRolloverSecond: yup.boolean(),
        fullRolloverThird: yup.boolean(),
        fundName: yup
          .string()
          .nullable()
          .when('rollover', { is: true, then: yup.string().required('FUNDNAME is required') }),
        fundNameSecond: yup
          .string()
          .nullable()
          .when('additionalRollover', { is: true, then: yup.string().required('FUNDNAME is required') }),
        fundNameThird: yup
          .string()
          .nullable()
          .when('additionalRolloverSecond', { is: true, then: yup.string().required('FUNDNAME is required') }),
        rolloverAmount: yup
          .number()
          .nullable()
          .when('rollover', { is: true, then: yup.number().required('ROLLOVER AMOUNT is required') }),
        rolloverAmountSecond: yup
          .number()
          .typeError('ROLLOVER AMOUNT must be a number')
          .nullable()
          .when('additionalRollover', {
            is: (additionalRollover) => additionalRollover,
            then: yup.number().typeError('ROLLOVER AMOUNT must be a number').required('ROLLOVER AMOUNT is required'),
          }),
        rolloverAmountThird: yup
          .number()
          .typeError('ROLLOVER AMOUNT must be a number')
          .nullable()
          .when('additionalRolloverSecond', {
            is: (additionalRolloverSecond) => additionalRolloverSecond,
            then: yup.number().typeError('ROLLOVER AMOUNT must be a number').required('ROLLOVER AMOUNT is required'),
          }),
        pensionTypeId: yup
          .number()
          .nullable()
          .when('subAccountTypeId', { is: ClientAccountSubType.Pension.id, then: yup.number().required('PENSION TYPE is required') }),
        paymentFrequencyId: yup
          .number()
          .nullable()
          .when('subAccountTypeId', { is: ClientAccountSubType.Pension.id, then: yup.number().required('PAYMENT FREQUENCY is required') }),
        paymentLimitTypeId: yup
          .number()
          .nullable()
          .when('subAccountTypeId', { is: ClientAccountSubType.Pension.id, then: yup.number().required('PAYMENT LIMIT TYPE is required') }),
        paymentLimit: yup
          .number()
          .nullable()
          .when(['subAccountTypeId', 'paymentLimitTypeId'], {
            is: (subType, paymentLimitTypeId) => subType === ClientAccountSubType.Pension.id && paymentLimitTypeId === PaymentLimitType.Other.id,
            then: yup.number().required('AMOUNT is required'),
          }),
      })}
      onSubmit={async (values: FormValues) => {
        const detailsValues = {
          // remove additionalRollover/additionalRolloverSecond from form values
          ...Object.entries(values).reduce(
            (results: SuperSimplifierDetails, [key, value]) => {
              let fieldValue = value;
              // convert value
              if (key === 'feeAnniversaryDate') {
                fieldValue = !!values.feeAnniversaryDate ? DateTime.fromISO(value).toISODate() : null;
              } else if (values.subAccountTypeId === ClientAccountSubType.Pension.id && key === 'paymentLimit') {
                fieldValue = values.paymentLimitTypeId === PaymentLimitType.Other.id ? value : null;
              } else if (key === 'paymentTypeId' && values.paymentTypeId === PaymentType.Neither.id) {
                fieldValue = null;
              }

              return Object.keys(details).indexOf(key) === -1
                ? results
                : {
                    ...results,
                    [key]: fieldValue,
                  };
            },
            { ...details }
          ),
          ...(!values.rollover && {
            fundName: '',
            memberNumber: '',
            superAnnuationId: '',
            rolloverAmount: null,
            fullRollover: false,
            inSpecie: false,
          }),
          ...(!values.additionalRollover && {
            fundNameSecond: '',
            memberNumberSecond: '',
            superAnnuationIdSecond: '',
            rolloverAmountSecond: null,
            fullRolloverSecond: false,
            inSpecieSecond: false,
          }),
          ...(!values.additionalRolloverSecond && {
            fundNameThird: '',
            memberNumberThird: '',
            superAnnuationIdThird: '',
            rolloverAmountThird: null,
            fullRolloverThird: false,
            inSpecieThird: false,
          }),
        };

        onSave(detailsValues);
      }}
      enableReinitialize={true}
    >
      {(formikProps: FormikProps<FormValues>) => (
        <Form>
          <fieldset style={{ border: 'none', padding: '0' }}>
            <Box style={{ pointerEvents: readonly ? 'none' : 'auto' }}>
              <Box paddingTop={'30px'} paddingBottom={'10px'}>
                <Typography variant="h4" gutterBottom>
                  Fee Anniversary Date
                </Typography>
                {hasClientEditAdminOnlyPermission ? (
                  <Paper elevation={5} style={{ padding: '30px', paddingLeft: '10px' }}>
                    <Field
                      component={FormikKeyboardDatePicker}
                      disabled={readonly}
                      variant="inline"
                      name="feeAnniversaryDate"
                      label="FEE ANNIVERSARY DATE"
                      style={{ width: '280px' }}
                      fullWidth
                    />
                  </Paper>
                ) : (
                  <Card variant="outlined" style={{ width: '280px', marginBottom: '10px', textAlign: 'center' }} data-testid="feeAnniversaryDate">
                    <CardContent>
                      <Typography color="textSecondary" gutterBottom>
                        FEE ANNIVERSARY DATE
                      </Typography>
                      <Paper elevation={5} style={{ marginBottom: '20px', padding: '10px' }}>
                        <Typography variant="h5">
                          {!!formikProps.values.feeAnniversaryDate
                            ? getLocalDateTime(formikProps.values.feeAnniversaryDate ?? undefined, DateTimeFormat.Short)
                            : 'None'}
                        </Typography>
                      </Paper>
                    </CardContent>
                  </Card>
                )}
              </Box>
              <Paper elevation={0} style={{ marginBottom: '20px' }}>
                <Typography variant="h4" gutterBottom>
                  Listed Beneficiaries
                </Typography>
                <Beneficiaries beneficiaries={beneficiaries} loadingProgress={loadingProgressBeneficiaries}></Beneficiaries>
              </Paper>
              <Box paddingTop={'20px'} paddingBottom={'10px'}>
                <Typography variant="h4" gutterBottom>
                  Estimated Contributions
                </Typography>
                <Paper elevation={5} style={{ padding: '30px', paddingLeft: '10px' }}>
                  <Field name="contributionsAmount" label="ESTIMATED CONTRIBUTIONS" fullWidth>
                    {(fieldProps: FieldProps) => {
                      return (
                        <FormikNumberFormat
                          formikFieldProps={fieldProps}
                          numberFormatProps={{
                            placeholder: '$0.00',
                            isNumericString: true,
                            allowNegative: false,
                            decimalScale: 2,
                            thousandSeparator: true,
                            prefix: '$',
                            name: fieldProps.field.name,
                            label: 'ESTIMATED CONTRIBUTIONS',
                            disabled: readonly,
                          }}
                          isFloatValue={true}
                          fullWidth={true}
                        />
                      );
                    }}
                  </Field>
                </Paper>
              </Box>
              <Grid container marginTop={'20px'}>
                <Field
                  disabled={readonly || formikProps.values.additionalRollover || formikProps.values.additionalRolloverSecond}
                  name={'rollover'}
                  component={FormikSwitch}
                  label="Add rollover details"
                ></Field>
              </Grid>
              {formikProps.values.rollover && (
                <Box>
                  <Typography variant="h4" style={{ padding: '20px 0' }}>
                    Rollover details
                  </Typography>
                  <Paper elevation={5} style={{ padding: '20px' }}>
                    <Grid container>
                      <Grid item xs={12} style={{ margin: '0px 10px' }}>
                        <Field
                          exclusive={true}
                          component={FormikToggleButton}
                          buttons={rolloverToggleButtons}
                          formik={formikProps}
                          name={'fullRollover'}
                          style={{ minWidth: '380px', opacity: readonly ? 0.5 : 1 }}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Grid item xs={12} style={{ minHeight: '70px', margin: '20px 10px' }}>
                          <Field disabled={readonly} name="fundName" component={FormikTextField} label="FUND NAME" fullWidth></Field>
                        </Grid>
                        <Grid item xs={12} style={{ minHeight: '70px', margin: '20px 10px' }}>
                          <Field disabled={readonly} name={'superAnnuationId'} component={FormikTextField} label="UNIQUE SUPERANNUATION ID" fullWidth></Field>
                        </Grid>
                        <Grid item xs={12} style={{ margin: '20px 10px 10px' }}>
                          <Field
                            disabled={readonly || formikProps.values.additionalRolloverSecond}
                            name={'additionalRollover'}
                            component={FormikSwitch}
                            label="Additional Rollover"
                          ></Field>
                        </Grid>
                      </Grid>

                      <Grid item xs={6}>
                        <Grid item xs={12} style={{ minHeight: '70px', margin: '20px 10px' }}>
                          <Field disabled={readonly} name={'memberNumber'} component={FormikTextField} label="MEMBER NUMBER" fullWidth></Field>
                        </Grid>
                        <Grid item xs={12} style={{ minHeight: '70px', margin: '20px 10px' }}>
                          <Field name="rolloverAmount" label="* ROLLOVER AMOUNT ($)" fullWidth>
                            {(fieldProps: FieldProps) => {
                              return (
                                <FormikNumberFormat
                                  formikFieldProps={fieldProps}
                                  numberFormatProps={{
                                    placeholder: '',
                                    isNumericString: true,
                                    allowNegative: false,
                                    decimalScale: 2,
                                    thousandSeparator: true,
                                    name: fieldProps.field.name,
                                    label: 'ROLLOVER AMOUNT ($)',
                                    disabled: readonly,
                                  }}
                                  isFloatValue={true}
                                  showRequiredAsterisk={true}
                                  fullWidth={true}
                                />
                              );
                            }}
                          </Field>
                          <Container style={{ display: 'flex', alignItems: 'center', padding: '4px 0 0', opacity: readonly ? 0.5 : 1 }}>
                            <Typography variant="body1" style={{ fontSize: '14px', letterSpacing: 0, lineHeight: '16px' }}>
                              {rolloverAmountFieldText}
                            </Typography>
                            <Tooltip title={rolloverAmountTooltipText} arrow>
                              <InfoIcon style={{ width: '16px', height: '16px', fill: `${theme.palette.primary.main}` }} />
                            </Tooltip>
                          </Container>
                        </Grid>
                      </Grid>

                      <Grid item xs={12} style={{ margin: '0 10px' }}>
                        <Field disabled={readonly} name={'inSpecie'} component={FormikSwitch} label={inSpecieLabelText}></Field>
                      </Grid>
                    </Grid>
                  </Paper>
                </Box>
              )}
              {formikProps.values.additionalRollover && (
                <>
                  <Typography variant="h4" style={{ padding: '20px 0' }}>
                    Additional rollover from funds
                  </Typography>
                  <Paper elevation={5} style={{ padding: '20px' }}>
                    <Grid container>
                      <Grid item xs={12} style={{ margin: '0 10px' }}>
                        <Field
                          exclusive={true}
                          component={FormikToggleButton}
                          buttons={rolloverToggleButtons}
                          formik={formikProps}
                          name={'fullRolloverSecond'}
                          style={{ minWidth: '380px', opacity: readonly ? 0.5 : 1 }}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Grid item xs={12} style={{ minHeight: '70px', margin: '20px 10px' }}>
                          <Field disabled={readonly} name={'fundNameSecond'} component={FormikTextField} label="FUND NAME" fullWidth></Field>
                        </Grid>
                        <Grid item xs={12} style={{ minHeight: '70px', margin: '20px 10px' }}>
                          <Field
                            disabled={readonly}
                            name={'superAnnuationIdSecond'}
                            component={FormikTextField}
                            label="UNIQUE SUPERANNUATION ID"
                            fullWidth
                          ></Field>
                        </Grid>
                        <Grid item xs={12} style={{ margin: '20px 10px 10px' }}>
                          <Field disabled={readonly} name={'additionalRolloverSecond'} component={FormikSwitch} label="Additional Rollover"></Field>
                        </Grid>
                      </Grid>
                      <Grid item xs={6}>
                        <Grid item xs={12} style={{ minHeight: '70px', margin: '20px 10px' }}>
                          <Field disabled={readonly} name={'memberNumberSecond'} component={FormikTextField} label="MEMBER NUMBER" fullWidth></Field>
                        </Grid>
                        <Grid item xs={12} style={{ minHeight: '70px', margin: '20px 10px' }}>
                          <Field name="rolloverAmountSecond" label="* ROLLOVER AMOUNT ($)" fullWidth>
                            {(fieldProps: FieldProps) => {
                              return (
                                <FormikNumberFormat
                                  formikFieldProps={fieldProps}
                                  numberFormatProps={{
                                    placeholder: '',
                                    isNumericString: true,
                                    allowNegative: false,
                                    decimalScale: 2,
                                    thousandSeparator: true,
                                    name: fieldProps.field.name,
                                    label: 'ROLLOVER AMOUNT ($)',
                                    disabled: readonly,
                                  }}
                                  isFloatValue={true}
                                  showRequiredAsterisk={true}
                                  fullWidth={true}
                                />
                              );
                            }}
                          </Field>
                          <Container style={{ display: 'flex', alignItems: 'center', padding: '4px 0 0', opacity: readonly ? 0.5 : 1 }}>
                            <Typography variant="body1" style={{ fontSize: '14px', letterSpacing: 0, lineHeight: '16px' }}>
                              {rolloverAmountFieldText}
                            </Typography>
                            <Tooltip title={rolloverAmountTooltipText} arrow>
                              <InfoIcon style={{ width: '16px', height: '16px', fill: `${theme.palette.primary.main}` }} />
                            </Tooltip>
                          </Container>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} style={{ margin: '0 10px' }}>
                        <Field disabled={readonly} name={'inSpecieSecond'} component={FormikSwitch} label={inSpecieLabelText}></Field>
                      </Grid>
                    </Grid>
                  </Paper>
                </>
              )}
              {formikProps.values.additionalRolloverSecond && (
                <>
                  <Typography variant="h4" style={{ padding: '20px 0' }}>
                    Second additional rollover from funds
                  </Typography>
                  <Paper elevation={5} style={{ padding: '20px' }}>
                    <Grid container>
                      <Grid item xs={12} style={{ margin: '0 10px' }}>
                        <Field
                          exclusive={true}
                          component={FormikToggleButton}
                          buttons={rolloverToggleButtons}
                          formik={formikProps}
                          name={'fullRolloverThird'}
                          style={{ minWidth: '380px', opacity: readonly ? 0.5 : 1 }}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Grid item xs={12} style={{ minHeight: '70px', margin: '20px 10px' }}>
                          <Field disabled={readonly} name={'fundNameThird'} component={FormikTextField} label="FUND NAME" fullWidth></Field>
                        </Grid>
                        <Grid item xs={12} style={{ minHeight: '70px', margin: '20px 10px' }}>
                          <Field
                            disabled={readonly}
                            name={'superAnnuationIdThird'}
                            component={FormikTextField}
                            label="UNIQUE SUPERANNUATION ID"
                            fullWidth
                          ></Field>
                        </Grid>
                      </Grid>
                      <Grid item xs={6}>
                        <Grid item xs={12} style={{ minHeight: '70px', margin: '20px 10px' }}>
                          <Field disabled={readonly} name={'memberNumberThird'} component={FormikTextField} label="MEMBER NUMBER" fullWidth></Field>
                        </Grid>
                        <Grid item xs={12} style={{ minHeight: '70px', margin: '20px 10px' }}>
                          <Field name="rolloverAmountThird" label="* ROLLOVER AMOUNT ($)" fullWidth>
                            {(fieldProps: FieldProps) => {
                              return (
                                <FormikNumberFormat
                                  formikFieldProps={fieldProps}
                                  numberFormatProps={{
                                    placeholder: '',
                                    isNumericString: true,
                                    allowNegative: false,
                                    decimalScale: 2,
                                    thousandSeparator: true,
                                    name: fieldProps.field.name,
                                    label: 'ROLLOVER AMOUNT ($)',
                                    disabled: readonly,
                                  }}
                                  isFloatValue={true}
                                  showRequiredAsterisk={true}
                                  fullWidth={true}
                                />
                              );
                            }}
                          </Field>
                          <Container style={{ display: 'flex', alignItems: 'center', padding: '4px 0 0', opacity: readonly ? 0.5 : 1 }}>
                            <Typography variant="body1" style={{ fontSize: '14px', letterSpacing: 0, lineHeight: '16px' }}>
                              {rolloverAmountFieldText}
                            </Typography>
                            <Tooltip title={rolloverAmountTooltipText} arrow>
                              <InfoIcon style={{ width: '16px', height: '16px', fill: `${theme.palette.primary.main}` }} />
                            </Tooltip>
                          </Container>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} style={{ margin: '0 10px' }}>
                        <Field disabled={readonly} name={'inSpecieThird'} component={FormikSwitch} label={inSpecieLabelText}></Field>
                      </Grid>
                    </Grid>
                  </Paper>
                </>
              )}
              {details.subAccountTypeId === ClientAccountSubType.Super.id && (
                <>
                  <Typography variant="h4" style={{ padding: '20px 0' }}>
                    Other details
                  </Typography>
                  <Paper elevation={5} style={{ padding: '20px' }}>
                    <Box style={{ opacity: readonly ? 0.5 : 1 }}>
                      <Typography variant="h4" gutterBottom>
                        If you wish to make regular contributions to your superannuation account, please select the preferred method and we will send you the
                        forms to commence the savings plan
                      </Typography>
                      <Field
                        component={FormikToggleButton}
                        exclusive={true}
                        disabled={readonly}
                        buttons={paymentTypeToggleButtons}
                        formik={formikProps}
                        name={'paymentTypeId'}
                        style={{ minWidth: '600px', margin: '0 10px' }}
                        fullWidth
                      />
                    </Box>
                  </Paper>
                </>
              )}
              {details.subAccountTypeId === ClientAccountSubType.Pension.id && (
                <>
                  <Typography variant="h4" style={{ padding: '20px 0' }}>
                    Pension details
                  </Typography>
                  <Paper elevation={5} style={{ padding: '20px' }}>
                    <Box style={{ opacity: readonly ? 0.5 : 1 }}>
                      <Typography variant="h4">Pension type</Typography>
                      <Field
                        component={FormikToggleButton}
                        exclusive={true}
                        disabled={readonly}
                        buttons={pensionTypeToggleButtons}
                        formik={formikProps}
                        name={'pensionTypeId'}
                        style={{ minWidth: '900px', margin: '0 10px' }}
                        onChangeHandler={(value: number) => {
                          if (value !== PensionType.TransitionRetirementPension.id && formikProps.values.paymentLimitTypeId === PaymentLimitType.Maximum.id) {
                            formikProps.setFieldValue('paymentLimitTypeId', PaymentLimitType.Minimum.id);
                          }
                        }}
                        fullWidth
                      />
                    </Box>

                    <Box padding={'10px 15px'}>
                      <Grid>
                        <Field
                          disabled={readonly}
                          name={'resident'}
                          component={FormikSwitch}
                          label="I am an Australian citizen, New Zealand citizen or permanent resident"
                        ></Field>
                      </Grid>

                      <Grid>
                        <Field disabled={readonly} name={'ageOver65'} component={FormikSwitch} label="I am aged 65 or older"></Field>
                      </Grid>

                      <Grid>
                        <Field
                          disabled={readonly}
                          name={'ageOver60NotPaid'}
                          component={FormikSwitch}
                          label="After attaining age 60 I ceased a paid employment arrangement and never again intend to be gainfully employed for more than 9 hours per week"
                        ></Field>
                      </Grid>

                      <Grid>
                        <Field
                          disabled={readonly}
                          name={'ageNotOver60NotPaid'}
                          component={FormikSwitch}
                          label="I have reached my preservation age but not 60, ceased gainfull employment and never again intend to be gainfully employed for more than 9 hours per week"
                        ></Field>
                      </Grid>

                      <Grid>
                        <Field disabled={readonly} name={'reachedAge'} component={FormikSwitch} label="I have reached my preservation age"></Field>
                      </Grid>

                      <Grid>
                        <Field
                          disabled={readonly}
                          name={'atp'}
                          component={FormikSwitch}
                          label="I have an existing ATP in another fund I am transferring to the plan"
                        ></Field>
                      </Grid>
                    </Box>
                  </Paper>
                  <Typography variant="h4" style={{ padding: '20px 0' }}>
                    Pension payment details
                  </Typography>
                  <Paper elevation={5} style={{ padding: '20px', opacity: readonly ? 0.5 : 1 }}>
                    <Typography variant="h5">Frequency of payments</Typography>
                    <Field
                      exclusive={true}
                      component={FormikToggleButton}
                      buttons={paymentFrequencyToggleButtons}
                      formik={formikProps}
                      name={'paymentFrequencyId'}
                      style={{ minWidth: '480px', padding: '10px' }}
                      fullWidth
                    />
                    <Typography variant="h5">Please nominate the gross amount of the annual pension to be received</Typography>
                    <Field
                      exclusive={true}
                      component={FormikToggleButton}
                      buttons={paymentLimitTypeToggleButtons.map((paymentLimitTypeButton: ToggleButtonItem<number>) => {
                        return {
                          ...paymentLimitTypeButton,
                          disabled:
                            paymentLimitTypeButton.value === PaymentLimitType.Maximum.id &&
                            formikProps.values.pensionTypeId !== PensionType.TransitionRetirementPension.id,
                        };
                      })}
                      formik={formikProps}
                      name={'paymentLimitTypeId'}
                      style={{ minWidth: '480px', padding: '10px' }}
                      fullWidth
                    />
                    <Fade in={formikProps.values.paymentLimitTypeId === PaymentLimitType.Other.id}>
                      <Box width={'480px'} padding={'10px'}>
                        {formikProps.values.paymentLimitTypeId === PaymentLimitType.Other.id && (
                          <Field name="paymentLimit" label="OTHER AMOUNT ($)" fullWidth>
                            {(fieldProps: FieldProps) => {
                              return (
                                <FormikNumberFormat
                                  formikFieldProps={fieldProps}
                                  numberFormatProps={{
                                    placeholder: '',
                                    isNumericString: true,
                                    allowNegative: false,
                                    decimalScale: 2,
                                    thousandSeparator: true,
                                    name: fieldProps.field.name,
                                    label: 'OTHER AMOUNT ($)',
                                    disabled: readonly,
                                  }}
                                  isFloatValue={true}
                                  showRequiredAsterisk={true}
                                  fullWidth={true}
                                />
                              );
                            }}
                          </Field>
                        )}
                      </Box>
                    </Fade>
                  </Paper>
                </>
              )}
            </Box>
            {(hasClientEditPermission || status !== Status.Active.id) && (
              <Box paddingTop={'20px'}>
                <EditCancelSaveButtons
                  mode={Mode.EditCancelSave}
                  readonly={readonly}
                  handleEditClick={onToggleEditMode}
                  handleCancelClick={() => {
                    onToggleEditMode();
                    formikProps.resetForm();
                  }}
                  saveProgress={savingProgress}
                ></EditCancelSaveButtons>
              </Box>
            )}
          </fieldset>
        </Form>
      )}
    </Formik>
  );
};
