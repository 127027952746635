import { Box, Typography } from '@mui/material';
import { Action, AnyAction } from '@reduxjs/toolkit';
import { DateTime } from 'luxon';
import React, { useEffect } from 'react';
import { LoadingProgress } from '../../../../../common/store/types';
import { PagedRequest } from '../../../../../store';
import { DocumentsTable } from '../../../client/common/components/documents/documentsTable';
import {
  DeleteContactDocumentPayload,
  DocumentDetails,
  DocumentsParameters,
  DocumentType,
  DownloadDocumentPayload,
  EditDocuments,
  FetchContactDocumentPayload,
  FetchContactDocumentsPayload,
  SaveContactDocumentPayload,
  SaveDocumentDetails,
} from '../../../client/common/store/types';
import { SaveContactIdentificationValues } from '../../store/types';

export interface ContactIdentificationTableProps {
  clientId: number | null;
  contactId: number | null;
  title: string;
  documentTypes: DocumentType[];
  identifications: EditDocuments;
  editIdentification: DocumentDetails | null | undefined;
  parameters: DocumentsParameters;
  loadingProgress: LoadingProgress;
  saveIdentificationProgress: LoadingProgress;
  hideAllActions?: boolean;
  fetchDocumentTypes: () => void;
  fetchContactIdentifications: (document: FetchContactDocumentsPayload) => Promise<AnyAction>;
  fetchContactIdentificationForEdit: (document: FetchContactDocumentPayload) => Promise<AnyAction>;
  saveDocument: (payload: SaveContactDocumentPayload) => Promise<AnyAction>;
  saveContactIdentificationValues: (payload: SaveContactIdentificationValues) => Promise<AnyAction>;
  downloadDocument: (payload: DownloadDocumentPayload) => Promise<AnyAction>;
  deleteDocument: (payload: DeleteContactDocumentPayload) => Promise<AnyAction>;
  setIdentificationAddMode: () => Action;
  cancelIdentificationAddEditMode: () => Action;
  setIdentificationEditId: (documentId: number) => Action;
  removeIdentification: (documentId: number) => Action;
}

export const ContactIdentificationTable = (props: ContactIdentificationTableProps): JSX.Element => {
  const {
    clientId,
    contactId,
    title,
    documentTypes,
    identifications,
    editIdentification,
    parameters,
    hideAllActions,
    loadingProgress,
    saveIdentificationProgress,
    fetchContactIdentificationForEdit,
    fetchDocumentTypes,
    fetchContactIdentifications,
    downloadDocument,
    deleteDocument,
    saveDocument,
    saveContactIdentificationValues,
    setIdentificationAddMode,
    cancelIdentificationAddEditMode,
    setIdentificationEditId,
  } = props;

  const hideColumns = !contactId ? ['name', 'created', 'download'] : undefined;
  const pagination = { pageNumber: 1, pageSize: 1, queryFields: [] };

  useEffect(() => {
    if (!!clientId && !!contactId) {
      fetchContactIdentifications({ clientId, contactId, parameters });
    }
  }, [fetchContactIdentifications, clientId, contactId, parameters]);

  const onSaveIdentification = (document: SaveDocumentDetails) => {
    if (!!clientId) {
      if (!!contactId) {
        saveDocument({
          clientId,
          contactId,
          document,
          fetchPayload: { clientId, contactId, parameters },
        }).then(() => {
          fetchContactIdentifications({ clientId, contactId, parameters });
        });
      } else {
        const documents = [...(identifications.results.items.results || [])];
        const index = documents.findIndex((existingDocument) => document.id !== null && document.id !== undefined && existingDocument.id === document.id);
        if (index >= 0) {
          documents[index] = { ...documents[index], ...document };
        } else {
          documents.push({
            ...document,
            extensionId: null,
            isEditable: true,
            ...(!editIdentification && { dateCreated: DateTime.now().toISO() }),
          });
        }

        saveContactIdentificationValues({
          documents: documents.map((document, index) => {
            return { ...document, id: index + 1 };
          }),
        });
      }
    }
  };

  const onDeleteIdentification = (documentId: number) => {
    if (!!clientId) {
      if (!!contactId) {
        deleteDocument({
          clientId,
          contactId,
          attachmentId: documentId,
          fetchPayload: {
            clientId,
            contactId,
            parameters,
          },
        }).then(() => {
          fetchContactIdentifications({ clientId, contactId, parameters });
        });
      } else {
        saveContactIdentificationValues({
          documents: identifications.results.items.results
            .filter((item) => item.id !== documentId)
            .map((document, index) => {
              return { ...document, id: index + 1 };
            }),
        });
      }
    }
  };

  const handleDownloadIdentification = (attachmentId: number, filename: string) => {
    if (!!contactId) {
      downloadDocument({
        clientId: contactId,
        attachmentId,
        filename,
      });
    }
  };

  const handleGridActions = (pagedRequest: PagedRequest) => {
    if (!!clientId && !!contactId) {
      fetchContactIdentifications({
        clientId,
        contactId,
        parameters: { ...parameters, pagination: pagedRequest },
      });
    }
  };

  const onAddEditIdentification = (documentId: number | null | undefined) => {
    if (documentId !== null && documentId !== undefined) {
      if (!!contactId) {
        // load identification from backend
        fetchContactIdentificationForEdit({ contactId, documentId });
      } else {
        setIdentificationEditId(documentId);
      }
    } else if (documentId === null) {
      setIdentificationAddMode();
    } else {
      cancelIdentificationAddEditMode();
    }
  };

  return (
    <Box>
      <Typography variant="h4" gutterBottom>
        {title}
      </Typography>
      <DocumentsTable
        type="Identifications"
        hideColumns={hideColumns}
        hideAllActions={!!hideAllActions}
        showRequiredAsterisk={true}
        hideTypeTitle={true}
        hideDocumentUpload={!contactId}
        fetchDocumentTypes={fetchDocumentTypes}
        documentTypes={documentTypes}
        documents={identifications.results.items}
        pagination={pagination}
        progress={loadingProgress}
        saveProgress={saveIdentificationProgress}
        selectedDocument={editIdentification}
        onAddEdit={onAddEditIdentification}
        handleGridActions={handleGridActions}
        handleDownloadDocument={handleDownloadIdentification}
        onSave={onSaveIdentification}
        onDelete={onDeleteIdentification}
      />
    </Box>
  );
};
