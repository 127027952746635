import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { progressSelectorFactory } from 'src/common';
import { RootState } from '../../../../reducers';
import { selectRoles } from '../../client/common/store/selectors';
import { selectPrevNextRoutePaths } from '../store/routeSelectors';
import {
  selectAddressItems,
  selectAdviceFeesValues,
  selectClient,
  selectContactsItems,
  selectExternalInstitutions,
  selectSubmitted,
  selectSuperSimplifierDetailsValues,
} from '../store/selectors';
import { OnboardActionTypes, processClient, saveOnboarding } from '../store/thunks';
import { Review } from './components/review';

const mapStateToProps = (state: RootState, routeComponentProps: RouteComponentProps) => ({
  client: selectClient(state),
  prevNextRoutePaths: selectPrevNextRoutePaths(state, routeComponentProps.location.pathname),
  addressesValues: selectAddressItems(state),
  superSimplifierDetailsValues: selectSuperSimplifierDetailsValues(state),
  adviceFeesValues: selectAdviceFeesValues(state),
  contactItems: selectContactsItems(state),
  roles: selectRoles(state),
  externalInstitutions: selectExternalInstitutions(state),
  savingProgress: progressSelectorFactory([OnboardActionTypes.SaveOnboarding])(state),
  submitted: selectSubmitted(state),
});

const mapDispatchToProps = {
  saveOnboarding,
  processClient,
};

interface MatchParams {
  id?: string;
}

const connector = connect(mapStateToProps, mapDispatchToProps);
export type Props = ConnectedProps<typeof connector> & RouteComponentProps<MatchParams>;
export const ReviewContainer = connect(mapStateToProps, mapDispatchToProps)(Review);
