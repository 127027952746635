import { createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../../../../app/api';
import { buildEncodedQueryString } from '../../../../../common';
import { PagedResult } from '../../../../../store';
import { ModelItem, SecurityItem } from '../../../common/store/types';
import { OrderSearchParameters, OrderSearchResult } from './types';

export enum OrderSearchActionTypes {
  FetchResults = '@@bulk/order/search/FetchResults',
  FetchSecurities = '@@bulk/order/search/FetchSecurities',
  FetchModels = '@@bulk/common/FetchModels',
}

export enum OrderSearchApi {
  FetchModels = '/strategies/GetModelList',
  FetchResults = '/holdings/GetFilteredPortfolios',
  FetchSecurities = '/securities/GetSecuritiesBySearchTerm',
}

export const fetchSecurities = createAsyncThunk(OrderSearchActionTypes.FetchSecurities, async (searchTerm: string) => {
  const payload = {
    pagedRequest: {
      pageNumber: 1,
      pageSize: 200,
      queryFields: [
        {
          fieldName: 'name',
          hasSearchTerm: true,
          searchTerm: searchTerm,
          operator: 'Contains',
          isSortTerm: true,
          descendingSortDirection: false,
        },
        {
          fieldName: 'code',
          hasSearchTerm: true,
          searchTerm: searchTerm,
          operator: 'Contains',
          isSortTerm: true,
          descendingSortDirection: false,
        },
      ],
    },
  };

  const response = await api.post<PagedResult<SecurityItem>>(`${OrderSearchApi.FetchSecurities}`, payload);
  return response.data.results;
});

export const fetchResults = createAsyncThunk(OrderSearchActionTypes.FetchResults, async (parameters: OrderSearchParameters) => {
  const queryString = buildEncodedQueryString({
    afslIds: parameters.afsls.map((i) => i.afslId),
    practiceIds: parameters.practices.map((i) => i.practiceId),
    adviserIds: parameters.advisers.map((i) => i.advisorId),
    clientTypes: parameters.clientTypes,
    serviceType: parameters.serviceType,
    portfolioValueFrom: parameters.portfolioValueFrom,
    portfolioValueTo: parameters.portfolioValueTo,
    availableCashBalanceFrom: parameters.availableCashBalanceFrom,
    availableCashBalanceTo: parameters.availableCashBalanceTo,
    securityHeld: parameters.securityHeld,
    securityId: parameters.security ? parameters.security.id : null,
    modelId: parameters.model ? parameters.model.modelId : null,
  });

  const response = await api.get<OrderSearchResult[]>(`${OrderSearchApi.FetchResults}${queryString}`);
  return response.data;
});

export const fetchModels = createAsyncThunk(OrderSearchActionTypes.FetchModels, async (afslIds?: number[]) => {
  const response = await api.post(`${OrderSearchApi.FetchModels}`, !!afslIds ? afslIds : []);
  return Promise.resolve<ModelItem[]>(response.data);
});
