import { v4 } from 'uuid';

export const uuidv4 = (): string => {
  return v4();
};

export function nameof<T>(nameFunction: (obj: T) => unknown): string {
  const fnStr = nameFunction.toString();

  // "x => x.prop"
  return fnStr.substring(fnStr.indexOf('.') + 1);
}

//const nameof = <T,>(name: keyof T) => name;
