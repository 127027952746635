import { createSelector } from '@reduxjs/toolkit';
import { selectMainDetailsItem } from '../../mainDetails/store/selectors';
import { selectDetails } from '../../store/selectors';

export const selectClientStatus = createSelector(selectMainDetailsItem, (mainDetailsItem) => {
  return mainDetailsItem?.status ?? undefined;
});

export const selectAdviceFeesValues = createSelector(selectDetails, (details) => details.adviceFees);

export const selectEstimatedFees = createSelector(selectAdviceFeesValues, (adviceFees) => adviceFees.estimatedFees);

export const selectEstimatedFeesItems = createSelector(selectEstimatedFees, (estimatedFees) => {
  return estimatedFees?.items ?? [];
});

export const selectAdviceFeeEdit = createSelector(selectEstimatedFees, (estimatedFees) => {
  const editValue = estimatedFees?.edit;

  if (typeof editValue === 'number') {
    return estimatedFees?.items[editValue] ?? undefined;
  }

  return editValue;
});

export const selectEditingTieredFeeDetails = createSelector(selectAdviceFeesValues, (adviceFees) => adviceFees.editingTieredFeeDetails || { items: [] });

export const selectTieredFeeDetailsEdit = createSelector(selectEditingTieredFeeDetails, (editingTieredFeeDetails) => editingTieredFeeDetails.edit);

export const selectTieredFeeDetailsItems = createSelector(selectEditingTieredFeeDetails, (editingTieredFeeDetails) => editingTieredFeeDetails.items);
