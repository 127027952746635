import { createAsyncThunk } from '@reduxjs/toolkit';
import { $enum } from 'ts-enum-util';
import api from '../../../../../../app/api';
import { buildEncodedQueryString } from '../../../../../../common/utils/stringFunctions';
import { fetchInvestmentService } from '../../configuration/details/store';
import { commonSlice, fetchInvestmentServiceVersionsByClient, InvestmentService } from '../../store/common';
import {
  CreateAndGetNewInvestmentServicePayload,
  CreateAndGetNewInvestmentServiceResponse,
  FetchPortfolioDetailPayload,
  FetchTemplatesPayload,
  GroupingType,
  InvestmentServiceTemplate,
  PortfolioDetailResult,
} from './types';

export enum PortfolioActionTypes {
  FetchPortfolioDetail = '@@client/portfolio/FetchPortfolioDetail',
  FetchTemplates = '@@client/portfolio/FetchTemplates',
  CreateAndGetNewInvestmentService = '@@client/portfolio/CreateAndGetNewInvestmentService',
}

export enum PortfolioApiEndpoints {
  FetchPortfolioDetail = '/bff/GetPortfolioDetail',
  FetchTemplates = 'investmentservices/GetActiveTemplates',
  CreateAndGetNewInvestmentService = '/investmentservices/CreateAndGetNewInvestmentService',
}

export const fetchPortfolioDetail = createAsyncThunk(
  PortfolioActionTypes.FetchPortfolioDetail,
  async ({ clientId, investmentServiceId, parameters }: FetchPortfolioDetailPayload) => {
    const queryString = buildEncodedQueryString({
      clientId,
      groupingType: $enum(GroupingType).getKeyOrThrow(parameters.groupingType),
      effectiveDate: parameters.dates.dateTo,
      investmentServiceId: investmentServiceId,
    });

    const response = await api.get<PortfolioDetailResult>(`${PortfolioApiEndpoints.FetchPortfolioDetail}${queryString}`);
    return response.data;
  }
);

export const fetchTemplates = createAsyncThunk(PortfolioActionTypes.FetchTemplates, async ({ afslId }: FetchTemplatesPayload) => {
  const queryString = buildEncodedQueryString({
    afslId,
  });

  const response = await api.get<InvestmentServiceTemplate[]>(`${PortfolioApiEndpoints.FetchTemplates}${queryString}`);
  return response.data;
});

export const createAndGetNewInvestmentService = createAsyncThunk<CreateAndGetNewInvestmentServiceResponse, CreateAndGetNewInvestmentServicePayload>(
  PortfolioActionTypes.CreateAndGetNewInvestmentService,
  async (payload: CreateAndGetNewInvestmentServicePayload, thunkApi) => {
    try {
      const response = await api.post<InvestmentService>(`${PortfolioApiEndpoints.CreateAndGetNewInvestmentService}`, payload);
      thunkApi.dispatch(
        fetchInvestmentService.fulfilled(response.data, 'Portfolio created', {
          clientId: payload.clientId,
          investmentServiceVersionId: response.data.investmentServiceVersionId,
        })
      );

      await thunkApi.dispatch(fetchInvestmentServiceVersionsByClient({ clientId: payload.clientId, showLatestVersionOnly: false }));

      thunkApi.dispatch(
        commonSlice.actions.setInvestmentServiceVersion({
          investmentServiceId: response.data.investmentServiceId,
          investmentServiceVersionId: response.data.investmentServiceVersionId,
        })
      );

      return {
        investmentServiceId: response.data.investmentServiceId,
        investmentServiceVersionId: response.data.investmentServiceVersionId,
        message: 'Portfolio added',
      };
    } catch (_e) {
      return { message: 'An error occured adding portfolio', variant: 'error' };
    }
  }
);
