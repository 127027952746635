import { createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../../../app/api';
import { fetchStrategyDetailsPayload, StrategyDetails } from './types';

export enum StrategyDetailsActionTypes {
  FetchStrategyDetails = '@@strategy/GetStrategyDetails',
}

export enum StrategyDetailsApiEndpoints {
  fetchStrategyDetails = 'bff/GetStrategyDetails',
}

export const fetchStrategyDetails = createAsyncThunk(StrategyDetailsActionTypes.FetchStrategyDetails, async (wrapper: fetchStrategyDetailsPayload) => {
  const response = await api.get<StrategyDetails>(`${StrategyDetailsApiEndpoints.fetchStrategyDetails}?strategyId=${wrapper.strategyId}`);
  return response.data;
});
