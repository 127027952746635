import { Grid, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { AsyncAutocomplete, Autocomplete, EnumerationMultiSelect, NumericInput, ToggleButtonItem, WO2ToggleButtonGroup } from '../../../../../common';
import WO2Button from '../../../../../common/components/button/Button';
import { AfslPracticeAdviserSelect } from './../../../common/components/AfslPracticeAdviserSelect';
import { ClientAccountTypeForBulk, ModelItem, SecurityItem, ServiceType } from '../../../common/store/types';
import { Props } from '../filtersContainer';
import { SecurityHeld } from '../store/types';

const serviceTypeToggleButtons: ToggleButtonItem<number>[] = [
  { name: 'All', value: -1 },
  { name: ServiceType.Mda.displayName, value: ServiceType.Mda.id },
  { name: ServiceType.Roa.displayName, value: ServiceType.Roa.id },
];

const securityHoldToggleButtons: ToggleButtonItem<string>[] = [
  { name: SecurityHeld.Held.displayName, value: SecurityHeld.Held.name },
  { name: SecurityHeld.NotHeld.displayName, value: SecurityHeld.NotHeld.name },
];

export const FormSearch = (props: Props): JSX.Element => {
  const {
    parameters,
    afsls,
    models,
    securities,
    setAfslParameter,
    setPracticeParameter,
    setAdviserParameter,
    setAccountTypeParameter,
    setServiceTypeParameter,
    setPortfolioValueFrom,
    setPortfolioValueTo,
    setAvailableCashBalanceTo,
    setAvailableCashBalanceFrom,
    setSecurityHold,
    setSecurity,
    setSecuritySearch,
    setModel,
    fetchAccountSearchResults,
    fetchSecurities,
    fetchModels,
  } = props;

  useEffect(() => {
    const afsls = parameters.afsls.map((x) => x.afslId);
    fetchModels(afsls);
  }, [parameters.afsls]);

  const handleSearch = () => {
    fetchAccountSearchResults(parameters);
  };

  return (
    <Grid container direction="column">
      <AfslPracticeAdviserSelect
        data={afsls}
        selectedAfsls={parameters.afsls}
        selectedPractices={parameters.practices}
        selectedAdvisers={parameters.advisers}
        setSelectedAfsls={setAfslParameter}
        setSelectedPractices={setPracticeParameter}
        setSelectedAdvisers={setAdviserParameter}
      />
      <EnumerationMultiSelect label="Account Type" type={ClientAccountTypeForBulk} value={parameters.accountType} onChange={setAccountTypeParameter} />
      <Grid container direction="column" alignItems="flex-start" style={{ paddingBottom: '10px', paddingTop: '10px' }}>
        <Typography variant={'h4'} style={{ paddingBottom: '10px ' }}>
          Service Type
        </Typography>
        <Grid
          style={{
            width: '100%',
          }}
        >
          <WO2ToggleButtonGroup<number>
            fullWidth={true}
            buttons={serviceTypeToggleButtons}
            value={parameters.serviceTypeId || -1}
            onChangeHandler={(e) => setServiceTypeParameter(e >= 0 ? e : null)}
          />
        </Grid>
      </Grid>
      <Grid
        style={{
          textAlign: 'justify',
          paddingBottom: '10px',
          paddingTop: '10px',
        }}
      >
        <Typography variant={'h5'} style={{ paddingBottom: '10px' }}>
          Portfolio value between
        </Typography>
        <Grid container direction="row">
          <Grid item={true} xs={6} style={{ paddingRight: '5px' }}>
            <NumericInput prefix="$" label="From" value={parameters.portfolioValueFrom} onChange={setPortfolioValueFrom} />
          </Grid>
          <Grid item={true} xs={6} style={{ paddingLeft: '5px' }}>
            <NumericInput prefix="$" label="To" value={parameters.portfolioValueTo} onChange={setPortfolioValueTo} />
          </Grid>
        </Grid>
      </Grid>
      <Grid
        style={{
          textAlign: 'justify',
          paddingBottom: '10px',
          paddingTop: '10px',
        }}
      >
        <Typography variant={'h5'} style={{ paddingBottom: '10px' }}>
          Available cash balance
        </Typography>
        <Grid container direction="row">
          <Grid item={true} xs={6} style={{ paddingRight: '5px' }}>
            <NumericInput prefix="$" label="From" value={parameters.availableCashBalanceFrom} onChange={setAvailableCashBalanceFrom} />
          </Grid>
          <Grid item={true} xs={6} style={{ paddingLeft: '5px' }}>
            <NumericInput prefix="$" label="To" value={parameters.availableCashBalanceTo} onChange={setAvailableCashBalanceTo} />
          </Grid>
        </Grid>
      </Grid>
      <Grid container direction="column" alignItems="flex-start" style={{ paddingBottom: '10px', paddingTop: '10px' }}>
        <Typography variant={'h4'} style={{ paddingBottom: '10px ' }}>
          Security
        </Typography>
        <Grid
          style={{
            width: '100%',
          }}
        >
          <WO2ToggleButtonGroup fullWidth={true} buttons={securityHoldToggleButtons} value={parameters.securityHeld} onChangeHandler={setSecurityHold} />
        </Grid>
      </Grid>
      <Grid style={{ paddingTop: '10px' }}>
        <AsyncAutocomplete<SecurityItem>
          value={parameters.security}
          optionLabel={(i) => i.code + ' - ' + i.name}
          options={securities}
          onOptionChange={setSecurity}
          onSearchChange={setSecuritySearch}
          getNewOptions={fetchSecurities}
        />
      </Grid>

      <Grid style={{ textAlign: 'justify', paddingTop: '10px' }}>
        <Typography variant={'h5'} style={{ paddingBottom: '10px' }}>
          Within Model
        </Typography>
        <Autocomplete<ModelItem> value={parameters.model} optionLabel={(i) => i.name} options={models} loading={false} onOptionChange={setModel} />
      </Grid>
      <Grid style={{ textAlign: 'end', margin: '20px 0' }}>
        <WO2Button
          style={{
            borderRadius: 24,
            height: 48,
            width: 132,
            alignSelf: 'flex-end',
          }}
          color={'primary'}
          onClick={() => handleSearch()}
          variant="contained"
          id="search_button"
        >
          Search
        </WO2Button>
      </Grid>
    </Grid>
  );
};
