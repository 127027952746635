import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ConstraintsState, SecurityConstraint } from '.';
import { fetchConstraints } from './thunks';

export const initialState: ConstraintsState = {
  securityConstraints: [],
};

export const constraintsSlice = createSlice({
  name: '@@client/investmentServices/constraints',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchConstraints.fulfilled, (state: ConstraintsState, action: PayloadAction<SecurityConstraint[]>) => {
      state.securityConstraints = action.payload;
    });
  },
});
