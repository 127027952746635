import { PayloadWithMessage } from 'src/common/store/reducers/types';
import { moment } from 'src/common/types';
import { DateRange } from '../../../../../../store';

export interface OverviewState {
  parameters: PortfolioParameters;
  portfolioDetailResult?: PortfolioDetailResult;
  templates: InvestmentServiceTemplate[];
}

export interface PortfolioParameters {
  dates: DateRange;
  groupingType: GroupingType;
  showPositionsNotHeld: boolean;
}

export enum GroupingType {
  AssetClass = 'Asset Class',
  Model = 'Model',
  Account = 'Account',
}

export interface PortfolioDetailResult {
  clientId: number;
  investmentServiceId: number;
  gainsLossesDetail: GainsLossesDetail;
  portfolioDetail: PortfolioDetail;
}

export interface GainsLossesDetail {
  costBase: number;
  gainLossValue: number;
  items: GainsLossesDetailItem[];
}
export interface PortfolioDetail {
  clientId: number;
  marketValue: number;
  availableCash: number;
  items: AssetGroup[];
}

export interface AssetGroup {
  groupName: string;
  currentValue: number;
  currentWeightPercentage: number;
  targetValue: number;
  targetWeightPercentage: number;
  differenceValue: number;
  differenceWeightPercentage: number;
  items: AssetGroupItem[];
  isGroup: boolean;
  underweightAssetCount: number | null;
  overweightAssetCount: number | null;
  bandName: string | null;
  securityId: number | null;
  securityCode: string | null;
  securityName: string | null;
  marketCode: string | null;
  assetClassId: number | null;
  assetClass: string | null;
  unitPrice: number | null;
  priceDate: string | null;
  units: number | null;
  unsettledUnits: number | null;
  unsettledValue: number | null;
}

export interface AssetGroupItem {
  securityName: string | null;
  securityCode: string | null;
  units: number | null;
  unitPrice: number | null;
  priceDate: moment | undefined;
  currentValue: number | null;
  currentWeightPercentage: number | null;
  targetValue: number | null;
  targetWeightPercentage: number | null;
  differenceValue: number;
  differenceWeightPercentage: number | null;
  unsettledValue: number | null;
  isGroup: boolean;
  groupName: string | null;
  underweightAssetCount: number | null;
  overweightAssetCount: number | null;
  bandName: string | null;
  securityId: number;
  marketCode: string | null;
  assetClassId: number;
  assetClass: string | null;
  unsettledUnits: number | null;
  items: [];
}

export interface GainsLossesDetailItem {
  acquisitionDate: string | null;
  capitalLossPercentage: number | null;
  capitalLossValue: number | null;
  costBase: number;
  discountableGainPercentage: number | null;
  discountableGainValue: number | null;
  gainLossPercentage: number | null;
  gainLossValue: number | null;
  groupName: string;
  groupSubName: string;
  isGroup: boolean;
  items: [];
  marketValue: number | null;
  otherGainPercentage: number | null;
  otherGainValue: number | null;
  tradeDate: string | null;
  unitPrice: number;
  units: number;
}

export interface InvestmentServiceTemplate {
  investmentServiceTemplateVersionId: number;
  majorVersion: number;
  minorVersion: number;
  name: string;
  code: string;
}

export interface FetchPortfolioDetailPayload {
  clientId: number;
  investmentServiceId: number | null;
  parameters: PortfolioParameters;
}

export interface FetchTemplatesPayload {
  afslId: number;
}

export interface CreateAndGetNewInvestmentServicePayload {
  clientId: number;
  serviceTypeId: number;
  investmentServiceTemplateVersionId: number | null;
}

export interface CreateAndGetNewInvestmentServiceResponse extends PayloadWithMessage {
  investmentServiceId?: number;
  investmentServiceVersionId?: number;
}
