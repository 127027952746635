import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FeeCalculationType, FeeMethod } from '../../../../common/enums';
import { EstimatedFee, TieredFeeDetails } from '../../../../common/types';
import { fetchAdviceFees, saveAdviceFees } from './thunks';
import { AdviceFeesState, FeeForClientGroupModel, FeeForClientModel, FetchedFeeForClientModel } from './types';

export const initialState: AdviceFeesState = {
  estimatedFees: {
    items: [],
  },
  editingTieredFeeDetails: {
    items: [],
  },
  isSubmitted: undefined,
};

export const adviceFeesSlice = createSlice({
  name: 'adviceFees',
  initialState,
  reducers: {
    setEstimatedFeeEdit: (state, action: PayloadAction<number | null | undefined>) => {
      // need to copy data from `estimatedFees.items` to editingTieredFeeDetails for tiered type
      if (typeof action.payload === 'number') {
        const feeItemIndex = action.payload;
        if (feeItemIndex >= 0 && state.estimatedFees.items[feeItemIndex] && FeeMethod.Tiered.id === state.estimatedFees.items[feeItemIndex].methodId) {
          state.editingTieredFeeDetails.items = state.estimatedFees.items[feeItemIndex].tieredFeeDetails.items;
        }
      }

      state.estimatedFees.edit = action.payload;
    },
    saveEstimatedFee: (state, action: PayloadAction<EstimatedFee>) => {
      const estimatedFeesIdEdit = state.estimatedFees.edit;
      if (estimatedFeesIdEdit !== undefined) {
        if (estimatedFeesIdEdit !== null) {
          // edit existing fee item
          if (estimatedFeesIdEdit >= 0 && !!state.estimatedFees.items[estimatedFeesIdEdit]) {
            state.estimatedFees.items[estimatedFeesIdEdit] = action.payload;
          }
        } else {
          // add new fee item
          if (state.estimatedFees.items.findIndex((fee) => fee.calculationTypeId === action.payload.calculationTypeId) === -1) {
            state.estimatedFees.items.push(action.payload);
          }
        }

        // remove editingTieredFeeDetails data
        state.editingTieredFeeDetails = { items: [] };
        // clear estimatedFees.edit
        state.estimatedFees.edit = undefined;
        // clear isSubmitted
        state.isSubmitted = undefined;
      }
    },
    removeEstimatedFee: (state, action: PayloadAction<number>) => {
      const feeItemIndex = action.payload;
      if (feeItemIndex >= 0 && !!state.estimatedFees.items[feeItemIndex]) {
        state.estimatedFees.items.splice(feeItemIndex, 1);
        // clear isSubmitted
        state.isSubmitted = undefined;
      }
    },
    restoreEstimatedFeesItems: (state, action: PayloadAction<EstimatedFee[]>) => {
      state.estimatedFees.items = action.payload;
    },
    setTieredFeeDetailsEditId: (state, action: PayloadAction<number | null | undefined>) => {
      state.editingTieredFeeDetails.edit = action.payload;
      state.editingTieredFeeDetails.items = state.editingTieredFeeDetails.items.filter((tieredFeeDetailsItem) => !!tieredFeeDetailsItem.id);
    },
    setTieredFeeDetailsAdd: (state, action: PayloadAction<TieredFeeDetails>) => {
      if (state.editingTieredFeeDetails.edit !== null) {
        state.editingTieredFeeDetails.edit = action.payload.id;
        state.editingTieredFeeDetails.items = [...state.editingTieredFeeDetails.items, action.payload];
      }
    },
    saveEditingTieredFeeDetails: (state, action: PayloadAction<TieredFeeDetails>) => {
      const tieredFeeDetailsIdEdit = state.editingTieredFeeDetails.edit;
      if (tieredFeeDetailsIdEdit !== undefined) {
        if (tieredFeeDetailsIdEdit !== null) {
          // edit
          const index = state.editingTieredFeeDetails.items.findIndex((item) => item.id === tieredFeeDetailsIdEdit);
          if (index >= 0) {
            state.editingTieredFeeDetails.items[index] = action.payload;
          }
        } else {
          // add tiered fee details
          // TODO: the `id` here is using items.length for now to bypass the id isNull check in InlineEditDataTable, need to change when backend is ready
          const tieredFeeDetailsItem = { ...action.payload, id: state.editingTieredFeeDetails.items.length };
          state.editingTieredFeeDetails.items.pop();
          state.editingTieredFeeDetails.items.push(tieredFeeDetailsItem);
        }
        state.editingTieredFeeDetails.edit = undefined;
      }
    },
    removeEditingTieredFeeDetails: (state, action: PayloadAction<number>) => {
      const feeItemIndex = state.editingTieredFeeDetails.items.findIndex((item) => item.id === action.payload);
      if (feeItemIndex >= 0) {
        state.editingTieredFeeDetails.items.splice(feeItemIndex, 1);
      }
    },
    clearEditingTieredFeeDetails: (state) => {
      state.editingTieredFeeDetails = { items: [] };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAdviceFees.fulfilled, (state, action: PayloadAction<FetchedFeeForClientModel>) => {
      if (Array.isArray(action.payload.results)) {
        const feeItems: EstimatedFee[] = action.payload.results
          .filter((feeGroupModel: FeeForClientGroupModel) => Array.isArray(feeGroupModel.fees) && feeGroupModel.fees.length > 0)
          .map((feeGroupModel: FeeForClientGroupModel, index) => {
            const returnValue = {
              index,
              templateCode: feeGroupModel.fees[0].templateCode,
              name:
                feeGroupModel.fees[0].name ??
                feeGroupModel.fees[0].feeCalculationType ??
                (FeeCalculationType.getById(feeGroupModel.fees[0].feeCalculationTypeId)?.displayName || ''),
              calculationTypeId: feeGroupModel.fees[0].feeCalculationTypeId,
              methodId: feeGroupModel.fees[0].feeMethodId,
              frequencyId: feeGroupModel.fees[0].feeFrequencyId,
              amount: feeGroupModel.fees[0].feeMethodId === FeeMethod.Dollar.id && feeGroupModel.fees[0].value ? feeGroupModel.fees[0].value : 0,
              percentageOfValue:
                feeGroupModel.fees[0].feeMethodId === FeeMethod.Percentage.id && feeGroupModel.fees[0].percentage
                  ? +feeGroupModel.fees[0].percentage.toFixed(4)
                  : 0,
              tieredFeeDetails: {
                items:
                  feeGroupModel.fees[0].feeMethodId === FeeMethod.Tiered.id
                    ? feeGroupModel.fees
                        .sort(
                          (feeClientModelA: FeeForClientModel, feeClientModelB: FeeForClientModel) => (feeClientModelA.from ?? 0) - (feeClientModelB.from ?? 0)
                        )
                        .map((feeClientModel: FeeForClientModel, mapIndex: number) => {
                          return {
                            id: mapIndex + 1,
                            from: feeClientModel.from ?? 0,
                            to: feeClientModel.to ?? 0,
                            amount: feeClientModel.value ?? 0,
                            percentage: feeClientModel.percentage ? +feeClientModel.percentage.toFixed(4) : 0,
                          };
                        })
                    : [],
              },
            };
            return returnValue;
          });

        // merge imported standard fees if needed
        state.estimatedFees.items = action.payload.isImported ? feeItems.concat(state.estimatedFees.items) : feeItems;
      }
    });
    builder.addCase(saveAdviceFees.pending, (state) => {
      state.isSubmitted = false;
    });
    builder.addCase(saveAdviceFees.fulfilled, (state) => {
      state.isSubmitted = true;
    });
  },
});
