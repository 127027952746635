import { Typography } from '@mui/material';
import React from 'react';
import { DatatableColumn } from 'src/common/components/dataTable/types';
import { LoadingProgress } from 'src/common/store/types';
import { formatPercentage } from '../../../../../../common';
import { ClientSideDataTable } from '../../../../../../common/components/dataTable/clientSide';
import { SuperSimplifierBeneficiary } from './../store/types';

export interface Props {
  beneficiaries: SuperSimplifierBeneficiary[];
  loadingProgress: LoadingProgress;
}

export const Beneficiaries = ({ beneficiaries, loadingProgress }: Props): JSX.Element => {
  const percentageColumn = (dataIndex: number): React.ReactNode => {
    const percentage = formatPercentage((beneficiaries[dataIndex]?.percent ?? 0) / 100, '0.00%', 2);
    return <Typography variant="h5">{percentage}</Typography>;
  };
  const columns: DatatableColumn[] = [
    {
      name: 'name',
      label: 'NAME',
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: 'relationship',
      label: 'RELATIONSHIP',
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: 'binding',
      label: 'TYPE',
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: 'percent',
      label: 'PERCENTAGE',
      options: {
        filter: false,
        sort: true,
        customBodyRenderLite: (dataIndex): React.ReactNode => percentageColumn(dataIndex),
      },
    },
  ];
  return (
    <>
      <ClientSideDataTable
        loadingProgress={loadingProgress}
        columns={columns}
        data={beneficiaries}
        options={{
          filter: false,
          pagination: false,
        }}
        id="beneficiaries-datatable"
      ></ClientSideDataTable>
    </>
  );
};
