import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { isLoadingSelectorFactory, progressSelectorFactory } from '../../../common';
import { RootState } from '../../../reducers';
import { selectCommonModelHasEditModelAccess, selectCommonModelVersionId } from './../common/store/selectors';
import { modelCommonSlice } from './../common/store/slice';
import { fetchModelVersions } from './../common/store/thunks';
import { CompositionComponent } from './components/CompositionComponent';
import { importAllocationsAction } from './store/sagas/allocationsImporterSaga';
import {
  selectCompositionDetail,
  selectImportErrors,
  selectModelGroupItems,
  selectModelUploadSuccess,
  selectModelVersions,
  selectParameters,
} from './store/selectors';
import { modelCompositionSlice } from './store/slice';
import { fetchCompositionDetail, fetchModelCompositionCSV, ModelCompositionActionTypes } from './store/thunks';

const mapStateToProps = (state: RootState) => ({
  parameters: selectParameters(state),
  modelDetail: selectCompositionDetail(state),
  modelGroupItems: selectModelGroupItems(state),
  isImporting: isLoadingSelectorFactory([importAllocationsAction.actionName])(state),
  versionLoadingIndicator: progressSelectorFactory([ModelCompositionActionTypes.FetchCompositionDetail])(state),
  importErrors: selectImportErrors(state),
  modelVersions: selectModelVersions(state),
  modelUploadSuccess: selectModelUploadSuccess(state),
  hasEditModelAccess: selectCommonModelHasEditModelAccess(state),
  currentModelVersionId: selectCommonModelVersionId(state),
});

const mapDispatchToProps = {
  ...modelCompositionSlice.actions,
  modelCompositionSlice,
  fetchCompositionDetail,
  fetchModelCompositionCSV,
  importAllocations: importAllocationsAction.pending,
  setModelVersion: modelCommonSlice.actions.setModelVersion,
  fetchModelVersions,
};

interface MatchParams {
  modelId?: string;
}

const connector = connect(mapStateToProps, mapDispatchToProps);
export type Props = ConnectedProps<typeof connector> & RouteComponentProps<MatchParams>;
export const ModelCompositionStateContainer = connect(mapStateToProps, mapDispatchToProps)(CompositionComponent);
