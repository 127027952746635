import AddOutlined from '@mui/icons-material/AddOutlined';
import { Box, Container, FormControl, InputLabel, MenuItem, Paper, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import WO2Button from 'src/common/components/button/Button';
import { LoadingIndicator } from 'src/common/components/LoadingIndicator';
import { formatDollars, formatPercentage, WO2Select, WO2TextInput } from '../../../common';
import DateRangePicker from '../../../common/components/DateRangePicker';
import SearchButton from '../../../features/clients/client/common/components/SearchButton';
import { theme } from '../../../themes';
import { Props } from '../container';
import { Grouping, HoldingGroup } from '../store/types';
import { HoldingsTable } from './holdingsTable';

export const Holdings = (props: Props): JSX.Element => {
  const [colorIndex, setColorIndex] = useState(0);
  const {
    parameters,
    leftTable,
    rightTable,
    filteredResults,
    totalValue,
    FetchConsolidatedSecurityHoldings,
    FetchConsolidatedHoldings,
    setGroupingSelectedParameter,
    setDateSelectedParameter,
    setFilterTextParameter,
    setSelectedGroupParameter,
    loadingProgress,
  } = props;

  const inputLabel = useRef<HTMLLabelElement>(null);

  const onSetup = useCallback(() => {
    FetchConsolidatedSecurityHoldings({ primaryGrouping: parameters.groupingSelected, date: parameters.dateSelected.dateTo });
    setSelectedGroupParameter({ label: 'ALL', groupKey: 'ALL' });
  }, [FetchConsolidatedSecurityHoldings, parameters.dateSelected.dateTo, parameters.groupingSelected, setSelectedGroupParameter]);

  useEffect(() => {
    onSetup();
  }, [onSetup]);

  const chartColors = [
    '#2c82be',
    '#429ff9',
    '#423cff',
    '#0080ff',
    '#000080',
    '#008ecc',
    '#008081',
    '#4482b4',
    '#111e6c',
    '#1d2951',
    '#dbecf8',
    '#2c82be',
    '#429ff9',
    '#423cff',
    '#0080ff',
    '#000080',
    '#008ecc',
    '#008081',
    '#4482b4',
    '#111e6c',
    '#1d2951',
    '#dbecf8',
    '#2c82be',
    '#429ff9',
    '#423cff',
    '#0080ff',
    '#000080',
    '#008ecc',
    '#008081',
    '#4482b4',
    '#111e6c',
    '#1d2951',
    '#dbecf8',
  ];

  function onSearchClicked() {
    FetchConsolidatedSecurityHoldings({ primaryGrouping: parameters.groupingSelected, date: parameters.dateSelected.dateTo });
  }

  const chartText = `<div style="color:#000a12; font-family: 'AvenirHeavy'; font-weight: 400; font-size: 20px; line-height: 27px; height: 27px; width: 106px;">${formatDollars(
    totalValue
  )}</div>`;

  const chartData = leftTable
    .filter((i) => i.groupKey !== 'ALL')
    .map((i, index) => ({
      y: Math.abs(i.value / totalValue),
      name: i.label,
      color: chartColors[index],
      sliced: i.groupKey === parameters.selectedGroup.groupKey,
      selected: i.groupKey === parameters.selectedGroup.groupKey,
      events: {
        select: function () {
          setSelectedGroupParameter({ label: i.label, groupKey: i.groupKey });
        },
        unselect: function () {
          setSelectedGroupParameter({ label: 'ALL', groupKey: 'ALL' });
        },
      },
    }));

  const chartOptions = {
    chart: {
      type: 'pie',
      backgroundColor: 'rgba(0,0,0,0)',
    },
    allowPointSelect: true,
    title: {
      verticalAlign: 'middle',
      floating: true,
      text: chartText,
    },
    colors: chartColors,
    subtitle: {
      text: '',
    },
    plotOptions: {
      pie: {
        innerSize: '60%',
        depth: 45,
        tooltip: {
          pointFormatter: function (this: Highcharts.TooltipFormatterContextObject) {
            return `<span style="color:${this.color}">●</span> <b>${this.percentage?.toFixed(2)}%</b><br/>`;
          },
        },
      },
    },
    series: [
      {
        allowPointSelect: true,
        name: '',
        data: chartData,
        dataLabels: {
          enabled: false,
        },
      },
    ],
    credits: {
      enabled: false,
    },
  };

  const groupRows = leftTable.map((row: HoldingGroup, index: number) => (
    <ListItem
      key={index}
      divider
      selected={row.groupKey === parameters.selectedGroup.groupKey}
      button
      onClick={() => {
        setSelectedGroupParameter({ label: row.label, groupKey: row.groupKey });
        setColorIndex(index);
      }}
    >
      <Grid container>
        <Grid item xs={2}>
          <Box style={{ backgroundColor: chartColors[index] }} marginTop={'5px'} display={'block'} width={'20px'} height={'20px'} borderRadius={'15px'}></Box>
        </Grid>
        <Grid item xs={5}>
          <Typography variant={'h5'}>{row.label}</Typography>
        </Grid>
        <Grid item xs={5}>
          <Typography variant={'h5'} align="right">
            {formatDollars(row.value)}
          </Typography>
          <Typography variant={'h6'} align="right">
            {formatPercentage(row.weight)}
          </Typography>
        </Grid>
      </Grid>
    </ListItem>
  ));
  let groupName = 'MODEL';

  switch (parameters.groupingSelected) {
    case Grouping.ModelSecurity: {
      groupName = 'MODEL';
      break;
    }
    case Grouping.AssetClassSecurity: {
      groupName = 'ASSET CLASS';
      break;
    }
    default: {
      groupName = 'MODEL';
    }
  }

  const exportCSV = () => {
    FetchConsolidatedHoldings({ date: parameters.dateSelected.dateTo });
  };

  return (
    <Container style={{ paddingTop: '20px', paddingBottom: '20px' }}>
      <LoadingIndicator progress={loadingProgress}>
        <Typography
          style={{
            height: '48px',
            color: '#000a12',
            fontFamily: theme.typography.fontFamily,
            fontSize: '36px',
            fontWeight: 400,
            lineHeight: '48px',
            paddingRight: '20px',
            marginLeft: '11px',
            marginBottom: theme.spacing(3),
          }}
        >
          Consolidated Holdings
        </Typography>
        <Box
          display={'flex'}
          justifyContent={'space-between'}
          style={{
            marginBottom: theme.spacing(3),
          }}
        >
          <Box paddingRight={'10px'}>
            <FormControl variant="outlined">
              <InputLabel ref={inputLabel} id="portfolio-select-label">
                Grouping
              </InputLabel>
              <WO2Select
                labelId="portfolio-select-label"
                id="portfolio-select"
                value={parameters.groupingSelected}
                onChange={(event) => setGroupingSelectedParameter(event.target.value as string)}
                label={'Grouping'}
              >
                <MenuItem key={2} value={Grouping.AssetClassSecurity}>
                  {Grouping.AssetClassSecurity}
                </MenuItem>
                <MenuItem key={1} value={Grouping.ModelSecurity}>
                  {Grouping.ModelSecurity}
                </MenuItem>
              </WO2Select>
            </FormControl>
          </Box>
          <Box display={'flex'} justifyContent={'space-between'}>
            <DateRangePicker dateRange={parameters.dateSelected} setDateRange={setDateSelectedParameter} isDateOnly />
            <Box paddingRight={'10px'}>
              <WO2TextInput
                placeholder={'Filter'}
                inputProps={{ 'aria-label': 'search' }}
                value={props.parameters.filterText}
                onChange={(event) => {
                  setFilterTextParameter(event.target.value);
                }}
              />
            </Box>
            <Box>
              <SearchButton onClick={onSearchClicked} />
            </Box>
            <Box>
              <WO2Button
                type="button"
                data-testid="exportButton"
                variant={'contained'}
                style={{
                  marginLeft: '10px',
                }}
                startIcon={<AddOutlined />}
                onClick={() => {
                  exportCSV();
                }}
              >
                Export
              </WO2Button>
            </Box>
          </Box>
        </Box>
        <Grid container spacing={3}>
          <Grid item xs={4}>
            <Paper elevation={5}>
              <Grid item>
                <HighchartsReact highcharts={Highcharts} options={chartOptions} />
              </Grid>
              <Grid container>
                <Grid item xs={2}>
                  {' '}
                </Grid>
                <Grid item xs={5}>
                  <Typography variant="h6">{groupName}</Typography>
                </Grid>
                <Grid item xs={5}>
                  <Typography variant={'h6'} style={{ textAlign: 'right', paddingRight: '15px' }}>
                    VALUE & WEIGHT
                  </Typography>
                </Grid>
              </Grid>
              <List disablePadding={true}>{groupRows}</List>
            </Paper>
          </Grid>
          <Grid item xs={8}>
            {!!filteredResults.length && !loadingProgress.isLoading && (
              <HoldingsTable
                assetClassTotal={formatDollars(rightTable.value)}
                assetClassPercentage={formatPercentage(rightTable.weight)}
                assetClassTitle={
                  parameters.selectedGroup.groupKey === 'ALL'
                    ? parameters.groupingSelected === 'Model, Security'
                      ? 'ALL MODELS'
                      : 'ALL ASSET CLASSES'
                    : parameters.selectedGroup.label
                }
                assetClassColor={chartColors[colorIndex]}
                data={filteredResults}
                progress={loadingProgress}
              />
            )}
          </Grid>
        </Grid>
      </LoadingIndicator>
    </Container>
  );
};
