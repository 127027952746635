import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import React from 'react';
import { formatNumberCommaSeparated } from 'src/common';
import { BenefitStatement } from './../store/types';

export interface Props {
  details: BenefitStatement;
}

export const PreservationComponents = ({ details }: Props): JSX.Element => {
  return (
    <>
      <Typography variant="h4" style={{ padding: '20px 0' }}>
        <b>Preservation components</b>
      </Typography>
      <Paper elevation={5} style={{ marginBottom: '30px' }}>
        <TableContainer component={Paper} elevation={0}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="left" style={{ width: '70%' }}>
                  <Typography variant="h6" color="textSecondary">
                    COMPONENT
                  </Typography>
                </TableCell>
                <TableCell align="left">
                  <Typography variant="h6" color="textSecondary">
                    VALUE ($)
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell align="left">
                  <Typography variant="h5">Unrestricted, non-preserved (UNP)</Typography>
                </TableCell>
                <TableCell align="left">
                  <Typography variant="h5">{`${formatNumberCommaSeparated(details?.unrestrictedNonPreserved || 0, 2)}`}</Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="left">
                  <Typography variant="h5">Restricted, non-preserved (RNP)</Typography>
                </TableCell>
                <TableCell align="left">
                  <Typography variant="h5">{`${formatNumberCommaSeparated(details?.restrictedNonPreserved || 0, 2)}`}</Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="left">
                  <Typography variant="h5">Preserved</Typography>
                </TableCell>
                <TableCell align="left">
                  <Typography variant="h5">{`${formatNumberCommaSeparated(details?.preserved || 0, 2)}`}</Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="left">
                  <Typography variant="h5">
                    <b>Total</b>
                  </Typography>
                </TableCell>
                <TableCell align="left">
                  <Typography variant="h5">
                    <b>{`${formatNumberCommaSeparated(details?.totalPreservationComponents || 0, 2)}`}</b>
                  </Typography>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </>
  );
};
