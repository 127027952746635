import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DateTime } from 'luxon';
import { SystemMinimumDate } from '../../../../../common';
import { DateRange } from '../../../../../store';
import { ClientPortfolio } from '../../common/store/types';
import {
  fetchBenchmarkPerformance,
  fetchBenchmarks,
  fetchMonthlyPortfolioPerformance,
  fetchPerformanceChart,
  fetchPeriodicSummary,
  fetchPortfolioSecurityPerformance,
} from './thunks';
import {
  BenchmarkItem,
  BenchmarkPerformanceResult,
  CurrentTabType,
  Frequency,
  GroupingType,
  MonthlyPerformanceTableResult,
  PerformanceChartResult,
  PerformanceState,
  PeriodicSummaryTableResult,
  PortfolioSecurityPerformanceTableResult,
} from './types';

const initialState: PerformanceState = {
  parameters: {
    selectedPortfolio: null,
    frequency: Frequency.Monthly,
    groupingType: GroupingType.AssetClass,
    dates: { dateFrom: DateTime.fromISO(SystemMinimumDate).toISODate(), dateTo: DateTime.now().toISODate() },
  },
  currentTab: CurrentTabType.MonthlySummary,
};

export const performanceSlice = createSlice({
  name: '@@client/performance',
  initialState,
  reducers: {
    setPortfolioParameter: (state, action: PayloadAction<ClientPortfolio>) => {
      state.parameters.selectedPortfolio = action.payload;
    },

    setFrequencyParameter: (state, action: PayloadAction<Frequency>) => {
      state.parameters.frequency = action.payload;
    },

    setGroupingTypeParameter: (state, action: PayloadAction<GroupingType>) => {
      state.parameters.groupingType = action.payload;
    },

    setDateParameter: (state, action: PayloadAction<DateRange>) => {
      state.parameters.dates = action.payload;
    },

    setBenchmarkParameter: (state, action: PayloadAction<BenchmarkItem | null>) => {
      state.parameters.selectedBenchmark = action.payload;
    },

    setCurrentTab: (state, action: PayloadAction<CurrentTabType>) => {
      state.currentTab = action.payload;
    },

    clearBenchmarkPerformanceResult: (state) => {
      state.benchmarkPerformanceResult = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchBenchmarks.fulfilled, (state, action: PayloadAction<BenchmarkItem[]>) => {
      state.benchmarks = action.payload;
    });

    builder.addCase(fetchBenchmarkPerformance.fulfilled, (state, action: PayloadAction<BenchmarkPerformanceResult>) => {
      state.benchmarkPerformanceResult = action.payload;
    });

    builder.addCase(fetchPerformanceChart.fulfilled, (state, action: PayloadAction<PerformanceChartResult>) => {
      state.performanceChartResult = action.payload;
    });

    builder.addCase(fetchMonthlyPortfolioPerformance.fulfilled, (state, action: PayloadAction<MonthlyPerformanceTableResult>) => {
      state.monthlyPerformanceTableResult = action.payload;
    });

    builder.addCase(fetchPeriodicSummary.fulfilled, (state, action: PayloadAction<PeriodicSummaryTableResult>) => {
      state.periodicSummaryTableResult = action.payload;
    });

    builder.addCase(fetchPortfolioSecurityPerformance.fulfilled, (state, action: PayloadAction<PortfolioSecurityPerformanceTableResult>) => {
      state.portfolioSecurityPerformanceTableResult = action.payload;
    });
  },
});
