import { createSelector } from '@reduxjs/toolkit';
import { selectClientState } from '../../store/selectors';

export const selectPerformance = createSelector(selectClientState, (clientState) => clientState.performance);
export const selectParameters = createSelector(selectPerformance, (performance) => performance.parameters);
export const selectCurrentTab = createSelector(selectPerformance, (performance) => performance.currentTab);
export const selectPortfolio = createSelector(selectParameters, (parameters) => parameters.selectedPortfolio);
export const selectBenchmarks = createSelector(selectPerformance, (performance) => performance.benchmarks ?? []);

export const selectPerformanceChartResults = createSelector(selectPerformance, (performance) => performance.performanceChartResult);
export const selectBenchmarkPerformanceResults = createSelector(selectPerformance, (performance) => performance.benchmarkPerformanceResult);
export const selectMonthlyPerformanceTableResults = createSelector(selectPerformance, (performance) => performance.monthlyPerformanceTableResult);
export const selectPeriodicSummaryTableResults = createSelector(selectPerformance, (performance) => performance.periodicSummaryTableResult);
export const selectPortfolioSecurityPerformanceResults = createSelector(
  selectPerformance,
  (performance) => performance.portfolioSecurityPerformanceTableResult
);
