import { connect, ConnectedProps } from 'react-redux';
import { progressSelectorFactory } from '../../../common';
import { RootState } from '../../../reducers';
import { selectCommonModelVersionId } from '../common/store/selectors';
import { AssociationsComponent } from './components/AssociationsComponent';
import { selectModelAssociations } from './store/selectors';
import { modelAssociationsSlice } from './store/slice';
import { fetchModelAssociations, ModelAssociationsActionTypes } from './store/thunks';

const actionTypes = [ModelAssociationsActionTypes.FetchModelAssociations];

const mapStateToProps = (state: RootState) => ({
  modelVersionId: selectCommonModelVersionId(state),
  modelAssociations: selectModelAssociations(state),
  loadingProgress: progressSelectorFactory(actionTypes)(state),
});

const mapDispatchToProps = {
  ...modelAssociationsSlice.actions,
  fetchModelAssociations,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export type Props = ConnectedProps<typeof connector>;
export const ModelAssociationsStateContainer = connect(mapStateToProps, mapDispatchToProps)(AssociationsComponent);
