import InfoIcon from '@mui/icons-material/Info';
import { Backdrop, Box, Container, Fade, Grid, Modal, Paper, Tooltip, Typography } from '@mui/material';
import { Field, FieldProps, Form, Formik, FormikProps } from 'formik';
import React, { useCallback, useRef, useState } from 'react';
import WO2Button from 'src/common/components/button/Button';
import * as yup from 'yup';
import { ToggleButtonItem } from '../../../../../common';
import { ButtonType, EditCancelSaveButtons, FormikNumberFormat, FormikToggleButton, Mode } from '../../../../../common/components/formik';
import { theme } from '../../../../../themes';
import { maxDollarFeeValue } from '../../../common/config/adviceFees';
import { TieredValidateResultTypes } from '../../../common/config/tieredValidations';
import { FeeCalculationType, FeeFrequency, FeeMethod } from '../../../common/enums';
import { EstimatedFee, FeeDetailsValues, TieredFeeDetails } from '../../../common/types';
import { TieredTable } from './tiered/tieredTable';

export interface AdviceFeeDetailsEditProps {
  estimatedFeesItems: EstimatedFee[];
  selectedFeeItem: EstimatedFee | null | undefined;
  tieredFeeDetailsItems: TieredFeeDetails[];
  selectedTieredFeeDetailsEdit: TieredFeeDetails | null | undefined;
  isViewMode: boolean;
  onSave: (feeDetails: EstimatedFee) => void;
  onCancel: () => void;
  setTieredFeeDetailsEditId: (id: number | null | undefined) => void;
  setTieredFeeDetailsAdd: (tieredFee: TieredFeeDetails) => void;
  saveEditingTieredFeeDetails: (tieredFee: TieredFeeDetails) => void;
  removeEditingTieredFeeDetails: (id: number) => void;
}

export const AdviceFeeDetailsEdit = (props: AdviceFeeDetailsEditProps): JSX.Element => {
  const {
    selectedFeeItem,
    tieredFeeDetailsItems,
    selectedTieredFeeDetailsEdit,
    isViewMode,
    onSave,
    onCancel,
    setTieredFeeDetailsEditId,
    setTieredFeeDetailsAdd,
    saveEditingTieredFeeDetails,
    removeEditingTieredFeeDetails,
  } = props;

  const formRef = useRef<FormikProps<FeeDetailsValues>>(null);
  const [selectedCalculationTypeId, setSelectedCalculationTypeId] = useState<number | null>(selectedFeeItem?.calculationTypeId ?? null);
  const [selectedMethodId, setSelectedMethodId] = useState<number | null>(selectedFeeItem?.methodId ?? null);

  const [tieredValidatedError, setTieredValidatedError] = useState<number | null>(null);

  const FeeCalculationTypeToggleButtons: ToggleButtonItem<number>[] = FeeCalculationType.getArray().map((fee: FeeCalculationType) => {
    return { name: fee.displayName, value: fee.id, disabled: false };
  });

  const FeeMethodToggleButtons: ToggleButtonItem<number>[] = FeeMethod.getArray().map((methodId: FeeMethod) => {
    return { name: methodId.displayName, value: methodId.id };
  });

  const FeeFrequencyToggleButtons: ToggleButtonItem<number>[] = FeeFrequency.getArray().map((frequency: FeeFrequency) => {
    return { name: frequency.displayName, value: frequency.id };
  });

  const feeAmountFrequencyTooltipTitle: React.ReactNode = (
    <ul style={{ padding: '10px 10px 10px 20px' }}>
      <li>Fees are charged monthly in arrears.</li>
      <li>Entering a per annum amount will mean fees are charged based on the amount of days in the corresponding month.</li>
      <li>Entering a per month fee will mean the fees charged each month are the same value.</li>
    </ul>
  );

  const maxPercentageOfValue = 100;

  const formValues: FeeDetailsValues = {
    templateCode: selectedFeeItem?.templateCode ?? null,
    name: selectedFeeItem?.name ?? '',
    calculationTypeId: selectedCalculationTypeId,
    methodId: selectedMethodId,
    frequencyId: selectedFeeItem?.frequencyId ?? null,
    amount: selectedFeeItem?.amount || null,
    percentageOfValue: selectedFeeItem?.percentageOfValue ?? formRef?.current?.values.percentageOfValue ?? null,
    tieredFeeDetails: { items: tieredFeeDetailsItems },
  };

  const validateTieredFeeDetails = (): TieredValidateResultTypes => {
    let result = TieredValidateResultTypes.Success;
    const currentFormValues = formRef?.current?.values;

    if (!!currentFormValues && Array.isArray(currentFormValues.tieredFeeDetails.items)) {
      if (currentFormValues.tieredFeeDetails.items.length === 0) {
        return TieredValidateResultTypes.NoTieredItem;
      }

      const currentTieredFeeDetailsItems = currentFormValues.tieredFeeDetails.items;
      const firstFromValueOverlapItemIndex =
        currentTieredFeeDetailsItems.length > 1
          ? currentTieredFeeDetailsItems.findIndex((item: TieredFeeDetails, index: number) => {
              return (
                index + 1 < currentTieredFeeDetailsItems.length &&
                parseFloat(((item?.to || 0) + 0.01).toFixed(2)) !== currentTieredFeeDetailsItems[index + 1].from
              );
            })
          : -1;

      if (currentTieredFeeDetailsItems[0].from > 0) {
        result = TieredValidateResultTypes.FirstFromValueNotZero;
      } else if (firstFromValueOverlapItemIndex >= 0) {
        result =
          (currentTieredFeeDetailsItems[firstFromValueOverlapItemIndex]?.to ?? 0) > currentTieredFeeDetailsItems[firstFromValueOverlapItemIndex + 1].from
            ? TieredValidateResultTypes.ValuesOverlap
            : TieredValidateResultTypes.GapBetweenValues;
      }
    }

    return result;
  };

  const onSaveTieredFeeDetails = (tieredFeeDetails: TieredFeeDetails) => {
    // clear validation result if exists
    setTieredValidatedError(null);
    saveEditingTieredFeeDetails(tieredFeeDetails);
  };

  const onDeleteTieredFeeDetails = (id: number) => {
    // clear validation result if exists
    setTieredValidatedError(null);
    removeEditingTieredFeeDetails(id);
  };

  const handleSaveClick = useCallback(() => {
    if (selectedMethodId === FeeMethod.Tiered.id) {
      const result = validateTieredFeeDetails();
      if (result !== TieredValidateResultTypes.Success) {
        setTieredValidatedError(result);
        return;
      }
    }
    if (!!formRef && !!formRef.current) {
      formRef.current.handleSubmit();
    }
  }, [formRef, selectedMethodId]);

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={true}
        onClose={(_, reason) => {
          if (reason !== 'backdropClick') {
            onCancel();
          }
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 500 }}
      >
        <Fade in={true}>
          <Paper
            elevation={0}
            style={{
              width: '860px',
              padding: '40px',
              maxHeight: '100%',
              overflow: 'auto',
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
            }}
          >
            <Formik<FeeDetailsValues>
              enableReinitialize={true}
              initialValues={formValues}
              innerRef={formRef}
              validationSchema={yup.object({
                calculationTypeId: yup.number().nullable().required('Fee Type is required'),
                methodId: yup.number().nullable().required('Calculation Method is required'),
                frequencyId: yup
                  .number()
                  .nullable()
                  .when(['calculationTypeId', 'methodId'], {
                    is: (calculationTypeId, methodId) => calculationTypeId === FeeCalculationType.OngoingAdviceFee.id && methodId === FeeMethod.Dollar.id,
                    then: yup
                      .number()
                      .integer()
                      .min(FeeFrequency.PerMonth.id, 'Fee Amount Frequency is required')
                      .max(FeeFrequency.PerAnnum.id, 'Fee Amount Frequency is required')
                      .required('Fee Amount Frequency is required'),
                  }),
                amount: yup
                  .number()
                  .nullable()
                  .when('methodId', {
                    is: (methodId) => methodId === FeeMethod.Dollar.id,
                    then: yup.number().required('Fee Amount is required').moreThan(0, 'Fee Amount should be a positive number'),
                  }),
                percentageOfValue: yup
                  .number()
                  .nullable()
                  .when(['calculationTypeId', 'methodId'], {
                    is: (calculationTypeId, methodId) => !!calculationTypeId && methodId === FeeMethod.Percentage.id,
                    then: yup
                      .number()
                      .required('Fee Percentage is required')
                      .moreThan(0, 'Fee Percentage should be a positive number')
                      .max(maxPercentageOfValue, `Fee Percentage cannot exceed ${maxPercentageOfValue}`),
                  }),
              })}
              onSubmit={(feeDetails: FeeDetailsValues) => {
                onSave({
                  index: selectedFeeItem?.index === undefined ? null : selectedFeeItem.index,
                  templateCode: null,
                  name: FeeCalculationType.getById(feeDetails.calculationTypeId ?? 0)?.displayName ?? '',
                  calculationTypeId: feeDetails.calculationTypeId ?? 0,
                  methodId: feeDetails.methodId ?? 0,
                  frequencyId:
                    feeDetails.calculationTypeId === FeeCalculationType.OngoingAdviceFee.id &&
                    feeDetails.methodId === FeeMethod.Dollar.id &&
                    !!feeDetails.frequencyId
                      ? feeDetails.frequencyId
                      : FeeFrequency.NotDefined.id,
                  amount: feeDetails.amount ?? 0,
                  percentageOfValue: feeDetails.percentageOfValue ?? 0,
                  tieredFeeDetails: {
                    items: feeDetails.methodId === FeeMethod.Tiered.id ? tieredFeeDetailsItems : [],
                  },
                });
              }}
            >
              {(formikProps: FormikProps<FeeDetailsValues>) => (
                <Form>
                  <fieldset style={{ border: 'none', margin: '0', padding: '0', pointerEvents: isViewMode ? 'none' : 'auto' }}>
                    <Grid container style={{ marginBottom: '10px' }}>
                      <Typography variant="h4">Fee Type</Typography>
                      <Grid item xs={12} style={{ minHeight: '90px' }}>
                        <Field
                          disabled={isViewMode}
                          exclusive={true}
                          component={FormikToggleButton}
                          buttons={FeeCalculationTypeToggleButtons}
                          formik={props}
                          name={'calculationTypeId'}
                          style={{ width: '780px' }}
                          fullWidth
                          onChangeHandler={(value: number) => {
                            setSelectedCalculationTypeId(value);
                          }}
                        />
                      </Grid>
                    </Grid>
                    <Grid container style={{ marginBottom: '10px', alignContent: 'flex-start' }}>
                      <Typography variant="h4">Calculation Methodology</Typography>
                      <Grid item xs={12} style={{ minHeight: '90px' }}>
                        <Field
                          disabled={isViewMode}
                          exclusive={true}
                          component={FormikToggleButton}
                          buttons={FeeMethodToggleButtons}
                          formik={formikProps}
                          name={'methodId'}
                          style={{ width: '780px' }}
                          fullWidth
                          onChangeHandler={(value: number) => {
                            setSelectedMethodId(value);
                          }}
                        />
                      </Grid>
                      {formikProps.values.calculationTypeId === FeeCalculationType.OngoingAdviceFee.id && formikProps.values.methodId === FeeMethod.Dollar.id && (
                        <Grid container style={{ marginBottom: '10px' }}>
                          <Container style={{ display: 'flex', alignItems: 'center', padding: '0' }}>
                            <Typography variant="h4">Fee Amount Frequency</Typography>
                            <Tooltip title={feeAmountFrequencyTooltipTitle} placement={'right'} arrow>
                              <InfoIcon style={{ width: '18px', height: '18px', marginLeft: '4px', fill: `${theme.palette.primary.main}` }} />
                            </Tooltip>
                          </Container>
                          <Grid item xs={12}>
                            <Field
                              disabled={isViewMode}
                              exclusive={true}
                              component={FormikToggleButton}
                              buttons={FeeFrequencyToggleButtons}
                              formik={props}
                              name={'frequencyId'}
                              style={{ width: '780px' }}
                              fullWidth
                            />
                          </Grid>
                          <Typography variant="body1" style={{ fontStyle: 'italic' }}>
                            * All fee amounts are to be entered inclusive of GST.
                          </Typography>
                        </Grid>
                      )}
                      {formikProps.values.methodId === FeeMethod.Dollar.id && (
                        <Grid item xs={4} style={{ marginTop: '10px', minHeight: '70px' }}>
                          <Field name="amount" label="FEE AMOUNT" fullWidth>
                            {(fieldProps: FieldProps) => {
                              return (
                                <FormikNumberFormat
                                  formikFieldProps={fieldProps}
                                  numberFormatProps={{
                                    disabled: isViewMode,
                                    placeholder: '$0.00',
                                    isNumericString: true,
                                    allowNegative: false,
                                    decimalScale: 2,
                                    thousandSeparator: true,
                                    prefix: '$',
                                    name: fieldProps.field.name,
                                    label: 'FEE AMOUNT ($)',
                                    isAllowed: (values) => !values.floatValue || (values.floatValue > 0 && values.floatValue <= maxDollarFeeValue),
                                  }}
                                  isFloatValue={true}
                                  showRequiredAsterisk={true}
                                  fullWidth={true}
                                />
                              );
                            }}
                          </Field>
                        </Grid>
                      )}
                      {formikProps.values.methodId === FeeMethod.Percentage.id && (
                        <Grid item xs={4} style={{ marginTop: '10px', minHeight: '70px' }}>
                          <Field name="percentageOfValue" label="FEE AMOUNT" fullWidth>
                            {(fieldProps: FieldProps) => {
                              return (
                                <FormikNumberFormat
                                  formikFieldProps={fieldProps}
                                  numberFormatProps={{
                                    disabled: isViewMode,
                                    placeholder: '0.0000%',
                                    isNumericString: true,
                                    allowNegative: false,
                                    decimalScale: 4,
                                    thousandSeparator: true,
                                    isAllowed: (values) => {
                                      return !values.floatValue || (values.floatValue > 0 && values.floatValue <= maxPercentageOfValue);
                                    },
                                    suffix: '%',
                                    name: fieldProps.field.name,
                                    label: 'FEE AMOUNT (%)',
                                  }}
                                  isFloatValue={true}
                                  showRequiredAsterisk={true}
                                  fullWidth={true}
                                />
                              );
                            }}
                          </Field>
                        </Grid>
                      )}
                    </Grid>
                  </fieldset>
                </Form>
              )}
            </Formik>
            {selectedMethodId === FeeMethod.Tiered.id && (
              <TieredTable
                items={tieredFeeDetailsItems}
                editId={selectedTieredFeeDetailsEdit?.id}
                tieredValidatedError={tieredValidatedError}
                isViewMode={isViewMode}
                progress={{ isLoading: false, hasErrors: false }}
                onSave={onSaveTieredFeeDetails}
                onDelete={onDeleteTieredFeeDetails}
                onSelectEditId={setTieredFeeDetailsEditId}
                onStartAddItem={setTieredFeeDetailsAdd}
              />
            )}
            {!isViewMode && (
              <EditCancelSaveButtons
                mode={Mode.CancelSave}
                handleCancelClick={onCancel}
                handleSaveClick={handleSaveClick}
                disabledButtonTypes={!!selectedTieredFeeDetailsEdit ? ButtonType.Save : undefined}
              />
            )}
            {isViewMode && (
              <Box style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <WO2Button type="button" data-testid="closeButton" variant="contained" onClick={onCancel} style={{ marginRight: '10px' }}>
                  Close
                </WO2Button>
              </Box>
            )}
          </Paper>
        </Fade>
      </Modal>
    </div>
  );
};
