import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { progressSelectorFactory } from '../../../../../common';
import { RootState } from '../../../../../reducers';
import { selectHasClientEdit } from '../../../../../store/selectors';
import { selectClientId, selectDocumentTypes, selectRoles } from '../../common/store/selectors';
import { CommonActionTypes, fetchDocumentTypes, fetchRoles } from '../../common/store/thunks';
import { Trustees } from './components/trustees';
import { cancelDocumentAddEditMode, setEmailAddressEditId, setPhoneNumberEditId } from './store/actions';
import {
  selectAddressItems,
  selectAttachContactList,
  selectAttachTrusteeList,
  selectAvailableAddressTypes,
  selectContactAddressItems,
  selectContactAvailableAddressTypes,
  selectContactEditAddress,
  selectContactEditDocument,
  selectContactEditDocuments,
  selectContactEditDocumentsParameters,
  selectContactEditIdentification,
  selectContactEditIdentifications,
  selectContactEditIdentificationsParameters,
  selectContactEmailAddressEditId,
  selectContactEmailAddressesState,
  selectContactItems,
  selectContactPhoneNumberEditId,
  selectContactPhoneNumberState,
  selectDocuments,
  selectEditAddress,
  selectEditContact,
  selectEditContactId,
  selectEditDocument,
  selectEditDocumentsParameters,
  selectEmailAddressEditId,
  selectEmailAddressesState,
  selectPhoneNumberEditId,
  selectPhoneNumberState,
  selectTrustee,
  selectTrusteeId,
  selectTrusteesItems,
} from './store/selectors';
import { trusteesSlice } from './store/slice';
import {
  attachContact,
  attachTrustee,
  deleteAddress,
  deleteContactAddress,
  deleteContactDocument,
  deleteContactEmailAddress,
  deleteContactPhoneNumber,
  deleteDocument,
  deleteEmailAddress,
  deletePhoneNumber,
  detachContact,
  detachTrustee,
  downloadContactDocument,
  downloadDocument,
  fetchAddresses,
  fetchContact,
  fetchContactAddresses,
  fetchContactContactDetails,
  fetchContactDetails,
  fetchContactDocumentForEdit,
  fetchContactDocuments,
  fetchContactIdentificationForEdit,
  fetchContactIdentifications,
  fetchContacts,
  fetchContactsToAttach,
  fetchDocumentForEdit,
  fetchDocuments,
  fetchTrustee,
  fetchTrustees,
  fetchTrusteesToAttach,
  saveAddress,
  saveContact,
  saveContactAddress,
  saveContactDocument,
  saveContactEmailAddress,
  saveContactPhoneNumber,
  saveDocument,
  saveEmailAddress,
  savePhoneNumber,
  saveTrustee,
  TrusteesActionTypes,
  updateContact,
  updateContactRoles,
} from './store/thunks';

const mapStateToProps = (state: RootState) => ({
  hasClientEditPermission: selectHasClientEdit(state),
  clientId: selectClientId(state),
  trustees: selectTrusteesItems(state),
  loadingTrusteesProgress: progressSelectorFactory([TrusteesActionTypes.FetchTrustees])(state),
  trustee: selectTrustee(state),
  trusteeId: selectTrusteeId(state),
  loadingTrusteeProgress: progressSelectorFactory([TrusteesActionTypes.FetchTrustee])(state),
  saveTrusteeProgress: progressSelectorFactory([TrusteesActionTypes.UpdateTrustee])(state),
  trusteesToAttach: selectAttachTrusteeList(state),
  loadingTrusteesToAttach: progressSelectorFactory([TrusteesActionTypes.FetchTrusteesToAttach])(state),
  savingAttachProgress: progressSelectorFactory([TrusteesActionTypes.AttachTrustee])(state),

  roles: selectRoles(state),
  loadingRolesProgress: progressSelectorFactory([CommonActionTypes.FetchRoles])(state),

  addresses: selectAddressItems(state),
  selectedAddress: selectEditAddress(state),
  availableAddressTypes: selectAvailableAddressTypes(state),
  loadingAddressesProgress: progressSelectorFactory([TrusteesActionTypes.FetchAddresses])(state),
  savingAddressProgress: progressSelectorFactory([TrusteesActionTypes.UpdateAddress, TrusteesActionTypes.DeleteAddress])(state),

  documentsParameters: selectEditDocumentsParameters(state),
  documentTypes: selectDocumentTypes(state),
  documents: selectDocuments(state),
  loadingDocumentsProgress: progressSelectorFactory([TrusteesActionTypes.FetchAddresses])(state),
  selectEditDocument: selectEditDocument(state),
  saveDocumentProgress: progressSelectorFactory([TrusteesActionTypes.SaveDocument])(state),

  phoneNumbers: selectPhoneNumberState(state),
  phoneNumberEditId: selectPhoneNumberEditId(state),
  isLoadingContactDetails: progressSelectorFactory([TrusteesActionTypes.FetchContactDetails])(state),

  emailAddresses: selectEmailAddressesState(state),
  emailAddressEditId: selectEmailAddressEditId(state),

  // contacts
  contacts: selectContactItems(state),
  contact: selectEditContact(state),
  contactId: selectEditContactId(state),
  loadingContactProgress: progressSelectorFactory([TrusteesActionTypes.FetchContact])(state),
  loadingContactsProgress: progressSelectorFactory([TrusteesActionTypes.FetchContacts])(state),
  saveContactProgress: progressSelectorFactory([TrusteesActionTypes.CreateContact, TrusteesActionTypes.UpdateContact])(state),
  contactsToAttach: selectAttachContactList(state),
  loadingContactsToAttach: progressSelectorFactory([TrusteesActionTypes.FetchContactsToAttach])(state),
  updateContactRolesProgress: progressSelectorFactory([TrusteesActionTypes.UpdateContactRoles])(state),

  contactAddresses: selectContactAddressItems(state),
  editContactAddress: selectContactEditAddress(state),
  loadingContactAddressesProgress: progressSelectorFactory([TrusteesActionTypes.FetchContactAddresses])(state),
  savingContactAddressProgress: progressSelectorFactory([TrusteesActionTypes.UpdateContactAddress, TrusteesActionTypes.DeleteContactAddress])(state),
  contactAvailableAddressTypes: selectContactAvailableAddressTypes(state),

  contactDocuments: selectContactEditDocuments(state),
  contactDocumentsParameters: selectContactEditDocumentsParameters(state),
  contactEditDocument: selectContactEditDocument(state),
  loadingContactDocumentsProgress: progressSelectorFactory([TrusteesActionTypes.FetchContactDocuments])(state),
  saveContactDocumentProgress: progressSelectorFactory([TrusteesActionTypes.SaveContactDocument])(state),

  contactIdentifications: selectContactEditIdentifications(state),
  contactIdentificationsParameters: selectContactEditIdentificationsParameters(state),
  editContactIdentification: selectContactEditIdentification(state),
  loadingContactIdentificationsProgress: progressSelectorFactory([TrusteesActionTypes.FetchContactIdentifications])(state),

  contactPhoneNumbers: selectContactPhoneNumberState(state),
  contactPhoneNumberEditId: selectContactPhoneNumberEditId(state),
  loadingContactContactDetailsProgress: progressSelectorFactory([TrusteesActionTypes.FetchContactContactDetails])(state),

  contactEmailAddresses: selectContactEmailAddressesState(state),
  contactEmailAddressEditId: selectContactEmailAddressEditId(state),
});

const mapDispatchToProps = {
  ...trusteesSlice.actions,
  fetchTrustees,
  fetchTrustee,
  saveTrustee,
  detachTrustee,
  fetchTrusteesToAttach,
  attachTrustee,

  fetchAddresses,
  deleteAddress,
  saveAddress,

  fetchDocuments,
  fetchDocumentForEdit,
  fetchDocumentTypes,
  saveDocument,
  deleteDocument,
  downloadDocument,
  cancelDocumentAddEditMode,

  fetchContactDetails,
  savePhoneNumber,
  deletePhoneNumber,
  saveEmailAddress,
  deleteEmailAddress,
  setPhoneNumberEditId,
  setEmailAddressEditId,

  // contacts
  fetchContacts,
  fetchContact,
  fetchContactsToAttach,
  attachContact,
  updateContactRoles,
  detachContact,
  saveContact,
  updateContact,
  fetchRoles,

  fetchContactAddresses,
  saveContactAddress,
  deleteContactAddress,

  fetchContactDocuments,
  downloadContactDocument,
  fetchContactDocumentForEdit,
  fetchContactDocumentTypes: fetchDocumentTypes,
  fetchContactIdentifications,
  fetchContactIdentificationForEdit,
  deleteContactDocument,
  saveContactDocument,

  fetchContactContactDetails,
  saveContactPhoneNumber,
  deleteContactPhoneNumber,
  saveContactEmailAddress,
  deleteContactEmailAddress,
};

interface MatchParams {
  id?: string;
  trusteeId?: string;
}

const connector = connect(mapStateToProps, mapDispatchToProps);
export type Props = ConnectedProps<typeof connector> & RouteComponentProps<MatchParams>;
export const TrusteesContainer = connector(Trustees);
