import { connect, ConnectedProps } from 'react-redux';
import { progressSelectorFactory } from '../../../../../../common';
import { RootState } from '../../../../../../reducers';
import { selectAfslId, selectClientId } from '../../../common/store/selectors';
import { selectCurrentInvestmentVersionId } from '../../store/common';
import { Details } from './components/details';
import { selectBenchmarks, selectInvestmentService } from './store/selectors';
import { detailsSlice } from './store/slice';
import {
  fetchBenchmarks,
  fetchInvestmentService,
  MainDetailsActionTypes,
  updateInvestmentService,
  updateInvestmentServiceAssetClassTolerances,
  updateInvestmentServiceSecurityTolerances,
} from './store/thunks';

const mapStateToProps = (state: RootState) => ({
  clientId: selectClientId(state),
  afslId: selectAfslId(state),
  currentInvestmentServiceVersionId: selectCurrentInvestmentVersionId(state),
  details: selectInvestmentService(state),
  isLoadingProgress: progressSelectorFactory([MainDetailsActionTypes.FetchInvestmentService])(state),
  benchmarks: selectBenchmarks(state),
  savingProgress: progressSelectorFactory([MainDetailsActionTypes.UpdateInvestmentService])(state),
  savingSecurityTolerancesProgress: progressSelectorFactory([MainDetailsActionTypes.UpdateInvestmentServiceSecurityTolerances])(state),
  savingAssetClassTolerancesProgress: progressSelectorFactory([MainDetailsActionTypes.UpdateInvestmentServiceAssetClassTolerances])(state),
});

const mapDispatchToProps = {
  ...detailsSlice.actions,
  fetchInvestmentService,
  updateInvestmentService,
  fetchBenchmarks,
  updateInvestmentServiceSecurityTolerances,
  updateInvestmentServiceAssetClassTolerances,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export type Props = ConnectedProps<typeof connector>;
export const DetailsContainer = connector(Details);
