import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { progressSelectorFactory } from '../../../common/store/selectors';
import { RootState } from '../../../reducers';
import { selectHasClientEditAdminOnly } from '../../../store/selectors';
import { ClientList } from './components/list';
import { selectClientListResults, selectOnboardings, selectParameters, selectQuickOnboardingAfsls, selectTotals } from './store/selectors';
import { clientListSlice } from './store/slice';
import {
  ClientListActionTypes,
  createQuickOnboardClient,
  deleteOnboardingWizard,
  downloadClientList,
  downloadProposedOrders,
  fetchAfsls,
  fetchClientList,
  fetchClientsSummary,
  fetchOnboardingWizardsModels,
  resetStatus,
  resubmitClient,
} from './store/thunks';

const mapStateToProps = (state: RootState) => ({
  totals: selectTotals(state),
  clients: selectClientListResults(state),
  parameters: selectParameters(state),
  loadingProgress: progressSelectorFactory([ClientListActionTypes.FetchClientsSummary])(state),
  addQuickClientLoadingProgress: progressSelectorFactory([ClientListActionTypes.CreateQuickOnboardClient])(state),
  clientList: selectClientListResults(state),
  clientListProgress: progressSelectorFactory([ClientListActionTypes.FetchClientList])(state),
  onboardings: selectOnboardings(state),
  onboardingsLoadProgress: progressSelectorFactory([ClientListActionTypes.FetchAllOnboarding])(state),
  // Quick Onboarding
  afsls: selectQuickOnboardingAfsls(state),

  hasClientEditAdminOnlyPermission: selectHasClientEditAdminOnly(state),

  resubmitProgress: progressSelectorFactory([ClientListActionTypes.ResubmitClient])(state),
  resetStatusProgress: progressSelectorFactory([ClientListActionTypes.ResetStatus])(state),
});

const mapDispatchToProps = {
  ...clientListSlice.actions,
  fetchAfsls,
  fetchClientsSummary,
  fetchClientList,
  downloadClientList,
  downloadProposedOrders,
  fetchOnboardingWizardsModels,
  deleteOnboardingWizard,
  // Quick Onboarding
  createQuickOnboardClient,

  resubmitClient,
  resetStatus,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export type Props = ConnectedProps<typeof connector> & RouteComponentProps;
export const ClientListStateContainer = connector(ClientList);
