import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import FileSaver from 'file-saver';
import api from 'src/app/api';
import { buildEncodedQueryString, extractFilenameFromContentDisposition } from 'src/common';
import { AfslViewModel } from 'src/features/bulk/common/store/types';
import {
  AddressDetails,
  AuthorisedSignatoryRoleId,
  ContactDetail,
  DocumentType,
  DownloadDocumentPayload,
} from 'src/features/clients/client/common/store/types';
import { AttachableContact, EstimatedFee, TieredFeeDetails } from 'src/features/clients/common/types';
import { ExternalInstitution } from '../../../../common/components/accounts/types';
import { FetchPagedResults, PagedResult, RootState } from '../../../../store';
import {
  DeleteContactDocumentPayload,
  DocumentDetails,
  FetchContactDocumentPayload,
  FetchContactDocumentsPayload,
  SaveContactDocumentPayload,
} from '../../client/common/store/types';
import { AttachmentType, FeeMethod } from '../../common/enums';
import {
  removeAddress,
  removeContact,
  setAccountInfo,
  setAccountTypeValues,
  setAdditionalInformationValues,
  setAddress,
  setAdviceFeesValues,
  setContactIdentificationValues,
  setContactValues,
  setSuperSimplifierDetailsValues,
  setTrustee,
} from './actions';
import { EndpointAdviceFee, EndpointClientState } from './endpointTypes';
import { selectClient } from './selectors';
import { blankContact, onboardSlice } from './slice';
import {
  AccountInfo,
  AccountTypeValues,
  AdditionalInformationValues,
  AdviceFees,
  AfslAuthorisation,
  ClientState,
  ContactDetails,
  FeeForAdviserOrClientModel,
  FetchAfslAuthorisationPayload,
  FetchApprovedInstitutionIdsPayload,
  FetchApprovedInstitutionIdsResponse,
  FetchContactPayload,
  FetchContactsToAttachPayload,
  FetchFeesForAdviserPayload,
  OnboardingIndentification,
  SaveContactIdentificationValues,
  SaveContactValues,
  SetAttachedContactPayload,
  SuperSimplifierDetails,
  Trustee,
} from './types';

export enum OnboardActionTypes {
  FetchOnboarding = '@@onboard/FetchOnboarding',
  SaveOnboarding = '@@onboard/SaveOnboarding',
  ProcessClient = '@@onboard/ProcessClient',
  SaveAccountTypeValues = '@@onboard/saveAccountTypeValues',
  SaveAdditionalInfoValues = '@@onboard/SaveAdditionalInfoValues',
  SaveSuperSimplifierDetailsValues = '@@onboard/SaveSuperSimplifierDetailsValues',
  SaveTrustee = '@@onboard/SaveTrustee',
  SaveAdviceFeesValues = '@@onboard/SaveAdviceFeesValues',
  SaveAccountInfo = '@@onboard/SaveAccountInfo',
  SaveContactInfo = '@@onboard/SaveContactInfo',
  DeleteContact = '@@onboard/DeleteContact',
  SaveContactIdentification = '@@onboard/SaveContactIdentificationValues',
  FetchDocumentTypes = '@@onboard/FetchDocumentTypes',
  FetchContactIdentifications = '@@onboard/FetchContactIdentifications',
  FetchContactIdentification = '@@onboard/FetchContactIdentification',
  FetchDocuments = '@@onboard/FetchDocuments',
  FetchDocument = '@@onboard/FetchDocument',
  DownloadDocument = '@@onboard/DownloadDocument',
  DeleteDocument = '@@onboard/DeleteDocument',
  SaveDocument = '@@onboard/SaveDocument',
  FetchExistingContact = '@@client/details/contacts/FetchContact',
  FetchContactInfo = '@@client/details/contacts/FetchContactInfo',
  FetchContactsToAttach = '@@client/details/contacts/FetchContactsToAttach',
  FetchAfsls = '@@onBoard/accountType/FetchAfsls',
  FetchAfslAuthorisation = '@@onBoard/accountType/FetchAfslAuthorisation',
  FetchFeesForAdviser = '@@onBoard/fees/GetOnboardingFeesForAdviser',
  FetchExternalAccountInstitutions = '@@onBoard/accounts/GetExternalAccountInstitutions',
  FetchApprovedInstitutionIds = '@@onBoard/accounts/FetchApprovedInstitutionIds',
  DownloadIdentification = '@@onBoard/contact/identifications/DownloadIdentification',
  FetchContactDetails = '@@onBoard/contact/FetchContactDetails',
  FetchAddresses = '@@onBoard/addresses/FetchAddresses',
  DeleteAddress = '@@onBoard/addresses/DeleteAddress',
  SaveAddress = '@@onBoard/addresses/SaveAddress',
}

export enum OnboardApiEndpoints {
  FetchOnboarding = 'onboarding/GetOnboardingWizard',
  SaveOnboarding = 'onboarding/SaveOnboardingWizard',
  ProcessClient = 'onboarding/ProcessClient',
  FetchDocumentTypes = 'documents/GetAttachmentTypes',
  FetchDocuments = 'documents/GetContactAttachments',
  FetchDocument = 'documents/GetContactAttachment',
  DownloadDocument = 'documents/Download',
  DeleteDocument = 'documents/DeleteContactAttachment',
  UpdateDocument = 'documents/UpdateContactAttachment',
  CreateDocument = 'documents/CreateContactAttachment',
  FetchExistingContact = 'customers/GetContactForEntity',
  FetchExistingContactRoles = 'customers/GetClientContact',
  FetchContactsToAttach = 'customers/GetAttachableContacts',
  FetchAfslAuthorisation = 'onboarding/GetAfslAuthorisation',
  FetchAfsls = 'bff/GetAfslsPracticesAdvisersForUser',
  FetchFeesForAdviser = '/fees/GetOnboardingFeesForAdviser',
  FetchExternalAccountInstitutions = '/institutions/GetInstitutions',
  FetchLatestApprovedServiceVersion = '/approvedServices/GetLatestApprovedServiceVersion',
  DownloadIdentification = '/documents/DownloadIdentification',
  FetchContactDetails = '/customers/GetContactDetails',
  FetchAddresses = '/customers/GetContactAddresses',
}

export const fetchOnboarding = createAsyncThunk(OnboardActionTypes.FetchOnboarding, async (id: string) => {
  const queryString = buildEncodedQueryString({
    id,
  });
  const response = await api.get<EndpointClientState>(`${OnboardApiEndpoints.FetchOnboarding}${queryString}`);
  const incomingClient = response.data;

  const clientState: ClientState = {
    ...incomingClient,
    contacts: {
      items: incomingClient.contacts.map((contact) => {
        return {
          ...contact,
          identifications: {
            parameters: blankContact.identifications.parameters,
            results: {
              ...blankContact.identifications.results,
              items: {
                ...blankContact.identifications.results.items,
                results:
                  contact.identifications.map((i: OnboardingIndentification, index: number) => ({
                    id: index,
                    name: i.name,
                    fileName: '',
                    issueDate: i.issueDate,
                    expiryDate: i.expiryDate,
                    typeId: i.typeId,
                    type: AttachmentType.getById(i.typeId)?.displayName || '',
                    extensionId: null,
                    number: i.number,
                    placeOfIssue: i.placeOfIssue,
                    isPhotoShown: i.isPhotoShown,
                    dateCreated: undefined,
                    isEditable: true,
                  })) || [],
              },
            },
          },
          addresses: { items: contact.addresses },
        };
      }),
    },
    addresses: { items: incomingClient.addresses || [] },
    fees: {
      estimatedFees: { items: mapIncomingFees(incomingClient.fees || []) },
      standardFees: [],
    },
  };
  return clientState;
});

export const saveOnboarding = createAsyncThunk(OnboardActionTypes.SaveOnboarding, async (clientState: ClientState, thunkApi) => {
  // strip out the 'items' from the editable collections
  const payload = {
    ...clientState,
    contacts: [
      ...clientState.contacts.items.map((contact) => {
        return {
          ...contact,
          addresses: contact.addresses.items,
          identifications: contact.identifications.results.items.results,
        };
      }),
    ],
    addresses: clientState.addresses.items,
    // strip out editingTieredFeeDetails and `items` from the editable collections of adviceFees
    fees: mapOutgoingFees(clientState.fees?.estimatedFees.items || []),
  };

  // save to backend
  const response = await api.post(`${OnboardApiEndpoints.SaveOnboarding}`, payload);

  if (!response?.data?.id) {
    //error
    thunkApi.rejectWithValue({
      message: 'Save failed',
      variant: 'error',
    });
  }

  return response.data.id;
});

export const fetchAfsls = createAsyncThunk(OnboardActionTypes.FetchAfsls, async () => {
  const response = await api.get<AfslViewModel>(`${OnboardApiEndpoints.FetchAfsls}`);
  return response.data.afsls;
});

export const fetchAfslAuthorisation = createAsyncThunk(OnboardActionTypes.FetchAfslAuthorisation, async (params: FetchAfslAuthorisationPayload) => {
  const queryString = buildEncodedQueryString({
    afslId: params.afslId,
  });

  const response = await api.get<AfslAuthorisation>(`${OnboardApiEndpoints.FetchAfslAuthorisation}${queryString}`);
  return response.data;
});

export const fetchFeesForAdviser = createAsyncThunk(OnboardActionTypes.FetchFeesForAdviser, async (payload: FetchFeesForAdviserPayload) => {
  const [responseOfAllAccountTypes, responseOfIndividual] = await Promise.all([
    api.post<FeeForAdviserOrClientModel[]>(`${OnboardApiEndpoints.FetchFeesForAdviser}`, {
      ...payload,
      accountTypeIds: [],
      subAccountTypeIds: [],
    }),
    api.post<FeeForAdviserOrClientModel[]>(`${OnboardApiEndpoints.FetchFeesForAdviser}`, payload),
  ]);

  return Array.isArray(responseOfAllAccountTypes.data) && Array.isArray(responseOfIndividual.data)
    ? responseOfAllAccountTypes.data.concat(
        responseOfIndividual.data.filter(
          (individualData: FeeForAdviserOrClientModel) =>
            responseOfAllAccountTypes.data.find(
              (AllAccountTypesData: FeeForAdviserOrClientModel) => AllAccountTypesData.templateCode === individualData.templateCode
            ) === undefined
        )
      )
    : [];
});

export const fetchExternalInstitutions = createAsyncThunk(OnboardActionTypes.FetchExternalAccountInstitutions, async () => {
  const response = await api.get<ExternalInstitution[]>(OnboardApiEndpoints.FetchExternalAccountInstitutions);
  return response.data;
});

export const fetchApprovedInstitutionIds = createAsyncThunk(
  OnboardActionTypes.FetchApprovedInstitutionIds,
  async (params: FetchApprovedInstitutionIdsPayload) => {
    const queryString = buildEncodedQueryString({
      afslId: params.afslId,
    });

    const response = await api.get<FetchApprovedInstitutionIdsResponse>(`${OnboardApiEndpoints.FetchLatestApprovedServiceVersion}${queryString}`);

    return [
      ...new Set(
        response.data.approvedInstitutions.map((institution) => {
          return institution.institutionId;
        })
      ),
    ];
  }
);

export const downloadIdentification = createAsyncThunk(OnboardActionTypes.DownloadIdentification, async (wrapper: DownloadDocumentPayload) => {
  const queryString = buildEncodedQueryString({
    clientId: wrapper.clientId,
    attachmentId: wrapper.attachmentId,
  });

  await api
    .get(`${OnboardApiEndpoints.DownloadIdentification}${queryString}`, {
      responseType: 'blob',
    })
    .then((response: AxiosResponse) => {
      const fileName = extractFilenameFromContentDisposition(response.headers['content-disposition']) || wrapper.filename;
      FileSaver.saveAs(new Blob([response.data]), fileName);
    });
});

export const setAdditionalInformationValuesThunk = createAsyncThunk(
  setAdditionalInformationValues.type,
  async (values: AdditionalInformationValues, thunkApi) => {
    thunkApi.dispatch(setAdditionalInformationValues(values));
  }
);

export const saveAdditionalInfoValues = createAsyncThunk(
  OnboardActionTypes.SaveAdditionalInfoValues,
  async (values: AdditionalInformationValues, { dispatch, getState }) => {
    // get the full onboarding client state and save it
    const response = dispatch(setAdditionalInformationValues(values));

    const clientState = selectClient(getState() as RootState);
    await dispatch(saveOnboarding({ ...clientState, stepKey: 'information' }));

    return response;
  }
);

export const saveAccountTypeValues = createAsyncThunk(OnboardActionTypes.SaveAccountTypeValues, async (values: AccountTypeValues, { dispatch, getState }) => {
  // save to store
  dispatch(setAccountTypeValues(values));

  if (!!values.id) {
    // get the full onboarding client state and save it
    const rootState = getState() as RootState;
    const client = selectClient(rootState);
    return dispatch(saveOnboarding(client));
  }
});

export const saveSuperSimplifierDetailsValues = createAsyncThunk(
  OnboardActionTypes.SaveSuperSimplifierDetailsValues,
  async (values: SuperSimplifierDetails, thunkApi) => {
    // save to store
    thunkApi.dispatch(setSuperSimplifierDetailsValues(values));

    // get the full onboarding client state and save it
    const clientState = selectClient(thunkApi.getState() as RootState);
    return thunkApi.dispatch(saveOnboarding({ ...clientState, stepKey: 'superSimplifier' }));
  }
);

export const saveTrustee = createAsyncThunk(OnboardActionTypes.SaveTrustee, async (trustee: Trustee | undefined, thunkApi) => {
  // save to store
  thunkApi.dispatch(setTrustee(trustee));

  // get the full onboarding client state and save it
  const clientState = selectClient(thunkApi.getState() as RootState);
  return thunkApi.dispatch(saveOnboarding(clientState));
});

export const saveAdviceFeesValues = createAsyncThunk(OnboardActionTypes.SaveAdviceFeesValues, async (values: AdviceFees, thunkApi) => {
  // save to store
  thunkApi.dispatch(setAdviceFeesValues(values));

  // get the full onboarding client state and save it
  const clientState = selectClient(thunkApi.getState() as RootState);
  await thunkApi.dispatch(saveOnboarding({ ...clientState, stepKey: 'fees' }));
});

export const saveAccountInfo = createAsyncThunk(OnboardActionTypes.SaveAccountInfo, async (values: AccountInfo, thunkApi) => {
  // save to store
  thunkApi.dispatch(setAccountInfo(values));

  // get the full onboarding client state and save it
  const client = selectClient(thunkApi.getState() as RootState);
  await thunkApi.dispatch(saveOnboarding({ ...client, stepKey: 'accounts' }));
});

export const fetchDocumentTypes = createAsyncThunk(OnboardActionTypes.FetchDocumentTypes, async () => {
  const response = await api.get<DocumentType[]>(OnboardApiEndpoints.FetchDocumentTypes);
  return response.data;
});

export const fetchContactIdentifications = createAsyncThunk(OnboardActionTypes.FetchContactIdentifications, async (wrapper: FetchContactDocumentsPayload) => {
  const body = {
    clientId: wrapper.clientId,
    contactId: wrapper.contactId,
    filter: 'identification',
    pagedRequest: wrapper.parameters.pagination,
  };

  const response = await api.post<PagedResult<DocumentDetails>>(`${OnboardApiEndpoints.FetchDocuments}`, body);

  return {
    results: response.data,
    pagination: wrapper.parameters.pagination,
  } as FetchPagedResults<DocumentDetails>;
});

export const fetchContactIdentificationForEdit = createAsyncThunk(
  OnboardActionTypes.FetchContactIdentification,
  async (wrapper: FetchContactDocumentPayload) => {
    const queryString = buildEncodedQueryString({
      entityCoreId: wrapper.contactId,
      attachmentId: wrapper.documentId,
    });

    const response = await api.get<DocumentDetails>(`${OnboardApiEndpoints.FetchDocument}${queryString}`);
    return response.data;
  }
);

export const downloadDocument = createAsyncThunk(OnboardActionTypes.DownloadDocument, async (wrapper: DownloadDocumentPayload) => {
  const queryString = buildEncodedQueryString({
    clientId: wrapper.clientId,
    attachmentId: wrapper.attachmentId,
  });

  await api
    .get(`${OnboardApiEndpoints.DownloadDocument}${queryString}`, {
      responseType: 'blob',
    })
    .then((response: AxiosResponse) => {
      const fileName = extractFilenameFromContentDisposition(response.headers['content-disposition']) || wrapper.filename;
      FileSaver.saveAs(new Blob([response.data]), fileName);
    });
});

export const deleteDocument = createAsyncThunk(OnboardActionTypes.DeleteDocument, async (payload: DeleteContactDocumentPayload, thunkApi) => {
  await api.delete(OnboardApiEndpoints.DeleteDocument, { data: payload });
  thunkApi.dispatch(fetchContactIdentifications(payload.fetchPayload));

  return { message: 'Document deleted' };
});

export const saveDocument = createAsyncThunk(OnboardActionTypes.SaveDocument, async (payload: SaveContactDocumentPayload, thunkApi) => {
  await api.post(!!payload.document.id ? OnboardApiEndpoints.UpdateDocument : OnboardApiEndpoints.CreateDocument, {
    ...payload.document,
    clientId: payload.clientId,
    contactId: payload.fetchPayload.contactId,
  });

  await thunkApi.dispatch(fetchContactIdentifications(payload.fetchPayload));
  thunkApi.dispatch(onboardSlice.actions.cancelIdentificationAddEditMode());

  return { message: !!payload.document.id ? 'Document saved' : 'Document added' };
});

export const saveContactIdentificationValues = createAsyncThunk(
  OnboardActionTypes.SaveContactIdentification,
  async (values: SaveContactIdentificationValues, thunkApi) => {
    const documents = values.documents ?? [];
    const rootState = thunkApi.getState() as RootState;
    const client = selectClient(rootState);

    return thunkApi
      .dispatch(
        saveOnboarding({
          ...client,
          contacts: {
            ...client.contacts,
            items: [
              {
                ...client.contacts.items[0],
                identifications: {
                  ...client.contacts.items[0].identifications,
                  results: {
                    ...client.contacts.items[0].identifications.results,
                    items: { ...client.contacts.items[0].identifications.results.items, results: documents },
                  },
                },
              },
              ...client.contacts.items.slice(1),
            ],
          },
        })
      )
      .then(() => {
        thunkApi.dispatch(setContactIdentificationValues(documents));
        thunkApi.dispatch(onboardSlice.actions.cancelIdentificationAddEditMode());
      });
  }
);

export const saveContactInfo = createAsyncThunk(OnboardActionTypes.SaveContactInfo, async (values: SaveContactValues, thunkApi) => {
  // save to store
  thunkApi.dispatch(setContactValues(values));

  // get the full onboarding client state and save it
  const stepKey = values.index === null || values.index === 0 ? 'contact0' : 'contacts';
  const clientState = selectClient(thunkApi.getState() as RootState);
  await thunkApi.dispatch(saveOnboarding({ ...clientState, stepKey }));
});

export const deleteContact = createAsyncThunk(OnboardActionTypes.DeleteContact, async (index: number, thunkApi) => {
  // save to store
  thunkApi.dispatch(removeContact({ index }));

  // get the full onboarding client state and save it
  const clientState = selectClient(thunkApi.getState() as RootState);
  await thunkApi.dispatch(saveOnboarding({ ...clientState, stepKey: 'information' }));
});

export const fetchContactsToAttach = createAsyncThunk(OnboardActionTypes.FetchContactsToAttach, async (payload: FetchContactsToAttachPayload) => {
  const queryString = buildEncodedQueryString({ ...payload });
  const response = await api.get<AttachableContact[]>(`${OnboardApiEndpoints.FetchContactsToAttach}${queryString}`);
  return response.data;
});

export const fetchExistingContact = createAsyncThunk(OnboardActionTypes.FetchExistingContact, async (params: FetchContactPayload, thunkApi) => {
  // call multiple endpoints to load all contact info
  await Promise.all([
    thunkApi.dispatch(fetchContactInfo(params)),
    thunkApi.dispatch(fetchContactAddresses(params.contactId)),
    thunkApi.dispatch(fetchContactDetails(params.contactId)),
  ]);

  // get the full onboarding client state and save it
  // const rootState = thunkApi.getState() as RootState;
  // thunkApi.dispatch(saveOnboarding(selectClient(rootState)));

  return true;
});

export const fetchContactInfo = createAsyncThunk(
  OnboardActionTypes.FetchContactInfo,
  async (params: FetchContactPayload): Promise<SetAttachedContactPayload> => {
    const queryString = buildEncodedQueryString({
      entityCoreId: params.contactId,
      adviserId: params.adviserId,
    });

    const contact = await api.get<ContactDetails>(`${OnboardApiEndpoints.FetchExistingContact}${queryString}`);

    return {
      index: params.index,
      contactId: params.contactId,
      contact: {
        ...contact.data,
        isPrimary: params.index === 0,
        clientRoles: params.roles.length > 0 ? params.roles : [{ roleId: AuthorisedSignatoryRoleId, percent: null, relationship: '' }],
        trusteeRoles: [],
      },
    };
  }
);

export const fetchContactAddresses = createAsyncThunk(OnboardActionTypes.FetchAddresses, async (contactId: number) => {
  const queryString = buildEncodedQueryString({
    contactId,
  });
  const response = await api.get<AddressDetails[]>(`${OnboardApiEndpoints.FetchAddresses}${queryString}`);
  return response.data;
});

export const fetchContactDetails = createAsyncThunk(OnboardActionTypes.FetchContactDetails, async (contactId: number) => {
  const queryString = buildEncodedQueryString({
    contactId,
  });
  const response = await api.get<ContactDetail[]>(`${OnboardApiEndpoints.FetchContactDetails}${queryString}`);
  return response.data;
});

export const deleteAddress = createAsyncThunk(OnboardActionTypes.DeleteAddress, async (index: number, thunkApi) => {
  // save to store
  thunkApi.dispatch(removeAddress({ index }));

  // get the full onboarding client state and save it
  const rootState = thunkApi.getState() as RootState;
  thunkApi.dispatch(saveOnboarding(selectClient(rootState)));
});

export const saveAddress = createAsyncThunk(OnboardActionTypes.SaveAddress, async (values: AddressDetails, thunkApi) => {
  // save to store
  thunkApi.dispatch(setAddress(values));

  // get the full onboarding client state and save it
  const rootState = thunkApi.getState() as RootState;
  await thunkApi.dispatch(saveOnboarding(selectClient(rootState)));
});

const mapIncomingFees = (incoming: EndpointAdviceFee[]): EstimatedFee[] => {
  const fees: { [keyframes: string]: EstimatedFee } = {};

  const reducedArray = incoming.reduce((accumulator: EstimatedFee[], currentFee: EndpointAdviceFee) => {
    const key = `${currentFee.feeCalculationTypeId || 'null'}-${currentFee.feeMethodId || 'null'}-${currentFee.feeFrequencyId || null}`;

    if (!fees[key]) {
      fees[key] = {
        index: accumulator.length,
        name: currentFee.name,
        templateCode: currentFee.templateCode,
        frequencyId: currentFee.feeFrequencyId,
        methodId: currentFee.feeMethodId,
        calculationTypeId: currentFee.feeCalculationTypeId,
        amount: currentFee.amount,
        percentageOfValue: currentFee.percentage,
        tieredFeeDetails: {
          items:
            currentFee.feeMethodId === FeeMethod.Tiered.id
              ? [
                  {
                    id: 0,
                    from: currentFee.from || 0,
                    to: currentFee.to,
                    amount: currentFee.amount,
                    percentage: currentFee.percentage,
                  },
                ]
              : [],
        },
      };
      accumulator.push(fees[key]);
    } else {
      fees[key].tieredFeeDetails.items.push({
        id: fees[key].tieredFeeDetails.items.length,
        from: currentFee.from || 0,
        to: currentFee.to,
        amount: currentFee.amount,
        percentage: currentFee.percentage,
      });
    }

    return accumulator;
  }, []);

  return reducedArray;
};

const mapOutgoingFees = (outgoing: EstimatedFee[]): EndpointAdviceFee[] => {
  const result: EndpointAdviceFee[] = [];

  outgoing.forEach((fee: EstimatedFee) => {
    if (fee.tieredFeeDetails.items.length === 0) {
      // no tiers
      result.push({
        name: fee.name,
        templateCode: fee.templateCode,
        feeFrequencyId: fee.frequencyId,
        feeMethodId: fee.methodId,
        feeCalculationTypeId: fee.calculationTypeId,
        tiered: fee.methodId === FeeMethod.Tiered.id,
        from: null,
        to: null,
        amount: fee.amount,
        percentage: fee.percentageOfValue,
      });
    } else {
      // loop through tiers and create a new row for each
      fee.tieredFeeDetails.items.forEach((tier: TieredFeeDetails) => {
        result.push({
          name: fee.name,
          templateCode: fee.templateCode,
          feeFrequencyId: fee.frequencyId,
          feeMethodId: fee.methodId,
          feeCalculationTypeId: fee.calculationTypeId,
          tiered: fee.methodId === FeeMethod.Tiered.id,
          from: tier.from,
          to: tier.to,
          amount: tier.amount,
          percentage: tier.percentage,
        });
      });
    }
  });
  return result;
};

export const processClient = createAsyncThunk(OnboardActionTypes.ProcessClient, async (id: string) => {
  // strip out the 'items' from the editable collections
  const payload = {
    id,
  };

  // save to backend
  return api
    .post(`${OnboardApiEndpoints.ProcessClient}`, payload)
    .then(() => {
      return { message: 'Client submitted.  Client will be available in a few minutes.' };
    })
    .catch(() => {
      return { message: 'Failed to submit client', variant: 'error' };
    });
});
