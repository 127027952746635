import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PagedRequest } from '../../../../store';
import { WorkflowListState, WorkflowItem, FetchPagedResults } from './types';
import { fetchWorkflowList, uploadSuperMemberDataCsv } from './thunks';
export const initialState: WorkflowListState = {
  parameters: {
    pagination: {
      pageNumber: 1,
      pageSize: 25,
      queryFields: [{ fieldName: 'createdOn', descendingSortDirection: true, hasSearchTerm: false, isSortTerm: true, searchTerm: '', operator: '' }],
    },
  },
  workflows: null,
  importErrors: [],
  importUploadSuccess: false,
  isImporting: false,
};

export const workflowListSlice = createSlice({
  name: '@@workflows/list',
  initialState,
  reducers: {
    setPaginationParameter: (state, action: PayloadAction<PagedRequest>) => {
      state.parameters.pagination = action.payload;
    },
    resetModelUploadSuccessStatus: (state, action: PayloadAction<boolean>) => {
      state.importUploadSuccess = action.payload;
    },
    setErrorOnFileImport: (state, action: PayloadAction<string[]>) => {
      state.importErrors = action.payload;
      state.importUploadSuccess = false;
      state.isImporting = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchWorkflowList.fulfilled, (state, action: PayloadAction<FetchPagedResults<WorkflowItem>>) => {
      state.parameters.pagination = action.payload.pagination;
      state.workflows = action.payload.results;
    });
    builder.addCase(uploadSuperMemberDataCsv.pending, (state) => {
      state.importErrors = [];
      state.importUploadSuccess = false;
      state.isImporting = true;
    });
    builder.addCase(uploadSuperMemberDataCsv.fulfilled, (state) => {
      state.importUploadSuccess = true;
      state.isImporting = false;
    });
    builder.addCase(uploadSuperMemberDataCsv.rejected, (state, action) => {
      if (action.payload) {
        state.importErrors = action.payload;
      }
      state.importUploadSuccess = false;
      state.isImporting = false;
    });
  },
});
