import { Box, Paper } from '@mui/material';
import React from 'react';
import { ClientSideDataTable } from '../../../../../common/components/dataTable/clientSide';
import { Props } from '../resultsContainer';
import { AccountSearchResult } from '../store/types';
import WO2Button from './../../../../../common/components/button/Button';
import { getResultColumns } from './resultColumns';

export const Results = (props: Props): JSX.Element => {
  const { history } = props;
  const { accountSlected, accountList, setAccountSelected, setDefaultParameters, isLoadingProgress } = props;
  const columns = getResultColumns(accountList);

  const handleNext = () => {
    setDefaultParameters();
    history.push('email/create');
  };

  return (
    <Paper elevation={0}>
      <Box display="flex" flexDirection="row" justifyContent="flex-end" padding="24px">
        <WO2Button
          disabled={accountSlected.length <= 0}
          disableElevation
          color={'primary'}
          onClick={() => accountSlected.length && handleNext()}
          variant="contained"
          id="next_button"
        >
          Next
        </WO2Button>
      </Box>
      <ClientSideDataTable<AccountSearchResult>
        loadingProgress={isLoadingProgress}
        columns={columns}
        data={accountList}
        options={{
          viewColumns: false,
          filter: false,
          selectableRows: 'multiple',
          selectableRowsHideCheckboxes: false,
          onRowSelectionChange: (currentRowsSelected, allRowsSelected, rowsSelected) => {
            const selected = rowsSelected ?? [];
            const data = accountList.filter((el: AccountSearchResult, i) => selected.some((j) => i === j));
            setAccountSelected(data);
          },
        }}
      ></ClientSideDataTable>
      <Box display="flex" flexDirection="row" justifyContent="flex-end" padding="24px">
        <WO2Button
          disabled={accountSlected.length <= 0}
          disableElevation
          color={'primary'}
          onClick={() => accountSlected.length && handleNext()}
          variant="contained"
          id="next_button"
        >
          Next
        </WO2Button>
      </Box>
    </Paper>
  );
};
