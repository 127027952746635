import { FeeCalculationType, FeeFrequency, FeeMethod } from '../enums';
import { EstimatedFee, TieredFeeDetails } from '../types';

interface CalculationResults {
  amount: number;
  percentageOfValue: number;
}

export const calculateAmountAndPercentage = (
  feeDetails: EstimatedFee,
  totalRolloverAmount: number,
  contributionsAmount: number,
  isFeeConvertedFromMonthToAnnual: boolean
): CalculationResults => {
  const totalAmount = +(totalRolloverAmount + (feeDetails.calculationTypeId === FeeCalculationType.UpfrontAdviceFee.id ? 0 : contributionsAmount)).toFixed(2);
  let amount = 0;
  let percentageOfValue = 0;

  if (feeDetails.methodId === FeeMethod.Dollar.id) {
    amount = feeDetails.amount ?? 0;

    // the annual fee needs to be multiplied by 12 when the frequency is per month
    if (
      isFeeConvertedFromMonthToAnnual &&
      feeDetails.calculationTypeId === FeeCalculationType.OngoingAdviceFee.id &&
      feeDetails.frequencyId === FeeFrequency.PerMonth.id
    ) {
      amount = +(12 * amount).toFixed(2);
    }

    // need to use ceil to calculate percentage for validation
    percentageOfValue = totalAmount > 0 ? +(Math.ceil((amount / totalAmount) * 10000) / 100).toFixed(4) : 0;
  } else if (feeDetails.methodId === FeeMethod.Percentage.id) {
    amount = totalAmount > 0 ? +((totalAmount * (feeDetails.percentageOfValue ?? 0)) / 100).toFixed(2) : 0;
    percentageOfValue = feeDetails.percentageOfValue ?? 0;
  } else if (feeDetails.methodId === FeeMethod.Tiered.id) {
    const tieredFeeDetailsItems = feeDetails.tieredFeeDetails.items;
    const lastValidTieredItemIndex =
      (tieredFeeDetailsItems[tieredFeeDetailsItems.length - 1].to ?? 0) < totalAmount
        ? tieredFeeDetailsItems.length - 1
        : tieredFeeDetailsItems.findIndex(
            (item) => !!item.to && ((item.from < totalAmount && item.to > totalAmount) || item.from === totalAmount || item.to === totalAmount)
          );

    if (lastValidTieredItemIndex >= 0) {
      const validTieredFeeDetailsItems = tieredFeeDetailsItems.slice(0, lastValidTieredItemIndex + 1);
      const sumAmount = validTieredFeeDetailsItems.map((item) => item.amount ?? 0).reduce((acc: number, value: number) => acc + value);
      const sumAmountByPercentage = validTieredFeeDetailsItems.reduce(
        (acc: number, tieredFeeDetailsItem: TieredFeeDetails, index: number, arraySelf: TieredFeeDetails[]) => {
          return (
            acc +
            (parseFloat(
              (
                (index === arraySelf.length - 1 && (tieredFeeDetailsItem?.to ?? 0) >= totalAmount ? totalAmount : tieredFeeDetailsItem.to ?? 0) -
                tieredFeeDetailsItem.from
              ).toFixed(2)
            ) *
              (tieredFeeDetailsItem?.percentage ?? 0)) /
              100
          );
        },
        0
      );

      amount = +(sumAmount + sumAmountByPercentage).toFixed(2);
      percentageOfValue = totalAmount > 0 ? +(Math.ceil((amount / totalAmount) * 10000) / 100).toFixed(4) : 0;
    }
  }

  return {
    amount: amount,
    percentageOfValue: percentageOfValue,
  };
};

export const calculateEstimatedFeesItems = (estimatedFeesItems: EstimatedFee[], totalRolloverAmount: number, contributionsAmount: number): EstimatedFee[] => {
  const result = estimatedFeesItems.map((estimatedFeesItem) => {
    const calculatedAmountAndPercentage = calculateAmountAndPercentage(estimatedFeesItem, totalRolloverAmount, contributionsAmount, true);
    return {
      ...estimatedFeesItem,
      amount: calculatedAmountAndPercentage.amount,
      percentageOfValue: calculatedAmountAndPercentage.percentageOfValue,
    };
  });

  // add total row if result is not empty
  if (result.length > 0) {
    const totalFeeData = {
      index: -1,
      templateCode: null,
      name: '',
      calculationTypeId: FeeCalculationType.TotalFee.id,
      methodId: null,
      amount: result.reduce((acc: number, estimatedFeeItem: EstimatedFee) => {
        return +(acc + (estimatedFeeItem.amount || 0)).toFixed(2);
      }, 0),
      frequencyId: null,
      percentageOfValue: result.reduce((acc: number, estimatedFeeItem: EstimatedFee) => {
        return +(acc + (estimatedFeeItem.percentageOfValue || 0)).toFixed(4);
      }, 0),
      tieredFeeDetails: {
        items: [],
      },
    };

    result.push(totalFeeData);
  }

  return result;
};
