import { Container, Grid, Typography } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { DateTime } from 'luxon';
import React, { useCallback, useEffect } from 'react';
import { LoadingIndicator } from 'src/common/components/LoadingIndicator';
import { formatDollars, formatPercentage } from '../../../../common';
import CardComponent from '../../../../common/components/CardComponent';
import { theme } from '../../../../themes';
import { Props } from '../container';
import { FetchMonthlyDashboardChartPayload, StrategyVersionCompositionItem } from '../store/types';
import { MonthlyPerformanceChartContainer } from './MonthlyPerformanceChartContainer';

export const DashboardComponent = (props: Props): JSX.Element => {
  const {
    parameters,
    loadingProgresStrategyDetails,
    loadingProgresStrategyDashboard,
    strategyDetails,
    strategyDashboard,
    fetchMonthlyDashboardChart,
    fetchStrategyDetails,
    fetchStrategyDashboard,
    match: { params },
  } = props;

  const fetch = useCallback(() => {
    if (params.strategyId) {
      const monthlyDashboardChartRequestPayload: FetchMonthlyDashboardChartPayload = {
        strategyId: parseInt(params.strategyId),
        fromDate: parameters.performanceChartStartDate.startDate,
        toDate: DateTime.now().toISODate(),
      };
      fetchStrategyDashboard({ strategyId: parseInt(params.strategyId) });
      fetchStrategyDetails({ strategyId: parseInt(params.strategyId) });
      fetchMonthlyDashboardChart(monthlyDashboardChartRequestPayload);
    }
  }, [params.strategyId, fetchMonthlyDashboardChart, fetchStrategyDetails, fetchStrategyDashboard]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  const chartColors = [
    '#2c82be',
    '#429ff9',
    '#423cff',
    '#0080ff',
    '#000080',
    '#008ecc',
    '#008081',
    '#4482b4',
    '#111e6c',
    '#1d2951',
    '#dbecf8',
    '#2c82be',
    '#429ff9',
    '#423cff',
    '#0080ff',
    '#000080',
    '#008ecc',
    '#008081',
    '#4482b4',
    '#111e6c',
    '#1d2951',
    '#dbecf8',
    '#2c82be',
    '#429ff9',
    '#423cff',
    '#0080ff',
    '#000080',
    '#008ecc',
    '#008081',
    '#4482b4',
    '#111e6c',
    '#1d2951',
    '#dbecf8',
  ];

  const chartData = strategyDashboard?.strategyVersionCompositionItems.map((i: StrategyVersionCompositionItem, index: number) => ({
    y: Math.abs(i.allocation / 100),
    name: i.securityName,
    color: chartColors[index],
  }));
  const chartOptions = {
    chart: {
      type: 'pie',
    },
    allowPointSelect: true,
    title: {
      verticalAlign: 'middle',
      floating: true,
      text: '',
    },
    colors: chartColors,
    subtitle: {
      text: '',
    },
    plotOptions: {
      pie: {
        innerSize: '60%',
        depth: 45,
        tooltip: {
          pointFormatter: function (this: Highcharts.TooltipFormatterContextObject) {
            return `<span style="color:${this.color}">●</span> <b>${this.percentage?.toFixed(2)}%</b><br/>`;
          },
        },
      },
    },
    series: [
      {
        allowPointSelect: true,
        name: '',
        data: chartData,
        dataLabels: {
          enabled: false,
        },
      },
    ],
    credits: {
      enabled: false,
    },
  };

  const rightTableHeader = (
    <Grid container>
      <Grid item xs={3}>
        <Typography variant="h6" style={{ paddingLeft: '15px' }}>
          CODE
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="h6" style={{ paddingLeft: '8px' }}>
          NAME
        </Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography variant="h6" align="right" style={{ paddingRight: '13px' }}>
          TARGET ALLOCATION
        </Typography>
      </Grid>
    </Grid>
  );

  const rightTableRows = strategyDashboard?.strategyVersionCompositionItems.map((row: StrategyVersionCompositionItem, index: number) => {
    return (
      <ListItem key={index} divider>
        <Grid container>
          <Grid item xs={3}>
            <Typography variant={'h5'}>{row.securityCode}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant={'h5'} color={'primary'}>
              {row.securityName}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant={'h5'} align="right">
              {formatPercentage(row.allocation / 100)}
            </Typography>
          </Grid>
        </Grid>
      </ListItem>
    );
  });

  return (
    <>
      <MonthlyPerformanceChartContainer {...props} />
      <Container style={{ paddingTop: '20px', paddingBottom: '20px' }}>
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <CardComponent
              progress={loadingProgresStrategyDetails}
              background={theme.palette.gradient1?.main}
              value={formatDollars(strategyDetails?.fua)}
              subText={'FUA'}
            />
          </Grid>
          <Grid item xs={3}>
            <CardComponent
              progress={loadingProgresStrategyDetails}
              background={theme.palette.gradient2?.main}
              value={strategyDetails?.portfolioCount}
              subText={'Portfolios'}
            />
          </Grid>
          <Grid item xs={3}>
            <CardComponent
              progress={loadingProgresStrategyDashboard}
              background={theme.palette.gradient1?.main}
              value={formatPercentage(strategyDashboard?.oneMonthReturn)}
              subText={'1 MONTH RETURN'}
            />
          </Grid>
          <Grid item xs={3}>
            <CardComponent
              progress={loadingProgresStrategyDashboard}
              background={theme.palette.gradient2?.main}
              value={formatPercentage(strategyDashboard?.inceptionReturn)}
              subText={'INCEPTION RETURN'}
            />
          </Grid>
        </Grid>

        <Grid container spacing={2} style={{ paddingTop: '10px' }}>
          <Grid item xs={4}>
            <Card elevation={0}>
              <Grid container>
                <Typography variant="h3" align="left" style={{ padding: '10px' }}>
                  All Securities
                </Typography>
              </Grid>
              <CardContent>
                <Grid item>
                  <HighchartsReact highcharts={Highcharts} options={chartOptions} />
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={8}>
            <Card elevation={0}>
              <CardContent>
                <LoadingIndicator progress={loadingProgresStrategyDashboard}>
                  <Grid>{rightTableHeader}</Grid>
                  <List>{rightTableRows}</List>
                </LoadingIndicator>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};
