import { Typography } from '@mui/material';
import { MUIDataTableOptions } from 'mui-datatables';
import React from 'react';
import { formatDollars } from '../../../../common';
import { ClientSideDataTable } from '../../../../common/components/dataTable/clientSide/clientSideDataTable';
import { DatatableColumn, FilterDataType } from '../../../../common/components/dataTable/types';
import { LoadingProgress } from '../../../../common/store/types';
import { theme } from '../../../../themes';
import { ModelAssociationItem } from '../store/types';

export interface Props {
  progress: LoadingProgress;
  data: ModelAssociationItem[];
}
export function AssociationsTable({ progress, data }: Props): JSX.Element {
  const columns: DatatableColumn[] = [
    {
      label: 'PORTFOLIO ID',
      name: 'investmentServiceCode',
      options: {
        customBodyRenderLite: function customBodyRenderLite(dataIndex): React.ReactNode {
          return (
            <Typography
              variant={'h5'}
              color={'primary'}
              style={{
                color: theme.palette.primary.main,
                fontWeight: 'bold',
                letterSpacing: '1px',
              }}
            >
              {data[dataIndex].investmentServiceCode}
            </Typography>
          );
        },
        sort: false,
        viewColumns: false,
      },
    },
    {
      name: 'clientName',
      label: 'CLIENT AND PORTFOLIO NAME',
      textAlign: 'left',
      options: {
        customBodyRenderLite: function customBodyRenderLite(dataIndex): React.ReactNode {
          return (
            <>
              <Typography variant={'h5'} align="left">
                {data[dataIndex].clientName}
              </Typography>
              <Typography color={'textSecondary'} variant={'h6'} align="left">
                {data[dataIndex].investmentServiceName}
              </Typography>
            </>
          );
        },
        sort: true,
      },
      filterDataType: FilterDataType.string,
    },
    {
      name: 'value',
      label: 'MODEL VALUE',
      textAlign: 'right',
      options: {
        customBodyRenderLite: (dataIndex: number) => formatDollars(data[dataIndex].value),
        sort: true,
      },
      filterDataType: FilterDataType.numeric,
    },
  ];
  const options: MUIDataTableOptions = {
    search: true,
    download: true,
    filter: false,
  };
  return (
    <ClientSideDataTable
      data={!!progress.isLoading ? [] : data}
      columns={columns}
      options={options}
      loadingProgress={progress}
      id="model-associations-datatable"
    />
  );
}
