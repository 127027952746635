import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { selectHasClientEditAdminOnly } from 'src/store/selectors';
import { progressSelectorFactory } from '../../../common/store/selectors';
import { RootState } from '../../../reducers';
import { WorkflowList } from './components/list';
import { selectWorkflowListResults, selectParameters, selectImportErrors, selectImportUploadSuccess, selectIsImporting } from './store/selectors';
import { workflowListSlice } from './store/slice';
import { fetchWorkflowList, WorkflowListActionTypes, uploadSuperMemberDataCsv } from './store/thunks';

const mapStateToProps = (state: RootState) => ({
  workflows: selectWorkflowListResults(state),
  parameters: selectParameters(state),
  loadingProgress: progressSelectorFactory([WorkflowListActionTypes.FetchWorkflowList])(state),
  workflowsList: selectWorkflowListResults(state),
  workflowsListProgress: progressSelectorFactory([WorkflowListActionTypes.FetchWorkflowList])(state),
  hasAdminAccess: selectHasClientEditAdminOnly(state),
  importErrors: selectImportErrors(state),
  importUploadSuccess: selectImportUploadSuccess(state),
  isImporting: selectIsImporting(state),
});

const mapDispatchToProps = {
  ...workflowListSlice.actions,
  fetchWorkflowList,
  uploadSuperMemberDataCsv,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export type Props = ConnectedProps<typeof connector> & RouteComponentProps;
export const WorkflowListStateContainer = connector(WorkflowList);
