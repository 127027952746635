import InfoIcon from '@mui/icons-material/Info';
import { Box, Tooltip, Typography } from '@mui/material';
import { Form, Formik, FormikProps } from 'formik';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { LoadingIndicator } from 'src/common/components/LoadingIndicator';
import * as yup from 'yup';
import WO2Button from '../../../../../../common/components/button/Button';
import { EditCancelSaveButtons, Mode } from '../../../../../../common/components/formik';
import { theme } from '../../../../../../themes';
import { feeGridHelpText, feesGridGstText, feesGridTooltipTexts } from '../../../../common/config/adviceFees';
import { estimatedFeeValidations } from '../../../../common/config/estimatedFeeValidations';
import { FeeCalculationType, FeeFrequency, FeeMethod } from '../../../../common/enums';
import { AdviceFeesValues, EstimatedFee, EstimatedFeeValidation, TieredFeeDetails } from '../../../../common/types';
import { calculateAmountAndPercentage, calculateEstimatedFeesItems } from '../../../../common/utils/calculation';
import { Props } from '../container';
import { FeeRequestModel } from '../store/types';
import { AdviceFeeDetailsEdit } from './adviceFeeDetailsEdit';
import { AdviceFeesTable } from './adviceFeesTable';

export const AdviceFees = (props: Props): JSX.Element => {
  const {
    clientId,
    superSimplifierItem,
    adviceFeesValues,
    estimatedFeesItems,
    selectedFeeItem,
    saveEstimatedFee,
    removeEstimatedFee,
    restoreEstimatedFeesItems,
    tieredFeeDetailsItems,
    selectedTieredFeeDetailsEdit,
    setEstimatedFeeEdit,
    setTieredFeeDetailsEditId,
    setTieredFeeDetailsAdd,
    saveEditingTieredFeeDetails,
    removeEditingTieredFeeDetails,
    clearEditingTieredFeeDetails,
    hasClientEditAdminOnlyPermission,
    loadingProgress,
    savingProgress,
    fetchAdviceFees,
    loadAdviceFees,
    saveAdviceFees,
  } = props;

  const formRef = useRef<FormikProps<AdviceFeesValues>>(null);
  const [readonly, setReadonly] = useState<boolean>(true);
  const [isViewMode, setViewMode] = useState<boolean>(false);
  const [feeDetailsEditOpen, setFeeDetailsEditOpen] = useState<boolean>(false);
  const [existingEstimatedFeesItems, setExistingEstimatedFeesItems] = useState<EstimatedFee[]>(estimatedFeesItems);

  useEffect(() => {
    if (!!clientId) {
      fetchAdviceFees({ clientId, isImported: false });
    }
  }, [fetchAdviceFees, clientId]);

  useEffect(() => {
    // update state if form values are submitted successfully
    if (adviceFeesValues.isSubmitted) {
      setExistingEstimatedFeesItems(estimatedFeesItems);
    }
  }, [estimatedFeesItems, adviceFeesValues.isSubmitted]);

  const totalRolloverAmount =
    (superSimplifierItem?.rolloverAmount ?? 0) + (superSimplifierItem?.rolloverAmountSecond ?? 0) + (superSimplifierItem?.rolloverAmountThird ?? 0);

  const contributionsAmount = superSimplifierItem?.contributionsAmount ?? 0;

  const formValues: AdviceFeesValues = {
    estimatedFeesItems: estimatedFeesItems,
  };

  const handleCloseFeeDetailsEditModal = () => {
    clearEditingTieredFeeDetails();
    setFeeDetailsEditOpen(false);
  };

  const handleImportStandardFeesButtonClick = useCallback(() => {
    if (clientId) {
      loadAdviceFees(clientId);
    }
  }, [loadAdviceFees, clientId]);

  const handleAddButtonClick = () => {
    setEstimatedFeeEdit(null);
    setViewMode(false);
    setFeeDetailsEditOpen(true);
  };

  const handleCancelClick = useCallback(() => {
    setEstimatedFeeEdit(undefined);
  }, [setEstimatedFeeEdit]);

  const onAddEditEstimatedFee = (index: number, isViewMode: boolean) => {
    setEstimatedFeeEdit(index);
    setViewMode(isViewMode);
    setFeeDetailsEditOpen(true);
  };

  const onDeleteEstimatedFee = (index: number) => {
    removeEstimatedFee(index);
  };

  const onSaveFeeDetails = (feeDetails: EstimatedFee) => {
    saveEstimatedFee({
      ...feeDetails,
      amount: feeDetails?.methodId === FeeMethod.Dollar.id ? feeDetails.amount : 0,
      percentageOfValue: feeDetails?.methodId === FeeMethod.Percentage.id ? feeDetails.percentageOfValue : 0,
    });
  };

  return (
    <div style={{ width: '100%', maxWidth: '1120px' }}>
      <Typography variant="h3" style={{ padding: '20px 0' }}>
        Advice Fees
      </Typography>
      <LoadingIndicator progress={loadingProgress}>
        <>
          <Formik<AdviceFeesValues>
            enableReinitialize={true}
            initialValues={formValues}
            innerRef={formRef}
            onSubmit={async () => {
              if (!!clientId) {
                const feesValues: FeeRequestModel[] = [];
                formValues.estimatedFeesItems
                  .map((estimatedFeesItem) => {
                    const calculatedAmountAndPercentage = calculateAmountAndPercentage(estimatedFeesItem, totalRolloverAmount, contributionsAmount, false);
                    return {
                      ...estimatedFeesItem,
                      feeAmount: estimatedFeesItem?.methodId === FeeMethod.Dollar.id ? calculatedAmountAndPercentage.amount : 0,
                      percentageOfValue: estimatedFeesItem?.methodId === FeeMethod.Percentage.id ? calculatedAmountAndPercentage.percentageOfValue : 0,
                    };
                  })
                  .forEach((feeItem) => {
                    if (feeItem.methodId === FeeMethod.Tiered.id) {
                      feeItem.tieredFeeDetails.items.forEach((tieredFeeItem: TieredFeeDetails) => {
                        feesValues.push({
                          feeFrequencyId: FeeFrequency.NotDefined.id,
                          feeCalculationTypeId: feeItem.calculationTypeId,
                          feeMethodId: feeItem.methodId ?? 0,
                          from: tieredFeeItem.from,
                          to: tieredFeeItem.to,
                          amount: tieredFeeItem.amount,
                          percentage: tieredFeeItem.percentage ? +tieredFeeItem.percentage.toFixed(4) : null,
                          templateCode: feeItem.templateCode,
                        });
                      });
                    } else if (feeItem.methodId === FeeMethod.Dollar.id || feeItem.methodId === FeeMethod.Percentage.id) {
                      // non-tiered fees
                      feesValues.push({
                        feeFrequencyId: feeItem.frequencyId ?? FeeFrequency.NotDefined.id,
                        feeMethodId: feeItem.methodId ?? 0,
                        feeCalculationTypeId: feeItem.calculationTypeId,
                        from: null,
                        to: null,
                        amount: feeItem.methodId === FeeMethod.Percentage.id ? null : feeItem.feeAmount,
                        percentage: feeItem.methodId === FeeMethod.Percentage.id ? +feeItem.percentageOfValue.toFixed(4) : null,
                        templateCode: feeItem.templateCode,
                      });
                    }
                  });

                await saveAdviceFees({ adviceFees: { clientId: clientId, fees: feesValues }, message: 'Advice Fees saved' });
                setReadonly(true);
              }
            }}
            validationSchema={yup.object({
              estimatedFeesItems: yup.array().of(
                yup.object().shape({
                  calculationTypeId: yup.number(),
                  percentageOfValue: yup.number().when('calculationTypeId', {
                    is: (calculationTypeId) =>
                      calculationTypeId === FeeCalculationType.OngoingAdviceFee.id ||
                      calculationTypeId === FeeCalculationType.UpfrontAdviceFee.id ||
                      calculationTypeId === FeeCalculationType.PortfolioManagementFee.id,
                    then: yup.number().test({
                      name: 'max',
                      exclusive: true,
                      message: 'Percentage of value is invalid',
                      test: function () {
                        const feeValidationItem = estimatedFeeValidations.find(
                          (validation: EstimatedFeeValidation) => validation.calculationTypeId === this.parent.calculationTypeId
                        );

                        return (
                          !feeValidationItem ||
                          calculateAmountAndPercentage(this.parent, totalRolloverAmount, contributionsAmount, true).percentageOfValue <=
                            feeValidationItem.maxPercentage
                        );
                      },
                    }),
                  }),
                })
              ),
            })}
          >
            {(formikProps: FormikProps<AdviceFeesValues>) => (
              <Form>
                <Box
                  display={'flex'}
                  justifyContent={'space-between'}
                  alignItems={'center'}
                  margin={'60px 0 30px'}
                  minHeight={'50px'}
                  style={{ pointerEvents: readonly ? 'none' : 'auto' }}
                >
                  <Typography variant="h2" style={{ fontSize: '18px' }}>
                    Estimated Fees
                  </Typography>
                  <Box data-testid="feeButtonsBox">
                    <WO2Button
                      color={'primary'}
                      style={{ padding: '0 30px' }}
                      variant={'contained'}
                      value="Import Standard Fees"
                      type={'button'}
                      disabled={readonly}
                      data-testid="importStandardFeesButton"
                      onClick={handleImportStandardFeesButtonClick}
                    >
                      Import Standard Fees
                    </WO2Button>
                    <WO2Button
                      color={'primary'}
                      style={{ marginLeft: '30px', padding: '0 30px' }}
                      variant={'contained'}
                      value="Add New Fee"
                      type={'button'}
                      disabled={readonly}
                      data-testid="addNewFeeButton"
                      onClick={handleAddButtonClick}
                    >
                      <Typography variant="inherit">+</Typography>
                      <Typography variant="inherit" style={{ marginLeft: '20px' }}>
                        Add New Fee
                      </Typography>
                    </WO2Button>
                  </Box>
                </Box>
                <Typography variant="body1" gutterBottom>
                  {feesGridGstText}
                </Typography>
                <AdviceFeesTable
                  estimatedFeesItems={calculateEstimatedFeesItems(formikProps.values.estimatedFeesItems, totalRolloverAmount, contributionsAmount)}
                  loadingProgress={{ isLoading: false, hasErrors: false }}
                  readonly={readonly}
                  onAddEdit={onAddEditEstimatedFee}
                  onDelete={onDeleteEstimatedFee}
                />
                <Box display={'flex'} alignItems={'center'} marginBottom={'80px'} height={'20px'}>
                  <Typography variant="body1">{feeGridHelpText}</Typography>
                  <Tooltip
                    title={
                      <Box>
                        {feesGridTooltipTexts.map((tooltipText, index) => (
                          <Typography key={index} variant="inherit" style={{ padding: '10px' }}>
                            {tooltipText}
                          </Typography>
                        ))}
                      </Box>
                    }
                    placement="right"
                    arrow
                  >
                    <InfoIcon style={{ width: '18px', height: '18px', margin: '0 0 2px 2px', fill: `${theme.palette.primary.main}` }} />
                  </Tooltip>
                </Box>
                {hasClientEditAdminOnlyPermission && (
                  <Box paddingTop={'20px'}>
                    <EditCancelSaveButtons
                      mode={Mode.EditCancelSave}
                      readonly={readonly}
                      disabledButtonTypes={undefined}
                      handleEditClick={() => {
                        // save current estimatedFeesItems to state for restore when cancel button is clicked
                        setExistingEstimatedFeesItems(estimatedFeesItems);
                        setReadonly(!readonly);
                      }}
                      handleCancelClick={() => {
                        // restore estimatedFeesItems with old values
                        restoreEstimatedFeesItems(existingEstimatedFeesItems);
                        setReadonly(!readonly);
                      }}
                      saveProgress={savingProgress}
                    ></EditCancelSaveButtons>
                  </Box>
                )}
              </Form>
            )}
          </Formik>
          {hasClientEditAdminOnlyPermission && feeDetailsEditOpen && (
            <AdviceFeeDetailsEdit
              estimatedFeesItems={estimatedFeesItems}
              selectedFeeItem={selectedFeeItem}
              tieredFeeDetailsItems={tieredFeeDetailsItems}
              selectedTieredFeeDetailsEdit={selectedTieredFeeDetailsEdit}
              isViewMode={isViewMode}
              onSave={onSaveFeeDetails}
              onCancel={handleCancelClick}
              handleCloseModal={handleCloseFeeDetailsEditModal}
              setTieredFeeDetailsEditId={setTieredFeeDetailsEditId}
              setTieredFeeDetailsAdd={setTieredFeeDetailsAdd}
              saveEditingTieredFeeDetails={saveEditingTieredFeeDetails}
              removeEditingTieredFeeDetails={removeEditingTieredFeeDetails}
            />
          )}
        </>
      </LoadingIndicator>
    </div>
  );
};
