import { createAsyncThunk } from '@reduxjs/toolkit';
import { DatePickerNames } from 'src/common/components/DateRangePickerPreDefinedDates';
import api from '../../../../../app/api';
import { buildEncodedQueryString } from '../../../../../common';
import { PagedResult } from '../../../../../store';
import { ModelItem, SecurityItem } from '../../../common/store/types';
import { ReportSearchParameters, ReportSearchResult } from './types';

export enum ReportSearchActionTypes {
  FetchResults = '@@bulk/report/search/FetchResults',
  FetchSecurities = '@@bulk/report/search/FetchSecurities',
  FetchModels = '@@bulk/common/FetchModels',
}

export enum ReportSearchApi {
  FetchModels = '/strategies/GetModelList',
  FetchResults = '/holdings/GetFilteredClients',
  FetchSecurities = '/securities/GetSecuritiesBySearchTerm',
}

export const fetchSecurities = createAsyncThunk(ReportSearchActionTypes.FetchSecurities, async (searchTerm: string) => {
  const payload = {
    pagedRequest: {
      pageNumber: 1,
      pageSize: 200,
      queryFields: [
        {
          fieldName: 'name',
          hasSearchTerm: true,
          searchTerm: searchTerm,
          operator: 'Contains',
          isSortTerm: true,
          descendingSortDirection: false,
        },
        {
          fieldName: 'code',
          hasSearchTerm: true,
          searchTerm: searchTerm,
          operator: 'Contains',
          isSortTerm: true,
          descendingSortDirection: false,
        },
      ],
    },
  };

  const response = await api.post<PagedResult<SecurityItem>>(`${ReportSearchApi.FetchSecurities}`, payload);
  return response.data.results;
});
export const fetchModels = createAsyncThunk(ReportSearchActionTypes.FetchModels, async (afslIds?: number[]) => {
  const response = await api.post(`${ReportSearchApi.FetchModels}`, !!afslIds ? afslIds : []);
  return Promise.resolve<ModelItem[]>(response.data);
});
export const fetchResults = createAsyncThunk(ReportSearchActionTypes.FetchResults, async (parameters: ReportSearchParameters) => {
  const queryString = buildEncodedQueryString({
    afslIds: parameters.afsls.map((i) => i.afslId),
    practiceIds: parameters.practices.map((i) => i.practiceId),
    adviserIds: parameters.advisers.map((i) => i.advisorId),
    clientTypes: parameters.clientTypes,
    statuses: parameters.statuses,
    serviceType: parameters.serviceType,
    servicesAgreementDate:
      parameters.servicesAgreementDate.dateRangeType === DatePickerNames.InceptionToDate
        ? null
        : {
            dateRangeType: parameters.servicesAgreementDate.dateRangeType,
            fromDate: parameters.servicesAgreementDate.dateFrom,
            toDate: parameters.servicesAgreementDate.dateTo,
          },
    inceptionDate:
      parameters.inceptionDate.dateRangeType === DatePickerNames.InceptionToDate
        ? null
        : {
            dateRangeType: parameters.inceptionDate.dateRangeType,
            fromDate: parameters.inceptionDate.dateFrom,
            toDate: parameters.inceptionDate.dateTo,
          },
    holdingsDate: {
      asAtDateType: parameters.holdingsDate.dateRangeType,
      asAtDate: parameters.holdingsDate.dateTo,
    },
    holdingsValue:
      parameters.holdingsValue.parameterType === ''
        ? null
        : {
            parameterType: parameters.holdingsValue.parameterType,
            fromValue: parameters.holdingsValue.fromValue,
            toValue: parameters.holdingsValue.toValue,
          },
    availableCashBalance:
      parameters.availableCashBalance.parameterType === ''
        ? null
        : {
            parameterType: parameters.availableCashBalance.parameterType,
            fromValue: parameters.availableCashBalance.fromValue,
            toValue: parameters.availableCashBalance.toValue,
          },
    associations: parameters.associations.map((item) => {
      return {
        associationType: item.associationType.name,
        id: item.id,
        isHeld: item.isHeld,
      };
    }),
  });
  const response = await api.get<ReportSearchResult[]>(`${ReportSearchApi.FetchResults}${queryString}`);
  return response.data;
});
