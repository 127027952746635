import { createAsyncThunk } from '@reduxjs/toolkit';
import { ModelItem } from 'src/features/bulk/common/store/types';
import api from '../../../../../app/api';
import { buildEncodedQueryString } from '../../../../../common';
import { AccountSearchParameters, AccountSearchResult } from './types';

export enum EmailSearchTypes {
  FetchAccountResults = '@@bulk/email/GetFilteredClientEmails',
  FetchModels = '@@bulk/common/FetchModels',
}

export enum EmailSearchApi {
  FetchAccountTemplate = '/holdingsnext/GetFilteredClientEmails',
  FetchModels = '/strategies/GetModelList',
}

export const fetchAccountSearchResults = createAsyncThunk(EmailSearchTypes.FetchAccountResults, async (parameters: AccountSearchParameters | null) => {
  const queryString = parameters
    ? buildEncodedQueryString({
        afslIds: parameters.afsls.map((i) => i.afslId),
        practiceIds: parameters.practices.map((i) => i.practiceId),
        adviserIds: parameters.advisers.map((i) => i.advisorId),
        clientTypes: parameters.accountType,
        serviceTypeId: parameters.serviceTypeId,
        portfolioValueFrom: parameters.portfolioValueFrom,
        portfolioValueTo: parameters.portfolioValueTo,
        availableCashBalanceFrom: parameters.availableCashBalanceFrom,
        availableCashBalanceTo: parameters.availableCashBalanceTo,
        securityHeld: parameters.securityHeld,
        includeZeroHoldings: parameters.includeZeroHoldings,
        securityId: parameters.security ? parameters.security.id : null,
        modelId: parameters.model ? parameters.model.modelId : null,
      })
    : '';

  const response = await api.get<AccountSearchResult[]>(`${EmailSearchApi.FetchAccountTemplate}${queryString}`);
  return response.data;
});

export const fetchModels = createAsyncThunk(EmailSearchTypes.FetchModels, async (afslIds?: number[]) => {
  const response = await api.post(`${EmailSearchApi.FetchModels}`, !!afslIds ? afslIds : []);
  return Promise.resolve<ModelItem[]>(response.data);
});
