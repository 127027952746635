import DeleteIcon from '@mui/icons-material/Delete';
import InfoIcon from '@mui/icons-material/Info';
import { Alert, Box, Container, Divider, Fade, Grid, IconButton, Link, Tooltip, Typography } from '@mui/material';
import { Field, FieldProps, Form, Formik, FormikProps } from 'formik';
import React, { useCallback, useEffect, useState } from 'react';
import { useConfirmation } from 'src/common/components/dialogs/confirmationService';
import * as yup from 'yup';
import { ToggleButtonItem } from '../../../../../common';
import { FormikNumberFormat, FormikSwitch, FormikTextField, FormikToggleButton } from '../../../../../common/components/formik';
import { ClientAccountSubType, PensionType } from '../../../../../common/types';
import { PaymentFrequency, PaymentLimitType, PaymentType } from '../../../client/common/store/enums';
import {
  inSpecieLabelText,
  maxContributionsAmountValue,
  maxRolloverAmountValue,
  removeRolloverTooltipText,
  rolloverAmountFieldText,
} from '../../../common/config/superSimplifier';
import { RolloverType } from '../../../common/enums';
import { PageBackNextButtons } from '../../components/common/pageBackNextButtons';
import { blankSuperSimplifierDetails } from '../../store/constants';
import { SuperSimplifierDetails } from '../../store/types';
import { Props } from '../container';

interface FormValues extends SuperSimplifierDetails {
  rollover: boolean;
  additionalRollover: boolean;
  additionalRolloverSecond: boolean;
}

export const SuperSimplifier = (props: Props): JSX.Element => {
  const confirm = useConfirmation();
  const { id, saveProgress, history, prevNextRoutePaths, superSimplifierDetailsValues, saveSuperSimplifierDetailsValues } = props;

  const rolloverToggleButtons: ToggleButtonItem<boolean>[] = RolloverType.getArray().map((rollover: RolloverType) => {
    return { name: rollover.displayName, value: rollover.name === RolloverType.FullRollover.name };
  });

  const paymentFrequencyToggleButtons: ToggleButtonItem<number>[] = PaymentFrequency.getArray().map((paymentFrequency: PaymentFrequency) => {
    return { name: paymentFrequency.displayName, value: paymentFrequency.id };
  });

  const paymentLimitTypeToggleButtons: ToggleButtonItem<number>[] = PaymentLimitType.getArray().map((paymentLimit: PaymentLimitType) => {
    return { name: paymentLimit.displayName, value: paymentLimit.id };
  });

  const paymentTypeToggleButtons: ToggleButtonItem<number>[] = PaymentType.getArray().map((paymentType: PaymentType) => {
    return { name: paymentType.displayName, value: paymentType.id };
  });

  const rolloverAmountTooltipText =
    'WealthO2 is required to capture this amount to meet fee consent requirements. You must still obtain client consent in line with ASIC Instrument 2021/124.';

  const initialFormValues: FormValues = {
    superMemberNumber: '',
    subAccountTypeId: null,
    fundName: '',
    memberNumber: '',
    superAnnuationId: '',
    rolloverAmount: null,
    fullRollover: false,
    fundNameSecond: '',
    memberNumberSecond: '',
    superAnnuationIdSecond: '',
    rolloverAmountSecond: null,
    fullRolloverSecond: false,
    fundNameThird: '',
    memberNumberThird: '',
    superAnnuationIdThird: '',
    rolloverAmountThird: null,
    fullRolloverThird: false,
    isResident: true,
    ageOver65: false,
    ageOver60NotPaid: false,
    ageNotOver60NotPaid: false,
    reachedAge: false,
    atp: false,
    paymentFrequencyId: null,
    paymentLimitTypeId: null,
    paymentLimit: null,
    paymentTypeId: PaymentType.Neither.id,
    pensionTypeId: null,
    inSpecie: false,
    inSpecieSecond: false,
    inSpecieThird: false,
    contributionsAmount: null,
    rollover: false,
    additionalRollover: false,
    additionalRolloverSecond: false,
  };

  const [formValues, setFormValues] = useState<FormValues>(initialFormValues);

  useEffect(() => {
    if (!!superSimplifierDetailsValues) {
      setFormValues({
        ...superSimplifierDetailsValues,
        isResident: superSimplifierDetailsValues.isResident ?? false,
        ageOver65: superSimplifierDetailsValues.ageOver65 ?? false,
        ageOver60NotPaid: superSimplifierDetailsValues.ageOver60NotPaid ?? false,
        ageNotOver60NotPaid: superSimplifierDetailsValues.ageNotOver60NotPaid ?? false,
        reachedAge: superSimplifierDetailsValues.reachedAge ?? false,
        atp: superSimplifierDetailsValues.atp ?? false,
        paymentTypeId: superSimplifierDetailsValues.paymentTypeId ?? PaymentType.Neither.id,
        paymentLimitTypeId: !!superSimplifierDetailsValues.paymentLimitTypeId
          ? superSimplifierDetailsValues.paymentLimitTypeId === PaymentLimitType.Maximum.id &&
            superSimplifierDetailsValues.pensionTypeId !== PensionType.TransitionRetirementPension.id
            ? PaymentLimitType.Minimum.id
            : superSimplifierDetailsValues.paymentLimitTypeId
          : PaymentLimitType.Other.id,
        inSpecie: superSimplifierDetailsValues.inSpecie ?? false,
        inSpecieSecond: superSimplifierDetailsValues.inSpecieSecond ?? false,
        inSpecieThird: superSimplifierDetailsValues.inSpecieThird ?? false,
        rollover: !!superSimplifierDetailsValues.fundName,
        additionalRollover: !!superSimplifierDetailsValues.fundNameSecond,
        additionalRolloverSecond: !!superSimplifierDetailsValues.fundNameThird,
        contributionsAmount:
          superSimplifierDetailsValues.subAccountTypeId === ClientAccountSubType.Pension.id ? null : superSimplifierDetailsValues.contributionsAmount,
      });
    }
  }, [superSimplifierDetailsValues]);

  const save = useCallback(
    async (values: FormValues) => {
      const detailsValues = {
        // remove additionalRollover/additionalRolloverSecond from form values
        ...Object.entries(values).reduce(
          (results: SuperSimplifierDetails, [key, value]) => {
            let fieldValue = value;
            if (key === 'paymentLimit' && values.subAccountTypeId === ClientAccountSubType.Pension.id) {
              fieldValue = values.paymentLimitTypeId === PaymentLimitType.Other.id ? value : null;
            } else if (key === 'paymentTypeId' && values.paymentTypeId === PaymentType.Neither.id) {
              fieldValue = null;
            }
            return Object.keys(blankSuperSimplifierDetails).indexOf(key) === -1
              ? results
              : {
                  ...results,
                  [key]: fieldValue,
                };
          },
          { ...blankSuperSimplifierDetails }
        ),
        ...(!values.rollover && {
          fundName: '',
          memberNumber: '',
          superAnnuationId: '',
          rolloverAmount: null,
          fullRollover: false,
          inSpecie: false,
        }),
        ...(!values.additionalRollover && {
          fundNameSecond: '',
          memberNumberSecond: '',
          superAnnuationIdSecond: '',
          rolloverAmountSecond: null,
          fullRolloverSecond: false,
          inSpecieSecond: false,
        }),
        ...(!values.additionalRolloverSecond && {
          fundNameThird: '',
          memberNumberThird: '',
          superAnnuationIdThird: '',
          rolloverAmountThird: null,
          fullRolloverThird: false,
          inSpecieThird: false,
        }),
      };
      await saveSuperSimplifierDetailsValues(detailsValues);
    },
    [saveSuperSimplifierDetailsValues]
  );

  const onBackButtonClick = useCallback(
    async (values: FormValues) => {
      const { prevRoutePath } = prevNextRoutePaths;
      if (prevRoutePath) {
        await save(values);
        history.push(prevRoutePath + (!!id ? `?id=${id}` : ''));
      }
    },
    [history, prevNextRoutePaths, save, id]
  );

  return (
    <div>
      <Typography variant="h1" style={{ fontSize: '26px', fontWeight: 500, lineHeight: 1.3 }}>
        {"We'll need some additional information about the Super Simplifier account"}
      </Typography>
      {!!superSimplifierDetailsValues && (
        <Formik<FormValues>
          enableReinitialize={true}
          initialValues={formValues}
          validationSchema={yup.object({
            subAccountTypeId: yup.number(),
            rollover: yup.boolean(),
            additionalRollover: yup.boolean(),
            additionalRolloverSecond: yup.boolean(),
            contributionsAmount: yup.number().nullable().typeError('ESTIMATED CONTRIBUTIONS PER ANNUM must be a number'),
            fullRollover: yup.boolean(),
            fullRolloverSecond: yup.boolean(),
            fullRolloverThird: yup.boolean(),
            fundName: yup
              .string()
              .nullable()
              .when('rollover', { is: true, then: yup.string().required('FUNDNAME is required') }),
            fundNameSecond: yup
              .string()
              .nullable()
              .when('additionalRollover', { is: true, then: yup.string().required('FUND NAME is required') }),
            fundNameThird: yup
              .string()
              .nullable()
              .when('additionalRolloverSecond', { is: true, then: yup.string().required('FUND NAME is required') }),
            rolloverAmount: yup
              .number()
              .nullable()
              .when('rollover', { is: true, then: yup.number().required('ROLLOVER AMOUNT is required') }),
            rolloverAmountSecond: yup
              .number()
              .typeError('ROLLOVER AMOUNT must be a number')
              .nullable()
              .when('additionalRollover', {
                is: (additionalRollover) => additionalRollover,
                then: yup.number().typeError('ROLLOVER AMOUNT must be a number').required('ROLLOVER AMOUNT is required'),
              }),
            rolloverAmountThird: yup
              .number()
              .typeError('ROLLOVER AMOUNT must be a number')
              .nullable()
              .when('additionalRolloverSecond', {
                is: (additionalRolloverSecond) => additionalRolloverSecond,
                then: yup.number().typeError('ROLLOVER AMOUNT must be a number').required('ROLLOVER AMOUNT is required'),
              }),
            paymentFrequencyId: yup
              .number()
              .nullable()
              .when('subAccountTypeId', { is: ClientAccountSubType.Pension.id, then: yup.number().required('PAYMENT FREQUENCY is required') }),
            paymentLimitTypeId: yup
              .number()
              .nullable()
              .when('subAccountTypeId', { is: ClientAccountSubType.Pension.id, then: yup.number().required('PAYMENT LIMIT TYPE is required') }),
            paymentLimit: yup
              .number()
              .nullable()
              .when(['subAccountTypeId', 'paymentLimitTypeId'], {
                is: (subType, paymentLimitTypeId) => subType === ClientAccountSubType.Pension.id && paymentLimitTypeId === PaymentLimitType.Other.id,
                then: yup.number().required('PAYMENT LIMIT AMOUNT is required'),
              }),
          })}
          onSubmit={async (superSimplifierDetails: FormValues) => {
            const { nextRoutePath } = prevNextRoutePaths;
            await save(superSimplifierDetails);
            if (nextRoutePath) {
              history.push(nextRoutePath + (!!id ? `?id=${id}` : ''));
            }
          }}
        >
          {(formikProps: FormikProps<FormValues>) => (
            <Form>
              <fieldset style={{ border: 'none', padding: '0' }}>
                {superSimplifierDetailsValues.subAccountTypeId === ClientAccountSubType.Super.id && (
                  <>
                    <Box paddingTop={'30px'} paddingBottom={'10px'} width={'400px'}>
                      <Typography variant="h3" gutterBottom>
                        Estimated Contributions
                      </Typography>
                      <Field name="contributionsAmount" label="ESTIMATED CONTRIBUTIONS" fullWidth>
                        {(fieldProps: FieldProps) => {
                          return (
                            <FormikNumberFormat
                              formikFieldProps={fieldProps}
                              numberFormatProps={{
                                placeholder: '$0.00',
                                isNumericString: true,
                                allowNegative: false,
                                decimalScale: 2,
                                thousandSeparator: true,
                                prefix: '$',
                                name: fieldProps.field.name,
                                label: 'ESTIMATED CONTRIBUTIONS',
                                isAllowed: (values) => !values.floatValue || (values.floatValue > 0 && values.floatValue <= maxContributionsAmountValue),
                              }}
                              isFloatValue={true}
                              fullWidth={true}
                            />
                          );
                        }}
                      </Field>
                    </Box>

                    <Alert severity="info" style={{ margin: '10px' }} icon={<InfoIcon></InfoIcon>}>
                      <Typography variant="h5">
                        This is used for the purpose of calculating estimated advice fees and includes all expected contributions (E.g. Concessional,
                        Non-concessional, Downsizer etc.) in the first year.
                      </Typography>
                    </Alert>

                    <div style={{ marginTop: '20px' }}>
                      <Typography variant="h5" gutterBottom>
                        How will you make regular contributions to the Super Simplifier account? We will send you the required forms based on your selection.
                      </Typography>
                      <Field
                        component={FormikToggleButton}
                        exclusive={true}
                        buttons={paymentTypeToggleButtons}
                        formik={formikProps}
                        name={'paymentTypeId'}
                        style={{ minWidth: '600px' }}
                        fullWidth
                      />
                    </div>
                  </>
                )}
                <Box paddingTop={'30px'} paddingBottom={'10px'}>
                  <Typography variant="h3" gutterBottom>
                    Rollover details
                  </Typography>
                  <Typography variant="h5">
                    Enter the details of the fund(s) that you want to roll into the Super Simplifier account. We will contact the fund Trustee(s) to arrange the
                    rollover on your behalf.
                  </Typography>
                  {!formikProps.values.rollover && (
                    <Alert severity="info" style={{ margin: '10px' }} icon={<InfoIcon></InfoIcon>}>
                      <Typography variant="h5">
                        You will not be requesting a rollover into the new Super Simplifier account at this stage. To request a rollover at a later time, refer
                        to WealthO2 Academy for more details.
                      </Typography>
                    </Alert>
                  )}

                  <Grid container style={{ marginTop: '20px', justifyContent: 'flex-end' }}>
                    {!formikProps.values.rollover && !formikProps.values.additionalRollover && !formikProps.values.additionalRolloverSecond && (
                      <Link
                        data-testid="rolloverLink"
                        component="button"
                        variant="body2"
                        type="button"
                        underline="none"
                        onClick={() => {
                          formikProps.setFieldValue('rollover', true);
                        }}
                      >
                        + Add another rollover
                      </Link>
                    )}
                  </Grid>
                  {formikProps.values.rollover && (
                    <>
                      <Grid container style={{ width: '100%' }}>
                        <Grid item xs={6} justifyContent="flex-start">
                          <Typography variant="h4" style={{ marginTop: '10px', marginBottom: '12px' }}>
                            Rollover 1
                          </Typography>
                        </Grid>
                        <Grid item container xs={6} justifyContent={'flex-end'}>
                          {formikProps.values.rollover && !formikProps.values.additionalRollover && !formikProps.values.additionalRolloverSecond && (
                            <Tooltip title={removeRolloverTooltipText} arrow>
                              <IconButton
                                data-testid="deleteRollover"
                                aria-label="delete"
                                color="primary"
                                onClick={() => {
                                  confirm({
                                    title: 'Delete rollover',
                                    description: 'Are you sure you want to remove these rollover details?',
                                    description2: 'This cannot be undone.',
                                  }).then(() => {
                                    formikProps.setFieldValue('rollover', false);
                                  });
                                }}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </Tooltip>
                          )}
                        </Grid>
                      </Grid>
                      <Divider />
                      <Box>
                        <Grid container style={{ maxWidth: '1020px' }}>
                          <Grid item xs={6}>
                            <Field
                              exclusive={true}
                              component={FormikToggleButton}
                              buttons={rolloverToggleButtons}
                              formik={formikProps}
                              name={'fullRollover'}
                              style={{ minWidth: '380px' }}
                              fullWidth
                            />
                          </Grid>
                          <Grid item xs={6} style={{ padding: '10px 0 20px 10px' }}>
                            <Field name={'inSpecie'} component={FormikSwitch} label={inSpecieLabelText}></Field>
                          </Grid>
                          <Grid item xs={6}>
                            <Grid item xs={12} style={{ minHeight: '70px', margin: '20px 10px 20px 0' }}>
                              <Field name="fundName" component={FormikTextField} showRequiredAsterisk={true} label="FUND NAME" fullWidth></Field>
                            </Grid>
                            <Grid item xs={12} style={{ minHeight: '70px', margin: '20px 10px 0 0' }}>
                              <Field name={'superAnnuationId'} component={FormikTextField} label="UNIQUE SUPERANNUATION ID" fullWidth></Field>
                            </Grid>
                          </Grid>
                          <Grid item xs={6}>
                            <Grid item xs={12} style={{ minHeight: '70px', margin: '20px 0 20px 10px' }}>
                              <Field name={'memberNumber'} component={FormikTextField} label="MEMBER NUMBER" fullWidth></Field>
                            </Grid>
                            <Grid item xs={12} style={{ minHeight: '70px', margin: '20px 0 0 10px' }}>
                              <Field name="rolloverAmount" label="* ROLLOVER AMOUNT ($)" fullWidth>
                                {(fieldProps: FieldProps) => {
                                  return (
                                    <FormikNumberFormat
                                      formikFieldProps={fieldProps}
                                      numberFormatProps={{
                                        placeholder: '',
                                        isNumericString: true,
                                        allowNegative: false,
                                        decimalScale: 2,
                                        thousandSeparator: true,
                                        name: fieldProps.field.name,
                                        label: 'ROLLOVER AMOUNT ($)',
                                        isAllowed: (values) => !values.floatValue || (values.floatValue > 0 && values.floatValue <= maxRolloverAmountValue),
                                      }}
                                      isFloatValue={true}
                                      showRequiredAsterisk={true}
                                      fullWidth={true}
                                    />
                                  );
                                }}
                              </Field>
                              <Container style={{ display: 'flex', alignItems: 'center', padding: '4px 0 0' }}>
                                <Tooltip title={rolloverAmountTooltipText} arrow>
                                  <Alert
                                    severity="info"
                                    icon={<InfoIcon style={{ height: '0.8em', marginRight: '-8px' }}></InfoIcon>}
                                    style={{ width: '100%', backgroundColor: 'transparent', fontSize: 'smaller', padding: 'unset' }}
                                  >
                                    {rolloverAmountFieldText}
                                  </Alert>
                                </Tooltip>
                              </Container>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Box>

                      <Grid container style={{ marginTop: '20px', justifyContent: 'flex-end' }}>
                        {!formikProps.values.additionalRollover && !formikProps.values.additionalRolloverSecond && (
                          <Link
                            data-testid="additionalRolloverLink"
                            component="button"
                            variant="body2"
                            type="button"
                            underline="none"
                            onClick={() => {
                              formikProps.setFieldValue('additionalRollover', true);
                            }}
                          >
                            + Add another rollover
                          </Link>
                        )}
                      </Grid>
                    </>
                  )}
                </Box>
                {formikProps.values.additionalRollover && (
                  <>
                    <Grid container style={{ width: '100%' }}>
                      <Grid item xs={6} justifyContent="flex-start">
                        <Typography variant="h4" style={{ marginTop: '10px', marginBottom: '12px' }}>
                          Rollover 2
                        </Typography>
                      </Grid>
                      <Grid item container xs={6} justifyContent={'flex-end'}>
                        {formikProps.values.rollover && formikProps.values.additionalRollover && !formikProps.values.additionalRolloverSecond && (
                          <Tooltip title={removeRolloverTooltipText} arrow>
                            <IconButton
                              data-testid="deleteAdditionalRollover"
                              aria-label="delete"
                              color="primary"
                              onClick={() => {
                                confirm({
                                  title: 'Delete rollover',
                                  description: 'Are you sure you want to remove these rollover details?',
                                  description2: 'This cannot be undone.',
                                }).then(() => {
                                  formikProps.setFieldValue('additionalRollover', false);
                                });
                              }}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Tooltip>
                        )}
                      </Grid>
                    </Grid>
                    <Divider />
                    <Grid container style={{ maxWidth: '1020px' }}>
                      <Grid item xs={6}>
                        <Field
                          exclusive={true}
                          component={FormikToggleButton}
                          buttons={rolloverToggleButtons}
                          formik={formikProps}
                          name={'fullRolloverSecond'}
                          style={{ minWidth: '380px' }}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={6} style={{ padding: '10px 0 20px 10px' }}>
                        <Field name={'inSpecieSecond'} component={FormikSwitch} label={inSpecieLabelText}></Field>
                      </Grid>
                      <Grid item xs={6}>
                        <Grid item xs={12} style={{ minHeight: '70px', margin: '20px 10px 20px 0' }}>
                          <Field name={'fundNameSecond'} component={FormikTextField} showRequiredAsterisk={true} label="FUND NAME" fullWidth></Field>
                        </Grid>
                        <Grid item xs={12} style={{ minHeight: '70px', margin: '20px 10px 0 0' }}>
                          <Field name={'superAnnuationIdSecond'} component={FormikTextField} label="UNIQUE SUPERANNUATION ID" fullWidth></Field>
                        </Grid>
                      </Grid>
                      <Grid item xs={6}>
                        <Grid item xs={12} style={{ minHeight: '70px', margin: '20px 0 20px 10px' }}>
                          <Field name={'memberNumberSecond'} component={FormikTextField} label="MEMBER NUMBER" fullWidth></Field>
                        </Grid>
                        <Grid item xs={12} style={{ minHeight: '70px', margin: '20px 0 0 10px' }}>
                          <Field name="rolloverAmountSecond" label="* ROLLOVER AMOUNT ($)" fullWidth>
                            {(fieldProps: FieldProps) => {
                              return (
                                <FormikNumberFormat
                                  formikFieldProps={fieldProps}
                                  numberFormatProps={{
                                    placeholder: '',
                                    isNumericString: true,
                                    allowNegative: false,
                                    decimalScale: 2,
                                    thousandSeparator: true,
                                    name: fieldProps.field.name,
                                    label: 'ROLLOVER AMOUNT ($)',
                                    isAllowed: (values) => !values.floatValue || (values.floatValue > 0 && values.floatValue <= maxRolloverAmountValue),
                                  }}
                                  isFloatValue={true}
                                  showRequiredAsterisk={true}
                                  fullWidth={true}
                                />
                              );
                            }}
                          </Field>
                          <Container style={{ display: 'flex', alignItems: 'center', padding: '4px 0 0' }}>
                            <Tooltip title={rolloverAmountTooltipText} arrow>
                              <Alert
                                severity="info"
                                icon={<InfoIcon style={{ height: '0.8em', marginRight: '-8px' }}></InfoIcon>}
                                style={{ width: '100%', backgroundColor: 'transparent', fontSize: 'smaller', padding: 'unset' }}
                              >
                                {rolloverAmountFieldText}
                              </Alert>
                            </Tooltip>
                          </Container>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid container style={{ marginTop: '20px', justifyContent: 'flex-end' }}>
                      {!formikProps.values.additionalRolloverSecond && (
                        <Link
                          data-testid="additionalRolloverSecondLink"
                          component="button"
                          variant="body2"
                          type="button"
                          underline="none"
                          onClick={() => {
                            formikProps.setFieldValue('additionalRolloverSecond', true);
                          }}
                        >
                          + Add another rollover
                        </Link>
                      )}
                    </Grid>
                  </>
                )}
                {formikProps.values.additionalRolloverSecond && (
                  <>
                    <Grid container style={{ width: '100%' }}>
                      <Grid item xs={6} justifyContent="flex-start">
                        <Typography variant="h4" style={{ marginTop: '10px', marginBottom: '12px' }}>
                          Rollover 3
                        </Typography>
                      </Grid>
                      <Grid item container xs={6} justifyContent={'flex-end'}>
                        {formikProps.values.additionalRolloverSecond && (
                          <Tooltip title={removeRolloverTooltipText} arrow>
                            <IconButton
                              data-testid="deleteAdditionalRolloverSecond"
                              aria-label="delete"
                              color="primary"
                              onClick={() => {
                                confirm({
                                  title: 'Delete rollover',
                                  description: 'Are you sure you want to remove these rollover details?',
                                  description2: 'This cannot be undone.',
                                }).then(() => {
                                  formikProps.setFieldValue('additionalRolloverSecond', false);
                                });
                              }}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Tooltip>
                        )}
                      </Grid>
                    </Grid>
                    <Divider />
                    <Grid container style={{ maxWidth: '1020px' }}>
                      <Grid item xs={6}>
                        <Field
                          exclusive={true}
                          component={FormikToggleButton}
                          buttons={rolloverToggleButtons}
                          formik={formikProps}
                          name={'fullRolloverThird'}
                          style={{ minWidth: '380px' }}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={6} style={{ padding: '10px 0 20px 10px' }}>
                        <Field name={'inSpecieThird'} component={FormikSwitch} label={inSpecieLabelText}></Field>
                      </Grid>
                      <Grid item xs={6}>
                        <Grid item xs={12} style={{ minHeight: '70px', margin: '20px 10px 20px 0' }}>
                          <Field name={'fundNameThird'} component={FormikTextField} showRequiredAsterisk={true} label="FUND NAME" fullWidth></Field>
                        </Grid>
                        <Grid item xs={12} style={{ minHeight: '70px', margin: '20px 10px 0 0' }}>
                          <Field name={'superAnnuationIdThird'} component={FormikTextField} label="UNIQUE SUPERANNUATION ID" fullWidth></Field>
                        </Grid>
                      </Grid>
                      <Grid item xs={6}>
                        <Grid item xs={12} style={{ minHeight: '70px', margin: '20px 0 20px 10px' }}>
                          <Field name={'memberNumberThird'} component={FormikTextField} label="MEMBER NUMBER" fullWidth></Field>
                        </Grid>
                        <Grid item xs={12} style={{ minHeight: '70px', margin: '20px 0 0 10px' }}>
                          <Field name="rolloverAmountThird" label="ROLLOVER AMOUNT ($)" fullWidth>
                            {(fieldProps: FieldProps) => {
                              return (
                                <FormikNumberFormat
                                  formikFieldProps={fieldProps}
                                  numberFormatProps={{
                                    placeholder: '',
                                    isNumericString: true,
                                    allowNegative: false,
                                    decimalScale: 2,
                                    thousandSeparator: true,
                                    name: fieldProps.field.name,
                                    label: 'ROLLOVER AMOUNT ($)',
                                    isAllowed: (values) => !values.floatValue || (values.floatValue > 0 && values.floatValue <= maxRolloverAmountValue),
                                  }}
                                  isFloatValue={true}
                                  showRequiredAsterisk={true}
                                  fullWidth={true}
                                />
                              );
                            }}
                          </Field>
                          <Container style={{ display: 'flex', alignItems: 'center', padding: '4px 0 0' }}>
                            <Tooltip title={rolloverAmountTooltipText} arrow>
                              <Alert
                                severity="info"
                                icon={<InfoIcon style={{ height: '0.8em', marginRight: '-8px' }}></InfoIcon>}
                                style={{ width: '100%', backgroundColor: 'transparent', fontSize: 'smaller', padding: 'unset' }}
                              >
                                {rolloverAmountFieldText}
                              </Alert>
                            </Tooltip>
                          </Container>
                        </Grid>
                      </Grid>
                    </Grid>
                  </>
                )}

                {superSimplifierDetailsValues.subAccountTypeId === ClientAccountSubType.Pension.id && (
                  <>
                    <Box paddingTop={'30px'} paddingBottom={'10px'} width={'400px'}>
                      <Typography variant="h3" gutterBottom>
                        Estimated Contributions
                      </Typography>
                      <Field name="contributionsAmount" label="ESTIMATED CONTRIBUTIONS" fullWidth>
                        {(fieldProps: FieldProps) => {
                          return (
                            <FormikNumberFormat
                              formikFieldProps={fieldProps}
                              numberFormatProps={{
                                placeholder: '$0.00',
                                isNumericString: true,
                                allowNegative: false,
                                decimalScale: 2,
                                thousandSeparator: true,
                                prefix: '$',
                                name: fieldProps.field.name,
                                label: 'ESTIMATED CONTRIBUTIONS',
                                isAllowed: (values) => !values.floatValue || (values.floatValue > 0 && values.floatValue <= maxContributionsAmountValue),
                              }}
                              isFloatValue={true}
                              fullWidth={true}
                            />
                          );
                        }}
                      </Field>
                    </Box>

                    <Alert severity="info" style={{ margin: '10px' }} icon={<InfoIcon></InfoIcon>}>
                      <Typography variant="h5">
                        This is used for the purpose of calculating estimated advice fees and includes all expected contributions (E.g. Concessional,
                        Non-concessional, Downsizer etc.) in the first year.
                      </Typography>
                    </Alert>

                    <Typography variant="h3" style={{ padding: '20px 0' }}>
                      Pension details
                    </Typography>

                    <Grid container style={{ padding: '0' }}>
                      <Grid item xs={12}>
                        <Field
                          name={'isResident'}
                          component={FormikSwitch}
                          label="The member is an Australian citizen, New Zealand citizen or permanent resident"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Field name={'ageOver65'} component={FormikSwitch} label="The member is aged 65 or older" />
                      </Grid>
                      <Grid item xs={12}>
                        <Field
                          name={'ageOver60NotPaid'}
                          component={FormikSwitch}
                          label="After attaining age 60 the member ceased a paid employment arrangement and intends to never again be gainfully employed for more than 9 hours per week"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Field
                          name={'ageNotOver60NotPaid'}
                          component={FormikSwitch}
                          label="The member has reached preservation age but is under 60 and has ceased gainful employment. The member also intends to never again be gainfully employed for more than 9 hours per week"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Field name={'reachedAge'} component={FormikSwitch} label="The member has reached their preservation age" />
                      </Grid>
                      <Grid item xs={12}>
                        <Field
                          name={'atp'}
                          component={FormikSwitch}
                          label="The member has an existing ATP in another fund that they are transferring into this plan"
                        />
                      </Grid>
                    </Grid>
                    <Typography variant="h3" style={{ padding: '20px 0' }}>
                      Pension payment details
                    </Typography>
                    <Box minHeight={'290px'}>
                      <Typography variant="h5">What frequency will regular payments be made? *</Typography>
                      <Field
                        exclusive={true}
                        component={FormikToggleButton}
                        buttons={paymentFrequencyToggleButtons}
                        formik={formikProps}
                        name={'paymentFrequencyId'}
                        style={{ minWidth: '480px', padding: '10px 0' }}
                        fullWidth
                      />
                      <Typography variant="h5">How much should be paid (gross) for regular payments? *</Typography>
                      <Field
                        exclusive={true}
                        component={FormikToggleButton}
                        buttons={paymentLimitTypeToggleButtons.map((paymentLimitTypeButton: ToggleButtonItem<number>) => {
                          return {
                            ...paymentLimitTypeButton,
                            disabled:
                              paymentLimitTypeButton.value === PaymentLimitType.Maximum.id &&
                              formikProps.values.pensionTypeId !== PensionType.TransitionRetirementPension.id,
                          };
                        })}
                        formik={formikProps}
                        name={'paymentLimitTypeId'}
                        style={{ minWidth: '480px', padding: '10px 0' }}
                        fullWidth
                      />
                      <Fade in={formikProps.values.paymentLimitTypeId === PaymentLimitType.Other.id}>
                        <Box width={'480px'} padding={'10px 0'}>
                          {formikProps.values.paymentLimitTypeId === PaymentLimitType.Other.id && (
                            <Field name="paymentLimit" label="OTHER AMOUNT ($)" fullWidth>
                              {(fieldProps: FieldProps) => {
                                return (
                                  <FormikNumberFormat
                                    formikFieldProps={fieldProps}
                                    numberFormatProps={{
                                      placeholder: '',
                                      isNumericString: true,
                                      allowNegative: false,
                                      decimalScale: 2,
                                      thousandSeparator: true,
                                      name: fieldProps.field.name,
                                      label: 'OTHER AMOUNT ($)',
                                    }}
                                    isFloatValue={true}
                                    showRequiredAsterisk={true}
                                    fullWidth={true}
                                  />
                                );
                              }}
                            </Field>
                          )}
                        </Box>
                      </Fade>
                    </Box>
                  </>
                )}
              </fieldset>
              <PageBackNextButtons<FormValues>
                onBackButtonClick={() => onBackButtonClick(formikProps.values)}
                onNextButtonClick={formikProps.submitForm}
                onQuitButtonClick={async () => {
                  await save(formikProps.values);
                  history.push('/client/list?mode=onboard');
                }}
                progress={saveProgress}
              />
            </Form>
          )}
        </Formik>
      )}
    </div>
  );
};
