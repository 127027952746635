/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DateTime } from 'luxon';
import { SystemMinimumDate } from '../../../common';
import { DateRange } from '../../../store';
import { FetchConsolidatedSecurityHoldings } from './thunks';
import { ConsolidatedSecurityHoldings, Grouping, HoldingsState, SelectedGroup } from './types';

export const initialState: HoldingsState = {
  parameters: {
    groupingSelected: Grouping.AssetClassSecurity,
    selectedGroup: {
      label: 'ALL',
      groupKey: '',
    },
    dateSelected: {
      dateFrom: DateTime.fromISO(SystemMinimumDate).toISODate(),
      dateTo: DateTime.now().toISODate(),
    },
    filterText: '',
  },
  ConsolidatedSecurityHoldings: {
    groupKey: '',
    label: '',
    value: 0,
    weight: 0,
    groups: [],
    consolidatedSecurityHoldings: [],
  },
  ConsolidatedHoldings: { headers: [], rows: [] },
};

export const holdingsSlice = createSlice({
  name: '@@holdings',
  initialState,
  reducers: {
    setDateSelectedParameter: (state, action: PayloadAction<DateRange>) => {
      state.parameters.dateSelected = action.payload;
    },
    setFilterTextParameter: (state, action: PayloadAction<string>) => {
      state.parameters.filterText = action.payload;
    },
    setGroupingSelectedParameter: (state, action: PayloadAction<string>) => {
      let grouping: Grouping = Grouping.AssetClassSecurity;

      if (action.payload === 'Asset class, Security') {
        grouping = Grouping.AssetClassSecurity;
      } else if (action.payload === 'Adviser, Portfolio') {
        grouping = Grouping.AdviserPortfolio;
      } else if (action.payload === 'Model, Security') {
        grouping = Grouping.ModelSecurity;
      }
      state.parameters.groupingSelected = grouping;
    },
    setSelectedGroupParameter: (state, action: PayloadAction<SelectedGroup>) => {
      state.parameters.selectedGroup = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(FetchConsolidatedSecurityHoldings.fulfilled, (state, action: PayloadAction<ConsolidatedSecurityHoldings>) => {
      state.ConsolidatedSecurityHoldings = action.payload;
    });
  },
});
