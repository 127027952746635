import { TableCell, TableRow, Typography } from '@mui/material';
import React from 'react';
import { formatDollars } from '../../../../../common';
import { Trade, TradeAction } from '../store/types';

interface Props {
  trade: Trade;
  index: number;
}

export default function PreapprovedTrade(props: Props): JSX.Element {
  const { trade, index } = props;

  return (
    <TableRow key={index}>
      <TableCell>
        <Typography variant="h5" color={'primary'}>
          {trade.clientName}
        </Typography>
        <Typography variant="h5">{trade.portfolioName}</Typography>
      </TableCell>
      <TableCell align="left">
        <Typography variant="h5">{Intl.NumberFormat('en-AU').format(trade.units)}</Typography>
        <Typography variant="h6" color={'textSecondary'}>
          @ {formatDollars(trade.price)}
        </Typography>
      </TableCell>
      <TableCell align="center">
        {TradeAction.Buy.name === trade.action ? (
          <Typography variant="h5" color="secondary">
            BUY
          </Typography>
        ) : (
          <Typography variant="h5" color="error">
            SELL
          </Typography>
        )}
      </TableCell>
      <TableCell align="right">
        <Typography variant="h5">
          {(trade.action === TradeAction.Sell.name || trade.action === TradeAction.SellAll.name) && trade.total ? '-' : ''}
          {formatDollars(trade.total)}
        </Typography>
      </TableCell>
    </TableRow>
  );
}
