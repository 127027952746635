import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../../../../reducers';
import { selectAfsls } from '../../common/store/selectors';
import { Filters } from './components/filters';
import { selectParameters, selectSecurities, selectModels } from './store/selectors';
import { searchSlice } from './store/slice';
import { fetchResults, fetchSecurities, fetchModels } from './store/thunks';

const mapStateToProps = (state: RootState) => ({
  afsls: selectAfsls(state),
  models: selectModels(state),
  securities: selectSecurities(state),
  parameters: selectParameters(state),
  isLoading: false,
  hasErrors: false,
});

const mapDispatchToProps = {
  ...searchSlice.actions,
  fetchResults,
  fetchSecurities,
  fetchModels,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export type Props = ConnectedProps<typeof connector>;
export const OrderSearchFiltersContainer = connector(Filters);
