import _ from 'lodash';
import { AssetAllocation } from '../types';

export const checkDuplicateSecurityCodes = (allocations: AssetAllocation[]): string => {
  let errors = '';

  const duplicates: AssetAllocation[] = _.flow([
    (codeData) => _.groupBy(codeData, 'securityCode'),
    (groupedBySecurityCode) => _.filter(groupedBySecurityCode, (group) => group.length > 1),
    _.flatten,
  ])(allocations);

  if (duplicates && duplicates.length > 0) {
    errors = `Duplicate securities found: ${JSON.stringify(duplicates)}`;
  }

  return errors;
};

export const targetAllocationsShouldBeBetween0And1 = (allocations: AssetAllocation[]): string => {
  for (let i = 0; i < allocations.length; i++) {
    if (allocations[i].targetAllocation < 0 || allocations[i].targetAllocation > 1) {
      return `Target allocations cannot be more than 1 or less than 0: ${JSON.stringify(allocations[i])}`;
    }
  }

  return '';
};
