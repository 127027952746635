import { AddCircle, BalanceOutlined, Settings } from '@mui/icons-material';
import { Box, Container, FormControlLabel, FormGroup, Grid, IconButton, Stack, Switch, Tooltip } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { ProgressButton, WO2Modal } from 'src/common/components';
import { useConfirmation } from 'src/common/components/dialogs';
import DateRangePicker from '../../../../../../common/components/DateRangePicker';
import { PortfolioVersionSelect } from '../../components/PortfolioVersionSelect';
import { Props } from '../container';
import AvailableCashCard from './AvailableCashCard';
import CostBaseCard from './CostBaseCard';
import { GroupingSelect } from './GroupingSelect';
import MarketValueCard from './MarketValueCard';
import { NewPortfolioDialog, NewPortfolioDialogResponse } from './newPortfolio/newPortfolioDialog';
import { PortfolioDetails } from './PortfolioDetails';
import UnrealisedGainsLossCard from './UnrealisedGainsLossCard';

export const Overview = ({
  history,
  clientId,
  parameters,
  fetchPortfolioDetail,
  setParameters,
  portfolioAssetGroups,
  gainsLossesItems,
  marketValue,
  fetchPortfolioDetailProgress,
  fetchTemplates,
  templates,
  fetchTemplatesProgress,
  createAndGetNewInvestmentService,
  createAndGetNewInvestmentServiceProgress,
  hasClientEditAdminOnlyPermission,
  investmentServicesWithVersionsGrouped,
  common,
  setInvestmentServiceVersion,
  afslId,
  rebalanceTemplate,
  rebalanceTemplateProgress,
  selectedInvestmentService,
  rebalanceValidationWarnings,
  clearRebalance,
}: Props): JSX.Element => {
  const confirm = useConfirmation();
  const [newPortfolioDialogOpen, setNewPortfolioDialogOpen] = useState<boolean>(false);

  useEffect(() => {
    if (!!clientId) {
      fetchPortfolioDetail({ clientId, parameters, investmentServiceId: common.investmentServiceId });
    }
  }, [clientId, parameters, common, fetchPortfolioDetail]);

  const handleRebalanceClick = useCallback(async () => {
    confirm({
      title: 'Rebalance portfolio',
      description: 'Are you sure you wish to initialise a rebalance for this portfolio?',
    }).then(async () => {
      if (common.investmentServiceId && !!common.investmentServiceVersionId && !!clientId) {
        await rebalanceTemplate({
          investmentServiceId: common.investmentServiceId,
          investmentServiceVersionId: common.investmentServiceVersionId,
          serviceTypeId: selectedInvestmentService?.serviceTypeId || null,
          userId: 511,
          clientId,
        });
      }
    });
  }, [rebalanceTemplate, common, selectedInvestmentService]);

  return (
    <Container style={{ paddingTop: '20px', paddingBottom: '20px' }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Stack direction="row">
            <Box style={{ width: '50%', display: 'flex', justifyContent: 'flex-start' }}>
              <PortfolioVersionSelect
                items={investmentServicesWithVersionsGrouped}
                selectedInvestmentServiceVersion={
                  investmentServicesWithVersionsGrouped.find((v) => v.investmentServiceId === common.investmentServiceId) || null
                }
                setInvestmentServiceId={(investmentServiceId, investmentServiceVersionId) => {
                  if (common.investmentServiceId !== investmentServiceId) {
                    setInvestmentServiceVersion({ investmentServiceId, investmentServiceVersionId });
                  }
                }}
                optionForAll={true}
              />
              {hasClientEditAdminOnlyPermission && (
                <>
                  <IconButton
                    type="button"
                    data-testid={`addPortfolioButton`}
                    disableFocusRipple
                    disableRipple
                    onClick={() => {
                      setNewPortfolioDialogOpen(true);
                    }}
                  >
                    <AddCircle></AddCircle>
                  </IconButton>
                  <IconButton
                    type="button"
                    data-testid={`investmentServicesButton`}
                    onClick={() => {
                      history.push(`./configuration`);
                    }}
                  >
                    <Settings></Settings>
                  </IconButton>
                </>
              )}
            </Box>
            <Box style={{ width: '50%', display: 'flex', justifyContent: 'flex-end' }}>
              {hasClientEditAdminOnlyPermission && (
                <Tooltip
                  arrow
                  title={selectedInvestmentService?.currentVersionStatus !== 'Active' ? 'Portfolio must be active to rebalance' : ''}
                  placement="top"
                >
                  <span>
                    <ProgressButton
                      type="submit"
                      disabled={!selectedInvestmentService?.versions.find((v) => v.status === 'Active')}
                      startIcon={BalanceOutlined}
                      onClick={handleRebalanceClick}
                      variant={'contained'}
                      progress={rebalanceTemplateProgress}
                      data-testid={'saveButton'}
                      style={{ minWidth: '160px', height: '36px' }}
                    >
                      Rebalance
                    </ProgressButton>
                  </span>
                </Tooltip>
              )}
            </Box>
          </Stack>
          {newPortfolioDialogOpen && (
            <NewPortfolioDialog
              fetchTemplates={() => {
                !!afslId && fetchTemplates({ afslId });
              }}
              fetchTemplatesProgress={fetchTemplatesProgress}
              onClose={() => {
                if (!!afslId) {
                  fetchTemplates({ afslId });
                  setNewPortfolioDialogOpen(false);
                }
              }}
              onCreate={async (payload: NewPortfolioDialogResponse) => {
                if (!!clientId && payload.serviceTypeId) {
                  await createAndGetNewInvestmentService({
                    clientId,
                    investmentServiceTemplateVersionId: payload.template?.investmentServiceTemplateVersionId || null,
                    serviceTypeId: payload.serviceTypeId,
                  });
                  history.push(`./configuration`);
                }
              }}
              templates={templates}
              createProgress={createAndGetNewInvestmentServiceProgress}
            />
          )}
        </Grid>
        <Grid item xs={3}>
          <CostBaseCard />
        </Grid>
        <Grid item xs={3}>
          <UnrealisedGainsLossCard />
        </Grid>
        <Grid item xs={3}>
          <MarketValueCard />
        </Grid>
        <Grid item xs={3}>
          <AvailableCashCard />
        </Grid>
        <Grid item xs={12}>
          <Box width={'100%'} display={'inline-flex'} justifyContent={'flex-end'}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    checked={parameters?.showPositionsNotHeld ?? true}
                    onClick={() => setParameters({ ...parameters, showPositionsNotHeld: !parameters?.showPositionsNotHeld })}
                  />
                }
                label="Show positions not held"
              />
            </FormGroup>
            <DateRangePicker
              dateRange={parameters?.dates}
              setDateRange={(dates) => {
                setParameters({ ...parameters, dates });
              }}
              isDateOnly
            />
            <GroupingSelect
              selectedItem={parameters?.groupingType}
              setSelectedItem={(groupingType) => {
                setParameters({ ...parameters, groupingType });
              }}
            />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <PortfolioDetails
            clientId={clientId}
            parameters={parameters}
            portfolioAssetGroups={portfolioAssetGroups}
            gainsLossesItems={gainsLossesItems}
            totalValue={marketValue}
            progress={fetchPortfolioDetailProgress}
            viewFullPortfolio={() => {
              return;
            }}
          />
        </Grid>
      </Grid>
      <WO2Modal
        MuiDialogProps={{
          open: rebalanceValidationWarnings.length > 0,
          onClose: () => {
            clearRebalance();
          },
        }}
        title={<>Rebalance failed</>}
        actions={[
          {
            label: 'Cancel',
            onClickHandler: () => {
              clearRebalance();
            },
            type: 'outlined',
          },
        ]}
      >
        <>
          <p>Rebalance is unable to initialise due to the following reason.</p>
          <ul>
            {rebalanceValidationWarnings.map((m, idx) => (
              <li key={idx}>{m}</li>
            ))}
          </ul>
        </>
      </WO2Modal>
    </Container>
  );
};
