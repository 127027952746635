import { Container, Tab, Tabs } from '@mui/material';
import React from 'react';
import { Redirect, Route, Switch, useLocation, useRouteMatch } from 'react-router-dom';
import { Props } from '../container';
import { EditContainer } from '../edit/container';
import { PortfoliosContainer } from '../portfolios/container';

export const Templates = ({}: Props): JSX.Element => {
  const match = useRouteMatch();
  const { pathname } = useLocation();
  const [value, setValue] = React.useState(0);

  return (
    <Container style={{ padding: '20px' }}>
      <Tabs
        value={value}
        onChange={(_event, value) => {
          setValue(value);
        }}
        TabIndicatorProps={{ style: { height: '4px' } }}
        style={{ padding: '0 24px 10px' }}
      >
        <Tab label="PORTFOLIO" href="/portfolios" />
        <Tab label="EMAIL" href="/email" disabled={true} />
        <Tab label="REPORTS" href="/reports" disabled={true} />
      </Tabs>
      <Switch>
        <Route exact path={`${match.path}/`} render={() => <Redirect to={`${pathname}/portfolios`} />} />
        <Route exact path={`${match.path}/portfolios/:afslId?`} component={PortfoliosContainer}></Route>
        <Route path={`${match.path}/portfolios/:afslId/:id`} component={EditContainer}></Route>
      </Switch>
    </Container>
  );
};
