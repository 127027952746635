import { createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../../../../../app/api';
import { buildEncodedQueryString } from '../../../../../../common';
import { BenefitStatement } from './types';

export enum BenefitStatmentActionTypes {
  FetchBenefitStatementDetailsByClientId = '@@client/details/benefitStatement/FetchBenefitStatementDetails',
}

export enum BenefitStatmentApiEndpoints {
  FetchBenefitStatementDetails = '/customers/GetSuperSimplifierMemberBenefitStatement',
}

export const fetchBenefitStatementDetailsByClientId = createAsyncThunk(
  BenefitStatmentActionTypes.FetchBenefitStatementDetailsByClientId,
  async (clientId: number) => {
    const queryString = buildEncodedQueryString({
      clientId,
    });
    const response = await api.get<BenefitStatement>(`${BenefitStatmentApiEndpoints.FetchBenefitStatementDetails}${queryString}`);
    return response.data;
  }
);
