import { Box, Container, Grid } from '@mui/material';
import { DateTime } from 'luxon';
import React, { useCallback, useEffect } from 'react';
import SearchButton from 'src/features/clients/client/common/components/SearchButton';
import { formatDollars } from '../../../../common';
import CardComponent from '../../../../common/components/CardComponent';
import DateRangePicker from '../../../../common/components/DateRangePicker';
import { PagedRequest } from '../../../../store';
import { theme } from '../../../../themes';
import { Props } from '../container';
import {
  ConsolidatedSecurityHoldingsSummaryThunkParameters,
  SecurityExposureCsvRow,
  SecurityExposureItem,
  SecurityExposureThunkParameters,
} from '../store/types';
import { SecurityExposureTable } from './securityExposureTable';

export const AssociatedClientsComponent = (props: Props): JSX.Element => {
  const {
    parameters,
    setDateParameter,
    fetchConsolidatedSecurityHoldingsSummary,
    fetchSecurityExposure,
    downloadSecurityExposure,
    consolidatedSecurityHoldingsSummary,
    securityExposure,
    consolidatedSecurityHoldingsSummaryProgress,
    securityExposureProgress,
    match: { params },
  } = props;

  const callEndPoints = useCallback(() => {
    if (params.securityId) {
      const requestPayload: ConsolidatedSecurityHoldingsSummaryThunkParameters = {
        securityId: parseInt(params.securityId),
      };
      fetchConsolidatedSecurityHoldingsSummary(requestPayload);
    }
    if (params.securityCode) {
      const requestPayload2: SecurityExposureThunkParameters = {
        pagination: parameters.pagination,
        securityId: parseInt(params.securityId || ''),
        fromDate: DateTime.now().minus({ years: 1 }).toISODate(),
        toDate: DateTime.now().toISODate(),
      };
      fetchSecurityExposure(requestPayload2);
    }
  }, [fetchConsolidatedSecurityHoldingsSummary, fetchSecurityExposure, params.securityId, parameters.pagination, params.securityCode]);

  useEffect(() => {
    callEndPoints();
  }, [callEndPoints]);

  function onSearchClicked() {
    if (params.securityCode) {
      const requestPayload3: SecurityExposureThunkParameters = {
        securityId: parseInt(params.securityId || ''),
        fromDate: parameters.dateSelected.dateFrom,
        toDate: parameters.dateSelected.dateTo,
        pagination: parameters.pagination,
      };
      fetchSecurityExposure(requestPayload3);
    }
  }

  const handleGridActions = (pagedRequest: PagedRequest) => {
    if (params.securityCode) {
      fetchSecurityExposure({
        pagination: pagedRequest,
        securityId: parseInt(params.securityId || ''),
        fromDate: parameters.dateSelected.dateFrom,
        toDate: parameters.dateSelected.dateTo,
      });
    }
  };

  return (
    <Container style={{ paddingTop: '20px', paddingBottom: '20px' }}>
      <Box display={'flex'} justifyContent={'space-between'} style={{ padding: '10px 0' }}>
        <Box paddingRight={'10px'}></Box>
        <Box display={'flex'} justifyContent={'space-between'}>
          <DateRangePicker dateRange={parameters.dateSelected} setDateRange={setDateParameter} isDateOnly />
          <Box paddingRight={'10px'}></Box>
          <Box>
            <SearchButton onClick={onSearchClicked} />
          </Box>
        </Box>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <CardComponent
            {...props}
            progress={consolidatedSecurityHoldingsSummaryProgress}
            background={theme.palette.gradient1?.main}
            value={formatDollars(consolidatedSecurityHoldingsSummary?.fua)}
            subText={'FUA'}
          />
        </Grid>
        <Grid item xs={6}>
          <CardComponent
            {...props}
            progress={consolidatedSecurityHoldingsSummaryProgress}
            background={theme.palette.gradient2?.main}
            value={consolidatedSecurityHoldingsSummary?.portfolioCount}
            subText={'Portfolios'}
          />
        </Grid>
      </Grid>

      <Grid container spacing={2} style={{ paddingTop: '15px', paddingLeft: '10px' }}>
        <SecurityExposureTable
          pagedRequest={parameters.pagination}
          pagedResults={securityExposure || undefined}
          progressPrices={securityExposureProgress}
          handlePagedRequest={handleGridActions}
          handleDownload={(pagedRequest: PagedRequest, mapper: (securityExposure: SecurityExposureItem[]) => SecurityExposureCsvRow[]) =>
            downloadSecurityExposure({
              pagination: pagedRequest,
              securityId: parseInt(params.securityId || ''),
              fromDate: parameters.dateSelected.dateFrom,
              toDate: parameters.dateSelected.dateTo,
              mapper,
            })
          }
        ></SecurityExposureTable>
      </Grid>
    </Container>
  );
};
