import { List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Link, useLocation, useRouteMatch } from 'react-router-dom';
import { ClientAccountSubType, ClientAccountType } from '../../../../../../common/types';
import { MenuRoute } from '../../../../../clients/common/types';
import { detailsRoutes } from '../../detailsRoutes';
import { TrusteeType } from '../../mainDetails/store';

export interface Props {
  accountTypeId: number;
  accountSubTypeId: number | null;
  isSuperSimplifierClient: boolean;
  trusteeTypeId: number | null;
  hasClientEditAdminOnlyPermission: boolean;
}

export const LeftMenu = (props: Props): JSX.Element => {
  const { accountTypeId, accountSubTypeId, trusteeTypeId, isSuperSimplifierClient, hasClientEditAdminOnlyPermission } = props;
  const { pathname } = useLocation();
  const { url } = useRouteMatch();

  const [routes, setRoutes] = useState<MenuRoute[]>([]);

  useEffect(() => {
    const accountType: ClientAccountType | undefined = !!accountTypeId ? ClientAccountType.getById(accountTypeId) || undefined : undefined;
    const accountSubType: ClientAccountSubType | undefined = !!accountSubTypeId ? ClientAccountSubType.getById(accountSubTypeId) || undefined : undefined;
    const trusteeType: TrusteeType | undefined = !!trusteeTypeId ? TrusteeType.getById(trusteeTypeId) || undefined : undefined;

    const hideAddressForSuperSimplifierClients = isSuperSimplifierClient ? detailsRoutes.filter((route) => route.path !== 'addresses') : detailsRoutes;

    setRoutes(hideAddressForSuperSimplifierClients.filter((r) => r.visible(accountType, accountSubType, trusteeType, hasClientEditAdminOnlyPermission)));
  }, [accountTypeId, accountSubTypeId, isSuperSimplifierClient, trusteeTypeId, hasClientEditAdminOnlyPermission]);

  return (
    <List>
      {routes.map((r) => (
        <ListItem
          className={'LeftMenu'}
          selected={pathname.startsWith(`${url}/${r.path}`)}
          disableRipple
          key={r.menuIndex}
          button
          style={{ paddingLeft: '30px', paddingRight: '25px' }}
          component={Link}
          to={`${url}/${r.path}`}
        >
          <ListItemIcon>
            <ListItemIcon>{!!r.icon && <r.icon></r.icon>}</ListItemIcon>
          </ListItemIcon>
          <ListItemText primary={r.label} primaryTypographyProps={{ color: 'textSecondary', variant: 'h5' }} />
        </ListItem>
      ))}
    </List>
  );
};
