import { Add as AddIcon, Cancel as CancelIcon, Delete as DeleteIcon, Edit as EditIcon, Save as SaveIcon } from '@mui/icons-material';
import { Box, IconButton, LinearProgress, Paper, Stack, styled, Typography } from '@mui/material';
import { GridToolbarContainer } from '@mui/x-data-grid';
import {
  DataGridPro,
  GridActionsCellItem,
  GridColumns,
  GridPreProcessEditCellProps,
  GridRenderCellParams,
  GridRenderEditCellParams,
  GridRowModes,
  GridRowParams,
  GridValueFormatterParams,
  GridValueGetterParams,
  MuiBaseEvent,
  MuiEvent,
  useGridApiRef,
} from '@mui/x-data-grid-pro';
import React, { useCallback, useEffect, useState } from 'react';
import PulseLoader from 'react-spinners/PulseLoader';
import { AssetClassEnum } from 'src/common/enums/assetClasses';
import { AssetTypeEnum } from 'src/common/enums/assetTypeEnum';
import { LoadingProgress } from 'src/common/store/types';
import { theme } from '../../../../../../../themes';
import { ApprovedProduct } from '../../../store/common';
import { Configuration, SaveConfigurationsItem } from '../store';
import { AllocationInput } from './allocationInput';

const StyledBox = styled(Box)(() => ({
  width: '100%',
  '& .MuiDataGrid-cell--editing': {
    backgroundColor: 'rgb(255,215,115, 0.19)',
    color: '#1a3e72',
    '& .MuiInputBase-root': {
      height: '100%',
    },
  },
  '& .Mui-error': {
    backgroundColor: `#FFCCCC`,
    color: 'red',
    borderRadius: '4px',
    borderColor: 'red',
    borderStyle: 'solid',
    borderWidth: '1px',
  },
  '& .MuiDataGrid-editInputCell': {
    borderWidth: '2px',
  },
  '& input[type=number]': {
    textAlign: 'right',
    paddingRight: '0px',
  },
  '& .MuiDataGrid-toolbarContainer': {
    justifyContent: 'flex-end',
  },
}));

export interface ConfigurationTableProps {
  items: Configuration[];
  itemLoadingProgress: LoadingProgress;
  approvedProducts: ApprovedProduct[];
  cashAccountProduct?: ApprovedProduct;
  onSave?: (configurationsToSave: SaveConfigurationsItem[]) => Promise<void>;
  savingProgress: LoadingProgress;
  hideEditControls?: boolean;
}

interface DataRowComponent {
  componentId: number;
  name: string;
  code: string;
}

interface DataRow {
  id: string;
  typeId: number | null;
  configurationId: number | null;
  componentId: number | null;
  componentName: string;
  componentCode: string;
  assetClassId: number | null;
  weight: string;
}

interface UpdatedRow {
  [key: string]: DataRow;
}

export const ConfigurationTable = (props: ConfigurationTableProps): JSX.Element => {
  const { items, itemLoadingProgress, approvedProducts, onSave, cashAccountProduct, savingProgress, hideEditControls } = props;
  const [configurations, setConfigurations] = useState<DataRow[]>([]);
  const [editMode, setEditMode] = useState<boolean>(false);
  const [cashComponentRowIndex, setCashComponentRowIndex] = useState<number>(-1);

  const rowsWithUpdatedValues = React.useRef<UpdatedRow>({});

  const apiRef = useGridApiRef();

  const resetConfigurations = useCallback(async () => {
    setConfigurations([]);
    setConfigurations(
      items.map((i, index) => ({
        id: index.toString(),
        configurationId: i.configurationId,
        componentId: i.componentId,
        componentName: i.name,
        componentCode: i.code,
        typeId: i.typeId,
        assetClassId: i.assetClassId,
        weight: i.allocation.toFixed(2),
      }))
    );

    items.forEach((i, index) => {
      rowsWithUpdatedValues.current[index.toString()] = {
        id: index.toString(),
        configurationId: i.configurationId,
        componentId: i.componentId,
        componentName: i.name,
        componentCode: i.code,
        typeId: i.typeId,
        assetClassId: i.assetClassId || -1,
        weight: i.allocation.toFixed(2),
      };
    });
  }, [items, setConfigurations]);

  useEffect(() => {
    resetConfigurations();
  }, [items]);

  useEffect(() => {
    if (!!cashAccountProduct) {
      setCashComponentRowIndex(configurations.findIndex((c) => c.componentId === cashAccountProduct.componentId && c.typeId === cashAccountProduct.typeId));
    }
  }, [configurations, cashAccountProduct]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const validateRow = React.useCallback((row: any | null): boolean => {
    const assetClassIdValid = row.typeId === AssetTypeEnum.Model.id ? row.assetClassId === -1 : row.assetClassId !== null;
    return row.componentId !== null && assetClassIdValid && !isNaN(row.weight) && +row.weight >= 0 && +row.weight <= 100;
  }, []);

  const columns: GridColumns = [
    {
      field: 'configurationId',
      flex: 1,
      editable: false,
    },
    {
      field: 'typeId',
      headerName: 'TYPE',
      type: 'singleSelect',
      flex: 1,
      editable: true,
      sortable: false,
      renderCell: (params: GridRenderCellParams<number>) => <div>{AssetTypeEnum.getById(params.value)?.displayName}</div>,
      preProcessEditCellProps: (params) => {
        rowsWithUpdatedValues.current[params.id].typeId = params.props.value;
        return params.props;
      },
      valueOptions: () => AssetTypeEnum.getAll().map((t) => ({ value: t.id, label: t.displayName })),
    },
    {
      field: 'componentId',
      headerName: 'COMPONENT',
      type: 'singleSelect',
      flex: 2,
      editable: true,
      sortable: false,
      renderCell: (params: GridRenderCellParams<DataRowComponent>) => {
        return (
          <div>
            <Typography
              variant="h5"
              color="primary"
              style={{
                letterSpacing: '1px',
              }}
            >
              {params.row.componentName}
            </Typography>
            <Typography color={'textSecondary'} variant={'h6'} align="left">
              {params.row.componentCode}
            </Typography>
          </div>
        );
      },
      valueOptions: (params) => {
        if (params.id !== undefined) {
          const typeId = rowsWithUpdatedValues.current[params.id]?.typeId;

          return [...approvedProducts]
            .filter((p) => p.typeId === typeId)
            .map((p) => ({ value: p.componentId, label: p.name }))
            .sort((a, b) => a.label.localeCompare(b.label));
        }
        return [];
      },
      preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
        rowsWithUpdatedValues.current[params.id].componentId = params.props.value;

        const typeId = rowsWithUpdatedValues.current[params.id].typeId;
        const productInList = !!approvedProducts.find((p) => p.typeId === typeId && p.componentId === params.props.value);

        return { ...params.props, error: !productInList };
      },
      valueGetter: (params: GridValueGetterParams) => {
        const { row } = params;
        return rowsWithUpdatedValues.current[row.id]?.componentId || '';
      },
    },
    {
      field: 'assetClassId',
      type: 'singleSelect',
      flex: 1,
      headerName: 'ASSET CLASS',
      editable: true,
      sortable: false,
      renderCell: (params: GridRenderCellParams<number>) => <div>{AssetClassEnum.getById(params.value)?.displayName || 'n/a'}</div>,
      valueOptions: (params) => {
        if (params.id !== undefined) {
          const typeId = rowsWithUpdatedValues.current[params.id]?.typeId;
          const componentId = rowsWithUpdatedValues.current[params.id]?.componentId;

          if (typeId === AssetTypeEnum.Model.id) {
            return [{ value: -1, label: 'n/a' }];
          }

          const approvedProduct = approvedProducts.find((p) => p.typeId === typeId && p.componentId === componentId);

          return [
            { value: params.row?.assetClassId, label: AssetClassEnum.getById(params.row?.assetClassId)?.displayName || '' },
            ...AssetClassEnum.getAll()
              .filter((a) => a.id !== params.row?.assetClassId)
              .filter((a) => {
                return !!(approvedProduct?.assetClassIds || []).find((i) => i === a.id);
              })
              .map((c) => ({ value: c.id, label: c.displayName }))
              .sort((a, b) => a.label.localeCompare(b.label)),
          ];
        }
        return [];
      },
      valueGetter: (params: GridValueGetterParams) => {
        return rowsWithUpdatedValues.current[params.id]?.typeId !== AssetTypeEnum.Model.id ? params.value || '' : -1;
      },
      preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
        let assetClassIsValid = false;
        rowsWithUpdatedValues.current[params.id].assetClassId = params.props.value;

        const typeId = rowsWithUpdatedValues.current[params.id].typeId;
        if (typeId === AssetTypeEnum.Model.id) {
          assetClassIsValid = params.props.value === -1;
        } else {
          const componentId = rowsWithUpdatedValues.current[params.id].componentId;
          const approvedProduct = approvedProducts.find((p) => p.typeId === typeId && p.componentId === componentId);
          assetClassIsValid = !!approvedProduct?.assetClassIds && !!approvedProduct?.assetClassIds.find((a) => a === params.props.value);
        }
        return { ...params.props, error: !assetClassIsValid };
      },
    },
    {
      field: 'weight',
      type: 'number',
      headerName: 'WEIGHT (%)',
      flex: 1,
      width: 220,
      editable: true,
      sortable: false,
      preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
        rowsWithUpdatedValues.current[params.id].weight = params.props.value;
        updateCashComponentWeight();

        const hasError = isNaN(params.props.value) || +params.props.value < 0 || +params.props.value > 100;

        return { ...params.props, error: hasError };
      },
      valueGetter: (params: GridValueGetterParams) => {
        return `${rowsWithUpdatedValues.current[params.id]?.weight || '0.00'}`;
      },
      valueFormatter: (params: GridValueFormatterParams<number>) => {
        return Number(params.value).toFixed(2);
      },
      renderEditCell: (params: GridRenderEditCellParams) => {
        return <AllocationInput {...params} value={params.value} />;
      },
    },
    {
      field: 'actions',
      type: 'actions',
      sortable: false,
      width: 80,
      getActions: (params) => {
        if (params.id !== cashComponentRowIndex.toString()) {
          return [
            <GridActionsCellItem key="1" icon={<DeleteIcon />} label="Delete" onClick={deleteRow(params.id.toString())} disabled={savingProgress.isLoading} />,
          ];
        }
        return [];
      },
    },
  ];

  const updateCashComponentWeight = React.useCallback(() => {
    if (cashComponentRowIndex < 0) {
      return;
    }

    const totalWeights = Object.entries(rowsWithUpdatedValues.current)
      .filter((_r, index) => index !== cashComponentRowIndex)
      .map((r) => Number(r[1].weight))
      .reduce((partialSum, a) => partialSum + a, 0);

    const newCashComponentWeight = totalWeights < 100 ? 100 - totalWeights : 0;

    rowsWithUpdatedValues.current[cashComponentRowIndex].weight = newCashComponentWeight.toFixed(2);
  }, [cashComponentRowIndex, rowsWithUpdatedValues, configurations]);

  const deleteRow = React.useCallback(
    (id: string) => () => {
      delete rowsWithUpdatedValues.current[id];
      updateCashComponentWeight();
      apiRef.current.setRowMode(id, GridRowModes.View);
      apiRef.current.updateRows([{ id, _action: 'delete' }]);
    },
    [rowsWithUpdatedValues, apiRef, cashComponentRowIndex, configurations]
  );

  return (
    <>
      <Typography variant="h4" style={{ paddingBottom: '10px' }}>
        Target Composition
      </Typography>
      <Paper elevation={3}>
        <StyledBox>
          <DataGridPro
            editMode="row"
            apiRef={apiRef}
            rows={savingProgress.isLoading ? [] : configurations}
            columns={columns}
            columnVisibilityModel={{
              configurationId: false,
              actions: editMode,
            }}
            disableColumnMenu
            disableColumnReorder={true}
            style={{ height: '400px' }}
            components={{
              LoadingOverlay: LinearProgress,
              Toolbar: () => {
                const validationErrors = !!Object.entries(rowsWithUpdatedValues.current).find((row) => {
                  return !validateRow(row[1]);
                });

                const handleAddClick = React.useCallback(async () => {
                  const id = apiRef.current.getAllRowIds().length.toString();

                  const newRow: DataRow = {
                    id,
                    typeId: AssetTypeEnum.Security.id,
                    configurationId: null,
                    componentName: '',
                    componentCode: '',
                    componentId: null,
                    assetClassId: null,
                    weight: '0.00',
                  };

                  rowsWithUpdatedValues.current[id] = newRow;
                  apiRef.current.updateRows([{ ...newRow, id, isNew: true }]);
                  apiRef.current.setRowMode(id, GridRowModes.Edit);

                  await apiRef.current.setEditCellValue({ id: id.toString(), field: 'typeId', value: newRow.typeId });
                  await apiRef.current.setEditCellValue({ id: id.toString(), field: 'componentId', value: '' });
                  await apiRef.current.setEditCellValue({ id: id.toString(), field: 'assetClassId', value: '' });
                  await apiRef.current.setEditCellValue({ id: id.toString(), field: 'weight', value: newRow.weight });

                  // Wait for the grid to render with the new row
                  setTimeout(() => {
                    apiRef.current.scrollToIndexes({
                      rowIndex: apiRef.current.getRowsCount() - 1,
                    });

                    apiRef.current.setCellFocus(-1, 'name');
                  });
                }, [apiRef]);

                const handleEditClick = React.useCallback(() => {
                  setEditMode(true);
                  apiRef.current.getAllRowIds().forEach((id) => {
                    const row = rowsWithUpdatedValues.current[id];
                    if (!(row.componentId === cashAccountProduct?.componentId && row.typeId === cashAccountProduct?.typeId)) {
                      apiRef.current.setRowMode(id, GridRowModes.Edit);
                    }
                  });
                }, [apiRef, rowsWithUpdatedValues]);

                const handleCancelClick = React.useCallback(() => {
                  setEditMode(false);
                  apiRef.current.getAllRowIds().forEach((id) => {
                    const rowMode = apiRef.current.getRowMode(id);
                    if (rowMode === 'edit') {
                      apiRef.current.setRowMode(id, GridRowModes.View);
                    }
                    const row = apiRef.current.getRow(id);
                    if (!!row && row.isNew) {
                      apiRef.current.updateRows([{ id, _action: 'delete' }]);
                    }
                  });

                  resetConfigurations();
                }, [apiRef]);

                const handleSave = useCallback(async () => {
                  if (!!onSave) {
                    const configurationsToSave: SaveConfigurationsItem[] = Object.entries(rowsWithUpdatedValues.current)
                      .map((e) => e[1])
                      .map((r) => {
                        return {
                          typeId: r.typeId as number,
                          allocation: Number(r.weight),
                          assetClassId: r.assetClassId === -1 ? null : r.assetClassId,
                          componentId: r.componentId as number,
                        };
                      });

                    handleCancelClick();
                    await onSave(configurationsToSave);
                  }
                }, [onSave, rowsWithUpdatedValues]);

                return (
                  <GridToolbarContainer>
                    <Stack direction="row" alignItems="center">
                      {savingProgress.isLoading && (
                        <div className="LoadingIndicator" style={{ padding: '7px' }}>
                          <PulseLoader size="9px" margin="5px" color={theme.palette.grey[400]} />
                        </div>
                      )}
                      {!hideEditControls && editMode && !savingProgress.isLoading && (
                        <IconButton disableFocusRipple disableRipple data-testid="addButton" onClick={handleAddClick} color={'primary'}>
                          <AddIcon style={{ height: '24px' }} />
                        </IconButton>
                      )}
                      {!hideEditControls && !editMode && !savingProgress.isLoading && (
                        <IconButton disableFocusRipple disableRipple data-testid="editButton" onClick={handleEditClick} color={'primary'}>
                          <EditIcon style={{ height: '24px' }} />
                        </IconButton>
                      )}
                      {!hideEditControls && editMode && !savingProgress.isLoading && (
                        <IconButton disableFocusRipple disableRipple data-testid="cancelButton" onClick={handleCancelClick} color={'primary'}>
                          <CancelIcon style={{ height: '24px' }} />
                        </IconButton>
                      )}
                      {!hideEditControls && editMode && !savingProgress.isLoading && (
                        <IconButton
                          disableFocusRipple
                          disableRipple
                          data-testid="saveButton"
                          onClick={handleSave}
                          color={'primary'}
                          disabled={validationErrors}
                        >
                          <SaveIcon style={{ height: '20px' }} />
                        </IconButton>
                      )}
                    </Stack>
                  </GridToolbarContainer>
                );
              },
              Footer: () => {
                const totalWeights = Object.entries(rowsWithUpdatedValues.current)
                  .map((e) => Number(e[1].weight))
                  .map((w) => {
                    return isNaN(w) ? 0 : +w;
                  })
                  .reduce((partialSum, a) => partialSum + a, 0);

                return (
                  <Typography
                    variant="h4"
                    style={{
                      color: totalWeights !== 100 ? 'red' : 'darkgray',
                      display: 'flex',
                      justifyContent: 'right',
                      padding: '20px',
                      fontWeight: 'bold',
                    }}
                  >
                    Total: {totalWeights}%
                  </Typography>
                );
              },
            }}
            componentsProps={{
              footer: { total: items.reduce((partialSum, a) => partialSum + a.allocation, 0) },
            }}
            loading={itemLoadingProgress.isLoading || savingProgress.isLoading}
            onRowEditStop={(_params: GridRowParams, event: MuiEvent<MuiBaseEvent>) => {
              // we don't want to end editting unless they click save
              event.defaultMuiPrevented = true;
            }}
            onRowEditStart={(_params: GridRowParams, event: MuiEvent<MuiBaseEvent>) => {
              // we don't want to end editting unless they click edit
              event.defaultMuiPrevented = true;
            }}
          />
        </StyledBox>
      </Paper>
    </>
  );
};
