import { Alert, Box, FormControl, FormControlLabel, Paper, Radio, RadioGroup, Stack, Typography } from '@mui/material';
import React from 'react';
import { InitiateExistingUxchangeAccountApplicationPayload } from 'src/features/clients/client/details/accounts/store/types';
import { WO2Checkbox } from '../../Checkbox';
import { WO2Modal } from '../../Modal';
import { AttachableCmaAccount } from '../types';

export interface Props {
  onClose: () => void;
  submitAccountId: number | null;
  onSubmit: (selectedCmaAccount: InitiateExistingUxchangeAccountApplicationPayload) => void;
  attachableCmaAccounts: AttachableCmaAccount[];
  clientId: number | null | undefined;
}

export function UXchangeAccount({ attachableCmaAccounts, submitAccountId, clientId, onClose, onSubmit }: Props): JSX.Element {
  const [selectedAcknowledgements, selectAcknowledgements] = React.useState<boolean>(false);
  const [selectedCmaAccount, setCmaAccount] = React.useState<string | null>(null);

  const acknowledgements = () => {
    selectAcknowledgements(!selectedAcknowledgements);
  };

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCmaAccount(event.target.value);
  };

  return (
    <WO2Modal
      MuiDialogProps={{
        open: true,
        onClose: () => {
          onClose();
        },
        maxWidth: 'sm',
      }}
      title={<b>Acknowledgement</b>}
      actions={[
        {
          label: 'Close',
          onClickHandler: () => {
            onClose();
          },
          type: 'contained',
          disabled: false,
        },
        {
          label: 'Submit',
          onClickHandler: () => {
            if (selectedAcknowledgements && submitAccountId && clientId) {
              const account = attachableCmaAccounts[parseInt(selectedCmaAccount ?? '0')];
              onSubmit({
                pendingAccountId: submitAccountId.toString(),
                clientId,
                cmaSubNumber: account.bsb,
                cmaName: account.name,
                cmaNumber: account.number,
              });
              !!onClose && onClose();
            }
          },
          type: 'contained',
          disabled: !selectedAcknowledgements || !attachableCmaAccounts || attachableCmaAccounts.length === 0,
        },
      ]}
    >
      <div>
        <Typography variant="h4" style={{ fontWeight: 700, paddingTop: '10px' }}>
          Are you sure you want to create this account?
        </Typography>
        <p>The application is ready to be submitted. Please ensure the application is correct before processing.</p>

        <Typography variant="h4" style={{ fontWeight: 700, paddingTop: '10px' }}>
          Link CMA
        </Typography>

        <FormControl component="fieldset" variant="standard">
          <RadioGroup row aria-label="gender" name="row-radio-buttons-group" value={parseInt(selectedCmaAccount ?? '0')} onChange={handleRadioChange}>
            {attachableCmaAccounts.map((account, index) => (
              <Stack key={index} spacing={2} direction="row" padding={'6px'}>
                <Box width="50px" paddingTop="12px" paddingBottom="12px">
                  <FormControlLabel value={index ?? 0} control={<Radio />} label="" style={{ margin: '0' }} />
                </Box>
                <Paper variant={'outlined'} style={{ padding: '12px', marginLeft: '0' }}>
                  <Stack key={index} spacing={2} direction="row">
                    <img src="/images/institution/10.png" alt="uXchange logo" height="48" />
                    <div>
                      <Typography variant="h5">BSB: {account.bsb}</Typography>
                      <Typography variant="h5">number: {account.number}</Typography>
                    </div>
                  </Stack>
                </Paper>
              </Stack>
            ))}
          </RadioGroup>
        </FormControl>

        {!attachableCmaAccounts.length && (
          <Alert variant="outlined" severity="warning">
            Please setup a CMA before continuing. You must have a pending or active CMA before you can create other accounts.
          </Alert>
        )}

        <Typography variant="h4" style={{ fontWeight: 700, padding: '10px 0' }}>
          Acknowledgements
        </Typography>

        <Stack direction="row" spacing={2}>
          <Box width="50px">
            <WO2Checkbox color="primary" checked={selectedAcknowledgements} onChangeHandler={acknowledgements} />
          </Box>
          <Typography variant="body2" style={{ marginBottom: '24px ' }}>
            I hereby declare that I am not a US Person as defined in the PDS; that the applicant is not a US Person as defined in the PDS, and I have completed
            an appropriate customer identification procedure (CID) on this investor which meets the AML/CTF Act. I have provided the latest uXchange PDS to the
            applicant who has read, accepted and understood the terms.
          </Typography>
        </Stack>
      </div>
    </WO2Modal>
  );
}
