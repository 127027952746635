import InfoIcon from '@mui/icons-material/Info';
import { Box, Tooltip, Typography } from '@mui/material';
import { Form, Formik, FormikProps } from 'formik';
import React, { useCallback, useEffect, useRef } from 'react';
import { LoadingIndicator } from 'src/common/components/LoadingIndicator';
import WO2Button from '../../../../../common/components/button/Button';
import { theme } from '../../../../../themes';
import { feesGridGstText, onboardingFeeGridHelpText } from '../../../common/config/adviceFees';
import { FeeMethod } from '../../../common/enums';
import { AdviceFeesValues, EstimatedFee } from '../../../common/types';
import { calculateAmountAndPercentage, calculateEstimatedFeesItems } from '../../../common/utils/calculation';
import { PageBackNextButtons } from '../../components/common/pageBackNextButtons';
import { AdviceFees as AdviceFeesType, FetchFeesForAdviserPayload } from '../../store/types';
import { Props } from '../container';
import { AdviceFeeDetailsEdit } from './adviceFeeDetailsEdit';
import { AdviceFeesTable } from './adviceFeesTable';

export const AdviceFees = (props: Props): JSX.Element => {
  const {
    id,
    history,
    prevNextRoutePaths,
    clientAccount,
    superSimplifierDetailsValues,
    estimatedFeesItems,
    selectedAdvisor,
    selectedFeeItem,
    saveEstimatedFee,
    removeEstimatedFee,
    importStandardFees,
    tieredFeeDetailsItems,
    selectedTieredFeeDetailsEdit,
    standardFees,
    setEstimatedFeeEdit,
    setTieredFeeDetailsEditId,
    setTieredFeeDetailsAdd,
    saveEditingTieredFeeDetails,
    removeEditingTieredFeeDetails,
    fetchFeesForAdviserProgress,
    saveProgress,
    saveAdviceFeesValues,
    fetchFeesForAdviser,
  } = props;

  useEffect(() => {
    const payload: FetchFeesForAdviserPayload = {
      adviserId: selectedAdvisor?.advisorId ?? 0,
      calculationTypeIds: [],
      accountTypeIds: clientAccount.accountTypeId ? [clientAccount.accountTypeId] : [],
      subAccountTypeIds: clientAccount.subTypeId ? [clientAccount.subTypeId] : [],
    };
    fetchFeesForAdviser(payload);
  }, [fetchFeesForAdviser, selectedAdvisor, clientAccount.accountTypeId, clientAccount.subTypeId]);

  const formRef = useRef<FormikProps<AdviceFeesValues>>(null);

  const formValues: AdviceFeesValues = {
    estimatedFeesItems: estimatedFeesItems,
  };
  const totalRolloverAmount =
    (superSimplifierDetailsValues?.rolloverAmount ?? 0) +
    (superSimplifierDetailsValues?.rolloverAmountSecond ?? 0) +
    (superSimplifierDetailsValues?.rolloverAmountThird ?? 0);

  const contributionsAmount = superSimplifierDetailsValues?.contributionsAmount ?? 0;

  const handleImportStandardFeesButtonClick = useCallback(() => {
    importStandardFees();
  }, [importStandardFees]);

  const handleAddButtonClick = () => {
    setEstimatedFeeEdit(null);
  };

  const handleCancelClick = useCallback(() => {
    setEstimatedFeeEdit(undefined);
  }, [setEstimatedFeeEdit]);

  const onAddEditEstimatedFee = (fee: EstimatedFee) => {
    setEstimatedFeeEdit(fee);
  };

  const onDeleteEstimatedFee = (index: number) => {
    removeEstimatedFee(index);
  };

  const onSaveFeeDetails = (fee: EstimatedFee) => {
    saveEstimatedFee({
      ...fee,
      amount: fee?.methodId === FeeMethod.Dollar.id ? fee.amount : 0,
      percentageOfValue: fee?.methodId === FeeMethod.Percentage.id ? fee.percentageOfValue : 0,
    });
  };

  const save = useCallback(
    async (values: AdviceFeesType) => {
      await saveAdviceFeesValues(values);
    },
    [saveAdviceFeesValues]
  );

  const onSaveButtonClick = useCallback(
    async (redirectUrl: string) => {
      // clear all advice fees data
      await save({
        estimatedFees: { items: estimatedFeesItems },
        // editingTieredFeeDetails: { items: [] },
        standardFees: standardFees ?? [],
      });
      history.push(redirectUrl);
    },
    [history, prevNextRoutePaths, save, estimatedFeesItems, id, standardFees]
  );

  return (
    <Box style={{ width: '100%' }}>
      <Box style={{ paddingBottom: '10px', marginBottom: '30px' }}>
        <Typography variant="h1" style={{ fontSize: '26px', fontWeight: 500, lineHeight: 1.3 }}>
          What advice fees will be charged to the client?
        </Typography>
      </Box>
      <Formik<AdviceFeesValues>
        enableReinitialize={true}
        initialValues={formValues}
        innerRef={formRef}
        onSubmit={() => {
          const { nextRoutePath } = prevNextRoutePaths;
          save({
            estimatedFees: {
              items: formValues.estimatedFeesItems.map((estimatedFeesItem) => {
                const calculatedAmountAndPercentage = calculateAmountAndPercentage(estimatedFeesItem, totalRolloverAmount, contributionsAmount, false);
                return {
                  ...estimatedFeesItem,
                  amount: estimatedFeesItem?.methodId === FeeMethod.Dollar.id ? calculatedAmountAndPercentage.amount : 0,
                  percentageOfValue: estimatedFeesItem?.methodId === FeeMethod.Percentage.id ? +calculatedAmountAndPercentage.percentageOfValue.toFixed(4) : 0,
                };
              }),
            },
            standardFees: standardFees ?? [],
          }).then(() => {
            if (nextRoutePath) {
              history.push(nextRoutePath + (!!id ? `?id=${id}` : ''));
            }
          });
        }}
      >
        {(formikProps: FormikProps<AdviceFeesValues>) => (
          <Form>
            <LoadingIndicator progress={fetchFeesForAdviserProgress}>
              <Box
                style={{ padding: '20px', margin: '60px 0 30px', minHeight: '50px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
              >
                <Typography variant="h2" style={{ fontSize: '18px' }}></Typography>
                <Box display={fetchFeesForAdviserProgress && fetchFeesForAdviserProgress.isLoading ? 'none' : 'flex'} data-testid="feeButtonsBox">
                  <Box style={{ display: 'flex', alignItems: 'center' }}>
                    <WO2Button
                      color={'primary'}
                      style={{ padding: '0 30px' }}
                      variant={'contained'}
                      value="Import Standard Fees"
                      type={'button'}
                      disabled={false}
                      onClick={handleImportStandardFeesButtonClick}
                    >
                      Import Standard Fees
                    </WO2Button>
                    {Array.isArray(standardFees) && standardFees.length === 0 && (
                      <Tooltip title={'There are no standard fees at the Practice or AFSL level to import.'} placement={'bottom'} arrow>
                        <InfoIcon style={{ width: '20px', height: '20px', marginLeft: '4px', fill: `${theme.palette.primary.main}` }} />
                      </Tooltip>
                    )}
                  </Box>
                  <WO2Button
                    color={'primary'}
                    style={{ marginLeft: '30px', padding: '0 30px' }}
                    variant={'contained'}
                    value="Add New Fee"
                    type={'button'}
                    disabled={false}
                    onClick={handleAddButtonClick}
                  >
                    <Typography variant="inherit">+</Typography>
                    <Typography variant="inherit" style={{ marginLeft: '20px' }}>
                      Add New Fee
                    </Typography>
                  </WO2Button>
                </Box>
              </Box>
              <Box style={{ display: 'flex', alignItems: 'center', marginBottom: '20px', height: '20px' }}>
                <Typography variant="body1">{onboardingFeeGridHelpText}</Typography>
              </Box>
              <AdviceFeesTable
                estimatedFeesItems={calculateEstimatedFeesItems(formikProps.values.estimatedFeesItems, totalRolloverAmount, contributionsAmount)}
                loadingProgress={{ isLoading: false, hasErrors: false }}
                onAddEdit={onAddEditEstimatedFee}
                onDelete={onDeleteEstimatedFee}
              />
              <Typography variant="body1" style={{ marginBottom: '60px' }}>
                {feesGridGstText}
              </Typography>
            </LoadingIndicator>
            <PageBackNextButtons<AdviceFeesValues>
              onBackButtonClick={() => {
                const { prevRoutePath } = prevNextRoutePaths;
                if (prevRoutePath) {
                  onSaveButtonClick(prevRoutePath + (!!id ? `?id=${id}` : ''));
                }
              }}
              onNextButtonClick={async () => {
                await formikProps.validateForm().then(() => {
                  if (formikProps.isValid) {
                    formikProps.submitForm();
                  }
                });
              }}
              onQuitButtonClick={() => {
                onSaveButtonClick('/client/list?mode=onboard');
              }}
              progress={saveProgress}
            />
          </Form>
        )}
      </Formik>
      {selectedFeeItem !== undefined && (
        <AdviceFeeDetailsEdit
          estimatedFeesItems={estimatedFeesItems}
          selectedFeeItem={selectedFeeItem}
          tieredFeeDetailsItems={tieredFeeDetailsItems}
          selectedTieredFeeDetailsEdit={selectedTieredFeeDetailsEdit}
          isViewMode={!!selectedFeeItem?.templateCode}
          onSave={onSaveFeeDetails}
          onCancel={handleCancelClick}
          setTieredFeeDetailsEditId={(index: number | null | undefined) => {
            if (index !== null && index !== undefined) {
              setTieredFeeDetailsEditId(tieredFeeDetailsItems[index]);
            } else {
              setTieredFeeDetailsEditId(index);
            }
          }}
          setTieredFeeDetailsAdd={setTieredFeeDetailsAdd}
          saveEditingTieredFeeDetails={saveEditingTieredFeeDetails}
          removeEditingTieredFeeDetails={removeEditingTieredFeeDetails}
        />
      )}
    </Box>
  );
};
