import {
  AccountCircleRounded,
  AutoGraphRounded,
  BeachAccessRounded,
  HistoryEduRounded,
  PeopleAltRounded,
  SpaRounded,
  StorefrontRounded,
} from '@mui/icons-material';
import { Box, Card, CardContent, Grid, SvgIconProps, Typography } from '@mui/material';
import { ErrorMessage, Field, Form, Formik, FormikProps } from 'formik';
import { default as React, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { ToggleButtonItem } from 'src/common';
import { FormikSelect, FormikToggleButton } from 'src/common/components/formik';
import { ClientAccountSubType, ClientAccountType, PensionType } from 'src/common/types';
import { TrusteeType, TrustType } from 'src/features/clients/client/details/mainDetails/store';
import { CorporateType } from 'src/features/clients/common/enums';
import * as yup from 'yup';
import WO2Button from '../../../../../common/components/button/Button';
import { AfslItem, PracticeItem } from '../../../../bulk/common/store/types';
import { PageBackNextButtons } from '../../components/common/pageBackNextButtons';
import { AccountTypeValues, AdviserItem } from '../../store/types';
import { Props } from '../container';

interface AccountTypeButton {
  label: string;
  subText: string;
  accountTypeId: number;
  subTypeId: number | null;
  icon: React.ReactElement<SvgIconProps>;
}

const AccountTypeButtons: AccountTypeButton[] = [
  {
    label: ClientAccountType.Individual.displayName,
    accountTypeId: ClientAccountType.Individual.id,
    subTypeId: 1,
    icon: <AccountCircleRounded color="primary" fontSize="large" />,
    subText: 'Single person',
  },
  {
    label: ClientAccountType.Smsf.displayName,
    accountTypeId: ClientAccountType.Smsf.id,
    subTypeId: null,
    icon: <SpaRounded color="primary" fontSize="large" />,
    subText: 'Self Managed Super',
  },
  {
    label: 'Super',
    accountTypeId: ClientAccountType.Individual.id,
    subTypeId: 2,
    icon: <AutoGraphRounded color="primary" fontSize="large" />,
    subText: 'Super Simplifier accumulation division',
  },
  {
    label: ClientAccountType.Company.displayName,
    accountTypeId: ClientAccountType.Company.id,
    subTypeId: null,
    icon: <StorefrontRounded color="primary" fontSize="large" />,
    subText: 'Enterprise registered with ASIC',
  },
  {
    label: 'Pension',
    accountTypeId: ClientAccountType.Individual.id,
    subTypeId: 3,
    icon: <BeachAccessRounded color="primary" fontSize="large" />,
    subText: 'Super Simplifier pension division',
  },
  {
    label: ClientAccountType.Trust.displayName,
    accountTypeId: ClientAccountType.Trust.id,
    subTypeId: null,
    icon: <HistoryEduRounded color="primary" fontSize="large" />,
    subText: 'With corporate or individual trustee',
  },
  {
    label: ClientAccountType.Joint.displayName,
    accountTypeId: ClientAccountType.Joint.id,
    subTypeId: null,
    icon: <PeopleAltRounded color="primary" fontSize="large" />,
    subText: '2 or more individuals',
  },
];

export const AccountType = (props: Props): JSX.Element => {
  const location = useLocation();

  const { id, history, accountTypeValues, saveAccountTypeValues, clearOnboarding, prevNextRoutePaths, afsls, fetchAfsls, saveProgress } = props;

  useEffect(() => {
    const newOnboardingSession = new URLSearchParams(location.search).get('new') === '';

    if (newOnboardingSession) {
      // starting a new onboarding so clear the state
      clearOnboarding();
    }
  }, [location, clearOnboarding]);

  const initialFormValues: AccountTypeValues = {
    id: null,
    advisor: { advisorId: 0, advisorName: '', afslId: 0 },
    clientAccount: {
      accountTypeId: null,
      subTypeId: null,
      corporateTypeId: null,
      typeOfTrustId: null,
      trusteeTypeId: null,
    },
    superSimplifierDetails: {
      pensionTypeId: null,
    },
  };

  const [formValues, setFormValues] = useState<AccountTypeValues>(initialFormValues);

  useEffect(() => {
    fetchAfsls();
  }, [fetchAfsls]);

  useEffect(() => {
    setFormValues({
      id: accountTypeValues.id,
      advisor: accountTypeValues.advisor,
      clientAccount: accountTypeValues.clientAccount,
      superSimplifierDetails: accountTypeValues.superSimplifierDetails,
    });
  }, [accountTypeValues, setFormValues]);

  const corporateTypeToggleButtons: ToggleButtonItem<number>[] = CorporateType.getArray().map((corporateType: CorporateType) => {
    return { name: corporateType.displayName, value: corporateType.id };
  });

  const trustTypeToggleButtons: ToggleButtonItem<number>[] = TrustType.getArray().map((trustType: TrustType) => {
    return { name: trustType.displayName, value: trustType.id };
  });

  const trusteeTypeToggleButtons: ToggleButtonItem<number>[] = TrusteeType.getArray().map((trusteeType: TrusteeType) => {
    return { name: trusteeType.displayName, value: trusteeType.id };
  });

  const pensionTypeToggleButtons: ToggleButtonItem<number>[] = PensionType.getArray().map((pensionType: PensionType) => {
    return { name: pensionType.displayName, value: pensionType.id };
  });

  const clearUnusedFields = (accountTypeId: number, subTypeId: number | null, props: FormikProps<AccountTypeValues>) => {
    // clear any fields that have values that shouldn't now because accountTypeId was changed
    props.setFieldValue('clientAccount.corporateTypeId', accountTypeId === ClientAccountType.Company.id ? props.values.clientAccount?.corporateTypeId : null);

    props.setFieldValue(
      'clientAccount.trusteeTypeId',
      accountTypeId === ClientAccountType.Smsf.id || accountTypeId === ClientAccountType.Trust.id ? props.values.clientAccount?.trusteeTypeId : null
    );

    props.setFieldValue('clientAccount.typeOfTrustId', accountTypeId === ClientAccountType.Trust.id ? props.values.clientAccount?.typeOfTrustId : null);

    props.setFieldValue(
      'superSimplifierDetails.pensionTypeId',
      subTypeId === ClientAccountSubType.Pension.id ? props.values.clientAccount?.typeOfTrustId : null
    );
  };

  const [isPension, setIsPension] = useState<boolean>(false); // fix for pensionType validation

  const allAdvisors =
    Array.isArray(afsls) && afsls.length > 0
      ? afsls
          .reduce((acc: AdviserItem[], afsl: AfslItem) => {
            const advisors =
              Array.isArray(afsl.practices) && afsl.practices.length > 0
                ? afsl.practices
                    .map((practice: PracticeItem) => practice.advisors)
                    .reduce((practiceA, practiceB) => practiceA.concat(practiceB))
                    .filter((advisor, index, self) => index === self.findIndex((t) => t.advisorId === advisor.advisorId))
                    .map((advisor) => {
                      return { afslId: afsl.afslId, ...advisor };
                    })
                : [];
            return [...acc, ...advisors];
          }, [])
          .sort((advisorA, advisorB) => advisorA.advisorName.localeCompare(advisorB.advisorName))
      : [];

  return (
    <Grid container>
      <Grid item xs={12}>
        <Formik<AccountTypeValues>
          enableReinitialize={true}
          initialValues={formValues}
          onSubmit={async (details) => {
            const { nextRoutePath } = prevNextRoutePaths;
            // pre-select trust type to Superannuation for SMSF
            await saveAccountTypeValues({
              ...details,
              ...(details.clientAccount.accountTypeId === ClientAccountType.Smsf.id &&
                details.clientAccount.subTypeId === null && { clientAccount: { ...details.clientAccount, typeOfTrustId: TrustType.Superannuation.id } }),
            });
            if (nextRoutePath) {
              history.push(nextRoutePath + (!!id ? `?id=${id}` : ''));
            }
          }}
          validationSchema={yup.object({
            advisor: yup
              .object({
                advisorId: yup.number().nullable().required('Adviser is required'),
                advisorName: yup.string(),
                afslId: yup.number().nullable().required('Afsl is required'),
              })
              .nullable()
              .required('Adviser is required'),
            clientAccount: yup.object({
              accountTypeId: yup.number().nullable().required('Account type is required'),
              subTypeId: yup.number().nullable(),
              trusteeTypeId: yup
                .number()
                .nullable()
                .when('accountTypeId', {
                  is: (accountTypeId) => accountTypeId === ClientAccountType.Trust.id || accountTypeId === ClientAccountType.Smsf.id,
                  then: yup.number().required('Trustee type is required'),
                }),
              typeOfTrustId: yup
                .number()
                .nullable()
                .when('accountTypeId', {
                  is: (accountTypeId) => accountTypeId === ClientAccountType.Trust.id,
                  then: yup.number().required('Type of trust is required'),
                }),
              corporateTypeId: yup
                .number()
                .nullable()
                .when('accountTypeId', {
                  is: (accountTypeId) => accountTypeId === ClientAccountType.Company.id,
                  then: yup.number().required('Company type is required'),
                }),
            }),
            superSimplifierDetails: yup
              .object({
                validationFix: yup.boolean(),
                pensionTypeId: yup
                  .number()
                  .nullable()
                  .when('validationFix', {
                    is: () => isPension,
                    then: yup.number().required('Pension type is required'),
                  }),
              })
              .nullable(),
          })}
        >
          {(formikProps: FormikProps<AccountTypeValues>) => (
            <Form>
              <Box>
                <fieldset style={{ border: 'none', padding: 0, margin: 0 }}>
                  <Grid style={{ paddingBottom: '10px', marginBottom: '30px' }}>
                    <Typography variant="h7">Please start by telling us the account type and adviser for the client you want to setup.</Typography>
                  </Grid>
                  <Grid style={{ width: '300px', minHeight: '90px', paddingBottom: '20px' }}>
                    <Typography variant="h8">Adviser</Typography>
                    <Field
                      fieldName="advisor.advisorId"
                      as={FormikSelect}
                      itemDisplayNameField="name"
                      showRequiredAsterisk={true}
                      label="ADVISER"
                      valueIsId={true}
                      data={allAdvisors.map((advisor) => {
                        return { id: advisor.advisorId, name: advisor.advisorName };
                      })}
                      onChangeValue={(advisorId: number | null) => {
                        if (!!advisorId) {
                          const advisor = allAdvisors.find((advisor) => advisor.advisorId === advisorId);

                          if (!!advisor) {
                            formikProps.setFieldValue('advisor.advisorName', advisor.advisorName);
                            formikProps.setFieldValue('advisor.afslId', advisor.afslId);
                          }
                        }
                      }}
                      fullWidth
                    ></Field>
                  </Grid>
                  <Typography variant="h8">Client account type</Typography>
                  <Grid container data-testid="accountTypeSelection" style={{ height: '250px', display: 'flex', flexDirection: 'column', flexWrap: 'wrap' }}>
                    {AccountTypeButtons.map((button: AccountTypeButton, index: number) => (
                      <Grid item key={index} style={{ width: 220, height: 120 }}>
                        <WO2Button
                          name={`AccountTypeButton${index}`}
                          type="button"
                          key={index}
                          variant="text"
                          selected={
                            button.accountTypeId === formikProps.values.clientAccount?.accountTypeId &&
                            button.subTypeId === formikProps.values.clientAccount?.subTypeId
                          }
                          style={{ width: '194px', flex: '0 1 40px', marginBottom: '10px', marginTop: '10px', padding: '5px' }}
                          onClick={async () => {
                            formikProps.setFieldValue('clientAccount.accountTypeId', button.accountTypeId);
                            formikProps.setFieldValue('clientAccount.subTypeId', button.subTypeId);

                            clearUnusedFields(button.accountTypeId, button.subTypeId, formikProps);
                            setIsPension(button.subTypeId === ClientAccountSubType.Pension.id);
                            await formikProps.validateForm();
                          }}
                        >
                          <Card
                            elevation={0}
                            style={
                              button.accountTypeId === formikProps.values.clientAccount?.accountTypeId &&
                              button.subTypeId === formikProps.values.clientAccount?.subTypeId
                                ? { border: `1px solid #4306FA`, backgroundColor: `#F2F8FF`, minWidth: 200, height: 90 }
                                : { border: `1px solid #757575`, backgroundColor: ``, minWidth: 200, height: 90 }
                            }
                          >
                            <CardContent style={{ paddingTop: '5px' }}>
                              <Grid container>
                                <Grid item xs={2} color="text.primary">
                                  {button.icon}
                                </Grid>
                                <Grid item xs={10} style={{ paddingLeft: '20px' }}>
                                  <Typography
                                    variant="h4"
                                    align="left"
                                    style={
                                      button.accountTypeId === formikProps.values.clientAccount?.accountTypeId &&
                                      button.subTypeId === formikProps.values.clientAccount?.subTypeId
                                        ? { color: '#4306FA' }
                                        : { color: '#757575' }
                                    }
                                  >
                                    {button.label}
                                  </Typography>
                                  <Typography variant="subtitle1" align="left" style={{ color: '#757575' }}>
                                    {button.subText}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </CardContent>
                          </Card>
                        </WO2Button>
                      </Grid>
                    ))}
                    <ErrorMessage name="clientAccount.accountTypeId"></ErrorMessage>
                  </Grid>
                  {formikProps.values.clientAccount?.accountTypeId === ClientAccountType.Company.id && (
                    <Grid>
                      <Field
                        component={FormikToggleButton}
                        exclusive={true}
                        showRequiredAsterisk={true}
                        buttons={corporateTypeToggleButtons}
                        name="clientAccount.corporateTypeId"
                        label="What is the Company Type?"
                        style={{ minWidth: '300px' }}
                        fullWidth
                      />
                    </Grid>
                  )}
                  {(formikProps.values.clientAccount?.accountTypeId === ClientAccountType.Smsf.id ||
                    formikProps.values.clientAccount?.accountTypeId === ClientAccountType.Trust.id) && (
                    <Grid>
                      <Field
                        component={FormikToggleButton}
                        exclusive={true}
                        showRequiredAsterisk={true}
                        buttons={trusteeTypeToggleButtons}
                        name="clientAccount.trusteeTypeId"
                        label={`What is the Trustee Type of the ${
                          formikProps.values.clientAccount?.accountTypeId === ClientAccountType.Smsf.id ? 'SMSF' : 'Trust'
                        }?`}
                        style={{ minWidth: '380px' }}
                        fullWidth
                      />
                    </Grid>
                  )}
                  {formikProps.values.clientAccount?.accountTypeId === ClientAccountType.Trust.id && (
                    <Grid>
                      <Field
                        component={FormikToggleButton}
                        exclusive={true}
                        showRequiredAsterisk={true}
                        buttons={trustTypeToggleButtons}
                        name="clientAccount.typeOfTrustId"
                        label={`What is the Trust Type of the ${
                          formikProps.values.clientAccount?.accountTypeId === ClientAccountType.Smsf.id ? 'SMSF' : 'Trust'
                        }?`}
                        style={{ minWidth: '570px' }}
                        fullWidth
                      />
                    </Grid>
                  )}
                  {formikProps.values.clientAccount?.subTypeId === ClientAccountSubType.Pension.id && (
                    <Grid>
                      <Field
                        component={FormikToggleButton}
                        exclusive={true}
                        showRequiredAsterisk={true}
                        buttons={pensionTypeToggleButtons}
                        name="superSimplifierDetails.pensionTypeId"
                        label={`What is the Pension Type?`}
                        style={{ minWidth: '900px' }}
                        fullWidth
                      />
                    </Grid>
                  )}
                </fieldset>
              </Box>
              <PageBackNextButtons<AccountTypeValues>
                formikProps={formikProps}
                onNextButtonClick={formikProps.submitForm}
                onQuitButtonClick={
                  !!id
                    ? async () => {
                        await formikProps.submitForm();
                        history.push('/client/list?mode=onboard');
                      }
                    : undefined
                }
                progress={saveProgress}
              />
            </Form>
          )}
        </Formik>
      </Grid>
    </Grid>
  );
};
