import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Account, AccountType, AttachableCmaAccounts, ExternalInstitution, PendingAccount } from 'src/common/components/accounts/types';
import {
  fetchAccounts,
  fetchAccountTypes,
  fetchAttachableCmaAccounts,
  fetchClientNewMacquarieAccountDetailByClientId,
  fetchExternalAccountForEdit,
  fetchExternalInstitutions,
  fetchPendingAccounts,
  fetchTradingAccountForEdit,
} from './thunks';
import { AccountDetails, AccountsState, ClientNewAccountDetail } from './types';

export const initialState: AccountsState = {
  tradingAccounts: { items: [] },
  pendingAccounts: [],
  externalAccounts: { items: [] },
  existingAccounts: [],
  attachableCmaAccounts: { attachableCmaAccounts: [] },
  newAccounts: {
    institutionIds: [],
    accountInfo: {
      isHinTransfer: null,
      hin: null,
      pid: null,
    },
  },
  accountTypes: [],
  externalInstitutions: [],
  macquarieCashOnlinePortal: {
    clientNewAccountDetails: [],
  },
};

export const accountsSlice = createSlice({
  name: 'accounts',
  initialState,
  reducers: {
    setTradingAccountAddMode: (state) => {
      state.tradingAccounts.edit = null;
    },
    cancelTradingAccountAddEditMode: (state) => {
      state.tradingAccounts.edit = undefined;
    },
    setExternalAccountAddMode: (state) => {
      state.externalAccounts.edit = null;
    },
    cancelExternalAccountAddEditMode: (state) => {
      state.externalAccounts.edit = undefined;
    },
    setAccountCreateTypeParameter: () => {
      // to do
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAccounts.fulfilled, (state, action: PayloadAction<Account[]>) => {
      state.tradingAccounts.items = action.payload.filter((a) => !a.isExternal);
      state.externalAccounts.items = action.payload.filter((a) => a.isExternal);
    });
    builder.addCase(fetchPendingAccounts.fulfilled, (state, action: PayloadAction<PendingAccount[]>) => {
      state.pendingAccounts = action.payload;
    });
    builder.addCase(fetchAttachableCmaAccounts.fulfilled, (state, action: PayloadAction<AttachableCmaAccounts>) => {
      state.attachableCmaAccounts = action.payload;
    });
    builder.addCase(fetchExternalInstitutions.fulfilled, (state, action: PayloadAction<ExternalInstitution[]>) => {
      state.externalInstitutions = action.payload;
    });
    builder.addCase(fetchTradingAccountForEdit.pending, (state) => {
      state.tradingAccounts.edit = null;
    });
    builder.addCase(fetchTradingAccountForEdit.fulfilled, (state, action: PayloadAction<AccountDetails>) => {
      state.tradingAccounts.edit = action.payload;
    });
    builder.addCase(fetchExternalAccountForEdit.pending, (state) => {
      state.externalAccounts.edit = null;
    });
    builder.addCase(fetchExternalAccountForEdit.fulfilled, (state, action: PayloadAction<AccountDetails>) => {
      state.externalAccounts.edit = action.payload;
    });
    builder.addCase(fetchAccountTypes.fulfilled, (state, action: PayloadAction<AccountType[]>) => {
      state.accountTypes = action.payload;
    });
    builder.addCase(fetchClientNewMacquarieAccountDetailByClientId.fulfilled, (state, action: PayloadAction<ClientNewAccountDetail>) => {
      state.macquarieCashOnlinePortal = action.payload;
    });
  },
});
