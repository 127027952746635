import AdjustIcon from '@mui/icons-material/Adjust';
import { Box, Button, Grid, OutlinedInput, Paper, ToggleButton, ToggleButtonGroup, Tooltip, Typography } from '@mui/material';
import React from 'react';
import NumberFormat from 'react-number-format';
import { AsyncAutocomplete, DateTimeFormat, decimalPlacesByMarketCode, formatDollars, getLocalDateTime } from '../../../../../common';
import { SecurityItem } from '../../../common/store/types';
import { Props } from '../container';
import { PortfolioSecurityHoldingResult, TradeAction, TradeMethod } from '../store/types';

export const Parameters = (props: Props): JSX.Element => {
  const {
    parameters,
    securities,
    securityHoldings,
    portfolioIds,
    setSecurity,
    setSecuritySearch,
    fetchSecurities,
    fetchPortfolioSecurityHoldings,
    setTradeAction,
    setTradeMethod,
    setAmount,
    setCalculateOrder,
    isLoading,
  } = props;

  const handleSetSecurity = (item: SecurityItem | null) => {
    setSecurity(item);
    if (item) {
      fetchPortfolioSecurityHoldings({ securityId: item.id, portfolioIds: portfolioIds });
    }
  };

  const securityPriceSection = (securityHoldings: PortfolioSecurityHoldingResult) => {
    return (
      <Box display={'inline-flex'}>
        <Typography variant="h5">{formatDollars(securityHoldings.unitPrice)} AUD</Typography>
        <Typography variant="h5" color="secondary" style={{ marginLeft: 5 }}>
          As of {getLocalDateTime(securityHoldings.priceDate, DateTimeFormat.DailyDate)}
        </Typography>
      </Box>
    );
  };

  return (
    <Paper elevation={0} style={{ marginTop: '40px', paddingTop: '30px' }}>
      <Grid container style={{ padding: '0px 20px' }}>
        <Box maxWidth={'600px'} minWidth={'300px'} width={'20%'}>
          <AsyncAutocomplete<SecurityItem>
            label="Security"
            value={parameters.security}
            optionLabel={(i) => i.code + ' - ' + i.name}
            options={securities}
            onOptionChange={handleSetSecurity}
            onSearchChange={setSecuritySearch}
            getNewOptions={fetchSecurities}
          />
        </Box>

        <ToggleButtonGroup size="small" exclusive aria-label="small outlined button group" style={{ padding: '0 20px' }}>
          <ToggleButton
            className={'Green'}
            color={'primary'}
            value={TradeAction.Buy.displayName}
            onClick={() => {
              parameters.tradeAction !== TradeAction.Buy.name && setTradeAction(TradeAction.Buy.name);
            }}
            selected={parameters.tradeAction === TradeAction.Buy.name}
          >
            Buy
          </ToggleButton>
          <ToggleButton
            className={'Red'}
            value={TradeAction.Sell.displayName}
            onClick={() => {
              parameters.tradeAction !== TradeAction.Sell.name && setTradeAction(TradeAction.Sell.name);
            }}
            selected={parameters.tradeAction === TradeAction.Sell.name || parameters.tradeAction === TradeAction.SellAll.name}
          >
            Sell
          </ToggleButton>
          <ToggleButton
            className={'Red'}
            value={TradeAction.SellAll.displayName}
            onClick={() => {
              parameters.tradeAction !== TradeAction.SellAll.name &&
                setTradeAction(TradeAction.SellAll.name) &&
                setTradeMethod(TradeMethod.Units.name) &&
                setAmount(0);
            }}
            selected={parameters.tradeAction === TradeAction.SellAll.name}
          >
            All
          </ToggleButton>
        </ToggleButtonGroup>

        <ToggleButtonGroup size="small" exclusive aria-label="small outlined button group">
          <Tooltip title={'Dollar'} arrow>
            <ToggleButton
              value={TradeMethod.Amount.displayName}
              disabled={parameters.tradeAction === TradeAction.SellAll.name}
              onClick={() => {
                parameters.tradeMethod !== TradeMethod.Amount.name && setTradeMethod(TradeMethod.Amount.name);
              }}
              selected={parameters.tradeMethod === TradeMethod.Amount.name}
            >
              $
            </ToggleButton>
          </Tooltip>
          <Tooltip title={'Percentage'} arrow>
            <ToggleButton
              value={TradeMethod.Percentage.displayName}
              disabled={parameters.tradeAction === TradeAction.SellAll.name}
              onClick={() => {
                parameters.tradeMethod !== TradeMethod.Percentage.name && setTradeMethod(TradeMethod.Percentage.name);
              }}
              selected={parameters.tradeMethod === TradeMethod.Percentage.name}
            >
              %
            </ToggleButton>
          </Tooltip>
          <Tooltip title={'Units'} arrow>
            <ToggleButton
              value={TradeMethod.Units.displayName}
              onClick={() => {
                parameters.tradeMethod !== TradeMethod.Units.name && setTradeMethod(TradeMethod.Units.name);
              }}
              selected={parameters.tradeMethod === TradeMethod.Units.name}
            >
              #
            </ToggleButton>
          </Tooltip>

          <Tooltip title={'Target'} arrow>
            <ToggleButton
              value={TradeMethod.Target.displayName}
              disabled={parameters.tradeAction === TradeAction.SellAll.name}
              onClick={() => {
                parameters.tradeMethod !== TradeMethod.Target.name && setTradeMethod(TradeMethod.Target.name) && setAmount(0);
              }}
              selected={parameters.tradeMethod === TradeMethod.Target.name}
            >
              <AdjustIcon />
            </ToggleButton>
          </Tooltip>
        </ToggleButtonGroup>
        <Box paddingLeft={'20px'} paddingRight={'20px'}>
          {parameters.tradeAction !== TradeAction.SellAll.name && parameters.tradeMethod !== TradeMethod.Target.name && (
            <NumberFormat
              value={parameters.amount ?? 0}
              customInput={OutlinedInput}
              type="text"
              data-id={'edit-amount'}
              disabled={parameters.tradeAction === TradeAction.SellAll.name || parameters.tradeMethod === TradeMethod.Target.name}
              thousandSeparator
              fixedDecimalScale
              allowNegative={false}
              decimalScale={
                parameters.tradeMethod === TradeMethod.Amount.name || parameters.tradeMethod === TradeMethod.Percentage.name
                  ? 2
                  : decimalPlacesByMarketCode(securityHoldings?.code)
              }
              onValueChange={(values) => {
                if (values.floatValue !== parameters.amount) {
                  setAmount(values.floatValue ?? 0);
                }
              }}
            />
          )}
        </Box>
        <Button
          color={'primary'}
          onClick={() => setCalculateOrder()}
          variant="contained"
          disableElevation
          disabled={parameters.security === null || isLoading.isLoading}
        >
          Calculate
        </Button>
      </Grid>
      <Box padding={'5px 20px'}>{securityHoldings && securityPriceSection(securityHoldings)}</Box>
    </Paper>
  );
};
