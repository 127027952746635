import { createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../../../app/api';
import { buildEncodedQueryString } from '../../../../common';
import {
  FetchModelPerformancechartWithBenchmarkPayload,
  FetchModelPeriodicSummaryPayload,
  ModelDetail,
  ModelPerformancechartWithBenchmark,
  ModelPeriodicSummary,
} from './types';

export enum ModelPerformanceActionTypes {
  FetchModelDetail = '@@model/dashboard/FetchModelDetail',
  FetchModelPerformanceChartWithBenchmark = '​@@model/performances/GetModelPerformanceChartWithBenchmark',
  FetchModelPeriodicSummary = '​@@model/performances/GetModelPeriodicSummary',
  FetchBenchmarks = '@@client/performance/FetchBenchmarks',
}

export enum ModelPerformanceApiEndpoints {
  fetchModelDetail = '/bff/GetModelDashboard',
  fetchModelPerformanceChartWithBenchmark = '/performances/model/GetModelPerformanceChartWithBenchmark',
  fetchModelPeriodicSummary = '/performances/model/GetModelPeriodicSummary',
  FetchBenchmarks = '/strategies/GetBenchmarksForAfsl',
}

export const fetchModelDetail = createAsyncThunk(ModelPerformanceActionTypes.FetchModelDetail, async (modelVersionId: number) => {
  const response = await api.get<ModelDetail>(`${ModelPerformanceApiEndpoints.fetchModelDetail}?modelVersionId=${modelVersionId}`);
  return response.data;
});

export const fetchModelPeriodicSummary = createAsyncThunk(
  ModelPerformanceActionTypes.FetchModelPeriodicSummary,
  async (wrapper: FetchModelPeriodicSummaryPayload) => {
    const queryString = buildEncodedQueryString({
      modelVersionId: wrapper.modelVersionId,
      benchmarkStrategyId: wrapper.benchmarkStrategyId,
      endDate: wrapper.endDate,
    });
    const response = await api.get<ModelPeriodicSummary>(`${ModelPerformanceApiEndpoints.fetchModelPeriodicSummary}${queryString}`);
    return response.data;
  }
);

export const fetchModelPerformanceChartWithBenchmark = createAsyncThunk(
  ModelPerformanceActionTypes.FetchModelPerformanceChartWithBenchmark,
  async (wrapper: FetchModelPerformancechartWithBenchmarkPayload) => {
    const queryString = buildEncodedQueryString({
      modelVersionId: wrapper.modelVersionId,
      startDate: wrapper.startDate,
      endDate: wrapper.endDate,
      frequency: wrapper.frequency,
      strategyId: wrapper.strategyId,
    });
    const response = await api.get<ModelPerformancechartWithBenchmark>(`${ModelPerformanceApiEndpoints.fetchModelPerformanceChartWithBenchmark}${queryString}`);
    return response.data;
  }
);
