import { createSelector } from '@reduxjs/toolkit';
import jwt_decode from 'jwt-decode';
import { RootState } from './reducer';
import { UserToken } from './types/UserToken';

export const selectOidc = createSelector(
  (state: RootState) => state,
  (state) => state.oidc
);

export const selectUser = createSelector(selectOidc, (oidc) => oidc.user || undefined);

export const selectToken = createSelector(selectUser, (user) => user?.access_token);

export const selectTokenDecoded = createSelector(selectToken, (token) => (!!token ? jwt_decode<UserToken>(token) : undefined));

export const selectHasClientEdit = createSelector(selectTokenDecoded, (token: UserToken | undefined) => {
  if (!!token) {
    if (!!token['https://api.wealtho2.com.au/scope']) {
      const scope = token['https://api.wealtho2.com.au/scope'];
      return Array.isArray(scope) && scope.indexOf('client:edit') !== -1;
    } else {
      return false;
    }
  } else {
    return process.env.REACT_APP_CLIENT_EDIT === 'true';
  }
});

export const selectHasClientEditAdminOnly = createSelector(selectTokenDecoded, (token: UserToken | undefined) => {
  if (!!token) {
    if (!!token['https://api.wealtho2.com.au/scope']) {
      const scope = token['https://api.wealtho2.com.au/scope'];
      return Array.isArray(scope) && scope.indexOf('client:edit:adminonly') !== -1;
    } else {
      return false;
    }
  } else {
    return process.env.REACT_APP_CLIENT_EDIT === 'true';
  }
});

export const selectHasRebalanceEnhancedTrading = createSelector(selectTokenDecoded, (token: UserToken | undefined) => {
  if (!!token) {
    if (!!token['https://api.wealtho2.com.au/scope']) {
      const scope = token['https://api.wealtho2.com.au/scope'];
      return Array.isArray(scope) && scope.indexOf('rebalance:enhanced-trading') !== -1;
    } else {
      return false;
    }
  } else {
    return process.env.REACT_APP_CLIENT_EDIT === 'true';
  }
});

export const selectTemplatesState = createSelector(
  (state: RootState) => state,
  (state) => state.templates
);

export const selectUserSettingsState = createSelector(
  (state: RootState) => state,
  (state) => state.userSettings
);
