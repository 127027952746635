import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SystemMinimumDate } from '../../../../../common';
import { ClientAccount, ClientCommonState, ClientPortfolio, EntityDetails, Role } from '../../common/store/types';
import {
  fetchClientAccounts,
  fetchClientMainDetails,
  fetchClientPortfolios,
  fetchDocumentTypes,
  fetchEntityDetails,
  fetchRoles,
  fetchSuperSimplifier,
} from './thunks';
import { ClientMainDetails, DocumentType, SuperSimplifierDetails } from './types';

const initialState: ClientCommonState = {
  clientId: null,
  portfolios: [],
  entityDetails: undefined,
  clientMainDetails: undefined,
  accounts: [],
  documentTypes: [],
  roles: [],
  superSimplifier: null,
};

export const commonSlice = createSlice({
  name: '@@client/common',
  initialState,
  reducers: {
    setClientIdParameter: (state, action: PayloadAction<number>) => {
      state.clientId = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchClientPortfolios.fulfilled, (state, action: PayloadAction<ClientPortfolio[]>) => {
      const allPortfolios = action.payload || [];
      const portfoliosWithDates = allPortfolios.filter((p) => !!p.inceptionDate);

      const minimumInceptionDate =
        portfoliosWithDates.length > 0
          ? portfoliosWithDates.sort((a, b) => a.inceptionDate.localeCompare(b.inceptionDate))[0].inceptionDate
          : SystemMinimumDate;
      allPortfolios.unshift({ investmentProgramId: null, inceptionDate: minimumInceptionDate, investmentServiceName: 'All Portfolios' });

      state.portfolios = allPortfolios;
    });

    builder.addCase(fetchEntityDetails.fulfilled, (state, action: PayloadAction<EntityDetails>) => {
      state.entityDetails = action.payload;
    });

    builder.addCase(fetchClientMainDetails.fulfilled, (state, action: PayloadAction<ClientMainDetails>) => {
      state.clientMainDetails = action.payload;
    });

    builder.addCase(fetchSuperSimplifier.fulfilled, (state, action: PayloadAction<SuperSimplifierDetails>) => {
      state.superSimplifier = action.payload;
    });

    builder.addCase(fetchClientAccounts.fulfilled, (state, action: PayloadAction<ClientAccount[]>) => {
      state.accounts = action.payload;
    });

    builder.addCase(fetchDocumentTypes.fulfilled, (state, action: PayloadAction<DocumentType[]>) => {
      state.documentTypes = action.payload;
    });

    builder.addCase(fetchRoles.fulfilled, (state, action: PayloadAction<Role[]>) => {
      state.roles = action.payload;
    });
  },
});
