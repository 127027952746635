import { Typography } from '@mui/material';
import { default as React } from 'react';
import { LoadingIndicator } from '../../../../../../../common/components/LoadingIndicator';
import { LoadingProgress } from '../../../../../../../common/store/types';
import { ConstraintsTable } from '../../constraints/components/constraintsTable';
import { AssetClassTolerances } from '../../details/components/assetClassTolerances';
import { DetailsForm } from '../../details/components/detailsForm';
import { SecurityTolerances } from '../../details/components/securityTolerances';
import { ConfigurationTable } from '../../targets/components/configurationTable';
import { StrategicAllocationTable } from '../../targets/components/strategicAllocationTable';
import { VersionInfo } from './../types';

interface VersionProps {
  version: VersionInfo;
  loadProgress: LoadingProgress;
}

export const Version = (props: VersionProps): JSX.Element => {
  const { details, constraints, configurations, strategicAllocations, assetClasses } = props.version;
  const { loadProgress } = props;

  return (
    <LoadingIndicator progress={loadProgress}>
      {(loadProgress.isLoading || loadProgress.hasErrors) && <div style={{ height: '200px' }}></div>}
      {!loadProgress.isLoading && !loadProgress.hasErrors && (
        <>
          <Typography variant="h4" style={{ paddingBottom: '10px' }}>
            Version {props.version.versionName}
          </Typography>
          <DetailsForm
            benchmarks={[]}
            loadingProgress={{ isLoading: details === null, hasErrors: false }}
            investmentService={details}
            onSave={async () => {
              /* VOID */
            }}
            isCollapsible={true}
            savingProgress={{ isLoading: false, hasErrors: false }}
            hideEditControls={true}
          />
          <div style={{ marginBottom: '24px' }}>
            <SecurityTolerances
              securityRebalanceToleranceBands={details?.securityRebalanceToleranceBands || []}
              loadingProgress={{ isLoading: details === null, hasErrors: false }}
              hideEditControls={true}
              onSave={async () => {
                /* VOID */
              }}
            />
          </div>
          <div style={{ marginBottom: '24px' }}>
            <AssetClassTolerances
              assetClassToleranceBands={details?.assetClassRebalanceToleranceBands || []}
              loadingProgress={{ isLoading: details === null, hasErrors: false }}
              hideEditControls={true}
              onSave={async () => {
                /* VOID */
              }}
            />
          </div>
          {constraints !== null && (
            <div style={{ marginBottom: '24px' }}>
              <Typography variant="h4" style={{ paddingBottom: '10px' }}>
                Constraints
              </Typography>
              <ConstraintsTable
                securities={[]}
                securitiesLoadingProgress={{ isLoading: false, hasErrors: false }}
                items={constraints}
                itemLoadingProgress={{ isLoading: constraints === null, hasErrors: false }}
                savingProgress={{ isLoading: false, hasErrors: false }}
                hideEditControls={true}
              />
            </div>
          )}
          {configurations !== null && (
            <div style={{ marginBottom: '24px' }}>
              <ConfigurationTable
                approvedProducts={[]}
                itemLoadingProgress={{ isLoading: configurations === null, hasErrors: false }}
                items={configurations}
                savingProgress={{ isLoading: false, hasErrors: false }}
                hideEditControls={true}
              />
            </div>
          )}
          {strategicAllocations !== null && assetClasses !== null && (
            <div style={{ marginBottom: '24px' }}>
              <StrategicAllocationTable
                itemLoadingProgress={{ isLoading: strategicAllocations === null || assetClasses === null, hasErrors: false }}
                items={strategicAllocations}
                savingProgress={{ isLoading: false, hasErrors: false }}
                hideEditControls={true}
              />
            </div>
          )}
        </>
      )}
    </LoadingIndicator>
  );
};
