import { moment } from 'src/common/types';

export enum PerformanceChartInterval {
  OneMonth = '1M',
  ThreeMonths = '3M',
  SixMonths = '6M',
  OneYear = '1Y',
  ThreeYears = '3Y',
  Inception = 'ALL',
}

export interface PrimaryBenchmark {
  id: number;
  code: string;
  name: string;
}

export interface SecondaryBenchmark {
  id: number;
  code: string;
  name: string;
}

export interface Afsl {
  id: number;
  name: string;
}

export interface SeriesData {
  date: string;
  return: number;
  cumulativeReturn: number;
}

export interface MonthlyDashboardChart {
  strategyId: number;
  benchmarkStrategyId: number;
  capitalReturn: number;
  incomeReturn: number;
  totalReturn: number;
  bestMonthReturn: number;
  bestMonthDate: Date;
  worstMonthReturn: number;
  worstMonthDate: Date;
  annualVolatility: number;
  seriesData: SeriesData[];
  benchmarkSeriesData: SeriesData[];
}

export interface StrategyVersionCompositionItem {
  strategyVersionId: number;
  securityId: number;
  securityCode: string;
  securityName: string;
  securityType: string;
  allocation: number;
  isSecurityList: boolean;
  securityListId: number;
}

export interface StrategyDashboard {
  strategyId: number;
  strategyName: string;
  assetClass: string;
  strategyVersionId: number;
  fua: number;
  portfolioCount: number;
  oneMonthReturn: number;
  inceptionReturn: number;
  strategyVersionCompositionItems: StrategyVersionCompositionItem[];
}

export interface StrategyDetails {
  strategyId: number;
  strategyVersionId: number;
  name: string;
  code: string;
  type: string;
  inceptionDate: Date;
  analyticsStartDate: Date;
  assetClass: string;
  description: string;
  isSuperSimplifier: boolean;
  targetReturnRate: number | null;
  fua: number;
  portfolioCount: number;
  accessAfsls: Afsl[];
  ownerAfsls: Afsl[];
  primaryBenchmark: PrimaryBenchmark;
  secondaryBenchmark: SecondaryBenchmark;
}

export interface PerformanceChartStartDateParameter {
  startDate: moment;
  interval: PerformanceChartInterval;
}

export interface StrategyDashboardParameters {
  performanceChartStartDate: PerformanceChartStartDateParameter;
}

export interface StrategyDashboardState {
  parameters: StrategyDashboardParameters;
  strategyDashboard: StrategyDashboard | null;
  strategyDetails: StrategyDetails | null;
  monthlyDashboardChart: MonthlyDashboardChart | null;
}

export interface FetchStrategyDashboardPayload {
  strategyId: number;
}

export interface FetchStrategyDetailsPayload {
  strategyId: number;
}

export interface FetchMonthlyDashboardChartPayload {
  strategyId: number;
  fromDate: string;
  toDate: string;
}
