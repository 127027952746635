import { Grid, Tooltip, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { Account } from './types';

export interface AccountDetailProps {
  account: Account;
  isSuperSimplifier?: boolean;
}

const useStyles = makeStyles(() => ({
  rootDiv: {
    width: '100%',
    textAlign: 'left',
  },
  iconImage: {
    height: '30px',
    margin: '0 auto',
  },
  bsb: {
    paddingRight: '20px',
  },
}));

export const AccountDetail = ({ account, isSuperSimplifier }: AccountDetailProps): JSX.Element => {
  const classes = useStyles();

  const institutionLogoFileNames = [6, 10, 13, 61, 81];
  const institutionLogoFileName: string | number | null = institutionLogoFileNames.includes(account.institutionId ?? 0) ? account.institutionId : 'unknown';

  return (
    <div className={classes.rootDiv}>
      <Grid container spacing={2}>
        <Grid container item xs={3} alignContent={'center'}>
          <Tooltip title={account.institution ?? ''} arrow>
            <img src={`/images/institution/${institutionLogoFileName}.png`} alt="Institution" className={classes.iconImage} />
          </Tooltip>
        </Grid>
        <Grid container item xs={9}>
          <Grid container>
            <Grid item xs={12}>
              <Typography variant="h5" style={{ fontWeight: 700 }}>
                {account.accountName}
              </Typography>
            </Grid>
            {!!account.bsb && (
              <Typography variant="h5" className={classes.bsb}>
                BSB: {account.bsb}
              </Typography>
            )}
            <Typography variant="h5">
              {isSuperSimplifier ? 'Super member' : 'Account'} number: {account.accountNumber}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};
