import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { RootState } from '../../../../reducers';
import { selectHasClientEditAdminOnly } from '../../../../store/selectors';
import { setClientIdParameterActionCreator } from '../store/reducer';
import { ClientTabs } from './components/ClientTabs';
import { selectClientId, selectClientMainDetails, selectEntityDetails, selectIsSuperSimplifierClient } from './store/selectors';
import { fetchClientAccounts, fetchClientMainDetails, fetchClientPortfolios, fetchEntityDetails, fetchSuperSimplifier } from './store/thunks';

const mapStateToProps = (state: RootState) => ({
  clientId: selectClientId(state),
  entityDetails: selectEntityDetails(state),
  clientMainDetails: selectClientMainDetails(state),
  isSuperSimplifierClient: selectIsSuperSimplifierClient(state),
  hasClientEditAdminOnlyPermission: selectHasClientEditAdminOnly(state),
});

const mapDispatchToProps = {
  fetchClientAccounts,
  fetchClientPortfolios,
  fetchEntityDetails,
  fetchClientMainDetails,
  fetchSuperSimplifier,
  setClientIdParameter: (clientId: number) => setClientIdParameterActionCreator(clientId),
};

interface MatchParams {
  id?: string;
}

const connector = connect(mapStateToProps, mapDispatchToProps);
export type Props = ConnectedProps<typeof connector> & RouteComponentProps<MatchParams>;
export const CommonContainer = connect(mapStateToProps, mapDispatchToProps)(ClientTabs);
