import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Box, Breadcrumbs, Link, Typography } from '@mui/material';
import React from 'react';

export default function Breadcrumb(): JSX.Element {
  const items = ['Portfolio', 'Rebalance'];

  return (
    <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
      {items.slice(0, -1).map((el, i) => (
        <Link key={i} href="/rebalance">
          <Typography color="textSecondary">{el}</Typography>
        </Link>
      ))}
      <Box borderBottom={'solid 2px black'}>
        <Typography color="textSecondary">{items.slice(-1)}</Typography>
      </Box>
    </Breadcrumbs>
  );
}
