import {
  CodeOutlined,
  DescriptionOutlined,
  FolderOutlined,
  GridOnOutlined,
  HeadsetOutlined,
  HttpOutlined,
  InsertDriveFileOutlined,
  InsertPhotoOutlined,
  PictureAsPdfOutlined,
  ShowChartOutlined,
  SubjectOutlined,
  SvgIconComponent,
  TheatersOutlined,
} from '@mui/icons-material';
import { Enumeration } from '../../../../../common/types';

export enum ContactRole {
  Adviser = 1,
  AuthorisedSignatory = 9,
}

export class AttachmentExtension extends Enumeration {
  static Other = new AttachmentExtension(1, 'Other', 'other', DescriptionOutlined);
  static Word = new AttachmentExtension(2, 'Word', 'word', InsertDriveFileOutlined);
  static Excel = new AttachmentExtension(3, 'Excel', 'excel', GridOnOutlined);
  static Pdf = new AttachmentExtension(5, 'Pdf', 'pdf', PictureAsPdfOutlined);
  static Text = new AttachmentExtension(6, 'Text', 'text', SubjectOutlined);
  static Image = new AttachmentExtension(7, 'Image', 'image', InsertPhotoOutlined);
  static Movie = new AttachmentExtension(8, 'Movie', 'movie', TheatersOutlined);
  static Audio = new AttachmentExtension(9, 'Audio', 'audio', HeadsetOutlined);
  static Html = new AttachmentExtension(10, 'Html', 'html', HttpOutlined);
  static CSV = new AttachmentExtension(11, 'CSV', 'CSV', GridOnOutlined);
  static Zip = new AttachmentExtension(12, 'Zip', 'zip', FolderOutlined);
  static XML = new AttachmentExtension(13, 'XML', 'XML', CodeOutlined);
  static PowerPoint = new AttachmentExtension(14, 'powerPoint', 'PowerPoint', ShowChartOutlined);

  icon: SvgIconComponent;

  constructor(id: number, name: string, displayName: string, icon: SvgIconComponent) {
    super(id, name, displayName);
    this.icon = icon;
  }

  static getById = (status: number): AttachmentExtension | undefined => {
    const extension = Object.values(AttachmentExtension)
      .filter((t) => t instanceof AttachmentExtension)
      .find((t: AttachmentExtension) => t.id === status);

    return extension || AttachmentExtension.Other;
  };
}

export enum FileType {
  Word = 'Word',
  PDF = 'PDF',
  Email = 'Email',
  Other = 'Other',
}

export class AddressType extends Enumeration {
  static Physical = new AddressType(1, 'Physical / Home / Business', 'Physical');
  static Mailing = new AddressType(2, 'Mailing', 'Mailing');
  static Asic = new AddressType(3, 'Registered (ASIC)', 'ASIC');
  static Chess = new AddressType(8, 'Registered (CHESS)', 'Chess');

  static getArray = (): AddressType[] => [AddressType.Mailing, AddressType.Physical, AddressType.Asic, AddressType.Chess];

  static getById = (id: number): AddressType | undefined => {
    return Object.values(AddressType)
      .filter((t) => t instanceof AddressType)
      .find((t: AddressType) => t.id === id);
  };

  static getByName = (name: string): AddressType | undefined => {
    return Object.values(AddressType)
      .filter((t) => t instanceof AddressType)
      .find((t: AddressType) => t.name.toLowerCase() === name.toLowerCase());
  };
}

export class PhoneNumberType extends Enumeration {
  static Business = new PhoneNumberType(1, 'Business', 'Business');
  static Home = new PhoneNumberType(2, 'Home', 'Home');
  static Mobile = new PhoneNumberType(3, 'Mobile', 'Mobile');
  static Fax = new PhoneNumberType(4, 'Fax', 'Fax');

  static getById = (status: number): PhoneNumberType | undefined => {
    const extension = Object.values(PhoneNumberType)
      .filter((t) => t instanceof PhoneNumberType)
      .find((t: PhoneNumberType) => t.id === status);

    return extension;
  };

  static getByName = (name: string): PhoneNumberType | undefined => {
    return Object.values(PhoneNumberType)
      .filter((t) => t instanceof PhoneNumberType)
      .find((t: PhoneNumberType) => t.name.toLowerCase() === name.toLowerCase());
  };
}

export class EmailType extends Enumeration {
  static Work = new EmailType(5, 'Work', 'Work');
  static Home = new EmailType(6, 'Home', 'Home');
  static UXCorrespondence = new EmailType(7, 'UXCorrespondence', 'UX Correspondence');

  static getById = (status: number | null): EmailType | undefined => {
    const extension = Object.values(EmailType)
      .filter((t) => t instanceof EmailType)
      .find((t: EmailType) => t.id === status);

    return extension;
  };

  static getByName = (name: string): EmailType | undefined => {
    return Object.values(EmailType)
      .filter((t) => t instanceof EmailType)
      .find((t: EmailType) => {
        return t.name.toLowerCase() === name.toLowerCase();
      });
  };
}

export class PaymentFrequency extends Enumeration {
  static Monthly = new PaymentFrequency(1, 'Monthly', 'Monthly');
  static Quarterly = new PaymentFrequency(2, 'Quarterly', 'Quarterly');
  static HalfYearly = new PaymentFrequency(3, 'HalfYearly', 'Half Yearly');
  static Yearly = new PaymentFrequency(4, 'Yearly', 'Yearly');

  static getArray = (): PaymentFrequency[] => [PaymentFrequency.Monthly, PaymentFrequency.Quarterly, PaymentFrequency.HalfYearly, PaymentFrequency.Yearly];
}

export class PaymentLimitType extends Enumeration {
  static Minimum = new PaymentLimitType(1, 'Minimum', 'Minimum');
  static Maximum = new PaymentLimitType(2, 'Maximum', 'Maximum');
  static Other = new PaymentLimitType(3, 'Other', 'Other');

  static getArray = (): PaymentLimitType[] => [PaymentLimitType.Minimum, PaymentLimitType.Maximum, PaymentLimitType.Other];
}

export class PaymentType extends Enumeration {
  static ElectronicTransfer = new PaymentType(1, 'ElectronicTransfer', 'Electronic Transfer');
  static DirectDebit = new PaymentType(2, 'DirectDebit', 'Direct Debit');
  static Neither = new PaymentType(-1, 'Neither', 'No regular contributions');

  static getArray = (): PaymentType[] => [PaymentType.Neither, PaymentType.ElectronicTransfer, PaymentType.DirectDebit];
}
