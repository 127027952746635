import { createAsyncThunk } from '@reduxjs/toolkit';
import api, { ApiError } from '../../../../../../app/api';
import history from '../../../../../../history';
import { RebalancePortfolioRequest, RebalancePortfolioResponse } from './types';

export enum RebalanceActionTypes {
  RebalanceTemplate = '@@client/investmentServices/rebalancesmanual/Initiate',
}

export enum RebalanceApiEndpoints {
  RebalanceTemplate = '/rebalancesmanual/Initiate',
}

export const rebalanceTemplate = createAsyncThunk<RebalancePortfolioResponse, RebalancePortfolioRequest>(
  RebalanceActionTypes.RebalanceTemplate,
  async (payload, thunkApi) => {
    return api
      .post<RebalancePortfolioResponse>(RebalanceApiEndpoints.RebalanceTemplate, payload)
      .then((response) => {
        if (!!response.data.rebalanceId) {
          history.push(`/rebalance?investmentServiceId=${payload.investmentServiceId}&rebalanceId=${response.data?.rebalanceId}`);
          return { message: 'Rebalance initialisation complete', rebalanceId: response.data.rebalanceId, validationMessages: [] };
        }
        return response.data;
      })
      .catch((error: ApiError) => {
        if (error.name === 'wrapped-error') {
          return { message: '', rebalanceId: null, validationMessages: [error.message || ''] };
        }
        return thunkApi.rejectWithValue({ message: 'Could not initiate rebalance at this time.', variant: 'error' });
      });
  }
);
