import { connect, ConnectedProps } from 'react-redux';
import { progressSelectorFactory } from '../../../../common/store/selectors';
import { RootState } from '../../../../reducers';
import { selectClientId, selectPortfolios } from '../common/store/selectors';
import { Transactions } from './components/transactions';
import { selectAccountsForPortfolio, selectParameters, selectSelectedTransaction, selectTransactionResults, selectTransactionsTotals } from './store/selectors';
import { transactionsSlice } from './store/slice';
import { downloadTransactions, fetchTransactionDetails, fetchTransactions, fetchTransactionTotals, TransactionActionTypes } from './store/thunks';

const mapStateToProps = (state: RootState) => ({
  clientId: selectClientId(state),
  totals: selectTransactionsTotals(state),
  transactions: selectTransactionResults(state),
  portfolios: selectPortfolios(state),
  portfolioAccounts: selectAccountsForPortfolio(state),
  parameters: selectParameters(state),
  selectedTransaction: selectSelectedTransaction(state),
  progressTotals: progressSelectorFactory([TransactionActionTypes.FetchTransactionTotals])(state),
  progressTransactions: progressSelectorFactory([TransactionActionTypes.FetchTransactionsForAccounts])(state),
  progressTransactionDetails: progressSelectorFactory([TransactionActionTypes.FetchTransactionDetails])(state),
});

const mapDispatchToProps = {
  ...transactionsSlice.actions,
  fetchTransactionTotals,
  fetchTransactions,
  fetchTransactionDetails,
  downloadTransactions,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export type Props = ConnectedProps<typeof connector>;
export const TransactionsStateContainer = connect(mapStateToProps, mapDispatchToProps)(Transactions);
