import { Delete, Edit, MoreVertOutlined, Visibility } from '@mui/icons-material';
import { Typography } from '@mui/material';
import React from 'react';
import { formatNumberCommaSeparated, formatPercentage } from '../../../../../common';
import { ClientSideDataTable } from '../../../../../common/components/dataTable/clientSide';
import { DatatableColumn } from '../../../../../common/components/dataTable/types';
import { useConfirmation } from '../../../../../common/components/dialogs';
import { WO2Menu } from '../../../../../common/components/Menu';
import { LoadingProgress } from '../../../../../common/store/types';
import { FeeCalculationType, FeeMethod } from '../../../common/enums';
import { AdviceFeesActionType, EstimatedFee } from '../../../common/types';

export interface AdviceFeesTableProps {
  estimatedFeesItems: EstimatedFee[];
  loadingProgress: LoadingProgress;
  hideColumns?: string[];
  onAddEdit?: (fee: EstimatedFee, isViewMode: boolean) => void;
  onDelete?: (calculationTypeId: number) => void;
}

export const AdviceFeesTable = (props: AdviceFeesTableProps): JSX.Element => {
  const { estimatedFeesItems, hideColumns, loadingProgress, onAddEdit, onDelete } = props;
  const confirm = useConfirmation();

  const templateCodeColumn = (dataIndex: number): React.ReactNode => {
    return (
      <Typography variant="h5">
        {FeeCalculationType.getById(estimatedFeesItems[dataIndex]?.calculationTypeId || 0)?.id === FeeCalculationType.TotalFee.id
          ? ''
          : estimatedFeesItems[dataIndex]?.templateCode || 'N/A'}
      </Typography>
    );
  };

  const feeNameColumn = (dataIndex: number): React.ReactNode => {
    return <Typography variant="h5">{estimatedFeesItems[dataIndex]?.name || ''}</Typography>;
  };

  const FeeCalculationTypeColumn = (dataIndex: number): React.ReactNode => {
    return <Typography variant="h5">{FeeCalculationType.getById(estimatedFeesItems[dataIndex]?.calculationTypeId || 0)?.displayName || ''}</Typography>;
  };

  const methodIdColumn = (dataIndex: number): React.ReactNode => {
    return <Typography variant="h5">{FeeMethod.getById(estimatedFeesItems[dataIndex]?.methodId || 0)?.displayName || ''}</Typography>;
  };

  const estimatedFeeColumn = (dataIndex: number): React.ReactNode => {
    const feeAmount = `$${formatNumberCommaSeparated(estimatedFeesItems[dataIndex]?.amount ?? 0, 2)}`;
    return <Typography variant="h5">{feeAmount}</Typography>;
  };

  const percentageOfValueColumn = (dataIndex: number): React.ReactNode => {
    const percentageOfValue = formatPercentage((estimatedFeesItems[dataIndex]?.percentageOfValue ?? 0) / 100, '0.0000%', 4);

    return <Typography variant="h5">{percentageOfValue}</Typography>;
  };

  const actionsColumn = (dataIndex: number): React.ReactNode => {
    const calculationTypeId = FeeCalculationType.getById(estimatedFeesItems[dataIndex]?.calculationTypeId || 0)?.id;
    const isfeeItemEditable = estimatedFeesItems[dataIndex]?.templateCode === null;
    const isTotalRow = calculationTypeId === FeeCalculationType.TotalFee.id;
    const menuProps = {
      open: true,
      PaperProps: {
        style: {
          borderRadius: '8px',
          boxShadow: '0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 5px 8px 0px rgb(0 0 0 / 14%), 0px 1px 14px 0px rgb(0 0 0 / 12%)',
          backgroundColor: '#ffffff',
        },
      },
      MenuListProps: {
        style: {
          padding: 0,
        },
      },
    };

    const actions: AdviceFeesActionType[] = [
      {
        label: isfeeItemEditable ? 'Edit' : 'View',
        clickHandler: () => {
          if (!!onAddEdit) {
            onAddEdit(estimatedFeesItems[dataIndex], !isfeeItemEditable);
          }
        },
        icon: isfeeItemEditable ? (
          <Edit color={'primary'} data-testid={`editButton_${dataIndex}`} />
        ) : (
          <Visibility color={'primary'} data-testid={`viewButton_${dataIndex}`} />
        ),
      },
      {
        label: 'Delete',
        clickHandler: () => {
          confirm({
            title: 'Delete fee item',
            description: 'Are you sure you wish to delete this fee item?',
          }).then(() => {
            if (!!onDelete) {
              onDelete(dataIndex);
            }
          });
        },
        icon: <Delete color="primary" data-testid={`deleteButton_${dataIndex}`} />,
      },
    ];
    const actionsList = actions.map((action: AdviceFeesActionType) => ({
      icon: action.icon,
      label: action.label,
      onClick: () => {
        action.clickHandler();
      },
      testId: `adviceFeesAction_${action.label.replace(/\s/g, '')}_${dataIndex}`,
    }));

    return !isTotalRow ? (
      <WO2Menu
        MUIProps={menuProps}
        testId={`adviceFeesActions_${dataIndex}`}
        buttonTitle="Advice Fees Actions"
        buttonIcon={<MoreVertOutlined color="primary" />}
        items={actionsList}
        showDivider
      />
    ) : null;
  };

  let columns: DatatableColumn[] = [
    {
      name: 'templateCode',
      label: 'Template ID',
      textAlign: 'left',
      options: {
        filter: true,
        sort: false,
        customBodyRenderLite: (dataIndex): React.ReactNode => templateCodeColumn(dataIndex),
      },
    },
    {
      name: 'feeName',
      label: 'Fee Name',
      textAlign: 'left',
      options: {
        filter: true,
        sort: false,
        customBodyRenderLite: (dataIndex): React.ReactNode => feeNameColumn(dataIndex),
      },
    },
    {
      name: 'FeeCalculationType',
      label: 'Fee Type',
      textAlign: 'left',
      options: {
        filter: true,
        sort: false,
        customBodyRenderLite: (dataIndex): React.ReactNode => FeeCalculationTypeColumn(dataIndex),
      },
    },
    {
      name: 'methodId',
      label: 'Calculation Method',
      textAlign: 'left',
      options: {
        filter: true,
        sort: false,
        customBodyRenderLite: (dataIndex): React.ReactNode => methodIdColumn(dataIndex),
      },
    },
    {
      name: 'estimatedFee',
      label: 'Estimated Fee',
      textAlign: 'right',
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (dataIndex): React.ReactNode => estimatedFeeColumn(dataIndex),
        setCellProps: () => {
          return {
            style: {
              textAlign: 'right',
            },
          };
        },
      },
    },
    {
      name: 'percentageOfValue',
      label: '% of Value',
      textAlign: 'right',
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (dataIndex): React.ReactNode => percentageOfValueColumn(dataIndex),
        setCellProps: () => {
          return {
            style: {
              textAlign: 'right',
            },
          };
        },
      },
    },
    {
      name: 'actions',
      label: 'Actions',
      textAlign: 'right',
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (dataIndex: number): React.ReactNode => actionsColumn(dataIndex),
      },
    },
  ];

  // remove columns needs to be hidden (exclude actions)
  if (!!hideColumns && hideColumns.length > 0) {
    columns = columns.filter(
      (column: DatatableColumn) => hideColumns.map((hideColumn: string) => hideColumn.toLowerCase()).indexOf(column.name.toLowerCase()) === -1
    );
  }

  return (
    <div style={{ width: '100%', minWidth: '900px', marginBottom: '10px' }}>
      <ClientSideDataTable
        columns={columns}
        loadingProgress={loadingProgress}
        data={estimatedFeesItems}
        options={{
          filter: false,
          pagination: false,
        }}
      />
    </div>
  );
};
