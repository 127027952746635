import { Box } from '@mui/material';
import Paper from '@mui/material/Paper';
import React, { useEffect } from 'react';
import { Route, Switch, useLocation, useRouteMatch } from 'react-router-dom';
import { theme } from '../../../../themes';
import { AccountTypeContainer } from '../accountType/container';
import { Props } from '../container';
import { LeftMenu } from './leftMenu';
import { onboardRoutes } from './onboardRoutes';

export const Onboard = (props: Props): JSX.Element => {
  const location = useLocation();
  const { clientObject, loadingClientProgress, fetchOnboarding, fetchRoles } = props;
  const { path } = useRouteMatch();

  useEffect(() => {
    fetchRoles();
  }, [fetchRoles]);

  useEffect(() => {
    const id = new URLSearchParams(location.search).get('id');

    if (id !== clientObject.id) {
      if (!!id) {
        // load onboarding data from backend
        fetchOnboarding(id);
      }
    }
  }, [clientObject, location, fetchOnboarding]);

  return (
    <Box
      style={{
        display: 'inline-flex',
        flexDirection: 'row',
        width: '100%',
        margin: '0 auto',
        padding: '40px 24px',
        background: '#FAFAFA',
        alignItems: 'flex-start',
        minHeight: process.env.REACT_APP_NONPROD_ENV === 'true' ? 'calc(100vh - 40px)' : '100vh',
      }}
    >
      <Box
        style={{
          display: 'block',
          marginRight: '10px',
          width: '25%',
          backgroundColor: '#ffffff',
          minWidth: '300px',
          maxWidth: '320px',
          borderRadius: '8px',
          border: '1px #DCE1E3 solid',
          boxShadow: theme.shadows[1],
        }}
      >
        <Paper elevation={0}>
          <LeftMenu clientState={clientObject}></LeftMenu>
        </Paper>
      </Box>
      <Box
        style={{
          display: 'block',
          flex: '1',
          backgroundColor: '#ffffff',
          margin: '0 10px 20px',
          maxWidth: '1300px',
          padding: '20px',
          borderRadius: '8px',
          border: '1px #DCE1E3 solid',
          boxShadow: theme.shadows[1],
        }}
      >
        {!loadingClientProgress.isLoading && (
          <Switch>
            {[
              ...onboardRoutes
                .filter((route) => route.visible(clientObject))
                .map((route) => <Route key={route.menuIndex} path={`${path}/${route.path}`} component={route.component}></Route>),
              <Route key="-1" component={AccountTypeContainer}></Route>,
            ]}
          </Switch>
        )}
      </Box>
    </Box>
  );
};
