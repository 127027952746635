import { createSelector } from '@reduxjs/toolkit';
import { selectConfiguration } from '../../store/selectors';

export const selectLinkedAccounts = createSelector(selectConfiguration, (configuration) => configuration?.linkedAccounts);

export const selectConstraints = createSelector(selectConfiguration, (configuration) => configuration?.constraints);

export const selectTargets = createSelector(selectConfiguration, (configuration) => configuration?.targets);

export const selectDetails = createSelector(selectConfiguration, (configuration) => configuration?.details);

export const selectActivation = createSelector(selectConfiguration, (configuration) => configuration?.activation);
