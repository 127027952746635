import { connect, ConnectedProps } from 'react-redux';
import { progressSelectorFactory } from '../../../../../common';
import { RootState } from '../../../../../reducers';
import { selectClientId, selectIsSuperSimplifierClient, selectSuperSimplifierDetails } from '../../common/store/selectors';
import { CommonActionTypes } from '../../common/store/thunks';
import { BenefitStatement } from './components/benefitStatement';
import { selectBenefitStatementDetails } from './store/selectors';
import { benefitStatementSlice } from './store/slice';
import { BenefitStatmentActionTypes, fetchBenefitStatementDetailsByClientId } from './store/thunks';

const mapStateToProps = (state: RootState) => ({
  clientId: selectClientId(state),
  details: selectSuperSimplifierDetails(state),
  loadingProgressMainDetails: progressSelectorFactory([CommonActionTypes.FetchSuperSimplifier])(state),
  loadingProgressBenefitStatementDetails: progressSelectorFactory([BenefitStatmentActionTypes.FetchBenefitStatementDetailsByClientId])(state),
  benefitStatement: selectBenefitStatementDetails(state),
  isSuperSimplifierClient: selectIsSuperSimplifierClient(state),
});

const mapDispatchToProps = {
  ...benefitStatementSlice.actions,
  fetchBenefitStatementDetailsByClientId,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export type Props = ConnectedProps<typeof connector>;
export const BenefitStatementContainer = connector(BenefitStatement);
