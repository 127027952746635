import { FormControl, InputLabel, MenuItem } from '@mui/material';
import { default as React, useEffect, useState } from 'react';
import { WO2Select } from '../../../../../common';
import { InvestmentServiceWithVersionGrouped } from '../store/common';

interface Props {
  items: InvestmentServiceWithVersionGrouped[];
  selectedInvestmentServiceVersion: InvestmentServiceWithVersionGrouped | null;
  setInvestmentServiceId: (investmentServiceId: number | null, investmentServiceVersionId: number | null) => void;
  optionForAll: boolean;
}

interface MenuItemData {
  value: number;
  name: string;
}

export const PortfolioVersionSelect = ({ selectedInvestmentServiceVersion, optionForAll, items, setInvestmentServiceId }: Props): JSX.Element => {
  const [menuItems, setMenuItems] = useState<MenuItemData[]>([]);

  useEffect(() => {
    setMenuItems(
      items.map((v, index) => {
        let label = `${v.name || `New portfolio ${index + 1}`} `;
        if (v.versions.length === 2) {
          label = `${label} (v${v.versions[0].majorVersion}.${v.versions[0].minorVersion} ${v.versions[0].status.toLowerCase()} / v${
            v.versions[1].majorVersion
          }.${v.versions[1].minorVersion} ${v.versions[1].status.toLowerCase()})`;
        } else {
          label = `${label} (v${v.versions[0].majorVersion}.${v.versions[0].minorVersion} ${v.versions[0].status.toLowerCase()})`;
        }
        return { value: v.investmentServiceId, name: `${label}` };
      })
    );
  }, [items, setMenuItems]);

  return (
    <FormControl variant="outlined">
      <InputLabel id="portfolio-select-label">Portfolio</InputLabel>
      <WO2Select
        defaultValue={''}
        style={{ width: '500px' }}
        id="portfolio-select"
        value={menuItems.length > 0 ? selectedInvestmentServiceVersion?.investmentServiceId ?? (!!optionForAll ? -1 : '') : ''}
        onChange={(event) => {
          const selectedItem = items.find((v) => v.investmentServiceId === event.target.value);
          const investmentServiceVersionId = selectedItem?.versions[selectedItem?.versions.length - 1]?.investmentServiceVersionId || null;
          setInvestmentServiceId(selectedItem?.investmentServiceId || null, investmentServiceVersionId);
        }}
        label={'Portfolio'}
        labelId="portfolio-select-label"
      >
        {optionForAll && <MenuItem value={-1}>All portfolios</MenuItem>}
        {menuItems.map((item) => (
          <MenuItem key={item.value ?? 0} value={item.value}>
            {item.name}
          </MenuItem>
        ))}
      </WO2Select>
    </FormControl>
  );
};
