import { DateRange } from '../../../store';

export enum Grouping {
  ModelSecurity = 'Model, Security',
  AssetClassSecurity = 'Asset class, Security',
  AdviserPortfolio = 'Adviser, Portfolio',
}

export interface SelectedGroup {
  label: string | null;
  groupKey: string | null;
}

export interface HoldingGroup {
  groupKey: string | null;
  label: string | null;
  value: number;
  weight: number;
}

export interface HoldingsParameters {
  groupingSelected: Grouping;
  selectedGroup: SelectedGroup;
  dateSelected: DateRange;
  filterText: string;
}

export interface HoldingItem {
  securityId: number;
  securityCode: string | null;
  securityName: string | null;
  securityPrice: number;
  units: number;
  value: number;
  weight: number;
  numberOfClients: number;
}

export interface GroupItem {
  groupKey: string | null;
  label: string | null;
  value: number;
  weight: number;
  consolidatedSecurityHoldings: HoldingItem[];
}

export interface ConsolidatedSecurityHoldings {
  groupKey: string | null;
  label: string | null;
  value: number;
  weight: number;
  groups: GroupItem[];
  consolidatedSecurityHoldings: HoldingItem[];
}

export interface ConsolidatedHoldingsFields {
  fields: (string | number)[];
}

export interface ConsolidatedHoldings {
  headers: string[];
  rows: ConsolidatedHoldingsFields[];
}

export interface HoldingsState {
  parameters: HoldingsParameters;
  ConsolidatedSecurityHoldings: ConsolidatedSecurityHoldings;
  ConsolidatedHoldings: ConsolidatedHoldings;
}

export interface FetchClientListPayload {
  primaryGrouping: Grouping;
  date: string;
}

export interface FetchConsolidatedHoldingsPayload {
  date: string;
}
