import GetAppIcon from '@mui/icons-material/GetApp';
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import FileSaver from 'file-saver';
import { unparse } from 'papaparse';
import React, { useState } from 'react';
import { DateTimeFormat, getLocalDateTimeNow } from 'src/common';
import { FabMenu, FabMenuItem } from '../../../../../common/components';
import {
  AddTradePayload,
  ExportTrade,
  FetchSecurityRequestPayload,
  FetchSecuritySearchRequestPayload,
  ImportRequestPayload,
  MenuOptions,
  Rebalance,
  Security,
  SecuritySearchResults,
  Trade,
} from '../store/types';
import Import from './import';
import QuickOrder from './quickOrder';

interface Props {
  rebalance: Rebalance;
  importTrades: (payload: ImportRequestPayload) => void;
  fetchSecurities: (payload: FetchSecuritySearchRequestPayload) => void;
  fetchSecurity: (payload: FetchSecurityRequestPayload) => void;
  addTrade: (payload: AddTradePayload) => void;
  setClearGroupTrades: (payload: Trade[]) => void;
  setClearSecuritySearch: () => void;
  selectedSecurity: Security;
  securitySearchResults: SecuritySearchResults;
  selectedExportTrades: ExportTrade[];
  isImporting: boolean;
  importErrors: string[];
}

export default function Menu(props: Props): JSX.Element {
  const { selectedExportTrades, rebalance } = props;
  const [isMenuOpen, setMenuState] = useState(false);
  const [displayMenuOption, setMenuOption] = useState(MenuOptions.None);

  const fabMenuItems: Array<FabMenuItem> = [
    {
      label: 'Import',
      onClick: () => {
        setMenuOption(MenuOptions.Import);
        setMenuState(!isMenuOpen);
      },
      icon: <GetAppIcon />,
    },
    {
      label: 'Export',
      onClick: () => {
        if (selectedExportTrades && selectedExportTrades.length > 0) {
          const csv = unparse(selectedExportTrades);
          const csvData = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
          FileSaver.saveAs(csvData, `WO2_Rebalance_${rebalance.clientName}_${getLocalDateTimeNow(DateTimeFormat.Filename)}.csv`);
        }
        setMenuState(!isMenuOpen);
      },
      icon: <GetAppIcon style={{ transform: 'rotate(180deg)' }} />,
    },
    {
      label: 'Quick order',
      onClick: () => {
        setMenuOption(MenuOptions.QuickOrder);
        setMenuState(!isMenuOpen);
      },
      icon: <SyncAltIcon />,
    },
  ];
  return (
    <>
      <FabMenu
        isMenuOpen={isMenuOpen}
        toggleMenu={() => {
          setMenuState(!isMenuOpen);
        }}
        menuItems={fabMenuItems}
        name="menuFab"
      />
      <QuickOrder {...props} displayMenuOption={displayMenuOption} setMenuOption={setMenuOption} />
      <Import {...props} displayMenuOption={displayMenuOption} setMenuOption={setMenuOption} />
    </>
  );
}
