import { createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../../../app/api';
import { PagedResult } from '../../../../store';
import { FetchWorkflowListPayload, WorkflowItem, FetchPagedResults, SuperMemberDataRequest } from './types';

export enum WorkflowListActionTypes {
  FetchWorkflowList = '@@workflows/list/FetchWorkflowList',
  UploadSuperMemberData = '@@workflows/list/supermemberdataimport',
}

export enum WorkflowListApiEndpoints {
  getAllWorkflows = '/workflowmanager/GetAllWorkflows',
  uploadSuperMemberData = '/SuperSimplifierWorkflow/UploadSuperMemberData',
}

export const fetchWorkflowList = createAsyncThunk(WorkflowListActionTypes.FetchWorkflowList, async (wrapper: FetchWorkflowListPayload) => {
  const response = await getPagedWorkflowsList(wrapper);
  return {
    results: response.data,
    pagination: wrapper.pagination,
  } as FetchPagedResults<WorkflowItem>;
});

const getPagedWorkflowsList = async (request: FetchWorkflowListPayload) => {
  const body = {
    pagedRequest: { ...request.pagination, pageNumber: request.pagination.pageNumber },
  };

  return api.post<PagedResult<WorkflowItem>>(WorkflowListApiEndpoints.getAllWorkflows, body);
};

export const uploadSuperMemberDataCsv = createAsyncThunk<void, SuperMemberDataRequest[], { rejectValue: string[] }>(
  WorkflowListActionTypes.UploadSuperMemberData,
  async (request: SuperMemberDataRequest[], thunkApi) => {
    try {
      return await api.post(WorkflowListApiEndpoints.uploadSuperMemberData, request);
    } catch (e) {
      const result: string[] = [`${JSON.stringify(e)}`];
      return thunkApi.rejectWithValue(result);
    }
  }
);
