import { Grading, Info, LocationOn, SvgIconComponent } from '@mui/icons-material';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded';
import AssessmentIcon from '@mui/icons-material/Assessment';
import ContactsIcon from '@mui/icons-material/Contacts';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import { RouteComponentProps } from 'react-router-dom';
import { ClientAccountSubType, ClientAccountType } from 'src/common/types';
import { TrusteeType } from '../../client/details/mainDetails/store/enums';
import { AccountsContainer } from '../accounts/container';
import { AccountTypeContainer } from '../accountType/container';
import { AdditionalInfoContainer } from '../additionalInfo/container';
import { AddressesContainer } from '../addresses/container';
import { AdviceFeesContainer } from '../adviceFees/container';
import { ContactsContainer } from '../contacts/container';
import { ReviewContainer } from '../review/container';
import { ClientState } from '../store/types';
import { SuperSimplifierContainer } from '../superSimplifier/container';
import { TrusteeContainer } from '../trustees/container';

export interface OnboardMenuRoute {
  path?: string;
  exact?: boolean;
  component?: React.ComponentType<RouteComponentProps<never>> | React.ComponentType<unknown>;
  url?: string;

  key?: string;
  label?: string;
  menuIndex?: number;
  icon?: SvgIconComponent;
  visible: (client: ClientState) => boolean;
  selected: (pathname: string, routeMatch: string, route: OnboardMenuRoute) => boolean;
}

export const onboardRoutes: OnboardMenuRoute[] = [
  {
    key: 'accountType',
    label: 'Account Type',
    menuIndex: 0,
    path: 'accountType/:new?',
    url: 'accountType',
    exact: true,
    component: AccountTypeContainer,
    icon: AccountCircleRoundedIcon,
    visible: (): boolean => true,
    selected: (pathname: string, routeMatch: string, route: OnboardMenuRoute): boolean => pathname === `${routeMatch}/${route.url}`,
  },
  {
    key: 'information',
    label: 'Additional Information',
    menuIndex: 1,
    path: 'information',
    url: 'information',
    component: AdditionalInfoContainer,
    icon: Info,
    visible: (): boolean => true,
    selected: (pathname: string, routeMatch: string, route: OnboardMenuRoute): boolean => pathname === `${routeMatch}/${route.url}`,
  },
  {
    key: 'superSimplifier',
    label: 'Super Simplifier',
    menuIndex: 2,
    path: 'superSimplifier',
    url: 'superSimplifier',
    component: SuperSimplifierContainer,
    icon: AssessmentIcon,
    visible: (client: ClientState): boolean =>
      client.clientAccount.accountTypeId === ClientAccountType.Individual.id &&
      (client.clientAccount.subTypeId === ClientAccountSubType.Super.id || client.clientAccount.subTypeId === ClientAccountSubType.Pension.id),

    selected: (pathname: string, routeMatch: string, route: OnboardMenuRoute): boolean => pathname === `${routeMatch}/${route.url}`,
  },
  {
    key: 'fees',
    label: 'Advice Fees',
    menuIndex: 3,
    path: 'fees',
    url: 'fees',
    component: AdviceFeesContainer,
    icon: MonetizationOnIcon,
    visible: (): boolean => true,

    selected: (pathname: string, routeMatch: string, route: OnboardMenuRoute): boolean => pathname === `${routeMatch}/${route.url}`,
  },
  {
    key: 'accounts',
    label: 'Accounts',
    menuIndex: 4,
    path: 'accounts',
    url: 'accounts',
    component: AccountsContainer,
    icon: AccountBalanceWalletIcon,
    visible: (): boolean => true,
    selected: (pathname: string, routeMatch: string, route: OnboardMenuRoute): boolean => pathname === `${routeMatch}/${route.url}`,
  },
  {
    key: 'trustee',
    label: 'Trustee',
    menuIndex: 5,
    path: 'trustee',
    url: 'trustee',
    component: TrusteeContainer,
    icon: AccountCircleIcon,
    visible: (client: ClientState): boolean =>
      (client.clientAccount.accountTypeId === ClientAccountType.Smsf.id ||
        client.clientAccount.accountTypeId === ClientAccountType.Trust.id ||
        client.clientAccount.accountTypeId === ClientAccountType.Company.id) &&
      client.clientAccount.trusteeTypeId === TrusteeType.Corporate.id,
    selected: (pathname: string, routeMatch: string, route: OnboardMenuRoute): boolean => pathname === `${routeMatch}/${route.url}`,
  },
  {
    key: 'contact0',
    label: 'Primary Contact',
    menuIndex: 6,
    path: 'contacts/contact/:index',
    url: 'contacts/contact/0',
    component: ContactsContainer,
    icon: ContactsIcon,
    visible: (client: ClientState): boolean => !client.contacts.items[0].contactId,
    selected: (pathname: string, routeMatch: string, route: OnboardMenuRoute): boolean => pathname === `${routeMatch}/${route.url}`,
  },
  {
    key: 'contact1',
    label: 'Additional Contact',
    menuIndex: 7,
    path: 'contacts/contact/:index',
    url: 'contacts/contact/1',
    component: ContactsContainer,
    icon: ContactsIcon,
    visible: (client: ClientState): boolean => {
      return client.clientAccount.accountTypeId === ClientAccountType.Joint.id && client.contacts.items.length >= 2 && !client.contacts.items[1].contactId;
    },
    selected: (pathname: string, routeMatch: string, route: OnboardMenuRoute): boolean => pathname === `${routeMatch}/${route.url}`,
  },
  {
    key: 'contacts',
    label: 'Contacts',
    menuIndex: 8,
    path: 'contacts',
    url: 'contacts',
    component: ContactsContainer,
    icon: ContactsIcon,
    visible: (client: ClientState): boolean =>
      client.clientAccount.accountTypeId === ClientAccountType.Company.id ||
      client.clientAccount.accountTypeId === ClientAccountType.Smsf.id ||
      client.clientAccount.accountTypeId === ClientAccountType.Trust.id,
    selected: (pathname: string, routeMatch: string, route: OnboardMenuRoute): boolean =>
      pathname.startsWith(`${routeMatch}/${route.url}`) && !pathname.endsWith('0'),
  },
  {
    key: 'addresses',
    label: 'Addresses',
    menuIndex: 9,
    path: 'addresses',
    url: 'addresses',
    component: AddressesContainer,
    icon: LocationOn,
    visible: (client: ClientState): boolean =>
      !(
        client.clientAccount.accountTypeId === ClientAccountType.Individual.id &&
        (client.clientAccount.subTypeId === ClientAccountSubType.Super.id || client.clientAccount.subTypeId === ClientAccountSubType.Pension.id)
      ),
    selected: (pathname: string, routeMatch: string, route: OnboardMenuRoute): boolean => pathname === `${routeMatch}/${route.url}`,
  },
  {
    key: 'review',
    label: 'Review',
    menuIndex: 10,
    path: 'review',
    url: 'review',
    component: ReviewContainer,
    icon: Grading,
    visible: (): boolean => true,
    selected: (pathname: string, routeMatch: string, route: OnboardMenuRoute): boolean => pathname === `${routeMatch}/${route.url}`,
  },
];
