import { Box, Container, Grid, Typography } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { PagedRequest } from '../../../../store';
import { Props } from '../container';
import { SuperMemberDataRequest } from '../store/types';
import ImportMenu from './ImportMenu';
import { WorkflowListTable } from './workflowListTable';

export const WorkflowList = ({
  workflowsList,
  workflowsListProgress,
  parameters,
  fetchWorkflowList,
  hasAdminAccess,
  importErrors,
  isImporting,
  importUploadSuccess,
  resetModelUploadSuccessStatus,
  setErrorOnFileImport,
  uploadSuperMemberDataCsv,
}: Props): JSX.Element => {
  const [isMenuOpen, setMenuState] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const fetchData = useCallback(() => {
    fetchWorkflowList({ pagination: parameters.pagination });
  }, [parameters, fetchWorkflowList]);

  useEffect(() => {
    fetchData();
  }, [parameters, fetchData, importUploadSuccess, refresh]);

  const handleGridActions = (pagedRequest: PagedRequest) => {
    fetchWorkflowList({
      pagination: pagedRequest,
    });
  };

  const onImportFailed = (errors: string[]) => {
    setErrorOnFileImport(errors);
  };

  const importSuperMemberData = (memberData: SuperMemberDataRequest[]) => {
    uploadSuperMemberDataCsv(memberData);
  };

  return (
    <Container style={{ padding: '20px' }}>
      <Box justifyContent={'space-between'} alignItems={'center'}>
        <Typography variant="h2">Workflows</Typography>
        <Typography variant="h4">View and manage workflow tasks</Typography>
      </Box>
      <Grid container spacing={2} style={{ padding: '20px 0' }}>
        <Grid item xs={12}>
          <WorkflowListTable
            pagedRequest={parameters.pagination}
            pagedResults={workflowsList || undefined}
            progress={workflowsListProgress}
            handlePagedRequest={handleGridActions}
            refreshGrid={() => setRefresh(!refresh)}
          ></WorkflowListTable>
        </Grid>
      </Grid>
      {hasAdminAccess && (
        <ImportMenu
          importErrors={importErrors}
          isImporting={isImporting}
          isMenuOpen={isMenuOpen}
          onUploadSuccessHandler={() => {
            setMenuState(false);
            resetModelUploadSuccessStatus(false);
          }}
          importUploadSuccess={importUploadSuccess}
          setMenuState={setMenuState}
          importSuperMemberData={importSuperMemberData}
          onImportFailed={onImportFailed}
        />
      )}
    </Container>
  );
};
