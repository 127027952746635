import { Assessment, DonutSmall, Person, SummarizeRounded, SupervisorAccount } from '@mui/icons-material';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import ImportContactsIcon from '@mui/icons-material/ImportContacts';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import NoteIcon from '@mui/icons-material/Note';
import { ClientAccountSubType, ClientAccountType } from '../../../../common/types';
import { MenuRoute } from '../../common/types';
import { AccountsContainer } from './accounts/container';
import { AddressesContainer } from './addresses/container';
import { AdviceFeesContainer } from './adviceFees/container';
import { BenefitStatementContainer } from './benefitStatement/container';
import { ContactsContainer } from './contacts/container';
import { DocumentsContainer } from './documents/container';
import { InvestmentServicesContainer } from './investmentServices/container';
import { MainDetailsContainer } from './mainDetails/container';
import { TrusteeType } from './mainDetails/store';
import { SuperSimplifierContainer } from './superSimplifier/container';
import { TrusteesContainer } from './trustees/container';

export const detailsRoutes: MenuRoute[] = [
  {
    label: 'Client Details',
    menuIndex: 0,
    path: 'primary',
    component: MainDetailsContainer,
    icon: Person,
    visible: (): boolean => true,
  },
  {
    label: 'Addresses',
    menuIndex: 1,
    path: 'addresses',
    component: AddressesContainer,
    icon: LocationOnIcon,
    visible: (): boolean => true,
  },
  {
    label: 'Contacts',
    menuIndex: 2,
    path: 'contacts',
    component: ContactsContainer,
    icon: ImportContactsIcon,
    visible: (): boolean => true,
  },
  {
    label: 'Super Simplifier',
    menuIndex: 3,
    path: 'superSimplifier',
    component: SuperSimplifierContainer,
    icon: Assessment,
    visible: (accountType?: ClientAccountType, accountSubType?: ClientAccountSubType): boolean =>
      accountType === ClientAccountType.Individual && (accountSubType === ClientAccountSubType.Super || accountSubType === ClientAccountSubType.Pension),
  },
  {
    label: 'Advice Fees',
    menuIndex: 4,
    path: 'fees',
    component: AdviceFeesContainer,
    icon: MonetizationOnIcon,
    visible: () => true,
  },
  {
    label: 'Benefit Statement',
    menuIndex: 5,
    path: 'benefitStatement',
    component: BenefitStatementContainer,
    icon: SummarizeRounded,
    visible: (
      accountType?: ClientAccountType,
      accountSubType?: ClientAccountSubType,
      trusteeType?: TrusteeType,
      hasClientEditAdminOnlyPermission?: boolean
    ): boolean =>
      !!hasClientEditAdminOnlyPermission &&
      accountType === ClientAccountType.Individual &&
      (accountSubType === ClientAccountSubType.Super || accountSubType === ClientAccountSubType.Pension),
  },
  {
    label: 'Documents',
    menuIndex: 6,
    path: 'documents',
    component: DocumentsContainer,
    icon: NoteIcon,
    visible: (): boolean => true,
  },
  {
    label: 'Accounts',
    menuIndex: 7,
    path: 'accounts',
    component: AccountsContainer,
    icon: CreditCardIcon,
    visible: (): boolean => true,
  },
  {
    label: 'Trustees',
    menuIndex: 8,
    path: 'trustees',
    component: TrusteesContainer,
    icon: SupervisorAccount,
    visible: (accountType?: ClientAccountType, _accountSubType?: ClientAccountSubType, trusteeType?: TrusteeType): boolean =>
      accountType === ClientAccountType.Company || trusteeType === TrusteeType.Corporate,
  },
  {
    label: 'Investment Services',
    menuIndex: 9,
    path: 'investmentServices',
    component: InvestmentServicesContainer,
    visible: (): boolean => false,
    icon: DonutSmall,
  },
  // {
  //   label: 'User history',
  //   menuIndex: 6,
  //   path: 'userHistory',
  //   component: UserHistoryContainer,
  //   protected: true,
  //   exact: false,
  //   icon: Update,
  // visible: () => true,
  // },
  // {
  //   label: 'Rebalance history',
  //   menuIndex: 7,
  //   path: 'rebalanceHistory',
  //   component: RebalanceHistoryContainer,
  //   protected: true,
  //   exact: false,
  //   icon: Timeline,
  // visible: () => true,
  // },
];
