import { connect, ConnectedProps } from 'react-redux';
import { progressSelectorFactory } from '../../common';
import { RootState } from '../../reducers';
import { Holdings } from './components/holdings';
import { filteredResults, leftTable, rightTable, selectConsolidatedSecurityHoldings, selectParameters, totalValue } from './store/selectors';
import { holdingsSlice } from './store/slice';
import { FetchConsolidatedHoldings, FetchConsolidatedSecurityHoldings, HoldingsActionTypes } from './store/thunks';

const actionTypes = [HoldingsActionTypes.FetchConsolidatedHoldings, HoldingsActionTypes.FetchConsolidatedSecurityHoldings];

const mapStateToProps = (state: RootState) => ({
  parameters: selectParameters(state),
  selectConsolidatedSecurityHoldings: selectConsolidatedSecurityHoldings(state),
  leftTable: leftTable(state),
  rightTable: rightTable(state),
  filteredResults: filteredResults(state),
  totalValue: totalValue(state),
  loadingProgress: progressSelectorFactory(actionTypes)(state),
});

const mapDispatchToProps = {
  ...holdingsSlice.actions,
  FetchConsolidatedSecurityHoldings,
  FetchConsolidatedHoldings,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export type Props = ConnectedProps<typeof connector>;
export const HoldingsContainer = connect(mapStateToProps, mapDispatchToProps)(Holdings);
