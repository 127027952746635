import AdjustIcon from '@mui/icons-material/Adjust';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, IconButton, OutlinedInput, Paper, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import NumberFormat from 'react-number-format';
import { arrayUnion, decimalPlacesByMarketCode, formatDollars, nameof, roundUnitsByMarketCode } from '../../../../../common';
import { ClientSideDataTable } from '../../../../../common/components/dataTable/clientSide';
import { DatatableColumn } from '../../../../../common/components/dataTable/types';
import { LoadingIndicator } from '../../../../../common/components/LoadingIndicator';
import { useDebounce } from '../../../../../common/hooks/useDebounce';
import history from '../../../../../history';
import { Props } from '../container';
import { HoldingAmount, OrderEditResult, TradeAction, TradeMethod } from '../store/types';

export const Results = (props: Props): JSX.Element => {
  const {
    results,
    validateBulkOrder,
    isPreApproved,
    bulkOrder,
    setIsPreApproved,
    securityHoldings,
    setAmounts,
    setClearAmount,
    preApprovalInvestmentServices,
    isLoading,
  } = props;
  const [isValidateRequested, setValidateRequest] = useState<boolean>(false);
  const [amount, setAmount] = useState<HoldingAmount[]>([]);
  const isInitialValidation = useRef(true);
  const onAmountToTradeChange = useDebounce<HoldingAmount[]>(amount, 500);

  function isHoldingsEqual(holdings1: HoldingAmount, holdings2: HoldingAmount) {
    return holdings1.amount === holdings2.amount && holdings1.portfolioId === holdings2.portfolioId;
  }

  const onAmountToTradeChanged = useCallback(() => {
    const holdingsAmount: HoldingAmount[] = results.map((result) => {
      return { amount: result.amount, portfolioId: result.portfolioId };
    });
    const union = arrayUnion(amount, holdingsAmount, isHoldingsEqual);
    if (amount?.filter((holdingAmount) => holdingAmount.amount === undefined).length === 0 && union.length === amount.length + 1) {
      setAmounts(amount);
    }
  }, [amount, results, setAmounts]);

  useEffect(onAmountToTradeChanged, [onAmountToTradeChange]);

  useEffect(() => {
    const holdingsAmount: HoldingAmount[] = results.map((result) => {
      return { amount: result.amount, portfolioId: result.portfolioId };
    });
    setAmount(holdingsAmount);
  }, [results]);

  useEffect(() => {
    if (isInitialValidation.current) {
      isInitialValidation.current = false;
      return;
    }
    if (isPreApproved) {
      history.push('/bulk/order/checkout');
    }
  }, [isPreApproved]);

  useEffect(() => {
    if (isValidateRequested && !isPreApproved && bulkOrder !== null && preApprovalInvestmentServices !== null) {
      setValidateRequest(false);
      validateBulkOrder({ preApprovalInvestmentServices: preApprovalInvestmentServices });
      window.scrollTo(0, 0);
    }
    if (isValidateRequested && isPreApproved) {
      setValidateRequest(false);
      history.push('/bulk/order/checkout');
    }
  }, [isValidateRequested, isPreApproved, bulkOrder, preApprovalInvestmentServices, validateBulkOrder]);

  const clientPortfolioNameColumn = (dataIndex: number, results: OrderEditResult[]): React.ReactNode => (
    <>
      <Typography variant="h5">{results[dataIndex]?.clientName}</Typography>
      <Typography variant="h5" color={'textSecondary'}>
        {results[dataIndex]?.portfolioName}
      </Typography>
    </>
  );

  const tradeActionColumn = (): React.ReactNode => (
    <ToggleButtonGroup size="small" exclusive aria-label="small outlined button group">
      <ToggleButton className={'Green'} color={'primary'} value={TradeAction.Buy.displayName} selected={securityHoldings?.tradeAction === TradeAction.Buy.name}>
        Buy
      </ToggleButton>
      <ToggleButton
        className={'Red'}
        value={TradeAction.Sell.displayName}
        selected={securityHoldings?.tradeAction === TradeAction.Sell.name || securityHoldings?.tradeAction === TradeAction.SellAll.name}
      >
        Sell
      </ToggleButton>
      <ToggleButton className={'Red'} value={TradeAction.SellAll.displayName} selected={securityHoldings?.tradeAction === TradeAction.SellAll.name}>
        All
      </ToggleButton>
    </ToggleButtonGroup>
  );

  const tradeMethodColumn = (): React.ReactNode => (
    <ToggleButtonGroup size="small" exclusive aria-label="small outlined button group">
      <ToggleButton value={TradeMethod.Amount.displayName} selected={securityHoldings?.tradeMethod === TradeMethod.Amount.name}>
        $
      </ToggleButton>
      <ToggleButton value={TradeMethod.Percentage.displayName} selected={securityHoldings?.tradeMethod === TradeMethod.Percentage.name}>
        %
      </ToggleButton>
      <ToggleButton value={TradeMethod.Units.displayName} selected={securityHoldings?.tradeMethod === TradeMethod.Units.name}>
        #
      </ToggleButton>
      <ToggleButton value={TradeMethod.Target.displayName} selected={securityHoldings?.tradeMethod === TradeMethod.Target.name}>
        <AdjustIcon />
      </ToggleButton>
    </ToggleButtonGroup>
  );

  const amountColumn = (dataIndex: number, results: OrderEditResult[]): React.ReactNode => (
    <NumberFormat
      value={amount.find((a) => a.portfolioId === results[dataIndex]?.portfolioId)?.amount}
      customInput={OutlinedInput}
      type="text"
      data-id={'editamount'}
      disabled={securityHoldings?.tradeAction === TradeAction.SellAll.name}
      thousandSeparator
      fixedDecimalScale
      allowNegative={false}
      decimalScale={
        securityHoldings?.tradeMethod === TradeMethod.Amount.name || securityHoldings?.tradeMethod === TradeMethod.Percentage.name
          ? 2
          : decimalPlacesByMarketCode(securityHoldings?.code.substring(securityHoldings?.code.lastIndexOf('.') + 1))
      }
      isAllowed={(values) =>
        !values.floatValue ||
        securityHoldings?.tradeAction === TradeAction.Buy.name ||
        ((securityHoldings?.tradeMethod === TradeMethod.Amount.name || securityHoldings?.tradeMethod === TradeMethod.Target.name) &&
          values.floatValue <= results[dataIndex]?.currentValue) ||
        (securityHoldings?.tradeMethod === TradeMethod.Percentage.name &&
          roundUnitsByMarketCode(
            ((values.floatValue / 100) * results[dataIndex]?.marketValue) / securityHoldings?.unitPrice,
            securityHoldings?.code.substring(securityHoldings?.code.lastIndexOf('.') + 1)
          ) <= results[dataIndex]?.currentUnits) ||
        (securityHoldings?.tradeMethod === TradeMethod.Units.name && values.floatValue <= results[dataIndex]?.currentUnits)
      }
      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
        const value = Number(event.target.value.replaceAll(',', ''));
        if (value !== amount[dataIndex]?.amount) {
          const index = amount.findIndex((a) => a.portfolioId === results[dataIndex]?.portfolioId);
          const updateAmount = [...amount];
          updateAmount[index].amount = value;
          setAmount(updateAmount);
        }
      }}
      onBlur={() => {
        if (!amount.find((a) => a.portfolioId === results[dataIndex]?.portfolioId)?.amount) {
          const index = amount.findIndex((a) => a.portfolioId === results[dataIndex]?.portfolioId);
          const updateAmount = [...amount];
          updateAmount[index].amount = results[dataIndex]?.amount;
          setAmount(updateAmount);
        }
      }}
    />
  );

  const tradeValueColumn = (dataIndex: number, results: OrderEditResult[]): React.ReactNode => (
    <>
      <Typography variant="h5">{formatDollars(results[dataIndex]?.tradeValue)}</Typography>
      <Typography variant="h5" color={'textSecondary'} style={{ fontStyle: 'italic', paddingRight: '2px' }}>
        {roundUnitsByMarketCode(results[dataIndex]?.tradeUnits, securityHoldings?.code.substring(securityHoldings?.code.lastIndexOf('.') + 1) ?? '')}
      </Typography>
    </>
  );

  const securityValueColumn = (dataIndex: number, results: OrderEditResult[]): React.ReactNode => (
    <>
      <Typography variant="h5" color={!results[dataIndex]?.currentValue ? 'textPrimary' : results[dataIndex]?.currentValue < 0 ? 'error' : 'secondary'}>
        {formatDollars(results[dataIndex]?.currentValue)}
      </Typography>
      <Typography variant="h5" color={'textSecondary'} style={{ fontStyle: 'italic', paddingRight: '2px' }}>
        {formatDollars(results[dataIndex]?.proposedValue)}
      </Typography>
    </>
  );

  const cashValueColumn = (dataIndex: number, results: OrderEditResult[]): React.ReactNode => (
    <>
      <Typography variant="h5" color={!results[dataIndex]?.cashBalance ? 'textPrimary' : results[dataIndex]?.cashBalance < 0 ? 'error' : 'secondary'}>
        {formatDollars(results[dataIndex]?.cashBalance)}
      </Typography>
      <Typography variant="h5" color={'textSecondary'} style={{ fontStyle: 'italic', paddingRight: '2px' }}>
        {formatDollars(results[dataIndex]?.proposedCash)}
      </Typography>
    </>
  );

  const portfolioValueColumn = (dataIndex: number, results: OrderEditResult[]): React.ReactNode => (
    <Typography variant="h5">{formatDollars(results[dataIndex]?.marketValue)}</Typography>
  );

  const resetAmountColumn = (dataIndex: number, results: OrderEditResult[]): React.ReactNode => (
    <IconButton
      key="close"
      aria-label="close"
      color="inherit"
      onClick={() => {
        if (results[dataIndex]) {
          setClearAmount(results[dataIndex].portfolioId);
        }
      }}
    >
      <CloseIcon style={{ fontSize: '16px' }} />
    </IconButton>
  );

  const getResultColumns = (results: OrderEditResult[]): DatatableColumn[] => [
    {
      textAlign: 'left',
      name: nameof((i: OrderEditResult) => i.clientName),
      label: 'CLIENT & PORTFOLIO NAME',
      options: {
        filter: false,
        sort: true,
        customBodyRenderLite: (dataIndex: number) => clientPortfolioNameColumn(dataIndex, results),
      },
    },
    {
      textAlign: 'center',
      name: nameof((i: OrderEditResult) => i.amount),
      label: 'BUY/SELL',
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: () => tradeActionColumn(),
      },
    },
    {
      textAlign: 'center',
      name: nameof((i: OrderEditResult) => i.amount),
      label: 'METHOD',
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: () => tradeMethodColumn(),
      },
    },
    {
      textAlign: 'left',
      name: nameof((i: OrderEditResult) => i.amount),
      label: 'AMOUNT',
      options: {
        filter: false,
        sort: true,
        customBodyRenderLite: (dataIndex) => amountColumn(dataIndex, results),
      },
    },
    {
      textAlign: 'right',
      name: nameof((i: OrderEditResult) => i.tradeValue),
      label: 'CALCULATED VALUE & UNITS',
      options: {
        filter: false,
        sort: true,
        customBodyRenderLite: (dataIndex) => tradeValueColumn(dataIndex, results),
      },
    },
    {
      textAlign: 'right',
      name: nameof((i: OrderEditResult) => i.currentValue),
      label: 'SECURITY - CURRENT & PROPOSED',
      options: {
        filter: false,
        sort: true,
        customBodyRenderLite: (dataIndex) => securityValueColumn(dataIndex, results),
      },
    },
    {
      textAlign: 'right',
      name: nameof((i: OrderEditResult) => i.cashBalance),
      label: 'CASH - CURRENT & PROPOSED',
      options: {
        filter: false,
        sort: true,
        customBodyRenderLite: (dataIndex) => cashValueColumn(dataIndex, results),
      },
    },
    {
      textAlign: 'right',
      name: nameof((i: OrderEditResult) => i.marketValue),
      label: 'PORTFOLIO VALUE',
      options: {
        filter: false,
        sort: true,
        customBodyRenderLite: (dataIndex) => portfolioValueColumn(dataIndex, results),
      },
    },
    {
      textAlign: 'right',
      name: '',
      label: '',
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (dataIndex) => resetAmountColumn(dataIndex, results),
      },
    },
  ];

  return (
    <Paper elevation={0}>
      <LoadingIndicator progress={isLoading} spinner={false}>
        <ClientSideDataTable loadingProgress={isLoading} columns={getResultColumns(results)} data={results} options={{ filter: false }}></ClientSideDataTable>
      </LoadingIndicator>
      <Box display="flex" flexDirection="row" justifyContent="flex-end" padding="24px">
        <Button
          disableElevation
          color={'primary'}
          variant="outlined"
          onClick={() => {
            setIsPreApproved();
            history.goBack();
          }}
          style={{ marginRight: '20px' }}
        >
          Back
        </Button>
        <Button disabled={bulkOrder === null} disableElevation color={'primary'} variant="contained" onClick={() => bulkOrder && setValidateRequest(true)}>
          Next
        </Button>
      </Box>
    </Paper>
  );
};
