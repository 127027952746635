import { connect, ConnectedProps } from 'react-redux';
import { progressSelectorFactory } from '../../../../../common';
import { RootState } from '../../../../../reducers';
import { selectHasClientEdit } from '../../../../../store/selectors';
import { selectClientId, selectDocumentTypes } from '../../common/store/selectors';
import { fetchDocumentTypes } from '../../common/store/thunks';
import { Documents } from './components/documents';
import { selectDocuments, selectEditDocument, selectParameters } from './store/selectors';
import { documentsSlice } from './store/slice';
import { deleteDocument, DocumentsActionTypes, downloadDocument, fetchDocumentForEdit, fetchDocuments, saveDocument } from './store/thunks';

const mapStateToProps = (state: RootState) => ({
  hasClientEditPermission: selectHasClientEdit(state),
  clientId: selectClientId(state),
  parameters: selectParameters(state),
  documentTypes: selectDocumentTypes(state),
  documents: selectDocuments(state),
  progress: progressSelectorFactory([DocumentsActionTypes.FetchDocuments])(state),
  saveProgress: progressSelectorFactory([DocumentsActionTypes.SaveDocument])(state),
  selectEditDocument: selectEditDocument(state),
});

const mapDispatchToProps = {
  ...documentsSlice.actions,
  fetchDocuments,
  fetchDocumentForEdit,
  fetchDocumentTypes,
  downloadDocument,
  deleteDocument,
  saveDocument,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export type Props = ConnectedProps<typeof connector>;
export const DocumentsContainer = connector(Documents);
