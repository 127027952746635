import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosRequestConfig } from 'axios';
import FileSaver from 'file-saver';
import { buildEncodedQueryString } from 'src/common/utils';
import api from '../../../../../app/api';
import { MainDetails } from '../../common/store/types';
import { Report, ReportGeneration, SavedParameter, FetchReportParametersRequest, ReportingParameters } from './types';

export enum ClientReportsActionTypes {
  FetchClientReport = '@@client/reports/FetchReport',
  InitiateReportGeneration = '@@client/reports/InitiateReportGeneration',
  FetchClientAccount = '@@client/details/reports/Fetch',
  GetReportById = '@@client/reports/GetReportById',
  GetReportParametersList = '@@client/reports/GetReportParametersList',
  GetReportParametersById = '@@client/reports/GetReportParametersById',
  SaveReportParameters = '@@client/reports/SaveReportParameters',
}

export enum ClientReportsApiEndpoints {
  fetchClientReport = '/reporting/DownloadReport',
  initiateReportGeneration = '/reporting/InitiateReportRequest',
  Fetch = '/customers/GetClientMainDetails',
  getReportById = '/reporting/GetReportById',
  getReportParametersList = '/reporting/GetReportParametersList',
  getReportParametersById = '/reporting/GetReportParametersById',
  saveReportParameters = '/reporting/SaveReportingParameters',
}

export const fetchClientReport = createAsyncThunk(ClientReportsActionTypes.FetchClientReport, async (wrapper: ReportGeneration) => {
  const options: AxiosRequestConfig = {
    responseType: 'blob',
  };
  const queryString = buildEncodedQueryString({
    key: wrapper.key,
    clientId: wrapper.clientId,
  });
  const response = await api.get<BlobPart>(`${ClientReportsApiEndpoints.fetchClientReport}${queryString}`, options);

  const isMergeReport = wrapper.isMergeReport;
  const fileExtension = isMergeReport ? 'pdf' : 'zip';
  const fileName = `${generateFileName(isMergeReport, wrapper)}.${fileExtension}`;
  const data = new Blob([response.data], { type: `application/pdf` });
  FileSaver.saveAs(data, fileName, { autoBom: false });
  return 'success';
});

export const initiateReportGeneration = createAsyncThunk(ClientReportsActionTypes.InitiateReportGeneration, async (wrapper: Report) => {
  const formData = getFormData(wrapper);
  const response = await api.post<string>(`${ClientReportsApiEndpoints.initiateReportGeneration}`, formData);
  return response.data;
});

export const saveReportParameters = createAsyncThunk(ClientReportsActionTypes.SaveReportParameters, async (wrapper: Report) => {
  const formData = getFormData(wrapper);
  const response = await api.post<string>(`${ClientReportsApiEndpoints.saveReportParameters}`, formData);
  return response.data;
});

export const getReportStatus = createAsyncThunk(ClientReportsActionTypes.GetReportById, async (reportGeneration: ReportGeneration) => {
  const queryString = buildEncodedQueryString({
    requestId: reportGeneration.requestId,
    clientId: reportGeneration.clientId,
  });
  const response = await api.get<ReportGeneration>(`${ClientReportsApiEndpoints.getReportById}${queryString}`);
  return response.data;
});

export const fetchReportParametersList = createAsyncThunk(ClientReportsActionTypes.GetReportParametersList, async (afslId: number) => {
  const queryString = buildEncodedQueryString({
    afslId: afslId,
  });
  const response = await api.get<SavedParameter[]>(`${ClientReportsApiEndpoints.getReportParametersList}${queryString}`);
  return response.data;
});

export const getReportParametersById = createAsyncThunk(ClientReportsActionTypes.GetReportParametersById, async (request: FetchReportParametersRequest) => {
  const queryString = buildEncodedQueryString({
    requestId: request.requestId,
    afslId: request.afslId,
  });
  const response = await api.get<ReportingParameters>(`${ClientReportsApiEndpoints.getReportParametersById}${queryString}`);
  return response.data;
});

const getFormData = (payload: Report): FormData => {
  const formData = new FormData();
  formData.append('clientName', payload.clientName);
  formData.append('clientId', payload.clientId.toString());
  formData.append('fromDate', payload.fromDate);
  formData.append('toDate', payload.toDate);
  formData.append('dateRangeType', payload.dateRangeType);
  formData.append('includeLogo', payload.includeLogo.toString());
  formData.append('visibleToAfsl', payload.visibleToAfsl.toString());
  formData.append('parameterName', payload.parameterName.toString());
  formData.append('includeCoverLetter', payload.includeCoverLetter.toString());
  formData.append('includeAppendix', payload.includeAppendix.toString());
  if (payload.coverLetter !== undefined) formData.append('coverLetter', payload.coverLetter);
  if (payload.appendix !== undefined) formData.append('appendix', payload.appendix);
  if (payload.mergeReportOrder.length > 0) {
    payload.mergeReportOrder.forEach((mergeItem, index) => {
      formData.append(`mergeReportOrder[${index}]`, mergeItem.toString());
    });
  }
  if (payload.cashTransactions !== undefined) {
    formData.append('cashTransactions[fromDate]', payload.cashTransactions?.fromDate);
    formData.append('cashTransactions[toDate]', payload.cashTransactions?.toDate);
    formData.append('cashTransactions[dateRangeType]', payload.cashTransactions?.dateRangeType);
  }
  if (payload.expenses !== undefined) {
    formData.append('expenses[hidingOptions]', payload.expenses?.hidingOptions);
    formData.append('expenses[fromDate]', payload.expenses?.fromDate);
    formData.append('expenses[toDate]', payload.expenses?.toDate);
    formData.append('expenses[dateRangeType]', payload.expenses?.dateRangeType);
  }
  if (payload.transactions !== undefined) {
    formData.append('transactions[hidingOptions]', payload.transactions?.hidingOptions);
    formData.append('transactions[filteringOptions]', payload.transactions?.filteringOptions);
    formData.append('transactions[sortBy]', payload.transactions?.sortBy);
    formData.append('transactions[fromDate]', payload.transactions?.fromDate);
    formData.append('transactions[toDate]', payload.transactions?.toDate);
    formData.append('transactions[dateRangeType]', payload.transactions?.dateRangeType);
  }
  if (payload.incomeReceived !== undefined) {
    formData.append('incomeReceived[filteringOptions]', payload.incomeReceived?.filteringOptions);
    formData.append('incomeReceived[sortBy]', payload.incomeReceived?.sortBy);
    formData.append('incomeReceived[thenSortBy]', payload.incomeReceived?.thenSortBy);
    formData.append('incomeReceived[fromDate]', payload.incomeReceived?.fromDate);
    formData.append('incomeReceived[toDate]', payload.incomeReceived?.toDate);
    formData.append('incomeReceived[dateRangeType]', payload.incomeReceived?.dateRangeType);
  }
  if (payload.portfolioValuation !== undefined) {
    formData.append('portfolioValuation[hidingOptions]', payload.portfolioValuation?.hidingOptions);
    formData.append('portfolioValuation[sortBy]', payload.portfolioValuation?.sortBy);
    formData.append('portfolioValuation[groupBy]', payload.portfolioValuation?.groupBy);
    formData.append('portfolioValuation[asAtDate]', payload.portfolioValuation?.asAtDate);
    formData.append('portfolioValuation[asAtDateType]', payload.portfolioValuation?.asAtDateType);
  }
  if (payload.assetAllocation !== undefined) {
    formData.append('assetAllocation[asAtDate]', payload.assetAllocation?.asAtDate);
    formData.append('assetAllocation[asAtDateType]', payload.assetAllocation?.asAtDateType);

    payload.assetAllocation.investmentProgramIds.forEach((item, index) => {
      formData.append(`assetAllocation[investmentProgramIds][${index}]`, item.toString());
    });
  }
  if (payload.gainsLossesUnrealised !== undefined) {
    formData.append('gainsLossesUnrealised[asAtDate]', payload.gainsLossesUnrealised?.asAtDate);
    formData.append('gainsLossesUnrealised[asAtDateType]', payload.gainsLossesUnrealised?.asAtDateType);
  }
  if (payload.gainsLossesRealised !== undefined) {
    formData.append('gainsLossesRealised[fromDate]', payload.gainsLossesRealised?.fromDate);
    formData.append('gainsLossesRealised[toDate]', payload.gainsLossesRealised?.toDate);
    formData.append('gainsLossesRealised[dateRangeType]', payload.gainsLossesRealised?.dateRangeType);
  }
  if (payload.securityPerformance !== undefined) {
    formData.append('securityPerformance[fromDate]', payload.securityPerformance?.fromDate);
    formData.append('securityPerformance[toDate]', payload.securityPerformance?.toDate);
    formData.append('securityPerformance[dateRangeType]', payload.securityPerformance?.dateRangeType);
  }
  return formData;
};

export const fetchClientAccount = createAsyncThunk(ClientReportsActionTypes.FetchClientAccount, async (clientId: number) => {
  const queryString = buildEncodedQueryString({
    clientId,
  });
  const response = await api.get<MainDetails>(`${ClientReportsApiEndpoints.Fetch}${queryString}`);
  return response.data;
});

const generateFileName = (isMergeReport: boolean, wrapper: ReportGeneration) => {
  if (!isMergeReport) return `${wrapper.clientName}_${wrapper.reportName}`;
  return wrapper.mergeReportOrder.length === 2 ? `${wrapper.clientName}_${wrapper.reportName}_${wrapper.dateRange}` : `${wrapper.clientName}_Merged_Reports`;
};
