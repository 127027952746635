import { TabRoute } from '../../../store';
import { ClientDashboardContainer } from './dashboard/container';
import { DetailsContainer } from './details/container';
import { GainsLossesContainer } from './gainslosses/container';
import { PerformanceContainer } from './performance/container';
import { PortfolioContainer } from './portfolio/container';
import { ReportsContainer } from './reports/container';
import { TransactionsStateContainer } from './transactions/container';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const getClientTabRoutes = (hasClientEditAdminOnlyPermission: boolean): TabRoute[] => [
  {
    label: 'DASHBOARD',
    order: 0,
    path: '/dashboard',
    component: ClientDashboardContainer,
    visible: (): boolean => true,
  },
  {
    label: 'PORTFOLIO',
    order: 1,
    path: '/portfolio',
    component: PortfolioContainer,
    visible: (): boolean => true,
  },
  {
    label: 'PERFORMANCE',
    order: 2,
    path: '/performance',
    component: PerformanceContainer,
    visible: (): boolean => true,
  },
  {
    label: 'GAINS & LOSSES',
    order: 3,
    path: '/gainslosses',
    component: GainsLossesContainer,
    visible: (): boolean => true,
  },
  {
    label: 'TRANSACTIONS',
    order: 4,
    path: '/transactions',
    component: TransactionsStateContainer,
    visible: (): boolean => true,
  },
  {
    label: 'DETAILS',
    order: 5,
    path: '/details',
    component: DetailsContainer,
    visible: (): boolean => true,
  },
  {
    label: 'REPORTS',
    order: 6,
    path: '/reports',
    component: ReportsContainer,
    visible: (): boolean => !!hasClientEditAdminOnlyPermission,
  },
];
