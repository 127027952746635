import { Paper, Typography } from '@mui/material';
import { default as React, useEffect } from 'react';
import { Props } from '../container';
import { SaveConfigurationsItem, SaveStrategicAllocationItem } from '../store';
import { ConfigurationTable } from './configurationTable';
import { StrategicAllocationTable } from './strategicAllocationTable';

export const Targets = ({
  configurations,
  clientId,
  currentInvestmentVersionId,
  investmentService,
  loadingConfigurationsProgress,
  fetchConfigurations,
  saveConfigurations,
  savingConfigurationsProgress,
  fetchStrategicAllocations,
  approvedProducts,
  cashAccountProduct,
  strategicAllocations,
  loadingStrategicAllocationsProgress,
  saveStrategicAllocations,
  savingStrategicAllocationsProgress,
}: Props): JSX.Element => {
  useEffect(() => {
    if (!!currentInvestmentVersionId && !!clientId) {
      fetchConfigurations({ investmentServiceVersionId: currentInvestmentVersionId, clientId });
      fetchStrategicAllocations({ investmentServiceVersionId: currentInvestmentVersionId, clientId });
    }
  }, [fetchConfigurations, fetchStrategicAllocations, currentInvestmentVersionId, investmentService, clientId]);

  return (
    <>
      <Typography variant="h3" style={{ paddingBottom: '20px' }}>
        Portfolio Targets
      </Typography>
      <Typography variant="h5" style={{ paddingBottom: '30px' }}>
        View and update portfolio composition and strategic asset allocation targets.
      </Typography>

      <Paper elevation={0} style={{ paddingBottom: '10px' }}>
        <ConfigurationTable
          items={configurations}
          onSave={async (configurationsToSave: SaveConfigurationsItem[]) => {
            if (!!clientId && !!currentInvestmentVersionId) {
              await saveConfigurations({
                investmentServiceVersionId: currentInvestmentVersionId,
                clientId,
                configurations: configurationsToSave,
              }).then((payload) => {
                if ((payload.payload as { message: string; variant?: string }).variant === 'error') {
                  throw payload.payload;
                }
                return payload;
              });
            }
          }}
          itemLoadingProgress={loadingConfigurationsProgress}
          approvedProducts={approvedProducts}
          cashAccountProduct={cashAccountProduct}
          savingProgress={savingConfigurationsProgress}
        />
      </Paper>

      <Paper elevation={0} style={{ paddingBottom: '10px' }}>
        <StrategicAllocationTable
          items={strategicAllocations}
          onSave={async (strategicAllocationItems: SaveStrategicAllocationItem[]) => {
            if (!!clientId && !!currentInvestmentVersionId) {
              await saveStrategicAllocations({
                investmentServiceVersionId: currentInvestmentVersionId,
                clientId,
                strategicAssetAllocations: strategicAllocationItems,
              });
            }
          }}
          itemLoadingProgress={loadingStrategicAllocationsProgress}
          savingProgress={savingStrategicAllocationsProgress}
        />
      </Paper>
    </>
  );
};
