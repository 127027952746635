import { createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../../../app/api';
import { buildEncodedQueryString } from '../../../../common';
import { fetchPerformanceDetailsPayload, ModelDetail, PerformanceChartFrequency, PerformanceDetails } from './types';

export enum ModelDashboardActionTypes {
  FetchModelDetail = '@@model/dashboard/FetchModelDetail',
  FetchPerformanceDetails = '@@model/dashboard/FetchPerformanceDetails',
}

export enum ModelDashboardApiEndpoints {
  fetchModelDetail = '/bff/GetModelDashboard',
  fetchPerformanceDetails = '/performances/model/GetModelPerformanceChartWithBenchmark',
}

export const fetchModelDetail = createAsyncThunk(ModelDashboardActionTypes.FetchModelDetail, async (modelVersionId: number) => {
  const response = await api.get<ModelDetail>(`${ModelDashboardApiEndpoints.fetchModelDetail}?modelVersionId=${modelVersionId}`);
  return response.data;
});

export const fetchPerformanceDetails = createAsyncThunk(ModelDashboardActionTypes.FetchPerformanceDetails, async (wrapper: fetchPerformanceDetailsPayload) => {
  const queryString = buildEncodedQueryString({
    modelVersionId: wrapper.modelVersionId,
    startDate: wrapper.startDate,
    endDate: wrapper.endDate,
    frequency: PerformanceChartFrequency.Monthly,
  });

  const response = await api.get<PerformanceDetails>(`${ModelDashboardApiEndpoints.fetchPerformanceDetails}${queryString}`);
  return response.data;
});
