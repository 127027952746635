import { moment } from 'src/common/types';
import { Enumeration } from '../../../../../common/types';
import { SecurityItem } from '../../../common/store/types';
import { ValidationResult } from '../../types';
export { TradeAction } from '../../types';
export type { BulkOrder, Order, PreApprovalInvestmentService, ValidationResult } from '../../types';

export interface OrderEditState {
  parameters: OrderEditParameters;
  securities: SecurityItem[];
  securityHoldings: PortfolioSecurityHoldingResult | null;
  isPreApproved: boolean;
  validationMessages: ValidationResult;
  investmentServicesLockedByOrders: number[];
}

export interface HoldingAmount {
  amount: number | undefined;
  portfolioId: number;
}

export interface OrderAmount {
  portfolioId: number;
  amount: number;
}

export interface OrderEditParameters {
  securitySearch: string | null;
  security: SecurityItem | null;
  tradeAction: string;
  tradeMethod: string;
  amount: number | null;
}

export interface OrderEditResult {
  clientId: number;
  clientName: string;
  portfolioId: number;
  portfolioName: string;
  marketValue: number;
  amount: number;
  tradeValue: number;
  tradeUnits: number;
  currentValue: number;
  currentUnits: number;
  proposedValue: number;
  cashBalance: number;
  proposedCash: number;
  targetPercentage: number;
}

export interface FetchPortfolioSecurityHoldingsParameters {
  securityId: number;
  portfolioIds: number[];
}

export interface PortfolioSecurityHoldingResult {
  securityId: number;
  code: string;
  name: string;
  tradeMethod: string;
  tradeAction: string;
  unitPrice: number;
  priceDate: moment;
  holdings: PortfolioSecurityHoldingItem[];
}

export interface PortfolioSecurityHoldingItem {
  portfolioId: number;
  units: number;
  newUnits: number;
  amount: number;
  targetPercentage: number;
  value: number;
  portfolioValue: number;
}

export class TradeMethod extends Enumeration {
  static Amount = new TradeMethod(1, 'Amount', 'Amount');
  static Units = new TradeMethod(2, 'Units', 'Units');
  static Percentage = new TradeMethod(3, 'Percentage', 'Percentage');
  static Target = new TradeMethod(4, 'Target', 'Target');
}
