import { Add } from '@mui/icons-material';
import { Button, Grid, Tooltip, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import DateRangePicker from 'src/common/components/DateRangePicker';
import { NumericValueSelect } from 'src/features/bulk/common/components/NumericValueSelect';
import { AsyncAutocomplete, Autocomplete, EnumerationMultiSelect, ToggleButtonItem, WO2ToggleButtonGroup } from '../../../../../common';
import { AfslPracticeAdviserSelect } from '../../../common/components/AfslPracticeAdviserSelect';
import { ClientAccountTypeForBulk, ModelItem, SecurityItem, ServiceType } from '../../../common/store/types';
import { Props } from '../filtersContainer';
import { ClientAccountStatus, SecurityHeld, SecurityModelAssociation, SecurityModelAssociationType } from '../store/types';
import { Associations } from './Associations';

const serviceTypeToggleButtons: ToggleButtonItem<string>[] = [
  { name: 'All', value: 'All' },
  { name: ServiceType.Mda.displayName, value: ServiceType.Mda.name },
  { name: ServiceType.Roa.displayName, value: ServiceType.Roa.name },
];

const securityHeldToggleButtons: ToggleButtonItem<string>[] = [
  { name: SecurityHeld.Hold.displayName, value: SecurityHeld.Hold.name },
  { name: SecurityHeld.NotHeld.displayName, value: SecurityHeld.NotHeld.name },
];
export const Filters = (props: Props): JSX.Element => {
  const {
    parameters,
    afsls,
    models,
    securities,
    setAfslParameter,
    setPracticeParameter,
    setAdviserParameter,
    setClientTypesParameter,
    setStatusesParameter,
    setServiceTypeParameter,
    setSecurityHeld,
    setSecurity,
    setSecuritySearch,
    setModel,
    setHoldingsValueOperator,
    setHoldingsFromValue,
    setHoldingsToValue,
    setAvailableCashBalanceOperator,
    setAvailableCashBalanceFromValue,
    setAvailableCashBalanceToValue,
    setServicesAgreementDate,
    setInceptionDate,
    setHoldingsDate,
    fetchResults,
    fetchSecurities,
    fetchModels,
    addAssociation,
    removeAssociation,
  } = props;

  useEffect(() => {
    const afsls = parameters.afsls.map((x) => x.afslId);
    fetchModels(afsls);
  }, [parameters.afsls]);

  const handleSearch = () => {
    fetchResults(parameters);
  };
  const handleAddSecurity = () => {
    if (!!!parameters.security) return;
    const association: SecurityModelAssociation = {
      associationType: SecurityModelAssociationType.Security,
      id: parameters.security.id,
      name: parameters.security.code,
      isHeld: parameters.securityHeld === SecurityHeld.Hold.name,
    };
    addAssociation(association);
  };
  const handleAddModel = () => {
    if (!!!parameters.model) return;
    const association: SecurityModelAssociation = {
      associationType: SecurityModelAssociationType.Model,
      id: parameters.model.modelId,
      name: parameters.model.code,
      isHeld: parameters.securityHeld === SecurityHeld.Hold.name,
    };
    addAssociation(association);
  };
  return (
    <Grid container direction="column">
      <AfslPracticeAdviserSelect
        data={afsls}
        selectedAfsls={parameters.afsls}
        selectedPractices={parameters.practices}
        selectedAdvisers={parameters.advisers}
        setSelectedAfsls={setAfslParameter}
        setSelectedPractices={setPracticeParameter}
        setSelectedAdvisers={setAdviserParameter}
      />

      <EnumerationMultiSelect label="Account Type" type={ClientAccountTypeForBulk} value={parameters.clientTypes} onChange={setClientTypesParameter} />
      <EnumerationMultiSelect label="Status" type={ClientAccountStatus} value={parameters.statuses} onChange={setStatusesParameter} />
      <Grid container direction="column" alignItems="flex-start" style={{ paddingBottom: '10px', paddingTop: '10px' }}>
        <Typography variant={'h4'} style={{ paddingBottom: '10px ' }}>
          Service Type
        </Typography>
        <Grid
          style={{
            width: '100%',
          }}
        >
          <WO2ToggleButtonGroup fullWidth={true} buttons={serviceTypeToggleButtons} value={parameters.serviceType} onChangeHandler={setServiceTypeParameter} />
        </Grid>
      </Grid>
      <Grid style={{ textAlign: 'justify', padding: '10px 0' }}>
        <Typography variant={'h5'}>Holdings Value</Typography>
        <Grid container direction="row">
          <NumericValueSelect
            selectedOperator={parameters.holdingsValue?.parameterType}
            fromValue={parameters.holdingsValue?.fromValue}
            toValue={parameters.holdingsValue?.toValue}
            setSelectedOperator={setHoldingsValueOperator}
            setFromValue={setHoldingsFromValue}
            setToValue={setHoldingsToValue}
          />
        </Grid>
      </Grid>
      <Grid style={{ textAlign: 'justify', padding: '5px 0' }}>
        <Typography variant={'h5'}>Available cash balance</Typography>
        <Grid container direction="row">
          <NumericValueSelect
            selectedOperator={parameters.availableCashBalance?.parameterType}
            fromValue={parameters.availableCashBalance?.fromValue}
            toValue={parameters.availableCashBalance?.toValue}
            setSelectedOperator={setAvailableCashBalanceOperator}
            setFromValue={setAvailableCashBalanceFromValue}
            setToValue={setAvailableCashBalanceToValue}
          />
        </Grid>
      </Grid>
      <Grid style={{ textAlign: 'justify', padding: '5px 0' }}>
        <Typography variant={'h5'}>Services Agreement Date</Typography>
        <Grid container direction="row" style={{ textAlign: 'justify', padding: '5px 0' }}>
          <DateRangePicker dateRange={parameters.servicesAgreementDate} setDateRange={setServicesAgreementDate} />
        </Grid>
      </Grid>
      <Grid style={{ textAlign: 'justify', padding: '5px 0' }}>
        <Typography variant={'h5'}>Inception Date</Typography>
        <Grid container direction="row" style={{ textAlign: 'justify', padding: '5px 0' }}>
          <DateRangePicker dateRange={parameters.inceptionDate} setDateRange={setInceptionDate} />
        </Grid>
      </Grid>
      <Grid style={{ textAlign: 'justify', padding: '5px 0' }}>
        <Typography variant={'h5'}>Holds Security/Model/Product as of date</Typography>
        <Grid container direction="row" style={{ textAlign: 'justify', padding: '5px 0' }}>
          <DateRangePicker dateRange={parameters.holdingsDate} setDateRange={setHoldingsDate} isDateOnly={true} />
        </Grid>
      </Grid>
      <Grid container direction="column" alignItems="flex-start" style={{ paddingBottom: '10px', paddingTop: '10px' }}>
        <Typography variant={'h4'} style={{ paddingBottom: '10px ' }}>
          Security
        </Typography>
        <Grid
          style={{
            width: '100%',
          }}
        >
          <WO2ToggleButtonGroup fullWidth={true} buttons={securityHeldToggleButtons} value={parameters.securityHeld} onChangeHandler={setSecurityHeld} />
        </Grid>
      </Grid>
      <Grid container direction="row">
        <Grid item={true} xs={11}>
          <AsyncAutocomplete<SecurityItem>
            value={parameters.security}
            optionLabel={(i) => i.code + ' - ' + i.name}
            options={securities}
            onOptionChange={setSecurity}
            onSearchChange={setSecuritySearch}
            getNewOptions={fetchSecurities}
          />
        </Grid>
        {!!parameters.security && (
          <Grid item={true} xs={1} style={{ paddingTop: '10px' }}>
            <Tooltip title="Add">
              <Add style={{ cursor: 'pointer' }} onClick={handleAddSecurity}></Add>
            </Tooltip>
          </Grid>
        )}
      </Grid>
      <Grid style={{ textAlign: 'justify', padding: '10px 0' }}>
        <Typography variant={'h5'} style={{ padding: '10px 0' }}>
          Within Model
        </Typography>
        <Grid container direction="row">
          <Grid item={true} xs={11}>
            <Autocomplete<ModelItem> value={parameters.model} optionLabel={(i) => i.name} options={models} loading={false} onOptionChange={setModel} />
          </Grid>
          {!!parameters.model && (
            <Grid item={true} xs={1} style={{ paddingTop: '10px' }}>
              <Tooltip title="Add">
                <Add style={{ cursor: 'pointer' }} onClick={handleAddModel}></Add>
              </Tooltip>
            </Grid>
          )}
        </Grid>
      </Grid>
      <Associations associations={parameters.associations} removeAssociation={removeAssociation} />
      <Grid style={{ textAlign: 'end', marginTop: '20px' }}>
        <Button id="search_button" disableElevation color={'primary'} onClick={() => handleSearch()} variant="contained">
          Search
        </Button>
      </Grid>
    </Grid>
  );
};
