import { Paper, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import React from 'react';
import { formatPercentage, uuidv4 } from '../../../../../common';
import { LoadingIndicator } from '../../../../../common/components/LoadingIndicator';
import { LoadingProgress } from '../../../../../common/store/types';
import { PerformanceParameters, PeriodicSummaryTableResult } from '../store/types';

export interface PeriodicSummaryTableProps {
  parameters: PerformanceParameters;
  periodicSummaryTableProgress: LoadingProgress;
  periodicSummaryTableResults?: PeriodicSummaryTableResult;
}

export const PeriodicSummaryTable = (props: PeriodicSummaryTableProps): JSX.Element => {
  const { parameters, periodicSummaryTableResults, periodicSummaryTableProgress } = props;
  const benchmark = parameters?.selectedBenchmark;

  const results = periodicSummaryTableResults?.seriesData;
  const rows = results ?? [];

  return (
    <LoadingIndicator progress={periodicSummaryTableProgress}>
      <Paper elevation={0} style={{ marginBottom: '40px' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell variant={'body'} style={{ padding: '0' }} />
              <TableCell variant={'body'} colSpan={3}>
                <Typography variant="h3" color={'primary'}>
                  {parameters?.selectedPortfolio?.investmentServiceName}
                </Typography>
              </TableCell>
              <TableCell variant={'body'} colSpan={3}>
                <Typography variant="h3" color={'primary'}>
                  {benchmark ? `${benchmark?.code} - ${benchmark?.name}` : 'No benchmark selected'}
                </Typography>
              </TableCell>
              <TableCell variant={'body'} colSpan={3}>
                <Typography variant="h3" color={'primary'}>
                  Portfolio Vs Benchmark
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant={'head'} style={{ padding: '0' }} />
              <TableCell variant={'head'}>
                <Typography variant="h6" color="textSecondary">
                  CAPITAL
                </Typography>
              </TableCell>
              <TableCell variant={'head'}>
                <Typography variant="h6" color="textSecondary">
                  INCOME
                </Typography>
              </TableCell>
              <TableCell variant={'head'}>
                <Typography variant="h6" color="textSecondary">
                  TOTAL
                </Typography>
              </TableCell>
              <TableCell variant={'head'}>
                <Typography variant="h6" color="textSecondary">
                  CAPITAL
                </Typography>
              </TableCell>
              <TableCell variant={'head'}>
                <Typography variant="h6" color="textSecondary">
                  INCOME
                </Typography>
              </TableCell>
              <TableCell variant={'head'}>
                <Typography variant="h6" color="textSecondary">
                  TOTAL
                </Typography>
              </TableCell>
              <TableCell variant={'head'}>
                <Typography variant="h6" color="textSecondary">
                  CAPITAL
                </Typography>
              </TableCell>
              <TableCell variant={'head'}>
                <Typography variant="h6" color="textSecondary">
                  INCOME
                </Typography>
              </TableCell>
              <TableCell variant={'head'}>
                <Typography variant="h6" color="textSecondary">
                  TOTAL
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow key={uuidv4()}>
                <TableCell>
                  <Typography variant="h5">{row.label}</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="h5" color={!row.capitalReturn ? 'textPrimary' : row.capitalReturn < 0 ? 'error' : 'secondary'}>
                    {formatPercentage(row.capitalReturn, '-')}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="h5" color={!row.incomeReturn ? 'textPrimary' : row.incomeReturn < 0 ? 'error' : 'secondary'}>
                    {formatPercentage(row.incomeReturn, '-')}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="h5" color={!row.totalReturn ? 'textPrimary' : row.totalReturn < 0 ? 'error' : 'secondary'}>
                    {formatPercentage(row.totalReturn, '-')}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="h5" color={!row.benchmarkCapitalReturn ? 'textPrimary' : row.benchmarkCapitalReturn < 0 ? 'error' : 'secondary'}>
                    {formatPercentage(row.benchmarkCapitalReturn, '-')}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="h5" color={!row.benchmarkIncomeReturn ? 'textPrimary' : row.benchmarkIncomeReturn < 0 ? 'error' : 'secondary'}>
                    {formatPercentage(row.benchmarkIncomeReturn, '-')}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="h5" color={!row.benchmarkTotalReturn ? 'textPrimary' : row.benchmarkTotalReturn < 0 ? 'error' : 'secondary'}>
                    {formatPercentage(row.benchmarkTotalReturn, '-')}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    variant="h5"
                    color={!row.portfolioVsBenchmarkCapitalReturn ? 'textPrimary' : row.portfolioVsBenchmarkCapitalReturn < 0 ? 'error' : 'secondary'}
                  >
                    {formatPercentage(row.portfolioVsBenchmarkCapitalReturn, '-')}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    variant="h5"
                    color={!row.portfolioVsBenchmarkIncomeReturn ? 'textPrimary' : row.portfolioVsBenchmarkIncomeReturn < 0 ? 'error' : 'secondary'}
                  >
                    {formatPercentage(row.portfolioVsBenchmarkIncomeReturn, '-')}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    variant="h5"
                    color={!row.portfolioVsBenchmarkTotalReturn ? 'textPrimary' : row.portfolioVsBenchmarkTotalReturn < 0 ? 'error' : 'secondary'}
                  >
                    {formatPercentage(row.portfolioVsBenchmarkTotalReturn, '-')}
                  </Typography>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
    </LoadingIndicator>
  );
};
