import * as Sentry from '@sentry/react';
import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { userManager } from '../userManager';

const API_BASEURL = process.env.REACT_APP_API_BASEURL;

export interface ApiError {
  name: string | undefined;
  message: string | undefined;
  status: number | undefined;
  statusText: string | undefined;
  stack?: string | undefined;
}

const config: AxiosRequestConfig = {
  baseURL: API_BASEURL,
  timeout: 30000,
};

const instance = () => {
  const result = axios.create(config);
  result.interceptors.response.use((response) => response, errorHandler);
  result.interceptors.request.use(
    function (config) {
      return Promise.resolve<AxiosRequestConfig>(
        userManager.getUser().then((user) => {
          if (user && !user.expired && config.headers) {
            config.headers['Authorization'] = 'Bearer ' + user.access_token;
          } else {
            if (config.headers) {
              config.headers['Authorization'] = '';
            }
          }

          const correlationId = uuidv4();
          if (config.headers) {
            config.headers['X-Correlation-Id'] = correlationId;
          }

          Sentry.configureScope((scope) => {
            scope.setTag('correlation_id', correlationId);
          });

          return config;
        })
      );
    },
    function (error) {
      return Promise.reject(error);
    }
  );
  return result;
};

const errorHandler = (axiosError: AxiosError) => {
  const wrappedErrors = !!axiosError.response?.data?.error && axiosError.response?.data?.error instanceof Array ? axiosError.response?.data?.error.join() : '';

  const error: ApiError = {
    name: !!wrappedErrors ? 'wrapped-error' : axiosError.name,
    message: wrappedErrors || axiosError.message,
    status: axiosError.response?.status,
    statusText: axiosError.response?.statusText,
    stack: axiosError.stack,
  };

  // no status means network error
  if (!error.status) {
    error.status = 599;
    error.statusText = 'Network Error';
  }

  return Promise.reject(error);
};

export default instance();
