import { Cancel as CancelIcon, Edit as EditIcon, Save as SaveIcon } from '@mui/icons-material';
import { Box, IconButton, LinearProgress, Paper, Stack, styled, Tooltip, Typography } from '@mui/material';
import { GridToolbarContainer } from '@mui/x-data-grid';
import {
  DataGridPro,
  GridColumns,
  gridEditRowsStateSelector,
  GridRenderCellParams,
  GridRowModes,
  GridRowParams,
  MuiBaseEvent,
  MuiEvent,
  useGridApiRef,
} from '@mui/x-data-grid-pro';
import React, { useCallback, useEffect, useState } from 'react';
import PulseLoader from 'react-spinners/PulseLoader';
import { LoadingProgress } from 'src/common/store/types';
import { theme } from '../../../../../../../themes';
import { Account } from '../store';

const StyledBox = styled(Box)(() => ({
  width: '100%',
  '& .MuiDataGrid-toolbarContainer': {
    justifyContent: 'flex-end',
  },
}));

export interface AccountsTableProps {
  items: Account[];
  itemLoadingProgress: LoadingProgress;
  onSave?: (accountIds: number[]) => Promise<void>;
  savingProgress: LoadingProgress;
}

interface DataRow extends Partial<Account> {
  id: string;
}

export const AccountsTable = (props: AccountsTableProps): JSX.Element => {
  const { items, itemLoadingProgress, onSave, savingProgress } = props;
  const [dataRows, setDataRows] = useState<DataRow[]>([]);
  const [editMode, setEditMode] = useState<boolean>(false);

  const apiRef = useGridApiRef();

  useEffect(() => {
    resetDataRows();
  }, [items]);

  const resetDataRows = useCallback(() => {
    setDataRows(
      items.map((i, index) => ({
        id: index.toString(),
        ...i,
      }))
    );
  }, [items]);

  const institutionLogoFileNames = [6, 10, 13, 61, 81];

  const columns: GridColumns = [
    {
      field: 'accountId',
      type: 'number',
      editable: true,
    },
    {
      field: 'accountName',
      headerName: 'ACCOUNT DETAILS',
      type: 'string',
      flex: 2,
      editable: false,
      sortable: false,
      renderCell: (params: GridRenderCellParams<Account>) => {
        const institutionLogoFileName: string | number | null = institutionLogoFileNames.includes(params.row.institutionId ?? 0)
          ? params.row.institutionId
          : 'unknown';

        return (
          <Stack direction="row" alignItems="center" gap={2}>
            <Tooltip title={params.row.institution ?? ''} arrow>
              <img
                src={`/images/institution/${institutionLogoFileName}.png`}
                alt="Institution"
                style={{ width: '40px', margin: '0 auto', marginLeft: '20px', marginRight: '20px' }}
              />
            </Tooltip>
            <div>
              <Typography
                variant="h5"
                style={{
                  letterSpacing: '1px',
                }}
              >
                {params.value}
              </Typography>
              <Typography variant={'h5'}>{!!params.row.accountBsb && `BSB: ${params.row.accountBsb}`}</Typography>
              <Typography variant={'h5'} style={{ paddingLeft: !!params.row.accountBsb ? '20px' : '0px' }}>
                {!!params.row.accountNumber && `Account number: ${params.row.accountNumber}`}
              </Typography>
            </div>
          </Stack>
        );
      },
    },
    {
      field: 'isLinkedToInvestmentProgramVersion',
      headerName: 'LINKED',
      type: 'boolean',
      editable: true,
      sortable: false,
      width: 80,
    },
  ];

  const handleEditClick = React.useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.stopPropagation();
      setEditMode(true);
      apiRef.current.getAllRowIds().forEach((id) => {
        apiRef.current.setRowMode(id, GridRowModes.Edit);
      });
    },
    [apiRef]
  );

  const handleCancelClick = React.useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.stopPropagation();
      setEditMode(false);

      apiRef.current.getAllRowIds().forEach((id) => {
        const rowMode = apiRef.current.getRowMode(id);
        if (rowMode === 'edit') {
          apiRef.current.setRowMode(id, GridRowModes.View);
        }
        const row = apiRef.current.getRow(id);
        if (!!row && row.isNew) {
          apiRef.current.updateRows([{ id, _action: 'delete' }]);
        }
      });

      resetDataRows();
    },
    [apiRef, items]
  );

  const handleSave = useCallback(
    async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.stopPropagation();
      if (!!onSave) {
        const editModels = gridEditRowsStateSelector(apiRef.current.state);

        await onSave(
          Object.entries(editModels)
            .filter((m) => m[1].isLinkedToInvestmentProgramVersion.value)
            .map((m) => m[1]?.accountId.value as number)
        );
        handleCancelClick(e);
      }
    },
    [onSave, apiRef.current]
  );

  return (
    <>
      <Typography variant="h4" style={{ paddingBottom: '10px', paddingTop: '10px' }}>
        Portfolio Accounts
      </Typography>
      <div style={{ height: '600px' }}>
        <Paper elevation={3}>
          <StyledBox>
            <DataGridPro
              editMode="row"
              apiRef={apiRef}
              rows={savingProgress.isLoading ? [] : dataRows}
              columns={columns}
              columnVisibilityModel={{
                accountId: false,
              }}
              initialState={{
                sorting: {
                  sortModel: [{ field: 'accountName', sort: 'asc' }],
                },
              }}
              pageSize={items.length}
              disableColumnMenu
              disableColumnReorder={true}
              autoHeight
              components={{
                LoadingOverlay: LinearProgress,
                Toolbar: () => {
                  return (
                    <GridToolbarContainer>
                      <Stack direction="row" alignItems="center">
                        {savingProgress.isLoading && (
                          <div className="LoadingIndicator" style={{ padding: '7px' }}>
                            <PulseLoader size="9px" margin="5px" color={theme.palette.grey[400]} />
                          </div>
                        )}
                        {!editMode && (
                          <IconButton disableFocusRipple disableRipple data-testid="editButton" onClick={(e) => handleEditClick(e)} color={'primary'}>
                            <EditIcon style={{ height: '24px' }} />
                          </IconButton>
                        )}
                        {editMode && !savingProgress.isLoading && (
                          <IconButton disableFocusRipple disableRipple data-testid="cancelButton" onClick={(e) => handleCancelClick(e)} color={'primary'}>
                            <CancelIcon style={{ height: '24px' }} />
                          </IconButton>
                        )}
                        {editMode && !savingProgress.isLoading && (
                          <IconButton disableFocusRipple disableRipple data-testid="saveButton" onClick={(e) => handleSave(e)} color={'primary'}>
                            <SaveIcon style={{ height: '24px' }} />
                          </IconButton>
                        )}
                      </Stack>
                    </GridToolbarContainer>
                  );
                },
              }}
              loading={itemLoadingProgress.isLoading || savingProgress.isLoading}
              onRowEditStop={(_params: GridRowParams, event: MuiEvent<MuiBaseEvent>) => {
                // we don't want to end editting unless they click save
                event.defaultMuiPrevented = true;
              }}
              onRowEditStart={(_params: GridRowParams, event: MuiEvent<MuiBaseEvent>) => {
                // we don't want to end editting unless they click edit
                event.defaultMuiPrevented = true;
              }}
              density="comfortable"
              hideFooter={true}
            />
          </StyledBox>
        </Paper>
      </div>
    </>
  );
};
