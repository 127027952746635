import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { progressSelectorFactory } from 'src/common';
import { RootState } from '../../../../../reducers';
import { selectAfslId, selectEntityDetails } from '../../common/store/selectors';
import {
  CommonActionTypes,
  commonSlice,
  fetchApprovedProducts,
  fetchInvestmentServices,
  fetchInvestmentServiceVersions,
  fetchSecurities,
  selectCurrentInvestmentVersionId,
  selectInvestmentService,
  selectInvestmentServicesList,
  selectInvestmentServicesWithVersionsGrouped,
} from '../store/common';
import { Configuration } from './common/configuration';
import { activateInvestmentServiceVersion, activationSlice, selectStatus, selectValidationMessages, ValidationMessagesActionTypes } from './store/activation';

const mapStateToProps = (state: RootState) => ({
  investmentServices: selectInvestmentServicesList(state),
  currentInvestmentServiceVersionId: selectCurrentInvestmentVersionId(state),
  loadingProgress: progressSelectorFactory([CommonActionTypes.FetchInvestmentServices, CommonActionTypes.FetchInvestmentServiceVersions])(state),
  investmentServicesWithVersionsGrouped: selectInvestmentServicesWithVersionsGrouped(state),
  investmentService: selectInvestmentService(state),
  activationValidationMessages: selectValidationMessages(state),
  activateInvestmentServiceVersionProgress: progressSelectorFactory([ValidationMessagesActionTypes.ActivateInvestmentServiceVersion])(state),
  activationStatus: selectStatus(state),
  clientDetails: selectEntityDetails(state),
  afslId: selectAfslId(state),
});

const mapDispatchToProps = {
  setInvestmentServiceVersion: commonSlice.actions.setInvestmentServiceVersion,
  fetchInvestmentServices,
  fetchInvestmentServiceVersions,
  fetchSecurities,
  fetchApprovedProducts,
  activateInvestmentServiceVersion,
  clearValidationMessages: activationSlice.actions.clearValidationMessages,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export type Props = ConnectedProps<typeof connector> & RouteComponentProps;
export const ConfigurationContainer = connect(mapStateToProps, mapDispatchToProps)(Configuration);
