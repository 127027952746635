import { Box, Container, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Props } from '../container';
import { PreapprovalGroupedTrades } from '../store/types';
import Breadcrumb from './breadcrumb';
import PreapprovedTrades from './preapprovedTrades';
import Summary from './summary';
import ValidationMessages from './validationMessages';

export default function RebalanceCheckoutComponent(props: Props & RouteComponentProps<void>): JSX.Element {
  const { groupedTrades, rebalance } = props;

  useEffect(() => {
    document.title = ' WealthO2 | Rebalance';

    const message = 'Warning!\n\nNavigating away from this page will delete your rebalance.';

    window.addEventListener('beforeunload', (e: BeforeUnloadEvent) => {
      e.returnValue = message;
      return message;
    });

    return () => {
      window.removeEventListener('beforeunload', (e: BeforeUnloadEvent) => {
        e.returnValue = message;
        return message;
      });
    };
  }, []);

  return (
    <Container>
      <Box width={'100%'} display={'flex'} paddingTop={'60px'}>
        <Typography variant="h2" style={{ display: 'inline-flex', width: '100%', height: '48px' }}>
          {rebalance.clientName}
        </Typography>
      </Box>
      <Box marginBottom={'40px'}>
        <Breadcrumb />
      </Box>
      <ValidationMessages {...props} />
      <Box style={{ display: 'inline-flex' }} width={'100%'}>
        <Box style={{ paddingRight: '40px', width: '100%' }}>
          {groupedTrades.map((groupedTrades: PreapprovalGroupedTrades, index: number) => (
            <PreapprovedTrades key={index} groupedTrades={groupedTrades} hasRebalanceEnhancedTrading hasClientEditAdminOnlyPermission />
          ))}
        </Box>
        <Box style={{ width: '450px' }}>
          <Summary {...props} />
        </Box>
      </Box>
    </Container>
  );
}
