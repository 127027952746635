import { Container, Grid } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { Field, Form, Formik } from 'formik';
import React, { useCallback, useEffect, useState } from 'react';
import { LoadingIndicator } from 'src/common/components/LoadingIndicator';
import { formatDollars, formatPercentage } from '../../../../common';
import CardComponent from '../../../../common/components/CardComponent';
import { FormikKeyboardDatePicker, FormikSwitch, FormikTextField } from '../../../../common/components/formik';
import { theme } from '../../../../themes';
import { Props } from '../container';
import { Afsl, StrategyDetails } from '../store/types';

interface FormValues extends Partial<StrategyDetails> {
  targetRateOfReturnFormatted: string;
  ownerAfslsNames: string;
  //accessAfslsName: string;
  primaryBenchmarkFull: string;
  secondaryBenchmarkFull: string;
}

export const DetailsComponent = ({ loadingProgress, fetchStrategyDetails, strategyDetails, match: { params } }: Props): JSX.Element => {
  const fetch = useCallback(() => {
    if (params.strategyId) {
      fetchStrategyDetails({ strategyId: parseInt(params.strategyId) });
    }
  }, [params.strategyId, fetchStrategyDetails]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  const arrayToString = (arr: Afsl[]) => {
    const newArr: string[] = [];
    arr.forEach((item: Afsl) => {
      newArr.push(item.name);
    });
    return newArr.toString();
  };

  const fullName = (code: string, name: string) => {
    if (name) {
      return code + ' - ' + name;
    } else {
      return name;
    }
  };

  const initialFormValues: FormValues = {
    targetRateOfReturnFormatted: '',
    ownerAfslsNames: '',
    //accessAfslsName: '',
    primaryBenchmarkFull: '',
    secondaryBenchmarkFull: '',
  };

  useEffect(() => {
    setFormValues({
      ...strategyDetails,
      targetRateOfReturnFormatted: formatPercentage((strategyDetails?.targetReturnRate || 0) / 100),
      ownerAfslsNames: arrayToString(strategyDetails?.ownerAfsls || []),
      //accessAfslsName: arrayToString(strategyDetails?.accessAfsls || []),
      primaryBenchmarkFull: fullName(strategyDetails?.primaryBenchmark?.code || '', strategyDetails?.primaryBenchmark?.name || ''),
      secondaryBenchmarkFull: fullName(strategyDetails?.secondaryBenchmark?.code || '', strategyDetails?.secondaryBenchmark?.name || ''),
    });
  }, [strategyDetails]);

  const readonly = true;

  const [formValues, setFormValues] = useState<FormValues>(initialFormValues);

  return (
    <Container style={{ paddingTop: '20px', paddingBottom: '20px' }}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <CardComponent progress={loadingProgress} background={theme.palette.gradient1?.main} value={formatDollars(strategyDetails?.fua)} subText={'FUA'} />
        </Grid>
        <Grid item xs={6}>
          <CardComponent progress={loadingProgress} background={theme.palette.gradient2?.main} value={strategyDetails?.portfolioCount} subText={'Portfolios'} />
        </Grid>
      </Grid>
      <LoadingIndicator progress={loadingProgress}>
        <Card style={{ marginTop: '25px' }} elevation={0}>
          <CardContent>
            <Formik<FormValues>
              initialValues={formValues}
              enableReinitialize={true}
              onSubmit={() => {
                return;
              }}
            >
              <Form>
                <Grid container spacing={2}>
                  <Grid item xs={6} container>
                    <Grid item xs={12}>
                      <Field disabled={readonly} component={FormikTextField} name={'name'} label="STRATEGY NAME" fullWidth />
                    </Grid>
                    <Grid item xs={12} style={{ marginTop: '20px' }}>
                      <Field disabled={readonly} component={FormikTextField} name={'type'} label="STRATEGY TYPE" fullWidth />
                    </Grid>
                    <Grid item xs={12} style={{ marginTop: '20px' }}>
                      <Field disabled={readonly} component={FormikTextField} name={'primaryBenchmarkFull'} label="PRIMARY BENCHMARK" fullWidth />
                    </Grid>
                    <Grid item xs={12} style={{ marginTop: '20px' }}>
                      <Field disabled={readonly} component={FormikTextField} name={'assetClass'} label="ASET CLASS" fullWidth />
                    </Grid>
                    <Grid item xs={12} style={{ marginTop: '20px' }}>
                      <Field disabled={readonly} multiline component={FormikTextField} name={'description'} label="DESCRIPTION" rows="3" fullWidth />
                    </Grid>
                  </Grid>
                  <Grid item xs={6} container>
                    <Grid item xs={12}>
                      <Field disabled={readonly} component={FormikTextField} name={'code'} label="MODEL CODE" fullWidth />
                    </Grid>
                    <Grid item xs={12} style={{ marginTop: '20px' }}>
                      <Field
                        disabled={readonly}
                        component={FormikKeyboardDatePicker}
                        name="inceptionDate"
                        label="INCEPTION DATE"
                        maxDateMessage="From Date should not be after To Date"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} style={{ marginTop: '20px' }}>
                      <Field
                        disabled={readonly}
                        component={FormikKeyboardDatePicker}
                        name="analyticsStartDate"
                        label="ANALYTICS START DATE"
                        maxDateMessage="From Date should not be after To Date"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} style={{ marginTop: '20px' }}>
                      <Field disabled={readonly} component={FormikTextField} name={'secondaryBenchmarkFull'} label="SECONDARY BENCHMARK" fullWidth />
                    </Grid>
                    <Grid item xs={12} style={{ marginTop: '20px' }}>
                      <Field disabled={readonly} component={FormikTextField} name={'targetRateOfReturnFormatted'} label="TARGET RATE OF RETURN" fullWidth />
                    </Grid>
                    <Grid item xs={12} style={{ marginTop: '20px' }}>
                      <Field disabled={readonly} multiline component={FormikTextField} name={'ownerAfslsNames'} label="OWNER AFSL" rows="3" fullWidth />
                    </Grid>
                    <Grid item xs={12} style={{ marginTop: '20px' }}>
                      <Field disabled={readonly} name={'isSuperSimplifier'} component={FormikSwitch} label="Is this a Super Simplifier Strategy?"></Field>
                    </Grid>
                  </Grid>
                </Grid>
              </Form>
            </Formik>
          </CardContent>
        </Card>
      </LoadingIndicator>
    </Container>
  );
};
