import { Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { LoadingIndicator } from 'src/common/components/LoadingIndicator';
import { SuperSimplifierDetails } from '../../../common/store/types';
import { Props } from '../container';
import { DetailsForm } from './detailsForm';
import { MemberDetails } from './memberDetails';

export const SuperSimplifier = ({
  clientId,
  details,
  hasClientEditPermission,
  hasClientEditAdminOnlyPermission,
  updateSuperSimplifier,
  loadingProgress,
  savingProgress,
  beneficiaries,
  loadingProgressBeneficiaries,
  fetchSuperSimplifierBeneficiaries,
  status,
}: Props): JSX.Element => {
  const [readonly, setReadonly] = useState<boolean>(true);

  useEffect(() => {
    if (!!clientId) {
      fetchSuperSimplifierBeneficiaries(clientId);
    }
  }, [fetchSuperSimplifierBeneficiaries, clientId]);

  const onSave = (details: SuperSimplifierDetails) => {
    if (!!clientId) {
      updateSuperSimplifier({ details: { ...details, clientId: clientId }, message: 'Super Simplifier details saved' }).then(() => {
        setReadonly(true);
      });
    }
  };

  return (
    <>
      <Typography variant="h3" style={{ padding: '20px 0' }}>
        Super Simplifier Details
      </Typography>
      <LoadingIndicator progress={loadingProgress}>
        {!!details && (
          <>
            <MemberDetails details={details}></MemberDetails>
            <DetailsForm
              details={details}
              onSave={onSave}
              readonly={readonly}
              savingProgress={savingProgress}
              onToggleEditMode={() => setReadonly(!readonly)}
              hasClientEditPermission={hasClientEditPermission}
              hasClientEditAdminOnlyPermission={hasClientEditAdminOnlyPermission}
              loadingProgressBeneficiaries={loadingProgressBeneficiaries}
              beneficiaries={beneficiaries}
              status={status}
            ></DetailsForm>
          </>
        )}
      </LoadingIndicator>
    </>
  );
};
