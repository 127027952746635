import { SuperSimplifierDetails } from './types';

export const blankSuperSimplifierDetails: SuperSimplifierDetails = {
  superMemberNumber: '',
  subAccountTypeId: null,
  fundName: '',
  memberNumber: '',
  superAnnuationId: '',
  rolloverAmount: null,
  fullRollover: false,
  fundNameSecond: '',
  memberNumberSecond: '',
  superAnnuationIdSecond: '',
  rolloverAmountSecond: null,
  fullRolloverSecond: false,
  fundNameThird: '',
  memberNumberThird: '',
  superAnnuationIdThird: '',
  rolloverAmountThird: null,
  fullRolloverThird: false,
  isResident: true,
  ageOver65: null,
  ageOver60NotPaid: null,
  ageNotOver60NotPaid: null,
  reachedAge: null,
  atp: null,
  paymentFrequencyId: null,
  paymentLimitTypeId: null,
  paymentLimit: null,
  paymentTypeId: null,
  pensionTypeId: null,
  inSpecie: false,
  inSpecieSecond: false,
  inSpecieThird: false,
  contributionsAmount: null,
};
