import { Backdrop, Fade, Grid, Modal, Paper } from '@mui/material';
import { Field, FieldProps, Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import WO2Button from 'src/common/components/button/Button';
import { useConfirmation } from 'src/common/components/dialogs';
import * as yup from 'yup';
import { EditCancelSaveButtons, FormikNumberFormat, FormikTextField, Mode } from '../../../../../common/components/formik';
import { OnboardingAccountInstitutionType } from '../../store/enums';
import { ExistingAccount } from '../../store/types';

export interface AccountProps {
  institutionId: number;
  existingAccount: ExistingAccount | undefined;
  onSave: (payload: ExistingAccount) => void;
  onRemove: (payload: number) => void;
  handleCloseModal: () => void;
}

export function AccountEditModal(props: AccountProps): JSX.Element {
  const confirm = useConfirmation();
  const { institutionId, onSave, onRemove, handleCloseModal, existingAccount } = props;
  const isMacquarieCMA = institutionId === OnboardingAccountInstitutionType.MacquarieCma.id;
  const isAMM = institutionId === OnboardingAccountInstitutionType.AMM.id;

  const initialFormValues: ExistingAccount = {
    institutionId,
    bsb: null,
    accountName: '',
    accountNumber: null,
  };

  const [formValues, setFormValues] = useState<ExistingAccount>(initialFormValues);

  useEffect(() => {
    if (!!existingAccount) {
      setFormValues({ ...existingAccount, institutionId });
    }
  }, [existingAccount, setFormValues, institutionId]);

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={true}
      onClose={handleCloseModal}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{ timeout: 500 }}
    >
      <Fade in={true}>
        <Paper
          elevation={0}
          style={{ width: '400px', padding: '40px 40px', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}
        >
          <Formik<ExistingAccount>
            initialValues={formValues}
            onSubmit={async (accounts) => {
              onSave(accounts);
            }}
            enableReinitialize={true}
            validationSchema={yup.object({
              institutionId: yup.number(),
              bsb: isMacquarieCMA
                ? yup
                    .string()
                    .required('BSB is required')
                    .matches(/^[0-9]{6}$/, 'BSB must be 6 digits')
                : yup.string().nullable(),
              accountName: yup.string().required('Account name is required'),
              accountNumber: isAMM ? yup.string().nullable() : yup.string().required('Account number is required'),
            })}
          >
            {() => (
              <Form>
                <fieldset style={{ border: 'none', padding: '0' }}>
                  <legend data-testid="institutionLabel" style={{ padding: '30px 0' }}>
                    {OnboardingAccountInstitutionType.getById(formValues?.institutionId || null)?.displayName}
                  </legend>
                  <Grid container style={{ height: isMacquarieCMA ? '290px' : '190px' }}>
                    <Grid item xs={12}>
                      {isMacquarieCMA && (
                        <Grid item xs={12} style={{ marginBottom: '30px' }}>
                          <Field name="bsb" label="BSB" fullWidth>
                            {(fieldProps: FieldProps) => {
                              return (
                                <FormikNumberFormat
                                  formikFieldProps={fieldProps}
                                  numberFormatProps={{
                                    format: '###-###',
                                    mask: '_',
                                    placeholder: '',
                                    name: fieldProps.field.name,
                                    label: 'BSB',
                                    isNumericString: true,
                                  }}
                                  showRequiredAsterisk={true}
                                  fullWidth={true}
                                ></FormikNumberFormat>
                              );
                            }}
                          </Field>
                        </Grid>
                      )}
                      <Grid item xs={12} style={{ marginBottom: '30px' }}>
                        <Field name="accountName" component={FormikTextField} showRequiredAsterisk={true} label="ACCOUNT NAME" fullWidth></Field>
                      </Grid>
                      {!isAMM && (
                        <Grid item xs={12} style={{ marginBottom: '30px' }}>
                          <Field name="accountNumber" component={FormikTextField} showRequiredAsterisk={true} label="ACCOUNT NUMBER" fullWidth></Field>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                  <EditCancelSaveButtons mode={Mode.CancelSave} handleCancelClick={handleCloseModal}>
                    {!!existingAccount && (
                      <WO2Button
                        type="button"
                        data-testid="removeButton"
                        variant={'contained'}
                        disableElevation
                        onClick={() => {
                          confirm({
                            title: 'Delete account',
                            description: 'Are you sure you wish to delete this account?',
                          }).then(() => {
                            onRemove(institutionId);
                          });
                        }}
                        style={{ marginRight: '10px' }}
                      >
                        Remove
                      </WO2Button>
                    )}
                  </EditCancelSaveButtons>
                </fieldset>
              </Form>
            )}
          </Formik>
        </Paper>
      </Fade>
    </Modal>
  );
}
