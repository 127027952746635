import { Box, Fade, Grid, Paper, Typography } from '@mui/material';
import { Field, Form, Formik, FormikProps } from 'formik';
import React from 'react';
import { CreatePendingAccountPayload, PendingAccountCreate } from 'src/features/clients/client/details/accounts/store/types';
import * as yup from 'yup';
import { WO2Modal } from '../../';
import { MainDetails } from '../../../../features/clients/client/common/store/types';
import { LoadingProgress } from '../../../store/types';
import { EditCancelSaveButtons, FormikSwitch, FormikTextField, Mode } from '../../formik';
import { AccountInstitutionItem } from '../accountInstitutionItem';
import { NewAccount, NewAccountInfo, PendingAccountInstitutionType } from '../types';

export interface OpenNewAccountProps {
  newAccounts: NewAccount;
  clientDetails: MainDetails | undefined;
  isOpen: boolean;
  saveProgress?: LoadingProgress;
  handleCloseModal: () => void;
  onSave: (payload: CreatePendingAccountPayload) => void;
  clientId: number | null;
}

interface FormValues {
  institutionIds: number[];
  accountInfo: NewAccountInfo;
}

export function WO2OpenNewAccount({ newAccounts, handleCloseModal, onSave, clientId, isOpen, saveProgress }: OpenNewAccountProps): JSX.Element {
  const initialFormValues: FormValues = {
    institutionIds: newAccounts.institutionIds,
    accountInfo: {
      isHinTransfer: newAccounts.accountInfo.isHinTransfer ?? false,
      hin: newAccounts.accountInfo.hin ?? null,
      pid: newAccounts.accountInfo.pid ?? null,
    },
  };

  return (
    <WO2Modal
      MuiDialogProps={{
        open: isOpen,
        onClose: handleCloseModal,
      }}
      title={<>Open New Account(s)</>}
      actions={[]}
      maxWidth={'md'}
    >
      <Fade in={isOpen}>
        <Paper elevation={0}>
          <Formik<FormValues>
            enableReinitialize={true}
            initialValues={initialFormValues}
            validationSchema={yup.object({
              institutionIds: yup.array(),
              accountInfo: yup.object().when('institutionIds', {
                is: (institutionIds) => institutionIds.length > 0,
                then: yup.object().shape({
                  isHinTransfer: yup.boolean(),
                  hin: yup
                    .mixed()
                    .nullable()
                    .when('isHinTransfer', {
                      is: true,
                      then: yup.string().required('HIN is required'),
                    }),
                  pid: yup
                    .mixed()
                    .nullable()
                    .when('isHinTransfer', {
                      is: true,
                      then: yup.string().required('PID is required'),
                    }),
                  sourceOfFunds: yup.string().nullable(),
                  riskProfile: yup.string().nullable(),
                }),
              }),
            })}
            onSubmit={(values: FormValues) => {
              const pendingAccounts: PendingAccountCreate[] = [];

              values.institutionIds.forEach((item) => {
                pendingAccounts.push({
                  institution:
                    PendingAccountInstitutionType.getAllNewAccountInstitutions().find(
                      (accountInstitution: PendingAccountInstitutionType) => accountInstitution.id === item
                    )?.name ?? '',
                  name: null,
                  number: null,
                  subNumber: null,
                  hin: values.accountInfo.hin,
                  pid: values.accountInfo.pid,
                  state: 'New',
                });
              });
              if (clientId) {
                const payload: CreatePendingAccountPayload = {
                  clientId,
                  pendingAccounts,
                };
                onSave(payload);
              }
            }}
          >
            {(formikProps: FormikProps<FormValues>) => (
              <Form>
                <Typography variant="h4" style={{ margin: '30px 0' }}>
                  Select which new portfolio account(s) you want to open
                </Typography>

                <Box display="flex" data-testid="openNewAccountBox" marginBottom="40px">
                  {PendingAccountInstitutionType.getAllNewAccountInstitutions().map((accountInstitution: PendingAccountInstitutionType) => {
                    return (
                      <AccountInstitutionItem
                        key={`accountInstitutionItem_${accountInstitution.id}`}
                        accountInstitution={accountInstitution}
                        isSelected={formikProps.values.institutionIds.indexOf(accountInstitution.id) !== -1}
                        isAvailable={true}
                        onClickHandler={(institutionId: number) => {
                          const institutionIndex = formikProps.values.institutionIds.indexOf(institutionId);
                          formikProps.setFieldValue(
                            'institutionIds',
                            institutionIndex === -1
                              ? Array.from([...formikProps.values.institutionIds, institutionId]).filter((id, index, self) => self.indexOf(id) === index)
                              : formikProps.values.institutionIds.filter((id: number) => id !== institutionId)
                          );
                          // hide all errors when another insititution is selected
                          if (institutionIndex !== -1) {
                            if (formikProps.values.accountInfo.isHinTransfer) {
                              formikProps.setFieldTouched(`accountInfo.hin`, false, false);
                              formikProps.setFieldTouched(`accountInfo.pid`, false, false);
                            }
                            formikProps.setFieldTouched(`accountInfo.sourceOfFunds`, false, false);
                            formikProps.setFieldTouched(`accountInfo.riskProfile`, false, false);
                          }
                        }}
                      />
                    );
                  })}
                </Box>
                {formikProps.values.institutionIds.length > 0 && (
                  <Box marginTop="40px 0 20px">
                    <Fade in={true} timeout={{ enter: 600 }}>
                      <Box>
                        <Grid container>
                          {formikProps.values.institutionIds.some((id: number) =>
                            [PendingAccountInstitutionType.DesktopBroker.id, PendingAccountInstitutionType.Openmarkets.id].includes(id)
                          ) && (
                            <Grid container>
                              <Grid item xs={12} style={{ marginBottom: '16px' }}>
                                <Field
                                  name={`accountInfo.isHinTransfer`}
                                  component={FormikSwitch}
                                  label="HIN Transfer (select this if you would like to transfer an existing HIN to the new broker account)"
                                  fullWidth
                                  onChange={(isChecked: boolean) => {
                                    if (!isChecked) {
                                      formikProps.setFieldValue('accountInfo.hin', '');
                                      formikProps.setFieldValue('accountInfo.pid', '');
                                    } else {
                                      formikProps.setFieldTouched('accountInfo.hin', false, false);
                                      formikProps.setFieldTouched('accountInfo.pid', false, false);
                                    }
                                  }}
                                />
                              </Grid>
                              {formikProps.values.institutionIds.some((id: number) =>
                                [PendingAccountInstitutionType.DesktopBroker.id, PendingAccountInstitutionType.Openmarkets.id].includes(id)
                              ) &&
                                !!formikProps.values.accountInfo?.isHinTransfer && (
                                  <>
                                    <Grid container>
                                      <Grid item xs={12} style={{ maxWidth: '380px', minHeight: '70px', marginBottom: '16px' }}>
                                        <Field name="accountInfo.hin" showRequiredAsterisk={true} component={FormikTextField} label="HIN" fullWidth />
                                      </Grid>
                                    </Grid>
                                    <Grid container>
                                      <Grid item xs={12} style={{ minHeight: '70px', maxWidth: '380px', marginBottom: '16px' }}>
                                        <Field name="accountInfo.pid" showRequiredAsterisk={true} component={FormikTextField} label="PID" fullWidth />
                                      </Grid>
                                    </Grid>
                                  </>
                                )}
                            </Grid>
                          )}
                        </Grid>
                      </Box>
                    </Fade>
                  </Box>
                )}
                <Grid item xs={12} style={{ marginTop: '30px' }}>
                  <EditCancelSaveButtons mode={Mode.CancelSave} handleCancelClick={handleCloseModal} saveProgress={saveProgress} />
                </Grid>
              </Form>
            )}
          </Formik>
        </Paper>
      </Fade>
    </WO2Modal>
  );
}
