import { createAsyncThunk } from '@reduxjs/toolkit';
import _ from 'lodash';
import api from '../../../../../app/api';
import { buildEncodedQueryString } from '../../../../../common/utils';
import {
  BenchmarkItem,
  BenchmarkPerformanceResult,
  Frequency,
  MonthlyPerformanceTableResult,
  PerformanceChartResult,
  PeriodicSummaryTableResult,
  PortfolioSecurityPerformanceTableResult,
  ThunkParameters,
} from './types';

export enum PerformanceActionTypes {
  FetchBenchmarks = '@@client/performance/FetchBenchmarks',
  FetchBenchmarkPerformance = '@@client/performance/FetchBenchmarksPerformance',
  FetchPerformanceChart = '@@client/performance/FetchPerformanceChart',
  FetchMonthlyPerformanceTable = '@@client/performance/FetchMonthlyPerformanceTable',
  FetchPeriodicSummaryTable = '@@client/performance/FetchPeriodicSummaryTable',
  FetchPortfolioSecurityPerformanceTable = '@@client/performance/FetchPortfolioSecurityPerformanceTable',
}

// Endpoints
export enum PerformanceApi {
  FetchBenchmarks = '/strategies/GetBenchmarksForAfsl',
  FetchDailyPerformanceChart = '/performances/client/GetDailyPerformanceChart',
  FetchMonthlyPerformanceChart = '/performances/client/GetMonthlyPerformanceChart',
  FetchDailyBenchmarkPerformance = '/performances/strategy/GetDailyBenchmarkPerformance',
  FetchMonthlyBenchmarkPerformance = '/performances/strategy/GetMonthlyBenchmarkPerformance',
  FetchMonthlyPortfolioPerformance = '/performances/client/GetMonthlyPortfolioPerformance',
  FetchPortfolioPeriodicSummary = '/performances/client/GetPortfolioPeriodicSummary',
  FetchPortfolioSecurityPerformance = '/performances/client/GetPortfolioSecurityPerformance',
}

export const fetchBenchmarks = createAsyncThunk(PerformanceActionTypes.FetchBenchmarks, async (afslId: number) => {
  const queryString = buildEncodedQueryString({
    afslId: afslId,
  });

  const response = await api.get<BenchmarkItem[]>(`${PerformanceApi.FetchBenchmarks}${queryString}`);
  return response.data;
});

export const fetchBenchmarkPerformance = createAsyncThunk(PerformanceActionTypes.FetchBenchmarkPerformance, async (wrapper: ThunkParameters) => {
  const parameters = wrapper.parameters;
  const queryString = buildEncodedQueryString({
    strategyId: parameters?.selectedBenchmark?.id,
    startDate: _.max([parameters.dates.dateFrom, parameters.selectedPortfolio?.inceptionDate]),
    endDate: parameters.dates.dateTo,
  });

  const endpoint = parameters.frequency === Frequency.Daily ? PerformanceApi.FetchDailyBenchmarkPerformance : PerformanceApi.FetchMonthlyBenchmarkPerformance;

  const response = await api.get<BenchmarkPerformanceResult>(`${endpoint}${queryString}`);
  return response.data;
});

export const fetchPerformanceChart = createAsyncThunk(PerformanceActionTypes.FetchPerformanceChart, async (wrapper: ThunkParameters) => {
  const parameters = wrapper.parameters;
  const queryString = buildEncodedQueryString({
    clientId: wrapper.clientId,
    strategyId: parameters.selectedBenchmark?.id,
    startDate: _.max([parameters.dates.dateFrom, parameters.selectedPortfolio?.inceptionDate]),
    endDate: wrapper.parameters.dates.dateTo,
    investmentServiceId: wrapper.parameters.selectedPortfolio?.investmentProgramId,
  });

  const endpoint = parameters.frequency === Frequency.Daily ? PerformanceApi.FetchDailyPerformanceChart : PerformanceApi.FetchMonthlyPerformanceChart;

  const response = await api.get<PerformanceChartResult>(`${endpoint}${queryString}`);
  return response.data;
});

export const fetchMonthlyPortfolioPerformance = createAsyncThunk(PerformanceActionTypes.FetchMonthlyPerformanceTable, async (wrapper: ThunkParameters) => {
  const parameters = wrapper.parameters;
  const payload = {
    clientId: wrapper.clientId,
    startDate: parameters.dates.dateFrom,
    endDate: parameters.dates.dateTo,
    portfolioId: parameters.selectedPortfolio?.investmentProgramId,
    pagedRequest: wrapper.pagedRequest,
  };

  const response = await api.post<MonthlyPerformanceTableResult>(`${PerformanceApi.FetchMonthlyPortfolioPerformance}`, payload);
  return response.data;
});

export const fetchPeriodicSummary = createAsyncThunk(PerformanceActionTypes.FetchPeriodicSummaryTable, async (wrapper: ThunkParameters) => {
  const parameters = wrapper.parameters;
  const queryString = buildEncodedQueryString({
    clientId: wrapper.clientId,
    inceptionDate: parameters.selectedPortfolio?.inceptionDate,
    endDate: parameters.dates.dateTo,
    investmentServiceId: parameters.selectedPortfolio?.investmentProgramId,
    strategyId: parameters.selectedBenchmark?.id,
  });

  const response = await api.get<PeriodicSummaryTableResult>(`${PerformanceApi.FetchPortfolioPeriodicSummary}${queryString}`);
  return response.data;
});

export const fetchPortfolioSecurityPerformance = createAsyncThunk(
  PerformanceActionTypes.FetchPortfolioSecurityPerformanceTable,
  async (wrapper: ThunkParameters) => {
    const parameters = wrapper.parameters;
    const queryString = buildEncodedQueryString({
      clientId: wrapper.clientId,
      startDate: parameters.dates.dateFrom,
      endDate: parameters.dates.dateTo,
      investmentServiceId: parameters.selectedPortfolio?.investmentProgramId,
      groupingType: parameters.groupingType,
    });

    const response = await api.get<PortfolioSecurityPerformanceTableResult>(`${PerformanceApi.FetchPortfolioSecurityPerformance}${queryString}`);
    return response.data;
  }
);
