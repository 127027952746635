import { Box, Container, Grid } from '@mui/material';
import 'date-fns';
import React, { useCallback, useEffect, useState } from 'react';
import DateRangePicker from '../../../../../common/components/DateRangePicker';
import { PagedRequest } from '../../../../../store';
import PortfolioSelect from '../../common/components/PortfolioSelect';
import { Props } from '../container';
import { Transaction, TransactionCsvRow } from '../store/types';
import WO2Button from './../../../../../common/components/button/Button';
import { AccountSelect } from './accountSelect';
import { TransactionCards } from './transactionCards';
import { TransactionDetails } from './transactionDetails';
import { TransactionsTable } from './transactionsTable';

export const Transactions = (props: Props): JSX.Element => {
  const {
    parameters,
    clientId,
    setPortfolioParameter,
    setTransactionIdParameter,
    setAccountParameter,
    setDateParameter,
    fetchTransactionDetails,
    fetchTransactions,
    fetchTransactionTotals,
    downloadTransactions,
    progressTotals,
    progressTransactions,
  } = props;

  const [initialSearchComplete, SetInitialSearchComplete] = useState(false);

  const handleSearchClick = () => {
    if (!!clientId) {
      fetchTransactionTotals({ clientId, parameters });
    }
    handleGridActions(props.parameters.pagination);
  };

  const handleGridActions = (pagedRequest: PagedRequest) => {
    if (!!clientId) {
      fetchTransactions({
        inputs: { clientId, parameters },
        pagination: pagedRequest,
      });
    }
  };

  const fetchHandleSearchClick = useCallback(() => {
    if (!!clientId) {
      fetchTransactionTotals({ clientId, parameters });
      fetchTransactions({
        inputs: { clientId, parameters },
        pagination: props.parameters.pagination,
      });
    }
  }, [fetchTransactionTotals, fetchTransactions, clientId, parameters, props.parameters.pagination]);

  useEffect(() => {
    if (parameters.accountIdList.length > 0 && !initialSearchComplete) {
      fetchHandleSearchClick();
      SetInitialSearchComplete(true);
    }
  }, [fetchTransactionTotals, parameters, fetchHandleSearchClick, initialSearchComplete]);

  const handleSelectTransaction = (transactionId: number) => {
    setTransactionIdParameter(transactionId);
    if (!!clientId) {
      fetchTransactionDetails({ clientId, transactionId });
    }
  };

  const handleCloseModal = () => {
    setTransactionIdParameter(null);
  };

  return (
    <Container style={{ paddingTop: '20px', paddingBottom: '20px' }}>
      <Box display={'flex'} justifyContent={'space-between'}>
        <Box paddingRight={'10px'}>
          <PortfolioSelect items={props.portfolios} selectedPortfolio={props.parameters.portfolio} setPortfolio={setPortfolioParameter} />
        </Box>
        <Box display={'flex'} justifyContent={'space-between'}>
          <Box paddingRight={'10px'}>
            <AccountSelect items={props.portfolioAccounts} selectedAccountId={props.parameters.selectedAccountId} setAccount={setAccountParameter} />
          </Box>
          <Box paddingRight={'10px'}>
            <DateRangePicker dateRange={props.parameters?.dates} setDateRange={setDateParameter} isDateOnly={false} />
          </Box>
          <Box>
            <WO2Button color={'primary'} disableElevation onClick={handleSearchClick} variant={'contained'} value="Search">
              Search
            </WO2Button>
          </Box>
        </Box>
      </Box>
      <Grid container spacing={2} style={{ paddingTop: '20px', paddingBottom: '20px' }}>
        <TransactionCards loadingProgress={progressTotals} totals={props.totals} />
        <Grid item xs={12}>
          <TransactionsTable
            pagedRequest={props.parameters.pagination}
            pagedResults={props.transactions || undefined}
            progressTransactions={progressTransactions}
            handlePagedRequest={handleGridActions}
            handleSelectTransaction={handleSelectTransaction}
            handleDownload={(pagedRequest: PagedRequest, mapper: (ts: Transaction[]) => TransactionCsvRow[]) => {
              if (!!clientId) {
                downloadTransactions({
                  inputs: { clientId, parameters },
                  pagination: pagedRequest,
                  mapper,
                });
              }
            }}
          ></TransactionsTable>
          {!!props.selectedTransaction && (
            <TransactionDetails
              transaction={props.selectedTransaction}
              isOpen={!!props.selectedTransaction}
              handleCloseModal={handleCloseModal}
            ></TransactionDetails>
          )}
        </Grid>
      </Grid>
    </Container>
  );
};
