import { connect, ConnectedProps } from 'react-redux';
import { progressSelectorFactory } from '../../../../../common';
import { RootState } from '../../../../../reducers';
import { selectClientId } from '../../common/store/selectors';
import {
  selectHoldings,
  selectMonthlyPerformanceChartResults,
  selectParameters,
  selectPortfolio,
  selectPortfoliosSelectModels,
  selectPortfolioValue,
} from '../store/selectors';
import { dashboardSlice } from '../store/slice';
import { DashboardActionTypes, fetchMonthlyPerformanceChart } from '../store/thunks';
import { MonthlyPerformanceChartComponent } from './MonthlyPerformanceChartComponent';

const actionTypes = [DashboardActionTypes.FetchMonthlyPerformanceChart];

const mapStateToProps = (state: RootState) => ({
  loadingProgress: progressSelectorFactory(actionTypes)(state),
  clientId: selectClientId(state),
  selectedPortfolio: selectPortfolio(state),
  parameters: selectParameters(state),
  clientPortfolios: selectPortfoliosSelectModels(state),
  portfolioValue: selectPortfolioValue(state),
  result: selectMonthlyPerformanceChartResults(state),
  holdings: selectHoldings(state),
});

const mapDispatchToProps = {
  fetchMonthlyPerformanceChart,
  setMonthlyPerformanceChartStartDate: dashboardSlice.actions.setMonthlyPerformanceChartStartDate,
  setSelectedPortfolio: dashboardSlice.actions.setPortfolioParameter,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export type Props = ConnectedProps<typeof connector>;
export const MonthlyPerformanceChartContainer = connect(mapStateToProps, mapDispatchToProps)(MonthlyPerformanceChartComponent);
