import { createSelector } from '@reduxjs/toolkit';
import { selectSearchResults, selectSearchSelectedResults } from '../../search/store/selectors';
import { OrderSearchResult } from '../../search/store/types';
import { selectOrder } from '../../selectors';
import { BulkOrder, PreApprovalInvestmentService, PreApprovalTradeItem } from '../../types';
import { Order, OrderEditResult, PortfolioSecurityHoldingItem, TradeAction } from './types';

export const selectEdit = createSelector(selectOrder, (orderState) => orderState.edit);
export const selectParameters = createSelector(selectEdit, (editState) => editState.parameters);
export const selectSecurities = createSelector(selectEdit, (editState) => editState.securities);
export const selectInvestmentServicesLockedByOrders = createSelector(selectEdit, (editState) => editState.investmentServicesLockedByOrders);
export const selectValidationMessages = createSelector(selectEdit, (editState) => editState.validationMessages);
export const selectSecurityHoldings = createSelector(selectEdit, (editState) => editState.securityHoldings);
export const selectIsPreApproved = createSelector(selectEdit, (editState) => editState.isPreApproved);
export const selectEditResults = createSelector(
  selectSearchResults,
  selectSearchSelectedResults,
  selectSecurityHoldings,
  (searchResults, selectedSearchResults, securityHoldings) => {
    const selected: OrderSearchResult[] = [];
    const unitPrice = securityHoldings?.unitPrice ?? 0;
    selectedSearchResults.forEach((i) => selected.push(searchResults[i]));

    const result: OrderEditResult[] = selected.map((r) => {
      const holding = securityHoldings?.holdings.find((i: PortfolioSecurityHoldingItem) => i.portfolioId === r.portfolioId);
      return {
        clientId: r.clientId,
        clientName: r.clientName,
        portfolioId: r.portfolioId,
        portfolioName: r.portfolioName,
        marketValue: holding?.portfolioValue || r.marketValue,
        amount: holding?.amount || 0,
        tradeValue: (holding?.newUnits || 0) * unitPrice,
        tradeUnits: holding?.newUnits || 0,
        currentValue: (holding?.units || 0) * unitPrice,
        currentUnits: holding?.units || 0,
        proposedValue:
          holding && securityHoldings
            ? securityHoldings.tradeAction === TradeAction.Buy.name
              ? (holding.units + holding.newUnits) * unitPrice
              : (holding.units - holding.newUnits) * unitPrice
            : 0,
        cashBalance: r.cashBalance,
        proposedCash:
          holding && securityHoldings
            ? securityHoldings.tradeAction === TradeAction.Buy.name
              ? r.cashBalance - holding.newUnits * unitPrice
              : r.cashBalance + holding.newUnits * unitPrice
            : 0,
        targetPercentage: holding ? holding.targetPercentage : 0,
      };
    });
    return result;
  }
);

export const selectBulkOrder = createSelector(
  selectSearchResults,
  selectSearchSelectedResults,
  selectSecurityHoldings,
  (searchResults, selectedSearchResults, securityHoldings) => {
    if (securityHoldings) {
      const orders: Order[] = [];

      selectedSearchResults.forEach((i) => {
        const order = searchResults[i];
        const holding = securityHoldings.holdings.find((h) => h.portfolioId === order.portfolioId);
        if (holding && holding.newUnits !== 0) {
          orders.push({
            portfolioId: holding.portfolioId,
            units: holding.newUnits,
          });
        }
      });

      const bulkOrder: BulkOrder = {
        security: {
          code: securityHoldings.code,
          name: securityHoldings.name,
          id: securityHoldings.securityId,
          unitPrice: securityHoldings.unitPrice,
          priceDate: securityHoldings.priceDate,
        },
        action: securityHoldings.tradeAction,
        orders: orders,
        comments: '',
      };

      return orders.length > 0 ? bulkOrder : null;
    } else return null;
  }
);

export const selectPreApprovalInvestmentServices = createSelector(selectBulkOrder, selectEditResults, (orderState, editResults) => {
  const preApprovalInvestmentServices: PreApprovalInvestmentService[] = [];

  editResults.forEach((editResult) => {
    const preApprovalTradeItems: PreApprovalTradeItem[] = [];

    orderState?.orders.forEach((order) => {
      if (order.portfolioId === editResult.portfolioId) {
        const preApprovalTradeItem: PreApprovalTradeItem = {
          calculatedTradeUnits: order.units,
          securityId: orderState.security.id,
          tradeAction: TradeAction.SellAll.name === orderState.action ? TradeAction.Sell.name : orderState.action,
          unitPrice: orderState.security.unitPrice,
        };

        preApprovalTradeItems.push(preApprovalTradeItem);
      }
    });

    if (preApprovalTradeItems.length > 0) {
      const preApprovalInvestmentService: PreApprovalInvestmentService = {
        clientId: editResult.clientId,
        clientName: editResult.clientName,
        investmentServiceId: editResult.portfolioId,
        InvestmentServiceName: editResult.portfolioName,
        preApprovalTradeItems: preApprovalTradeItems,
      };

      preApprovalInvestmentServices.push(preApprovalInvestmentService);
    }
  });

  return preApprovalInvestmentServices;
});

export const selectPortfolioIds = createSelector(selectEditResults, (editResults) => editResults?.map((i) => i.portfolioId) ?? []);
