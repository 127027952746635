import { Enumeration } from './enumeration';

export enum ServiceType {
  IMA = 'Individually Managed Account (IMA)',
  MDA = 'Managed Discretionary Account (MDA)',
  Personal = 'Personal',
  StatementOfAdvice = 'Statement of Advice (SOA)',
  Unlisted = 'Unlisted',
  Wholesale = 'Wholesale',
}

export class ServiceTypesEnum extends Enumeration {
  static MDA = new ServiceTypesEnum(1, 'Mda', ServiceType.MDA.toString());
  static IMA = new ServiceTypesEnum(2, 'Ima', ServiceType.IMA.toString());
  static Personal = new ServiceTypesEnum(6, 'Personal', ServiceType.Personal);
  static Unlisted = new ServiceTypesEnum(7, 'Unlisted', ServiceType.Unlisted);
  static StatementOfAdvice = new ServiceTypesEnum(8, 'Soa', ServiceType.StatementOfAdvice);
  static Wholesale = new ServiceTypesEnum(9, 'Wholesale', ServiceType.Wholesale);

  static getById = (id: number | null): ServiceTypesEnum | null =>
    Object.values(ServiceTypesEnum)
      .filter((t) => t instanceof ServiceTypesEnum)
      .find((t: ServiceTypesEnum) => t.id === id);

  static getByName = (name: string): ServiceTypesEnum | undefined => {
    return Object.values(ServiceTypesEnum)
      .filter((t) => t instanceof ServiceTypesEnum)
      .find((t: ServiceTypesEnum) => t.name.toLowerCase() === name.toLowerCase());
  };
}
