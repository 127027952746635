import { Enumeration } from '../../../common/types';
import { TfnExemptionCode } from './types';

export class RolloverType extends Enumeration {
  static PartialRollover = new RolloverType(1, 'PartialRollover', 'Partial Rollover');
  static FullRollover = new RolloverType(2, 'FullRollover', 'Full Rollover');

  static getArray = (): RolloverType[] => [RolloverType.PartialRollover, RolloverType.FullRollover];

  static getById = (id: number): RolloverType | undefined => {
    return Object.values(RolloverType)
      .filter((t) => t instanceof RolloverType)
      .find((t: RolloverType) => t.id === id);
  };
}

export class FeeCalculationType extends Enumeration {
  static OngoingAdviceFee = new FeeCalculationType(1, 'OngoingAdviceFee', 'Ongoing Advice Fee');
  static UpfrontAdviceFee = new FeeCalculationType(2, 'UpfrontAdviceFee', 'Upfront Advice Fee');
  static PortfolioManagementFee = new FeeCalculationType(3, 'PortfolioManagementFee', 'Portfolio Management Fee');
  static TotalFee = new FeeCalculationType(-1, 'TotalFee', 'Total'); //not in backend

  static getArray = (): FeeCalculationType[] => [
    FeeCalculationType.OngoingAdviceFee,
    FeeCalculationType.UpfrontAdviceFee,
    FeeCalculationType.PortfolioManagementFee,
  ];

  static getById = (id: number): FeeCalculationType | undefined => {
    return Object.values(FeeCalculationType)
      .filter((t) => t instanceof FeeCalculationType)
      .find((t: FeeCalculationType) => t.id === id);
  };
}

export class FeeMethod extends Enumeration {
  static Dollar = new FeeMethod(1, 'Dollar', 'Dollar');
  static Percentage = new FeeMethod(2, 'Percentage', 'Percentage');
  static Tiered = new FeeMethod(3, 'Tiered', 'Tiered');

  static getArray = (): FeeMethod[] => [FeeMethod.Dollar, FeeMethod.Percentage, FeeMethod.Tiered];

  static getById = (id: number): FeeMethod | undefined => {
    return Object.values(FeeMethod)
      .filter((t) => t instanceof FeeMethod)
      .find((t: FeeMethod) => t.id === id);
  };
}

export class FeeFrequency extends Enumeration {
  static PerMonth = new FeeFrequency(1, 'PerMonth', 'Per Month');
  static PerAnnum = new FeeFrequency(2, 'PerAnnum', 'Per Annum');
  static NotDefined = new FeeFrequency(3, 'NotDefined', 'Not Defined');

  static getArray = (): FeeFrequency[] => [FeeFrequency.PerAnnum, FeeFrequency.PerMonth];

  static getById = (id: number): FeeFrequency | undefined => {
    return Object.values(FeeFrequency)
      .filter((t) => t instanceof FeeFrequency)
      .find((t: FeeFrequency) => t.id === id);
  };
}

export class TfnExemptions extends Enumeration {
  static InvestorChoses = new TfnExemptions(1, 'InvestorChoses', 'Investor chooses not to quote TFN and has not claimed an exemption from quoting');
  static InvestorUnder = new TfnExemptions(2, 'InvestorUnder', 'Investor under age of 16');
  static InvestorIsPensioner = new TfnExemptions(3, 'InvestorIsPensioner', 'Investor is a pensioner');
  static InvestorIsRecipient = new TfnExemptions(4, 'InvestorIsRecipient', 'Investor is a recipient of eligible Social Security pension or Benefit');
  static EntityNotRequired = new TfnExemptions(5, 'EntityNotRequired', 'Entity not required to lodge an income tax return');
  static InvestorsInBussiness = new TfnExemptions(6, 'InvestorsInBussiness', 'Investors in the business of providing consumer or business finance');
  static NorfolkIslandResidents = new TfnExemptions(7, 'NorfolkIslandResidents', 'Norfolk Island residents');
  static NonResident = new TfnExemptions(8, 'NonResident', 'Non-resident');
  static AlphaCharacters = new TfnExemptions(9, 'AlphaCharacters', 'Alphabetic characters in quoted TFN or quoted TFN has more than 11 characters');

  static tfnExemptionCodes: TfnExemptionCode[] = [
    { id: 1, name: 'InvestorChoses', code: '000000000' },
    { id: 2, name: 'InvestorUnder', code: '333333333' },
    { id: 3, name: 'InvestorIsPensioner', code: '444444441' },
    { id: 4, name: 'InvestorIsRecipient', code: '444444442' },
    { id: 5, name: 'EntityNotRequired', code: '555555555' },
    { id: 6, name: 'InvestorsInBussiness', code: '666666666' },
    { id: 7, name: 'NorfolkIslandResidents', code: '777777777' },
    { id: 8, name: 'NonResident', code: '888888888' },
    { id: 9, name: 'AlphaCharacters', code: '987654321' },
  ];

  static getById = (id: number): TfnExemptions | undefined => {
    return Object.values(TfnExemptions)
      .filter((t) => t instanceof TfnExemptions)
      .find((t: TfnExemptions) => t.id === id);
  };

  static getTfnExemptionCodeById = (id: number): TfnExemptionCode | undefined => {
    return Object.values(TfnExemptions.tfnExemptionCodes).find((tfnExemptionCode: TfnExemptionCode) => tfnExemptionCode.id === id);
  };

  static getExemptionCode = (exemption: number | TfnExemptions): string => {
    const id: number = exemption instanceof TfnExemptions ? exemption.id : exemption;

    const exemptionCode = Object.values(TfnExemptions.tfnExemptionCodes).find((tfnExemptionCode: TfnExemptionCode) => tfnExemptionCode.id === id);

    return exemptionCode?.code ?? '';
  };

  static isValidExemptionCode = (tfn: string): boolean => {
    return Object.values(TfnExemptions.tfnExemptionCodes).some((tfnExemptionCode: TfnExemptionCode) => tfnExemptionCode.code === tfn);
  };
}

export class Occupation extends Enumeration {
  static Clerical = new Occupation(1, 'Clerical and administrative workers', 'Clerical and administrative workers');
  static Community = new Occupation(2, 'Community and personal service workers', 'Community and personal service workers');
  static Homemaker = new Occupation(3, 'Homemaker', 'Homemaker');
  static Labourers = new Occupation(4, 'Labourers', 'Labourers');
  static Machinery = new Occupation(5, 'Machinery operators and drivers', 'Machinery operators and drivers');
  static Military = new Occupation(6, 'Military', 'Military');
  static Professionals = new Occupation(7, 'Professionals', 'Professionals');
  static Retired = new Occupation(8, 'Retired', 'Retired');
  static Sales = new Occupation(9, 'Sales workers', 'Sales workers');
  static Student = new Occupation(10, 'Student', 'Student');
  static Technicians = new Occupation(11, 'Technicians and trades workers', 'Technicians and trades workers');

  static getArray = (): Occupation[] => [
    Occupation.Clerical,
    Occupation.Community,
    Occupation.Homemaker,
    Occupation.Labourers,
    Occupation.Machinery,
    Occupation.Military,
    Occupation.Professionals,
    Occupation.Retired,
    Occupation.Sales,
    Occupation.Student,
    Occupation.Technicians,
  ];

  static getById = (value: number | null): Occupation | undefined => {
    return Object.values(Occupation)
      .filter((t) => t instanceof Occupation)
      .find((t: Occupation) => t.id === value);
  };

  static getByName = (value: string | null): Occupation | undefined => {
    return Object.values(Occupation)
      .filter((t) => t instanceof Occupation)
      .find((t: Occupation) => t.name === value);
  };
}

export class Position extends Enumeration {
  occupation: string;

  constructor(id: number, name: string, displayName: string, occupation: string) {
    super(id, name, displayName);
    this.occupation = occupation;
  }

  static ClericalAdministrativeWorkers = new Position(
    1,
    'Clerical and Administrative Workers',
    'Clerical and Administrative Workers',
    Occupation.Clerical.displayName
  );
  static ClericalOfficeSupportWorkers = new Position(
    2,
    'Clerical and Office Support Workers',
    'Clerical and Office Support Workers',
    Occupation.Clerical.displayName
  );
  static GeneralClericalWorkers = new Position(3, 'General Clerical Workers', 'General Clerical Workers', Occupation.Clerical.displayName);
  static InquiryClerksReceptionists = new Position(4, 'Inquiry Clerks and Receptionists', 'Inquiry Clerks and Receptionists', Occupation.Clerical.displayName);
  static NumericalClerks = new Position(5, 'Numerical Clerks', 'Numerical Clerks', Occupation.Clerical.displayName);
  static OfficeManagerProgramAdministrators = new Position(
    6,
    'Office Managers and Program Administrators',
    'Office Managers and Program Administrators',
    Occupation.Clerical.displayName
  );
  static PersonalAssistantsSecretaries = new Position(
    7,
    'Personal Assistants and Secretaries',
    'Personal Assistants and Secretaries',
    Occupation.Clerical.displayName
  );

  static CarersAides = new Position(8, 'Carers and Aides', 'Carers and Aides', Occupation.Community.displayName);
  static HealthWelfareSupportWorkers = new Position(
    9,
    'Health and Welfare Support Workers',
    'Health and Welfare Support Workers',
    Occupation.Community.displayName
  );
  static HospitalityWorkers = new Position(10, 'Hospitality Workers', 'Hospitality Workers', Occupation.Community.displayName);
  static ProtectiveServiceWorkers = new Position(11, 'Protective Service Workers', 'Protective Service Workers', Occupation.Community.displayName);
  static SportsPersonalServiceWorkers = new Position(
    12,
    'Sports and Personal Service Workers',
    'Sports and Personal Service Workers',
    Occupation.Community.displayName
  );

  static Homemaker = new Position(13, 'Homemaker', 'Homemaker', Occupation.Homemaker.displayName);

  static CleanersLaundryWorkers = new Position(14, 'Cleaners and Laundry Workers', 'Cleaners and Laundry Workers', Occupation.Labourers.displayName);
  static ConstructionMiningLabourers = new Position(
    15,
    'Construction and Mining Labourers',
    'Construction and Mining Labourers',
    Occupation.Labourers.displayName
  );
  static FactoryProcessWorkers = new Position(16, 'Factory Process Workers', 'Factory Process Workers', Occupation.Labourers.displayName);
  static FarmForestryGardenWorkers = new Position(
    17,
    'Farm, Forestry and Garden Workers',
    'Farm, Forestry and Garden Workers',
    Occupation.Labourers.displayName
  );
  static FoodPreparationAssistants = new Position(18, 'Food Preparation Assistants', 'Food Preparation Assistants', Occupation.Labourers.displayName);
  static Labourers = new Position(19, 'Labourers', 'Labourers', Occupation.Labourers.displayName);

  static Machinery = new Position(20, 'Machinery', 'Machinery', Occupation.Machinery.displayName);
  static MobilePlantOperators = new Position(21, 'Mobile Plant Operators', 'Mobile Plant Operators', Occupation.Machinery.displayName);
  static RoadRailDrivers = new Position(22, 'Road and Rail Drivers', 'Road and Rail Drivers', Occupation.Machinery.displayName);
  static Storepersons = new Position(23, 'Storepersons', 'Storepersons', Occupation.Machinery.displayName);

  static MilitaryEnlisted = new Position(24, 'Military enlisted', 'Military enlisted', Occupation.Military.displayName);
  static MilitaryOfficer = new Position(25, 'Military officer', 'Military officer', Occupation.Military.displayName);

  static Professionals = new Position(
    26,
    'Accountants, Auditors and Company Secretaries',
    'Accountants, Auditors and Company Secretaries',
    Occupation.Professionals.displayName
  );
  static ArtsMediaProfessionals = new Position(27, 'Arts and Media Professionals', 'Arts and Media Professionals', Occupation.Professionals.displayName);
  static Aviation = new Position(28, 'Aviation', 'Aviation', Occupation.Professionals.displayName);
  static BusinessHumanResourceandMarketing = new Position(
    29,
    'Business, Human Resource and Marketing Professionals',
    'Business, Human Resource and Marketing Professionals',
    Occupation.Professionals.displayName
  );
  static DesignEngineeringScienceTransport = new Position(
    30,
    'Design, Engineering, Science and Transport Professionals',
    'Design, Engineering, Science and Transport Professionals',
    Occupation.Professionals.displayName
  );
  static DoctorVeterinarianHealthProfessionals = new Position(
    31,
    'Doctor, Veterinarian, Health Professionals',
    'Doctor, Veterinarian, Health Professionals',
    Occupation.Professionals.displayName
  );
  static EducationProfessionals = new Position(32, 'Education Professionals', 'Education Professionals', Occupation.Professionals.displayName);
  static FinancialBrokersDealersInvestmentAdvisers = new Position(
    33,
    'Financial Brokers and Dealers, and Investment Advisers',
    'Financial Brokers and Dealers, and Investment Advisers',
    Occupation.Professionals.displayName
  );
  static InformationCommunicationTechnologyProfessionals = new Position(
    34,
    'Information & Communication Technology Professionals',
    'Information & Communication Technology Professionals',
    Occupation.Professionals.displayName
  );
  static LegalSocialandWelfareProfessionals = new Position(
    35,
    'Legal, Social and Welfare Professionals',
    'Legal, Social and Welfare Professionals',
    Occupation.Professionals.displayName
  );

  static Retired = new Position(36, 'Retired', 'Retired', Occupation.Retired.displayName);

  static SalesAssistantsSalespersons = new Position(37, 'Sales Assistants and Salespersons', 'Sales Assistants and Salespersons', Occupation.Sales.displayName);
  static SalesRepresentativesAgents = new Position(38, 'Sales Representatives and Agents', 'Sales Representatives and Agents', Occupation.Sales.displayName);
  static SalesSupportWorkers = new Position(39, 'Sales Support Workers', 'Sales Support Workers', Occupation.Sales.displayName);

  static Student = new Position(40, 'Student', 'Student', Occupation.Student.displayName);

  static AutomotiveandEngineeringTradesWorkers = new Position(
    41,
    'Automotive and Engineering Trades Workers',
    'Automotive and Engineering Trades Workers',
    Occupation.Technicians.displayName
  );
  static ConstructionTradesWorkers = new Position(42, 'Construction Trades Workers', 'Construction Trades Workers', Occupation.Technicians.displayName);
  static ElectrotechnologyTelecommunicationsTradesWorkers = new Position(
    43,
    'Electrotechnology and Telecommunications Trades Workers',
    'Electrotechnology and Telecommunications Trades Workers',
    Occupation.Technicians.displayName
  );
  static EngineeringScienceTechnicians = new Position(
    44,
    'Engineering and Science Technicians',
    'Engineering and Science Technicians',
    Occupation.Technicians.displayName
  );
  static FoodTradesWorkers = new Position(45, 'Food Trades Workers', 'Food Trades Workers', Occupation.Technicians.displayName);
  static InformationCommunicationTechnologyTechnicians = new Position(
    46,
    'Information & Communication Technology Technicians',
    'Information & Communication Technology Technicians',
    Occupation.Technicians.displayName
  );
  static OtherTechniciansTradesWorkers = new Position(
    47,
    'Other Technicians and Trades Workers',
    'Other Technicians and Trades Workers',
    Occupation.Technicians.displayName
  );
  static SkilledAnimalHorticulturalWorkers = new Position(
    48,
    'Skilled Animal and Horticultural Workers',
    'Skilled Animal and Horticultural Workers',
    Occupation.Technicians.displayName
  );

  static getByOccupation = (occupation: string): Position[] => {
    return Object.values(Position)
      .filter((t) => t instanceof Position)
      .filter((t: Position) => t.occupation === occupation);
  };

  static getByName = (value: string | null): Position | undefined => {
    return Object.values(Position)
      .filter((t) => t instanceof Position)
      .find((t: Position) => t.name === value);
  };

  static getById = (value: number | null): Position | undefined => {
    return Object.values(Position)
      .filter((t) => t instanceof Position)
      .find((t: Position) => t.id === value);
  };
}

export class CorporateType extends Enumeration {
  static Private = new CorporateType(1, 'Private', 'Private');
  static Public = new CorporateType(2, 'Public', 'Public');

  static getArray = (): CorporateType[] => [CorporateType.Public, CorporateType.Private];

  static getById = (status: number | null): CorporateType | undefined => {
    return Object.values(CorporateType)
      .filter((t) => t instanceof CorporateType)
      .find((t: CorporateType) => t.id === status);
  };
}

export class RoleDefinition extends Enumeration {
  static Adviser = new RoleDefinition(1, 'Adviser', 'Adviser');
  static Beneficiary = new RoleDefinition(2, 'Beneficiary', 'Beneficiary');
  static Contact = new RoleDefinition(3, 'Contact', 'Contact');
  static Trustee = new RoleDefinition(4, 'Trustee', 'Trustee');
  static Relative = new RoleDefinition(5, 'Relative', 'Relative');
  static AccountHolder = new RoleDefinition(6, 'Account Holder', 'Account Holder');
  static TrustSettler = new RoleDefinition(7, 'Trust Settler', 'Trust Settler');
  static AuthorisedSignatory = new RoleDefinition(9, 'Authorised Signatory', 'Authorised Signatory');
  static CompanyOfficer = new RoleDefinition(10, 'Company Officer', 'Company Officer');
  static Chairperson = new RoleDefinition(12, 'Chairperson', 'Chairperson');
  static Member = new RoleDefinition(13, 'Member', 'Member');
  static Shareholder = new RoleDefinition(16, 'Shareholder', 'Shareholder');
  static AFSLManager = new RoleDefinition(17, 'AFSL Manager', 'AFSL Manager');
  static PracticeManager = new RoleDefinition(18, 'Practice Manager', 'Practice Manager');
  static Group = new RoleDefinition(19, 'Group', 'Group');
  static Accountant = new RoleDefinition(20, 'Accountant', 'Accountant');
  static CRMEmail = new RoleDefinition(21, 'CRM email', 'CRM email');

  static getById = (roleId: number | null): RoleDefinition | undefined => {
    return Object.values(RoleDefinition)
      .filter((t) => t instanceof RoleDefinition)
      .find((t: RoleDefinition) => t.id === roleId);
  };
}

export class AttachmentType extends Enumeration {
  static MdaAgreement = new AttachmentType(2, 'MdaAgreement', 'Mda Agreement');
  static AustralianPassport = new AttachmentType(3, 'AustralianPassport', 'Australian Passport');
  static AustralianCitizenshipCertificate = new AttachmentType(5, 'AustralianCitizenshipCertificate', 'Australian Citizenship Certificate');
  static AustralianTaxOfficeNotice = new AttachmentType(6, 'AustralianTaxOfficeNotice', 'Australian Tax Office Notice');
  static BirthCertificateAustralianOrForeign = new AttachmentType(7, 'BirthCertificateAustralianOrForeign', 'Birth Certificate Australian Or Foreign');
  static MedicareCard = new AttachmentType(8, 'MedicareCard', 'Medicare Card');
  static CentrelinkPensionCard = new AttachmentType(9, 'CentrelinkPensionCard', 'Centrelink Pension Card');
  static CurrentAustralianDriversLicense = new AttachmentType(10, 'CurrentAustralianDriversLicense', 'Current Australian Drivers License');
  static CurrentForeignDriversLicense = new AttachmentType(11, 'CurrentForeignDriversLicense', 'Current Foreign Drivers License');
  static CurrentForeignPassport = new AttachmentType(12, 'CurrentForeignPassport', 'Current Foreign Passport');
  static OtherBankStatement = new AttachmentType(13, 'OtherBankStatement', 'Other Bank Statement');
  static ProofOfAge = new AttachmentType(14, 'ProofOfAge', 'Proof Of Age');
  static PublicUtilityRecordBillSighted = new AttachmentType(15, 'PublicUtilityRecordBillSighted', 'Public Utility Record Bill Sighted');

  static Roa = new AttachmentType(29, 'Roa', 'Roa');
  static Report = new AttachmentType(31, 'Report', 'Report');
  static SuperSimplifierPension = new AttachmentType(44, 'SuperSimplifierPension', 'Super Simplifier Pension');
  static SuperSimplifierSuper = new AttachmentType(45, 'SuperSimplifierSuper', 'Super Simplifier Super');
  static ClientProposal = new AttachmentType(49, 'ClientProposal', 'Client Proposal');
  static Email = new AttachmentType(67, 'Email', 'Email');
  static OngoingFeeConsent = new AttachmentType(68, 'OngoingFeeConsent', 'Ongoing Fee Consent');
  static MacQ = new AttachmentType(23, 'MacQ', 'MacQ');

  static getById = (roleId: number | null): AttachmentType | undefined => {
    return Object.values(AttachmentType)
      .filter((t) => t instanceof AttachmentType)
      .find((t: AttachmentType) => t.id === roleId);
  };
}
