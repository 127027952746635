import { combineReducers } from '@reduxjs/toolkit';
import { configurationReducer } from '../configuration/store';
import { overviewSlice } from '../overview/store/slice';
import { commonSlice } from './common';
import { rebalanceSlice } from './rebalance';
import { PortfolioState } from './types';

export const portfolioReducer = combineReducers<PortfolioState>({
  overview: overviewSlice.reducer,
  configuration: configurationReducer,
  common: commonSlice.reducer,
  rebalance: rebalanceSlice.reducer,
});
