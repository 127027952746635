import { createSelector } from '@reduxjs/toolkit';
import { selectAccounts, selectPortfolios } from '../../common/store/selectors';
import { ClientAccount, ClientPortfolio } from '../../common/store/types';
import { selectClientState } from '../../store/selectors';
import { AssetClassPerformanceReportViewModel, AssetGroup, AssetGroupItem, CalculatedHoldingsForPortfolioItem, ClientPortfolioSelectModel } from './types';

export const selectDashboard = createSelector(selectClientState, (clientState) => clientState.dashboard);
export const selectParameters = createSelector(selectDashboard, (dashboard) => dashboard.parameters);
export const selectPortfolio = createSelector(selectParameters, (parameters) => parameters.selectedPortfolio ?? null);
export const selectMonthlyPerformanceChartResults = createSelector(selectDashboard, (dashboard) => dashboard.monthlyPerformanceChartResult);
export const selectClientPortfolioDetailResults = createSelector(selectDashboard, (dashboard) => dashboard.clientPortfolioDetailResult);
export const selectRecentTransactionsListResults = createSelector(selectDashboard, (dashboard) => dashboard.recentTransactionsListResult);
export const selectHoldings = createSelector(selectClientPortfolioDetailResults, (portfolioDetails) => portfolioDetails?.holdings);
export const selectHoldingItems = createSelector(selectHoldings, (holdings) => holdings?.items || []);

export const selectSelectedHoldingItems = createSelector(selectHoldingItems, selectParameters, (holdingItems, parameters) => {
  let allItems: CalculatedHoldingsForPortfolioItem[] = [];

  if (!!parameters.selectedHolding && holdingItems.length) {
    // get selected holding
    allItems = holdingItems.find((i) => i.groupName === parameters.selectedHolding)?.items || [];
  } else {
    // We are selecting all assets
    // get all the items from all the holdings
    allItems = Array.prototype.concat.apply(
      [],
      holdingItems.map((i) => i.items)
    );
  }

  const result: CalculatedHoldingsForPortfolioItem[] = [
    ...allItems
      .reduce((previousValue, value) => {
        const key = value.securityName;

        const item =
          previousValue.get(key) ||
          Object.assign({}, value, {
            currentValue: 0,
            units: 0,
          });

        item.currentValue += value.currentValue;
        item.units += value.units;

        return previousValue.set(key, item);
      }, new Map())
      .values(),
  ];

  return result;
});

export const selectPortfoliosSelectModels = createSelector(
  selectPortfolios,
  selectAccounts,
  (clientPortfolios: ClientPortfolio[], clientAccounts: ClientAccount[]) => {
    const selectModels: ClientPortfolioSelectModel[] = clientPortfolios?.map((i) => {
      const accounts =
        i.investmentProgramId !== null
          ? clientAccounts.filter((clientAccount) => clientAccount.investmentProgramIds.includes(i.investmentProgramId ?? 0))
          : clientAccounts;

      return {
        investmentProgramId: i.investmentProgramId,
        investmentServiceName: i.investmentServiceName,
        inceptionDate: i.inceptionDate,
        accountIdList: accounts.map((a) => a.accountId as number),
      };
    });

    return selectModels;
  }
);

export const selectPortfolioValue = createSelector(selectMonthlyPerformanceChartResults, (results) => results?.portfolioValue ?? 0);
export const selectChangeInValue = createSelector(selectMonthlyPerformanceChartResults, (results) => results?.changeInValue ?? 0);
export const selectChangeInValuePercentage = createSelector(selectMonthlyPerformanceChartResults, (results) => results?.changeInValuePercentage ?? 0);

export const selectAssetGroupResults = createSelector(
  selectClientPortfolioDetailResults,
  selectChangeInValue,
  selectChangeInValuePercentage,
  (results, changeInValue, changeInValuePercentage) => {
    // map the api result to a simple display dto
    let groups: AssetGroup[] = [];
    if (results?.performance) {
      const holdings = results.holdings?.items ?? [];
      groups = results.performance?.map((p: AssetClassPerformanceReportViewModel) => {
        const holdingRecord = holdings.find((h) => h.groupName === p.assetClass);
        const holdingRecordItems = (holdingRecord?.items ?? []).filter((h) => h.units !== 0);

        const topTenItems = holdingRecordItems.sort((a, b) => b.currentValue - a.currentValue).slice(0, 10);

        const group: AssetGroup = {
          name: p.assetClass,
          value: holdingRecord?.currentValue ?? 0,
          gainValue: p.totalReturnAmount,
          gainPercentage: p.totalReturnPercentage,
          items: topTenItems.map((i: CalculatedHoldingsForPortfolioItem) => {
            const groupItem: AssetGroupItem = {
              securityName: i.securityName,
              securityCode: i.securityCode,
              units: i.units,
              unitPrice: i.unitPrice,
              value: i.currentValue,
            };
            return groupItem;
          }),
        };

        return group;
      });

      // add all assets item
      const items =
        holdings.length > 1
          ? holdings
              .map((h) => h.items)
              ?.reduce((a, b) => a.concat(b))
              .sort((a, b) => b.currentValue - a.currentValue)
              .slice(0, 10)
              .map((i: CalculatedHoldingsForPortfolioItem) => {
                const groupItem: AssetGroupItem = {
                  securityName: i.securityName,
                  securityCode: i.securityCode,
                  units: i.units,
                  unitPrice: i.unitPrice,
                  value: i.currentValue,
                };
                return groupItem;
              })
          : [];

      groups.unshift({
        name: 'All Assets',
        value: results.holdings?.marketValue || 0,
        gainValue: changeInValue,
        gainPercentage: changeInValuePercentage,
        items,
      });
    }

    return groups;
  }
);
