import { Enumeration } from '../types';

export class AssetClassEnum extends Enumeration {
  static Unallocated = new AssetClassEnum(0, 'Unallocated', 'Unallocated');
  static Alternatives = new AssetClassEnum(1, 'Alternatives', 'Alternatives');
  static AustralianCash = new AssetClassEnum(2, 'AustralianCash', 'Australian Cash');
  static AustralianFixedIncome = new AssetClassEnum(3, 'AustralianFixedIncome', 'Australian Fixed Income');
  static AustralianREITs = new AssetClassEnum(4, 'AustralianREITs', 'Australian REITs');
  static AustralianShares = new AssetClassEnum(5, 'AustralianShares', 'Australian Shares');
  static Collectibles = new AssetClassEnum(6, 'Collectibles', 'Collectibles');
  static Commodity = new AssetClassEnum(7, 'Commodity', 'Commodity');
  static Currency = new AssetClassEnum(8, 'Currency', 'Currency');
  static DirectProperty = new AssetClassEnum(9, 'DirectProperty', 'Direct Property');
  static Infrastructure = new AssetClassEnum(10, 'Infrastructure', 'Infrastructure');
  static InternationalCash = new AssetClassEnum(11, 'InternationalCash', 'International Cash');
  static InternationalFixedIncome = new AssetClassEnum(12, 'InternationalFixedIncome', 'International Fixed Income');
  static InternationalListedProperty = new AssetClassEnum(13, 'InternationalListedProperty', 'International Listed Property');
  static InternationalShares = new AssetClassEnum(14, 'InternationalShares', 'International Shares');
  static Mixed = new AssetClassEnum(15, 'Mixed', 'Mixed');
  static Other = new AssetClassEnum(16, 'Other', 'Other');
  static Protection = new AssetClassEnum(17, 'Protection', 'Protection');
  static TermDeposit = new AssetClassEnum(19, 'TermDeposit', 'Term Deposit');
  static AtCall = new AssetClassEnum(20, 'AtCall', 'At Call');
  static Deposit = new AssetClassEnum(37, 'Deposit', 'Deposit');
  static DevelopedMarketEquities = new AssetClassEnum(38, 'DevelopedMarketEquities', 'Developed Market Equities');
  static EmergingMarketEquities = new AssetClassEnum(39, 'EmergingMarketEquities', 'Emerging Market Equities');
  static AtRiskDebt = new AssetClassEnum(40, 'AtRiskDebt', 'At Risk Debt');
  static SecureDebt = new AssetClassEnum(41, 'SecureDebt', 'Secure Debt');
  static Satellite = new AssetClassEnum(44, 'Satellite', 'Satellite');
  static Hedge = new AssetClassEnum(45, 'Hedge', 'Hedge');
  static AsiaExJapanEquities = new AssetClassEnum(46, 'AsiaExJapanEquities', 'Asia ex Japan Equities');
  static InternationalExUSEquities = new AssetClassEnum(47, 'InternationalExUSEquities', 'International ex US Equities');
  static USEquities = new AssetClassEnum(48, 'USEquities', 'US Equities');
  static HedgeFundsAndAlternativies = new AssetClassEnum(49, 'HedgeFundsAndAlternativies', 'Hedge Funds and Alternativies');
  static HighYieldFixedInterest = new AssetClassEnum(50, 'HighYieldFixedInterest', 'High Yield Fixed Interest');
  static InternationalREITs = new AssetClassEnum(51, 'InternationalREITs', 'International REITs');
  static Property = new AssetClassEnum(52, 'Property', 'Property');
  static Equity = new AssetClassEnum(53, 'Equity', 'Equity');
  static FixedInterest = new AssetClassEnum(54, 'FixedInterest', 'Fixed Interest');
  static CommodityEnergy = new AssetClassEnum(55, 'CommodityEnergy', 'Commodity/Energy');
  static AtCall2 = new AssetClassEnum(56, 'AtCall2', 'AtCall');
  static AUD = new AssetClassEnum(57, 'AUD', 'AUD');
  static MoneyMarket = new AssetClassEnum(58, 'MoneyMarket', 'Money Market');
  static MixedAsset = new AssetClassEnum(59, 'MixedAsset', 'Mixed Asset');
  static RealIncome = new AssetClassEnum(60, 'RealIncome', 'Real Income');

  static getById = (id: number | null | undefined): AssetClassEnum | undefined => {
    return Object.values(AssetClassEnum)
      .filter((t) => !!t['name'])
      .find((t: AssetClassEnum) => t.id === id);
  };

  static getByName = (name: string): AssetClassEnum | undefined => {
    return Object.values(AssetClassEnum)
      .filter((t) => t instanceof AssetClassEnum)
      .find((t: AssetClassEnum) => t.name.toLowerCase() === name.toLowerCase());
  };

  static getAll = (): AssetClassEnum[] => {
    return Object.values(AssetClassEnum)
      .filter((t) => !!t['name'])
      .map((val) => {
        return { id: val.id, name: val.name, displayName: val.displayName };
      });
  };
}
