import { combineReducers } from '@reduxjs/toolkit';
import { linkedAccountsSlice } from '../accounts/store';
import { constraintsSlice } from '../constraints/store';
import { detailsSlice } from '../details/store/slice';
import { targetsSlice } from '../targets/store/slice';
import { activationSlice } from './activation';
import { ConfigurationState } from './types';

export const configurationReducer = combineReducers<ConfigurationState>({
  details: detailsSlice.reducer,
  targets: targetsSlice.reducer,
  constraints: constraintsSlice.reducer,
  linkedAccounts: linkedAccountsSlice.reducer,
  activation: activationSlice.reducer,
});
