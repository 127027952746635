import { Modal, TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { DateRange } from '../../store';
import { DateTimeFormat, getLocalDateTime } from '../utils';
import DateRangePickerModal from './DateRangePickerModal';

interface Props {
  dateRange: DateRange;
  inceptionDate?: string;
  isDateOnly?: boolean;
  setDateRange: (dateRange: DateRange) => void;
}

const useStyles = makeStyles(() => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalTextFieldDateRange: {
    '& input': {
      minWidth: '166px',
    },
    // apply style to firefox only
    '@supports (-moz-appearance:none)': {
      '& input': {
        minWidth: '176px',
      },
    },
  },
}));

export default function DateRangePicker(props: Props): JSX.Element {
  const classes = useStyles();
  const [openModal, setOpenModal] = React.useState(false);
  const isDateOnly = props.isDateOnly ?? false;

  const dateRangeDisplay = isDateOnly
    ? `${getLocalDateTime(props.dateRange.dateTo, DateTimeFormat.Short)}`
    : `${getLocalDateTime(props.dateRange.dateFrom, DateTimeFormat.Short)} - ${getLocalDateTime(props.dateRange.dateTo, DateTimeFormat.Short)}`;

  const dateLabel = isDateOnly ? 'Date' : 'Dates';

  const handleOpenModal = () => {
    setOpenModal(true);
  };
  const handleCloseModal = () => {
    setOpenModal(false);
  };

  return (
    <div>
      <div style={{ position: 'relative', paddingRight: '10px' }} onClick={handleOpenModal}>
        <TextField
          label={dateLabel}
          variant="outlined"
          fullWidth
          InputProps={{ className: isDateOnly ? undefined : classes.modalTextFieldDateRange }}
          value={dateRangeDisplay}
        />
      </div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openModal}
        onClose={handleCloseModal}
      >
        <div>
          <DateRangePickerModal {...props} isDateOnly={isDateOnly} handleCloseModal={handleCloseModal} />
        </div>
      </Modal>
    </div>
  );
}
