import { Add as AddIcon, Cancel as CancelIcon, Delete as DeleteIcon, Edit as EditIcon, Save as SaveIcon } from '@mui/icons-material';
import { Box, IconButton, LinearProgress, Paper, Stack, styled, Typography } from '@mui/material';
import { GridToolbarContainer, GridValueGetterParams } from '@mui/x-data-grid';
import {
  DataGridPro,
  GridActionsCellItem,
  GridColumns,
  GridEditRowProps,
  gridEditRowsStateSelector,
  GridPreProcessEditCellProps,
  GridRenderCellParams,
  GridRowModes,
  GridRowParams,
  MuiBaseEvent,
  MuiEvent,
  useGridApiRef,
} from '@mui/x-data-grid-pro';
import React, { useCallback, useEffect, useState } from 'react';
import PulseLoader from 'react-spinners/PulseLoader';
import { LoadingProgress } from 'src/common/store/types';
import * as yup from 'yup';
import { theme } from '../../../../../../../themes';
import { ApprovedProduct } from '../../../store/common';
import { ConstraintNameEnum, ConstraintTypeEnum, SaveConstraintItem, SecurityConstraint } from '../store';

const StyledBox = styled(Box)(() => ({
  width: '100%',
  '& .MuiDataGrid-cell--editing': {
    backgroundColor: 'rgb(255,215,115, 0.19)',
    color: '#1a3e72',
    '& .MuiInputBase-root': {
      height: '100%',
    },
  },
  '& .Mui-error': {
    backgroundColor: `#FFCCCC`,
    color: 'red',
    borderRadius: '4px',
    borderColor: 'red',
    borderStyle: 'solid',
    borderWidth: '1px',
  },
  '& .MuiDataGrid-editInputCell': {
    borderWidth: '2px',
  },
  '& input[type=number]': {
    textAlign: 'right',
    paddingRight: '0px',
  },
  '& .unallocated-row': {
    backgroundColor: `#FFCCCC`,
    color: 'red',
  },
  '& .MuiDataGrid-toolbarContainer': {
    justifyContent: 'flex-end',
  },
}));

export interface StrategicAllocationTableProps {
  items: SecurityConstraint[];
  itemLoadingProgress: LoadingProgress;
  securities: ApprovedProduct[];
  securitiesLoadingProgress: LoadingProgress;
  onSave?: (constraintItems: SaveConstraintItem[]) => Promise<void>;
  savingProgress: LoadingProgress;
  hideEditControls?: boolean;
}

interface DataRow extends Partial<SecurityConstraint> {
  id: string;
}

interface DataRowSecurity {
  componentId: number;
  name: string;
  code: string;
}

export const ConstraintsTable = (props: StrategicAllocationTableProps): JSX.Element => {
  const { items, itemLoadingProgress, onSave, securities, securitiesLoadingProgress, savingProgress, hideEditControls } = props;
  const [dataRows, setDataRows] = useState<DataRow[]>([]);
  const [editMode, setEditMode] = useState<boolean>(false);

  const apiRef = useGridApiRef();

  const validationSchema = yup.object().shape({
    id: yup.string().required(),
    securityId: yup.number().required(),
    constraintId: yup.number().required(),
    constraintTypeId: yup.number().required(),
    substituteSecurityId: yup
      .number()
      .nullable()
      .test('test-substituteSecurityId', 'Not a valid substitute', function (this: yup.TestContext, substituteSecurityId: number | null | undefined): boolean {
        if (this.parent.constraintTypeId !== ConstraintTypeEnum.Substitute.id) {
          return true;
        }

        return substituteSecurityId !== -1 && substituteSecurityId !== this.parent.securityId;
      }),
  });

  useEffect(() => {
    resetDataRows();
  }, [items]);

  const resetDataRows = useCallback(() => {
    setDataRows(
      items.map((i, index) => ({
        id: index.toString(),

        investmentServiceConstraintId: i.investmentServiceConstraintId,
        securityId: i.securityId,
        securityName: i.securityName,
        securityCode: i.securityCode,
        constraintId: i.constraintId,
        constraintTypeId: i.constraintTypeId,
        substituteSecurityId: i.substituteSecurityId,
        substituteSecurityName: i.substituteSecurityName,
        substituteSecurityCode: i.substituteSecurityCode,
      }))
    );
  }, [items]);

  const columns: GridColumns = [
    {
      field: 'securityId',
      headerName: 'CONSTRAINT',
      type: 'singleSelect',
      flex: 2,
      editable: true,
      sortable: false,
      renderCell: (params: GridRenderCellParams<DataRowSecurity>) => {
        const security = securities.find((p) => p.componentId === params.row.securityId);

        return (
          <div>
            <Typography
              variant="h5"
              color="primary"
              style={{
                letterSpacing: '1px',
              }}
            >
              {security?.name}
            </Typography>
            <Typography color={'textSecondary'} variant={'h6'} align="left">
              {security?.code}
            </Typography>
          </div>
        );
      },
      valueGetter: (params: GridValueGetterParams) => {
        return params.value || '';
      },
      valueOptions: (params) => getSecurityValues(params.id?.toString()),
      preProcessEditCellProps: async (params: GridPreProcessEditCellProps) => {
        const valid = validationSchema.fields?.securityId.isValidSync(params.props.value);
        return { ...params.props, error: !valid };
      },
    },
    {
      field: 'constraintId',
      headerName: 'TYPE',
      type: 'singleSelect',
      flex: 1,
      editable: true,
      sortable: false,
      renderCell: (params: GridRenderCellParams<number>) => {
        return <div>{ConstraintNameEnum.getById(params.value)?.displayName}</div>;
      },
      valueOptions: [...ConstraintNameEnum.getAll()].map((c) => ({ value: c.id, label: c.displayName })).sort((a, b) => a.label.localeCompare(b.label)),
    },
    {
      field: 'constraintTypeId',
      headerName: 'BEHAVIOUR',
      type: 'singleSelect',
      flex: 1,
      editable: true,
      sortable: false,
      renderCell: (params: GridRenderCellParams<number>) => {
        return <div>{ConstraintTypeEnum.getById(params.value)?.displayName}</div>;
      },
      valueOptions: [...ConstraintTypeEnum.getAll()].map((c) => ({ value: c.id, label: c.displayName })).sort((a, b) => a.label.localeCompare(b.label)),
    },
    {
      field: 'substituteSecurityId',
      headerName: 'CONSTRAINT',
      type: 'singleSelect',
      flex: 2,
      editable: true,
      sortable: false,
      renderCell: (params: GridRenderCellParams<DataRowSecurity>) => {
        const security = securities.find((p) => p.componentId === params.row.substituteSecurityId);

        return (
          <div>
            <Typography
              variant="h5"
              color="primary"
              style={{
                letterSpacing: '1px',
              }}
            >
              {security?.name || 'none'}
            </Typography>
            <Typography color={'textSecondary'} variant={'h6'} align="left">
              {security?.code}
            </Typography>
          </div>
        );
      },
      valueOptions: (params) => getSubstitutionValues(params.id?.toString()),
      preProcessEditCellProps: async (params: GridPreProcessEditCellProps) => {
        const editModel = gridEditRowsStateSelector(apiRef.current.state)[params.id];
        const validationModel = {
          id: params.id.toString(),
          securityId: editModel.securityId.value,
          constraintId: editModel.constraintId.value,
          constraintTypeId: editModel.constraintTypeId.value,
          substituteSecurityId: params.props.value,
        };

        return validationSchema
          .validateAt('substituteSecurityId', validationModel)
          .then(() => {
            return { ...params.props, error: false };
          })
          .catch((e) => {
            return { ...params.props, error: true, message: e.message };
          });
      },
      valueGetter: (params: GridValueGetterParams) => {
        const editModel = gridEditRowsStateSelector(apiRef.current.state)[params.id];
        const constraintTypeId = !!editModel ? editModel.constraintTypeId.value : params.row.constraintTypeId;
        const substituteSecurityId = !!editModel ? editModel.substituteSecurityId.value : params.row.substituteSecurityId;
        return constraintTypeId === ConstraintTypeEnum.Substitute.id ? substituteSecurityId || -1 : -1;
      },
    },
    {
      field: 'actions',
      type: 'actions',
      sortable: false,
      width: 80,
      getActions: (params) => {
        return [
          <GridActionsCellItem key="1" icon={<DeleteIcon />} label="Delete" onClick={deleteRow(params.id.toString())} disabled={savingProgress.isLoading} />,
        ];
      },
    },
  ];

  const getSecurityValues = React.useCallback(
    (id: string | undefined) => {
      const models = gridEditRowsStateSelector(apiRef.current.state);

      const securityIdsInUse = Object.entries(models)
        .filter((v) => v[0] !== id)
        .map((v) => v[1].securityId.value);
      return [...securities]
        .filter((s) => !securityIdsInUse.includes(s.componentId))
        .map((p) => ({ value: p.componentId, label: `${p.name} - ${p.code}` }))
        .sort((a, b) => a.label.localeCompare(b.label));
    },
    [apiRef, securities]
  );

  const getSubstitutionValues = React.useCallback(
    (id: string | undefined) => {
      const models = gridEditRowsStateSelector(apiRef.current.state);

      if (id !== undefined) {
        const constraintTypeId = models[id].constraintTypeId.value;

        if (constraintTypeId === ConstraintTypeEnum.Substitute.id) {
          return [
            { value: -1, label: 'none' },
            ...[...securities].map((p) => ({ value: p.componentId, label: `${p.name} - ${p.code}` })).sort((a, b) => a.label.localeCompare(b.label)),
          ];
        }
      }
      return [{ value: -1, label: 'none' }];
    },
    [apiRef, securities]
  );

  const handleAddClick = React.useCallback(() => {
    const id = apiRef.current.getAllRowIds().length.toString();

    const newRow: DataRow = {
      id,
      securityId: '',
      securityName: '',
      securityCode: '',
      constraintId: ConstraintNameEnum.DoNotBuy.id,
      constraintTypeId: ConstraintTypeEnum.ProRata.id,
      substituteSecurityId: -1,
      substituteSecurityName: '',
      substituteSecurityCode: '',
    };

    apiRef.current.updateRows([{ ...newRow, id, isNew: true }]);
    apiRef.current.setRowMode(id, GridRowModes.Edit);
    apiRef.current.setEditCellValue({ id, field: 'securityId', value: '' });
    apiRef.current.setEditCellValue({ id, field: 'constraintId', value: newRow.constraintId });
    apiRef.current.setEditCellValue({ id, field: 'constraintTypeId', value: newRow.constraintTypeId });
    apiRef.current.setEditCellValue({ id, field: 'substituteSecurityId', value: newRow.substituteSecurityId });

    // Wait for the grid to render with the new row
    setTimeout(() => {
      apiRef.current.scrollToIndexes({
        rowIndex: apiRef.current.getRowsCount() - 1,
      });
      apiRef.current.setCellFocus(-1, 'name');
    });
  }, [apiRef, securities]);

  const handleEditClick = React.useCallback(() => {
    setEditMode(true);

    apiRef.current.getAllRowIds().forEach((id) => {
      apiRef.current.setRowMode(id, GridRowModes.Edit);
    });
  }, [apiRef]);

  const handleCancelClick = React.useCallback(() => {
    apiRef.current.getAllRowIds().forEach((id) => {
      apiRef.current.setRowMode(id, GridRowModes.View);
      const row = apiRef.current.getRow(id);
      if (!!row && row.isNew) {
        apiRef.current.updateRows([{ id, _action: 'delete' }]);
      }
    });

    setEditMode(false);
    resetDataRows();
  }, [apiRef, items]);

  const validateRows = (models: { [x: string]: GridEditRowProps }) => {
    const errorModel = Object.entries(models).find(
      (m) => m[1].securityId.error || m[1].constraintId.error || m[1].constraintTypeId.error || m[1].substituteSecurityId.error
    );
    return !errorModel;
  };

  const handleSave = useCallback(async () => {
    if (!!onSave) {
      const editModels = { ...gridEditRowsStateSelector(apiRef.current.state) };

      if (validateRows(editModels)) {
        handleCancelClick();
        await onSave([
          ...Object.entries(editModels).map((m) => ({
            securityId: m[1].securityId.value,
            constraintId: m[1].constraintId.value,
            constraintTypeId: m[1].constraintTypeId.value,
            substituteSecurityId: m[1].constraintTypeId.value === ConstraintTypeEnum.Substitute.id ? m[1].substituteSecurityId.value : null,
          })),
        ]);
      }
    }
  }, [onSave, apiRef.current]);

  const deleteRow = React.useCallback(
    (id: string) => () => {
      apiRef.current.setRowMode(id, GridRowModes.View);
      apiRef.current.updateRows([{ id, _action: 'delete' }]);
    },
    [apiRef, dataRows]
  );

  return (
    <>
      <Typography variant="h4" style={{ paddingBottom: '10px', paddingTop: '10px' }}>
        Security Constraints
      </Typography>
      <div style={{ height: '400px' }}>
        <Paper elevation={3}>
          <StyledBox>
            <DataGridPro
              editMode="row"
              apiRef={apiRef}
              rows={savingProgress.isLoading || securities.length === 0 ? [] : dataRows}
              columns={columns}
              columnVisibilityModel={{
                actions: editMode,
              }}
              pageSize={items.length}
              disableColumnMenu
              disableColumnReorder={true}
              autoHeight
              components={{
                LoadingOverlay: LinearProgress,
                Toolbar: () => {
                  return (
                    <GridToolbarContainer>
                      <Stack direction="row" alignItems="center">
                        {savingProgress.isLoading && (
                          <div className="LoadingIndicator" style={{ padding: '7px' }}>
                            <PulseLoader size="9px" margin="5px" color={theme.palette.grey[400]} />
                          </div>
                        )}
                        {!hideEditControls && editMode && !savingProgress.isLoading && (
                          <IconButton disableFocusRipple disableRipple data-testid="addButton" onClick={handleAddClick} color={'primary'}>
                            <AddIcon style={{ height: '24px' }} />
                          </IconButton>
                        )}
                        {!hideEditControls && !editMode && (
                          <IconButton disableFocusRipple disableRipple data-testid="editButton" onClick={handleEditClick} color={'primary'}>
                            <EditIcon style={{ height: '24px' }} />
                          </IconButton>
                        )}
                        {!hideEditControls && editMode && !savingProgress.isLoading && (
                          <IconButton disableFocusRipple disableRipple data-testid="cancelButton" onClick={handleCancelClick} color={'primary'}>
                            <CancelIcon style={{ height: '24px' }} />
                          </IconButton>
                        )}
                        {!hideEditControls && editMode && !savingProgress.isLoading && (
                          <IconButton disableFocusRipple disableRipple data-testid="saveButton" onClick={handleSave} color={'primary'}>
                            <SaveIcon style={{ height: '24px' }} />
                          </IconButton>
                        )}
                      </Stack>
                    </GridToolbarContainer>
                  );
                },
              }}
              loading={itemLoadingProgress.isLoading || securitiesLoadingProgress.isLoading || savingProgress.isLoading}
              onRowEditStop={(_params: GridRowParams, event: MuiEvent<MuiBaseEvent>) => {
                // we don't want to end editting unless they click save
                event.defaultMuiPrevented = true;
              }}
              onRowEditStart={(_params: GridRowParams, event: MuiEvent<MuiBaseEvent>) => {
                // we don't want to end editting unless they click edit
                event.defaultMuiPrevented = true;
              }}
            />
          </StyledBox>
        </Paper>
      </div>
    </>
  );
};
