import { Backdrop, Fade, Grid, Modal, Paper, Typography } from '@mui/material';
import { Field, Form, Formik } from 'formik';
import React from 'react';
import * as yup from 'yup';
import { LoadingProgress } from '../../../store/types';
import { EditCancelSaveButtons, FormikTextField, Mode } from '../../formik';
import { AccountInstitutionType, UnlistedAccount } from '../types';
export interface UnlistedAccountProps {
  handleCloseModal: () => void;
  onSave: (details: UnlistedAccount) => void;
  saveProgress?: LoadingProgress;
  isOpen: boolean;
}

export function WO2UnlistedAccount({ handleCloseModal, isOpen, onSave, saveProgress }: UnlistedAccountProps): JSX.Element {
  const initialFormValues: UnlistedAccount = {
    accountName: '',
    accountNumber: '',
  };

  return (
    <Modal
      aria-labelledby="Create Unlisted Account"
      aria-describedby="transition-modal-description"
      open={isOpen}
      onClose={() => {
        handleCloseModal();
      }}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{ timeout: 500 }}
    >
      <Fade in={isOpen}>
        <Paper
          elevation={0}
          style={{
            minWidth: '60vw',
            maxWidth: '100vw',
            minHeight: '20vh',
            maxHeight: '100vh',
            padding: '40px 40px',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        >
          <Formik<UnlistedAccount>
            initialValues={initialFormValues}
            onSubmit={async (details: UnlistedAccount) => {
              onSave(details);
            }}
            enableReinitialize={true}
            validationSchema={yup.object({
              accountName: yup.string().required('Account name required'),
              accountNumber: yup.string().nullable().required('Account code required'),
            })}
          >
            {() => (
              <Form>
                <fieldset style={{ border: 'none', padding: '0' }}>
                  <div style={{ display: 'flex', marginBottom: '2rem', height: '3rem', alignItems: 'center', justifyContent: 'flex-start' }}>
                    <img
                      src={`/images/institution/${AccountInstitutionType.Unlisted.id}.png`}
                      alt={`Institution-${AccountInstitutionType.Unlisted.displayName}`}
                      className={`iconImage iconImage${AccountInstitutionType.Unlisted.displayName}.replace(/ /g, '')}`}
                      height="48"
                    />
                    <Typography variant="h3" style={{ marginLeft: '20px ' }}>
                      New Unlisted Account
                    </Typography>
                  </div>
                  <Grid container>
                    <Grid item xs={6} style={{ paddingRight: '20px' }}>
                      <Grid item xs={12} style={{ paddingBottom: '20px' }}>
                        <Field name="accountName" component={FormikTextField} label="ACCOUNT NAME" fullWidth></Field>
                      </Grid>
                    </Grid>
                    <Grid item xs={6}>
                      <Grid item xs={12} style={{ paddingBottom: '20px' }}>
                        <Field name="accountNumber" component={FormikTextField} label="ACCOUNT CODE" fullWidth></Field>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} style={{ paddingTop: '20px' }}>
                      <EditCancelSaveButtons mode={Mode.CancelSave} handleCancelClick={handleCloseModal} saveProgress={saveProgress}></EditCancelSaveButtons>
                    </Grid>
                  </Grid>
                </fieldset>
              </Form>
            )}
          </Formik>
        </Paper>
      </Fade>
    </Modal>
  );
}
