import { Box, Button, Grid, Typography } from '@mui/material';
import { default as React, useEffect, useState } from 'react';
import { LoadingProgress } from '../../../../../../../common/store/types';
import { DateTimeFormat, getLocalDateTimeNow } from '../../../../../../../common/utils/dateFunctions';
import { ArchiveDialog } from '../../common/components/archiveDialog';
import { Props } from '../container';
import { VersionInfo } from './../types';
import { getConfigurations, getConstraints, getDetails, getPortfolioAssetClasses, getStrategicAllocations } from './services';
import { Version } from './version';

export const Review = ({ clientId, investmentService, archivePortfolio, archivingProgress }: Props): JSX.Element => {
  const emptyVersion: VersionInfo = {
    versionName: '',
    details: null,
    constraints: [],
    configurations: [],
    strategicAllocations: null,
    assetClasses: null,
  };

  const [archiveDialogOpen, setArchiveDialogOpen] = useState<boolean>(false);

  const [firstVersion, setFirstVersion] = useState<VersionInfo>(emptyVersion);
  const [secondVersion, setSecondVersion] = useState<VersionInfo>(emptyVersion);

  const [firstLoadProgress, setFirstLoadProgress] = useState<LoadingProgress>({ isLoading: true, hasErrors: false });
  const [secondLoadProgress, setSecondLoadProgress] = useState<LoadingProgress>({ isLoading: true, hasErrors: false });

  const setVersion = (
    versionName: string,
    version: VersionInfo,
    investmentServiceVersionId: number,
    setter: (v: VersionInfo) => void,
    progressSetter: (p: LoadingProgress) => void
  ) => {
    if (clientId !== null && !!investmentService?.investmentServiceId) {
      progressSetter({ isLoading: true, hasErrors: false });
      setter(emptyVersion);
      Promise.all([
        getConstraints({ clientId, investmentServiceVersionId }),
        getConfigurations({ clientId, investmentServiceVersionId }),
        getDetails({ clientId, investmentServiceVersionId }),
        getStrategicAllocations({ clientId, investmentServiceVersionId }),
        getPortfolioAssetClasses({
          clientId,
          groupingType: 'AssetClass',
          effectiveDate: getLocalDateTimeNow(DateTimeFormat.BackendDate),
          investmentServiceId: investmentService.investmentServiceId,
        }),
      ])
        .then(([constraints, configurations, details, strategicAllocations, portfolioDetailResult]) => {
          const assetClasses = portfolioDetailResult.portfolioDetail.items.map((asset) => ({
            targetWeightPercentage: asset.targetWeightPercentage,
            groupName: asset.groupName,
          }));

          setter({
            ...version,
            versionName,
            assetClasses,
            configurations,
            constraints,
            details,
            strategicAllocations,
          });

          progressSetter({ isLoading: false, hasErrors: false });
        })
        .catch(() => {
          progressSetter({ isLoading: false, hasErrors: true });
        });
    }
  };

  useEffect(() => {
    if (!!investmentService) {
      const version = investmentService.versions[0];
      setVersion(`${version.majorVersion}.${version.minorVersion}`, firstVersion, version.investmentServiceVersionId, setFirstVersion, setFirstLoadProgress);
    }
  }, [clientId, investmentService]);

  useEffect(() => {
    if (!!investmentService && investmentService.versions.length > 1) {
      const version = investmentService.versions[1];
      setVersion(`${version.majorVersion}.${version.minorVersion}`, secondVersion, version.investmentServiceVersionId, setSecondVersion, setSecondLoadProgress);
    }
  }, [clientId, investmentService]);

  const archiveButton = (
    <>
      <Button onClick={() => setArchiveDialogOpen(true)}>Archive Portfolio</Button>
      {archiveDialogOpen && (
        <ArchiveDialog
          onClose={() => setArchiveDialogOpen(false)}
          archivePortfolio={async () => {
            if (!!clientId && !!investmentService) {
              await archivePortfolio({ clientId, investmentServiceId: investmentService?.investmentServiceId });
              setArchiveDialogOpen(false);
            }
          }}
          progress={archivingProgress}
        ></ArchiveDialog>
      )}
    </>
  );

  if (!!investmentService && investmentService.versions.length <= 1) {
    return (
      <Box style={{ paddingLeft: '20px', paddingRight: '20px', paddingBottom: '20px' }}>
        <Typography variant="h4" gutterBottom>
          You do not have enough versions to compare to
        </Typography>
        {archiveButton}
      </Box>
    );
  }

  return (
    <>
      <Typography variant="h3" style={{ paddingBottom: '10px' }}>
        Review
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Version version={firstVersion} loadProgress={firstLoadProgress}></Version>
        </Grid>
        <Grid item xs={6}>
          <Version version={secondVersion} loadProgress={secondLoadProgress}></Version>
        </Grid>
      </Grid>
      {archiveButton}
    </>
  );
};
