import { Box, Button, Paper, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { formatDollars, nameof } from '../../../../../common';
import { ClientSideDataTable } from '../../../../../common/components/dataTable/clientSide';
import { DatatableColumn } from '../../../../../common/components/dataTable/types';
import { Props } from '../resultsContainer';
import { OrderSearchResult } from '../store/types';

export const Results = (props: Props): JSX.Element => {
  const [selected, setSelected] = useState<number[]>([]);
  const { results, setSelectedResults, history, selectedResults, isLoadingProgress } = props;
  const { path } = useRouteMatch();

  useEffect(() => {
    setSelected(selectedResults);
  }, [selectedResults]);

  const handleNext = () => {
    setSelectedResults(selected ?? []);
    history.push(`${path}/edit`);
  };

  const clientPortfolioNameColumn = (dataIndex: number, results: OrderSearchResult[]): React.ReactNode => (
    <>
      <Typography variant="h5">{results[dataIndex]?.clientName}</Typography>
      <Typography variant="h5" color={'textSecondary'}>
        {results[dataIndex]?.portfolioName}
      </Typography>
    </>
  );

  const typeColumn = (dataIndex: number, results: OrderSearchResult[]): React.ReactNode => (
    <>
      <Typography variant="h5">{results[dataIndex]?.clientType}</Typography>
      <Typography variant="h6" color={'textSecondary'}>
        {results[dataIndex]?.serviceType}
      </Typography>
    </>
  );

  const cashBalanceColumn = (dataIndex: number, results: OrderSearchResult[]): React.ReactNode => (
    <Typography variant="h5">{formatDollars(results[dataIndex]?.cashBalance)}</Typography>
  );

  const portfolioValueColumn = (dataIndex: number, results: OrderSearchResult[]): React.ReactNode => (
    <Typography variant="h5">{formatDollars(results[dataIndex]?.marketValue)}</Typography>
  );

  const getResultColumns = (results: OrderSearchResult[]): DatatableColumn[] => [
    {
      name: nameof((i: OrderSearchResult) => i.clientName),
      label: 'CLIENT & PORTFOLIO NAME',
      options: {
        filter: false,
        sort: true,
        customBodyRenderLite: (dataIndex: number) => clientPortfolioNameColumn(dataIndex, results),
      },
    },
    {
      name: nameof((i: OrderSearchResult) => i.clientType),
      label: 'TYPE',
      options: {
        filter: false,
        sort: true,
        customBodyRenderLite: (dataIndex) => typeColumn(dataIndex, results),
      },
    },
    {
      name: nameof((i: OrderSearchResult) => i.cashBalance),
      label: 'CASH BALANCE',
      textAlign: 'right',
      options: {
        filter: false,
        sort: true,
        customBodyRenderLite: (dataIndex) => cashBalanceColumn(dataIndex, results),
      },
    },
    {
      name: nameof((i: OrderSearchResult) => i.marketValue),
      label: 'PORTFOLIO VALUE',
      textAlign: 'right',
      options: {
        filter: false,
        sort: true,
        customBodyRenderLite: (dataIndex) => portfolioValueColumn(dataIndex, results),
      },
    },
  ];

  return (
    <Paper elevation={0}>
      <Box display="flex" flexDirection="row" justifyContent="flex-end" padding="24px">
        <Button
          disabled={selected.length <= 0}
          disableElevation
          color={'primary'}
          onClick={() => selected.length && handleNext()}
          variant="contained"
          id="next_button"
        >
          Next
        </Button>
      </Box>
      <ClientSideDataTable
        loadingProgress={isLoadingProgress}
        columns={getResultColumns(results)}
        data={results}
        options={{
          filter: false,
          selectableRows: 'multiple',
          selectableRowsHideCheckboxes: false,
          onRowSelectionChange: (currentRowsSelected, allRowsSelected, rowsSelected) => {
            setSelected(rowsSelected ?? []);
          },
          rowsSelected: selected,
        }}
      ></ClientSideDataTable>
      <Box display="flex" flexDirection="row" justifyContent="flex-end" padding="24px">
        <Button disabled={selected.length <= 0} disableElevation color={'primary'} onClick={() => selected.length && handleNext()} variant="contained">
          Next
        </Button>
      </Box>
    </Paper>
  );
};
