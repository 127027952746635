import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DateTime } from 'luxon';
import { ClientAccountSubType, ClientAccountType } from 'src/common/types';
import { AfslItem } from 'src/features/bulk/common/store/types';
import {
  AddressDetails,
  AuthorisedSignatoryRoleId,
  ContactDetail,
  DocumentDetails,
  DocumentType,
  TrusteeRoleId,
} from 'src/features/clients/client/common/store/types';
import { ExternalInstitution } from '../../../../common/components/accounts/types';
import { FetchPagedResults } from '../../../../store';
import { EmailType, PhoneNumberType } from '../../client/common/store/enums';
import { TrusteeType } from '../../client/details/mainDetails/store/enums';
import { FeeCalculationType, FeeMethod } from '../../common/enums';
import { AttachableContact, EstimatedFee, TieredFeeDetails } from '../../common/types';
import {
  removeAddress,
  removeContact,
  setAccountInfo,
  setAccountTypeValues,
  setAdditionalInformationValues,
  setAddress,
  setAdviceFeesValues,
  setContactIdentificationValues,
  setContactValues,
  setSuperSimplifierDetailsValues,
  setTrustee,
} from './actions';
import { blankSuperSimplifierDetails } from './constants';
import { OnboardingAccountInstitutionType } from './enums';
import {
  fetchAfslAuthorisation,
  fetchAfsls,
  fetchApprovedInstitutionIds,
  fetchContactAddresses,
  fetchContactDetails,
  fetchContactIdentificationForEdit,
  fetchContactIdentifications,
  fetchContactInfo,
  fetchContactsToAttach,
  fetchDocumentTypes,
  fetchExternalInstitutions,
  fetchFeesForAdviser,
  fetchOnboarding,
  processClient,
  saveAddress,
  saveOnboarding,
} from './thunks';
import {
  AccountInfo,
  AccountTypeValues,
  AdditionalInformationValues,
  AdviceFees,
  AfslAuthorisation,
  ClientState,
  Contact,
  ExistingAccount,
  FeeForAdviserOrClientModel,
  NewAccount,
  OnboardState,
  SaveContactValues,
  SetAttachedContactPayload,
  SuperSimplifierDetails,
  Trustee,
} from './types';

export const blankContact: Contact = {
  contactId: undefined,
  details: {
    titleId: null,
    genderId: null,
    firstName: '',
    middleName: '',
    lastName: '',
    dateOfBirth: null,
    tfn: '',
    tfnExemptionId: null,
    occupation: '',
    position: '',
    nationality: null,
    isAustralianResident: true,
    isForeignResident: false,
    isPrimary: false,
    clientRoles: [],
    trusteeRoles: [],
  },
  identifications: {
    parameters: {
      pagination: {
        pageNumber: 1,
        pageSize: 2,
        queryFields: [],
      },
    },
    results: {
      items: {
        pageNumber: 1,
        pageSize: 2,
        totalNumberOfPages: 1,
        totalNumberOfRecords: 0,
        results: [],
      },
      edit: undefined,
    },
  },
  phoneNumber: undefined,
  emailAddress: undefined,
  addresses: { items: [] },
};

export const blankAuthSigContact: Contact = {
  ...blankContact,
  details: {
    ...blankContact.details,
    clientRoles: [{ roleId: AuthorisedSignatoryRoleId, percent: null, relationship: '' }],
  },
};

export const initialState: OnboardState = {
  submitted: false,
  common: {
    attachContactList: [],
    afsls: [],
    afslAuthorisation: {
      advice: false,
      deal: false,
    },
    documentTypes: [],
    externalInstitutions: [],
    approvedInstitutionIds: [],
  },
  client: {
    id: null,
    stepKey: null,
    advisor: { advisorId: null, advisorName: '', afslId: null },
    clientAccount: {
      accountTypeId: ClientAccountType.Company.id,
      subTypeId: null,
      corporateTypeId: null,
      typeOfTrustId: null,
      trusteeTypeId: null,
      name: '',
      abn: '',
      tfnExemptionId: null,
      tfn: '',
      acn: '',
    },
    superSimplifierDetails: undefined,
    trustee: undefined,
    addresses: { items: [] },
    contacts: {
      items: [
        {
          ...blankAuthSigContact,
          details: { ...blankAuthSigContact.details, isPrimary: true },
        },
      ],
    },
    accounts: {
      existingAccounts: [],
      newAccounts: [],
      accountInfo: {
        riskProfile: '',
        sourceOfFunds: '',
        isHinTransfer: null,
        hin: null,
        pid: null,
        externalAccount: null,
      },
    },
    fees: {
      estimatedFees: {
        items: [],
      },
      // editingTieredFeeDetails: {
      //   items: [],
      // },
      standardFees: [],
    },
  },
};

export const onboardSlice = createSlice({
  name: 'onboard',
  initialState,
  reducers: {
    clearOnboarding: (state) => {
      state.client = initialState.client;
      state.submitted = false;
    },
    setContactEditIndex: (state, action: PayloadAction<number | null | undefined>) => {
      state.client.contacts.edit = action.payload;
    },
    clearAttachedContact: (state, action: PayloadAction<number>) => {
      if (action.payload === 0) {
        // we are clearing the primary contact
        // clear entity name for all individual types
        if (state.client.clientAccount.accountTypeId === ClientAccountType.Individual.id) {
          state.client.clientAccount.name = '';
        }
        state.client.contacts.items[action.payload] = initialState.client.contacts.items[0];
      } else if (state.client.clientAccount.accountTypeId === ClientAccountType.Joint.id) {
        // we are clearing the additional contact on a Joint accouny
        state.client.contacts.items[action.payload] = blankAuthSigContact;
      } else {
        // clear extra contact
        state.client.contacts.items[action.payload] = blankContact;
      }
    },
    setContactAddressEdit: (state, action: PayloadAction<{ index: number | null | undefined }>) => {
      if (state.client.contacts.edit !== undefined && state.client.contacts.edit !== null) {
        state.client.contacts.items[state.client.contacts.edit].addresses.edit = action.payload.index;
      }
    },
    saveContactAddress: (state, action: PayloadAction<AddressDetails>) => {
      if (state.client.contacts.edit !== undefined && state.client.contacts.edit !== null) {
        const contact = state.client.contacts.items[state.client.contacts.edit];
        const addressIndex = contact.addresses.edit;

        if (!!contact && addressIndex !== undefined) {
          if (addressIndex !== null) {
            // edit address
            state.client.contacts.items[state.client.contacts.edit].addresses.items[addressIndex] = action.payload;
          } else {
            // add address
            state.client.contacts.items[state.client.contacts.edit].addresses.items.push(action.payload);
          }
          state.client.contacts.items[state.client.contacts.edit].addresses.edit = undefined;
        }
      }
    },
    removeContactAddress: (state, action: PayloadAction<{ index: number }>) => {
      if (state.client.contacts.edit !== undefined && state.client.contacts.edit !== null) {
        state.client.contacts.items[state.client.contacts.edit].addresses.items.splice(action.payload.index, 1);
      }
    },
    setExistingAccount: (state, action: PayloadAction<ExistingAccount>) => {
      const existingIndex = state.client.accounts.existingAccounts.findIndex((a) => a.institutionId === action.payload.institutionId);
      if (existingIndex >= 0) {
        state.client.accounts.existingAccounts[existingIndex] = action.payload;
      } else {
        state.client.accounts.existingAccounts.push(action.payload);
      }
    },
    removeExistingAccount: (state, action: PayloadAction<{ institutionId: number }>) => {
      const existingIndex = state.client.accounts.existingAccounts.findIndex((a) => a.institutionId === action.payload.institutionId);
      if (existingIndex >= 0) {
        state.client.accounts.existingAccounts = state.client.accounts.existingAccounts.filter((a) => a.institutionId !== action.payload.institutionId);
      }
    },
    toggleNewAccount: (state, action: PayloadAction<NewAccount>) => {
      const existingIndex = state.client.accounts.newAccounts.findIndex((a) => a.institutionId === action.payload.institutionId);
      if (existingIndex >= 0) {
        state.client.accounts.newAccounts = state.client.accounts.newAccounts.filter((a) => a.institutionId !== action.payload.institutionId);
      } else {
        state.client.accounts.newAccounts.push(action.payload);
      }
    },
    removeNewAccount: (state, action: PayloadAction<{ institutionId: number }>) => {
      const existingIndex = state.client.accounts.newAccounts.findIndex((a) => a.institutionId === action.payload.institutionId);
      if (existingIndex >= 0) {
        state.client.accounts.newAccounts = state.client.accounts.newAccounts.filter((a) => a.institutionId !== action.payload.institutionId);
      }
    },
    setIdentificationAddMode: (state) => {
      const editIndex = state.client.contacts.edit;
      if (editIndex !== null && editIndex !== undefined) {
        state.client.contacts.items[editIndex].identifications.results.edit = null;
      }
    },
    cancelIdentificationAddEditMode: (state) => {
      const editIndex = state.client.contacts.edit;
      if (editIndex !== null && editIndex !== undefined) {
        state.client.contacts.items[editIndex].identifications.results.edit = undefined;
      }
    },
    setIdentificationEditId: (state, action: PayloadAction<number>) => {
      const editIndex = state.client.contacts.edit;
      if (editIndex !== null && editIndex !== undefined) {
        state.client.contacts.items[editIndex].identifications.results.edit = state.client.contacts.items[editIndex].identifications.results.items.results.find(
          (item) => item.id === action.payload
        );
      }
    },
    removeIdentification: (state, action: PayloadAction<number>) => {
      const editIndex = state.client.contacts.edit;
      if (editIndex !== null && editIndex !== undefined) {
        const index = state.client.contacts.items[editIndex].identifications.results.items.results.findIndex((item) => item.id === action.payload);
        if (index >= 0) {
          state.client.contacts.items[editIndex].identifications.results.items.results.splice(index, 1);
        }
      }
    },
    setAddressEdit: (state, action: PayloadAction<number | null | undefined>) => {
      state.client.addresses.edit = action.payload;
    },
    setEstimatedFeeEdit: (state, action: PayloadAction<EstimatedFee | null | undefined>) => {
      if (!!state.client.fees) {
        // need to copy data from `estimatedFees.items` to editingTieredFeeDetails for tiered type
        // if (typeof action.payload === 'number') {
        //   const feeItemIndex = action.payload;
        //   if (
        //     feeItemIndex >= 0 &&
        //     state.client.fees.estimatedFees.items[feeItemIndex] &&
        //     FeeMethod.Tiered.id === state.client.fees.estimatedFees.items[feeItemIndex].methodId
        //   ) {
        //     state.client.fees.editingTieredFeeDetails.items = state.client.fees.estimatedFees.items[feeItemIndex].tieredFeeDetails.items;
        //   }
        // }

        state.client.fees.estimatedFees.edit = action.payload;
      }
    },
    saveEstimatedFee: (state, action: PayloadAction<EstimatedFee>) => {
      const fee: EstimatedFee = {
        ...action.payload,
        tieredFeeDetails: {
          items: action.payload.methodId !== null && action.payload.methodId === FeeMethod.Tiered.id ? action.payload.tieredFeeDetails.items : [],
        },
      };

      if (!!state.client.fees) {
        if (fee.index === null) {
          state.client.fees.estimatedFees.items.push({ ...fee, index: state.client.fees.estimatedFees.items.length });
        } else {
          state.client.fees.estimatedFees.items[fee.index] = fee;
        }

        state.client.fees.estimatedFees.edit = undefined;
      }
    },
    removeEstimatedFee: (state, action: PayloadAction<number>) => {
      if (!!state.client.fees) {
        const feeItemIndex = action.payload;
        if (feeItemIndex >= 0 && !!state.client.fees.estimatedFees.items[feeItemIndex]) {
          state.client.fees.estimatedFees.items.splice(feeItemIndex, 1);
        }
      }
    },
    importStandardFees: (state) => {
      if (!!state.client.fees && Array.isArray(state.client.fees.standardFees) && state.client.fees.standardFees.length > 0) {
        const standardFees = state.client.fees.standardFees;

        // remove same FeeCalculationTypeid items as standardFees already have from existing estimated fees
        state.client.fees.estimatedFees.items = standardFees.concat(
          state.client.fees.estimatedFees.items.filter(
            (existingEstimatedFeesItem) =>
              standardFees.find((standardFee) => standardFee.calculationTypeId === existingEstimatedFeesItem.calculationTypeId) === undefined
          )
        );
      }
    },
    setTieredFeeDetailsEditId: (state, action: PayloadAction<TieredFeeDetails | null | undefined>) => {
      if (!!state.client.fees) {
        if (!!state.client.fees?.estimatedFees.edit) {
          state.client.fees.estimatedFees.edit.tieredFeeDetails.edit = action.payload;
        }
      }
    },
    setTieredFeeDetailsAdd: (state, action: PayloadAction<TieredFeeDetails>) => {
      if (!!state.client.fees) {
        if (!state.client.fees.estimatedFees.edit) {
          // the user is adding a new fee and adding a tier.  So we need to create a default fee to add the tier edit to.
          state.client.fees.estimatedFees.edit = {
            index: state.client.fees.estimatedFees.items.length,
            name: '',
            templateCode: '',
            frequencyId: null,
            methodId: FeeMethod.Tiered.id,
            calculationTypeId: null,
            amount: null,
            percentageOfValue: null,
            tieredFeeDetails: { items: [] },
          };
        }

        if (!!state.client.fees?.estimatedFees.edit) {
          state.client.fees.estimatedFees.edit.tieredFeeDetails.edit = action.payload;
          state.client.fees.estimatedFees.edit.tieredFeeDetails.items = [...state.client.fees.estimatedFees.edit.tieredFeeDetails.items, action.payload];
        }
      }
    },
    saveEditingTieredFeeDetails: (state, action: PayloadAction<TieredFeeDetails>) => {
      if (!!state.client.fees?.estimatedFees.edit) {
        const index = state.client.fees.estimatedFees.edit.tieredFeeDetails.items.findIndex((tier) => tier.id === action.payload.id);
        state.client.fees.estimatedFees.edit.tieredFeeDetails.items[index] = action.payload;
        state.client.fees.estimatedFees.edit.tieredFeeDetails.edit = undefined;
      }
    },
    removeEditingTieredFeeDetails: (state, action: PayloadAction<number>) => {
      if (!!state.client.fees?.estimatedFees.edit) {
        state.client.fees.estimatedFees.edit.tieredFeeDetails.items = state.client.fees.estimatedFees.edit.tieredFeeDetails.items.filter(
          (tier) => tier.id !== action.payload
        );
      }
    },
  },

  extraReducers: (builder) => {
    builder.addCase(fetchOnboarding.pending, (state) => {
      state.client = initialState.client;
      state.submitted = false;
    });
    builder.addCase(fetchOnboarding.fulfilled, (state, action: PayloadAction<ClientState>) => {
      state.client = action.payload;
      state.submitted = false;
    });
    builder.addCase(saveOnboarding.fulfilled, (state, action: PayloadAction<string>) => {
      state.client.id = action.payload;
    });
    builder.addCase(fetchContactsToAttach.fulfilled, (state, action: PayloadAction<AttachableContact[]>) => {
      state.common.attachContactList = action.payload;
    });
    builder.addCase(fetchContactInfo.fulfilled, (state, action: PayloadAction<SetAttachedContactPayload>) => {
      let index = 0;
      const { firstName, lastName } = action.payload.contact;

      if (state.client.contacts.edit !== undefined && state.client.contacts.edit !== null) {
        // updating contact in state
        index = state.client.contacts.edit;
      } else if (state.client.contacts.items.length > action.payload.index) {
        // updating existing contact
        index = action.payload.index;
      } else {
        // attaching a new contact
        index = state.client.contacts.items.push({ ...blankContact }) - 1;
      }

      // pre-populated entity name for all individual types
      if (state.client.clientAccount.accountTypeId === ClientAccountType.Individual.id && firstName.length > 0 && lastName.length > 0) {
        state.client.clientAccount.name = `${firstName} ${lastName}`;
      }

      state.client.contacts.items[index].details = {
        ...action.payload.contact,
        dateOfBirth: !!action.payload.contact.dateOfBirth ? DateTime.fromISO(action.payload.contact?.dateOfBirth).toISODate() : null,
      };
      state.client.contacts.items[index].contactId = action.payload.contactId;
    });
    builder.addCase(fetchContactAddresses.fulfilled, (state, action: PayloadAction<AddressDetails[]>) => {
      state.client.contacts.items[state.client.contacts.edit || 0].addresses.items = action.payload;
    });
    builder.addCase(fetchContactDetails.fulfilled, (state, action: PayloadAction<ContactDetail[]>) => {
      // mobile
      const mobileNumber = action.payload.find((n) => n.contactDetailTypeId === PhoneNumberType.Mobile.id);
      if (!!mobileNumber) {
        state.client.contacts.items[state.client.contacts.edit || 0].phoneNumber = {
          id: mobileNumber.id,
          typeId: mobileNumber.contactDetailTypeId,
          number: mobileNumber.phoneEmail,
        };
      } else {
        state.client.contacts.items[state.client.contacts.edit || 0].phoneNumber = undefined;
      }

      // email
      const email = action.payload.find((n) => !!EmailType.getById(n.contactDetailTypeId) && n.preferred);
      if (!!email) {
        state.client.contacts.items[state.client.contacts.edit || 0].emailAddress = {
          id: email.id,
          typeId: email.contactDetailTypeId,
          email: email.phoneEmail,
        };
      } else {
        state.client.contacts.items[state.client.contacts.edit || 0].emailAddress = undefined;
      }
    });
    builder.addCase(fetchAfsls.fulfilled, (state, action: PayloadAction<AfslItem[]>) => {
      state.common.afsls = action.payload;
    });
    builder.addCase(fetchAfslAuthorisation.fulfilled, (state, action: PayloadAction<AfslAuthorisation>) => {
      state.common.afslAuthorisation = action.payload;
    });
    builder.addCase(fetchDocumentTypes.fulfilled, (state, action: PayloadAction<DocumentType[]>) => {
      state.common.documentTypes = action.payload;
    });
    builder.addCase(fetchContactIdentifications.fulfilled, (state, action: PayloadAction<FetchPagedResults<DocumentDetails>>) => {
      state.client.contacts.items[0].identifications.results.items = action.payload.results;
    });
    builder.addCase(fetchExternalInstitutions.fulfilled, (state, action: PayloadAction<ExternalInstitution[]>) => {
      state.common.externalInstitutions = action.payload;
    });
    builder.addCase(fetchApprovedInstitutionIds.fulfilled, (state, action: PayloadAction<number[]>) => {
      state.common.approvedInstitutionIds = action.payload;
      state.client.accounts.newAccounts = state.client.accounts.newAccounts.filter((account) => action.payload.includes(account.institutionId));
    });
    builder.addCase(fetchFeesForAdviser.fulfilled, (state, action: PayloadAction<FeeForAdviserOrClientModel[]>) => {
      if (!!state.client.fees && Array.isArray(action.payload)) {
        // 1. filter all tiered data fetched from backend
        // 2. sort by `from` field
        // 3. group by `templateCode` field
        const tieredFeesGroupedByTemplateCode = action.payload
          .filter((tieredFee: FeeForAdviserOrClientModel) => !!tieredFee.templateCode && tieredFee.feeMethodId === FeeMethod.Tiered.id)
          .sort((a, b) => (a.from ?? 0) - (b.from ?? 0))
          .reduce((results: { [key: string]: FeeForAdviserOrClientModel[] }, current: FeeForAdviserOrClientModel) => {
            if (!!current['templateCode']) {
              results[current['templateCode']] = [...(results[current['templateCode']] || []), current];
            }
            return results;
          }, {});

        const tieredFees: EstimatedFee[] = [];
        for (const [key, value] of Object.entries(tieredFeesGroupedByTemplateCode)) {
          tieredFees.push({
            index: tieredFees.length,
            templateCode: key,
            name: value[0].name ?? (value[0].feeCalculationType || FeeCalculationType.getById(value[0].feeCalculationTypeId)?.displayName) ?? '',
            calculationTypeId: value[0].feeCalculationTypeId,
            methodId: value[0].feeMethodId,
            frequencyId: value[0].feeFrequencyId,
            amount: 0,
            percentageOfValue: 0,
            tieredFeeDetails: {
              items: value.map((fee: FeeForAdviserOrClientModel, index: number) => {
                return {
                  id: index,
                  // id: index + 1,
                  from: fee.from ?? 0,
                  to: fee.to ?? 0,
                  amount: fee.amount ?? 0,
                  percentage: fee.percentage ? +fee.percentage.toFixed(4) : 0,
                };
              }),
            },
          });
        }

        const nonTieredFees: EstimatedFee[] = action.payload
          .filter((fee) => !!fee.templateCode && (fee.feeMethodId === FeeMethod.Dollar.id || fee.feeMethodId === FeeMethod.Percentage.id))
          .map((fee, index) => {
            return {
              index,
              templateCode: fee.templateCode,
              name: fee.name ?? (fee.feeCalculationType || FeeCalculationType.getById(fee.feeCalculationTypeId)?.displayName) ?? '',
              calculationTypeId: fee.feeCalculationTypeId,
              methodId: fee.feeMethodId,
              frequencyId: fee.feeFrequencyId,
              amount: fee.amount ?? 0,
              percentageOfValue: fee.percentage ? +fee.percentage.toFixed(4) : 0,
              tieredFeeDetails: {
                items: [],
              },
            };
          });

        // make sure unique FeeCalculationType elements and the max length of array
        state.client.fees.standardFees = nonTieredFees.concat(tieredFees).map((fee, index) => ({
          ...fee,
          index,
        }));
      }
    });
    builder.addCase(fetchContactIdentificationForEdit.fulfilled, (state, action: PayloadAction<DocumentDetails>) => {
      state.client.contacts.items[0].identifications.results.edit = action.payload;
    });
    builder.addCase(setAccountTypeValues, (state, action: PayloadAction<AccountTypeValues>) => {
      state.client.stepKey = 'accountType';

      const existingAccountTypeId = state.client.clientAccount.accountTypeId;
      const existingSubTypeId = state.client.clientAccount.subTypeId;
      const newAccountTypeId = action.payload.clientAccount.accountTypeId;
      const newSubTypeId = action.payload.clientAccount.subTypeId;

      if (!!state.client.fees) {
        // adviser Id or accountType is changed
        if (
          state.client.advisor?.advisorId !== action.payload.advisor?.advisorId ||
          ((existingAccountTypeId !== newAccountTypeId || existingSubTypeId !== newSubTypeId) &&
            newAccountTypeId === ClientAccountType.Individual.id &&
            (newSubTypeId === ClientAccountSubType.Super.id || newSubTypeId === ClientAccountSubType.Pension.id))
        ) {
          if (state.client.fees.estimatedFees.items.length > 0 && state.client.fees.standardFees.length > 0) {
            // remove existing fees items fetched with old adviserId
            state.client.fees.estimatedFees.items = state.client.fees.estimatedFees.items.filter(
              (estimatedFeesItem) =>
                estimatedFeesItem.templateCode === null ||
                state.client.fees?.standardFees.find((standardFee) => standardFee.templateCode === estimatedFeesItem.templateCode) === undefined
            );
          }
          // clear existing standardFees if adviser is changed
          state.client.fees.standardFees = [];
        }
      }

      state.client.advisor = action.payload.advisor;

      // account type is changed
      if (existingAccountTypeId !== newAccountTypeId || existingSubTypeId !== newSubTypeId) {
        if (
          newAccountTypeId === ClientAccountType.Individual.id &&
          (newSubTypeId === ClientAccountSubType.Super.id || newSubTypeId === ClientAccountSubType.Pension.id)
        ) {
          // pre-select newAccounts if super or pension is selected
          state.client.accounts = {
            ...state.client.accounts,
            existingAccounts: [],
            newAccounts: OnboardingAccountInstitutionType.getAllAccountInstitutions().map((accountInstitution: OnboardingAccountInstitutionType) => {
              return { institutionId: accountInstitution.id };
            }),
          };
        } else if (
          existingAccountTypeId === ClientAccountType.Individual.id &&
          (existingSubTypeId === ClientAccountSubType.Super.id || existingSubTypeId === ClientAccountSubType.Pension.id)
        ) {
          // clear all newAccounts when account type is changed from super or pension to other types
          state.client.accounts.newAccounts = [];
        }

        // clear externalAccount if new account type is not pension
        if (
          existingAccountTypeId === ClientAccountType.Individual.id &&
          existingSubTypeId === ClientAccountSubType.Pension.id &&
          !(newAccountTypeId === ClientAccountType.Individual.id && newSubTypeId === ClientAccountSubType.Pension.id)
        ) {
          state.client.accounts.accountInfo.externalAccount = null;
        }
      }

      state.client.clientAccount = {
        ...state.client.clientAccount,
        accountTypeId: action.payload.clientAccount?.accountTypeId || null,
        subTypeId: action.payload.clientAccount?.subTypeId || null,
        corporateTypeId: action.payload.clientAccount?.corporateTypeId || null,
        typeOfTrustId: action.payload.clientAccount?.typeOfTrustId || null,
        trusteeTypeId: action.payload.clientAccount?.trusteeTypeId || null,
      };

      if (!!action.payload.clientAccount?.subTypeId || !!action.payload.superSimplifierDetails?.pensionTypeId) {
        state.client.superSimplifierDetails = {
          ...blankSuperSimplifierDetails,
          ...state.client.superSimplifierDetails,
          subAccountTypeId: action.payload.clientAccount?.subTypeId || null,
          pensionTypeId: action.payload.superSimplifierDetails?.pensionTypeId || null,
        };
      }

      // if the account type is Joint, add another blank contact
      if (action.payload.clientAccount?.accountTypeId === ClientAccountType.Joint.id) {
        state.client.contacts.items.push(blankAuthSigContact);
      }

      // add Trustee role if trusteeType is Individual
      if (
        state.client.clientAccount.trusteeTypeId === TrusteeType.Individual.id &&
        !state.client.contacts.items[0].details.clientRoles.find((r) => r.roleId === TrusteeRoleId)
      ) {
        state.client.contacts.items[0].details.clientRoles = [
          ...state.client.contacts.items[0].details.clientRoles,
          { roleId: TrusteeRoleId, percent: null, relationship: null },
        ];
      }
    });
    builder.addCase(setAdditionalInformationValues, (state, action: PayloadAction<AdditionalInformationValues>) => {
      state.client.stepKey = 'information';
      state.client.clientAccount = {
        ...state.client.clientAccount,
        name: action.payload.clientAccount.name,
        tfn: action.payload.clientAccount.tfn,
        tfnExemptionId: action.payload.clientAccount.tfnExemptionId,
        abn: action.payload.clientAccount.abn,
        acn: action.payload.clientAccount.acn,
      };

      state.client.contacts.items[0].details = {
        ...state.client.contacts.items[0].details,
        titleId: action.payload.contact.titleId,
        firstName: action.payload.contact.firstName,
        middleName: action.payload.contact.middleName,
        lastName: action.payload.contact.lastName,
        dateOfBirth: action.payload.contact.dateOfBirth,
      };
      if (!!action.payload.additionalContact) {
        state.client.contacts.items[1].details = {
          ...state.client.contacts.items[1].details,
          titleId: action.payload.additionalContact.titleId,
          firstName: action.payload.additionalContact.firstName,
          middleName: action.payload.additionalContact.middleName,
          lastName: action.payload.additionalContact.lastName,
          dateOfBirth: action.payload.additionalContact.dateOfBirth,
        };
      }
    });
    builder.addCase(setSuperSimplifierDetailsValues, (state, action: PayloadAction<SuperSimplifierDetails>) => {
      const { rolloverAmount, rolloverAmountSecond, rolloverAmountThird, contributionsAmount } = action.payload;
      state.client.stepKey = 'superSimplifier';
      state.client.superSimplifierDetails = action.payload;

      // remove all fees items when total rollover and contribution amounts are zero
      if (!!state.client.fees && (rolloverAmount ?? 0) + (rolloverAmountSecond ?? 0) + (rolloverAmountThird ?? 0) === 0 && (contributionsAmount ?? 0) === 0) {
        state.client.fees.estimatedFees.items = [];
      }
    });
    builder.addCase(setTrustee, (state, action: PayloadAction<Trustee | undefined>) => {
      state.client.stepKey = 'trustee';
      state.client.trustee = action.payload;
    });
    builder.addCase(setAdviceFeesValues, (state, action: PayloadAction<AdviceFees>) => {
      state.client.stepKey = 'fees';
      state.client.fees = action.payload;
    });
    builder.addCase(setAccountInfo, (state, action: PayloadAction<AccountInfo>) => {
      state.client.stepKey = 'accounts';
      state.client.accounts.accountInfo = action.payload;
    });
    builder.addCase(setContactValues, (state, action: PayloadAction<SaveContactValues>) => {
      state.client.stepKey = `contacts`;

      if (action.payload.index !== null && action.payload.index !== undefined) {
        state.client.contacts.items[action.payload.index].details = action.payload.details;
        state.client.contacts.items[action.payload.index].phoneNumber = action.payload.phoneNumber;
        state.client.contacts.items[action.payload.index].emailAddress = action.payload.emailAddress;
      } else {
        state.client.contacts.items.push({
          ...blankContact,
          details: action.payload.details,
          phoneNumber: action.payload.phoneNumber,
          emailAddress: action.payload.emailAddress,
        });

        // saving a new contact, we want to stay on this contact to add addresses etc.
        state.client.contacts.edit = state.client.contacts.items.length - 1;
      }
    });
    builder.addCase(removeContact, (state, action: PayloadAction<{ index: number }>) => {
      state.client.stepKey = `contacts`;

      state.client.contacts.items.splice(action.payload.index, 1);
    });
    builder.addCase(setContactIdentificationValues, (state, action: PayloadAction<DocumentDetails[]>) => {
      const editIndex = state.client.contacts.edit;
      if (editIndex !== null && editIndex !== undefined) {
        state.client.contacts.items[editIndex].identifications.results.items.results = action.payload;
      }
    });
    builder.addCase(setAddress, (state, action: PayloadAction<AddressDetails>) => {
      if (state.client.addresses.edit !== undefined) {
        if (state.client.addresses.edit !== null) {
          // edit
          state.client.addresses.items[state.client.addresses.edit] = action.payload;
        } else {
          // add address
          state.client.addresses.items.push(action.payload);
        }
      }
    });
    builder.addCase(saveAddress.fulfilled, (state) => {
      state.client.addresses.edit = undefined;
    });
    builder.addCase(removeAddress, (state, action: PayloadAction<{ index: number }>) => {
      state.client.addresses.items.splice(action.payload.index, 1);
    });
    builder.addCase(processClient.fulfilled, (state) => {
      state.submitted = true;
    });
  },
});
