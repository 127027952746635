import { Grid, Paper } from '@mui/material';
import { Field, Form, Formik, FormikProps } from 'formik';
import React, { useCallback, useEffect, useState } from 'react';
import { yupValidateTfn } from 'src/common/components/formik';
import { LoadingIndicator } from 'src/common/components/LoadingIndicator';
import { BeneficialOwnerRoleId, BeneficiaryRoleId, ContactDetails, DirectorCompanyOfficerRoleId, Role } from 'src/features/clients/client/common/store/types';
import { Occupation, Position, TfnExemptions } from 'src/features/clients/common/enums';
import * as yup from 'yup';
import { TestContext } from 'yup';
import {
  EditCancelSaveButtons,
  FormikEnumerationSelect,
  FormikKeyboardDatePicker,
  FormikSelect,
  FormikSwitch,
  FormikTextField,
  Mode,
} from '../../../../../../../common/components/formik';
import { LoadingProgress } from '../../../../../../../common/store/types';
import { Gender, moment, Title } from '../../../../../../../common/types';
import history from '../../../../../../../history';
import { RoleSelector } from './roleSelector';

export interface Props {
  details?: ContactDetails;
  loadingProgress: LoadingProgress;
  primaryContactId?: number;
  onSave: (details: ContactDetails) => void;
  saveContactProgress: LoadingProgress;
  roles: Role[];
  rolesLoadingProgress: LoadingProgress;
}

interface FormValues {
  contactId: number | null;
  titleId: number | null;
  genderId: number | null;
  firstName: string;
  middleName: string;
  lastName: string;
  dateOfBirth: moment | null;
  preferredName: string;
  tfn: string | null;
  tfnExemptionId: number | null;
  occupation: string;
  positionId: number | null;
  nationality: string;
  isResident: boolean;

  roleIds: number[];
  beneficialOwnerPercentage: number | null;
  beneficiaryPercentage: number | null;
  beneficiaryRelationship: string;
  directorCompanyOfficerPercentage: number | null;
  isPrimary: boolean;
}

export const DetailsForm = ({ onSave, details, loadingProgress, saveContactProgress, roles, rolesLoadingProgress, primaryContactId }: Props): JSX.Element => {
  const initialFormValues: FormValues = {
    contactId: null,
    titleId: null,
    genderId: Gender.Unspecified.id,
    firstName: '',
    middleName: '',
    lastName: '',
    dateOfBirth: null,
    preferredName: '',
    tfn: '',
    tfnExemptionId: null,
    occupation: '',
    positionId: null,
    nationality: '',
    isResident: true,
    isPrimary: false,

    roleIds: [],
    beneficialOwnerPercentage: null,
    beneficiaryPercentage: null,
    beneficiaryRelationship: '',
    directorCompanyOfficerPercentage: null,
  };

  const [formValues, setFormValues] = useState<FormValues>(initialFormValues);

  const resetFormValues = useCallback(
    (contactDetails: ContactDetails) => {
      const beneficialOwnerPercentage = contactDetails.roles.find((r) => r.roleId === BeneficialOwnerRoleId)?.percent || null;
      const beneficiaryPercentage = contactDetails.roles.find((r) => r.roleId === BeneficiaryRoleId)?.percent || null;
      const beneficiaryRelationship = contactDetails.roles.find((r) => r.roleId === BeneficiaryRoleId)?.relationship || '';
      const directorCompanyOfficerPercentage = contactDetails.roles.find((r) => r.roleId === DirectorCompanyOfficerRoleId)?.percent || null;

      setFormValues({
        ...contactDetails,
        contactId: contactDetails.id,
        roleIds: contactDetails.roles.map((r) => r.roleId),
        beneficiaryRelationship,
        beneficialOwnerPercentage: beneficialOwnerPercentage,
        beneficiaryPercentage: beneficiaryPercentage,
        directorCompanyOfficerPercentage: directorCompanyOfficerPercentage,
        positionId: Position.getByName(contactDetails.position)?.id ?? null,
      });
    },
    [setFormValues]
  );

  useEffect(() => {
    if (!!details) {
      resetFormValues(details);
    }
  }, [setFormValues, details, resetFormValues]);

  const isCreateMode = !details?.id;

  return (
    <Formik<FormValues>
      enableReinitialize={true}
      initialValues={formValues}
      onSubmit={async (submitDetails) => {
        const payload: ContactDetails = {
          id: submitDetails.contactId,
          titleId: submitDetails.titleId,
          genderId: submitDetails.genderId,
          firstName: submitDetails.firstName,
          middleName: submitDetails.middleName,
          lastName: submitDetails.lastName,
          dateOfBirth: submitDetails.dateOfBirth,
          preferredName: submitDetails.preferredName,
          tfn: submitDetails.tfn,
          tfnExemptionId: submitDetails.tfnExemptionId,
          occupation: submitDetails.occupation,
          position: submitDetails.occupation === null ? null : Position.getById(submitDetails.positionId)?.displayName ?? null,
          nationality: submitDetails.nationality,
          isResident: submitDetails.isResident,
          isPrimary: submitDetails.isPrimary,
          roles: submitDetails.roleIds.map((id) => {
            let percent: number | null;
            let relationship: string | null = null;

            switch (id) {
              case BeneficialOwnerRoleId:
                percent = submitDetails.beneficialOwnerPercentage;
                break;
              case BeneficiaryRoleId:
                percent = submitDetails.beneficiaryPercentage;
                relationship = submitDetails.beneficiaryRelationship;
                break;
              case DirectorCompanyOfficerRoleId:
                percent = submitDetails.directorCompanyOfficerPercentage;
                break;
              default:
                percent = null;
            }
            return {
              roleId: id,
              percent,
              relationship,
            };
          }),
        };

        onSave(payload);
      }}
      validationSchema={yup.object({
        titleId: yup.number().nullable(),
        genderId: yup.number().nullable(),
        firstName: yup.string().required('Required'),
        middleName: yup.string().nullable(),
        lastName: yup.string().required('Required'),
        dateOfBirth: yup.date().nullable().typeError('Must be a valid date'),
        preferredName: yup.string().nullable(),
        tfn: yup.string().test('test-tfn-valid', 'Not a valid Tax File Number', function (this: TestContext, tfn: string | null | undefined): boolean {
          if (!this.parent.tfnExemptionId) {
            return !tfn || yupValidateTfn(tfn);
          }
          return !!tfn && TfnExemptions.isValidExemptionCode(tfn);
        }),
        tfnExemption: yup.string().nullable(),
        occupation: yup.string().nullable(),
        position: yup.string().nullable(),
        nationality: yup.string(),
        isResident: yup.boolean(),
        roleIds: yup.array().of(yup.number()).required('At least one role is required'),

        isPrimary: yup.boolean(),
        beneficialOwnerPercentage: yup
          .number()
          .nullable()
          .when('roleIds', {
            is: (roleIds: number[]) => roleIds.find((r) => r === BeneficialOwnerRoleId),
            then: yup.number().max(100, 'Percentage cannot exceed 100%').required('Required'),
          }),
        beneficiaryPercentage: yup
          .number()
          .nullable()
          .when('roleIds', {
            is: (roleIds: number[]) => roleIds.find((r) => r === BeneficiaryRoleId),
            then: yup.number().max(100, 'Percentage cannot exceed 100%').required('Required'),
          }),
        directorCompanyOfficerPercentage: yup
          .number()
          .nullable()
          .when('roleIds', {
            is: (roleIds: number[]) => roleIds.find((r) => r === DirectorCompanyOfficerRoleId),
            then: yup.number().max(100, 'Percentage cannot exceed 100%').required('Required'),
          }),
      })}
    >
      {(formikProps: FormikProps<FormValues>) => (
        <Form>
          <Paper elevation={5} style={{ marginBottom: '15px', padding: '10px' }}>
            <LoadingIndicator progress={loadingProgress}>
              <RoleSelector
                label="ROLES"
                roles={roles}
                rolesLoadingProgress={rolesLoadingProgress}
                contactId={details?.id || undefined}
                primaryContactId={primaryContactId || undefined}
                roleIds={{ inputProps: formikProps.getFieldProps('roleIds'), helperProps: formikProps.getFieldHelpers('roleIds') }}
                beneficialOwnerPercentage={{ inputProps: formikProps.getFieldProps('beneficialOwnerPercentage') }}
                beneficiaryPercentage={{ inputProps: formikProps.getFieldProps('beneficiaryPercentage') }}
                directorCompanyOfficerPercentage={{ inputProps: formikProps.getFieldProps('directorCompanyOfficerPercentage') }}
                isPrimary={{ inputProps: formikProps.getFieldProps('isPrimary') }}
                beneficiaryRelationship={{ inputProps: formikProps.getFieldProps('beneficiaryRelationship') }}
                requiresAuthorisedSignatory={formikProps.values.isPrimary}
              ></RoleSelector>
            </LoadingIndicator>
          </Paper>

          <Paper elevation={5} style={{ marginBottom: '15px' }}>
            <LoadingIndicator progress={loadingProgress}>
              <fieldset style={{ border: 'none' }}>
                <Grid container>
                  <Grid item xs={6}>
                    <Grid item xs={12} style={{ margin: '20px 10px' }}>
                      <Field component={FormikEnumerationSelect} type={Title} showNone={false} valueIsId={true} name="titleId" label="TITLE" />
                    </Grid>
                    <Grid item xs={12} style={{ margin: '20px 10px' }}>
                      <Field component={FormikEnumerationSelect} type={Gender} valueIsId={true} showNone={false} name="genderId" label="GENDER" />
                    </Grid>
                    <Grid item xs={12} style={{ margin: '20px 10px' }}>
                      <Field component={FormikTextField} name="firstName" label="FIRST NAME" fullWidth />
                    </Grid>
                    <Grid item xs={12} style={{ margin: '20px 10px' }}>
                      <Field component={FormikTextField} name="middleName" label="MIDDLE NAME" fullWidth />
                    </Grid>
                    <Grid item xs={12} style={{ margin: '20px 10px' }}>
                      <Field component={FormikTextField} name="lastName" label="LAST NAME" fullWidth />
                    </Grid>
                    <Grid item xs={12} style={{ margin: '20px 10px' }}>
                      <Field component={FormikKeyboardDatePicker} name="dateOfBirth" label="DATE OF BIRTH" fullWidth />
                    </Grid>
                    <Grid item xs={12} style={{ margin: '20px 10px' }}>
                      <Field component={FormikTextField} disabled={true} name="id" label="CONTACT ID" fullWidth />
                    </Grid>
                  </Grid>
                  <Grid item xs={6}>
                    <Grid item xs={12} style={{ margin: '20px 10px' }}>
                      <Field component={FormikTextField} name="preferredName" label="PREFERRED NAME" fullWidth />
                    </Grid>
                    <Grid item xs={12} style={{ margin: '20px 10px' }}>
                      <Field
                        component={FormikEnumerationSelect}
                        type={TfnExemptions}
                        name="tfnExemptionId"
                        label="TFN EXEMPTION"
                        showNone={true}
                        valueIsId={true}
                        onChange={(value: number | null) => {
                          if (value === null) {
                            if (formikProps.values.tfn !== null && TfnExemptions.isValidExemptionCode(formikProps.values.tfn)) {
                              formikProps.setFieldValue('tfn', null);
                            }
                          } else {
                            const tfnExemptionsCode = TfnExemptions.getTfnExemptionCodeById(value);
                            if (!!tfnExemptionsCode) {
                              formikProps.setFieldValue('tfn', tfnExemptionsCode.code);
                              formikProps.setFieldTouched('tfn', false, false);
                            }
                          }
                          formikProps.setFieldTouched('tfnExemptionId', false, false);
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} style={{ margin: '20px 10px' }}>
                      <Field
                        mask="999 999 999"
                        type="password"
                        autocomplete="new-password"
                        component={FormikTextField}
                        name="tfn"
                        label="TAX FILE NUMBER (TFN)"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} style={{ margin: '20px 10px' }}>
                      <Field
                        component={FormikEnumerationSelect}
                        type={Occupation}
                        name="occupation"
                        label="OCCUPATION"
                        showNone={true}
                        valueIsId={false}
                        onChange={() => {
                          formikProps.setFieldValue('positionId', null);
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} style={{ margin: '20px 10px' }}>
                      <Field
                        component={FormikSelect}
                        data={Position.getByOccupation(formikProps.values.occupation)}
                        itemDisplayNameField="displayName"
                        fieldName="positionId"
                        valueIsId={false}
                        label="POSITION"
                      />
                    </Grid>
                    <Grid item xs={12} style={{ margin: '20px 10px' }}>
                      <Field component={FormikTextField} name="nationality" label="NATIONALITY" fullWidth />
                    </Grid>
                    <Grid item xs={12} style={{ margin: '20px 10px' }}>
                      <Field name="isResident" component={FormikSwitch} label="RESIDENT"></Field>
                    </Grid>
                  </Grid>
                </Grid>
                <EditCancelSaveButtons
                  mode={isCreateMode ? Mode.CancelSave : Mode.ResetUpdate}
                  handleCancelClick={() => {
                    if (!isCreateMode && !!details) {
                      //reset form
                      resetFormValues(details);
                      formikProps.setTouched({});
                    } else {
                      // cancel new contact - nav back to list
                      history.goBack();
                    }
                  }}
                  isPristine={Object.keys(formikProps.touched).length === 0}
                  saveProgress={saveContactProgress}
                ></EditCancelSaveButtons>
              </fieldset>
            </LoadingIndicator>
          </Paper>
        </Form>
      )}
    </Formik>
  );
};
