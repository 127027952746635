import { Enumeration } from './enumeration';

export enum MdaReportType {
  PortfolioValuation = 'Portfolio Valuation',
  Transactions = 'Transactions',
  CashTransactions = 'Cash Transactions',
  IncomeReceived = 'Income Received',
  GainsLossesRealised = 'Gains & Losses Realised',
  GainsLossesUnrealised = 'Gains & Losses Unrealised',
  Expenses = 'Expenses',
  SecurityPerformance = 'Security Performance',
  AssetAllocation = 'Asset Allocation',
  MergeReport = 'Report',
}

export class MdaReportTypes extends Enumeration {
  static PortfolioValuation = new MdaReportTypes(1, 'Portfolio Valuation', MdaReportType.PortfolioValuation);
  static Transactions = new MdaReportTypes(2, 'Transactions', MdaReportType.Transactions);
  static CashTransactions = new MdaReportTypes(3, 'Cash Transactions', MdaReportType.CashTransactions);
  static IncomeReceived = new MdaReportTypes(4, 'Income Received', MdaReportType.IncomeReceived);
  static GainsLossesRealised = new MdaReportTypes(5, 'Gains & Losses Realised', MdaReportType.GainsLossesRealised);
  static GainsLossesUnrealised = new MdaReportTypes(6, 'Gains & Losses Unrealised', MdaReportType.GainsLossesUnrealised);
  static Expenses = new MdaReportTypes(7, 'Expenses', MdaReportType.Expenses);
  static SecurityPerformance = new MdaReportTypes(8, 'Security Performance', MdaReportType.SecurityPerformance);
  static AssetAllocation = new MdaReportTypes(9, 'Asset Allocation', MdaReportType.AssetAllocation);
  static MergeReport = new MdaReportTypes(100, 'Report', MdaReportType.MergeReport);
}
