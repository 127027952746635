import { connect, ConnectedProps } from 'react-redux';
import { progressSelectorFactory } from '../../../../../common';
import { RootState } from '../../../../../reducers';
import { selectHasClientEdit, selectHasClientEditAdminOnly } from '../../../../../store/selectors';
import { selectClientId, selectSuperSimplifierDetails, selectClientStatus } from '../../common/store/selectors';
import { CommonActionTypes, updateSuperSimplifier } from '../../common/store/thunks';
import { SuperSimplifier } from './components/superSimplifier';
import { SuperSimplifierActionTypes, fetchSuperSimplifierBeneficiaries } from './store/thunks';
import { selectBeneficiaries } from './store/selectors';
import { superSimplifierSlice } from './store/slice';

const mapStateToProps = (state: RootState) => ({
  clientId: selectClientId(state),
  details: selectSuperSimplifierDetails(state),
  loadingProgress: progressSelectorFactory([CommonActionTypes.FetchSuperSimplifier])(state),
  savingProgress: progressSelectorFactory([CommonActionTypes.UpdateSuperSimplifier])(state),
  hasClientEditPermission: selectHasClientEdit(state),
  hasClientEditAdminOnlyPermission: selectHasClientEditAdminOnly(state),
  loadingProgressBeneficiaries: progressSelectorFactory([SuperSimplifierActionTypes.FetchSuperSimplifierBeneficiaries])(state),
  beneficiaries: selectBeneficiaries(state),
  status: selectClientStatus(state),
});

const mapDispatchToProps = {
  ...superSimplifierSlice.actions,
  updateSuperSimplifier,
  fetchSuperSimplifierBeneficiaries,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export type Props = ConnectedProps<typeof connector>;
export const SuperSimplifierContainer = connector(SuperSimplifier);
