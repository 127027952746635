import { SnackbarProvider } from '@dash/notistack';
import AdapterLuxon from '@mui/lab/AdapterLuxon';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { ThemeProvider } from '@mui/material/styles';
import { LicenseInfo } from '@mui/x-license-pro';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import React from 'react';
import { Router } from 'react-router';
import { ReportGenerationProgress } from 'src/features/clients/client/reports/components/ReportGenerationProgress';
import { ConfirmationServiceProvider } from '../common/components/dialogs';
import { Notifier } from '../components/notifications/notifier';
import Navigation from '../features/layout/components/navigation/container';
import history from '../history';
import { theme } from '../themes';
import './App.css';
import Routes from './Routes';

LicenseInfo.setLicenseKey('4b0bfa362126c5b3a59c1319a6ec384aT1JERVI6NDE4MzMsRVhQSVJZPTE2ODE1MTI0NzgwMDAsS0VZVkVSU0lPTj0x');

function App(): JSX.Element {
  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <LocalizationProvider dateAdapter={AdapterLuxon}>
          <SnackbarProvider
            maxSnack={5}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
          >
            <Notifier />
            <ReportGenerationProgress />
            <ConfirmationServiceProvider>
              <Router history={history}>
                <Navigation>
                  <Routes />
                </Navigation>
              </Router>
            </ConfirmationServiceProvider>
          </SnackbarProvider>
        </LocalizationProvider>
      </ThemeProvider>
    </div>
  );
}

if (process.env.REACT_APP_NONPROD_ENV === 'false') {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: process.env.REACT_APP_ENV,
    autoSessionTracking: true,
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
      }),
    ],

    tracesSampleRate: 0.25, // 1.0: most performance tracing, 0.0: no tracing
  });
}

export default App;
