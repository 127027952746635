import RefreshRounded from '@mui/icons-material/RefreshRounded';
import { IconButton, Paper, Tooltip, Typography } from '@mui/material';
import React from 'react';
import { DateTimeFormat, getLocalDateTime } from '../../../../common';
import { ServerSideDataTable } from '../../../../common/components/dataTable/serverSide';
import { DatatableColumn, FilterDataType } from '../../../../common/components/dataTable/types';
import { LoadingProgress } from '../../../../common/store/types';
import { PagedRequest, PagedResult } from '../../../../store';
import { WorkflowItem } from '../store/types';

export interface Props {
  pagedResults?: PagedResult<WorkflowItem>;
  pagedRequest: PagedRequest;
  handlePagedRequest: (pagedRequest: PagedRequest) => void;
  progress: LoadingProgress;
  refreshGrid: () => void;
}

export function WorkflowListTable(props: Props): JSX.Element {
  const { pagedResults, pagedRequest, handlePagedRequest, progress, refreshGrid } = props;
  const columns: DatatableColumn[] = [
    {
      filterDataType: FilterDataType.string,
      name: 'id',
      label: 'ID',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      filterDataType: FilterDataType.string,
      name: 'name',
      label: 'NAME',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      filterDataType: FilterDataType.string,
      name: 'clientName',
      label: 'CLIENT',
      options: {
        filter: true,
        sort: true,
        customBodyRenderLite: (dataIndex) => {
          if ((pagedResults?.results || [])[dataIndex] == null) return '';
          const clientName = pagedResults?.results[dataIndex].clientName;
          return clientName === null || clientName === 'undefined' ? pagedResults?.results[dataIndex].entityId : clientName;
        },
      },
    },
    {
      filterDataType: FilterDataType.string,
      name: 'adviserName',
      label: 'ADVISER',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      filterDataType: FilterDataType.string,
      name: 'afslName',
      label: 'AFSL',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      filterDataType: FilterDataType.string,
      name: 'practiceName',
      label: 'PRACTICE',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      filterDataType: FilterDataType.string,
      name: 'businessStatus',
      label: 'STATUS',
      options: {
        filter: true,
        sort: true,
        customBodyRenderLite: (dataIndex) => {
          if ((pagedResults?.results || [])[dataIndex] == null) return '';
          return getBusinessStatus(dataIndex);
        },
      },
    },
    {
      name: 'createdOn',
      label: 'CREATED',
      options: {
        filter: true,
        sort: true,
        customBodyRenderLite: (dataIndex) => {
          if ((pagedResults?.results || [])[dataIndex] == null) return '';
          return getLocalDateTime(pagedResults?.results[dataIndex].createdOn, DateTimeFormat.DateTime);
        },
      },
    },
    {
      name: 'modifiedOn',
      label: 'LAST CHANGED',
      options: {
        filter: false,
        sort: true,
        customBodyRenderLite: (dataIndex) => {
          if ((pagedResults?.results || [])[dataIndex] == null) return '';
          return getLocalDateTime(pagedResults?.results[dataIndex].modifiedOn, DateTimeFormat.DateTime);
        },
      },
    },
    {
      name: 'createdBy',
      label: 'CREATED BY',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'lastChangedBy',
      label: 'LAST CHANGED BY',
      options: {
        filter: false,
        sort: true,
      },
    },
  ];

  const getBusinessStatus = (dataIndex: number): React.ReactNode => {
    const errorMessage = pagedResults?.results[dataIndex].errorMessage;
    const name = pagedResults?.results[dataIndex].name;
    let businessStatus = pagedResults?.results[dataIndex].businessStatus;
    businessStatus = businessStatus ? businessStatus : 'Unspecified';
    //only show error message for super member data workflow for now
    return errorMessage && name?.includes('SuperSimplifierMemberDataUploadWorkflow') ? customStatusColumn(businessStatus, errorMessage) : businessStatus;
  };

  const customStatusColumn = (status: string, errorMessage: string): React.ReactNode => {
    return (
      <Tooltip
        title={
          <React.Fragment>
            <Typography color="inherit">{errorMessage}</Typography>
          </React.Fragment>
        }
      >
        <Typography style={{ color: 'red' }}>{status}</Typography>
      </Tooltip>
    );
  };
  const addButton = (): React.ReactNode => (
    <span>
      <Tooltip title="Refresh">
        <IconButton
          onClick={() => {
            refreshGrid();
          }}
        >
          <RefreshRounded style={{ cursor: 'pointer', color: '#757575' }}></RefreshRounded>
        </IconButton>
      </Tooltip>
    </span>
  );

  return (
    <Paper elevation={0}>
      <ServerSideDataTable
        loadingProgress={progress}
        columns={columns}
        pagedRequest={pagedRequest}
        pagedResult={pagedResults}
        options={{
          filter: true,
          viewColumns: true,
          customToolbar: () => addButton(),
        }}
        handlePagedRequest={handlePagedRequest}
      ></ServerSideDataTable>
    </Paper>
  );
}
