import { combineReducers } from '@reduxjs/toolkit';
import { EditableCollection } from '../../../../../common/store/types/editableCollection';
import { AddressDetails, ContactsState, MainDetailsState } from '../../common/store/types';
import { accountsSlice } from '../accounts/store/slice';
import { AccountsState } from '../accounts/store/types';
import { addressesSlice } from '../addresses/store/slice';
import { adviceFeesSlice } from '../adviceFees/store/slice';
import { AdviceFeesState } from '../adviceFees/store/types';
import { contactsSlice } from '../contacts/store/slice';
import { documentsSlice } from '../documents/store/slice';
import { DocumentsState } from '../documents/store/types';
import { investmentServicesSlice } from '../investmentServices/store/slice';
import { InvestmentServicesState } from '../investmentServices/store/types';
import { mainDetailsSlice } from '../mainDetails/store/slice';
import { trusteesSlice } from '../trustees/store/slice';
import { Trustee, TrusteeAbbreviated } from '../trustees/store/types';
import { BenefitStatementState } from '../benefitStatement/store/types';
import { benefitStatementSlice } from '../benefitStatement/store/slice';
import { SuperSimplifierBeneficiariesState } from '../superSimplifier/store/types';
import { superSimplifierSlice } from '../superSimplifier/store/slice';

export interface DetailsState {
  addresses: EditableCollection<AddressDetails, number>;
  mainDetails: MainDetailsState;
  contacts: ContactsState;
  documents: DocumentsState;
  accounts: AccountsState;
  trustees: EditableCollection<TrusteeAbbreviated, Trustee>;
  adviceFees: AdviceFeesState;
  investmentServices: InvestmentServicesState;
  benefitStatement: BenefitStatementState;
  superSimplifierbeneficiaries: SuperSimplifierBeneficiariesState;
}

export const detailsReducer = combineReducers<DetailsState>({
  addresses: addressesSlice.reducer,
  mainDetails: mainDetailsSlice.reducer,
  contacts: contactsSlice.reducer,
  documents: documentsSlice.reducer,
  accounts: accountsSlice.reducer,
  trustees: trusteesSlice.reducer,
  adviceFees: adviceFeesSlice.reducer,
  investmentServices: investmentServicesSlice.reducer,
  benefitStatement: benefitStatementSlice.reducer,
  superSimplifierbeneficiaries: superSimplifierSlice.reducer,
});
