import { Alert, Box, Link, Typography } from '@mui/material';
import React from 'react';
import { Props } from '../container';

export default function ValidationMessages(props: Props): JSX.Element {
  const { validationMessages, setBulkOrderValidated, preApprovalInvestmentServices, investmentServicesLockedByOrders } = props;

  return (
    <Box marginTop={'15px'} marginBottom={'15px'} marginLeft={'10px'} marginRight={'10px'}>
      {validationMessages && validationMessages.errors && validationMessages.errors.length > 0 && (
        <Alert style={{ marginRight: '20px' }} variant="outlined" severity="error">
          <Typography>
            The following trade(s) require your attention. Please review and update them before submitting again. Please contact us on 1300 726 008 or{' '}
            <a href="mailto: admin@wealtho2.com.au">admin@wealtho2.com.au</a> for assistance.
          </Typography>
          <ul style={{ textAlign: 'left' }}>
            {validationMessages.errors.map((errors) => (
              <li key={errors}>{errors}</li>
            ))}
          </ul>
        </Alert>
      )}
      {validationMessages && validationMessages.warnings && validationMessages.warnings.length > 0 && (
        <Alert style={{ marginRight: '20px' }} variant="outlined" severity="warning">
          {validationMessages.errors && validationMessages.errors.length > 0 && (
            <Typography>
              The following trade(s) are outside the portfolio`&#39;`s parameters. You can update them or proceed to the bulk order checkout after correcting
              the errors above.
            </Typography>
          )}
          {!validationMessages.errors ||
            (validationMessages.errors.length === 0 && (
              <Typography>
                The following trade(s) are outside the portfolio`&#39;`s parameters. You can update them or proceed to the bulk order checkout{' '}
                <Link
                  onClick={() =>
                    setBulkOrderValidated({
                      order: preApprovalInvestmentServices,
                      validationResult: validationMessages,
                      investmentServicesLockedByOrders: investmentServicesLockedByOrders,
                    })
                  }
                >
                  here
                </Link>
                .
              </Typography>
            ))}
          <ul style={{ textAlign: 'left' }}>
            {validationMessages.warnings.map((warning) => (
              <li key={warning}>{warning}</li>
            ))}
          </ul>
        </Alert>
      )}
      {validationMessages && validationMessages.information && validationMessages.information.length > 0 && (
        <Alert style={{ marginRight: '20px' }} variant="outlined" severity="info">
          {validationMessages.errors && validationMessages.errors.length > 0 && (
            <Typography>
              The following trade(s) are outside the portfolio`&#39;`s parameters. You can update them or proceed to the bulk order checkout after correcting
              the errors above.
            </Typography>
          )}
          {!validationMessages.errors ||
            (validationMessages.errors.length === 0 && (
              <Typography>
                The following trade(s) are outside the portfolio`&#39;`s parameters. You can update them or proceed to the bulk order checkout <Link>here</Link>
                .
              </Typography>
            ))}
          <ul style={{ textAlign: 'left' }}>
            {validationMessages.information.map((information) => (
              <li key={information}>{information}</li>
            ))}
          </ul>
        </Alert>
      )}
    </Box>
  );
}
