import { createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../../../../../app/api';
import { buildEncodedQueryString } from '../../../../../../common';
import { FeeForClientGroupModel, SaveAdviceFeesPayload } from './types';

export enum AdviceFeesActionTypes {
  FetchAdviceFees = '@@client/details/adviceFees/FetchAdviceFees',
  LoadAdviceFees = '@@client/details/adviceFees/LoadAdviceFees',
  SaveAdviceFees = '@@client/details/adviceFees/SaveAdviceFees',
}

export enum AdviceFeesApiEndpoints {
  FetchAdviceFees = '/fees/GetFeesForClient',
  SaveAdviceFees = '/fees/SaveClientFees',
  LoadAdviceFees = '/fees/LoadClientFees',
}

export const fetchAdviceFees = createAsyncThunk(AdviceFeesActionTypes.FetchAdviceFees, async (payload: { clientId: number; isImported: boolean }) => {
  const queryString = buildEncodedQueryString({
    clientId: payload.clientId,
  });
  const response = await api.get<FeeForClientGroupModel[]>(`${AdviceFeesApiEndpoints.FetchAdviceFees}${queryString}`);
  return {
    results: response.data,
    isImported: payload.isImported,
  };
});

export const loadAdviceFees = createAsyncThunk(AdviceFeesActionTypes.LoadAdviceFees, async (clientId: number, thunkApi) => {
  const queryString = buildEncodedQueryString({
    clientId,
  });
  return await api
    .post(`${AdviceFeesApiEndpoints.LoadAdviceFees}${queryString}`)
    .then(() => {
      thunkApi.dispatch(fetchAdviceFees({ clientId, isImported: true }));
    })
    .catch(() => {
      return { message: 'Failed to import Advice Fees', variant: 'error' };
    });
});

export const saveAdviceFees = createAsyncThunk(
  AdviceFeesActionTypes.SaveAdviceFees,
  async (payload: { adviceFees: SaveAdviceFeesPayload; message: string }, thunkApi) => {
    return await api
      .post(AdviceFeesApiEndpoints.SaveAdviceFees, payload.adviceFees)
      .then(() => {
        thunkApi.dispatch(fetchAdviceFees({ clientId: payload.adviceFees.clientId, isImported: false }));

        return { message: payload.message };
      })
      .catch(() => {
        return { message: 'Failed to save Advice Fees', variant: 'error' };
      });
  }
);
