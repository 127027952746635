import { connect, ConnectedProps } from 'react-redux';
import { progressSelectorFactory } from '../../../../../common';
import { RootState } from '../../../../../reducers';
import { selectHasClientEditAdminOnly } from '../../../../../store/selectors';
import { selectClientId, selectSuperSimplifierDetails } from '../../common/store/selectors';
import { CommonActionTypes } from '../../common/store/thunks';
import { AdviceFees } from './components/adviceFees';
import {
  selectAdviceFeeEdit,
  selectAdviceFeesValues,
  selectClientStatus,
  selectEstimatedFeesItems,
  selectTieredFeeDetailsEdit,
  selectTieredFeeDetailsItems,
} from './store/selectors';
import { adviceFeesSlice } from './store/slice';
import { AdviceFeesActionTypes, fetchAdviceFees, loadAdviceFees, saveAdviceFees } from './store/thunks';

const mapStateToProps = (state: RootState) => ({
  clientId: selectClientId(state),
  superSimplifierItem: selectSuperSimplifierDetails(state),
  clientStatus: selectClientStatus(state),
  adviceFeesValues: selectAdviceFeesValues(state),
  estimatedFeesItems: selectEstimatedFeesItems(state),
  selectedFeeItem: selectAdviceFeeEdit(state),
  tieredFeeDetailsItems: selectTieredFeeDetailsItems(state),
  selectedTieredFeeDetailsEdit: selectTieredFeeDetailsEdit(state),
  hasClientEditAdminOnlyPermission: selectHasClientEditAdminOnly(state),
  loadingProgress: progressSelectorFactory([
    CommonActionTypes.FetchSuperSimplifier,
    AdviceFeesActionTypes.FetchAdviceFees,
    AdviceFeesActionTypes.LoadAdviceFees,
  ])(state),
  savingProgress: progressSelectorFactory([AdviceFeesActionTypes.SaveAdviceFees])(state),
});

const mapDispatchToProps = {
  ...adviceFeesSlice.actions,
  fetchAdviceFees,
  loadAdviceFees,
  saveAdviceFees,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export type Props = ConnectedProps<typeof connector>;
export const AdviceFeesContainer = connector(AdviceFees);
