import { Grid, Typography } from '@mui/material';
import { Field, Form, Formik, FormikProps } from 'formik';
import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import { FormikEnumerationSelect, FormikKeyboardDatePicker, FormikSelect, FormikTextField } from '../formik';
import { WO2Modal } from './../';
import { LoadingIndicator } from './../../components/LoadingIndicator';
import { LoadingProgress } from './../../store/types';
import { getFieldsToDisplay } from './accountViewHelper';
import { Account, AccountType, AccountView, externalAccountTypesIds, SecurityStatus } from './types';

export interface AccountViewProps {
  accountTypes: AccountType[];
  account: Account | null;
  bsb: string;
  loadingProgress: LoadingProgress;
  loadingTypesProgress: LoadingProgress;
  isReadOnly: boolean;
  isExternal: boolean;
  handleCloseModal: () => void;
  fetchAccountTypes: () => void;
}

interface FormValues extends Partial<AccountView> {
  securityStatusText: string;
}

export const WO2AccountView = ({
  isReadOnly,
  isExternal,
  loadingProgress,
  account,
  accountTypes,
  handleCloseModal,
  fetchAccountTypes,
  bsb,
}: AccountViewProps): JSX.Element => {
  const initialFormValues: FormValues = {
    accountId: null,
    accountTypeId: null,
    accountName: '',
    accountNumber: '',
    bsb: '',
    institutionId: null,
    institution: '',
    securityStatus: null,
    securityStatusText: '',
    isExternal: isExternal,
  };

  const [formValues, setFormValues] = useState<FormValues>(initialFormValues);
  const [fieldsToDisplay, setFieldsToDisplay] = useState<string[]>(getFieldsToDisplay(initialFormValues.accountTypeId || -1));

  useEffect(() => {
    fetchAccountTypes();
  }, [fetchAccountTypes]);

  useEffect(() => {
    if (!!account) {
      // TODO: Impelment fix for correct BSB for #6560
      setFormValues({
        ...account,
        securityStatusText: !!account.securityStatus ? SecurityStatus.getById(account.securityStatus)?.name || '' : '',
        bsb,
      });
      setFieldsToDisplay(getFieldsToDisplay(account.accountTypeId));
    }
  }, [account, bsb]);

  return (
    <WO2Modal
      MuiDialogProps={{
        open: true,
        onClose: handleCloseModal,
      }}
      title={<>ACCOUNT DETAILS</>}
      actions={[]}
    >
      <Formik<FormValues>
        initialValues={formValues}
        onSubmit={() => {
          /* void */
        }}
        enableReinitialize={true}
        validationSchema={yup.object({
          accountTypeId: yup.number().nullable().required('Type required'),
          securityStatus: yup.string().nullable().required('Status required'),
          accountName: yup.string().required('Name required'),
          accountNumber: yup.string().required('Account number required'),
          institution: yup.string().required('Institution required'),
        })}
      >
        {(props: FormikProps<FormValues>) => (
          <Form>
            <fieldset style={{ border: 'none', padding: '0' }} disabled={isReadOnly && props.values.accountId !== null}>
              <Typography variant="h3" style={{ padding: '20px 0 20px 0' }}>
                {account?.accountName}
              </Typography>
              <LoadingIndicator progress={loadingProgress}>
                <Grid container>
                  {/* first column */}
                  <Grid item xs={6} style={{ paddingRight: '20px' }}>
                    <Grid item xs={12} style={{ paddingBottom: '20px' }}>
                      <Field
                        fieldName="accountTypeId"
                        as={FormikSelect}
                        itemDisplayNameField="type"
                        label="ACCOUNT TYPE"
                        id="accountTypeIdSelect"
                        data={isReadOnly ? accountTypes : accountTypes.filter((t) => externalAccountTypesIds.find((e) => e === t.id))}
                        onChange={(accountTypeId: number | null) => {
                          setFieldsToDisplay(getFieldsToDisplay(accountTypeId));
                        }}
                        fullWidth
                        disabled={isReadOnly}
                      ></Field>
                    </Grid>
                    <Grid item xs={12} style={{ paddingBottom: '20px' }}>
                      <Field
                        component={FormikEnumerationSelect}
                        type={SecurityStatus}
                        showNone={true}
                        name="securityStatus"
                        label="STATUS"
                        fullWidth
                        disabled={isReadOnly}
                        valueIsId={true}
                      />
                    </Grid>
                    {fieldsToDisplay.find((f) => f === 'bsb') && (
                      <Grid item xs={12} style={{ paddingBottom: '20px' }}>
                        <Field name="bsb" component={FormikTextField} label="BSB" fullWidth></Field>
                      </Grid>
                    )}
                    {fieldsToDisplay.find((f) => f === 'duration') && (
                      <Grid item xs={12} style={{ paddingBottom: '20px' }}>
                        <Field name="duration" component={FormikTextField} label="DURATION" fullWidth></Field>
                      </Grid>
                    )}
                    {fieldsToDisplay.find((f) => f === 'brokerNumber') && (
                      <Grid item xs={12} style={{ paddingBottom: '20px' }}>
                        <Field name="brokerNumber" component={FormikTextField} label="BROKER NUMBER" fullWidth></Field>
                      </Grid>
                    )}
                    {fieldsToDisplay.find((f) => f === 'accountDesignation') && (
                      <Grid item xs={12} style={{ paddingBottom: '20px' }}>
                        <Field name="accountDesignation" component={FormikTextField} label="ACCOUNT DESIGNATION" fullWidth></Field>
                      </Grid>
                    )}
                  </Grid>
                  {/* second column */}
                  <Grid item xs={6}>
                    <Grid item xs={12} style={{ paddingBottom: '20px' }}>
                      <Field name="accountName" component={FormikTextField} label="NAME" fullWidth></Field>
                    </Grid>
                    <Grid item xs={12} style={{ paddingBottom: '20px' }}>
                      <Field name="accountNumber" component={FormikTextField} label="ACCOUNT NUMBER" fullWidth></Field>
                    </Grid>
                    {fieldsToDisplay.find((f) => f === 'broker') && (
                      <Grid item xs={12} style={{ paddingBottom: '20px' }}>
                        <Field name="broker" component={FormikTextField} label="BROKER" fullWidth></Field>
                      </Grid>
                    )}
                    {fieldsToDisplay.find((f) => f === 'rate') && (
                      <Grid item xs={12} style={{ paddingBottom: '20px' }}>
                        <Field name="rate" component={FormikTextField} label="RATE" fullWidth></Field>
                      </Grid>
                    )}
                    {fieldsToDisplay.find((f) => f === 'maturityDate') && (
                      <Grid item xs={12} style={{ paddingBottom: '20px' }}>
                        <Field
                          component={FormikKeyboardDatePicker}
                          name="maturityDate"
                          label="MATURITY DATE"
                          maxDateMessage="From Date should not be after To Date"
                          fullWidth
                        />
                      </Grid>
                    )}
                    {fieldsToDisplay.find((f) => f === 'hin') && (
                      <Grid item xs={12} style={{ paddingBottom: '20px' }}>
                        <Field name="hin" component={FormikTextField} label="HIN" fullWidth></Field>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </LoadingIndicator>
            </fieldset>
          </Form>
        )}
      </Formik>
    </WO2Modal>
  );
};
