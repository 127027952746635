import { DateRange } from '../../../../../store';
import { ClientPortfolio } from '../../common/store/types';

export interface Report {
  clientName: string;
  clientId: number;
  fromDate: string;
  toDate: string;
  dateRangeType: string;
  includeLogo: boolean;
  visibleToAfsl: boolean;
  parameterName: string;
  includeCoverLetter: boolean;
  includeAppendix: boolean;
  coverLetter?: File;
  appendix?: File;
  mergeReportOrder: number[];
  cashTransactions?: {
    fromDate: string;
    toDate: string;
    dateRangeType: string;
  };
  expenses?: {
    hidingOptions: HidingOptions;
    fromDate: string;
    toDate: string;
    dateRangeType: string;
  };
  transactions?: {
    hidingOptions: string;
    filteringOptions: string;
    sortBy: SortByTransactions;
    fromDate: string;
    toDate: string;
    dateRangeType: string;
  };
  incomeReceived?: {
    filteringOptions: string;
    sortBy: SortByIncomeReceived;
    thenSortBy: SecondarySortByIncomeReceived;
    fromDate: string;
    toDate: string;
    dateRangeType: string;
  };
  portfolioValuation?: {
    hidingOptions: string;
    sortBy: SortByPortfolioValuation;
    groupBy: GroupByPortfolioValuation;
    asAtDate: string;
    asAtDateType: string;
  };
  assetAllocation?: {
    investmentProgramIds: number[];
    asAtDate: string;
    asAtDateType: string;
  };
  gainsLossesUnrealised?: {
    asAtDate: string;
    asAtDateType: string;
  };
  gainsLossesRealised?: {
    fromDate: string;
    toDate: string;
    dateRangeType: string;
  };
  securityPerformance?: {
    fromDate: string;
    toDate: string;
    dateRangeType: string;
  };
}

export interface ClientReportsState {
  parameters: ClientReportsParameters;
  reportResult?: ReportResult | null;
  reportGeneration: ReportGeneration;
  savedParametersList: SavedParameter[];
}

export interface ReportGeneration {
  isGenerating: boolean;
  requestId: string;
  isExpired: boolean;
  key: string;
  isMergeReport: boolean;
  clientId: number;
  clientName: string;
  isGenerated: boolean;
  mergeReportOrder: number[];
  dateRange: string;
  reportName: string;
}

export enum HidingOptions {
  selected = 'ExpenseType',
  notSelected = '',
}

export interface HidingOptionsTransactions {
  assetDescription: boolean;
  accounts: boolean;
  summary: boolean;
}

export enum SortByTransactions {
  account = 'Account',
  tradeDate = 'TradeDate',
  security = 'Security',
}

export enum HidingOptionTransactions {
  assetDescription = 'AssetDescription',
  accounts = 'Accounts',
  summary = 'Summary',
}

export interface FilterTransactions {
  excludeCash: boolean;
  includeDistribution: boolean;
}

export enum FilterByTransactions {
  excludeCash = 'ExcludeCash',
  includeDistribution = 'IncludeDistribution',
}

export interface FilterIncomeReceived {
  excludeCash: boolean;
}

export enum SortByIncomeReceived {
  AccountFullInformation = 'AccountFullInformation',
  PaymentDate = 'PaymentDate',
  IncomeType = 'IncomeType',
}

export enum SecondarySortByIncomeReceived {
  DateAscending = 'DateAscending',
  DateDescending = 'DateDescending',
}

export interface HidingOptionsPortfolioValuation {
  securityType: boolean;
  securityCode: boolean;
  unrealisedGain: boolean;
  gics: boolean;
  costBase: boolean;
  investmentService: boolean;
  market: boolean;
  securityName: boolean;
  avgUnitCost: boolean;
}

export enum HideByPortfolioValuation {
  securityType = 'SecurityType',
  costBase = 'CostBase',
  Gics = 'Gics',
  market = 'Market',
  unrealisedGain = 'UnrealisedGain',
  portfolio = 'InvestmentService',
  avgUnitCost = 'AvgUnitCost',
}

export enum SortByPortfolioValuation {
  SecurityType = 'SecurityType',
  SecurityName = 'SecurityName',
  Gics = 'Gics',
  Model = 'Model',
  Portfolio = 'Portfolio',
  AssetClass = 'AssetClass',
}

export enum GroupByPortfolioValuation {
  AssetClass = 'AssetClass',
  Gics = 'Gics',
  InvestmentService = 'InvestmentService',
  Model = 'Model',
}

export interface SelectedReports {
  incomeReceived: {
    selected: boolean;
    sortBy: SortByIncomeReceived;
    secondarySortBy: SecondarySortByIncomeReceived;
    filter: FilterIncomeReceived;
    dates: DateRange;
  };
  transactions: {
    selected: boolean;
    hidingOptions: HidingOptionsTransactions;
    sortBy: SortByTransactions;
    filter: FilterTransactions;
    dates: DateRange;
  };
  cashTransactions: {
    selected: boolean;
    dates: DateRange;
  };
  expenses: {
    selected: boolean;
    hidingOptions: HidingOptions;
    dates: DateRange;
  };
  gainsLossesUnrealised: {
    selected: boolean;
    dates: DateRange;
  };
  gainsLossesRealised: {
    selected: boolean;
    dates: DateRange;
  };
  securityPerformance: {
    selected: boolean;
    dates: DateRange;
  };
  portfolioValuation: {
    selected: boolean;
    hidingOptions: HidingOptionsPortfolioValuation;
    groupBy: GroupByPortfolioValuation;
    sortBy: SortByPortfolioValuation;
    dates: DateRange;
  };
  assetAllocation: {
    selected: boolean;
    investmentPrograms: ClientPortfolio[];
    dates: DateRange;
  };
}

export interface ClientReportsParameters {
  clientName: string;
  inceptionDate: string;
  showItemOrdering: boolean;
  dates: DateRange;
  mergeReports: boolean;
  includeLogo: boolean;
  reportingParameter: ReportingParameter;
  reportsToSort: ReportToSort[];
  selectedReports: SelectedReports;
  selectedParameter?: SavedParameter | null;
  includeCoverLetter: boolean;
  includeAppendix: boolean;
  coverLetter?: File;
  appendix?: File;
}

export interface ReportingParameter {
  visibleToAfsl: boolean;
  parameterName: string;
  isSaveReportParameters: boolean;
}

export interface ReportResult {
  data: [];
}

export interface Expenses {
  hidingOptions: HidingOptions;
}

export interface ReportToSort {
  id: number;
  text: string;
}

export enum ReportAttachmentType {
  None = 'None',
  CoverLetter = 'Cover Letter',
  Appendix = 'Appendix',
}

export interface ReportAttachment {
  ReportType: ReportAttachmentType;
  isIncluded: boolean;
  Content?: File;
}

export enum ReportNames {
  Expenses = 'Expenses',
  Transactions = 'Transactions',
  IncomeReceived = 'IncomeReceived',
  PortfolioValuation = 'PortfolioValuation',
  AssetAllocation = 'AssetAllocation',
  CashTransactions = 'CashTransactions',
  GainsLossesRealised = 'Gains&LossesRealised',
  GainsLossesUnrealised = 'Gains&LossesUnrealised',
  SecurityPerformance = 'SecurityPerformance',
}

export interface SavedParameter {
  id: string;
  name: string;
}

export interface FetchReportParametersRequest {
  requestId: string;
  afslId: number;
}

export interface ReportingParameters {
  clientId: number;
  includeLogo: boolean;
  fromDate: string;
  toDate: string;
  dateRangeType: string;
  additionalDocuments: {
    includeCoverLetter: boolean;
    includeAppendix: boolean;
    coverLetter?: string;
    appendix?: string;
  };
  mergeReportOrder: number[];
  cashTransactions?: {
    fromDate: string;
    toDate: string;
    dateRangeType: string;
  };
  expenses?: {
    hidingOptions: string;
    fromDate: string;
    toDate: string;
    dateRangeType: string;
  };
  transactions?: {
    hidingOptions: string;
    filteringOptions: string;
    sortBy: SortByTransactions;
    fromDate: string;
    toDate: string;
    dateRangeType: string;
  };
  incomeReceived?: {
    filteringOptions: string;
    sortBy: SortByIncomeReceived;
    thenSortBy: SecondarySortByIncomeReceived;
    fromDate: string;
    toDate: string;
    dateRangeType: string;
  };
  portfolioValuation?: {
    hidingOptions: string;
    sortBy: SortByPortfolioValuation;
    groupBy: GroupByPortfolioValuation;
    asAtDate: string;
    asAtDateType: string;
  };
  assetAllocation?: {
    investmentProgramIds: number[];
    asAtDate: string;
    asAtDateType: string;
  };
  gainsLossesUnrealised?: {
    asAtDate: string;
    asAtDateType: string;
  };
  gainsLossesRealised?: {
    fromDate: string;
    toDate: string;
    dateRangeType: string;
  };
  securityPerformance?: {
    fromDate: string;
    toDate: string;
    dateRangeType: string;
  };
}
