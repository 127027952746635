import {
  AssignmentTurnedInRounded,
  AttachEmailRounded,
  Delete,
  FileDownloadOutlined,
  FileUploadOutlined,
  Info,
  OpenInNew,
  SendOutlined,
  SendRounded,
  VisibilityOutlined,
} from '@mui/icons-material';
import AddSharpIcon from '@mui/icons-material/AddSharp';
import LinkIcon from '@mui/icons-material/Link';
import { Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useCallback, useEffect, useState } from 'react';
import { PendingAccount, PendingAccountStatus, PendingAccountWithActions, WO2PendingAccountActionType } from 'src/common/components/accounts/types';
import { WO2LinkExistingAccount } from '../../../../../../common/components/accounts/forms/linkExistingAccount';
import { WO2OpenNewAccount } from '../../../../../../common/components/accounts/forms/openNewAccount';
import WO2Button from '../../../../../../common/components/button/Button';
import { Props } from '../container';
import { PendingAccountType } from '../store/enum';
import { CreatePendingAccountPayload, InitiateExistingUxchangeAccountApplicationPayload, SuperSimplifierUploadDocument } from '../store/types';
import { WO2Accounts } from './../../../../../../common/components/accounts/Account';
import { WO2PendingAccounts } from './../../../../../../common/components/accounts/PendingAccounts';
import { DetachAccountPayload, ExternalAccount, UnlistedAccount, WO2AccountActionType } from './../../../../../../common/components/accounts/types';
import { LoadingIndicator } from './../../../../../../common/components/LoadingIndicator';
import { AttachmentType, ClientAccountSubType, StatusType } from './../../../../../../common/types';
import { Acknowledgement } from './acknowledgement';
import { UploadApplicationForm } from './uploadApplicationForm';

const useStyles = makeStyles(() => ({
  datatable: {
    '& .MuiToolbar-root': {
      minHeight: '0',
    },
  },
}));

export const Accounts = ({
  accountTypes,
  clientId,
  cancelExternalAccountAddEditMode,
  cancelTradingAccountAddEditMode,
  clientContactDetails,
  clientDetails,
  createUnlistedAccount,
  createExternalAccount,
  documentTypes,
  downloadSuperSimplifierApplication,
  downloadSuperSimplifierOngoingFeeConsentRenewal,
  downloadSuperSimplifierApplicationLoadingProgress,
  downloadConsentFeeRenewalLoadingProgress,
  externalAccounts,
  attachableCmaAccounts,
  existingAccounts,
  newAccounts,
  externalInstitutions,
  fetchAccounts,
  fetchAccountTypes,
  fetchClientAccount,
  fetchDocumentTypes,
  fetchExternalAccountForEdit,
  fetchExternalInstitutions,
  fetchTradingAccountForEdit,
  hasClientEditPermission,
  isSuperSimplifierClient,
  loadingProgress,
  loadingTypesProgress,
  loadingPendingProgress,
  onDetachAccount,
  submitOpenMarkets,
  submitAMM,
  submitDesktopBroker,
  selectedExternalAccount,
  saveExternalAccountLoadingProgress,
  saveUnlistedAccountProgress,
  selectedTradingAccount,
  superSimplifierDetails,
  superSimplifierLoadingProgress,
  sendApplicationForm,
  sendApplicationLoadingProgress,
  sendFeeConsentRenewal,
  setExternalAccountAddMode,
  setTradingAccountAddMode,
  tradingAccounts,
  uploadSuperSimplifierFormLoadingProgress,
  uploadSuperSimplifierForm,
  fetchEntityDetails,
  pendingAccounts,
  fetchPendingAccounts,
  fetchAttachableCmaAccounts,
  createPendingAccount,
  savePendingAccountsLoadingProgress,
  linkExsistingPendingAccountLoadingProgress,
  initiateExistingMacquarieAccountApplication,
  initiateNewMacquarieAccountApplication,
  initiateUxchangeworkflowAccountApplicationWorkflow,
  fetchClientNewMacquarieAccountDetailByClientId,
  macquarieCashOnlinePortal,
  deletePendingAccount,
  updatePendingAccountStatus,
  hasClientEditAdminOnlyPermission,
}: Props): JSX.Element => {
  const [shouldOpenSuperSimplifierOngoingFeeConsentForm, setSuperSimplifierOngoingFeeConsentForm] = useState<boolean>(false);
  const [shouldOpenSuperSimplifierApplication, setSuperSimplifierApplication] = useState<boolean>(false);
  const [hideSendApplicationAcknowledgement, setHideSendApplicationAcknowledgement] = useState<boolean>(true);
  const [sendApplicationAcknowledgement, setSendApplicationAcknowledgement] = useState<boolean>(false);
  const [selectedAccountsDialogType, setSelectedAccountsDialogType] = useState<number | null>(null);
  const [submitAccountId, setSubmitAccountId] = React.useState<number | null>(null);
  const [deleteAccountId, setDeleteAccountId] = React.useState<number | null>(null);

  useEffect(() => {
    if (clientId !== null && !!sendApplicationAcknowledgement) {
      sendApplicationForm({
        clientId,
        anchorString: 'sign here',
        isIndividualPension: clientDetails?.subTypeId === ClientAccountSubType.Pension.id,
        isIndividualSuper: clientDetails?.subTypeId === ClientAccountSubType.Super.id,
      });
      setSendApplicationAcknowledgement(false);
      setHideSendApplicationAcknowledgement(true);
    }
  }, [
    setSendApplicationAcknowledgement,
    setHideSendApplicationAcknowledgement,
    sendApplicationAcknowledgement,
    hideSendApplicationAcknowledgement,
    sendApplicationForm,
    clientDetails,
    clientId,
  ]);

  useEffect(() => {
    if (!!clientId) {
      fetchAccounts(clientId);
      fetchPendingAccounts(clientId);
      fetchAttachableCmaAccounts(clientId);
      fetchEntityDetails(clientId);
    }
  }, [fetchAccounts, fetchPendingAccounts, clientId, fetchEntityDetails]);
  const classes = useStyles();

  const init = useCallback(() => {
    fetchExternalInstitutions();

    if (clientId !== null) {
      fetchClientAccount(clientId);
    }

    fetchDocumentTypes();
  }, [fetchExternalInstitutions, fetchDocumentTypes, fetchClientAccount, clientId]);

  useEffect(() => {
    init();
  }, [init]);

  const handleLinkExistingAccountButtonClick = useCallback(() => {
    setSelectedAccountsDialogType(PendingAccountType.LinkExistingAccounts);
  }, [setSelectedAccountsDialogType]);

  const handleOpenNewAccountButtonClick = useCallback(() => {
    setSelectedAccountsDialogType(PendingAccountType.OpenNewAccounts);
  }, [setSelectedAccountsDialogType]);

  const onSaveLinkExistingAccount = (accountData: CreatePendingAccountPayload) => {
    createPendingAccount(accountData).then(() => setSelectedAccountsDialogType(null));
  };

  const onSaveNewAccount = (accountData: CreatePendingAccountPayload) => {
    createPendingAccount(accountData).then(() => setSelectedAccountsDialogType(null));
  };

  // Super Simplifier
  let pendingClientttachmentTypeId: number | null = null;
  let superSimplifierActionItems: WO2AccountActionType[] = [];
  const isSuperSimplifierPendingClient = clientDetails !== null && !!clientDetails?.status && clientDetails?.status === StatusType.Pending.id ? true : false;
  const isSuperSimplifierActiveClient = clientDetails !== null && !!clientDetails?.status && clientDetails?.status === StatusType.Active.id ? true : false;

  if (!!isSuperSimplifierPendingClient) {
    if (clientDetails?.subTypeId === ClientAccountSubType.Pension.id) {
      pendingClientttachmentTypeId = AttachmentType.SuperSimplifierPension.id;
    } else if (clientDetails?.subTypeId === ClientAccountSubType.Super.id) {
      pendingClientttachmentTypeId = AttachmentType.SuperSimplifierSuper.id;
    }
    superSimplifierActionItems = [
      {
        label: 'Download Application Form',
        clickHandler: () => {
          if (clientId !== null) {
            downloadSuperSimplifierApplication({ clientId });
          }
        },
        icon: <FileDownloadOutlined color="primary" />,
        name: 'accountId',
      },
      {
        label: 'Upload Signed Application Form',
        clickHandler: () => {
          if (clientId !== null) {
            setSuperSimplifierApplication(true);
          }
        },
        icon: <FileUploadOutlined color="primary" />,
        name: 'accountId',
      },
      {
        label: 'Send Application',
        clickHandler: () => {
          setHideSendApplicationAcknowledgement(false);
        },
        icon: <SendOutlined color="primary" />,
        name: 'accountId',
      },
    ];
  } else if (!!isSuperSimplifierActiveClient) {
    superSimplifierActionItems = [
      {
        label: 'Download Ongoing Fee Consent Renewal',
        clickHandler: () => {
          if (clientId !== null) {
            downloadSuperSimplifierOngoingFeeConsentRenewal({ clientId });
          }
        },
        icon: <FileDownloadOutlined color="primary" />,
        name: 'accountId',
      },
      {
        label: 'Upload Signed Ongoing Fee Consent',
        clickHandler: () => {
          if (clientId !== null) {
            setSuperSimplifierOngoingFeeConsentForm(true);
          }
        },
        icon: <FileUploadOutlined color="primary" />,
        name: 'accountId',
      },
      {
        label: 'Send Fee Consent Renewal',
        clickHandler: () => {
          if (clientId !== null) {
            sendFeeConsentRenewal({
              clientId,
              anchorString: 'sign here',
            });
          }
        },
        icon: <SendOutlined color="primary" />,
        name: 'accountId',
      },
    ];
  }

  return (
    <>
      {(clientDetails?.subTypeId === 2 || clientDetails?.subTypeId === 3) && clientDetails?.status !== 2 && (
        <Typography variant="h4" gutterBottom style={{ marginTop: '40px' }}>
          Please complete the Super Simplifier application before opening third-party accounts. Any third-party accounts added during onboarding will display
          here when the Super Simplifier application is complete.
        </Typography>
      )}
      {!((clientDetails?.subTypeId === 2 || clientDetails?.subTypeId === 3) && clientDetails?.status !== 2) && (
        <Box marginTop="60px" display="flex" justifyContent="flex-end">
          <WO2Button
            color="primary"
            style={{ marginLeft: '20px', padding: '15px 28px' }}
            disableElevation
            variant="contained"
            value="Link Existing Account"
            data-testid="linkExistingAccountButton"
            onClick={handleLinkExistingAccountButtonClick}
          >
            <LinkIcon fontSize="medium" />
            <Typography variant="inherit" style={{ marginLeft: '10px' }}>
              Link Existing Account
            </Typography>
          </WO2Button>
          <WO2Button
            color="primary"
            style={{ marginLeft: '20px', padding: '15px 28px' }}
            disableElevation
            variant="contained"
            value="Open New Account"
            data-testid="openNewAccountButton"
            onClick={handleOpenNewAccountButtonClick}
          >
            <AddSharpIcon fontSize="small" />
            <Typography variant="inherit" style={{ marginLeft: '10px' }}>
              Open New Account
            </Typography>
          </WO2Button>
          <WO2LinkExistingAccount
            existingAccounts={existingAccounts}
            isOpen={selectedAccountsDialogType === PendingAccountType.LinkExistingAccounts}
            saveProgress={linkExsistingPendingAccountLoadingProgress}
            onSave={onSaveLinkExistingAccount}
            clientId={clientId}
            handleCloseModal={() => {
              setSelectedAccountsDialogType(null);
            }}
          />
          <WO2OpenNewAccount
            newAccounts={newAccounts}
            clientDetails={clientDetails}
            isOpen={selectedAccountsDialogType === PendingAccountType.OpenNewAccounts}
            saveProgress={savePendingAccountsLoadingProgress}
            onSave={onSaveNewAccount}
            clientId={clientId}
            handleCloseModal={() => {
              setSelectedAccountsDialogType(null);
            }}
          />
        </Box>
      )}
      {!!pendingAccounts?.length && !((clientDetails?.subTypeId === 2 || clientDetails?.subTypeId === 3) && clientDetails?.status !== 2) && (
        <>
          <Typography variant="h4" gutterBottom style={{ marginTop: '40px' }}>
            Pending accounts
          </Typography>

          <WO2PendingAccounts
            clientId={clientId}
            attachableCmaAccounts={attachableCmaAccounts.attachableCmaAccounts}
            accounts={pendingAccounts.map((account: PendingAccount): PendingAccountWithActions => {
              const desktopBrokerInstitutionId = 13;
              const openMarketsInstitutionId = 61;
              const ammInstitutionId = 6;
              const macquarieCmaInstitutionId = 10;
              const uxChangeInstitutionId = 81;
              const actions: WO2PendingAccountActionType[] = [];

              if (
                (account.institutionId === desktopBrokerInstitutionId ||
                  account.institutionId === openMarketsInstitutionId ||
                  account.institutionId === ammInstitutionId) &&
                account.state === 'Existing'
              ) {
                actions.push({
                  label: 'Submit instructions',
                  clickHandler: (accountId: number | null | undefined) => {
                    setSubmitAccountId(accountId || null);
                  },
                  icon: <Info color="primary" />,
                  name: 'id',
                });
              }

              if (
                clientDetails?.subTypeId !== ClientAccountSubType.Pension.id &&
                clientDetails?.subTypeId !== ClientAccountSubType.Super.id &&
                account.institutionId === ammInstitutionId &&
                account.state === 'New' &&
                account.isDataStatusReady &&
                account.statusId === PendingAccountStatus.getByName('Proposed')?.id
              ) {
                actions.push({
                  label: 'Submit application',
                  clickHandler: (accountId: number | null | undefined) => {
                    setSubmitAccountId(accountId || null);
                  },
                  icon: <SendRounded color="primary" />,
                  name: 'id',
                });
              }

              if (account.errors?.length) {
                actions.push({
                  label: 'View missing info',
                  clickHandler: (accountId: number | null | undefined) => {
                    setSubmitAccountId(accountId || null);
                  },
                  icon: <VisibilityOutlined color="primary" />,
                  name: 'id',
                });
              }

              if (
                account.institutionId === macquarieCmaInstitutionId &&
                account.state === 'Existing' &&
                account.statusId === PendingAccountStatus.getByName('Proposed')?.id
              ) {
                actions.push({
                  label: 'Generate form',
                  clickHandler: (accountId: number | null | undefined) => {
                    setSubmitAccountId(accountId || null);
                  },
                  icon: <AttachEmailRounded color="primary" />,
                  name: 'id',
                });
              }

              if (
                account.institutionId === macquarieCmaInstitutionId &&
                account.state === 'New' &&
                account.isDataStatusReady &&
                account.statusId === PendingAccountStatus.getByName('Proposed')?.id
              ) {
                actions.push({
                  label: 'Submit application',
                  clickHandler: (accountId: number | null | undefined) => {
                    setSubmitAccountId(accountId || null);
                  },
                  icon: <SendRounded color="primary" />,
                  name: 'id',
                });
              }

              if (
                account.institutionId === macquarieCmaInstitutionId &&
                account.state === 'New' &&
                account.statusId === PendingAccountStatus.getByName('Submitted')?.id
              ) {
                actions.push({
                  label: 'Access application',
                  clickHandler: (accountId: number | null | undefined) => {
                    setSubmitAccountId(accountId || null);
                  },
                  icon: <OpenInNew color="primary" />,
                  name: 'id',
                });
              }

              if (
                account.institutionId === uxChangeInstitutionId &&
                account.state === 'New' &&
                account.isDataStatusReady &&
                account.statusId === PendingAccountStatus.getByName('Proposed')?.id
              ) {
                actions.push({
                  label: 'Submit application',
                  clickHandler: (accountId: number | null | undefined) => {
                    setSubmitAccountId(accountId || null);
                  },
                  icon: <SendRounded color="primary" />,
                  name: 'id',
                });
              }

              if (
                account.institutionId === desktopBrokerInstitutionId &&
                account.state === 'New' &&
                account.isDataStatusReady &&
                account.statusId === PendingAccountStatus.getByName('Proposed')?.id
              ) {
                actions.push({
                  label: 'Submit application',
                  clickHandler: (accountId: number | null | undefined) => {
                    setSubmitAccountId(accountId || null);
                  },
                  icon: <SendRounded color="primary" />,
                  name: 'id',
                });
              }

              if (
                account.institutionId === openMarketsInstitutionId &&
                account.state === 'New' &&
                account.isDataStatusReady &&
                account.statusId === PendingAccountStatus.getByName('Proposed')?.id
              ) {
                actions.push({
                  label: 'Submit application',
                  clickHandler: (accountId: number | null | undefined) => {
                    setSubmitAccountId(accountId || null);
                  },
                  icon: <SendRounded color="primary" />,
                  name: 'id',
                });
              }

              if (
                hasClientEditAdminOnlyPermission &&
                (account.statusId === PendingAccountStatus.getByName('Submitted')?.id ||
                  (account.institutionId === ammInstitutionId &&
                    (clientDetails?.subTypeId === ClientAccountSubType.Pension.id || clientDetails?.subTypeId === ClientAccountSubType.Super.id)))
              ) {
                actions.push({
                  label: 'Mark as complete',
                  clickHandler: (accountId: number | null | undefined) => {
                    if (clientId && accountId) {
                      updatePendingAccountStatus({
                        clientId: clientId,
                        pendingAccountId: accountId.toString(),
                        status: 'Completed',
                        statusMessage: 'Marked as complete by WealthO2',
                      });
                    }
                  },
                  icon: <AssignmentTurnedInRounded color="primary" />,
                  name: 'id',
                });
              }

              if (account.statusId === 1) {
                actions.push({
                  label: 'Delete account',
                  clickHandler: (accountId: number | null | undefined) => {
                    setDeleteAccountId(accountId || null);
                  },
                  icon: <Delete color="primary" />,
                  name: 'id',
                });
              }

              const accountWithActions: PendingAccountWithActions = {
                ...account,
                actions,
              };
              return accountWithActions;
            })}
            hideAddButton={false}
            submitAccountId={submitAccountId}
            onOpenMarketsSubmit={(cmaAccount) => {
              if (submitAccountId && clientId) {
                submitOpenMarkets({
                  cmaName: cmaAccount.name,
                  cmaNumber: cmaAccount.number,
                  cmaSubNumber: cmaAccount.bsb,
                  forceRoa: cmaAccount.forceRoa,
                  clientId: clientId,
                  pendingAccountId: submitAccountId.toString(),
                }).then(() => {
                  setSubmitAccountId(null);
                });
              }
            }}
            onAMMSubmit={(cmaAccount) => {
              if (submitAccountId && clientId) {
                submitAMM({
                  cmaName: cmaAccount.name,
                  cmaNumber: cmaAccount.number,
                  cmaSubNumber: cmaAccount.bsb,
                  forceRoa: cmaAccount.forceRoa,
                  clientId: clientId,
                  pendingAccountId: submitAccountId.toString(),
                }).then(() => {
                  setSubmitAccountId(null);
                });
              }
            }}
            onDesktopBrokerSubmit={(cmaAccount) => {
              if (submitAccountId && clientId) {
                submitDesktopBroker({
                  cmaName: cmaAccount.name,
                  cmaNumber: cmaAccount.number,
                  cmaSubNumber: cmaAccount.bsb,
                  forceRoa: cmaAccount.forceRoa,
                  clientId: clientId,
                  pendingAccountId: submitAccountId.toString(),
                }).then(() => {
                  setSubmitAccountId(null);
                });
              }
            }}
            onUXchangeSubmit={(selectedCmaAccount: InitiateExistingUxchangeAccountApplicationPayload) => {
              if (submitAccountId && clientId) {
                initiateUxchangeworkflowAccountApplicationWorkflow({
                  cmaName: selectedCmaAccount.cmaName,
                  cmaNumber: selectedCmaAccount.cmaNumber,
                  cmaSubNumber: selectedCmaAccount.cmaSubNumber,
                  pendingAccountId: submitAccountId.toString(),
                  clientId,
                }).then(() => {
                  setSubmitAccountId(null);
                });
              }
            }}
            deleteAccountId={deleteAccountId}
            onDeleteAccount={() => {
              if (deleteAccountId && clientId) {
                setDeleteAccountId(null);
              }
            }}
            deletePendingAccount={deletePendingAccount}
            onCloseSubmit={() => setSubmitAccountId(null)}
            onCloseDelete={() => setDeleteAccountId(null)}
            accountTypes={accountTypes}
            fetchAccountTypes={fetchAccountTypes}
            id="client-accounts-trading"
            isReadOnly={true}
            loadingProgress={loadingPendingProgress}
            fetchPendingAccounts={fetchPendingAccounts}
            loadingTypesProgress={loadingTypesProgress}
            onAddEdit={(accountId: number | null | undefined) => {
              if (!!accountId && !!clientId) {
                // load document from backend
                fetchTradingAccountForEdit({ clientId, accountId });
              } else if (accountId === null && clientId === null) {
                setTradingAccountAddMode();
              } else {
                cancelTradingAccountAddEditMode();
              }
            }}
            saveUnlistedAccountProgress={saveUnlistedAccountProgress}
            onSaveUnlistedForm={(account: UnlistedAccount) => {
              if (clientId !== null) {
                createUnlistedAccount({ ...account, clientId });
              }
            }}
            hideAllActions={!hasClientEditPermission}
            initiateExistingMacquarieAccountApplication={initiateExistingMacquarieAccountApplication}
            initiateNewMacquarieAccountApplication={initiateNewMacquarieAccountApplication}
            initiateUxchangeworkflowAccountApplicationWorkflow={initiateUxchangeworkflowAccountApplicationWorkflow}
            fetchClientNewMacquarieAccountDetailByClientId={fetchClientNewMacquarieAccountDetailByClientId}
            macquarieCashOnlinePortal={macquarieCashOnlinePortal}
          />
        </>
      )}

      {isSuperSimplifierClient && (
        <Box className={classes.datatable}>
          <LoadingIndicator
            progress={{
              isLoading:
                downloadConsentFeeRenewalLoadingProgress.isLoading ||
                downloadSuperSimplifierApplicationLoadingProgress.isLoading ||
                sendApplicationLoadingProgress.isLoading ||
                superSimplifierDetails === null ||
                !clientDetails,
              hasErrors: false,
            }}
          >
            {superSimplifierDetails !== null && !!clientDetails && (
              <>
                <Typography variant="h4" gutterBottom style={{ marginTop: '40px' }}>
                  Super Simplifier account
                </Typography>
                <WO2Accounts
                  clientId={clientId}
                  accounts={[
                    {
                      accountId: -1,
                      accountTypeId: clientDetails.accountTypeId,
                      accountName: clientDetails.name,
                      accountNumber: superSimplifierDetails.superMemberNumber,
                      bsb: '',
                      institutionId: null,
                      institution: '',
                      securityStatus: null,
                      isExternal: false,
                    },
                  ]}
                  superSimplifierAccountStatus={clientDetails.status}
                  actions={superSimplifierActionItems}
                  isReadOnly={true}
                  isExternal={false}
                  loadingProgress={superSimplifierLoadingProgress}
                  hideAddButton={true}
                  hideAllActions={false}
                  id="SuperSimplifier"
                />
              </>
            )}
          </LoadingIndicator>
        </Box>
      )}

      <Typography variant="h4" gutterBottom style={{ paddingTop: '20px', marginTop: isSuperSimplifierClient ? '40px' : '0' }}>
        Portfolio accounts
      </Typography>
      <WO2Accounts
        clientId={clientId}
        accounts={tradingAccounts}
        actions={[
          {
            label: 'Visibility',
            clickHandler: (accountId: number | null | undefined) => {
              if (!!accountId && clientId !== null) {
                // load document from backend
                fetchTradingAccountForEdit({ clientId, accountId });
              } else if (accountId === null && clientId === null) {
                setTradingAccountAddMode();
              } else {
                cancelTradingAccountAddEditMode();
              }
            },
            icon: <VisibilityOutlined color="primary" />,
            name: 'accountId',
          },
        ]}
        onDetach={(payload: DetachAccountPayload) => {
          onDetachAccount(payload);
        }}
        accountTypes={accountTypes}
        fetchAccountTypes={fetchAccountTypes}
        id="client-accounts-trading"
        isReadOnly={true}
        isExternal={false}
        loadingProgress={loadingProgress}
        loadingTypesProgress={loadingTypesProgress}
        onAddEdit={(accountId: number | null | undefined) => {
          if (!!accountId && !!clientId) {
            // load document from backend
            fetchTradingAccountForEdit({ clientId, accountId });
          } else if (accountId === null && clientId === null) {
            setTradingAccountAddMode();
          } else {
            cancelTradingAccountAddEditMode();
          }
        }}
        saveUnlistedAccountProgress={saveUnlistedAccountProgress}
        onSaveUnlistedForm={(account: UnlistedAccount) => {
          if (clientId !== null) {
            createUnlistedAccount({ ...account, clientId });
          }
        }}
        selectedAccount={
          selectedTradingAccount === null || tradingAccounts.find((a) => a.accountId === selectedTradingAccount?.accountId) ? selectedTradingAccount : undefined
        }
        hideAllActions={!hasClientEditPermission}
      />
      <Typography variant="h4" gutterBottom style={{ marginTop: '40px' }}>
        External accounts
      </Typography>
      <WO2Accounts
        clientId={clientId}
        accounts={externalAccounts}
        actions={[
          {
            label: 'Visibility',
            clickHandler: (accountId: number | null | undefined) => {
              if (!!accountId && !!clientId) {
                // load document from backend
                fetchExternalAccountForEdit({ clientId, accountId });
              } else if (accountId === null && clientId === null) {
                setExternalAccountAddMode();
              } else {
                cancelExternalAccountAddEditMode();
              }
            },
            icon: <VisibilityOutlined color={'primary'} />,
            name: 'accountId',
          },
        ]}
        onDetach={(payload: DetachAccountPayload) => {
          onDetachAccount(payload);
        }}
        accountTypes={accountTypes}
        fetchAccountTypes={fetchAccountTypes}
        isReadOnly={true}
        isExternal={true}
        id="client-accounts-trading"
        loadingProgress={loadingProgress}
        loadingTypesProgress={loadingTypesProgress}
        onAddEdit={(accountId: number | null | undefined) => {
          if (!!accountId && !!clientId) {
            // load document from backend
            fetchExternalAccountForEdit({ clientId, accountId });
          } else if (accountId === null && clientId === null) {
            setExternalAccountAddMode();
          } else {
            cancelExternalAccountAddEditMode();
          }
        }}
        onSaveExternal={(payload: ExternalAccount) => {
          if (!!clientId) {
            createExternalAccount({ ...payload, clientId });
          }
        }}
        saveExternalAccountProgress={saveExternalAccountLoadingProgress}
        externalInstitutions={externalInstitutions}
        selectedAccount={
          selectedExternalAccount === null || externalAccounts.find((a) => a.accountId === selectedExternalAccount?.accountId)
            ? selectedExternalAccount
            : undefined
        }
        hideAllActions={!hasClientEditPermission}
      />
      {pendingClientttachmentTypeId !== null && (
        <UploadApplicationForm
          attachmentTypeId={pendingClientttachmentTypeId}
          clientEmail={clientContactDetails?.email}
          documentTypes={documentTypes}
          onClose={() => {
            setSuperSimplifierApplication(false);
          }}
          onSave={(values: SuperSimplifierUploadDocument) => {
            if (clientId !== null && pendingClientttachmentTypeId !== null) {
              uploadSuperSimplifierForm({
                fileName: values.fileName,
                base64Content: values.base64Content,
                entityId: clientId,
                attachmentTypeId: pendingClientttachmentTypeId,
              });
              setSuperSimplifierApplication(false);
            }
          }}
          saveProgress={uploadSuperSimplifierFormLoadingProgress}
          shouldOpenForm={shouldOpenSuperSimplifierApplication}
          title="Upload Signed Application Form"
        />
      )}
      <UploadApplicationForm
        attachmentTypeId={AttachmentType.FeeConsent.id}
        clientEmail={clientContactDetails?.email}
        documentTypes={documentTypes}
        onClose={() => {
          setSuperSimplifierOngoingFeeConsentForm(false);
        }}
        onSave={(values: SuperSimplifierUploadDocument) => {
          if (clientId !== null) {
            uploadSuperSimplifierForm({
              fileName: values.fileName,
              base64Content: values.base64Content,
              entityId: clientId,
              attachmentTypeId: AttachmentType.FeeConsent.id,
            });
            setSuperSimplifierOngoingFeeConsentForm(false);
          }
        }}
        shouldOpenForm={shouldOpenSuperSimplifierOngoingFeeConsentForm}
        saveProgress={uploadSuperSimplifierFormLoadingProgress}
        title="Upload Ongoing Fee Consent Form"
        shouldHideAcknowledgement={true}
      />
      <Acknowledgement
        shouldOpenForm={!hideSendApplicationAcknowledgement}
        hasAcknowledged={sendApplicationAcknowledgement}
        setAcknowledgement={setSendApplicationAcknowledgement}
        clientEmail={clientContactDetails?.email}
        onClose={() => {
          setHideSendApplicationAcknowledgement(true);
        }}
      />
    </>
  );
};
