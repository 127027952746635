import { AddOutlined, LinkOffOutlined, MoreVertOutlined } from '@mui/icons-material';
import { IconButton, Menu, MenuItem, Tooltip, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { remove } from 'lodash';
import React, { useEffect } from 'react';
import { LoadingProgress } from '../../store/types';
import { ClientSideDataTable } from '../dataTable/clientSide';
import { DatatableColumn, FilterDataType } from '../dataTable/types';
import { DialogActionButton, WO2Modal } from '../Modal';
import { theme } from './../../../themes';
import { StatusType } from './../../types';
import { WO2Menu } from './../Menu';
import { AccountDetail } from './AccountDetail';
import { WO2AccountView } from './accountView';
import { WO2ExternalAccount } from './forms/externalAccount';
import { WO2UnlistedAccount } from './forms/unlistedAccount';
import {
  Account,
  AccountInstitutionType,
  AccountType,
  DetachAccountPayload,
  ExternalAccount,
  ExternalInstitution,
  OtherAccountTypes,
  SecurityStatus,
  UnlistedAccount,
  WO2AccountActionType,
} from './types';

export interface AccountProps {
  accounts: Account[];
  accountTypes?: AccountType[];
  actions?: WO2AccountActionType[];
  superSimplifierAccountStatus?: number;
  clientId?: number | null;
  fetchAccountTypes?: () => void;
  externalInstitutions?: ExternalInstitution[];
  hideAddButton?: boolean;
  hideAllActions?: boolean;
  id?: string;
  isExternal: boolean;
  isReadOnly: boolean;
  loadingProgress: LoadingProgress;
  loadingTypesProgress?: LoadingProgress;
  onAddEdit?: (id: number | null | undefined) => void;
  onDetach?: (payload: DetachAccountPayload) => void;
  onSaveUnlistedForm?: (account: UnlistedAccount) => void;
  onSaveExternal?: (account: ExternalAccount) => void;
  saveExternalAccountProgress?: LoadingProgress;
  saveUnlistedAccountProgress?: LoadingProgress;
  selectedAccount?: Account | null;
  missingInfoAccountId?: number | null;
  onCloseMissingInfo?: () => void;
}
export const useStyles = makeStyles(() => ({
  addToTable: {
    backgroundColor: theme.palette.primary.main,
    fontSize: '1.25rem !important',
    padding: '4px',
    margin: '12px',
    color: theme.palette.common.white,
    '& > span > svg': {
      fontSize: '1.25rem !important',
    },
  },
}));
export function WO2Accounts({
  accounts,
  accountTypes,
  actions,
  superSimplifierAccountStatus,
  clientId,
  externalInstitutions,
  fetchAccountTypes,
  hideAddButton,
  hideAllActions,
  id,
  isExternal,
  isReadOnly,
  loadingProgress,
  loadingTypesProgress,
  onAddEdit,
  onDetach,
  onSaveExternal,
  onSaveUnlistedForm,
  saveExternalAccountProgress,
  saveUnlistedAccountProgress,
  selectedAccount,
  missingInfoAccountId,
  onCloseMissingInfo,
}: AccountProps): JSX.Element {
  const classes = useStyles();
  // Elements
  const [menuAnchorEl, setMenuAnchorEl] = React.useState<null | HTMLElement>(null);
  const [selectAccount, setSelectedAccount] = React.useState<Account | null>(null);

  // Modals/Menus
  const [detachAccountModal, setDetachAccountModal] = React.useState<boolean>(false);

  // filtered accounts
  const [filteredAccountTypes, setFilteredAccountTypes] = React.useState<AccountInstitutionType[]>([]);
  const [selectedCreateFormState, selectCreateForm] = React.useState<number | string | null>(null);
  const filterAllowedAccountTypes = (allAccounts: Account[]): AccountInstitutionType[] => {
    const allowed = AccountInstitutionType.getArray();
    allAccounts
      .filter((account) => account.institutionId !== AccountInstitutionType.Unlisted.id)
      .forEach((account) => {
        for (let j = 0; j < allowed.length; j++) {
          if (account.institutionId === allowed[j].id) {
            remove(allowed, allowed[j]);
          }
        }
      });

    return allowed;
  };
  useEffect(() => {
    const allFilteredAccounts = filterAllowedAccountTypes(accounts);
    setFilteredAccountTypes(allFilteredAccounts);
  }, [accounts]);

  // ui components
  const dialogActionButtons: DialogActionButton[] = [
    {
      label: 'Cancel',
      onClickHandler: () => {
        setDetachAccountModal(false);
      },
      type: 'outlined',
    },
    {
      label: 'Detach',
      onClickHandler: async () => {
        if (!!clientId && selectAccount !== null && !!onDetach) {
          onDetach({ clientId, accountId: selectAccount.accountId });
          setDetachAccountModal(false);
        }
      },
      type: 'contained',
    },
  ];

  const missingInfoDialogActionButtons: DialogActionButton[] = [
    {
      label: 'Cancel',
      onClickHandler: () => {
        !!onCloseMissingInfo && onCloseMissingInfo();
      },
      type: 'outlined',
    },
  ];

  // columns
  const detailsColumn = (dataIndex: number): React.ReactNode => {
    return accounts.length > 0 && !!accounts[dataIndex] ? (
      <AccountDetail isSuperSimplifier={!!superSimplifierAccountStatus} account={accounts[dataIndex]} />
    ) : (
      <></>
    );
  };
  const statusColumn = (dataIndex: number): React.ReactNode => {
    let statusText = '';
    if (!!accounts[dataIndex] && accounts[dataIndex].securityStatus !== null) {
      const securityStatusId = accounts[dataIndex].securityStatus;
      statusText = !!securityStatusId ? SecurityStatus.getById(securityStatusId)?.displayName || '' : '';
    }
    return <Typography>{statusText}</Typography>;
  };
  const customStatusColumn = (status: number): React.ReactNode => {
    return <Typography>{StatusType.getById(status)?.displayName}</Typography>;
  };

  const accountIdColumn = (dataIndex: number): React.ReactNode => {
    let accountId = 0;
    if (!!accounts[dataIndex] && accounts[dataIndex].accountId !== null) {
      accountId = accounts[dataIndex].accountId;
    }
    return (
      <Typography align="right" style={{ paddingRight: '10px' }}>
        {accountId === -1 ? '' : accountId ?? ''}
      </Typography>
    );
  };

  const actionsColumn = (dataIndex: number): React.ReactNode => {
    const actionsList = !!actions
      ? actions.map((action: WO2AccountActionType) => ({
          icon: action.icon,
          label: action.label,
          onClick: () => {
            const account: Account = accounts[dataIndex];
            const property: keyof Account = action.name;

            if (account !== null && property !== null) {
              action.clickHandler(account[property] as number);
            }
          },
          testId: `accountAction_${action.label.replace(/\s/g, '')}_${dataIndex}`,
        }))
      : [];

    if (!!onDetach) {
      actionsList.push({
        icon: <LinkOffOutlined color="primary" />,
        label: 'Detach Account',
        onClick: () => {
          const account: Account = accounts[dataIndex];
          if (account !== null) {
            setSelectedAccount(account);
            setDetachAccountModal(true);
          }
        },
        testId: `accountAction_detach_${dataIndex}`,
      });
    }

    return (
      <WO2Menu
        testId={`accountActionsButton_${!!id ? id + '_' : ''}${dataIndex}`}
        buttonTitle="Client Account Actions"
        buttonIcon={<MoreVertOutlined color="primary" />}
        items={actionsList}
      />
    );
  };

  const openAddMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMenuAnchorEl(event.currentTarget);
  };
  const handleCloseAddMenu = () => {
    setMenuAnchorEl(null);
  };
  const handleCreateForm = (name: string) => {
    setMenuAnchorEl(null);
    selectCreateForm(name);
  };

  const onSaveUnlisted = (account: UnlistedAccount) => {
    selectCreateForm('CREATED');
    if (!!onSaveUnlistedForm) {
      onSaveUnlistedForm(account);
    }
  };

  const onSaveExternalAccount = (account: ExternalAccount) => {
    selectCreateForm('CREATED');
    if (!!onSaveExternal) {
      onSaveExternal(account);
    }
  };

  const addButton = (): React.ReactNode => (
    <>
      <Tooltip title="Add Account">
        <IconButton
          className={classes.addToTable}
          disableFocusRipple
          disableRipple
          onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
            openAddMenu(event);
          }}
          data-testid={`add${isExternal ? 'External' : 'Portfolio'}AccountButton`}
        >
          <AddOutlined />
        </IconButton>
      </Tooltip>
      <Menu anchorEl={menuAnchorEl} keepMounted open={Boolean(menuAnchorEl)} onClose={handleCloseAddMenu}>
        {!isExternal &&
          !!filteredAccountTypes.length &&
          filteredAccountTypes
            .filter((accountType) => accountType.name != 'UxChange')
            .map((accountType, index: number) => (
              <MenuItem
                key={index}
                onClick={() => {
                  handleCreateForm(accountType.name);
                }}
                data-testid={`addAccountButton_${accountType.name}`}
              >
                Add {accountType.displayName} Account
              </MenuItem>
            ))}
        {!!isExternal && (
          <MenuItem
            onClick={() => {
              handleCreateForm(OtherAccountTypes.External);
            }}
            data-testid={`addAccountButtonExternal`}
          >
            &nbsp; Add External Account
          </MenuItem>
        )}
      </Menu>
    </>
  );
  const columns: DatatableColumn[] = [
    {
      filterDataType: FilterDataType.string,
      name: 'accountName',
      label: 'ACCOUNT DETAILS',
      options: {
        filter: true,
        sort: true,
        customBodyRenderLite: function customBodyRenderLite(dataIndex: number): React.ReactNode {
          return (
            <div style={{ paddingRight: '10px' }}>
              <Typography variant={'h5'} align="right">
                {detailsColumn(dataIndex)}
              </Typography>
            </div>
          );
        },
        customHeadLabelRender: function customHeadLabelRender(): React.ReactNode {
          return (
            <Typography
              variant="h6"
              align="right"
              style={{
                textAlign: 'right',
                textTransform: 'uppercase',
                paddingLeft: '50px',
                color: '#7d7d7d',
                fontSize: '11px',
              }}
            >
              ACCOUNT DETAILS
            </Typography>
          );
        },
      },
    },
    {
      filterDataType: FilterDataType.string,
      name: 'accountId',
      label: 'Account ID',
      options: {
        filter: true,
        sort: true,
        customBodyRenderLite: function customBodyRenderLite(dataIndex: number): React.ReactNode {
          return (
            <Typography variant={'h5'} align="right" style={{ width: '100px' }}>
              {accountIdColumn(dataIndex)}
            </Typography>
          );
        },
        customHeadLabelRender: function customHeadLabelRender(): React.ReactNode {
          return (
            <Typography
              variant="h6"
              align="right"
              style={{
                textAlign: 'right',
                textTransform: 'uppercase',
                color: '#7d7d7d',
                fontSize: '11px',
                width: '100px',
                paddingRight: '10px',
              }}
            >
              Account ID
            </Typography>
          );
        },
      },
    },
    {
      name: 'securityStatus',
      label: 'STATUS',
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: function customBodyRenderLite(dataIndex: number): React.ReactNode {
          return (
            <div>
              <Typography variant={'h5'} align="right">
                {!!superSimplifierAccountStatus ? customStatusColumn(superSimplifierAccountStatus) : statusColumn(dataIndex)}
              </Typography>
            </div>
          );
        },
        customHeadLabelRender: function customHeadLabelRender(): React.ReactNode {
          return (
            <Typography
              variant="h6"
              align="right"
              style={{
                textTransform: 'uppercase',
                paddingLeft: '50px',
                color: '#7d7d7d',
                fontSize: '11px',
              }}
            >
              STATUS
            </Typography>
          );
        },
      },
    },
    {
      name: 'actions',
      label: ' ',
      textAlign: 'right',
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (dataIndex: number): React.ReactNode => actionsColumn(dataIndex),
      },
    },
  ];

  // TODO: Impelment fix for correct BSB for #6560
  const currentAccount = !!accounts && accounts.find((a) => a.accountId === selectedAccount?.accountId);
  const bsbNumber = !!currentAccount ? currentAccount.bsb : '';

  return (
    <>
      <ClientSideDataTable
        loadingProgress={loadingProgress}
        columns={hideAllActions === true ? columns.filter((c) => c.name !== 'actions') : columns}
        data={accounts}
        options={{
          filter: false,
          pagination: true,
          viewColumns: false,
          customToolbar: () => !hideAddButton && addButton(),
        }}
        id={`${id}-accounts-datatable`}
      ></ClientSideDataTable>
      {!!externalInstitutions && externalInstitutions.length > 0 && (
        <WO2ExternalAccount
          saveProgress={saveExternalAccountProgress}
          onSave={onSaveExternalAccount}
          handleCloseModal={() => {
            selectCreateForm(null);
          }}
          externalInstitutions={externalInstitutions}
          isOpen={selectedCreateFormState === OtherAccountTypes.External}
        />
      )}
      {clientId && (
        <WO2UnlistedAccount
          saveProgress={saveUnlistedAccountProgress}
          onSave={onSaveUnlisted}
          handleCloseModal={() => {
            selectCreateForm(null);
          }}
          isOpen={selectedCreateFormState === AccountInstitutionType.Unlisted.name}
        />
      )}
      {selectedAccount !== undefined && !!isReadOnly && !!onAddEdit && !!accountTypes && !!loadingTypesProgress && !!fetchAccountTypes && (
        <WO2AccountView
          isReadOnly={isReadOnly}
          isExternal={isExternal}
          bsb={bsbNumber}
          accountTypes={accountTypes}
          handleCloseModal={() => onAddEdit(undefined)}
          account={selectedAccount}
          fetchAccountTypes={fetchAccountTypes}
          loadingProgress={loadingProgress}
          loadingTypesProgress={loadingTypesProgress}
        />
      )}

      <WO2Modal
        MuiDialogProps={{
          open: !!missingInfoAccountId,
          onClose: () => {
            !!onCloseMissingInfo && onCloseMissingInfo();
          },
        }}
        title={<>MISSING INFORMATION</>}
        actions={missingInfoDialogActionButtons}
      >
        <p>The below information is mandatory to set up the account. Once provided the account application can be submitted. </p>
      </WO2Modal>

      <WO2Modal
        MuiDialogProps={{
          open: detachAccountModal,
          onClose: () => {
            setDetachAccountModal(false);
          },
        }}
        title={<>DETACH ACCOUNT</>}
        actions={dialogActionButtons}
      >
        <p>Are you sure you want to detach this account?</p>
      </WO2Modal>
    </>
  );
}
