import { Box, Container, Grid } from '@mui/material';
import React, { useCallback, useEffect } from 'react';
import SearchButton from 'src/features/clients/client/common/components/SearchButton';
import { formatPercentage } from '../../../../common';
import { EnumSelect } from '../../../../common/components';
import CardComponent from '../../../../common/components/CardComponent';
import DateRangePicker from '../../../../common/components/DateRangePicker';
import { theme } from '../../../../themes';
import { Props } from '../container';
import { FetchModelPerformancechartWithBenchmarkPayload, Frequency } from '../store/types';
import { PerformanceChart } from './performanceChart';
import { PeriodicSummaryTable } from './periodicSummaryTable';

export const PerformanceComponent = (props: Props): JSX.Element => {
  const {
    parameters,
    modelDetail,
    fetchModelPerformanceChartWithBenchmark,
    fetchModelPeriodicSummary,
    fetchModelDetail,
    setFrequencyParameter,
    setDateParameter,
    loadingProgresModelPerformancechartWithBenchmark,
    loadingProgresModelPeriodicSummary,
    modelPeriodicSummary,
    modelPerformancechartWithBenchmark,
    match: { params },
  } = props;

  const fetch = useCallback(() => {
    if (params.modelId) {
      const modelPerformancechartWithBenchmarkPayload: FetchModelPerformancechartWithBenchmarkPayload = {
        modelVersionId: parseInt(params.modelVersionId ?? ''),
        startDate: parameters.dates.dateFrom,
        endDate: parameters.dates.dateTo,
        frequency: parameters.frequency,
        strategyId: parameters.modelBenchmarkSelected?.id ?? 0,
      };
      fetchModelPerformanceChartWithBenchmark(modelPerformancechartWithBenchmarkPayload);

      if (params.modelVersionId) {
        fetchModelPeriodicSummary({
          modelVersionId: parseInt(params.modelVersionId),
          benchmarkStrategyId: parameters.modelBenchmarkSelected?.id ?? 0,
          endDate: parameters.dates.dateTo,
        });
        fetchModelDetail(parseInt(params.modelVersionId));
      }
    }
  }, [params.modelVersionId, fetchModelPeriodicSummary, fetchModelPerformanceChartWithBenchmark]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  function callModelEndpoints() {
    if (params.modelVersionId) {
      const modelPerformancechartWithBenchmarkPayload: FetchModelPerformancechartWithBenchmarkPayload = {
        modelVersionId: parseInt(params.modelVersionId ?? ''),
        startDate: parameters.dates.dateFrom,
        endDate: parameters.dates.dateTo,
        frequency: parameters.frequency,
        strategyId: parameters.modelBenchmarkSelected?.id ?? 0,
      };
      fetchModelPerformanceChartWithBenchmark(modelPerformancechartWithBenchmarkPayload);
      fetchModelPeriodicSummary({
        modelVersionId: parseInt(params.modelVersionId ?? ''),
        benchmarkStrategyId: parameters.modelBenchmarkSelected?.id ?? 0,
        endDate: parameters.dates.dateTo,
      });
    }
  }

  function onSearchClicked() {
    callModelEndpoints();
  }

  const bestLabel = parameters.frequency === 'Daily' ? 'Best Day' : 'Best Month';
  const worstLabel = parameters.frequency === 'Daily' ? 'Worst Day' : 'Worst Month';

  return (
    <Container style={{ paddingTop: '20px', paddingBottom: '20px' }}>
      <Box display={'flex'} justifyContent={'space-between'} style={{ padding: '10px 0' }}>
        <Box paddingRight={'10px'}></Box>
        <Box display={'flex'} justifyContent={'space-between'}>
          <DateRangePicker dateRange={parameters.dates} setDateRange={setDateParameter} />
          <Box paddingRight={'10px'}>
            <EnumSelect
              label="Frequency"
              enumType={Frequency}
              value={parameters?.frequency}
              onChange={(value) => {
                setFrequencyParameter(Frequency[value]);
              }}
            />
          </Box>
          <Box paddingRight={'10px'}></Box>
          <Box>
            <SearchButton onClick={onSearchClicked} />
          </Box>
        </Box>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={2}>
          <CardComponent
            progress={loadingProgresModelPerformancechartWithBenchmark}
            background={theme.palette.gradient1?.main}
            value={formatPercentage(modelPerformancechartWithBenchmark?.capitalReturn)}
            subText={'Capital Return'}
          />
        </Grid>
        <Grid item xs={2}>
          <CardComponent
            progress={loadingProgresModelPerformancechartWithBenchmark}
            background={theme.palette.gradient2?.main}
            value={formatPercentage(modelPerformancechartWithBenchmark?.incomeReturn)}
            subText={'IncomeReturn'}
          />
        </Grid>
        <Grid item xs={2}>
          <CardComponent
            progress={loadingProgresModelPerformancechartWithBenchmark}
            background={theme.palette.gradient1?.main}
            value={formatPercentage(modelPerformancechartWithBenchmark?.totalReturn)}
            subText={'Total Return'}
          />
        </Grid>
        <Grid item xs={2}>
          <CardComponent
            progress={loadingProgresModelPerformancechartWithBenchmark}
            background={theme.palette.gradient2?.main}
            value={formatPercentage(modelPerformancechartWithBenchmark?.bestDayReturn)}
            subText={bestLabel}
          />
        </Grid>
        <Grid item xs={2}>
          <CardComponent
            progress={loadingProgresModelPerformancechartWithBenchmark}
            background={theme.palette.gradient1?.main}
            value={formatPercentage(modelPerformancechartWithBenchmark?.worstDayReturn)}
            subText={worstLabel}
          />
        </Grid>
        <Grid item xs={2}>
          <CardComponent
            progress={loadingProgresModelPerformancechartWithBenchmark}
            background={theme.palette.gradient2?.main}
            value={formatPercentage(modelPerformancechartWithBenchmark?.annualVolatility)}
            subText={'A. Volatility'}
          />
        </Grid>
      </Grid>

      <PerformanceChart
        modelPerformancechartWithBenchmark={modelPerformancechartWithBenchmark}
        loadingProgresModelPerformancechartWithBenchmark={loadingProgresModelPerformancechartWithBenchmark}
        frequency={parameters.frequency}
      />

      <PeriodicSummaryTable
        modelDetail={modelDetail}
        parameters={parameters}
        loadingProgresModelPeriodicSummary={loadingProgresModelPeriodicSummary}
        modelPeriodicSummary={modelPeriodicSummary}
      />
    </Container>
  );
};
