import { createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../../../app/api';
import {
  FetchMonthlyDashboardChartPayload,
  FetchStrategyDashboardPayload,
  FetchStrategyDetailsPayload,
  MonthlyDashboardChart,
  StrategyDashboard,
  StrategyDetails,
} from './types';

export enum StrategyDashboardActionTypes {
  FetchStrategyDashboard = '​@@strategy/GetStrategyDashboard',
  FetchStrategyDetails = '​@@strategy/GetStrategyDetails',
  FetchMonthlyDashboardChart = '​@@strategy/GetMonthlyDashboardChart',
}

export enum StrategyDashboardApiEndpoints {
  fetchStrategyDashboard = '/bff/GetStrategyDashboard',
  fetchStrategyDetails = '/bff/GetStrategyDetails',
  fetchMonthlyDashboardChart = '/performances/strategy/GetMonthlyPerformanceChart',
}

export const fetchStrategyDashboard = createAsyncThunk(StrategyDashboardActionTypes.FetchStrategyDashboard, async (wrapper: FetchStrategyDashboardPayload) => {
  const response = await api.get<StrategyDashboard>(`${StrategyDashboardApiEndpoints.fetchStrategyDashboard}?strategyId=${wrapper.strategyId}`);
  return response.data;
});

export const fetchStrategyDetails = createAsyncThunk(StrategyDashboardActionTypes.FetchStrategyDetails, async (wrapper: FetchStrategyDetailsPayload) => {
  const response = await api.get<StrategyDetails>(`${StrategyDashboardApiEndpoints.fetchStrategyDetails}?strategyId=${wrapper.strategyId}`);
  return response.data;
});

export const fetchMonthlyDashboardChart = createAsyncThunk(
  StrategyDashboardActionTypes.FetchMonthlyDashboardChart,
  async (wrapper: FetchMonthlyDashboardChartPayload) => {
    const response = await api.get<MonthlyDashboardChart>(
      `${StrategyDashboardApiEndpoints.fetchMonthlyDashboardChart}?strategyId=${wrapper.strategyId}&fromDate=${wrapper.fromDate}&toDate=${wrapper.toDate}`
    );
    return response.data;
  }
);
