import { Paper, Typography } from '@mui/material';
import React from 'react';
import { DateTimeFormat, formatDollars, formatPercentage, getLocalDateTime } from '../../../../../common';
import { ServerSideDataTable } from '../../../../../common/components/dataTable/serverSide';
import { DatatableColumn } from '../../../../../common/components/dataTable/types';
import { LoadingProgress } from '../../../../../common/store/types';
import { PagedRequest } from '../../../../../store';
import { MonthlyPerformanceTableResult } from '../store/types';

export interface Props {
  monthlyPerformanceTableProgress: LoadingProgress;
  pagedResults: MonthlyPerformanceTableResult;
  pagedRequest: PagedRequest;
  handlePagedRequest: (pagedRequest: PagedRequest) => void;
}

export const MonthlyPerformanceTable = ({ pagedResults, monthlyPerformanceTableProgress, pagedRequest, handlePagedRequest }: Props): JSX.Element => {
  const openBalColumn = (dataIndex: number): React.ReactNode => {
    return (
      <>
        <Typography variant="h5">{formatDollars(pagedResults.results[dataIndex]?.openingBalance)}</Typography>
        <Typography variant="h6" color={'textSecondary'}>
          {getLocalDateTime(pagedResults.results[dataIndex]?.perfDate, DateTimeFormat.MonthDate)}
        </Typography>
      </>
    );
  };

  const capitalmvtColumn = (dataIndex: number): React.ReactNode => {
    return (
      <Typography
        variant="h5"
        color={!pagedResults.results[dataIndex]?.capitalMovement ? 'textPrimary' : pagedResults.results[dataIndex]?.capitalMovement < 0 ? 'error' : 'secondary'}
      >
        {formatDollars(pagedResults.results[dataIndex]?.capitalMovement)}
      </Typography>
    );
  };

  const investmentColumn = (dataIndex: number): React.ReactNode => {
    return <Typography variant="h5">{formatDollars(pagedResults.results[dataIndex]?.investment)}</Typography>;
  };

  const expenseColumn = (dataIndex: number): React.ReactNode => {
    return <Typography variant="h5">{formatDollars(pagedResults.results[dataIndex]?.expense)}</Typography>;
  };

  const taxColumn = (dataIndex: number): React.ReactNode => {
    return <Typography variant="h5">{formatDollars(pagedResults.results[dataIndex]?.tax)}</Typography>;
  };

  const capitalColumn = (dataIndex: number): React.ReactNode => {
    return (
      <>
        <Typography
          variant="h5"
          align="right"
          color={!pagedResults.results[dataIndex]?.growth ? 'textPrimary' : pagedResults.results[dataIndex]?.growth < 0 ? 'error' : 'secondary'}
        >
          {formatDollars(pagedResults.results[dataIndex]?.growth)}
        </Typography>
        <Typography variant="h6" align="right" color={'textSecondary'}>
          {formatPercentage(pagedResults.results[dataIndex]?.growthReturn)}
        </Typography>
      </>
    );
  };

  const incomeColumn = (dataIndex: number): React.ReactNode => {
    return (
      <>
        <Typography
          variant="h5"
          align="right"
          color={!pagedResults.results[dataIndex]?.income ? 'textPrimary' : pagedResults.results[dataIndex]?.income < 0 ? 'error' : 'secondary'}
        >
          {formatDollars(pagedResults.results[dataIndex]?.income)}
        </Typography>
        <Typography variant="h6" align="right" color={'textSecondary'}>
          {formatPercentage(pagedResults.results[dataIndex]?.incomeReturn)}
        </Typography>
      </>
    );
  };

  const totalColumn = (dataIndex: number): React.ReactNode => {
    return (
      <>
        <Typography
          variant="h5"
          align="right"
          color={!pagedResults.results[dataIndex]?.total ? 'textPrimary' : pagedResults.results[dataIndex]?.total < 0 ? 'error' : 'secondary'}
        >
          {formatDollars(pagedResults.results[dataIndex]?.total)}
        </Typography>
        <Typography variant="h6" align="right" color={'textSecondary'}>
          {formatPercentage(pagedResults.results[dataIndex]?.totalReturn)}
        </Typography>
      </>
    );
  };

  const closingBalColumn = (dataIndex: number): React.ReactNode => {
    return (
      <>
        <Typography variant="h5">{formatDollars(pagedResults.results[dataIndex]?.closingBalance)}</Typography>
      </>
    );
  };

  const columns: DatatableColumn[] = [
    {
      name: 'OPENINGBAL',
      label: 'OPENING BAL',
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (dataIndex): React.ReactNode => openBalColumn(dataIndex),
      },
    },
    {
      name: 'CAPITALMVT',
      label: 'CAPITAL MVT',
      textAlign: 'right',
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (dataIndex): React.ReactNode => capitalmvtColumn(dataIndex),
        setCellHeaderProps: () => {
          return {
            style: { textAlign: 'right' },
          };
        },
      },
    },
    {
      name: 'INVESTMENT',
      label: 'INVESTMENT',
      textAlign: 'right',
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (dataIndex): React.ReactNode => investmentColumn(dataIndex),
        setCellHeaderProps: () => {
          return {
            style: { textAlign: 'right' },
          };
        },
      },
    },
    {
      name: 'EXPENSE',
      label: 'EXPENSE',
      textAlign: 'right',
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (dataIndex): React.ReactNode => expenseColumn(dataIndex),
        setCellHeaderProps: () => {
          return {
            style: { textAlign: 'right' },
          };
        },
      },
    },
    {
      name: 'TAX',
      label: 'TAX',
      textAlign: 'right',
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (dataIndex): React.ReactNode => taxColumn(dataIndex),
        setCellHeaderProps: () => {
          return {
            style: { textAlign: 'right' },
          };
        },
      },
    },
    {
      name: 'CAPITAL',
      label: 'CAPITAL',
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (dataIndex): React.ReactNode => capitalColumn(dataIndex),
        setCellHeaderProps: () => {
          return {
            style: { textAlign: 'right' },
          };
        },
      },
    },
    {
      name: 'INCOME',
      label: 'INCOME',
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (dataIndex): React.ReactNode => incomeColumn(dataIndex),
        setCellHeaderProps: () => {
          return {
            style: { textAlign: 'right' },
          };
        },
      },
    },
    {
      name: 'TOTAL',
      label: 'TOTAL',
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (dataIndex): React.ReactNode => totalColumn(dataIndex),
        setCellHeaderProps: () => {
          return {
            style: { textAlign: 'right' },
          };
        },
      },
    },
    {
      name: 'CLOSING BAL',
      label: 'CLOSING BAL',
      textAlign: 'right',
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (dataIndex): React.ReactNode => closingBalColumn(dataIndex),
        setCellHeaderProps: () => {
          return {
            style: { textAlign: 'right' },
          };
        },
      },
    },
  ];

  return (
    <Paper elevation={0}>
      <ServerSideDataTable
        loadingProgress={monthlyPerformanceTableProgress}
        columns={columns}
        pagedRequest={pagedRequest}
        pagedResult={pagedResults}
        options={{ filter: false, rowsPerPage: 25 }}
        handlePagedRequest={handlePagedRequest}
      ></ServerSideDataTable>
    </Paper>
  );
};
