import { Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { LoadingIndicator } from 'src/common/components/LoadingIndicator';
import { Props } from '../container';
import { ClientAccountSubType } from './../../../../../../common/types';
import { Contributions } from './contributions';
import { MemberDetails } from './memberDetails';
import { PreservationComponents } from './preservationComponents';
import { TaxationComponents } from './taxationComponents';

export const BenefitStatement = ({
  clientId,
  details,
  loadingProgressMainDetails,
  loadingProgressBenefitStatementDetails,
  benefitStatement,
  fetchBenefitStatementDetailsByClientId,
  isSuperSimplifierClient,
}: Props): JSX.Element => {
  useEffect(() => {
    if (!!clientId) {
      fetchBenefitStatementDetailsByClientId(clientId);
    }
  }, [fetchBenefitStatementDetailsByClientId, clientId]);
  return (
    <>
      {isSuperSimplifierClient && !!details && (
        <>
          <Typography variant="h3" style={{ padding: '20px 0' }}>
            Benefit Statement
          </Typography>
          <LoadingIndicator progress={loadingProgressMainDetails}>
            <MemberDetails details={details}></MemberDetails>
          </LoadingIndicator>
          <LoadingIndicator progress={loadingProgressBenefitStatementDetails}>
            <>
              {details?.subAccountTypeId === ClientAccountSubType.Super.id && <Contributions details={benefitStatement}></Contributions>}
              <PreservationComponents details={benefitStatement}></PreservationComponents>
              <TaxationComponents details={benefitStatement}></TaxationComponents>
            </>
          </LoadingIndicator>
        </>
      )}
    </>
  );
};
