import { Cancel as CancelIcon, Edit as EditIcon, FiberManualRecord as DotIcon, Save as SaveIcon } from '@mui/icons-material';
import { Grid, IconButton, OutlinedInput, Paper, Typography } from '@mui/material';
import { Field, FieldProps, Form, Formik, FormikProps, getIn } from 'formik';
import React, { useEffect, useState } from 'react';
import NumberFormat from 'react-number-format';
import PulseLoader from 'react-spinners/PulseLoader';
import { LoadingIndicator } from 'src/common/components/LoadingIndicator';
import { LoadingProgress } from 'src/common/store/types';
import { formatNumberCommaSeparated } from 'src/common/utils/numberFunctions';
import * as yup from 'yup';
import { theme } from '../../../../../../../themes';
import { ToleranceBand } from '../../../store/common';

export interface SecurityTolerancesProps {
  securityRebalanceToleranceBands: ToleranceBand[];
  onSave: (bands: ToleranceBand[]) => Promise<void>;
  loadingProgress: LoadingProgress;
  savingProgress?: LoadingProgress;
  hideEditControls?: boolean;
}

interface FormValues {
  securityRebalanceToleranceBands: ToleranceBand[];
}

export const SecurityTolerances = ({
  loadingProgress,
  securityRebalanceToleranceBands,
  onSave,
  hideEditControls,
  savingProgress,
}: SecurityTolerancesProps): JSX.Element => {
  const [editMode, setEditMode] = useState<boolean>(false);

  const initialValues: FormValues = {
    securityRebalanceToleranceBands: [],
  };

  const [formValues, setFormValues] = useState<FormValues>(initialValues);

  const updateFormValues = (bands: ToleranceBand[]) => {
    setFormValues({
      securityRebalanceToleranceBands: bands || initialValues.securityRebalanceToleranceBands,
    });
  };

  useEffect(() => {
    setEditMode(false);
    securityRebalanceToleranceBands.length > 0 && updateFormValues(securityRebalanceToleranceBands);
  }, [setFormValues, securityRebalanceToleranceBands]);

  const getSecurityRebalanceType = (color: string): string => {
    switch (color) {
      case 'Green':
        return 'Holdings equal portfolio weight';
      case 'LightGreen':
        return 'Holding within tolerance';
      case 'Blue':
        return 'Rebalance at next scheduled date';
      case 'Amber':
        return 'Relabance within 7 working days';
      case 'Red':
        return 'Rebalance within 3 working days';
      default:
        return '';
    }
  };

  const indicatorColor = (bandName: string): string => {
    switch (bandName) {
      case 'Green':
        return 'Green';
      case 'LightGreen':
        return theme.palette.green?.main;
      case 'Blue':
        return theme.palette.primary.main;
      case 'Amber':
        return theme.palette.orange?.main;
      case 'Red':
        return theme.palette.error.main;
      default:
        return theme.palette.common.white;
    }
  };

  const getBandHeatMapIcon = (color: string): React.ReactNode => (
    <DotIcon style={{ alignItems: 'center' }} fontSize={'small'} htmlColor={indicatorColor(color)} />
  );

  return (
    <>
      <Typography variant="h4" style={{ marginBottom: '20px' }}>
        Security Tolerances
      </Typography>
      <Formik<FormValues>
        enableReinitialize={true}
        initialValues={formValues}
        onSubmit={async (details) => {
          await onSave(details.securityRebalanceToleranceBands);
          setEditMode(false);
        }}
        validationSchema={yup.object({
          securityRebalanceToleranceBands: yup
            .array()
            .of(
              yup.object().shape({
                color: yup.string(),
                min: yup.number().required('Minimum tolerance is required').min(0, 'Minimum tolerance is 0').typeError('Must be a number'),
                max: yup
                  .number()
                  .when(['min', 'color'], (min, color) => {
                    if (color === formValues.securityRebalanceToleranceBands[formValues.securityRebalanceToleranceBands.length - 1].color) {
                      return yup
                        .number()
                        .nullable()
                        .min(min || 0, 'Must be >= minimum');
                    } else {
                      return yup
                        .number()
                        .nullable()
                        .min(min || 0, 'Must be >= minimum')
                        .required('Required');
                    }
                  })
                  .typeError('Must be a number'),
              })
            )
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            .test('overlap', 'Overlapping bands', function (this: yup.TestContext, bands: (any | undefined)[] | null | undefined): boolean {
              // need to loop through all the rows and check the min is greater than the previous max
              if (!!bands) {
                for (let i = 0; i < bands.length; i++) {
                  const band = bands[i];
                  if (!!band && i > 0 && band.min < (bands[i - 1].max || 0)) {
                    return false;
                  }
                }
              }
              return true;
            }),
        })}
      >
        {(formikProps: FormikProps<FormValues>) => (
          <Form>
            <Paper elevation={3} style={{}}>
              <Grid container spacing={2} justifyContent="flex-end">
                {!hideEditControls && (
                  <Grid container justifyContent="flex-end">
                    {savingProgress?.isLoading && (
                      <div className="LoadingIndicator" style={{ padding: '5px', marginTop: '4px' }}>
                        <PulseLoader size="9px" margin="5px" color={theme.palette.grey[400]} />
                      </div>
                    )}
                    {!editMode && !savingProgress?.isLoading && (
                      <IconButton
                        name="editSecurityRebalanceToleranceBands"
                        aria-label="edit"
                        color="primary"
                        onClick={() => {
                          setEditMode(true);
                        }}
                      >
                        <EditIcon style={{ fontSize: '24px' }} />
                      </IconButton>
                    )}
                    {!!editMode && !savingProgress?.isLoading && (
                      <>
                        <IconButton
                          name="cancelSaveSecurityRebalanceToleranceBands"
                          key="cancelSave"
                          aria-label="close"
                          color="primary"
                          onClick={() => {
                            formikProps.resetForm();
                            setEditMode(false);
                          }}
                        >
                          <CancelIcon style={{ fontSize: '24px' }} />
                        </IconButton>
                        <IconButton
                          name="saveSecurityRebalanceToleranceBands"
                          key="save"
                          aria-label="close"
                          color="primary"
                          onClick={() => {
                            formikProps.submitForm();
                          }}
                        >
                          <SaveIcon style={{ fontSize: '24px' }} />
                        </IconButton>
                      </>
                    )}
                  </Grid>
                )}
              </Grid>
              <LoadingIndicator progress={loadingProgress}>
                <Grid
                  container
                  style={{
                    paddingBottom: '24px',
                    backgroundColor: '#EFF3F5',
                    marginBottom: '10px',
                    padding: '10px',
                  }}
                >
                  <Grid item xs={6} style={{ paddingTop: '0px' }}>
                    <Typography variant="h5" style={{ color: '#7D7D7D' }}>
                      TOLERANCE BAND
                    </Typography>
                  </Grid>
                  <Grid item xs={3} style={{ paddingTop: '0px', textAlign: 'right' }}>
                    <Typography variant="h5" style={{ color: '#7D7D7D' }}>
                      MINIMUM (%)
                    </Typography>
                  </Grid>
                  <Grid item xs={3} style={{ paddingTop: '0px', textAlign: 'right' }}>
                    <Typography variant="h5" style={{ color: '#7D7D7D' }}>
                      MAXIMUM (%)
                    </Typography>
                  </Grid>
                </Grid>

                {securityRebalanceToleranceBands.length > 0 &&
                  securityRebalanceToleranceBands.map((band: ToleranceBand, idx: number) => (
                    <Grid container spacing={1} style={{ padding: '10px', paddingBottom: '15px' }} key={idx}>
                      <Grid item xs={1}>
                        {getBandHeatMapIcon(band.color)}
                      </Grid>
                      <Grid item xs={5}>
                        <Typography variant="h5" style={{ color: '#a6a6a6' }}>
                          {getSecurityRebalanceType(band.color)}
                        </Typography>
                      </Grid>
                      <Grid item xs={3} style={{ textAlign: 'right' }}>
                        {editMode && (
                          <>
                            <Field name={`securityRebalanceToleranceBands[${idx}].min`} fullWidth>
                              {(fieldProps: FieldProps) => {
                                return (
                                  <NumberFormat
                                    customInput={OutlinedInput}
                                    value={fieldProps.field.value}
                                    type="text"
                                    thousandSeparator
                                    fixedDecimalScale
                                    allowNegative={false}
                                    onValueChange={(value) => {
                                      formikProps.setFieldValue(`securityRebalanceToleranceBands[${idx}].min`, value.floatValue);
                                    }}
                                  ></NumberFormat>
                                );
                              }}
                            </Field>
                            <Typography variant={'body1'} color={'error'} style={{ paddingTop: '2px', color: 'red' }}>
                              {getIn(formikProps.errors, `securityRebalanceToleranceBands[${idx}].min`)}
                            </Typography>
                          </>
                        )}

                        {!editMode && (
                          <Typography variant="h5" style={{ color: '#a6a6a6' }}>
                            {formatNumberCommaSeparated(formikProps.values.securityRebalanceToleranceBands[idx]?.min, 2)}
                          </Typography>
                        )}
                      </Grid>
                      <Grid item xs={3} style={{ textAlign: 'right' }}>
                        {editMode && (
                          <>
                            <Field name={`securityRebalanceToleranceBands[${idx}].max`} fullWidth>
                              {(fieldProps: FieldProps) => {
                                return (
                                  <>
                                    <NumberFormat
                                      customInput={OutlinedInput}
                                      value={fieldProps.field.value}
                                      type="text"
                                      thousandSeparator
                                      fixedDecimalScale
                                      allowNegative={false}
                                      onValueChange={(value) => {
                                        fieldProps.form.setFieldValue(`securityRebalanceToleranceBands[${idx}].max`, value.floatValue);
                                      }}
                                    ></NumberFormat>
                                  </>
                                );
                              }}
                            </Field>
                            <Typography variant={'body1'} color={'error'} style={{ paddingTop: '2px', color: 'red' }}>
                              {getIn(formikProps.errors, `securityRebalanceToleranceBands[${idx}].max`)}
                            </Typography>
                          </>
                        )}
                        {!editMode && (
                          <Typography variant="h5" style={{ color: '#a6a6a6' }}>
                            {formatNumberCommaSeparated(formikProps.values.securityRebalanceToleranceBands[idx]?.max, 2)}
                          </Typography>
                        )}
                      </Grid>
                    </Grid>
                  ))}

                {formikProps.errors.securityRebalanceToleranceBands === 'Overlapping bands' && (
                  <Grid container item xs={12} style={{ padding: '10px' }} justifyContent="flex-end">
                    <Typography variant={'body1'} color={'error'} style={{ paddingTop: '2px', color: 'red' }}>
                      Overlapping bands
                    </Typography>
                  </Grid>
                )}
              </LoadingIndicator>
            </Paper>
          </Form>
        )}
      </Formik>
    </>
  );
};
