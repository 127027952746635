import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { BenefitStatementState, BenefitStatement } from './types';
import { fetchBenefitStatementDetailsByClientId } from './thunks';

export const initialState: BenefitStatementState = {
  details: { memberNumber: '' },
};

export const benefitStatementSlice = createSlice({
  name: 'benefitStatement',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchBenefitStatementDetailsByClientId.fulfilled, (state, action: PayloadAction<BenefitStatement>) => {
      state.details = action.payload;
    });
  },
});
