import { Box } from '@mui/material';
import 'date-fns';
import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { AnyAction } from 'redux';
import history from '../../../../../../history';
import { SaveContactPayload } from '../../../common/store/types';
import { Props } from '../container';
import { ContactEdit } from './edit/contactEdit';
import { Edit } from './edit/edit';
import { List } from './list/list';

export const Trustees = (props: Props): JSX.Element => {
  const routeMatch = useRouteMatch();

  return (
    <Box width={'100%'}>
      <Switch>
        <Route path={`${routeMatch.path}/trustee/:trusteeId/contact/:contactId?`}>
          {!!props.clientId && (
            <ContactEdit
              parentId={props.trusteeId}
              contact={props.contact}
              fetchContact={props.fetchContact}
              loadingContactProgress={props.loadingContactProgress}
              saveContactProgress={props.saveContactProgress}
              updateContact={props.updateContact}
              addresses={props.contactAddresses}
              fetchAddresses={props.fetchContactAddresses}
              availableAddressTypes={props.contactAvailableAddressTypes}
              saveAddress={props.saveContactAddress}
              setAddressEdit={props.setContactAddressEdit}
              clearAddressEdit={props.clearContactAddressEdit}
              loadingAddressesProgress={props.loadingContactAddressesProgress}
              savingAddressProgress={props.savingContactAddressProgress}
              deleteAddress={props.deleteContactAddress}
              documents={props.contactDocuments}
              fetchDocuments={props.fetchContactDocuments}
              loadingDocumentsProgress={props.loadingContactDocumentsProgress}
              downloadDocument={props.downloadDocument}
              documentTypes={props.documentTypes}
              editDocument={props.contactEditDocument}
              setDocumentAddMode={props.setContactDocumentAddMode}
              cancelDocumentAddEditMode={props.cancelContactDocumentAddEditMode}
              fetchDocumentForEdit={props.fetchContactDocumentForEdit}
              fetchDocumentTypes={props.fetchContactDocumentTypes}
              saveDocument={props.saveContactDocument}
              saveDocumentProgress={props.saveContactDocumentProgress}
              deleteDocument={props.deleteContactDocument}
              documentsParameters={props.contactDocumentsParameters}
              identifications={props.contactIdentifications}
              editIdentification={props.editContactIdentification}
              loadingIdentificationsProgress={props.loadingContactIdentificationsProgress}
              fetchIdentifications={props.fetchContactIdentifications}
              setIdentificationAddMode={props.setContactIdentificationAddMode}
              cancelIdentificationAddEditMode={props.cancelContactIdentificationAddEditMode}
              fetchIdentificationForEdit={props.fetchContactIdentificationForEdit}
              identificationsParameters={props.contactIdentificationsParameters}
              loadingContactDetailsProgress={props.loadingContactContactDetailsProgress}
              fetchContactDetails={props.fetchContactContactDetails}
              phoneNumbers={props.contactPhoneNumbers}
              savePhoneNumber={props.saveContactPhoneNumber}
              phoneNumberEditId={props.contactPhoneNumberEditId}
              setPhoneNumberEditId={props.setContactPhoneNumberEditId}
              emailAddresses={props.contactEmailAddresses}
              setPhoneNumberAdd={props.setContactPhoneNumberAdd}
              deletePhoneNumber={props.deleteContactPhoneNumber}
              emailAddressEditId={props.contactEmailAddressEditId}
              setEmailAddressAdd={props.setContactEmailAddressAdd}
              setEmailAddressEditId={props.setContactEmailAddressEditId}
              saveEmailAddress={props.saveContactEmailAddress}
              deleteEmailAddress={props.deleteContactEmailAddress}
              editAddress={props.editContactAddress}
              fetchRoles={props.fetchRoles}
              roles={props.roles}
              loadingRolesProgress={props.loadingRolesProgress}
              onBackClick={(createMode: boolean) => {
                history.push(createMode ? `../${props.trusteeId}` : `../../${props.trusteeId}`);
              }}
              saveContact={(payload: SaveContactPayload) => {
                props.saveContact(payload).then((saveResponse: AnyAction) => {
                  history.push(`./contact/${saveResponse.payload.contactId}`);
                });
              }}
            />
          )}
        </Route>
        <Route path={`${routeMatch.path}/trustee/:trusteeId?`}>
          <Edit {...props} />
        </Route>
        <Route path={routeMatch.path}>
          {!!props.clientId && (
            <List
              hasClientEditPermission={props.hasClientEditPermission}
              trustees={props.trustees}
              fetchTrustees={props.fetchTrustees}
              clientId={props.clientId}
              loadingTrusteesProgress={props.loadingTrusteesProgress}
              detachTrustee={props.detachTrustee}
              trusteesToAttach={props.trusteesToAttach}
              loadingTrusteesToAttach={props.loadingTrusteesToAttach}
              fetchTrusteesToAttach={props.fetchTrusteesToAttach}
              attachTrustee={props.attachTrustee}
              savingAttachProgress={props.savingAttachProgress}
              match={props.match}
            />
          )}
        </Route>
      </Switch>
    </Box>
  );
};
