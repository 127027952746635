import { connect, ConnectedProps } from 'react-redux';
import { progressSelectorFactory } from '../../../../common';
import { RootState } from '../../../../reducers';
import { selectAfslId, selectClientId, selectPortfolios } from '../common/store/selectors';
import { PerformanceComponent } from './components/performanceComponent';
import {
  selectBenchmarkPerformanceResults,
  selectBenchmarks,
  selectCurrentTab,
  selectMonthlyPerformanceTableResults,
  selectParameters,
  selectPerformanceChartResults,
  selectPeriodicSummaryTableResults,
  selectPortfolioSecurityPerformanceResults,
} from './store/selectors';
import { performanceSlice } from './store/slice';
import {
  fetchBenchmarkPerformance,
  fetchBenchmarks,
  fetchMonthlyPortfolioPerformance,
  fetchPerformanceChart,
  fetchPeriodicSummary,
  fetchPortfolioSecurityPerformance,
  PerformanceActionTypes,
} from './store/thunks';

const mapStateToProps = (state: RootState) => ({
  clientId: selectClientId(state),
  afslId: selectAfslId(state),
  clientPortfolios: selectPortfolios(state),
  parameters: selectParameters(state),
  currentTab: selectCurrentTab(state),
  benchmarks: selectBenchmarks(state),
  monthlyPerformanceTableResults: selectMonthlyPerformanceTableResults(state),
  performanceChartResults: selectPerformanceChartResults(state),
  benchmarkPerformanceResults: selectBenchmarkPerformanceResults(state),
  periodicSummaryTableResults: selectPeriodicSummaryTableResults(state),
  portfolioSecurityPerformanceResults: selectPortfolioSecurityPerformanceResults(state),
  performanceChartProgress: progressSelectorFactory([PerformanceActionTypes.FetchPerformanceChart])(state),
  monthlyPerformanceTableProgress: progressSelectorFactory([PerformanceActionTypes.FetchMonthlyPerformanceTable])(state),
  periodicSummaryTableProgress: progressSelectorFactory([PerformanceActionTypes.FetchPeriodicSummaryTable])(state),
  portfolioSecurityPerformanceProgress: progressSelectorFactory([PerformanceActionTypes.FetchPortfolioSecurityPerformanceTable])(state),
});

const mapDispatchToProps = {
  ...performanceSlice.actions,
  fetchBenchmarks,
  fetchBenchmarkPerformance,
  fetchMonthlyPortfolioPerformance,
  fetchPeriodicSummary,
  fetchPortfolioSecurityPerformance,
  fetchPerformanceChart,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export type Props = ConnectedProps<typeof connector>;
export const PerformanceContainer = connect(mapStateToProps, mapDispatchToProps)(PerformanceComponent);
