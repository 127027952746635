import { createSelector } from '@reduxjs/toolkit';
import { AddressType } from '../../../common/store/enums';
import { selectDetails } from '../../store/selectors';

export const selectTrustees = createSelector(selectDetails, (details) => details.trustees);

export const selectTrusteesItems = createSelector(selectTrustees, (trustees) => trustees.items);

export const selectTrustee = createSelector(selectTrustees, (trustees) => trustees.edit || undefined);

export const selectTrusteeId = createSelector(selectTrustee, (trustee) => trustee?.id || null);

export const selectAttachTrusteeList = createSelector(selectTrustee, (trustee) => trustee?.attachableTrustees || []);

//--------------------------------

export const selectAddresses = createSelector(selectTrustee, (edit) => edit?.addresses);

export const selectEditAddress = createSelector(selectAddresses, (addresses) => {
  if (addresses?.edit === undefined) {
    return undefined;
  }
  return addresses.items.find((address) => address.addressId === addresses.edit) ?? null;
});

export const selectAddressItems = createSelector(selectAddresses, (addresses) => addresses?.items || []);

export const selectAvailableAddressTypes = createSelector(selectAddressItems, (addresses) => {
  let addressTypes = AddressType.getArray();

  addresses.forEach((address) => {
    if (!!address.addressTypeIds) {
      address.addressTypeIds.forEach((addressTypeId) => {
        const type = AddressType.getById(addressTypeId);
        addressTypes = addressTypes.filter((item) => item !== type);
      });
    }
  });
  return addressTypes;
});

//--------------------------------

export const selectDocuments = createSelector(selectTrustee, (edit) => edit?.documents || null);

export const selectEditDocumentsParameters = createSelector(selectDocuments, (documents) => documents?.parameters);

export const selectEditDocument = createSelector(selectDocuments, (documents) => documents?.results.edit);

//--------------------------------

export const selectPhoneNumberState = createSelector(selectTrustee, (trustee) => trustee?.phoneNumbers);

export const selectPhoneNumbers = createSelector(selectPhoneNumberState, (phoneNumberState) => phoneNumberState?.items);

export const selectPhoneNumberEditId = createSelector(selectPhoneNumberState, (phoneNumberState) => phoneNumberState?.edit);

export const selectEmailAddressesState = createSelector(selectTrustee, (trustee) => trustee?.emailAddresses);

export const selectEmailAddresses = createSelector(selectEmailAddressesState, (emailAddressesState) => emailAddressesState?.items);

export const selectEmailAddressEditId = createSelector(selectEmailAddressesState, (emailAddressesState) => emailAddressesState?.edit);

// Contacts ------------
export const selectContacts = createSelector(selectTrustee, (trustee) => trustee?.contacts);

export const selectContactItems = createSelector(selectContacts, (contacts) => {
  return contacts?.items.slice().sort((a, b) => (a.isPrimary === b.isPrimary ? 0 : a.isPrimary ? -1 : 1) || a.name.localeCompare(b.name)) || [];
});

export const selectContactEdit = createSelector(selectContacts, (contacts) => contacts?.edit);

export const selectContact = createSelector(selectContactEdit, (edit) => edit?.contact);

export const selectEditContact = createSelector(selectContactEdit, (contact) => contact?.contact.edit);

export const selectEditContactId = createSelector(selectEditContact, (contact) => contact?.id || null);

export const selectAttachContactList = createSelector(selectContact, (contact) => contact?.attachContactList || []);

export const selectContactAddresses = createSelector(selectContactEdit, (contact) => contact?.addresses);

export const selectContactAddressItems = createSelector(selectContactAddresses, (addressState) => addressState?.items || []);

export const selectContactEditAddress = createSelector(selectContactAddresses, (addresses) => {
  if (addresses?.edit === undefined) {
    return undefined;
  }
  return addresses.items.find((address) => address.addressId === addresses.edit) ?? null;
});

export const selectContactAvailableAddressTypes = createSelector(selectAddressItems, (addresses) => {
  let addressTypes = AddressType.getArray();

  addresses.forEach((address) => {
    if (!!address.addressTypeIds) {
      address.addressTypeIds.forEach((addressTypeId) => {
        const type = AddressType.getById(addressTypeId);
        addressTypes = addressTypes.filter((item) => item !== type);
      });
    }
  });
  return addressTypes;
});

export const selectContactEditDocuments = createSelector(selectContactEdit, (edit) => edit?.documents || undefined);

export const selectContactEditDocumentsParameters = createSelector(selectContactEditDocuments, (documents) => documents?.parameters);

export const selectContactEditDocument = createSelector(selectContactEditDocuments, (documentsState) => documentsState?.results.edit);

export const selectContactEditIdentifications = createSelector(selectContactEdit, (edit) => edit?.identifications || undefined);

export const selectContactEditIdentificationsParameters = createSelector(selectContactEditIdentifications, (identifications) => identifications?.parameters);

export const selectContactEditIdentification = createSelector(selectContactEditIdentifications, (identificationState) => identificationState?.results.edit);

export const selectContactPhoneNumberState = createSelector(selectContactEdit, (edit) => edit?.phoneNumbers);

export const selectContactPhoneNumbers = createSelector(selectContactPhoneNumberState, (phoneNumberState) => phoneNumberState?.items || []);

export const selectContactPhoneNumberEditId = createSelector(selectContactPhoneNumberState, (phoneNumberState) => phoneNumberState?.edit);

export const selectContactEmailAddressesState = createSelector(selectContactEdit, (edit) => edit?.emailAddresses);

export const selectContactEmailAddresses = createSelector(selectContactEmailAddressesState, (emailAddressesState) => emailAddressesState?.items || []);

export const selectContactEmailAddressEditId = createSelector(selectContactEmailAddressesState, (emailAddressesState) => emailAddressesState?.edit);
