import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { progressSelectorFactory } from '../../../common';
import { RootState } from '../../../reducers';
import { AssociatedClientsComponent } from './components/associatedClientsComponent';
import { selectConsolidatedSecurityHoldingsSummary, selectParameters, selectSecurityExposure } from './store/selectors';
import { securityAssociatedClientsSlice } from './store/slice';
import {
  downloadSecurityExposure,
  fetchConsolidatedSecurityHoldingsSummary,
  fetchSecurityExposure,
  SecurityAssociatedClientsActionTypes,
} from './store/thunks';

const mapStateToProps = (state: RootState) => ({
  parameters: selectParameters(state),
  consolidatedSecurityHoldingsSummary: selectConsolidatedSecurityHoldingsSummary(state),
  securityExposure: selectSecurityExposure(state),
  consolidatedSecurityHoldingsSummaryProgress: progressSelectorFactory([SecurityAssociatedClientsActionTypes.FetchConsolidatedSecurityHoldingsSummary])(state),
  securityExposureProgress: progressSelectorFactory([SecurityAssociatedClientsActionTypes.FetchSecurityExposure])(state),
});

const mapDispatchToProps = {
  ...securityAssociatedClientsSlice.actions,
  fetchConsolidatedSecurityHoldingsSummary,
  fetchSecurityExposure,
  downloadSecurityExposure,
};

interface MatchParams {
  securityId?: string;
  securityCode?: string;
}

const connector = connect(mapStateToProps, mapDispatchToProps);
export type Props = ConnectedProps<typeof connector> & RouteComponentProps<MatchParams>;
export const SecurityAssociatedClientsStateContainer = connect(mapStateToProps, mapDispatchToProps)(AssociatedClientsComponent);
