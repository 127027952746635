import { moment } from 'src/common/types';
import { DateRange, PagedRequest, PagedResult } from '../../../../store';

export interface SecurityDashboardParameters {
  pagination: PagedRequest;
  dates: DateRange;
  dailySecurityPricesChartStartDate: {
    startDate: moment;
    interval: DashboardChartInterval;
  };
  selectedGroup: string;
}

export enum DashboardChartInterval {
  OneWeek = '1W',
  OneMonth = '1M',
  ThreeMonths = '3M',
  SixMonths = '6M',
  OneYear = '1Y',
  Inception = 'ALL',
}

export interface DashboardChartStartDateParameter {
  startDate: moment;
  interval: DashboardChartInterval;
}

export interface ConsolidatedSecurityHoldingsSummary {
  securityId: number;
  fua: number;
  portfolioCount: number;
}

export interface SecurityPricesItem {
  date: moment;
  price: number;
  priceChange: number;
  priceChangePercentage: number;
}

export interface SecurityDashboard {
  securityId: number;
  category: string;
  currency: string;
  gicsSector: string;
  gicsSubGroup: string;
  market: string;
  type: string;
  lastPrice: number;
  lastPriceDate: moment | undefined;
  oneMonthTotalReturn: number;
  oneYearRange: {
    from: number;
    to: number;
  };
  oneYearDividendYield: number;
  oneYearFranking: number;
  oneYearGrossYield: number;
  oneYearIncome: number;
  oneYearTotalReturn: number;
}

export interface DailySecurityPricesChartItem {
  date: moment;
  price: number;
}

export interface SecurityDashboardState {
  parameters: SecurityDashboardParameters;
  consolidatedSecurityHoldingsSummary: ConsolidatedSecurityHoldingsSummary | null;
  securityPrices: PagedResult<SecurityPricesItem> | null;
  securityDashboard: SecurityDashboard | null;
  dailySecurityPricesChart: DailySecurityPricesChartItem[];
}

export interface ConsolidatedSecurityHoldingsSummaryThunkParameters {
  securityId: number;
}

export interface SecurityDashboardThunkParameters {
  securityId: number;
  securityCode: string;
}

export interface DailySecurityPricesChartThunkParameters {
  securityId: number;
  fromDate: moment;
  toDate: moment;
}

export interface fetchPagedResults<T> {
  results: PagedResult<T>;
  pagination: PagedRequest;
}

export interface SecurityPricesThunkParameters {
  pagination: PagedRequest;
  securityId: number;
}
