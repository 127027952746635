import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { progressSelectorFactory } from '../../../common';
import { RootState } from '../../../reducers';
import { AssociationsComponent } from './components/AssociationsComponent';
import { selectAssociations } from './store/selectors';
import { strategyAssociationsSlice } from './store/slice';
import { fetchStrategyAssociations, StrategyAssociationsActionTypes } from './store/thunks';

const actionTypes = [StrategyAssociationsActionTypes.FetchStrategyAssociations];

const mapStateToProps = (state: RootState) => ({
  strategyAssociations: selectAssociations(state),
  loadingProgress: progressSelectorFactory(actionTypes)(state),
});

const mapDispatchToProps = {
  ...strategyAssociationsSlice.actions,
  fetchStrategyAssociations,
};

interface MatchParams {
  strategyVersionId?: string;
}

const connector = connect(mapStateToProps, mapDispatchToProps);
export type Props = ConnectedProps<typeof connector> & RouteComponentProps<MatchParams>;
export const StrategyAssociationsStateContainer = connect(mapStateToProps, mapDispatchToProps)(AssociationsComponent);
