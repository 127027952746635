import { connect, ConnectedProps } from 'react-redux';
import { RootState } from 'src/reducers';
import { selectHasClientEditAdminOnly } from 'src/store/selectors';
import { Bulk } from './common/components/bulk';
import { fetchAfsls, fetchClients, fetchEmailTemplates, fetchParameters } from './common/store/thunks';

const mapDispatchToProps = {
  fetchAfsls,
  fetchEmailTemplates,
  fetchParameters,
  fetchClients,
};

const mapStateToProps = (state: RootState) => ({
  hasClientEditAdminOnlyPermission: selectHasClientEditAdminOnly(state),
});
const connector = connect(mapStateToProps, mapDispatchToProps);
export type Props = ConnectedProps<typeof connector>;
export const BulkContainer = connector(Bulk);
