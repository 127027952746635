import { PayloadAction } from '@reduxjs/toolkit';
import * as E from 'fp-ts/lib/Either';
import * as t from 'io-ts';
import reporter from 'io-ts-reporters';
import _ from 'lodash';
import { parse, ParseResult } from 'papaparse';
import { call, ForkEffect, put, select, takeLatest } from 'redux-saga/effects';
import { getCustomAsyncActions } from '../../../../../../store/asyncAction';
import { selectRebalance } from '../selectors';
import { validateSecuritiesService } from '../services';
import { ImportRequestPayload, ImportTrade, Rebalance, SecuritiesValidationRequest, SecurityValidationResult, Trade, TradeAction, TradeMethod } from '../types';

export const importTradesActionType = '@@rebalance/edit/importTrades';
export const importTradesAction = getCustomAsyncActions<ImportRequestPayload, Trade[], string[]>(importTradesActionType);

const trade: t.Type<ImportTrade> = t.type({
  securityCode: t.string,
  tradeAction: t.string,
  method: t.union([t.null, t.string]),
  amountToTrade: t.number,
});

const decodeData = (data: Trade[]) => {
  const errors: string[] = [] as string[];
  const trades: Trade[] = [] as Trade[];
  data.forEach((element, index) => {
    const result = trade.decode(element);
    if (E.isLeft(result)) {
      const error = reporter.report(result);
      errors.push(`row: ${index + 1} message: ${error.toString()}\n`);
    } else {
      if (
        result.right.method &&
        result.right.method.toLowerCase() !== 'units' &&
        result.right.method.toLowerCase() !== 'percentage' &&
        result.right.method.toLowerCase() !== 'dollar'
      ) {
        errors.push(`row: ${index + 1} message: trade method ${result.right.method} is unrecognised\n`);
      }
      if (
        result.right.tradeAction.toLocaleLowerCase() !== 'buy' &&
        result.right.tradeAction.toLocaleLowerCase() !== 'sell' &&
        result.right.tradeAction.toLocaleLowerCase() !== 'sellall'
      ) {
        errors.push(`row: ${index + 1} message: trade action ${result.right.tradeAction} is unrecognised\n`);
      }
      if (!errors || errors.length === 0) {
        trades.push({
          securityCode: result.right.securityCode,
          amountToTrade: result.right.amountToTrade,
          method:
            result.right.method?.toLocaleLowerCase() === 'units'
              ? TradeMethod.Hash
              : result.right.method?.toLocaleLowerCase() === 'percentage'
              ? TradeMethod.Percentage
              : result.right.method?.toLocaleLowerCase() === 'dollar'
              ? TradeMethod.Dollar
              : null,
          tradeAction:
            result.right.tradeAction.toLocaleLowerCase() === 'buy'
              ? TradeAction.Buy
              : result.right.tradeAction.toLocaleLowerCase() === 'sell'
              ? TradeAction.Sell
              : TradeAction.All,
        } as Trade);
      }
    }
  });
  return { trades: trades, errors: errors };
};

const validateData = (data: Trade[]) => {
  const errors: string[] = [] as string[];

  // Remove cash trades
  const trades = data.filter((importedTrade) => !importedTrade.securityCode.toLowerCase().endsWith('.adi'));

  // Check for duplicate securities
  const duplicates: Trade[] = _.flow([
    (securityCodeData) => _.groupBy(securityCodeData, 'securityCode'),
    (groupedBySecurityCode) => _.filter(groupedBySecurityCode, (group) => group.length > 1),
    _.flatten,
  ])(trades);

  if (duplicates && duplicates.length > 0) {
    errors.push(`Duplicate securities found: ${JSON.stringify(duplicates)}\n`);
    return { trades: [], errors: errors };
  }

  // Check method should not be null or unfined now cash trades are removed
  const emptyMethods = trades.filter((importedTrade) => {
    return !importedTrade.method || importedTrade.method === undefined || importedTrade.method === null;
  });

  if (emptyMethods && emptyMethods.length > 0) {
    errors.push(`Trades found with invalid trading method: ${JSON.stringify(emptyMethods)}\n`);
    return { trades: [], errors: errors };
  }

  // Check trade action for term deposits
  const invalidTradeActions = trades.filter((importedTrade) => {
    return (
      (importedTrade.tradeAction === TradeAction.Sell || importedTrade.tradeAction === TradeAction.All) &&
      importedTrade.securityCode.toLowerCase().endsWith('.adm')
    );
  });

  if (invalidTradeActions && invalidTradeActions.length > 0) {
    errors.push(`Trades found with invalid trading actions for term deposits: ${JSON.stringify(invalidTradeActions)}\n`);
    return { trades: [], errors: errors };
  }

  return { trades: trades, errors: errors };
};

function parseImportFile(importFile: File) {
  return new Promise<ParseResult<unknown>>((resolve) => {
    parse(importFile, {
      header: true,
      dynamicTyping: true,
      skipEmptyLines: true,
      complete: function (results) {
        resolve(results);
      },
    });
  });
}

function* importTrades(action: PayloadAction<ImportRequestPayload>): Generator<unknown, unknown, never> {
  try {
    const result: ParseResult<unknown> = yield call(parseImportFile, action.payload.file);

    // failed parsing
    if (result.errors && result.errors.length > 0) {
      const parseErrors = result.errors.map((error) => {
        return `row: ${error.row} type: ${error.type} code: ${error.code} message: ${error.message}\n`;
      });

      return yield put(importTradesAction.rejected(parseErrors));
    }

    // decode data
    const decodedData = decodeData(result.data as Trade[]);
    if (decodedData.errors && decodedData.errors.length > 0) {
      return yield put(importTradesAction.rejected(decodedData.errors));
    }

    // validate data
    const validatedData = validateData(decodedData.trades);
    if (validatedData.errors && validatedData.errors.length > 0) {
      return yield put(importTradesAction.rejected(validatedData.errors));
    }

    // Add new trade data
    const rebalance: Rebalance = yield select(selectRebalance);
    const newSecurities =
      validatedData.trades && validatedData.trades.length > 0
        ? validatedData.trades.filter((newTrade: Trade) => !rebalance.trades.some((trade: Trade) => newTrade.securityCode === trade.securityCode))
        : [];

    // Check that you cannot have sell or sell all new trades
    const invalidNewTrades = newSecurities.filter((newTrade) => newTrade.tradeAction !== TradeAction.Buy);
    if (invalidNewTrades && invalidNewTrades.length > 0) {
      return yield put(importTradesAction.rejected([`Trades found with invalid trading actions for new trades: ${JSON.stringify(invalidNewTrades)}\n`]));
    }

    const newSecurityCodes = newSecurities.map((newTrade) => {
      return newTrade.securityCode;
    });

    if (newSecurityCodes.length > 0) {
      const securitiesValidationRequest: SecuritiesValidationRequest = {
        afslId: rebalance.afslId,
        SecurityCodes: newSecurityCodes,
      };
      const securityValidationResult: SecurityValidationResult = yield call(validateSecuritiesService, securitiesValidationRequest);

      // Errors getting securities for afsl
      if (securityValidationResult.errors && securityValidationResult.errors.length > 0) {
        return yield put(importTradesAction.rejected(securityValidationResult.errors));
      }

      const importedTrades = validatedData.trades.map((trade) => {
        const newSecurity = securityValidationResult.securities.find((security) => security.securityCode === trade.securityCode);
        if (newSecurity) {
          return {
            ...trade,
            securityId: newSecurity.securityId,
            securityName: newSecurity.securityName,
            securityCode: newSecurity.securityCode,
            currentUnitPriceTime: newSecurity.currentUnitPriceTime,
            currentUnitPrice: newSecurity.currentUnitPrice,
            securityType: newSecurity.securityType,
            marketCode: newSecurity.marketCode,
            assetClass: newSecurity.assetClass,
            assetClassId: newSecurity.assetClassId,
            modelName: null,
          };
        }
        return trade;
      });

      return yield put(importTradesAction.fulfilled(importedTrades));
    }
    return yield put(importTradesAction.fulfilled(validatedData.trades));
  } catch (e) {
    const error = [`${e.status} - ${e.statusText}`];
    yield put(importTradesAction.rejected(error));
  }
}

export function* importTradesSaga(): Generator<ForkEffect<never>, void, unknown> {
  yield takeLatest(importTradesAction.pending, importTrades);
}
