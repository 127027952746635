import { DateRange } from 'src/store/types/dateRange';
import { Enumeration } from '../../../../../common/types';
import { AdviserItem, AfslItem, ModelItem, PracticeItem, SecurityItem } from '../../../common/store/types';

export interface ReportSearchState {
  parameters: ReportSearchParameters;
  securities: SecurityItem[];
  models: ModelItem[];
  results: ReportSearchResult[];
  selectedResults: number[];
}

export interface ReportSearchParameters {
  afsls: AfslItem[];
  practices: PracticeItem[];
  advisers: AdviserItem[];
  clientTypes: string[];
  serviceType: string;
  statuses: string[];
  holdingsValue: NumericValue;
  availableCashBalance: NumericValue;
  servicesAgreementDate: DateRange;
  inceptionDate: DateRange;
  holdingsDate: DateRange;
  associations: SecurityModelAssociation[];
  securityHeld: string;
  security: SecurityItem | null;
  securitySearch: string | null;
  model: ModelItem | null;
}

export interface NumericValue {
  parameterType?: string;
  fromValue: number | null;
  toValue: number | null;
}

export interface ReportSearchResult {
  clientId: number;
  clientName: string;
  accountType: string;
  adviserId: number;
  adviserName: string;
  executionDate: string;
  inceptionDate: string;
  status: string;
}

export interface SecurityModelAssociation {
  associationType: SecurityModelAssociationType;
  id: number;
  name: string;
  isHeld: boolean;
}

export class SecurityModelAssociationType extends Enumeration {
  static Security = new SecurityModelAssociationType(0, 'Security', 'Security');
  static Model = new SecurityModelAssociationType(1, 'Model', 'Model');
}
export class SecurityHeld extends Enumeration {
  static Hold = new SecurityHeld(1, 'Hold', 'Hold');
  static NotHeld = new SecurityHeld(2, 'NotHeld', "Doesn't hold");
}

export class ClientAccountStatus extends Enumeration {
  static Pending = new ClientAccountStatus(1, 'Pending', 'Pending');
  static Active = new ClientAccountStatus(2, 'Active', 'Active');
  static Terminated = new ClientAccountStatus(3, 'Terminated', 'Terminated');
  static WaitingforApproval = new ClientAccountStatus(4, 'WaitingforApproval', 'Waiting for Approval');
  static Stalled = new ClientAccountStatus(5, 'Stalled', 'Stalled');
  static DatafeedActive = new ClientAccountStatus(6, 'DatafeedActive', 'Datafeed Active');
}
