import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchDataFeeds } from './thunks';
import { DataFeed, UserSettingsState } from './types';

export const initialState: UserSettingsState = {
  dataFeeds: [],
};

export const userSettingsSlice = createSlice({
  name: '@@store/userSettings',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchDataFeeds.fulfilled, (state, action: PayloadAction<DataFeed[]>) => {
      state.dataFeeds = action.payload;
    });
  },
});
