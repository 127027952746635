import { connect, ConnectedProps } from 'react-redux';
import { isLoadingSelectorFactory } from '../../../../common/store/selectors/isLoadingSelectorFactory';
import { RootState } from '../../../../reducers';
import Edit from './components/edit';
import { importTradesAction } from './store/sagas/importSaga';
import { selectHasRebalanceEnhancedTrading, selectHasClientEditAdminOnly } from '../../../../store/selectors';
import {
  selectExportTrades,
  selectFilter,
  selectGroupedByKey,
  selectGroupedFilteredTrades,
  selectImportErrors,
  selectIsPreApproved,
  selectRebalance,
  selectRebalanceKey,
  selectRebalanceValidationRequest,
  selectSecurity,
  selectSecuritySearchResults,
  selectSummary,
  selectValidationMessages,
} from './store/selectors';
import { rebalanceEditSlice } from './store/slice';
import { deleteRebalance, fetchRebalance, fetchSecurities, fetchSecurity, validateRebalance } from './store/thunks';

const mapStateToProps = (state: RootState) => ({
  rebalance: selectRebalance(state),
  filteredTrades: selectGroupedFilteredTrades(state),
  rebalanceKey: selectRebalanceKey(state),
  filter: selectFilter(state),
  summary: selectSummary(state),
  isImporting: isLoadingSelectorFactory([importTradesAction.actionName])(state),
  validationMessages: selectValidationMessages(state),
  groupedByKey: selectGroupedByKey(state),
  isPreApproved: selectIsPreApproved(state),
  selectedSecurity: selectSecurity(state),
  securitySearchResults: selectSecuritySearchResults(state),
  rebalanceValidationRequest: selectRebalanceValidationRequest(state),
  selectedExportTrades: selectExportTrades(state),
  importErrors: selectImportErrors(state),
  hasRebalanceEnhancedTrading: selectHasRebalanceEnhancedTrading(state),
  hasClientEditAdminOnlyPermission: selectHasClientEditAdminOnly(state),
});

const importTrades = importTradesAction.pending;
const mapDispatchToProps = {
  ...rebalanceEditSlice.actions,
  deleteRebalance,
  fetchRebalance,
  fetchSecurities,
  fetchSecurity,
  validateRebalance,
  importTrades,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export type Props = ConnectedProps<typeof connector>;
export const RebalanceEditContainer = connect(mapStateToProps, mapDispatchToProps)(Edit);
