import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { progressSelectorFactory } from '../../../../../common';
import { RootState } from '../../../../../reducers';
import { selectHasClientEdit } from '../../../../../store/selectors';
import { selectClientId, selectDocumentTypes, selectRoles } from '../../common/store/selectors';
import { CommonActionTypes, fetchDocumentTypes, fetchRoles } from '../../common/store/thunks';
import { Contacts } from './components/contacts';
import {
  selectAddressItems,
  selectAttachContactList,
  selectAvailableAddressTypes,
  selectEditAddress,
  selectEditContact,
  selectEditContactId,
  selectEditDocument,
  selectEditDocuments,
  selectEditDocumentsParameters,
  selectEditIdentification,
  selectEditIdentifications,
  selectEditIdentificationsParameters,
  selectEmailAddressEditId,
  selectEmailAddressesState,
  selectItems,
  selectPhoneNumberEditId,
  selectPhoneNumberState,
} from './store/selectors';
import { contactsSlice } from './store/slice';
import {
  attachContact,
  ContactsActionTypes,
  deleteAddress,
  deleteDocument,
  deleteEmailAddress,
  deletePhoneNumber,
  detachContact,
  downloadDocument,
  fetchAddresses,
  fetchContact,
  fetchContactDetails,
  fetchContacts,
  fetchContactsToAttach,
  fetchDocumentForEdit,
  fetchDocuments,
  fetchIdentificationForEdit,
  fetchIdentifications,
  saveAddress,
  saveContact,
  saveDocument,
  saveEmailAddress,
  savePhoneNumber,
  updateContact,
  updateContactRoles,
} from './store/thunks';

const mapStateToProps = (state: RootState) => ({
  hasClientEditPermission: selectHasClientEdit(state),
  clientId: selectClientId(state),
  contacts: selectItems(state),

  contact: selectEditContact(state),
  contactId: selectEditContactId(state),
  loadingContactProgress: progressSelectorFactory([ContactsActionTypes.FetchContact])(state),
  loadingContactsProgress: progressSelectorFactory([ContactsActionTypes.FetchContacts])(state),
  saveContactProgress: progressSelectorFactory([ContactsActionTypes.CreateContact, ContactsActionTypes.UpdateContact])(state),
  contactsToAttach: selectAttachContactList(state),
  loadingContactsToAttach: progressSelectorFactory([ContactsActionTypes.FetchContactsToAttach])(state),
  roles: selectRoles(state),
  loadingRolesProgress: progressSelectorFactory([CommonActionTypes.FetchRoles])(state),
  updateContactRolesProgress: progressSelectorFactory([ContactsActionTypes.UpdateContactRoles])(state),

  addresses: selectAddressItems(state),
  editAddress: selectEditAddress(state),
  loadingAddressesProgress: progressSelectorFactory([ContactsActionTypes.FetchAddresses])(state),
  savingAddressProgress: progressSelectorFactory([ContactsActionTypes.UpdateAddress, ContactsActionTypes.DeleteAddress])(state),
  availableAddressTypes: selectAvailableAddressTypes(state),

  documents: selectEditDocuments(state),
  documentsParameters: selectEditDocumentsParameters(state),
  documentTypes: selectDocumentTypes(state),
  editDocument: selectEditDocument(state),
  loadingDocumentsProgress: progressSelectorFactory([ContactsActionTypes.FetchDocuments])(state),
  saveDocumentProgress: progressSelectorFactory([ContactsActionTypes.SaveDocument])(state),

  identifications: selectEditIdentifications(state),
  identificationsParameters: selectEditIdentificationsParameters(state),
  editIdentification: selectEditIdentification(state),
  loadingIdentificationsProgress: progressSelectorFactory([ContactsActionTypes.FetchIdentifications])(state),

  phoneNumbers: selectPhoneNumberState(state),
  phoneNumberEditId: selectPhoneNumberEditId(state),
  loadingContactDetailsProgress: progressSelectorFactory([ContactsActionTypes.FetchContactDetails])(state),

  emailAddresses: selectEmailAddressesState(state),
  emailAddressEditId: selectEmailAddressEditId(state),
});

const mapDispatchToProps = {
  ...contactsSlice.actions,
  fetchContacts,
  fetchContact,
  fetchContactsToAttach,
  attachContact,
  updateContactRoles,
  detachContact,
  saveContact,
  updateContact,
  fetchRoles,

  fetchAddresses,
  saveAddress,
  deleteAddress,

  fetchDocuments,
  downloadDocument,
  fetchDocumentForEdit,
  fetchDocumentTypes,
  fetchIdentifications,
  fetchIdentificationForEdit,
  deleteDocument,
  saveDocument,

  fetchContactDetails,
  savePhoneNumber,
  deletePhoneNumber,
  saveEmailAddress,
  deleteEmailAddress,
};

interface MatchParams {
  id?: string;
  contactId?: string;
}

const connector = connect(mapStateToProps, mapDispatchToProps);
export type Props = ConnectedProps<typeof connector> & RouteComponentProps<MatchParams>;
export const ContactsContainer = connector(Contacts);
