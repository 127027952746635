import { AnyAction, CombinedState, combineReducers, Dispatch } from '@reduxjs/toolkit';
import { commonSlice } from '../common/store/slice';
import { ClientCommonState } from '../common/store/types';
import { dashboardSlice } from '../dashboard/store/slice';
import { DashboardState } from '../dashboard/store/types';
import { detailsReducer } from '../details/store';
import { DetailsState } from '../details/store/reducer';
import { gainsAndLossesSlice } from '../gainslosses/store/slice';
import { GainsLossesState } from '../gainslosses/store/types';
import { performanceSlice } from '../performance/store/slice';
import { PerformanceState } from '../performance/store/types';
import { portfolioReducer } from '../portfolio/store/reducer';
import { PortfolioState } from '../portfolio/store/types';
import { reportsSlice } from '../reports/store/slice';
import { ClientReportsState } from '../reports/store/types';
import { transactionsSlice } from '../transactions/store/slice';
import { TransactionsState } from '../transactions/store/types';

export enum ClientReducerActionTypes {
  ResetClientState = '@@client/ResetClientState',
}

export interface ClientState {
  common: ClientCommonState;
  dashboard: DashboardState;
  performance: PerformanceState;
  portfolio: PortfolioState;
  gainsAndLosses: GainsLossesState;
  transactions: TransactionsState;
  details: DetailsState;
  reports: ClientReportsState;
}

export const clientReducer = combineReducers<ClientState>({
  common: commonSlice.reducer,
  dashboard: dashboardSlice.reducer,
  performance: performanceSlice.reducer,
  portfolio: portfolioReducer,
  gainsAndLosses: gainsAndLossesSlice.reducer,
  transactions: transactionsSlice.reducer,
  details: detailsReducer,
  reports: reportsSlice.reducer,
});

export const clientRootReducer = (state: ClientState | undefined, action: AnyAction): CombinedState<ClientState> => {
  if (action.type === ClientReducerActionTypes.ResetClientState) {
    state = undefined;
  }

  return clientReducer(state, action);
};

export const resetClientStateAction: AnyAction = {
  type: ClientReducerActionTypes.ResetClientState,
};

export function setClientIdParameterActionCreator(clientId: number) {
  return (dispatch: Dispatch): void => {
    dispatch(resetClientStateAction);
    dispatch(commonSlice.actions.setClientIdParameter(clientId));
  };
}
