import { Box, Fade, Grid, Paper, Typography } from '@mui/material';
import { Field, FieldProps, Form, Formik, FormikProps } from 'formik';
import React from 'react';
import { CreatePendingAccountPayload, PendingAccountCreate } from 'src/features/clients/client/details/accounts/store/types';
import * as yup from 'yup';
import { WO2Modal } from '../../';
import { LoadingProgress } from '../../../store/types';
import { EditCancelSaveButtons, FormikNumberFormat, FormikTextField, Mode } from '../../formik';
import { AccountInstitutionItem } from '../accountInstitutionItem';
import { ExistingAccount, PendingAccountInstitutionType } from '../types';

export interface LinkExistingAccountProps {
  existingAccounts: ExistingAccount[];
  isOpen: boolean;
  saveProgress?: LoadingProgress;
  handleCloseModal: () => void;
  onSave: (payload: CreatePendingAccountPayload) => void;
  clientId: number | null;
}

interface FormValues {
  existingAccounts: ExistingAccount[];
}

export function WO2LinkExistingAccount({ existingAccounts, handleCloseModal, onSave, clientId, isOpen, saveProgress }: LinkExistingAccountProps): JSX.Element {
  const initialFormValues: FormValues = {
    existingAccounts: existingAccounts.map((existingAccount: ExistingAccount) => {
      return {
        ...existingAccount,
        accountName: existingAccount.accountName ?? null,
        accountNumber: existingAccount.accountNumber ?? null,
        bsb: existingAccount.bsb ?? null,
        accountId: existingAccount.accountId ?? null,
      };
    }),
  };

  return (
    <WO2Modal
      MuiDialogProps={{
        open: isOpen,
        onClose: handleCloseModal,
      }}
      title={<>Link Existing Account(s)</>}
      actions={[]}
      maxWidth={'md'}
    >
      <Fade in={isOpen}>
        <Paper elevation={0}>
          <Formik<FormValues>
            enableReinitialize={true}
            initialValues={initialFormValues}
            validationSchema={yup.object({
              existingAccounts: yup
                .array()
                .ensure()
                .of(
                  yup.object().shape({
                    institutionId: yup.number(),
                    bsb: yup
                      .mixed()
                      .nullable()
                      .when(['institutionId'], {
                        is: (institutionId) => institutionId === PendingAccountInstitutionType.MacquarieCma.id,
                        then: yup
                          .string()
                          .required('BSB is required')
                          .matches(/^[0-9]{6}$/, 'BSB must be 6 digits'),
                      }),
                    accountName: yup.string().required('Account Name is required'),
                    accountNumber: yup
                      .mixed()
                      .nullable()
                      .when(['institutionId'], {
                        is: (institutionId) =>
                          institutionId === PendingAccountInstitutionType.MacquarieCma.id ||
                          institutionId === PendingAccountInstitutionType.DesktopBroker.id ||
                          institutionId === PendingAccountInstitutionType.Openmarkets.id,
                        then: yup.string().required('Account Number is required'),
                      }),
                  })
                ),
            })}
            onSubmit={(values: FormValues) => {
              const pendingAccounts: PendingAccountCreate[] = [];

              values.existingAccounts.forEach((item) => {
                pendingAccounts.push({
                  institution:
                    PendingAccountInstitutionType.getAllNewAccountInstitutions().find(
                      (accountInstitution: PendingAccountInstitutionType) => accountInstitution.id === item.institutionId
                    )?.name ?? '',
                  name: item.accountName || null,
                  number: item.accountNumber || null,
                  subNumber: item.bsb || null,
                  hin: null,
                  pid: null,
                  state: 'Existing',
                });
              });
              if (clientId) {
                const payload: CreatePendingAccountPayload = {
                  clientId,
                  pendingAccounts,
                };
                onSave(payload);
              }
            }}
          >
            {(formikProps: FormikProps<FormValues>) => (
              <Form>
                <Typography variant="h4" style={{ margin: '30px 0' }}>
                  Select which new portfolio account(s) you want to link
                </Typography>

                <Box display="flex" data-testid="linkExistingAccountBox" marginBottom="40px">
                  {PendingAccountInstitutionType.getAllExistingAccountInstitutions().map((accountInstitution: PendingAccountInstitutionType) => {
                    return (
                      <AccountInstitutionItem
                        key={`accountInstitutionItem_${accountInstitution.id}`}
                        accountInstitution={accountInstitution}
                        isSelected={
                          formikProps.values.existingAccounts.findIndex(
                            (existingAccount: ExistingAccount) => existingAccount.institutionId === accountInstitution.id
                          ) !== -1
                        }
                        isAvailable={true}
                        onClickHandler={(institutionId: number) => {
                          const institutionIndex = formikProps.values.existingAccounts.findIndex(
                            (existingAccount: ExistingAccount) => existingAccount.institutionId === institutionId
                          );
                          formikProps.setFieldValue(
                            'existingAccounts',
                            institutionIndex === -1
                              ? Array.from([
                                  ...formikProps.values.existingAccounts,
                                  {
                                    institutionId,
                                    accountName: null,
                                    accountNumber: null,
                                    bsb: null,
                                    accountId: null,
                                    accountTypeId: null,
                                    isExternal: null,
                                    securityStatus: null,
                                    institution: null,
                                  },
                                ]).filter(
                                  (value: ExistingAccount, index, self) =>
                                    self.map((v: ExistingAccount) => v.institutionId).indexOf(value.institutionId) === index
                                )
                              : formikProps.values.existingAccounts.filter(
                                  (existingAccount: ExistingAccount) => existingAccount.institutionId !== institutionId
                                )
                          );
                          // hide all errors when another insititution is selected
                          if (institutionIndex !== -1) {
                            formikProps.setFieldTouched(`existingAccounts.${institutionIndex}.bsb`, false, false);
                            formikProps.setFieldTouched(`existingAccounts.${institutionIndex}.clientId`, false, false);
                            formikProps.setFieldTouched(`existingAccounts.${institutionIndex}.accountName`, false, false);
                            formikProps.setFieldTouched(`existingAccounts.${institutionIndex}.accountNumber`, false, false);
                          }
                        }}
                      />
                    );
                  })}
                </Box>
                {formikProps.values.existingAccounts.length > 0 && (
                  <Box width="100%" display="flex" flexWrap="wrap" justifyContent="space-between">
                    {formikProps.values.existingAccounts.map((existingAccount: ExistingAccount, index: number) => {
                      return (
                        <Grid key={`existingAccounts${index}`} container>
                          {existingAccount.institutionId === PendingAccountInstitutionType.MacquarieCma.id && (
                            <Grid item xs={7} data-testid={`existingAccounts${PendingAccountInstitutionType.MacquarieCma.name}`}>
                              <Typography variant="h5" style={{ marginBottom: '30px' }}>
                                {PendingAccountInstitutionType.MacquarieCma.displayName}
                              </Typography>

                              <Grid item xs={12} style={{ marginBottom: '16px', minHeight: '70px' }}>
                                <Field name={`existingAccounts.${index}.bsb`} label="BSB">
                                  {(fieldProps: FieldProps) => {
                                    return (
                                      <FormikNumberFormat
                                        formikFieldProps={fieldProps}
                                        numberFormatProps={{
                                          format: '###-###',
                                          mask: '_',
                                          placeholder: '',
                                          name: fieldProps.field.name,
                                          label: 'BSB',
                                          isNumericString: true,
                                        }}
                                        showRequiredAsterisk
                                        fullWidth={true}
                                      />
                                    );
                                  }}
                                </Field>
                              </Grid>
                              <Grid item xs={12} style={{ marginBottom: '16px', minHeight: '70px' }}>
                                <Field
                                  name={`existingAccounts.${index}.accountName`}
                                  component={FormikTextField}
                                  label="ACCOUNT NAME"
                                  showRequiredAsterisk
                                  fullWidth
                                />
                              </Grid>
                              <Grid item xs={12} style={{ marginBottom: '16px', minHeight: '70px' }}>
                                <Field
                                  name={`existingAccounts.${index}.accountNumber`}
                                  component={FormikTextField}
                                  label="ACCOUNT NUMBER"
                                  showRequiredAsterisk
                                  fullWidth
                                />
                              </Grid>
                            </Grid>
                          )}
                          {existingAccount.institutionId === PendingAccountInstitutionType.DesktopBroker.id && (
                            <Grid item xs={7} data-testid={`existingAccounts${PendingAccountInstitutionType.DesktopBroker.name}`}>
                              <Typography variant="h5" style={{ marginBottom: '30px' }}>
                                {PendingAccountInstitutionType.DesktopBroker.displayName}
                              </Typography>

                              <Grid item xs={12} style={{ marginBottom: '16px', minHeight: '70px' }}>
                                <Field
                                  name={`existingAccounts.${index}.accountName`}
                                  component={FormikTextField}
                                  label="ACCOUNT NAME"
                                  showRequiredAsterisk
                                  fullWidth
                                />
                              </Grid>
                              <Grid item xs={12} style={{ marginBottom: '16px', minHeight: '70px' }}>
                                <Field
                                  name={`existingAccounts.${index}.accountNumber`}
                                  component={FormikTextField}
                                  label="ACCOUNT NUMBER"
                                  showRequiredAsterisk
                                  fullWidth
                                />
                              </Grid>
                            </Grid>
                          )}
                          {existingAccount.institutionId === PendingAccountInstitutionType.Openmarkets.id && (
                            <Grid item xs={7} data-testid={`existingAccounts${PendingAccountInstitutionType.Openmarkets.name}`}>
                              <Typography variant="h5" style={{ marginBottom: '30px' }}>
                                {PendingAccountInstitutionType.Openmarkets.displayName}
                              </Typography>

                              <Grid item xs={12} style={{ marginBottom: '16px', minHeight: '70px' }}>
                                <Field
                                  name={`existingAccounts.${index}.accountName`}
                                  component={FormikTextField}
                                  label="ACCOUNT NAME"
                                  showRequiredAsterisk
                                  fullWidth
                                />
                              </Grid>
                              <Grid item xs={12} style={{ marginBottom: '16px', minHeight: '70px' }}>
                                <Field
                                  name={`existingAccounts.${index}.accountNumber`}
                                  component={FormikTextField}
                                  label="ACCOUNT NUMBER"
                                  showRequiredAsterisk
                                  fullWidth
                                />
                              </Grid>
                            </Grid>
                          )}
                          {existingAccount.institutionId === PendingAccountInstitutionType.AMM.id && (
                            <Grid item xs={7} data-testid={`existingAccounts${PendingAccountInstitutionType.AMM.name}`}>
                              <Typography variant="h5" style={{ marginBottom: '30px' }}>
                                {PendingAccountInstitutionType.AMM.displayName}
                              </Typography>

                              <Grid item xs={12} style={{ marginBottom: '16px', minHeight: '70px' }}>
                                <Field
                                  name={`existingAccounts.${index}.accountName`}
                                  component={FormikTextField}
                                  label="ACCOUNT NAME"
                                  showRequiredAsterisk
                                  fullWidth
                                />
                              </Grid>
                            </Grid>
                          )}
                        </Grid>
                      );
                    })}
                  </Box>
                )}
                <Grid item xs={12} style={{ marginTop: '30px' }}>
                  <EditCancelSaveButtons mode={Mode.CancelSave} handleCancelClick={handleCloseModal} saveProgress={saveProgress} />
                </Grid>
              </Form>
            )}
          </Formik>
        </Paper>
      </Fade>
    </WO2Modal>
  );
}
