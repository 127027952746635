import { Box, Typography } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { ClientAccountSubType, ClientAccountType } from 'src/common/types';
import { Title } from '../../../../../common/types';
import { BeneficialOwnerRoleId, BeneficiaryRoleId, DirectorCompanyOfficerRoleId } from '../../../client/common/store/types';
import { ProcessClientDialog } from '../../../common/components/processClientDialog';
import { AccountTypeHeader } from '../../additionalInfo/components/accountTypeHeader';
import { PageBackNextButtons } from '../../components/common/pageBackNextButtons';
import { Contact, ContactInfoSummary, ReviewValues } from '../../store/types';
import { Props } from '../container';
import { Accounts } from './accounts';
import { AdditionalInfo } from './additionalInfo';
import { AddressTable } from './addressTable';
import { AdviceFees } from './adviceFees';
import { ContactDetails } from './contactDetails';
import { ContactSummaryTable } from './contactSummaryTable';
import { SuperSimplifier } from './superSimplifier';

export const Review = (props: Props): JSX.Element => {
  const { pathname } = useLocation();

  const {
    history,
    client,
    prevNextRoutePaths,
    addressesValues,
    adviceFeesValues,
    contactItems,
    roles,
    saveOnboarding,
    savingProgress,
    externalInstitutions,
    processClient,
    submitted,
  } = props;
  const accountTypeValues = {
    id: client.id,
    advisor: client.advisor,
    clientAccount: client.clientAccount,
    superSimplifierDetails: client.superSimplifierDetails,
  };

  const [isAccountTypeSuperOrPension, setIsAccountTypeSuperOrPension] = useState<boolean>(false);

  useEffect(() => {
    if (submitted) {
      history.push(`/client/list?mode=onboard`);
    }
  }, [submitted]);

  useEffect(() => {
    setIsAccountTypeSuperOrPension(
      client.clientAccount.accountTypeId === ClientAccountType.Individual.id &&
        (client.clientAccount.subTypeId === ClientAccountSubType.Super.id || client.clientAccount.subTypeId === ClientAccountSubType.Pension.id)
    );
  }, [client.clientAccount]);

  const reviewValues: ReviewValues = {
    accountTypeValues: accountTypeValues,
    contactDetails: [
      {
        title: Title.getById(client.contacts.items[0].details.titleId || 0)?.displayName ?? '',
        firstName: client.contacts.items[0].details.firstName,
        middleName: client.contacts.items[0].details.middleName,
        lastName: client.contacts.items[0].details.lastName,
        dateOfBirth: client.contacts.items[0].details.dateOfBirth,
        occupation: client.contacts.items[0].details.occupation,
        position: client.contacts.items[0].details.position,
        tfn: client.contacts.items[0].details.tfn,
        tfnExemptionId: client.contacts.items[0].details.tfnExemptionId,
        mobileNumber: client.contacts.items[0].phoneNumber,
        emailAddress: client.contacts.items[0].emailAddress,
        isAustralianResident: client.contacts.items[0].details.isAustralianResident || false,
        isForeignResident: client.contacts.items[0].details.isForeignResident || false,

        clientRoleIds: client.contacts.items[0].details.clientRoles?.map((r) => r.roleId) ?? [],
        clientBeneficialOwnerPercentage: client.contacts.items[0].details.clientRoles?.find((r) => r.roleId === BeneficialOwnerRoleId)?.percent ?? null,
        clientBeneficiaryPercentage: client.contacts.items[0].details.clientRoles?.find((r) => r.roleId === BeneficiaryRoleId)?.percent ?? null,
        clientDirectorCompanyOfficerPercentage:
          client.contacts.items[0].details.clientRoles?.find((r) => r.roleId === DirectorCompanyOfficerRoleId)?.percent ?? null,

        trusteeRoleIds: client.contacts.items[0].details.trusteeRoles?.map((r) => r.roleId) ?? [],
        trusteeBeneficialOwnerPercentage: client.contacts.items[0].details.trusteeRoles?.find((r) => r.roleId === BeneficialOwnerRoleId)?.percent ?? null,
        trusteeBeneficiaryPercentage: client.contacts.items[0].details.trusteeRoles?.find((r) => r.roleId === BeneficiaryRoleId)?.percent ?? null,
        trusteeDirectorCompanyOfficerPercentage:
          client.contacts.items[0].details.trusteeRoles?.find((r) => r.roleId === DirectorCompanyOfficerRoleId)?.percent ?? null,
      },
    ],
    adviser: client.advisor?.advisorName || '',
    entityName: client.clientAccount.name,
    tfn: client.clientAccount.tfn,
    tfnExemptionId: client.clientAccount.tfnExemptionId || 0,
    abn: client.clientAccount.abn,
    accounts: {
      existingAccounts: client.accounts.existingAccounts,
      newAccounts: client.accounts.newAccounts,
      accountInfo: client.accounts.accountInfo,
    },
    superSimplifierDetails: client.superSimplifierDetails,
    adviceFees: isAccountTypeSuperOrPension ? adviceFeesValues : undefined,
  };

  if (client.clientAccount.accountTypeId === ClientAccountType.Joint.id && client.contacts.items.length > 1) {
    reviewValues.contactDetails.push({
      title: Title.getById(client.contacts.items[1].details.titleId || 0)?.displayName ?? '',
      firstName: client.contacts.items[1].details.firstName || '',
      middleName: client.contacts.items[1].details.middleName || '',
      lastName: client.contacts.items[1].details.lastName || '',
      dateOfBirth: client.contacts.items[1].details.dateOfBirth,
      occupation: client.contacts.items[1].details.occupation || '',
      position: client.contacts.items[1].details.position || '',
      tfn: client.contacts.items[1].details.tfn,
      tfnExemptionId: client.contacts.items[1].details.tfnExemptionId,
      mobileNumber: client.contacts.items[1].phoneNumber,
      emailAddress: client.contacts.items[1].emailAddress,
      isAustralianResident: client.contacts.items[1].details.isAustralianResident || false,
      isForeignResident: client.contacts.items[1].details.isForeignResident || false,

      clientRoleIds: client.contacts.items[1].details.clientRoles?.map((r) => r.roleId),
      clientBeneficialOwnerPercentage: client.contacts.items[1].details.clientRoles?.find((r) => r.roleId === BeneficialOwnerRoleId)?.percent ?? null,
      clientBeneficiaryPercentage: client.contacts.items[1].details.clientRoles?.find((r) => r.roleId === BeneficiaryRoleId)?.percent ?? null,
      clientDirectorCompanyOfficerPercentage:
        client.contacts.items[1].details.clientRoles?.find((r) => r.roleId === DirectorCompanyOfficerRoleId)?.percent ?? null,

      trusteeRoleIds: client.contacts.items[1].details.trusteeRoles?.map((r) => r.roleId),
      trusteeBeneficialOwnerPercentage: client.contacts.items[1].details.trusteeRoles?.find((r) => r.roleId === BeneficialOwnerRoleId)?.percent ?? null,
      trusteeBeneficiaryPercentage: client.contacts.items[1].details.trusteeRoles?.find((r) => r.roleId === BeneficiaryRoleId)?.percent ?? null,
      trusteeDirectorCompanyOfficerPercentage:
        client.contacts.items[1].details.trusteeRoles?.find((r) => r.roleId === DirectorCompanyOfficerRoleId)?.percent ?? null,
    });
  }

  // contacts
  const contactSummaryData: ContactInfoSummary[] = contactItems.map((contactItem: Contact) => {
    return {
      name: `${contactItem.details.firstName} ${contactItem.details.lastName}`,
      roles: contactItem.details.clientRoles,
      documentTypeIds: contactItem.identifications.results.items.results?.map((item) => item.typeId ?? 0) ?? [],
    };
  });

  const [isProcessClientDialogOpen, setProcessClientDialogOpen] = useState(false);

  const onCloseProcessClientDialog = useCallback(() => {
    setProcessClientDialogOpen(false);
  }, []);

  const onBackButtonClick = useCallback(() => {
    const { prevRoutePath } = prevNextRoutePaths;
    if (prevRoutePath) {
      history.push(prevRoutePath + (!!client.id ? `?id=${client.id}` : ''));
    }
  }, [history, prevNextRoutePaths, client.id]);

  const onNextButtonClick = useCallback(() => {
    setProcessClientDialogOpen(true);
  }, []);

  return (
    <Box width="100%">
      <Box paddingBottom={'10px'} marginBottom={'30px'}>
        <Typography variant="h7">Please review that the information is correct and update if required</Typography>
      </Box>
      <Box marginBottom={'40px'}>
        <AccountTypeHeader accountTypeValues={reviewValues.accountTypeValues}></AccountTypeHeader>
        <AdditionalInfo reviewValues={reviewValues} />
        <ContactDetails contactIndex={0} contactDetailsValues={reviewValues.contactDetails[0]} roles={roles} />
        {client.clientAccount.accountTypeId === ClientAccountType.Joint.id && (
          <ContactDetails contactIndex={1} contactDetailsValues={reviewValues.contactDetails[1]} roles={roles} />
        )}
        <Accounts clientAccount={client.clientAccount} accountsValues={reviewValues.accounts} externalInstitutions={externalInstitutions} />
      </Box>
      {isAccountTypeSuperOrPension && (
        <>
          {!!reviewValues.superSimplifierDetails && <SuperSimplifier superSimplifierDetails={reviewValues.superSimplifierDetails} />}
          <AdviceFees superSimplifierDetails={reviewValues.superSimplifierDetails} adviceFeesValues={reviewValues.adviceFees} />
        </>
      )}
      <Box marginBottom={'40px'}>
        <Typography variant="h4" gutterBottom>
          Contacts
        </Typography>
        <ContactSummaryTable data={contactSummaryData} />
      </Box>
      {!isAccountTypeSuperOrPension && (
        <Box marginBottom={'40px'}>
          <Typography variant="h4" gutterBottom>
            Addresses
          </Typography>
          <AddressTable addresses={addressesValues} />
        </Box>
      )}
      <ProcessClientDialog
        isOpen={isProcessClientDialogOpen}
        saveProgress={savingProgress}
        handleClose={onCloseProcessClientDialog}
        handleProceedButtonClick={async () => {
          if (!!client.id) {
            processClient(client.id);
          }
        }}
      />
      <PageBackNextButtons<unknown>
        progress={savingProgress}
        onBackButtonClick={onBackButtonClick}
        onNextButtonClick={onNextButtonClick}
        nextButtonText={!!pathname && pathname === '/client/onboard/review' ? 'Process Client' : 'Next'}
        onQuitButtonClick={async () => {
          const response = await saveOnboarding({ ...client, stepKey: 'review' });
          if (!!response && !!response.payload) {
            history.push('/client/list?mode=onboard');
          }
        }}
      />
    </Box>
  );
};
