import { Typography } from '@mui/material';
import React from 'react';
import { WO2Modal } from './../../../../../../common';

export interface Props {
  clientEmail: string | undefined;
  shouldOpenForm: boolean;
  shouldHideAcknowledgement?: boolean | undefined;
  hasAcknowledged: boolean;
  onClose: () => void;
  setAcknowledgement: (acknowledge: boolean) => void;
}

export const Acknowledgement = ({ hasAcknowledged, onClose, setAcknowledgement, shouldOpenForm, shouldHideAcknowledgement, clientEmail }: Props): JSX.Element =>
  shouldOpenForm && !hasAcknowledged && !shouldHideAcknowledgement ? (
    <WO2Modal
      MuiDialogProps={{
        open: shouldOpenForm && !hasAcknowledged && !shouldHideAcknowledgement,
        onClose: () => {
          onClose();
          setAcknowledgement(false);
        },
        maxWidth: 'sm',
      }}
      title={<>Acknowledgement</>}
      actions={[
        {
          label: 'Cancel',
          onClickHandler: () => {
            onClose();
            setAcknowledgement(false);
          },
          type: 'outlined',
          disabled: false,
        },
        {
          label: 'Acknowledge and Proceed',
          onClickHandler: () => {
            setAcknowledgement(true);
          },
          type: 'contained',
          disabled: !clientEmail,
        },
      ]}
    >
      <>
        <Typography variant="body1" color="textPrimary" align="center" style={{ marginBottom: '12px', marginTop: '12px' }}>
          <strong>Receipient</strong>
        </Typography>
        <Typography variant="body2" color="textSecondary" align="center" style={{ marginBottom: '24px ' }}>
          {clientEmail}
        </Typography>
        <Typography variant="body1" color="textPrimary" align="center" style={{ marginBottom: '12px ' }}>
          <strong>Acknowledgement</strong>
        </Typography>
        <Typography variant="body2" color="textSecondary" align="center" style={{ marginBottom: '24px ' }}>
          I hereby declare that I am not a US Person; that the applicant is not a US Person, and I have completed an appropriate customer identification
          procedure (CID) on this investor which meets the AML/CTF Act. I have provided the latest Super Simplifier PDS and Member Guide to the applicant who
          has read, accepted and understood the terms.{' '}
        </Typography>
        {!clientEmail && (
          <Typography variant="body2" color="textSecondary">
            * Primary contact email address is required.
          </Typography>
        )}
      </>
    </WO2Modal>
  ) : (
    <></>
  );
