import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Configuration, StrategicAssetAllocation, TargetsState } from './';
import { fetchConfigurations, fetchStrategicAllocations } from './thunks';

export const initialState: TargetsState = {
  configurations: [],
  strategicAllocations: [],
};

export const targetsSlice = createSlice({
  name: '@@investmentServices/targets',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchConfigurations.fulfilled, (state: TargetsState, action: PayloadAction<Configuration[]>) => {
      state.configurations = action.payload;
    });

    builder.addCase(fetchStrategicAllocations.fulfilled, (state: TargetsState, action: PayloadAction<StrategicAssetAllocation[]>) => {
      state.strategicAllocations = action.payload;
    });
  },
});
