import { FilterOperator } from '../common';

export enum FilterType {
  none,
  numeric,
  string,
  date,
}

export interface FilterValue {
  operator: FilterOperator;
  value: string;
}
