import { Box, Container } from '@mui/material';
import 'date-fns';
import { default as React, useCallback, useEffect, useState } from 'react';
import { EnumSelect } from '../../../../../common/components';
import DateRangePicker from '../../../../../common/components/DateRangePicker';
import { PagedRequest } from '../../../../../store';
import { theme } from '../../../../../themes';
import PortfolioSelect from '../../common/components/PortfolioSelect';
import { Props } from '../container';
import { CurrentTabType, Frequency, PerformanceParameters } from '../store/types';
import WO2Button from './../../../../../common/components/button/Button';
import { BenchmarkSelect } from './benchmarkSelect';
import { MonthlyPerformanceTable } from './monthlyPerformanceTable';
import { PairedButttons } from './pairedButtons';
import { PerformanceCards } from './performanceCards';
import { PerformanceChart } from './performanceChart';
import { PeriodicSummaryTable } from './periodicSummaryTable';
import { SecurityPerformanceTable } from './securityPerformanceTable';

export const PerformanceComponent = (props: Props): JSX.Element => {
  const {
    clientId,
    afslId,
    setPortfolioParameter,
    setBenchmarkParameter,
    setFrequencyParameter,
    setCurrentTab,
    setDateParameter,
    clearBenchmarkPerformanceResult,
    currentTab,
    clientPortfolios,
    parameters,
    benchmarks,
    monthlyPerformanceTableResults,
    fetchBenchmarks,
    fetchBenchmarkPerformance,
    fetchPeriodicSummary,
    fetchMonthlyPortfolioPerformance,
    fetchPortfolioSecurityPerformance,
    fetchPerformanceChart,
  } = props;
  const [searchedClientId, setSearchedClientId] = useState<number>(0);
  const [searchedFrequency, setSearchedFrequency] = useState<Frequency>(Frequency.Monthly);

  const fetchTabData = useCallback(
    (pageNumber: number, searchParameters: PerformanceParameters, tab: string = CurrentTabType.MonthlySummary) => {
      const pagedRequest: PagedRequest = {
        pageNumber: pageNumber,
        pageSize: 25,
        queryFields: [],
      };

      if (tab === CurrentTabType.MonthlySummary && !!clientId) {
        fetchMonthlyPortfolioPerformance({
          clientId,
          parameters: searchParameters,
          pagedRequest,
        });
      } else if (tab === CurrentTabType.PeriodicSummary && !!clientId) {
        fetchPeriodicSummary({ clientId, parameters: searchParameters });
      } else if (tab === CurrentTabType.Detail && !!clientId) {
        fetchPortfolioSecurityPerformance({
          clientId,
          parameters: searchParameters,
        });
      }
    },
    [clientId, fetchMonthlyPortfolioPerformance, fetchPeriodicSummary, fetchPortfolioSecurityPerformance]
  );

  const handleClickSearch = useCallback(
    (searchParameters: PerformanceParameters) => {
      setSearchedFrequency(searchParameters.frequency);
      fetchTabData(1, searchParameters, currentTab);
      if (searchParameters?.selectedBenchmark && !!clientId) {
        fetchBenchmarkPerformance({ clientId, parameters: searchParameters });
      } else {
        clearBenchmarkPerformanceResult();
      }
      if (!!clientId) {
        fetchPerformanceChart({ clientId, parameters: searchParameters });
      }
    },
    [clientId, currentTab, fetchTabData, fetchBenchmarkPerformance, fetchPerformanceChart, setSearchedFrequency]
  );

  const handleMonthlyPerformancePagination = (pagedRequest: PagedRequest) => {
    fetchTabData(pagedRequest.pageNumber, parameters, currentTab);
  };

  useEffect(() => {
    if (!!clientId && clientId !== searchedClientId) {
      setSearchedClientId(clientId);
      handleClickSearch(parameters);
    }
  }, [clientId, searchedClientId, parameters, handleClickSearch]);

  useEffect(() => {
    if (afslId) {
      fetchBenchmarks(afslId);
    }
  }, [afslId, fetchBenchmarks]);

  const handleClickOpenTab = (tabLabel: CurrentTabType) => {
    setCurrentTab(tabLabel);
    fetchTabData(1, parameters, tabLabel);
  };

  const monthlySummary = {
    label: CurrentTabType.MonthlySummary,
    backgroundSelected: theme.palette.primary.main,
  };
  const periodicSummary = {
    label: CurrentTabType.PeriodicSummary,
    backgroundSelected: theme.palette.primary.main,
  };
  const detail = {
    label: CurrentTabType.Detail,
    backgroundSelected: theme.palette.primary.main,
  };

  return (
    <>
      <Container style={{ paddingTop: '20px' }}>
        <Box display={'flex'} justifyContent={'space-between'}>
          <Box paddingRight={'10px'}>
            <PortfolioSelect items={clientPortfolios} selectedPortfolio={parameters?.selectedPortfolio} setPortfolio={setPortfolioParameter} />
          </Box>
          <Box display={'flex'} justifyContent={'space-between'}>
            <Box paddingRight={'10px'}>
              <EnumSelect
                label="Frequency"
                enumType={Frequency}
                value={parameters?.frequency}
                onChange={(value) => {
                  setFrequencyParameter(Frequency[value]);
                }}
              />
            </Box>
            <DateRangePicker dateRange={parameters?.dates} setDateRange={setDateParameter} />
            <Box paddingRight={'10px'}>
              <BenchmarkSelect items={benchmarks} selectedItem={parameters?.selectedBenchmark ?? null} setBenchmark={setBenchmarkParameter} />
            </Box>
            <Box>
              <WO2Button color={'primary'} disableElevation onClick={() => handleClickSearch(parameters)} variant={'contained'} value="Search">
                Search
              </WO2Button>
            </Box>
          </Box>
        </Box>
        <PerformanceCards {...props} />
      </Container>

      <PerformanceChart {...props} frequency={searchedFrequency} />
      <Container style={{ paddingTop: '20px', paddingBottom: '20px' }}>
        <Box paddingBottom={'20px'}>
          <PairedButttons
            buttonLeft={monthlySummary}
            buttonMiddle={periodicSummary}
            buttonRight={detail}
            labelInitial={currentTab}
            width={180}
            handleClick={handleClickOpenTab}
          />
        </Box>
        {currentTab === CurrentTabType.MonthlySummary && !!monthlyPerformanceTableResults && (
          <MonthlyPerformanceTable
            {...props}
            handlePagedRequest={handleMonthlyPerformancePagination}
            pagedRequest={{
              pageNumber: monthlyPerformanceTableResults.pageNumber ?? 1,
              pageSize: 25,
              queryFields: [],
            }}
            pagedResults={monthlyPerformanceTableResults}
          />
        )}
        {currentTab === CurrentTabType.PeriodicSummary && <PeriodicSummaryTable {...props} />}
        {currentTab === CurrentTabType.Detail && <SecurityPerformanceTable {...props} />}
      </Container>
    </>
  );
};
