import React from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import Callback from '../components/Callback';
import ForgotPassword from '../components/ForgotPassword';
import Header from '../components/Header';
import Login from '../components/Login';
import NotFound from '../components/NotFound';
import ProtectedRoute from '../ProtectedRoute';
import ScrollToTop from '../ScrollToTop';
import routes from './RoutesRoot';

function Routes() {
  return (
    <React.Fragment>
      {process.env.REACT_APP_NONPROD_ENV === 'true' && <Header />}
      <ScrollToTop />
      <Switch>
        <Route path="/login" exact component={Login} />
        <Route path="/forgotpassword" exact component={ForgotPassword} />
        <Route path="/404" exact component={NotFound} />
        {/* Callback must be before / route */}
        <Route path="/callback" exact component={Callback} />
        {routes.map((route) => {
          return route.protected ?? true ? (
            <ProtectedRoute path={route.path} exact={route.exact ?? false} component={route.component} key={route.path} />
          ) : (
            <Route path={route.path} exact={route.exact ?? false} component={route.component} key={route.path} />
          );
        })}
        <Redirect from="*" to="/404" />
      </Switch>
    </React.Fragment>
  );
}

export default connect()(Routes);
