import { createSelector } from '@reduxjs/toolkit';
import { AssetTypeEnum } from 'src/common/enums/assetTypeEnum';
import { InvestmentServiceWithVersionGrouped } from '.';
import { selectCommon } from '../selectors';

export const selectInvestmentServicesList = createSelector(selectCommon, (common) => common.investmentServices);

export const selectSecurities = createSelector(selectCommon, (common) => common.securities);

export const selectAssetClasses = createSelector(selectCommon, (common) => common.assetClasses);

export const selectInvestmentServicesWithVersions = createSelector(selectCommon, (common) => common.investmentServicesWithVersions);

// build a portfolio array with nested versions array
export const selectInvestmentServicesWithVersionsGrouped = createSelector(selectInvestmentServicesWithVersions, (investmentServicesWithVersions) => {
  const result: InvestmentServiceWithVersionGrouped[] = [];

  investmentServicesWithVersions.forEach((version) => {
    const existingIndex = result.findIndex((v) => v.investmentServiceId === version.investmentServiceId);

    if (existingIndex < 0) {
      result.push({
        investmentServiceId: version.investmentServiceId,
        name: version.name,
        currentVersionStatus: version.status,
        previousVersionStatus: null,
        serviceTypeId: version.serviceTypeId,
        versions: [
          {
            investmentServiceVersionId: version.investmentServiceVersionId,
            majorVersion: version.majorVersion,
            minorVersion: version.minorVersion,
            status: version.status,
          },
        ],
      });
    } else {
      result[existingIndex] = {
        ...result[existingIndex],
        previousVersionStatus: result[existingIndex].currentVersionStatus,
        currentVersionStatus: version.status,
        serviceTypeId: version.serviceTypeId,
        versions: [
          result[existingIndex].versions[0],
          {
            investmentServiceVersionId: version.investmentServiceVersionId,
            majorVersion: version.majorVersion,
            minorVersion: version.minorVersion,
            status: version.status,
          },
        ],
      };
    }
  });

  return result;
});

export const selectInvestmentService = createSelector(
  selectCommon,
  selectInvestmentServicesWithVersionsGrouped,
  (common, investmentServices): InvestmentServiceWithVersionGrouped | undefined => {
    return investmentServices.find((i) => i.investmentServiceId === common.investmentServiceId);
  }
);

export const selectCurrentInvestmentServiceId = createSelector(selectCommon, (common) => common.investmentServiceId);

export const selectCurrentInvestmentVersionId = createSelector(
  selectCurrentInvestmentServiceId,
  selectInvestmentServicesWithVersionsGrouped,
  (currentInvestmentServiceId, investmentServices) => {
    let investmentService = investmentServices.find((i) => i.investmentServiceId === currentInvestmentServiceId);

    if (!investmentService && investmentServices.length > 0) {
      investmentService = investmentServices[0];
    }

    return investmentService?.versions[investmentService.versions.length - 1].investmentServiceVersionId;
  }
);

export const selectApprovedProducts = createSelector(selectCommon, (common) => common.approvedProducts);

export const selectApprovedSecurities = createSelector(selectApprovedProducts, (approvedProducts) =>
  approvedProducts.filter((p) => p.typeId === AssetTypeEnum.Security.id)
);
