import { Box, Grid, Paper, Typography } from '@mui/material';
import { ToggleButton as MuiToggleButton, ToggleButtonGroup as MuiToggleButtonGroup } from '@mui/material/';
import { makeStyles } from '@mui/styles';
import { Action, AnyAction } from '@reduxjs/toolkit';
import { Field, FieldProps, Form, Formik, FormikProps } from 'formik';
import { DateTime } from 'luxon';
import React, { useCallback, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { FormikErrorMessage, FormikSelect, yupValidatePhoneNumberFormat, yupValidateTfn } from 'src/common/components/formik';
import { ToggleButtonItem } from 'src/common/components/ToggleButton';
import { LoadingProgress } from 'src/common/store/types';
import { AddressTable } from 'src/features/clients/client/common/components/contactDetails/addressTable';
import { RoleSelector } from 'src/features/clients/client/common/components/contacts/edit/roleSelector';
import {
  DeleteContactDocumentPayload,
  DocumentDetails,
  DocumentsParameters,
  DocumentType,
  DownloadDocumentPayload,
  EditDocuments,
  FetchContactDocumentPayload,
  FetchContactDocumentsPayload,
  RolePercentageModel,
  SaveContactDocumentPayload,
} from 'src/features/clients/client/common/store/types';
import { Occupation, Position, TfnExemptions } from 'src/features/clients/common/enums';
import * as yup from 'yup';
import { TestContext } from 'yup';
import { FormikEnumerationSelect } from '../../../../../../common/components/formik/formikEnumerationSelect';
import { FormikKeyboardDatePicker } from '../../../../../../common/components/formik/formikKeyboardDatePicker';
import { FormikNumberFormat } from '../../../../../../common/components/formik/formikNumberFormat';
import { FormikSwitch } from '../../../../../../common/components/formik/formikSwitch';
import { FormikTextField } from '../../../../../../common/components/formik/formikTextField';
import { ClientAccountType, Gender, moment, Title } from '../../../../../../common/types';
import { AddressType, EmailType, PhoneNumberType } from '../../../../client/common/store/enums';
import { AddressDetails, BeneficialOwnerRoleId, BeneficiaryRoleId, DirectorCompanyOfficerRoleId, Role } from '../../../../client/common/store/types';
import { ContactIdentificationTable } from '../../../components/common/contactIdentificationTable';
import { PageBackNextButtons } from '../../../components/common/pageBackNextButtons';
import { OnboardingAccountInstitutionType } from '../../../store/enums';
import {
  Accounts,
  AccountTypeValues,
  Contact,
  FetchContactPayload,
  FetchContactsToAttachPayload,
  SaveContactIdentificationValues,
  SaveContactValues,
  Trustee,
} from '../../../store/types';

const useStyles = makeStyles(() => ({
  contactRoot: {
    width: '100%',
    marginRight: '20px',
    '& h1': {
      fontSize: '26px',
      fontWeight: '500',
      lineHeight: 1.3,
    },
    '& h2': {
      fontSize: '18px',
      fontWeight: '400',
      lineHeight: 1,
    },
    '& .contactDetailsForm': {
      marginTop: '30px',
      '& > fieldset': {
        display: 'flex',
        border: 'none',
        margin: '0',
        padding: '0',
      },
      '& .contactDetailsToggleButtonsContainer': {
        display: 'flex',
        flexDirection: 'column',
        '& .contactDetailField': {
          marginBottom: '10px',
        },
      },
    },
  },
}));

interface EditProps extends RouteComponentProps {
  prevNextRoutePaths: {
    prevRoutePath: string | undefined;
    nextRoutePath: string | undefined;
  };
  saveProgress: LoadingProgress;
  contact: Contact | undefined;
  contactsEditIndex: number | null | undefined;
  setContactEditIndex: (index: number | null | undefined) => void;
  saveAddress: (address: AddressDetails) => void;
  removeAddress: (payload: { index: number }) => void;
  addresses: AddressDetails[];
  editAddress: AddressDetails | null | undefined;
  availableAddressTypes: AddressType[];
  setContactAddressEdit: (payload: { index: number | null | undefined }) => void;
  accountTypeValues: AccountTypeValues;
  loadingContactsToAttach: LoadingProgress;
  roles: Role[];
  loadingRolesProgress: LoadingProgress;
  documentTypes: DocumentType[];
  identifications: EditDocuments | undefined;
  parameters: DocumentsParameters | undefined;
  editIdentification: DocumentDetails | null | undefined;
  loadingProgress: LoadingProgress;
  saveIdentificationProgress: LoadingProgress;
  clientId: string | null;
  accounts: Accounts;
  setIdentificationAddMode: () => Action;
  cancelIdentificationAddEditMode: () => Action;
  setIdentificationEditId: (documentId: number) => Action;
  removeIdentification: (documentId: number) => Action;
  fetchDocumentTypes: () => void;
  fetchContactIdentifications: (document: FetchContactDocumentsPayload) => Promise<AnyAction>;
  fetchContactIdentificationForEdit: (document: FetchContactDocumentPayload) => Promise<AnyAction>;
  downloadDocument: (payload: DownloadDocumentPayload) => Promise<AnyAction>;
  deleteDocument: (payload: DeleteContactDocumentPayload) => Promise<AnyAction>;
  saveDocument: (payload: SaveContactDocumentPayload) => Promise<AnyAction>;
  fetchContactsToAttach: (payload: FetchContactsToAttachPayload) => void;
  fetchExistingContact: (payload: FetchContactPayload) => void;
  saveContactInfo: (values: SaveContactValues) => void;
  saveContactIdentificationValues: (payload: SaveContactIdentificationValues) => Promise<AnyAction>;
  trustee?: Trustee;
}

export enum ContactType {
  client = 'client',
  trustee = 'trustee',
}

interface FormValues {
  contactId: number | null;
  details: {
    contactTypes: ContactType[];
    titleId: number | null;
    genderId: number | null;
    firstName: string;
    middleName: string;
    lastName: string;
    dateOfBirth: moment | null;
    tfn: string | null;
    tfnExemptionId: number | null;
    occupation: string;
    nationality: string | null;
    positionId: number | null;
    isAustralianResident: boolean;
    isForeignResident: boolean;

    clientRoleIds: number[];
    clientBeneficialOwnerPercentage: number | null;
    clientBeneficiaryPercentage: number | null;
    clientBeneficiaryRelationship: string;
    clientDirectorCompanyOfficerPercentage: number | null;

    trusteeRoleIds: number[];
    trusteeBeneficialOwnerPercentage: number | null;
    trusteeBeneficiaryPercentage: number | null;
    trusteeBeneficiaryRelationship: string;
    trusteeDirectorCompanyOfficerPercentage: number | null;

    isPrimary: boolean;
  };
  phoneNumber: {
    id: number | null;
    typeId: number;
    number: string;
  };
  emailAddress: {
    id: number | null;
    typeId: number;
    email: string;
  };
}

export const Edit = (props: EditProps): JSX.Element => {
  const {
    history,
    saveProgress,
    prevNextRoutePaths,
    contact,
    contactsEditIndex,
    setContactEditIndex,
    saveAddress,
    removeAddress,
    addresses,
    editAddress,
    availableAddressTypes,
    setContactAddressEdit,
    roles,
    loadingRolesProgress,
    identifications,
    clientId,
    accounts,
    accountTypeValues,
    trustee,
    saveContactInfo,
    loadingProgress,
    saveIdentificationProgress,
    documentTypes,
    parameters,
    editIdentification,
    setIdentificationAddMode,
    cancelIdentificationAddEditMode,
    setIdentificationEditId,
    removeIdentification,
    fetchDocumentTypes,
    fetchContactIdentifications,
    fetchContactIdentificationForEdit,
    downloadDocument,
    deleteDocument,
    saveDocument,
    saveContactIdentificationValues,
  } = props;

  const classes = useStyles();

  const blankContact: FormValues = {
    contactId: null,
    details: {
      contactTypes: [ContactType.client],
      titleId: null,
      genderId: Gender.Unspecified.id,
      firstName: '',
      middleName: '',
      lastName: '',
      dateOfBirth: null,
      tfn: '',
      tfnExemptionId: null,
      occupation: '',
      nationality: null,
      positionId: null,
      isAustralianResident: true,
      isForeignResident: false,
      isPrimary: false,

      clientRoleIds: [],
      clientBeneficialOwnerPercentage: null,
      clientBeneficiaryPercentage: null,
      clientBeneficiaryRelationship: '',
      clientDirectorCompanyOfficerPercentage: null,

      trusteeRoleIds: [],
      trusteeBeneficialOwnerPercentage: null,
      trusteeBeneficiaryPercentage: null,
      trusteeBeneficiaryRelationship: '',
      trusteeDirectorCompanyOfficerPercentage: null,
    },
    phoneNumber: { id: null, typeId: PhoneNumberType.Mobile.id, number: '' },
    emailAddress: { id: null, typeId: EmailType.Work.id, email: '' },
  };

  const [formValues, setFormValues] = useState<FormValues>(blankContact);

  const [isPrimaryOrAdditionalJointContact, setIsPrimaryOrAdditionalJointContact] = useState<boolean | undefined>(undefined);

  useEffect(() => {
    setIsPrimaryOrAdditionalJointContact(prevNextRoutePaths.prevRoutePath !== '/client/onboard/contacts');
  }, [prevNextRoutePaths, setIsPrimaryOrAdditionalJointContact]);

  const typeToggleButtons: ToggleButtonItem<ContactType>[] = [
    { name: 'CLIENT', value: ContactType.client },
    { name: 'TRUSTEE', value: ContactType.trustee },
  ];

  useEffect(() => {
    if (!!contact) {
      const contactTypes: ContactType[] = [];
      if ((contact.details.clientRoles || []).length > 0) {
        contactTypes.push(ContactType.client);
      }
      if ((contact.details.trusteeRoles || []).length > 0) {
        contactTypes.push(ContactType.trustee);
      }

      const clientRoles = contact.details.clientRoles || [];
      const clientBeneficialOwnerPercentage = clientRoles.find((r) => r.roleId === BeneficialOwnerRoleId)?.percent || null;
      const clientBeneficiaryPercentage = clientRoles.find((r) => r.roleId === BeneficiaryRoleId)?.percent || null;
      const clientBeneficiaryRelationship = clientRoles.find((r) => r.roleId === BeneficiaryRoleId)?.relationship || '';
      const clientDirectorCompanyOfficerPercentage = clientRoles.find((r) => r.roleId === DirectorCompanyOfficerRoleId)?.percent || null;

      const trusteeRoles = contact.details.trusteeRoles || [];
      const trusteeBeneficialOwnerPercentage = trusteeRoles.find((r) => r.roleId === BeneficialOwnerRoleId)?.percent || null;
      const trusteeBeneficiaryPercentage = trusteeRoles.find((r) => r.roleId === BeneficiaryRoleId)?.percent || null;
      const trusteeBeneficiaryRelationship = trusteeRoles.find((r) => r.roleId === BeneficiaryRoleId)?.relationship || '';
      const trusteeDirectorCompanyOfficerPercentage = trusteeRoles.find((r) => r.roleId === DirectorCompanyOfficerRoleId)?.percent || null;

      setFormValues({
        contactId: contact.contactId || null,
        details: {
          contactTypes,
          titleId: contact.details.titleId,
          genderId: contact.details.genderId,
          firstName: contact.details.firstName,
          middleName: contact.details.middleName,
          lastName: contact.details.lastName,
          dateOfBirth: contact.details.dateOfBirth,
          tfn: contact.details.tfn,
          tfnExemptionId: contact.details.tfnExemptionId,
          occupation: contact.details.occupation,
          positionId: Position.getByName(contact.details.position)?.id ?? null,
          nationality: contact.details.nationality,
          isAustralianResident: contact.details.isAustralianResident,
          isForeignResident: contact.details.isForeignResident,
          isPrimary: contact.details.isPrimary,

          clientRoleIds: (contact.details.clientRoles || []).map((r) => r.roleId),
          clientBeneficialOwnerPercentage,
          clientBeneficiaryPercentage,
          clientBeneficiaryRelationship,
          clientDirectorCompanyOfficerPercentage,

          trusteeRoleIds: (contact.details.trusteeRoles || []).map((r) => r.roleId),
          trusteeBeneficialOwnerPercentage,
          trusteeBeneficiaryPercentage,
          trusteeBeneficiaryRelationship,
          trusteeDirectorCompanyOfficerPercentage,
        },
        phoneNumber: !!contact.phoneNumber?.typeId
          ? { id: contact.phoneNumber.id, typeId: contact.phoneNumber.typeId, number: contact.phoneNumber.number }
          : { id: null, typeId: PhoneNumberType.Mobile.id, number: '' },
        emailAddress: !!contact.emailAddress?.typeId
          ? { id: contact.emailAddress.id, typeId: contact.emailAddress.typeId, email: contact.emailAddress.email }
          : { id: null, typeId: EmailType.Work.id, email: '' },
      });
    }
  }, [contact, contactsEditIndex, setFormValues]);

  const onSave = useCallback(
    async (saveFormValues: FormValues) => {
      const clientRoles: RolePercentageModel[] = saveFormValues.details.contactTypes.includes(ContactType.client)
        ? saveFormValues.details.clientRoleIds.map((id) => {
            let percent: number | null;
            let relationship: string | null = null;

            switch (id) {
              case BeneficialOwnerRoleId:
                percent = saveFormValues.details.clientBeneficialOwnerPercentage;
                relationship = '';
                break;
              case BeneficiaryRoleId:
                percent = saveFormValues.details.clientBeneficiaryPercentage;
                relationship = saveFormValues.details.clientBeneficiaryRelationship;
                break;
              case DirectorCompanyOfficerRoleId:
                percent = saveFormValues.details.clientDirectorCompanyOfficerPercentage;
                relationship = '';
                break;
              default:
                percent = null;
            }
            return {
              roleId: id,
              percent,
              relationship,
              name: roles.find((r) => r.id === id)?.name || '',
            };
          })
        : [];

      const trusteeRoles: RolePercentageModel[] = saveFormValues.details.contactTypes.includes(ContactType.trustee)
        ? saveFormValues.details.trusteeRoleIds.map((id) => {
            let percent: number | null;
            let relationship: string | null = null;

            switch (id) {
              case BeneficialOwnerRoleId:
                percent = saveFormValues.details.trusteeBeneficialOwnerPercentage;
                relationship = '';
                break;
              case BeneficiaryRoleId:
                percent = saveFormValues.details.trusteeBeneficiaryPercentage;
                relationship = saveFormValues.details.trusteeBeneficiaryRelationship;
                break;
              case DirectorCompanyOfficerRoleId:
                percent = saveFormValues.details.trusteeDirectorCompanyOfficerPercentage;
                relationship = '';
                break;
              default:
                percent = null;
            }
            return {
              roleId: id,
              percent,
              relationship,
              name: roles.find((r) => r.id === id)?.name || '',
            };
          })
        : [];

      const contactToSave: SaveContactValues = {
        index: contactsEditIndex === undefined ? null : contactsEditIndex,
        details: {
          titleId: saveFormValues.details.titleId,
          genderId: saveFormValues.details.genderId,
          firstName: saveFormValues.details.firstName,
          middleName: saveFormValues.details.middleName,
          lastName: saveFormValues.details.lastName,
          dateOfBirth: saveFormValues.details.dateOfBirth,
          tfn: saveFormValues.details.tfn || '',
          tfnExemptionId: saveFormValues.details.tfnExemptionId,
          occupation: saveFormValues.details.occupation,
          position: saveFormValues.details.occupation === null ? null : Position.getById(saveFormValues.details.positionId)?.displayName ?? null,
          nationality: saveFormValues.details.nationality,
          isAustralianResident: saveFormValues.details.isAustralianResident,
          isForeignResident: saveFormValues.details.isForeignResident,
          isPrimary: saveFormValues.details.isPrimary,
          clientRoles,
          trusteeRoles,
        },
        phoneNumber: saveFormValues.phoneNumber,
        emailAddress: saveFormValues.emailAddress,
      };

      saveContactInfo(contactToSave);
    },
    [saveContactInfo, contactsEditIndex, roles, identifications?.results.items.results]
  );

  return (
    <div className={classes.contactRoot}>
      <Box>
        {contactsEditIndex === 0 && <Typography variant="h7">{'We need a few more details about the primary contact'}</Typography>}
        {contactsEditIndex !== 0 && <Typography variant="h7">{'We need a few more details about this contact'}</Typography>}
      </Box>
      <Box>
        <Formik<FormValues>
          enableReinitialize={true}
          initialValues={formValues}
          onSubmit={(details) => {
            onSave(details);
          }}
          validationSchema={yup.object({
            details: yup.object({
              contactTypes: yup.array().of(yup.mixed()).required('At least one type is required'),
              titleId: yup.number().nullable().min(1, 'Title is required').required('Title is required'),
              genderId: yup.number().nullable().required('Gender is required'),
              firstName: yup.string().required('First name is required'),
              middleName: yup.string().nullable(),
              lastName: yup.string().required('Last name is required'),
              dateOfBirth: yup
                .date()
                .required('Date of birth is required')
                .nullable()
                .typeError('Date of birth must be a valid date')
                .max(DateTime.now().toISODate(), 'Date of birth cannot be in the future'),
              tfn: (isPrimaryOrAdditionalJointContact ? yup.string().nullable() : yup.string()).test(
                'test-tfn-valid',
                'Not a valid Tax File Number',
                function (this: TestContext, tfn: string | null | undefined): boolean {
                  if (!this.parent.tfnExemptionId) {
                    return !tfn || yupValidateTfn(tfn);
                  }
                  return (!!tfn && TfnExemptions.isValidExemptionCode(tfn)) || yupValidateTfn(tfn);
                }
              ),
              tfnExemptionId: yup.number().nullable(),
              clientRoleIds: yup
                .array()
                .of(yup.number())
                .when('contactTypes', {
                  is: (types: ContactType[]) => types.includes(ContactType.client),
                  then: yup.array().of(yup.number()).required('At least one role is required'),
                }),
              clientBeneficialOwnerPercentage: yup
                .number()
                .nullable()
                .when('clientRoleIds', {
                  is: (roleIds: number[]) => roleIds.find((r) => r === BeneficialOwnerRoleId),
                  then: yup.number().max(100, 'Percentage cannot exceed 100%').required('Percentage is required'),
                }),
              clientBeneficiaryPercentage: yup
                .number()
                .nullable()
                .when('clientRoleIds', {
                  is: (roleIds: number[]) => roleIds.find((r) => r === BeneficiaryRoleId),
                  then: yup.number().max(100, 'Percentage cannot exceed 100%').required('Percentage is required'),
                }),
              clientDirectorCompanyOfficerPercentage: yup
                .number()
                .nullable()
                .when('clientRoleIds', {
                  is: (roleIds: number[]) => roleIds.find((r) => r === DirectorCompanyOfficerRoleId),
                  then: yup.number().max(100, 'Percentage cannot exceed 100%').required('Percentage is required'),
                }),
              trusteeRoleIds: yup
                .array()
                .of(yup.number())
                .when('contactTypes', {
                  is: (types: ContactType[]) => types.includes(ContactType.trustee),
                  then: yup.array().of(yup.number()).required('At least one role is required'),
                }),
              trusteeBeneficialOwnerPercentage: yup
                .number()
                .nullable()
                .when('trusteeRoleIds', {
                  is: (roleIds: number[]) => roleIds.find((r) => r === BeneficialOwnerRoleId),
                  then: yup.number().max(100, 'Percentage cannot exceed 100%').required('Percentage is required'),
                }),
              trusteeBeneficiaryPercentage: yup
                .number()
                .nullable()
                .when('trusteeRoleIds', {
                  is: (roleIds: number[]) => roleIds.find((r) => r === BeneficiaryRoleId),
                  then: yup.number().max(100, 'Percentage cannot exceed 100%').required('Percentage is required'),
                }),
              trusteeDirectorCompanyOfficerPercentage: yup
                .number()
                .nullable()
                .when('trusteeRoleIds', {
                  is: (roleIds: number[]) => roleIds.find((r) => r === DirectorCompanyOfficerRoleId),
                  then: yup.number().max(100, 'Percentage cannot exceed 100%').required('Percentage is required'),
                }),
            }),
            phoneNumber: yup.object({
              number: isPrimaryOrAdditionalJointContact
                ? yup
                    .string()
                    .nullable()
                    .required('Mobile number is required')
                    .min(10, 'Must be 10 digits including area code')
                    .max(10, 'Must be 10 digits including area code')
                    .test('test-phoneNumber-format', 'Incorrect format', yupValidatePhoneNumberFormat)
                : yup
                    .string()
                    .nullable()
                    .min(10, 'Must be 10 digits including area code')
                    .max(10, 'Must be 10 digits including area code')
                    .test('test-phoneNumber-format', 'Incorrect format', yupValidatePhoneNumberFormat),
            }),
            emailAddress: yup.object({
              typeId: isPrimaryOrAdditionalJointContact ? yup.number().nullable().required('Email type is required') : yup.number().nullable(),
              email: isPrimaryOrAdditionalJointContact
                ? yup.string().nullable().required('Email address is required').email('Please enter an email address in the format of clientname@example.com')
                : yup.string().nullable().email('Please enter an email address in the format of clientname@example.com'),
            }),
          })}
        >
          {(formikProps: FormikProps<FormValues>) => (
            <Form className={'contactDetailsForm'}>
              {!!trustee && (
                <Paper elevation={5} style={{ padding: '15px', marginBottom: '15px' }}>
                  <Typography color="textSecondary">CONTACT TYPE</Typography>
                  <Field name="details.contactTypes" fullWidth>
                    {(fieldProps: FieldProps) => {
                      return (
                        <>
                          <MuiToggleButtonGroup
                            value={fieldProps.field.value}
                            onChange={(_event, value) => {
                              fieldProps.form.setFieldValue('details.contactTypes', value, true);
                            }}
                          >
                            {typeToggleButtons.map(
                              (button): JSX.Element => (
                                <MuiToggleButton
                                  key={button.value.toString()}
                                  value={button.value}
                                  style={{ width: '200px' }}
                                  disabled={
                                    button.value === ContactType.client &&
                                    formikProps.values.details.clientRoleIds.length > 0 &&
                                    (contactsEditIndex === 0 ||
                                      (accountTypeValues.clientAccount.accountTypeId === ClientAccountType.Joint.id && contactsEditIndex === 1))
                                  }
                                >
                                  {button.name}
                                </MuiToggleButton>
                              )
                            )}
                          </MuiToggleButtonGroup>
                        </>
                      );
                    }}
                  </Field>
                  <FormikErrorMessage name={'details.contactTypes'}></FormikErrorMessage>
                </Paper>
              )}
              {formikProps.values.details.contactTypes.includes(ContactType.client) && (
                <Paper elevation={5} style={{ marginBottom: '15px', padding: '10px' }}>
                  <RoleSelector
                    label="CLIENT ROLES"
                    roles={roles || []}
                    rolesLoadingProgress={loadingRolesProgress}
                    contactId={contact?.contactId}
                    roleIds={{
                      inputProps: formikProps.getFieldProps('details.clientRoleIds'),
                      helperProps: formikProps.getFieldHelpers('details.clientRoleIds'),
                    }}
                    beneficialOwnerPercentage={{ inputProps: formikProps.getFieldProps('details.clientBeneficialOwnerPercentage') }}
                    beneficiaryPercentage={{ inputProps: formikProps.getFieldProps('details.clientBeneficiaryPercentage') }}
                    directorCompanyOfficerPercentage={{ inputProps: formikProps.getFieldProps('details.clientDirectorCompanyOfficerPercentage') }}
                    isPrimary={{ inputProps: formikProps.getFieldProps('details.isPrimary') }}
                    beneficiaryRelationship={{ inputProps: formikProps.getFieldProps('details.clientBeneficiaryRelationship') }}
                    hideIsPrimarySwitch={true}
                    requiresAuthorisedSignatory={
                      contactsEditIndex === 0 || (accountTypeValues.clientAccount.accountTypeId === ClientAccountType.Joint.id && contactsEditIndex === 1)
                    }
                  ></RoleSelector>
                </Paper>
              )}
              {formikProps.values.details.contactTypes.includes(ContactType.trustee) && (
                <Paper elevation={5} style={{ marginBottom: '15px' }}>
                  <RoleSelector
                    label="TRUSTEE ROLES"
                    roles={roles || []}
                    rolesLoadingProgress={loadingRolesProgress}
                    contactId={contact?.contactId}
                    roleIds={{
                      inputProps: formikProps.getFieldProps('details.trusteeRoleIds'),
                      helperProps: formikProps.getFieldHelpers('details.trusteeRoleIds'),
                    }}
                    beneficialOwnerPercentage={{ inputProps: formikProps.getFieldProps('details.trusteeBeneficialOwnerPercentage') }}
                    beneficiaryPercentage={{ inputProps: formikProps.getFieldProps('details.trusteeBeneficiaryPercentage') }}
                    directorCompanyOfficerPercentage={{ inputProps: formikProps.getFieldProps('details.trusteeDirectorCompanyOfficerPercentage') }}
                    beneficiaryRelationship={{ inputProps: formikProps.getFieldProps('details.trusteeBeneficiaryRelationship') }}
                    isPrimary={{ inputProps: formikProps.getFieldProps('details.isPrimary') }}
                    hideIsPrimarySwitch={true}
                    requiresAuthorisedSignatory={false}
                  ></RoleSelector>
                </Paper>
              )}
              <Paper elevation={5} style={{ marginBottom: '15px' }}>
                <fieldset style={{ border: 'none' }}>
                  <Grid container>
                    <Grid item xs={6} style={{ paddingTop: '20px' }}>
                      <Grid item xs={12} style={{ paddingBottom: '20px' }}>
                        <Field
                          valueIsId={true}
                          component={FormikEnumerationSelect}
                          showNone={false}
                          showRequiredAsterisk={true}
                          type={Title}
                          name="details.titleId"
                          label="TITLE"
                        />
                      </Grid>
                      <Grid item xs={12} style={{ paddingBottom: '20px' }}>
                        <Field component={FormikTextField} name="details.firstName" label="FIRST NAME" showRequiredAsterisk={true} fullWidth />
                      </Grid>
                      <Grid item xs={12} style={{ paddingBottom: '20px' }}>
                        <Field component={FormikTextField} name="details.middleName" label="MIDDLE NAME" fullWidth />
                      </Grid>
                      <Grid item xs={12} style={{ paddingBottom: '20px' }}>
                        <Field component={FormikTextField} name="details.lastName" label="LAST NAME" showRequiredAsterisk={true} fullWidth />
                      </Grid>
                      <Grid item xs={12} style={{ paddingBottom: '20px' }}>
                        <Field
                          component={FormikEnumerationSelect}
                          type={Gender}
                          valueIsId={true}
                          showNone={false}
                          showRequiredAsterisk={true}
                          name="details.genderId"
                          label="GENDER"
                        />
                      </Grid>

                      <Grid item xs={12} style={{ paddingBottom: '20px' }}>
                        <Field
                          component={FormikKeyboardDatePicker}
                          variant="inline"
                          name="details.dateOfBirth"
                          label="DATE OF BIRTH"
                          showRequiredAsterisk={true}
                          maxDate={DateTime.now()}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} style={{ paddingBottom: '20px' }}>
                        <Field component={FormikTextField} name="details.nationality" label="NATIONALITY" fullWidth />
                      </Grid>
                    </Grid>
                    <Grid item xs={6} style={{ padding: '20px 0px 0px 10px' }}>
                      <Grid item xs={12} style={{ paddingBottom: '20px' }}>
                        <Field
                          component={FormikTextField}
                          name="emailAddress.email"
                          label="EMAIL ADDRESS"
                          showRequiredAsterisk={isPrimaryOrAdditionalJointContact}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} style={{ paddingBottom: '20px' }}>
                        <Field
                          component={FormikEnumerationSelect}
                          type={EmailType}
                          showNone={true}
                          showRequiredAsterisk={isPrimaryOrAdditionalJointContact}
                          name="emailAddress.typeId"
                          valueIsId={true}
                          label="EMAIL TYPE"
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} style={{ paddingBottom: '20px' }}>
                        <Field name="phoneNumber.number" label="MOBILE NUMBER" fullWidth>
                          {(fieldProps: FieldProps) => {
                            return (
                              <FormikNumberFormat
                                formikFieldProps={fieldProps}
                                numberFormatProps={{
                                  format: '## #### ####',
                                  mask: '_',
                                  placeholder: '',
                                  isNumericString: true,
                                  name: fieldProps.field.name,
                                  label: 'MOBILE NUMBER',
                                }}
                                showRequiredAsterisk={isPrimaryOrAdditionalJointContact}
                                fullWidth={true}
                              />
                            );
                          }}
                        </Field>
                      </Grid>
                      <Grid item xs={12} style={{ paddingBottom: '20px' }}>
                        <Field
                          component={FormikEnumerationSelect}
                          type={TfnExemptions}
                          showNone={true}
                          name="details.tfnExemptionId"
                          label="TFN EXEMPTION"
                          valueIsId={true}
                          onChange={(value: number | null) => {
                            if (value === null) {
                              if (formikProps.values.details.tfn !== null && TfnExemptions.isValidExemptionCode(formikProps.values.details.tfn)) {
                                formikProps.setFieldValue('details.tfn', null);
                              }
                            } else {
                              const tfnExemptionsCode = TfnExemptions.getTfnExemptionCodeById(value);
                              if (!!tfnExemptionsCode) {
                                formikProps.setFieldValue('details.tfn', tfnExemptionsCode.code);
                                formikProps.setFieldTouched('details.tfn', false, false);
                              }
                            }
                            formikProps.setFieldTouched('details.tfnExemptionId', false, false);
                          }}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} style={{ paddingBottom: '20px' }}>
                        <Field
                          component={FormikTextField}
                          disabled={formikProps.values.details.tfnExemptionId !== null}
                          name="details.tfn"
                          label="TAX FILE NUMBER"
                          mask="999 999 999"
                          fullWidth
                          type="password"
                          autocomplete="new-password"
                        />
                      </Grid>
                      <Grid item xs={12} style={{ paddingBottom: '20px' }}>
                        <Field
                          component={FormikEnumerationSelect}
                          type={Occupation}
                          name="details.occupation"
                          label="OCCUPATION"
                          showNone={true}
                          valueIsId={false}
                          onChange={() => {
                            formikProps.setFieldValue('details.positionId', null);
                          }}
                        />
                      </Grid>

                      <Grid item xs={12} style={{ paddingBottom: '20px' }}>
                        <Field
                          component={FormikSelect}
                          data={Position.getByOccupation(formikProps.values.details.occupation)}
                          itemDisplayNameField="displayName"
                          fieldName="details.positionId"
                          valueIsId={false}
                          label="POSITION"
                        />
                      </Grid>
                    </Grid>
                    <Grid container className={'contactDetailsToggleButtonsContainer'}>
                      <Grid item xs={12} style={{ margin: '0px 10px' }}>
                        <Field name="details.isAustralianResident" component={FormikSwitch} label="Australian Tax Resident"></Field>
                      </Grid>
                      <Grid item xs={12} style={{ margin: '0px 10px' }}>
                        <Field name="details.isForeignResident" component={FormikSwitch} label="Foreign Tax Resident"></Field>
                      </Grid>
                    </Grid>
                  </Grid>
                </fieldset>
              </Paper>
              {(contactsEditIndex === 0 || contactsEditIndex === 1) &&
                Array.isArray(accounts.newAccounts) &&
                Array.isArray(accounts.existingAccounts) &&
                (accounts.newAccounts
                  .map((account) => account.institutionId)
                  .some((id) =>
                    OnboardingAccountInstitutionType.getAccountInstitutionsNeedIdentification()
                      .map((institutionType: OnboardingAccountInstitutionType) => institutionType.id)
                      .includes(id)
                  ) ||
                  accounts.existingAccounts.map((account) => account.institutionId)) && (
                  <Box margin="30px 0">
                    {!!identifications && !!parameters && (
                      <ContactIdentificationTable
                        clientId={!!clientId ? parseInt(clientId, 10) : null}
                        contactId={contact?.contactId ?? null}
                        title={'Contact Identification'}
                        documentTypes={documentTypes}
                        editIdentification={editIdentification}
                        identifications={identifications}
                        parameters={parameters}
                        loadingProgress={loadingProgress}
                        saveIdentificationProgress={saveIdentificationProgress}
                        setIdentificationAddMode={setIdentificationAddMode}
                        cancelIdentificationAddEditMode={cancelIdentificationAddEditMode}
                        setIdentificationEditId={setIdentificationEditId}
                        removeIdentification={removeIdentification}
                        fetchDocumentTypes={fetchDocumentTypes}
                        fetchContactIdentifications={fetchContactIdentifications}
                        fetchContactIdentificationForEdit={fetchContactIdentificationForEdit}
                        downloadDocument={downloadDocument}
                        deleteDocument={deleteDocument}
                        saveDocument={saveDocument}
                        saveContactIdentificationValues={saveContactIdentificationValues}
                      />
                    )}
                  </Box>
                )}
              {contactsEditIndex !== null && (
                <AddressTable
                  addresses={addresses.map((a, index) => {
                    return { ...a, addressId: index };
                  })}
                  selectedAddress={editAddress}
                  loadingProgress={{ isLoading: false, hasErrors: false }}
                  savingProgress={{ isLoading: false, hasErrors: false }}
                  addressTypes={availableAddressTypes}
                  save={(address: AddressDetails) => {
                    saveAddress(address);
                  }}
                  delete={(addressId: number) => removeAddress({ index: addressId })}
                  cancel={() => setContactAddressEdit({ index: undefined })}
                  setAddEdit={(index: number | null) => setContactAddressEdit({ index })}
                ></AddressTable>
              )}
              {isPrimaryOrAdditionalJointContact && (
                // primary or additional-joint contact
                <PageBackNextButtons<FormValues>
                  formikProps={formikProps}
                  onQuitButtonClick={() => {
                    onSave(formikProps.values).then(() => {
                      setContactEditIndex(undefined);
                      history.push('/client/list?mode=onboard');
                    });
                  }}
                  onBackButtonClick={() => {
                    const { prevRoutePath } = prevNextRoutePaths;
                    if (prevRoutePath) {
                      onSave(formikProps.values).then(() => {
                        setContactEditIndex(undefined);
                        history.push(prevRoutePath + (!!clientId ? `?id=${clientId}` : ''));
                      });
                    }
                  }}
                  onNextButtonClick={() => {
                    formikProps.submitForm().then(() => {
                      formikProps.validateForm().then((errors) => {
                        if (Object.keys(errors).length === 0) {
                          setContactEditIndex(undefined);
                          history.push(`${prevNextRoutePaths.nextRoutePath}?id=${clientId}`);
                        }
                      });
                    });
                  }}
                  progress={saveProgress}
                />
              )}
              {!isPrimaryOrAdditionalJointContact && (
                // contacts added via grid
                <PageBackNextButtons<FormValues>
                  formikProps={formikProps}
                  onNextButtonClick={formikProps.submitForm}
                  onQuitButtonClick={() => {
                    onSave(formikProps.values).then(() => {
                      setContactEditIndex(undefined);
                      history.push('/client/list?mode=onboard');
                    });
                  }}
                  onBackButtonClick={() => {
                    setContactEditIndex(undefined);
                    history.push(`${prevNextRoutePaths.prevRoutePath}?id=${clientId}`);
                  }}
                  nextButtonText="Save"
                  backButtonText="Contacts"
                  progress={saveProgress}
                />
              )}
            </Form>
          )}
        </Formik>
      </Box>
    </div>
  );
};
