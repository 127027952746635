import { Backdrop, Fade, Grid, Modal, Paper, Typography } from '@mui/material';
import { Field, Form, Formik } from 'formik';
import React from 'react';
import * as yup from 'yup';
import { LoadingProgress } from '../../../store/types';
import { EditCancelSaveButtons, FormikSelect, FormikTextField, Mode } from '../../formik';
import { ExternalAccount, ExternalInstitution } from '../types';

export interface ExternalAccountProps {
  externalInstitutions: ExternalInstitution[];
  handleCloseModal: () => void;
  isOpen: boolean;
  onSave: (payload: ExternalAccount) => void;
  saveProgress?: LoadingProgress;
}

export function WO2ExternalAccount({ externalInstitutions, handleCloseModal, isOpen, onSave, saveProgress }: ExternalAccountProps): JSX.Element {
  const initialFormValues: ExternalAccount = {
    accountName: '',
    accountNumber: '',
    bsb: '',
    institutionId: null,
  };
  const institutions = externalInstitutions.map((i) => ({ id: i.institutionId, name: i.description }));
  return (
    <Modal
      aria-labelledby="Create External Account"
      aria-describedby="Create a new external account"
      open={isOpen}
      onClose={() => {
        handleCloseModal();
      }}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{ timeout: 500 }}
    >
      <Fade in={isOpen}>
        <Paper
          elevation={0}
          style={{
            minWidth: '60vw',
            maxWidth: '100vw',
            minHeight: '20vh',
            maxHeight: '100vh',
            padding: '40px 40px',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        >
          <Formik<ExternalAccount>
            initialValues={initialFormValues}
            onSubmit={async (details: ExternalAccount) => {
              onSave(details);
            }}
            enableReinitialize={true}
            validationSchema={yup.object({
              accountName: yup.string().required('Account name required'),
              accountNumber: yup.string().required('Account number required'),
              bsb: yup.string().required('BSB number required'),
              institutionId: yup.number().typeError('Institution required'),
            })}
          >
            {() => (
              <Form>
                <div style={{ display: 'flex', marginBottom: '2rem', height: '3rem', alignItems: 'center', justifyContent: 'flex-start' }}>
                  <img
                    src="/images/institution/unknown.png"
                    alt="External Accounts Image"
                    className={`iconImage iconImageunknown.replace(/ /g, '')`}
                    height="48"
                  />
                  <Typography variant="h3" style={{ marginLeft: '20px ' }}>
                    New External Account
                  </Typography>
                </div>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Field name="accountName" component={FormikTextField} label="ACCOUNT NAME" fullWidth></Field>
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      fieldName="institutionId"
                      as={FormikSelect}
                      itemDisplayNameField="name"
                      label="INSTITUTION"
                      data={institutions}
                      fullWidth
                      onChange={() => {
                        return;
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Field name="accountNumber" component={FormikTextField} label="ACCOUNT NUMBER" fullWidth></Field>
                  </Grid>
                  <Grid item xs={6} style={{ paddingBottom: '20px' }}>
                    <Field name="bsb" component={FormikTextField} label="BSB NUMBER" fullWidth></Field>
                  </Grid>
                  <Grid item xs={12} style={{ paddingTop: '20px' }}>
                    <EditCancelSaveButtons mode={Mode.CancelSave} handleCancelClick={handleCloseModal} saveProgress={saveProgress}></EditCancelSaveButtons>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </Paper>
      </Fade>
    </Modal>
  );
}
