import { createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../../../app/api';
import { buildEncodedQueryString } from '../../../../common';
import {
  ConsolidatedSecurityHoldingsSummary,
  ConsolidatedSecurityHoldingsSummaryThunkParameters,
  SecurityDetails,
  SecurityDetailsThunkParameters,
} from './types';

export enum SecurityDetailsActionTypes {
  FetchConsolidatedSecurityHoldingsSummary = '@@security/details/FetchConsolidatedSecurityHoldingsSummary',
  FetchSecurityDetails = '@@security/details/FetchSecurityDetails',
}

export enum SecurityDetailsApiEndpoints {
  fetchConsolidatedSecurityHoldingsSummary = '/holdingsnext/GetConsolidatedSecurityHoldingsSummary',
  fetchSecurityDetails = '/securities/GetSecurityDetails',
}

export const fetchConsolidatedSecurityHoldingsSummary = createAsyncThunk(
  SecurityDetailsActionTypes.FetchConsolidatedSecurityHoldingsSummary,
  async (wrapper: ConsolidatedSecurityHoldingsSummaryThunkParameters) => {
    const queryString = buildEncodedQueryString({
      securityId: wrapper.securityId,
    });
    const response = await api.get<ConsolidatedSecurityHoldingsSummary>(
      `${SecurityDetailsApiEndpoints.fetchConsolidatedSecurityHoldingsSummary}${queryString}`
    );
    return response.data;
  }
);

export const fetchSecurityDetails = createAsyncThunk(SecurityDetailsActionTypes.FetchSecurityDetails, async (wrapper: SecurityDetailsThunkParameters) => {
  const queryString = buildEncodedQueryString({
    securityId: wrapper.securityId,
  });
  const response = await api.get<SecurityDetails>(`${SecurityDetailsApiEndpoints.fetchSecurityDetails}${queryString}`);
  return response.data;
});
