import { Enumeration } from '../../types';

export interface WO2AccountActionType {
  clickHandler: (accountId: number | null | undefined) => void;
  disabled?: boolean;
  icon: React.ReactElement;
  label: string;
  name: keyof Account;
}

export interface AttachableCmaAccount {
  bsb: string;
  name: string;
  number: string;
}

export interface SelectedCmaAccount extends AttachableCmaAccount {
  forceRoa: boolean;
}

export interface AttachableCmaAccounts {
  attachableCmaAccounts: AttachableCmaAccount[];
}

export interface WO2PendingAccountActionType {
  clickHandler: (accountId: number | null | undefined) => void;
  disabled?: boolean;
  icon: React.ReactElement;
  label: string;
  name: keyof PendingAccount;
}

export interface Account {
  accountId: number;
  accountTypeId: number;
  accountName: string;
  accountNumber: string;
  bsb: string;
  institutionId: number | null;
  institution: string;
  securityStatus: number | null;
  isExternal: boolean;
}

export interface Errors {
  code: string | null;
  message: string | null;
}

export interface PendingAccount {
  id: string | null;
  name: string | null;
  number: string | null;
  bsb: string | null;
  institutionId: number;
  institution: string | null;
  statusId: number;
  statusMessage: string | null;
  isDataStatusReady: boolean | null;
  state: string | null;
  errors: Errors[] | null;
}

export interface PendingAccounts {
  pendingAccounts: PendingAccount[];
}

export interface PendingAccountWithActions extends PendingAccount {
  actions: WO2PendingAccountActionType[];
}

export interface AccountType {
  id: number;
  type: string;
  description: string;
}
export interface AccountView {
  accountId: number | null;
  accountTypeId: number | null;
  accountName: string;
  accountNumber: string;
  bsb: string;
  institutionId: number | null;
  institution: string;
  securityStatus: number | null;
  isExternal: boolean;
}

export interface PendingAccountView {
  accountId: string | null;
  accountTypeId: number | null;
  accountName: string;
  accountNumber: string;
  bsb: string;
  institutionId: number | null;
  institution: string;
  securityStatus: number | null;
  isExternal: boolean;
}

export interface DetachAccountPayload {
  clientId: number;
  accountId: number;
}

export interface DetachPendingAccountPayload {
  clientId: number;
  accountId: string;
}

// Forms
export interface SaveuXchangeAccountDetails {
  riskProfile: string;
  sourceOfFunds: string;
}
export interface UnlistedAccount {
  accountName: string;
  accountNumber: string;
}

export interface CreateUnlistedAccountPayload extends UnlistedAccount {
  clientId: number;
}

export interface ExternalAccount {
  accountName: string;
  accountNumber: string;
  bsb: string;
  institutionId: number | null;
}

export interface CreateExternalAccountPayload extends ExternalAccount {
  clientId: number;
}

export interface ExistingAccount {
  institutionId: number | null;
  accountName: string | null;
  accountNumber: string | null;
  bsb: string | null;
  accountId: string | null;
  accountTypeId: number | null;
  institution: string | null;
  securityStatus: number | null;
  isExternal: boolean | null;
}

export interface NewAccount {
  institutionIds: number[];
  accountInfo: NewAccountInfo;
}
export interface NewAccountInfo {
  isHinTransfer: boolean | null;
  hin: string | null;
  pid: string | null;
}

// common form types

export class SecurityStatus extends Enumeration {
  static Active = new SecurityStatus(1, 'Active', 'Active');
  static Inactive = new SecurityStatus(2, 'Inactive', 'Inactive');
  static Matured = new SecurityStatus(3, 'Matured', 'Matured');
  static Pending = new SecurityStatus(4, 'Pending', 'Pending');
  static Sold = new SecurityStatus(5, 'Sold', 'Sold');
  static Terminated = new SecurityStatus(7, 'Terminated', 'Terminated');
  static Suspended = new SecurityStatus(8, 'Suspended', 'Suspended');
  static Removed = new SecurityStatus(9, 'Removed', 'Removed');
  static Open = new SecurityStatus(10, 'Open', 'Open');
  static Archived = new SecurityStatus(11, 'Archived', 'Archived');
  static DocumentsRequired = new SecurityStatus(12, 'DocumentsRequired', 'Documents required');
  static Proforma = new SecurityStatus(13, 'Proforma', 'Proforma');
  static WaitingForApproval = new SecurityStatus(14, 'WaitingForApproval', 'Waiting for Approval');
  static DatafeedActive = new SecurityStatus(15, 'DatafeedActive', 'Datafeed active');
  static Delisted = new SecurityStatus(16, 'Delisted', 'Delisted');
  static Closed = new SecurityStatus(17, 'Closed', 'Closed');
  static Dormant = new SecurityStatus(18, 'Dormant', 'Dormant');

  static getById = (id: number): SecurityStatus | null => {
    return Object.values(SecurityStatus)
      .filter((t) => t instanceof SecurityStatus)
      .find((t: SecurityStatus) => t.id === id);
  };
}

export class PendingAccountStatus extends Enumeration {
  static Proposed = new PendingAccountStatus(1, 'Proposed', 'Proposed');
  static Initiated = new PendingAccountStatus(2, 'Initiated', 'Initiated');
  static Submitted = new PendingAccountStatus(3, 'Submitted', 'Submitted to vendor');

  static getById = (id: number): PendingAccountStatus | null => {
    return Object.values(PendingAccountStatus)
      .filter((t) => t instanceof PendingAccountStatus)
      .find((t: PendingAccountStatus) => t.id === id);
  };

  static getByName = (name: string): PendingAccountStatus | null => {
    return Object.values(PendingAccountStatus)
      .filter((t) => t instanceof PendingAccountStatus)
      .find((t: PendingAccountStatus) => t.name === name);
  };
}

export class PendingAccountInstitutionType extends Enumeration {
  static MacquarieCma = new PendingAccountInstitutionType(10, 'Macquarie', 'Macquarie CMA');
  static DesktopBroker = new PendingAccountInstitutionType(13, 'DesktopBroker', 'Desktop Broker');
  static Openmarkets = new PendingAccountInstitutionType(61, 'OpenMarkets', ' Openmarkets');
  static AMM = new PendingAccountInstitutionType(6, 'AMM', 'Australian Money Market');
  static UxChange = new PendingAccountInstitutionType(81, 'Uxchange', 'uXchange');

  static getAllAccountInstitutions = (): PendingAccountInstitutionType[] => [
    PendingAccountInstitutionType.MacquarieCma,
    PendingAccountInstitutionType.DesktopBroker,
    PendingAccountInstitutionType.Openmarkets,
    PendingAccountInstitutionType.AMM,
    PendingAccountInstitutionType.UxChange,
  ];

  static getAllExistingAccountInstitutions = (): PendingAccountInstitutionType[] =>
    PendingAccountInstitutionType.getAllAccountInstitutions().filter((institution: PendingAccountInstitutionType) => institution.displayName !== 'uXchange');

  static getAllNewAccountInstitutions = (): PendingAccountInstitutionType[] =>
    PendingAccountInstitutionType.getAllAccountInstitutions().filter((institution: PendingAccountInstitutionType) => institution.displayName !== 'AMM');

  static getById = (id: number | null): PendingAccountInstitutionType | undefined => {
    return Object.values(PendingAccountInstitutionType)
      .filter((t) => t instanceof PendingAccountInstitutionType)
      .find((t: PendingAccountInstitutionType) => t.id === id);
  };
}

export class AccountInstitutionType extends Enumeration {
  static UxChange = new AccountInstitutionType(81, 'UxChange', 'uXchange');
  static Unlisted = new AccountInstitutionType(11, 'Unlisted', 'Unlisted');

  static getAllAccountInstitutions = (): AccountInstitutionType[] => [AccountInstitutionType.UxChange, AccountInstitutionType.Unlisted];

  static getById = (id: number | null): AccountInstitutionType | undefined => {
    return Object.values(AccountInstitutionType)
      .filter((t) => t instanceof AccountInstitutionType)
      .find((t: AccountInstitutionType) => t.id === id);
  };
  static getArray = (): AccountInstitutionType[] => [AccountInstitutionType.UxChange, AccountInstitutionType.Unlisted];

  static getByName = (name: string): AccountInstitutionType | undefined => {
    return Object.values(AccountInstitutionType)
      .filter((t) => t instanceof AccountInstitutionType)
      .find((t: AccountInstitutionType) => t.name.toLowerCase() === name.toLowerCase());
  };
}

export enum OtherAccountTypes {
  External = 'External',
}

export interface SourceOfFundsItem {
  id: number;
  displayName: string;
}

export interface ExternalInstitution {
  institutionId: number;
  code: string;
  description: string;
}
export class SourceOfFunds extends Enumeration {
  static BusinessIncome = new SourceOfFunds(1, 'BusinessIncome', 'Business income/earnings');
  static BusinessProfits = new SourceOfFunds(2, 'BusinessProfits', 'Business profits');
  static CompensationPayment = new SourceOfFunds(3, 'CompensationPayment', 'Compensation payment');
  static Gift = new SourceOfFunds(4, 'Gift', 'Gift/Donation');
  static GovernmentBenefits = new SourceOfFunds(5, 'GovernmentBenefits', 'Government benefits');
  static Inheritance = new SourceOfFunds(6, 'Inheritance', 'Inheritance');
  static InsurancePayment = new SourceOfFunds(7, 'InsurancePayment', 'Insurance payment');
  static InvestmentIncome = new SourceOfFunds(8, 'InvestmentIncome', 'Investment income/earnings');
  static Redundancy = new SourceOfFunds(9, 'Redundancy', 'Redundancy');
  static RentalIncome = new SourceOfFunds(10, 'RentalIncome', 'Rental income');
  static SalaryWages = new SourceOfFunds(11, 'SalaryWages', 'Salary/Wages');
  static SaleOfAssets = new SourceOfFunds(12, 'SaleOfAssets', 'Sale of assets');
  static SuperannuationPension = new SourceOfFunds(13, 'SuperannuationPension', 'Superannuation/pension');
  static Windfall = new SourceOfFunds(14, 'Windfall', 'Windfall');

  static getArray = (): SourceOfFunds[] => [
    SourceOfFunds.BusinessIncome,
    SourceOfFunds.BusinessProfits,
    SourceOfFunds.CompensationPayment,
    SourceOfFunds.Gift,
    SourceOfFunds.GovernmentBenefits,
    SourceOfFunds.Inheritance,
    SourceOfFunds.InsurancePayment,
    SourceOfFunds.InvestmentIncome,
    SourceOfFunds.Redundancy,
    SourceOfFunds.RentalIncome,
    SourceOfFunds.SalaryWages,
    SourceOfFunds.SaleOfAssets,
    SourceOfFunds.SuperannuationPension,
    SourceOfFunds.Windfall,
  ];
}

enum externalAccountTypes {
  depositAtCall = 1,
  depositCMA = 2,
  depositTD = 3,
  securityBond = 4,
  SecurityMF = 5,
  UnlistedAssets = 9,
  ba = 12,
}

export const externalAccountTypesIds: number[] = [
  externalAccountTypes.depositAtCall,
  externalAccountTypes.depositCMA,
  externalAccountTypes.depositTD,
  externalAccountTypes.securityBond,
  externalAccountTypes.SecurityMF,
  externalAccountTypes.UnlistedAssets,
  externalAccountTypes.ba,
];
