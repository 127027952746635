import { Container, Grid } from '@mui/material';
import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { ClientPortfolioDetailsContainer } from './ClientPortfolioDetailsContainer';
import GainLossCard from './GainLossCard';
import { MonthlyPerformanceChartContainer } from './MonthlyPerformanceChartContainer';
import PortfolioValueCard from './PortfolioValueCard';
import { RecentTransactionsContainer } from './RecentTransactionsContainer';

type Props = RouteComponentProps;

export const DashboardComponent = (props: Props): JSX.Element => {
  return (
    <>
      <MonthlyPerformanceChartContainer />
      <Container style={{ paddingTop: '20px', paddingBottom: '20px' }}>
        <Grid container spacing={2}>
          <Grid item xs={8}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <PortfolioValueCard />
              </Grid>
              <Grid item xs={6}>
                <GainLossCard />
              </Grid>
              <Grid item xs={12}>
                <ClientPortfolioDetailsContainer {...props} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={4}>
            <RecentTransactionsContainer {...props} />
          </Grid>
        </Grid>
      </Container>
    </>
  );
};
