import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import FileSaver from 'file-saver';
import { AttachableContact, ContactRoles } from 'src/features/clients/common/types';
import api from '../../../../../../app/api';
import { buildEncodedQueryString } from '../../../../../../common';
import { extractFilenameFromContentDisposition } from '../../../../../../common/utils/fileDownload';
import { FetchPagedResults, PagedResult, RootState } from '../../../../../../store';
import { selectClientId } from '../../../common/store/selectors';
import {
  AddressDetails,
  AttachContactPayload,
  ContactDetail,
  ContactDetails,
  DeleteAddressPayload,
  DeleteContactDocumentPayload,
  DeleteEmailAddressPayload,
  DeletePhoneNumberPayload,
  DetachContactPayload,
  DocumentDetails,
  DownloadDocumentPayload,
  FetchContactDocumentPayload,
  FetchContactDocumentsPayload,
  FetchContactPayload,
  FetchContactRolesRequestModel,
  FetchContactsToAttachPayload,
  SaveAddressPayload,
  SaveContactDocumentPayload,
  SaveContactPayload,
  SaveEmailAddressPayload,
  SavePhoneNumberPayload,
  UpdateContactEmailDetailPayload,
  UpdateContactRolesPayload,
} from '../../../common/store/types';
import { contactsSlice } from './slice';

export enum ContactsActionTypes {
  FetchContacts = '@@client/details/contacts/FetchContacts',
  FetchContact = '@@client/details/contacts/FetchContact',
  CreateContact = '@@client/details/contacts/CreateContact',
  UpdateContact = '@@client/details/contacts/UpdateContact',
  FetchContactsToAttach = '@@client/details/contacts/FetchContactsToAttach',
  AttachContact = '@@client/details/contacts/AttachContact',
  UpdateContactRoles = '@@client/details/contacts/UpdateContactRoles',
  DetachContact = '@@client/details/contacts/DetachContact',

  FetchAddresses = '@@client/details/contacts/addresses/Fetch',
  UpdateAddress = '@@client/details/contacts/addresses/Update',
  DeleteAddress = '@@client/details/contacts/addresses/Delete',

  FetchDocuments = '@@client/details/contact/documents/Fetch',
  FetchDocument = '@@client/details/contact/documents/FetchSingle',
  DownloadDocument = '@@client/details/contact/documents/Download',
  DeleteDocument = '@@client/details/contact/documents/Delete',
  SaveDocument = '@@client/details/contact/documents/Save',

  FetchIdentifications = '@@client/details/contacts/identifications/Fetch',
  FetchIdentification = '@@client/details/contacts/identifications/FetchSingle',
  DownloadIdentification = '@@client/details/contacts/identifications/DownloadD',

  FetchContactDetails = '@@client/details/contacts/FetchContactDetails',
  SavePhoneNumber = '@@client/details/contacts/savePhoneNumber',
  DeletePhoneNumber = '@@client/details/contacts/deletePhoneNumber',
  SaveEmailAddress = '@@client/details/contacts/saveEmailAddresses',
  DeleteEmailAddress = '@@client/details/contacts/deleteEmailAddresses',
}

export enum ContactsApiEndpoints {
  FetchContacts = 'customers/GetClientContacts',
  FetchContact = 'customers/GetContactForEntity',
  FetchContactRoles = 'customers/GetClientContact',
  UpdateContact = 'customers/UpdateClientContact',
  CreateContact = 'customers/CreateClientContact',
  FetchContactsToAttach = 'customers/GetAttachableContacts',
  AttachContact = 'customers/AttachContactToClient',
  UpdateContactRoles = 'customers/UpdateContactToClientLinks',
  DetachContact = 'customers/DetachContact',

  FetchAddresses = 'customers/GetContactAddresses',
  UpdateAddress = 'customers/UpdateAddressForContact',
  CreateAddress = 'customers/CreateAddressForContact',
  DeleteAddress = 'customers/DeleteAddressForContact',

  FetchDocuments = 'documents/GetContactAttachments',
  FetchDocument = 'documents/GetContactAttachment',
  DownloadDocument = 'documents/Download',
  DeleteDocument = 'documents/DeleteContactAttachment',
  UpdateDocument = 'documents/UpdateContactAttachment',
  CreateDocument = 'documents/CreateContactAttachment',

  FetchIdentifications = 'documents/GetContactAttachments',
  FetchIdentification = 'documents/GetContactAttachment',
  DownloadIdentification = 'documents/Download',

  FetchContactDetails = 'customers/GetContactDetails',
  CreateContactDetails = 'customers/CreateContactDetails',
  UpdateContactDetails = 'customers/UpdateContactDetails',
  DeleteContactDetails = 'customers/DeleteContactDetails',
}

//----Contacts----------------------
export const fetchContacts = createAsyncThunk(ContactsActionTypes.FetchContacts, async (clientId: number) => {
  const queryString = buildEncodedQueryString({
    clientId,
  });
  const response = await api.get<ContactRoles[]>(`${ContactsApiEndpoints.FetchContacts}${queryString}`);
  return response.data;
});

export const fetchContact = createAsyncThunk(ContactsActionTypes.FetchContact, async (params: FetchContactPayload) => {
  const queryString = buildEncodedQueryString({
    clientId: params.clientId,
    contactId: params.contactId,
    entityCoreId: params.contactId,
  });

  const contact = await api.get<ContactDetails>(`${ContactsApiEndpoints.FetchContact}${queryString}`);
  const roles = await api.get<FetchContactRolesRequestModel>(`${ContactsApiEndpoints.FetchContactRoles}${queryString}`);

  const contactDetails: ContactDetails = { ...contact.data, isPrimary: roles.data.isPrimary, roles: roles.data.roleMappings };

  return contactDetails;
});

export const saveContact = createAsyncThunk(ContactsActionTypes.CreateContact, async (payload: SaveContactPayload, thunkApi) => {
  const contactRoleId = 3;
  const clientId = selectClientId(thunkApi.getState() as RootState);
  return api
    .post<{ contactId: number }>(`${ContactsApiEndpoints.CreateContact}`, { ...payload, clientId, roleIds: [contactRoleId] })
    .then((createContactResponse) => {
      return { message: 'Contact added', contactId: createContactResponse.data.contactId };
    })
    .catch(() => {
      return { message: 'Error adding contact', variant: 'error' };
    });
});

export const updateContact = createAsyncThunk(ContactsActionTypes.UpdateContact, async (payload: SaveContactPayload, thunkApi) => {
  const clientId = selectClientId(thunkApi.getState() as RootState);
  return api
    .post(`${ContactsApiEndpoints.UpdateContact}`, { ...payload, clientId, contactId: payload.id })
    .then(() => {
      return { message: 'Contact updated' };
    })
    .catch(() => {
      return { message: 'Error updating contact', variant: 'error' };
    });
});

export const fetchContactsToAttach = createAsyncThunk(ContactsActionTypes.FetchContactsToAttach, async (payload: FetchContactsToAttachPayload) => {
  const queryString = buildEncodedQueryString({ clientId: payload.parentId, searchText: payload.searchText });
  const response = await api.get<AttachableContact[]>(`${ContactsApiEndpoints.FetchContactsToAttach}${queryString}`);
  return response.data;
});

export const attachContact = createAsyncThunk(ContactsActionTypes.AttachContact, async (payload: AttachContactPayload, thunkApi) => {
  const apiPayload = {
    clientId: payload.clientId,
    contactId: payload.contactId,
    roles: payload.roles,
    isPrimary: payload.isPrimary,
  };

  await api.post(`${ContactsApiEndpoints.AttachContact}`, apiPayload);

  thunkApi.dispatch(fetchContacts(payload.clientId));
  return { message: 'Contact attached' };
});

export const updateContactRoles = createAsyncThunk(ContactsActionTypes.UpdateContactRoles, async (payload: UpdateContactRolesPayload, thunkApi) => {
  await api.post(`${ContactsApiEndpoints.UpdateContactRoles}`, payload);
  thunkApi.dispatch(fetchContacts(payload.clientId));
  return { message: 'Contact saved' };
});

export const detachContact = createAsyncThunk(ContactsActionTypes.AttachContact, async (payload: DetachContactPayload, thunkApi) => {
  await api.post(`${ContactsApiEndpoints.DetachContact}`, { clientId: payload.parentId, contactId: payload.contactId });

  thunkApi.dispatch(fetchContacts(payload.parentId));
  return { message: 'Contact detached' };
});

//----Addresses----------------------
export const fetchAddresses = createAsyncThunk(ContactsActionTypes.FetchAddresses, async (contactId: number) => {
  const queryString = buildEncodedQueryString({
    contactId,
  });
  const response = await api.get<AddressDetails[]>(`${ContactsApiEndpoints.FetchAddresses}${queryString}`);
  return response.data;
});

export const saveAddress = createAsyncThunk(ContactsActionTypes.UpdateAddress, async (payload: SaveAddressPayload, thunkApi) => {
  await api.post(!!payload.address.addressId ? ContactsApiEndpoints.UpdateAddress : ContactsApiEndpoints.CreateAddress, {
    ...payload.address,
    contactId: payload.contactId,
  });
  thunkApi.dispatch(fetchAddresses(payload.contactId));
  return { message: !!payload.address.addressId ? 'Address saved' : 'Address added' };
});

export const deleteAddress = createAsyncThunk(ContactsActionTypes.DeleteAddress, async (payload: DeleteAddressPayload, thunkApi) => {
  await api.post(`${ContactsApiEndpoints.DeleteAddress}`, payload);
  thunkApi.dispatch(fetchAddresses(payload.contactId));
  return { message: 'Address deleted' };
});

//----Documents----------------------
export const fetchDocuments = createAsyncThunk(ContactsActionTypes.FetchDocuments, async (wrapper: FetchContactDocumentsPayload) => {
  const body = {
    clientId: wrapper.clientId,
    contactId: wrapper.contactId,
    filter: 'documents',
    pagedRequest: wrapper.parameters.pagination,
  };

  const response = await api.post<PagedResult<DocumentDetails>>(`${ContactsApiEndpoints.FetchDocuments}`, body);

  return {
    results: response.data,
    pagination: wrapper.parameters.pagination,
  } as FetchPagedResults<DocumentDetails>;
});

export const fetchDocumentForEdit = createAsyncThunk(ContactsActionTypes.FetchDocument, async (wrapper: FetchContactDocumentPayload) => {
  const queryString = buildEncodedQueryString({
    entityCoreId: wrapper.contactId,
    attachmentId: wrapper.documentId,
  });

  return await (
    await api.get<DocumentDetails>(`${ContactsApiEndpoints.FetchDocument}${queryString}`)
  ).data;
});

//----Identifications----------------------
export const fetchIdentifications = createAsyncThunk(ContactsActionTypes.FetchIdentifications, async (wrapper: FetchContactDocumentsPayload) => {
  const body = {
    clientId: wrapper.clientId,
    contactId: wrapper.contactId,
    filter: 'identification',
    pagedRequest: wrapper.parameters.pagination,
  };

  const response = await api.post<PagedResult<DocumentDetails>>(`${ContactsApiEndpoints.FetchIdentifications}`, body);

  return {
    results: response.data,
    pagination: wrapper.parameters.pagination,
  } as FetchPagedResults<DocumentDetails>;
});

export const fetchIdentificationForEdit = createAsyncThunk(ContactsActionTypes.FetchIdentification, async (wrapper: FetchContactDocumentPayload) => {
  const queryString = buildEncodedQueryString({
    entityCoreId: wrapper.contactId,
    attachmentId: wrapper.documentId,
  });

  return await (
    await api.get<DocumentDetails>(`${ContactsApiEndpoints.FetchIdentification}${queryString}`)
  ).data;
});

/// Common docs/idents -------------------
export const downloadDocument = createAsyncThunk(ContactsActionTypes.DownloadDocument, async (wrapper: DownloadDocumentPayload) => {
  const queryString = buildEncodedQueryString({
    clientId: wrapper.clientId,
    attachmentId: wrapper.attachmentId,
  });

  await api
    .get(`${ContactsApiEndpoints.DownloadDocument}${queryString}`, {
      responseType: 'blob',
    })
    .then((response: AxiosResponse) => {
      const fileName = extractFilenameFromContentDisposition(response.headers['content-disposition']) || wrapper.filename;
      FileSaver.saveAs(new Blob([response.data]), fileName);
    });
});

export const deleteDocument = createAsyncThunk(ContactsActionTypes.DeleteDocument, async (payload: DeleteContactDocumentPayload, thunkApi) => {
  await api.delete(ContactsApiEndpoints.DeleteDocument, { data: payload });

  thunkApi.dispatch(fetchDocuments(payload.fetchPayload));

  return { message: 'Document deleted' };
});

export const saveDocument = createAsyncThunk(ContactsActionTypes.SaveDocument, async (payload: SaveContactDocumentPayload, thunkApi) => {
  await api.post(!!payload.document.id ? ContactsApiEndpoints.UpdateDocument : ContactsApiEndpoints.CreateDocument, {
    ...payload.document,
    clientId: payload.clientId,
    contactId: payload.fetchPayload.contactId,
  });
  thunkApi.dispatch(fetchDocuments(payload.fetchPayload));
  thunkApi.dispatch(contactsSlice.actions.cancelDocumentAddEditMode());
  thunkApi.dispatch(contactsSlice.actions.cancelIdentificationAddEditMode());

  return { message: !!payload.document.id ? 'Document saved' : 'Document added' };
});

//----contact details----------------------
export const fetchContactDetails = createAsyncThunk(ContactsActionTypes.FetchContactDetails, async (contactId: number) => {
  const queryString = buildEncodedQueryString({
    contactId,
  });
  const response = await api.get<ContactDetail[]>(`${ContactsApiEndpoints.FetchContactDetails}${queryString}`);
  return response.data;
});

export const savePhoneNumber = createAsyncThunk(ContactsActionTypes.SavePhoneNumber, async (payload: SavePhoneNumberPayload, thunkApi) => {
  const body: UpdateContactEmailDetailPayload = {
    contactDetailId: payload.phoneNumber.id,
    contactId: payload.contactId,
    areaCode: payload.phoneNumber.areaCode,
    countryCode: payload.phoneNumber.countryCode,
    phoneEmail: payload.phoneNumber.phoneNumber,
    contactDetailTypeId: payload.phoneNumber.typeId,
    preferred: payload.phoneNumber.preferred,
    clientPortalAccess: false,
    hasMoneysoftAccess: false,
  };

  if (!!body.contactDetailId) {
    await api.post<PagedResult<ContactDetail>>(`${ContactsApiEndpoints.UpdateContactDetails}`, body);
  } else {
    await api.post<PagedResult<ContactDetail>>(`${ContactsApiEndpoints.CreateContactDetails}`, body);
  }

  thunkApi.dispatch(fetchContactDetails(payload.contactId));
  thunkApi.dispatch(contactsSlice.actions.setPhoneNumberEditId(undefined));
  return { message: !!body.contactDetailId ? 'Phone number saved' : 'Phone number added' };
});

export const deletePhoneNumber = createAsyncThunk(ContactsActionTypes.DeletePhoneNumber, async (wrapper: DeletePhoneNumberPayload, thunkApi) => {
  const body = wrapper;
  await api.post<PagedResult<DocumentDetails>>(`${ContactsApiEndpoints.DeleteContactDetails}`, body);
  thunkApi.dispatch(fetchContactDetails(wrapper.contactId));
  return { message: `Phone number deleted` };
});

export const saveEmailAddress = createAsyncThunk(ContactsActionTypes.SaveEmailAddress, async (payload: SaveEmailAddressPayload, thunkApi) => {
  const body: UpdateContactEmailDetailPayload = {
    contactDetailId: payload.emailAddress.id,
    contactId: payload.contactId,
    areaCode: null,
    countryCode: null,
    phoneEmail: payload.emailAddress.emailAddress,
    contactDetailTypeId: payload.emailAddress.typeId,
    preferred: payload.emailAddress.preferred,
    clientPortalAccess: payload.emailAddress.clientPortalAccess,
    hasMoneysoftAccess: payload.emailAddress.hasMoneysoftAccess,
  };

  if (!!body.contactDetailId) {
    await api.post<PagedResult<ContactDetail>>(`${ContactsApiEndpoints.UpdateContactDetails}`, body);
  } else {
    await api.post<PagedResult<ContactDetail>>(`${ContactsApiEndpoints.CreateContactDetails}`, body);
  }

  thunkApi.dispatch(fetchContactDetails(payload.contactId));
  thunkApi.dispatch(contactsSlice.actions.setEmailAddressEditId(undefined));
  return { message: !!payload.emailAddress.id ? 'Email address saved' : 'Email address added' };
});

export const deleteEmailAddress = createAsyncThunk(ContactsActionTypes.DeleteEmailAddress, async (wrapper: DeleteEmailAddressPayload, thunkApi) => {
  const body = wrapper;
  await api.post(`${ContactsApiEndpoints.DeleteContactDetails}`, body);
  thunkApi.dispatch(fetchContactDetails(wrapper.contactId));
  return { message: 'Email address deleted' };
});
