import { PayloadAction } from '@reduxjs/toolkit';
import * as E from 'fp-ts/lib/Either';
import * as t from 'io-ts';
import reporter from 'io-ts-reporters';
import { parse, ParseResult } from 'papaparse';
import { call, ForkEffect, put, select, takeLatest } from 'redux-saga/effects';
import { getCustomAsyncActions } from '../../../../../store/asyncAction';
import { selectModelId } from '../selectors';
import { AllocationsImporterResponse, AssetAllocation, ImportRequestPayload, SaveModelCompositionPayload, SaveModelCompositionResult } from '../types';
import { saveModelCompositions } from './../services';
import { checkDuplicateSecurityCodes, targetAllocationsShouldBeBetween0And1 } from './rules';

export const importAllocationsActionType = '@@model/composition/allocations/import';
export const importAllocationsAction = getCustomAsyncActions<ImportRequestPayload, AllocationsImporterResponse, string[]>(importAllocationsActionType);

const allocation: t.Type<AssetAllocation> = t.type({
  securityCode: t.string,
  assetClass: t.string,
  targetAllocation: t.number,
});

const decodeData = (data: AssetAllocation[]) => {
  const errors: string[] = [];
  const allocations: AssetAllocation[] = [];

  data.forEach((row, index) => {
    const result = allocation.decode(row);

    if (E.isLeft(result)) {
      const error = reporter.report(result);
      errors.push(`row: ${index + 1} message: ${error.toString()}\n`);
    } else {
      allocations.push({
        assetClass: result.right.assetClass,
        securityCode: result.right.securityCode,
        targetAllocation: result.right.targetAllocation,
      });
    }
  });
  return { allocations, errors };
};

const validateData = (data: AssetAllocation[]): { allocations: AssetAllocation[]; errors: string[] } => {
  const errors: string[] = [] as string[];
  const allocations: AssetAllocation[] = data;

  // has duplicate security codes
  const duplicateErrors = checkDuplicateSecurityCodes(data);
  if (duplicateErrors !== '') {
    return { allocations: [], errors: [duplicateErrors] };
  }
  // target allocations must be more than 0 and less than 1
  const targetAllocationsErrors = targetAllocationsShouldBeBetween0And1(data);
  if (targetAllocationsErrors !== '') {
    return { allocations: [], errors: [targetAllocationsErrors] };
  }

  return { allocations, errors };
};

function parseImportFile(importFile: File) {
  return new Promise<ParseResult<unknown>>((resolve) => {
    parse(importFile, {
      header: true,
      dynamicTyping: true,
      skipEmptyLines: true,
      complete: function (results) {
        resolve(results);
      },
    });
  });
}

function* importAllocations(action: PayloadAction<ImportRequestPayload>) {
  try {
    const result: ParseResult<unknown> = yield call(parseImportFile, action.payload.file);
    // failed parsing
    if (result.errors && result.errors.length > 0) {
      const parseErrors = result.errors.map((error) => {
        return `row: ${error.row} type: ${error.type} code: ${error.code} message: ${error.message}\n`;
      });
      const response: PayloadAction<string[]> = yield put(importAllocationsAction.rejected(parseErrors));
      return response;
    }

    // decode data
    const decodedData = decodeData(result.data as AssetAllocation[]);
    if (decodedData.errors && decodedData.errors.length > 0) {
      const response: PayloadAction<string[]> = yield put(importAllocationsAction.rejected(decodedData.errors));
      return response;
    }

    // validate rules
    const validatedData = validateData(decodedData.allocations);
    if (validatedData.errors && validatedData.errors.length > 0) {
      const response: PayloadAction<string[]> = yield put(importAllocationsAction.rejected(validatedData.errors));
      return response;
    }

    const convertedAllocations = validatedData.allocations.map((allocation: AssetAllocation) => ({
      assetClass: allocation.assetClass,
      code: allocation.securityCode,
      targetAllocation: allocation.targetAllocation,
    }));

    // send service
    const modelId: number = yield select(selectModelId);
    const payloadRequest: SaveModelCompositionPayload = {
      modelId,
      assetAllocations: convertedAllocations,
    };
    const saveModelCompositionsResponse: SaveModelCompositionResult = yield call(saveModelCompositions, payloadRequest);

    // Errors getting securities for afsl
    if (saveModelCompositionsResponse.errors && saveModelCompositionsResponse.errors.length > 0) {
      const response: PayloadAction<string[]> = yield put(importAllocationsAction.rejected(saveModelCompositionsResponse.errors));
      return response;
    }

    // Send response along with warnings and information
    const payload = {
      allocations: validatedData.allocations,
      warnings: saveModelCompositionsResponse.warnings,
      information: saveModelCompositionsResponse.information,
      errors: saveModelCompositionsResponse.errors,
    };

    const response: PayloadAction<AllocationsImporterResponse> = yield put(importAllocationsAction.fulfilled(payload));
    return response;
  } catch (e) {
    const error = [`${e.status} - ${e.statusText}`];

    yield put(importAllocationsAction.rejected(error));
  }
}

export function* importAllocationsSaga(): Generator<ForkEffect<never>, void, unknown> {
  yield takeLatest(importAllocationsAction.pending, importAllocations);
}
