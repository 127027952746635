import { Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { Props } from '../container';
import { SaveConstraintItem } from './../store';
import { ConstraintsTable } from './constraintsTable';

export const Constraints = ({
  clientId,
  securityConstraints,
  currentInvestmentVersionId,
  isLoadingProgress,
  fetchConstraints,
  fetchSecurities,
  saveConstraints,
  securities,
  securitiesLoadingProgress,
  savingProgress,
}: Props): JSX.Element => {
  useEffect(() => {
    if (!!currentInvestmentVersionId && !!clientId) {
      fetchSecurities('');
      fetchConstraints({ investmentServiceVersionId: currentInvestmentVersionId, clientId });
    }
  }, [fetchConstraints, fetchSecurities, currentInvestmentVersionId, clientId]);

  return (
    <>
      <Typography variant="h3" style={{ paddingBottom: '20px' }}>
        Constraint Settings
      </Typography>
      <Typography variant="h5" style={{ paddingBottom: '30px' }}>
        View and update the configurations that dictate order and rebalance bahaviours.
      </Typography>

      <ConstraintsTable
        items={securityConstraints}
        securities={securities}
        onSave={async (constraints: SaveConstraintItem[]) => {
          if (!!currentInvestmentVersionId && !!clientId) {
            await saveConstraints({
              clientId,
              investmentServiceVersionId: currentInvestmentVersionId,
              securityConstraints: constraints,
            });
          }
        }}
        itemLoadingProgress={isLoadingProgress}
        securitiesLoadingProgress={securitiesLoadingProgress}
        savingProgress={savingProgress}
      />
    </>
  );
};
